export const AuthTranslationsEN = {
    "Ti diamo il benvenuto su": "Welcome to",
    "Accedi/Iscriviti con": "Login/Sign Up with:",
    "Email e password": "Email and password",
    "o": "or",

    "Inserisci qui la tua email, una volta premuto il pulsante riceverai un link alla tua casella email che ti permetterà di accedere a Reasy in un attimo.": "Enter your email here, once you press the button you will receive a link to your email inbox that will allow you to access Reasy in no time.",
    "Link magico": "Magic Link",
    "Richiedi un link magico": "Request a magic link",

    "Registrazione": "Sign Up",
    "Email": "Email address",

    "Conferma la email": " Confirm the email.",
    "Stiamo preparando il tuo ambiente di studio": "We are preparing your study environment.",
    "Hai aperto la email di conferma da un dispositivo diverso da quello da cui hai richiesto l'accesso.": "You opened the confirmation email from a device different from the one you requested access from.",
    "Inserisci la email qui sotto se vuoi accedere da questo dispositivo": "Enter the email below if you want to access from this device.",
    "Link non valido, richiedine uno nuovo nella": "Invalid link, request a new one on the",
    "pagina iniziale": "homepage",

    "Ho letto e accetto la ": "I have read and agree to the ",
    " e i ": " and the ",
    "termini di servizio": "terms of service",

    "Voglio registrarmi": "Sign Up",
    "Hai già un account? ": "Already have an account? ",
    "Accedi": "Sign In",
    "Accedi a Reasy": "Sign in to Reasy",

    "Indietro": "Go back",

    "Iniziamo": "Let's Start",
    "Inserisci email e password per registrarti": "Enter email and password to register",

    "Email non valida": "Email not valid",
    "La password deve essere di almeno 7 caratteri": "Password must be at least 7 characters",
    "Devi prima accettare i termini per la privacy": "You must agree to the privacy terms",

    "Non hai un account?": "Don't have an account yet?",
    "Registrati": "Sign Up",
    "Ho dimenticato la password": "Forgot Password?",

    "Reset password": "Reset password",
    "Email di reset password inviata.": "Reset password email sent",
    "Recupero della password": "Password Recovery",
    "Inviami la email di ripristino": "Send me the reset email",
    "Ti abbiamo inviato una mail di conferma a": "We've sent a confirmation link to",

    "Su quale dispositivo vuoi effettuare l'accesso?": "On which device do you want to sign in?",
    "Accedi da questo dispositivo": "Sign In from current device",
    "Accedi da un altro dispositivo": "Sign In from another device",

    "Scansiona questo QR Code dal dispositivo che vuoi usare su Reading Easy.": "Scan this QR Code from the device you want to use for Reading Easy",

    "Abbiamo inviato il link alla email da te inserita": "We have sent the link to the email you entered.",
    "Vai ad aprire la tua casella email!": "Go and open your email inbox!",

    "Get started": "Get started",
    "Get started for Free": "Get started for Free",
    "Continue with": "Continue with",
    "By registering you confirm that you have read and understood our": "By registering you confirm that you have read and understood our",
    "the": "the",
    "privacy policy": "privacy policy",
    "terms and conditions": "terms and conditions",
    "cookie policy": "cookie policy",
}

export const AuthTranslationsIT = {
    "Ti diamo il benvenuto su": "Ti diamo il benvenuto su",
    "Accedi/Iscriviti con": "Accedi/Iscriviti con:",
    "Email e password": "Email e password",
    "Link magico": "Link magico",
    "Richiedi un link magico": "Richiedi un link magico",

    "o": "o",

    "Inserisci qui la tua email, una volta premuto il pulsante riceverai un link alla tua casella email che ti permetterà di accedere a Reasy in un attimo.": "Inserisci qui la tua email, una volta premuto il pulsante riceverai un link alla tua casella email che ti permetterà di accedere a Reasy in un attimo.",
    "Inserisci email e password per registrarti": "Inserisci email e password per registrarti",

    "Registrazione": "Registrazione",
    "Email": "Email",
    "Ho letto e accetto la ": "Ho letto e accetto la ",
    " e i ": " e i ",

    "termini di servizio": "termini di servizio",
    "Voglio registrarmi": "Voglio registrarmi",
    "Hai già un account? ": "Hai già un account? ",
    "Accedi": "Accedi",
    "Accedi a Reasy": "Accedi a Reasy",

    "Conferma la email": "Conferma la email",
    "Stiamo preparando il tuo ambiente di studio": "Stiamo preparando il tuo ambiente di studio",
    "Hai aperto la email di conferma da un dispositivo diverso da quello da cui hai richiesto l'accesso.": "Hai aperto la email di conferma da un dispositivo diverso da quello da cui hai richiesto l'accesso.",
    "Inserisci la email qui sotto se vuoi accedere da questo dispositivo": "Inserisci la email qui sotto se vuoi accedere da questo dispositivo",
    "Link non valido, richiedine uno nuovo nella": "Link non valido, richiedine uno nuovo nella",
    "pagina iniziale": "pagina iniziale",

    "Indietro": "Indietro",

    "Iniziamo": "Iniziamo",
    "Email non valida": "Email non valida",
    "La password deve essere di almeno 7 caratteri": "La password deve essere di almeno 7 caratteri",
    "Devi prima accettare i termini per la privacy": "Devi prima accettare i termini per la privacy",

    "Non hai un account?": "Non hai un account?",
    "Registrati": "Registrati",
    "Ho dimenticato la password": "Ho dimenticato la password",

    "Reset password": "Reset password",
    "Email di reset password inviata.": "Email di reset password inviata.",
    "Recupero della password": "Recupero della password",
    "Inviami la email di ripristino": "Inviami la email di ripristino",
    "Ti abbiamo inviato una mail di conferma a": "Ti abbiamo inviato una mail di conferma a",

    "Su quale dispositivo vuoi effettuare l'accesso?": "Su quale dispositivo vuoi effettuare l'accesso?",
    "Accedi da questo dispositivo": "Accedi da questo dispositivo",
    "Accedi da un altro dispositivo": "Accedi da un altro dispositivo",

    "Scansiona questo QR Code dal dispositivo che vuoi usare su Reading Easy.": "Scansiona questo QR Code dal dispositivo che vuoi usare su Reading Easy.",

    "Abbiamo inviato il link alla email da te inserita": "Abbiamo inviato il link alla email da te inserita",
    "Vai ad aprire la tua casella email!": "Vai ad aprire la tua casella email!",

    "Get started": "Inizia",
    "Get started for Free": "Inizia gratuitamente",
    "Continue with": "Continua con",
    "By registering you confirm that you have read and understood our": "Registrandoti confermi di aver letto la nostra",
    "the": "la",
    "privacy policy": "politica sulla riservatezza",
    "terms and conditions": "termini e condizioni",
    "cookie policy": "politica sui cookie",
}
export const AuthTranslationsES = {
    "Ti diamo il benvenuto su": "Le damos la bienvenida en",
    "Accedi/Iscriviti con": "Conéctese/regístrese con:",
    "Email e password": "Email / password",
    "Link magico": "Enlace mágico",
    "Richiedi un link magico": "Solicitar un enlace mágico",
    "o": "o",

    "Inserisci qui la tua email, una volta premuto il pulsante riceverai un link alla tua casella email che ti permetterà di accedere a Reasy in un attimo.": "Introduce tu correo electrónico aquí, una vez que pulses el botón recibirás un enlace en tu buzón de correo electrónico que te permitirá acceder a Reasy en poco tiempo.",
    "Inserisci email e password per registrarti": "Introduce tu correo electrónico y contraseña para registrarte",

    "Conferma la email": "Confirma el correo electrónico.",
    "Stiamo preparando il tuo ambiente di studio": "Estamos preparando tu entorno de estudio.",
    "Hai aperto la email di conferma da un dispositivo diverso da quello da cui hai richiesto l'accesso.": " Has abierto el correo de confirmación desde un dispositivo diferente al que solicitaste el acceso.",
    "Inserisci la email qui sotto se vuoi accedere da questo dispositivo": "Ingresa el correo electrónico a continuación si deseas acceder desde este dispositivo.",
    "Link non valido, richiedine uno nuovo nella": " Enlace no válido, solicita uno nuevo en la",
    "pagina iniziale": "página de inicio.",

    "Registrazione": "Regístrate",
    "Email": "Correo electrónico",
    "Registrati": "Registrar",
    "Ho letto e accetto la ": "He leído y acepto la",
    " e i ": " y las ",
    "termini di servizio": "Condiciones de servicio",

    "Voglio registrarmi": "Regístrate",
    "Hai già un account? ": "¿Ya tienes una cuenta? ",
    "Accedi": "Entrar",
    "Accedi a Reasy": "Acceso Reasy",

    "Indietro": "Vuelve",

    "Iniziamo": "Comencemos",
    "Email non valida": "Correo electrónico no válido",
    "Password non valida": "Contraseña no valida",
    "La password deve essere di almeno 7 caratteri": "Contraseña must be at least 7 characters",
    "Devi prima accettare i termini per la privacy": "Primero debes aceptar los términos de privacidad",

    "Non hai un account?": "¿No tienes una cuenta?",
    "Ho dimenticato la password": "He olvidado mi contraseña",

    "Reset password": "Restablecer contraseña",
    "Email di reset password inviata.": "Email de restablecimiento de contraseña enviado",
    "Recupero della password": "Recuperación de contraseñas",
    "Inviami la email di ripristino": "Envíame el correo electrónico de restablecimiento",
    "Ti abbiamo inviato una mail di conferma a": "Te hemos enviado un correo electrónico de confirmación a",

    "Su quale dispositivo vuoi effettuare l'accesso?": "¿En qué dispositivo quieres iniciar sesión?",
    "Accedi da questo dispositivo": "Iniciar sesión desde este dispositivo",
    "Accedi da un altro dispositivo": "Iniciar sesión desde otro dispositivo",

    "Scansiona questo QR Code dal dispositivo che vuoi usare su Reading Easy.": "Escanea este código QR desde el dispositivo que quieras usar en Reading Easy.",

    "Abbiamo inviato il link alla email da te inserita": "Hemos enviado el enlace al correo electrónico que ingresaste.",
    "Vai ad aprire la tua casella email!": "Ve y abre tu bandeja de entrada de correo electrónico!",

    /* TRANSLATE */
    "Get started": "Comienza",
    "Get started for Free": "Comienza gratis",
    "Continue with": "Continuar con",
    "By registering you confirm that you have read and understood our": "Al registrarse, confirma que ha leído y comprendido nuestro",
    "the": "la",
    "privacy policy": "política de privacidad",
    "terms and conditions": "términos y condiciones",
    "cookie policy": "política de cookies",
}
export const AuthTranslationsFR = {
    "Ti diamo il benvenuto su": "Bienvenue chez",
    "Accedi/Iscriviti con": "Connectez-vous/enregistrez-vous avec:",
    "Email e password": "Email / password",
    "Password": "Mot de passe",
    "Link magico": "Lien magique",
    "Richiedi un link magico": "Demander un lien magique",

    "Inserisci qui la tua email, una volta premuto il pulsante riceverai un link alla tua casella email che ti permetterà di accedere a Reasy in un attimo.": "Entrez votre adresse e-mail ici, une fois que vous aurez appuyé sur le bouton, vous recevrez un lien dans votre boîte e-mail qui vous permettra d'accéder à Reasy en un rien de temps.",
    "Inserisci email e password per registrarti": "Entrez une adresse électronique et un mot de passe pour vous inscrire",
    "o": "ou",

    "Registrazione": "Enregistrer",
    "Email": "Adresse e-mail",

    "Conferma la email": "Confirmez l'e-mail.",
    "Stiamo preparando il tuo ambiente di studio": "Nous préparons votre environnement d'étude.",
    "Hai aperto la email di conferma da un dispositivo diverso da quello da cui hai richiesto l'accesso.": "Vous avez ouvert l'e-mail de confirmation à partir d'un appareil différent de celui depuis lequel vous avez demandé l'accès.",
    "Inserisci la email qui sotto se vuoi accedere da questo dispositivo": "Entrez l'e-mail ci-dessous si vous souhaitez accéder depuis cet appareil.",
    "Link non valido, richiedine uno nuovo nella": "Lien invalide, demandez-en un nouveau sur la",
    "pagina iniziale": "page d'accueil.",

    "Ho letto e accetto la ": "J'ai lu et j'accepte les",
    " e i ": " et le ",
    "termini di servizio": "conditions de service",

    "Voglio registrarmi": "Je veux m'inscrire",
    "Hai già un account? ": "Vous avez déjà un compte ?",
    "Accedi": "Se connecter",
    "Accedi a Reasy": "Accès Reasy",

    "Indietro": "Revenir",

    "Registrati": "Enregistrer",
    "Iniziamo": "Commençons",
    "Email non valida": "Email non valide",
    "La password deve essere di almeno 7 caratteri": "Le mot de passe doit contenir au moins 7 caractères",
    "Devi prima accettare i termini per la privacy": "Vous devez d’abord accepter les conditions de confidentialité",

    "Non hai un account?": "Vous n’avez pas de compte ?",
    "Ho dimenticato la password": "J'ai oublié mon mot de passe",

    "Reset password": "Reset password",
    "Email di reset password inviata.": "Reset password email sent French",
    "Recupero della password": "Récupération du mot de passe",
    "Inviami la email di ripristino": "Envoyez-moi l'email de réinitialisation",
    "Ti abbiamo inviato una mail di conferma a": "Nous vous avons envoyé un e-mail de confirmation à",

    "Su quale dispositivo vuoi effettuare l'accesso?": "Sur quel appareil souhaitez-vous vous connecter ?",
    "Accedi da questo dispositivo": "Connectez-vous depuis cet appareil",
    "Accedi da un altro dispositivo": "Connectez-vous depuis un autre appareil",

    "Scansiona questo QR Code dal dispositivo che vuoi usare su Reading Easy.": "Scannez ce code QR à partir de l’appareil que vous souhaitez utiliser sur Reading Easy.",

    "Abbiamo inviato il link alla email da te inserita": "Nous avons envoyé le lien à l'adresse e-mail que vous avez saisie.",
    "Vai ad aprire la tua casella email!": "Allez ouvrir votre boîte de réception e-mail !",


    "Get started": "Démarrer",
    "Get started for Free": "Démarrer gratuitement",
    "Continue with": "Continuer avec",
    "By registering you confirm that you have read and understood our": "En vous inscrivant, vous confirmez avoir lu et compris notre",
    "the": "la",
    "privacy policy": "politique de confidentialité",
    "terms and conditions": "termes et conditions",
    "cookie policy": "politique de cookies",

}
export const AuthTranslationsDE = {
    "Ti diamo il benvenuto su": "Wir heißen Sie willkommen auf",
    "Accedi/Iscriviti con": "Anmelden/Registrieren mit:",
    "Email e password": "Email / password",
    "Link magico": "Magischer Link",
    "Richiedi un link magico": "Fordern Sie einen magischen Link an",

    "o": "oder",

    "Inserisci qui la tua email, una volta premuto il pulsante riceverai un link alla tua casella email che ti permetterà di accedere a Reasy in un attimo.": "Geben Sie hier Ihre E-Mail-Adresse ein. Nach dem Drücken der Taste erhalten Sie einen Link in Ihrem E-Mail-Postfach, der es Ihnen ermöglicht, sich sofort bei Reasy anzumelden.",
    "Inserisci email e password per registrarti": "Geben Sie E-Mail und Passwort ein, um sich zu registrieren",

    "Registrazione": "Registrierung",
    "Email": "E-Mail",
    "Ho letto e accetto la ": "Ich habe dieIch habe die ",
    " e i ": " und die ",

    "termini di servizio": "Nutzungsbedingungen",
    "Voglio registrarmi": "Ich möchte mich registrieren",
    "Hai già un account? ": "Haben Sie bereits ein Konto? ",
    "Accedi": "Anmelden",
    "Accedi a Reasy": "Bei Reasy anmelden",

    "Conferma la email": " Bestätigen Sie die E-Mail.",
    "Stiamo preparando il tuo ambiente di studio": "Wir bereiten Ihre Lernumgebung vor.",
    "Hai aperto la email di conferma da un dispositivo diverso da quello da cui hai richiesto l'accesso.": " Sie haben die Bestätigungs-E-Mail von einem anderen Gerät geöffnet als dem, von dem aus Sie den Zugang angefordert haben.",
    "Inserisci la email qui sotto se vuoi accedere da questo dispositivo": "Geben Sie die E-Mail unten ein, wenn Sie von diesem Gerät aus zugreifen möchten.",
    "Confermo la mia email": "ch bestätige meine E-Mail.",
    "Link non valido, richiedine uno nuovo nella": "Ungültiger Link, fordern Sie einen neuen auf der",
    "pagina iniziale": "Startseite an.",

    "Indietro": "Zurück",

    "Iniziamo": "Lassen Sie uns beginnen",
    "Email non valida": "Ungültige E-Mail",
    "La password deve essere di almeno 7 caratteri": "Das Passwort muss mindestens 7 Zeichen lang sein",
    "Devi prima accettare i termini per la privacy": "Sie müssen zuerst die Datenschutzbestimmungen akzeptieren",

    "Non hai un account?": "Sie haben noch kein Konto?",
    "Registrati": "Registrieren",
    "Ho dimenticato la password": "Ich habe mein Passwort vergessen",

    "Reset password": "Passwort zurücksetzen",
    "Email di reset password inviata.": " E-Mail zum Zurücksetzen des Passworts gesendet",
    "Recupero della password": "Passwort-Wiederherstellung",
    "Inviami la email di ripristino": "Senden Sie mir eine Wiederherstellungs-E-Mail",
    "Ti abbiamo inviato una mail di conferma a": "Wir haben Ihnen eine Bestätigungs-E-Mail gesendet an",

    "Su quale dispositivo vuoi effettuare l'accesso?": "Auf welchem Gerät möchten Sie sich anmelden?",
    "Accedi da questo dispositivo": "Von diesem Gerät aus anmelden",
    "Accedi da un altro dispositivo": "Von einem anderen Gerät aus anmelden",

    "Scansiona questo QR Code dal dispositivo che vuoi usare su Reading Easy.": "Scannen Sie diesen QR-Code mit dem Gerät, das Sie für Reading Easy verwenden möchten.",

    "Abbiamo inviato il link alla email da te inserita": " Wir haben den Link an die von Ihnen eingegebene E-Mail-Adresse gesendet.",
    "Vai ad aprire la tua casella email!": "Geh und öffne dein E-Mail-Postfach!",

    "Get started": "Loslegen",
    "Get started for Free": "Kostenlos loslegen",
    "Continue with": "Fortfahren mit",
    "By registering you confirm that you have read and understood our": "Mit der Registrierung bestätigen Sie, dass Sie unsere gelesen und verstanden haben",
    "the": "die",
    "privacy policy": "Datenschutzrichtlinie",
    "terms and conditions": "Allgemeine Geschäftsbedingungen",
    "cookie policy": "Cookie-Richtlinie",
}

export const AuthTranslationsCA = {
    "Ti diamo il benvenuto su": "Us donem la benvinguda a",
    "Accedi/Iscriviti con": "Inicia/suscriu-te amb",
    "Email e password": "Email / password",
    "Link magico": "Enllaç màgic",
    "Richiedi un link magico": "Demanar un enllaç màgic",
    "o": "o",
    "Inserisci qui la tua email, una volta premuto il pulsante riceverai un link alla tua casella email che ti permetterà di accedere a Reasy in un attimo.": "Introdueix aquí el teu correu electrònic. En prémer el botó, rebràs un enllaç a la teva safata de correu que et permetrà accedir a Reasy en un instant.",
    "Inserisci email e password per registrarti": "Introdueix el teu correu electrònic i contrasenya per registrar-te.",

    "Registrazione": "Registre",
    "Email": "Correu electrònic",
    "Ho letto e accetto la ": "He llegit i accepto el ",
    " e i ": " i els ",

    "termini di servizio": "termes de servei",
    "Voglio registrarmi": "Vull registrar-me",
    "Hai già un account? ": "Ja tens un compte? ",
    "Accedi": "Inicia la sessió",
    "Accedi a Reasy": "Inicia la sessió a Reasy",

    "Conferma la email": "Confirma el correu electrònic",
    "Stiamo preparando il tuo ambiente di studio": "Estem preparant el teu entorn d'estudi",
    "Hai aperto la email di conferma da un dispositivo diverso da quello da cui hai richiesto l'accesso.": "Has obert el correu electrònic de confirmació des d'un dispositiu diferent del que has sol·licitat l'accés.",
    "Inserisci la email qui sotto se vuoi accedere da questo dispositivo": "Introdueix el correu electrònic aquí sota si vols accedir des d'aquest dispositiu",
    "Confermo la mia email": "Confirmo el meu correu electrònic.",
    "Link non valido, richiedine uno nuovo nella": "Enllaç no vàlid, demana'n un de nou a la",
    "pagina iniziale": "pàgina inicial",
    "Indietro": "Enrere",

    "Iniziamo": "Comencem",
    "Email non valida": "Correu electrònic no vàlid",
    "La password deve essere di almeno 7 caratteri": "La contrasenya ha de tenir com a mínim 7 caràcters.",
    "Devi prima accettare i termini per la privacy": "Has d'acceptar primer els termes de privadesa.",

    "Non hai un account?": "No tens un compte?",
    "Registrati": "Registra't",
    "Ho dimenticato la password": "He oblidat la contrasenya",

    "Reset password": "Restablir contrasenya",
    "Email di reset password inviata.": "S'ha enviat un correu electrònic per restablir la contrasenya.",
    "Recupero della password": "Recuperació de contrasenya",
    "Inviami la email di ripristino": "Envia'm el correu electrònic de restabliment",
    "Ti abbiamo inviato una mail di conferma a": "Et hem enviat un correu electrònic de confirmació a",

    "Su quale dispositivo vuoi effettuare l'accesso?": "A quin dispositiu vols accedir?",
    "Accedi da questo dispositivo": "Accedeix des d'aquest dispositiu",
    "Accedi da un altro dispositivo": "Accedeix des d'un altre dispositiu",

    "Abbiamo inviato il link alla email da te inserita": "Hem enviat l'enllaç al correu electrònic que has proporcionat.",
    "Vai ad aprire la tua casella email!": "Vés a obrir la teva safata de correu electrònic!",


    "Get started": "Comença",
    "Get started for Free": "Comença gratis",
    "Continue with": "Continuar amb",
    "By registering you confirm that you have read and understood our": "En registrar-vos, confirmeu que heu llegit i comprès el nostre",
    "the": "la",
    "privacy policy": "política de privacitat",
    "terms and conditions": "termes i condicions",
    "cookie policy": "política de galetes",


}
export const AuthTranslationsPT = {
    "Ti diamo il benvenuto su": "Damos as boas-vindas a",
    "Accedi/Iscriviti con": "Entrar/Inscrever-se com",
    "Email e password": "Email e senha",
    "Link magico": "Link mágico",
    "Richiedi un link magico": "Solicitar um link mágico",
    "o": "ou",
    "Inserisci qui la tua email, una volta premuto il pulsante riceverai un link alla tua casella email che ti permetterà di accedere a Reasy in un attimo.": "Insira aqui seu email. Ao pressionar o botão, você receberá um link em sua caixa de entrada que permitirá acessar o Reasy instantaneamente.",
    "Inserisci email e password per registrarti": "Insira email e senha para se inscrever.",

    "Registrazione": "Registro",
    "Email": "Email",
    "Ho letto e accetto la ": "Li e aceito os ",
    " e i ": " e os ",

    "termini di servizio": "termos de serviço",
    "Voglio registrarmi": "Quero me registrar",
    "Hai già un account? ": "Já tem uma conta? ",
    "Accedi": "Entrar",
    "Accedi a Reasy": "Entrar no Reasy",

    "Conferma la email": "Confirme o email",
    "Stiamo preparando il tuo ambiente di studio": "Estamos preparando seu ambiente de estudo",
    "Hai aperto la email di conferma da un dispositivo diverso da quello da cui hai richiesto l'accesso.": "Você abriu o email de confirmação de um dispositivo diferente daquele em que solicitou o acesso.",
    "Inserisci la email qui sotto se vuoi accedere da questo dispositivo": "Insira o email abaixo se quiser acessar deste dispositivo",
    "Confermo la mia email": "Confirmar meu email.",
    "Link non valido, richiedine uno nuovo nella": "Link inválido, solicite um novo no",
    "pagina iniziale": "página inicial",
    "Indietro": "Voltar",

    "Iniziamo": "Vamos começar",
    "Email non valida": "Email inválido",
    "La password deve essere di almeno 7 caratteri": "A senha deve ter pelo menos 7 caracteres.",
    "Devi prima accettare i termini per la privacy": "Você deve aceitar os termos de privacidade primeiro.",

    "Non hai un account?": "Não tem uma conta?",
    "Registrati": "Cadastre-se",
    "Ho dimenticato la password": "Esqueci minha senha",

    "Reset password": "Redefinir senha",
    "Email di reset password inviata.": "Email de redefinição de senha enviado.",
    "Recupero della password": "Recuperação de senha",
    "Inviami la email di ripristino": "Enviar email de recuperação",
    "Ti abbiamo inviato una mail di conferma a": "Enviamos um email de confirmação para",

    "Su quale dispositivo vuoi effettuare l'accesso?": "Em qual dispositivo deseja acessar?",
    "Accedi da questo dispositivo": "Acessar deste dispositivo",
    "Accedi da un altro dispositivo": "Acessar de outro dispositivo",

    "Abbiamo inviato il link alla email da te inserita": "Enviamos o link para o email fornecido por você.",
    "Vai ad aprire la tua casella email!": "Vá abrir sua caixa de entrada de email!",


    "Get started": "Comece",
    "Get started for Free": "Comece gratuitamente",
    "Continue with": "Continuar com",
    "By registering you confirm that you have read and understood our": "Ao se registrar, você confirma que leu e compreendeu nosso",
    "the": "a",
    "privacy policy": "política de privacidade",
    "terms and conditions": "termos e condições",
    "cookie policy": "política de cookies",

}
export const AuthTranslationsCZ = {
    "Ti diamo il benvenuto su": "Vítejte na našem webu!",
    "Accedi/Iscriviti con": "Přihlásit/Registrovat se pomocí:",
    "Email e password": "E-mail a heslo",
    "Link magico": "Magický odkaz",
    "Richiedi un link magico": "Požádat o magický odkaz",

    "o": "nebo",

    "Inserisci qui la tua email, una volta premuto il pulsante riceverai un link alla tua casella email che ti permetterà di accedere a Reasy in un attimo.": "Zadejte zde svůj e-mail. Po kliknutí na tlačítko obdržíte odkaz do své e-mailové schránky, který vás okamžitě přihlásí do Reasy.",
    "Inserisci email e password per registrarti": "Zadejte e-mail a heslo pro registraci",

    "Registrazione": "Registrace",
    "Email": "E-mail",
    "Ho letto e accetto la ": "Souhlasím s ",
    " e i ": " a s ",
    "termini di servizio": "podmínkami služby",
    "Voglio registrarmi": "Chci se zaregistrovat",
    "Hai già un account? ": "Už máte účet? ",
    "Accedi": "Přihlásit se",
    "Accedi a Reasy": "Přihlásit se do Reasy",

    "Conferma la email": "Potvrďte e-mail",
    "Stiamo preparando il tuo ambiente di studio": "Připravujeme váš studijní prostředí",
    "Hai aperto la email di conferma da un dispositivo diverso da quello da cui hai richiesto l'accesso.": "Otevřeli jste potvrzovací e-mail z jiného zařízení než toho, ze kterého jste požádali o přihlášení.",
    "Inserisci la email qui sotto se vuoi accedere da questo dispositivo": "Zadejte e-mail níže, pokud chcete přihlásit z tohoto zařízení",
    "Link non valido, richiedine uno nuovo nella": "Neplatný odkaz, požádejte o nový na ",
    "pagina iniziale": "domovské stránce",

    "Indietro": "Zpět",

    "Iniziamo": "Začněme",
    "Email non valida": "Neplatný e-mail",
    "La password deve essere di almeno 7 caratteri": "Heslo musí mít alespoň 7 znaků",
    "Devi prima accettare i termini per la privacy": "Nejprve musíte přijmout podmínky ochrany osobních údajů",

    "Non hai un account?": "Nemáte účet?",
    "Registrati": "Zaregistrujte se",
    "Ho dimenticato la password": "Zapomněl jsem heslo",

    "Reset password": "Obnovit heslo",
    "Email di reset password inviata.": "E-mail s instrukcemi k obnovení hesla byl odeslán.",
    "Recupero della password": "Obnova hesla",
    "Inviami la email di ripristino": "Pošlete mi e-mail k obnovení hesla",
    "Ti abbiamo inviato una mail di conferma a": "Poslali jsme vám e-mail s potvrzením na",

    "Su quale dispositivo vuoi effettuare l'accesso?": "Na jakém zařízení chcete přihlásit?",
    "Accedi da questo dispositivo": "Přihlásit se z tohoto zařízení",
    "Accedi da un altro dispositivo": "Přihlásit se z jiného zařízení",

    "Scansiona questo QR Code dal dispositivo che vuoi usare su Reading Easy.": "Naskenujte tento QR kód ze zařízení, které chcete použít na čtení",

    "Abbiamo inviato il link alla email da te inserita": "Odkaz byl zaslán na váš e-mail",
    "Vai ad aprire la tua casella email!": "Otevřete svou e-mailovou schránku!",


    "Get started": "Začít",
    "Get started for Free": "Začněte zdarma",
    "Continue with": "Pokračovat s",
    "By registering you confirm that you have read and understood our": "Registrací potvrzujete, že jste si přečetli a porozuměli našim",
    "the": "ta",
    "privacy policy": "zásady ochrany osobních údajů",
    "terms and conditions": "obchodní podmínky",
    "cookie policy": "zásady používání cookies",

}
export const AuthTranslationsNL = {
    "Ti diamo il benvenuto su": "Welkom bij",
    "Accedi/Iscriviti con": "Inloggen/Aanmelden met:",
    "Email e password": "E-mail en wachtwoord",
    "Link magico": "Magische link",
    "Richiedi un link magico": "Vraag een magische link aan",

    "o": "of",

    "Inserisci qui la tua email, una volta premuto il pulsante riceverai un link alla tua casella email che ti permetterà di accedere a Reasy in un attimo.": "Voer hier je e-mailadres in. Zodra je op de knop drukt, ontvang je een link naar je e-mailinbox waarmee je binnen no-time toegang krijgt tot Reasy.",
    "Inserisci email e password per registrarti": "Voer je e-mailadres en wachtwoord in om je te registreren",

    "Registrazione": "Registratie",
    "Email": "E-mail",
    "Ho letto e accetto la ": "Ik heb gelezen en accepteer de ",
    " e i ": " en de ",

    "termini di servizio": "servicevoorwaarden",
    "Voglio registrarmi": "Ik wil me registreren",
    "Hai già un account? ": "Heb je al een account? ",
    "Accedi": "Inloggen",
    "Accedi a Reasy": "Toegang tot Reasy",

    "Conferma la email": "Bevestig je e-mail",
    "Stiamo preparando il tuo ambiente di studio": "We bereiden je studieomgeving voor",
    "Hai aperto la email di conferma da un dispositivo diverso da quello da cui hai richiesto l'accesso.": "Je hebt de bevestigingsmail geopend vanaf een ander apparaat dan waarop je toegang hebt aangevraagd.",
    "Inserisci la email qui sotto se vuoi accedere da questo dispositivo": "Voer hieronder je e-mailadres in als je toegang wilt krijgen vanaf dit apparaat",
    "Link non valido, richiedine uno nuovo nella": "Ongeldige link, vraag een nieuwe aan op de",
    "pagina iniziale": "startpagina",

    "Indietro": "Terug",

    "Iniziamo": "Laten we beginnen",
    "Email non valida": "Ongeldige e-mail",
    "La password deve essere di almeno 7 caratteri": "Het wachtwoord moet minimaal 7 tekens bevatten",
    "Devi prima accettare i termini per la privacy": "Je moet eerst de privacyvoorwaarden accepteren",

    "Non hai un account?": "Heb je geen account?",
    "Registrati": "Registreren",
    "Ho dimenticato la password": "Wachtwoord vergeten",

    "Reset password": "Wachtwoord opnieuw instellen",
    "Email di reset password inviata.": "E-mail voor het opnieuw instellen van het wachtwoord verzonden.",
    "Recupero della password": "Wachtwoordherstel",
    "Inviami la email di ripristino": "Stuur me de herstel e-mail",
    "Ti abbiamo inviato una mail di conferma a": "We hebben een bevestigingsmail naar je gestuurd op",

    "Su quale dispositivo vuoi effettuare l'accesso?": "Vanaf welk apparaat wil je inloggen?",
    "Accedi da questo dispositivo": "Inloggen op dit apparaat",
    "Accedi da un altro dispositivo": "Inloggen op een ander apparaat",

    "Scansiona questo QR Code dal dispositivo che vuoi usare su Reading Easy.": "Scan deze QR-code met het apparaat dat je wilt gebruiken voor Reading Easy.",

    "Abbiamo inviato il link alla email da te inserita": "We hebben de link naar het opgegeven e-mailadres gestuurd",
    "Vai ad aprire la tua casella email!": "Ga naar je e-mailinbox om te openen!",


    "Get started": "Aan de slag",
    "Get started for Free": "Gratis aan de slag",
    "Continue with": "Doorgaan met",
    "By registering you confirm that you have read and understood our": "Door u te registreren bevestigt u dat u onze hebt gelezen en begrepen",
    "the": "het",
    "privacy policy": "privacybeleid",
    "terms and conditions": "algemene voorwaarden",
    "cookie policy": "cookiebeleid",
}
import axios, { AxiosResponse } from "axios";
import { backendApi, backendApiDemo } from "../../../../common/apiService";
import { Audiobook } from "../../../audiobooks/store/reducers";
import { ConceptualMap } from "../../../maps/store/reducers";

export async function createConceptualMap(token: string,language:string, session_id?: string, prompt?: string,map_json?:{}) {
	try {
		const response: AxiosResponse = await backendApi.post(
			`/resources/maps`,
			{
				study_session_id: session_id,
				content: map_json,
				prompt: prompt,
				language: language,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		if (response.status === 201) {
            console.log("[createConceptualMap]Creation Successful:", response.status);
			return response.data;
		} else {
			console.error("[createConceptualMap]Unexpected error:", response.status);
			return false
		}
	} catch (error) {
		// Handle errors
		if (axios.isAxiosError(error)) {
			console.error("[createConceptualMap]Error creating conceptual map:", error.response?.data || error.message);
			return false
		} else {
			console.error("[createConceptualMap]Unexpected error:", error);
		}
	}
}





export function deleteConceptualMap(map_id:string,token: string) {
	return new Promise<ConceptualMap>(async (resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let conceptual_map_obj: ConceptualMap;
		backendApi
			.delete(`/resources/maps/${map_id}`, config)
			.then((response) => {
				if (response.status === 200) {
                    console.log("[deleteConceptualMap]Deletion successful")
					conceptual_map_obj = response.data.audiobook;
					resolve(conceptual_map_obj);
				} else {
					reject(new Error(`[deleteConceptualMap]Request failed with status ${response.status}`));
				}
			})
			.catch((error) => {
				console.error(`[deleteConceptualMap]Error deleting conceptual maps`, error);
				reject(error);
			});
	});
}
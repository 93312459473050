export const SessionsTranslationsEN = {
    "Le tue sessioni": "Your sessions",
    "Sto cercando le tue sessioni...": "Fetching your sessions...",
    "Cerca per titolo": "Search by title",

    "Oggi": "Today",
    "Ieri": "Yesterday",
    "Questa settimana": "This week",
    "Settimana scorsa": "Last week",
    "Questo mese": "This month",
    "Sessioni più vecchie": "Older sessions",

    "Che argomento stai studiando?": "What topic are you studying?",
    "Che nuovo argomento vuoi studiare?": "What topic would you like to study?",
    "Hai ancora": "You still have",
    "Sessioni disponibili ✨": "available Sessions ✨",
    "Sessioni disponibili questo mese ✨": "available Sessions this month✨",


    "L'Impero romano": "The Roman Empire",
    "La mitosi cellulare": "The cell mitosis",
    "La geografia dell'Italia": "The geography of Italy",
    "Astrofisica: Buchi neri": "Astrophysics: Black holes",

    'Esempio "Il Rinascimento italiano"': 'e.g. "The Italian Renaissance"', //search translation

    "Digita l'argomento per ottenere:": "Type in the topic to get:",
    "mappa concettuale": "conceptual map",
    "riassunto": "summary",
    "parole chiave": "keywords",
    "audiolibro": "audiobook",

    "oppure carica 📤 un documento": "or upload 📤 a file",

    "Torna indietro": "Go back",
    "Rimuovi risorsa": "Delete resource",
    "Elimina sessione": "Delete session",
    "Annulla": "Cancel",
    "Mappa": "Map",
    "Riassunto": "Summary",
    "Parole chiave": "Keywords",
    "Flashcards": "Flashcards",
    "Audiolibro": "Audiobook",

    "Inizia a studiare": "Start studying ",

    "Utilizzi rimanenti": "Remaining uses",
    "Sessioni di studio": "Study sessions",
    "Documenti": "Files",
    "Mappe": "Maps",
    "Riassunti": "Summaries",
    "Parole chiave": "Keywords",
    "Flashcards": "Flashcards",
    "Audiolibri": "Audiobooks",
    "minuti": "minutes",

    "Utilizzi rimanenti": "Remaining uses",
    "Sessioni di studio": "Study sessions",
    "Documenti": "Documents",
    "Mappe": "Maps",
    "Riassunti": "Summaries",
    "Parole chiave": "Keywords",
    "Flashcards": "Flashcards",
    "Audiolibri": "Audiobooks",
    "minuti": "minutes",

    "Schede didattiche di memorizzazione": "Learning Flashcards",

    "Vuoi davvero eliminare la sessione?": "Are you sure you want to delete the session?",
    "Vuoi davvero rimuovere l'elemento?": "Are you sure you want to remove the item?",
    "Se si conferma, ad eliminazione avvenuta sarai reindirizzato alla pagina principale e non potrai recuperare i dati.": "If confirmed, after deletion you will be redirected to the main page and will not be able to recover the data.",
    "Se si conferma, ad eliminazione avvenuta non potrai recuperare la risorsa.": "If confirmed, after deletion you will not be able to recover the resource.",

}
export const SessionsTranslationsIT = {
    "Le tue sessioni": "Le tue sessioni",
    "Sto cercando le tue sessioni...": "Sto cercando le tue sessioni...",
    "Cerca per titolo": "Cerca per titolo",

    "Oggi": "Oggi",
    "Ieri": "Ieri",
    "Questa settimana": "Questa settimana",
    "Settimana scorsa": "Settimana scorsa",
    "Questo mese": "Questo mese",
    "Sessioni più vecchie": "Sessioni più vecchie",

    "Che argomento stai studiando?": "Che argomento stai studiando?",
    "Che nuovo argomento vuoi studiare?": "Che nuovo argomento vuoi studiare?",

    "Hai ancora": "Hai ancora",
    "Sessioni disponibili ✨": "Sessioni disponibili ✨",
    "Sessioni disponibili questo mese ✨": "Sessioni disponibili questo mese ✨",

    "L'Impero romano": "L'impero romano",
    "La mitosi cellulare": "La mitosi cellulare",
    "La geografia dell'Italia": "La geografia dell'Italia",
    "Astrofisica: Buchi neri": "Astrofisica: Buchi neri",

    'Esempio "Il Rinascimento italiano"': 'Esempio "Il Rinascimento italiano"',

    "Digita l'argomento per ottenere:": "Digita l'argomento per ottenere:",
    "mappa concettuale": "mappa concettuale",
    "riassunto": "riassunto",
    "parole chiave": "parole chiave",
    "audiolibro": "audiolibro",

    "oppure carica 📤 un documento": "oppure carica 📤 un documento",

    "Torna indietro": "Torna indietro",
    "Rimuovi risorsa": "Rimuovi risorsa",
    "Elimina sessione": "Elimina sessione",
    "Annulla": "Annulla",
    "Mappa": "Mappa",
    "Riassunto": "Riassunto",
    "Parole chiave": "Parole chiave",
    "Flashcards": "Flashcards",
    "Audiolibro": "Audiolibro",

    "Inizia a studiare": "Inizia a studiare",

    "Utilizzi rimanenti": "Utilizzi rimanenti",
    "Sessioni di studio": "Sessioni di studio",
    "Documenti": "Documenti",
    "Mappe": "Mappe",
    "Riassunti": "Riassunti",
    "Flashcards": "Flashcards",
    "Audiolibri": "Audiolibri",
    "minuti": "minuti",

    "Schede didattiche di memorizzazione": "Schede didattiche di memorizzazione",

    "Vuoi davvero eliminare la sessione?": "Vuoi davvero eliminare la sessione?",
    "Vuoi davvero rimuovere l'elemento?": "Vuoi davvero rimuovere l'elemento?",
    "Se si conferma, ad eliminazione avvenuta sarai reindirizzato alla pagina principale e non potrai recuperare i dati.": "Se si conferma, ad eliminazione avvenuta sarai reindirizzato alla pagina principale e non potrai recuperare i dati.",
    "Se si conferma, ad eliminazione avvenuta non potrai recuperare la risorsa.": "Se si conferma, ad eliminazione avvenuta non potrai recuperare la risorsa."
}
export const SessionsTranslationsES = {
    "Le tue sessioni": "Tus sesiones",
    "Sto cercando le tue sessioni...": "Buscando tus sesiones...",
    "Cerca per titolo": "Buscar por título",

    "Oggi": "Hoy",
    "Ieri": "Ayer",
    "Questa settimana": "Esta semana",
    "Settimana scorsa": "La semana pasada",
    "Questo mese": "Este mes",
    "Sessioni più vecchie": "Sesiones anteriores",

    "Che argomento stai studiando?": "¿Qué tema estás estudiando?",
    "Che nuovo argomento vuoi studiare?": "¿Qué tema te gustaría estudiar?",
    "Hai ancora": "Aún tienes",
    "Sessioni disponibili ✨": "sesiones disponibles ✨",
    "Sessioni disponibili questo mese ✨": "sesiones disponibles este mes ✨",

    "L'Impero romano": "El Imperio Romano",
    "La mitosi cellulare": "La mitosis celular",
    "La geografia dell'Italia": "La geografía de Italia",
    "Astrofisica: Buchi neri": "Astrofísica: Agujeros negros",

    "Esempio \"Il Rinascimento italiano\"": "Ejemplo \"El Renacimiento italiano\"",

    "Digita l'argomento per ottenere:": "Escribe el tema para obtener:",
    "mappa concettuale": "mapa conceptual",
    "riassunto": "resumen",
    "parole chiave": "palabras clave",
    "audiolibro": "audiolibro",

    "oppure carica 📤 un documento": "o carga 📤 un documento",

    "Torna indietro": "Volver",
    "Rimuovi risorsa": "Eliminar recurso",
    "Elimina sessione": "Borrar sesión",
    "Annulla": "Cancelar",
    "Mappa": "Mapa",
    "Riassunto": "Resumen",
    "Parole chiave": "Palabras clave",
    "Flashcards": "Tarjetas de memoria",
    "Audiolibro": "Audiolibro",

    "Inizia a studiare": "Comienza a estudiar",

    "Utilizzi rimanenti": "Usos restantes",
    "Sessioni di studio": "Sesiones de estudio",
    "Documenti": "Documentos",
    "Mappe": "Mapas",
    "Riassunti": "Resúmenes",
    "Parole chiave": "Palabras clave",
    "Flashcards": "Tarjetas de memoria",
    "Audiolibri": "Audiolibros",
    "minuti": "minutos",

    "Schede didattiche di memorizzazione": "Fichas de memorización",

    "Vuoi davvero eliminare la sessione?": "¿Estás seguro de que quieres eliminar la sesión?",
    "Vuoi davvero rimuovere l'elemento?": "¿Estás seguro de que quieres eliminar el elemento?",
    "Se si conferma, ad eliminazione avvenuta sarai reindirizzato alla pagina principale e non potrai recuperare i dati.": "Si se confirma, después de la eliminación serás redirigido a la página principal y no podrás recuperar los datos.",
    "Se si conferma, ad eliminazione avvenuta non potrai recuperare la risorsa.": "Si se confirma, después de la eliminación no podrás recuperar el recurso."

}

export const SessionsTranslationsFR = {
    "Le tue sessioni": "Vos sessions",
    "Sto cercando le tue sessioni...": "Recherche de vos sessions...",
    "Cerca per titolo": "Recherche par titre",
    "Oggi": "Aujourd'hui",
    "Ieri": "Hier",
    "Questa settimana": "Cette semaine",
    "Settimana scorsa": "La semaine dernière",
    "Questo mese": "Ce mois-ci",
    "Sessioni più vecchie": "Sessions plus anciennes",
    "Che argomento stai studiando?": "Quel sujet étudiez-vous ?",
    "Che nuovo argomento vuoi studiare?": "Quel sujet souhaitez-vous étudier ?",
    "Hai ancora": "Il vous reste encore",
    "Sessioni disponibili ✨": "sessions disponibles ✨",
    "Sessioni disponibili questo mese ✨": "sessions disponibles ce mois-ci ✨",
    "L'Impero romano": "L'Empire romain",
    "La mitosi cellulare": "La mitose cellulaire",
    "La geografia dell'Italia": "La géographie de l'Italie",
    "Astrofisica: Buchi neri": "Astrophysique : Trous noirs",
    "Esempio \"Il Rinascimento italiano\"": "Exemple \"La Renaissance italienne\"",
    "Digita l'argomento per ottenere:": "Tapez le sujet pour obtenir :",
    "mappa concettuale": "carte conceptuelle",
    "riassunto": "résumé",
    "parole chiave": "mots-clés",
    "audiolibro": "livre audio",
    "oppure carica 📤 un documento": "ou téléchargez 📤 un document",
    "Torna indietro": "Retour",
    "Rimuovi risorsa": "Supprimer la ressource",
    "Elimina sessione": "Supprimer la session",
    "Annulla": "Annuler",
    "Mappa": "Carte",
    "Riassunto": "Résumé",
    "Parole chiave": "Mots-clés",
    "Flashcards": "Cartes mémoire",
    "Audiolibro": "Livre audio",
    "Inizia a studiare": "Commencez à étudier",
    "Utilizzi rimanenti": "Utilisations restantes",
    "Sessioni di studio": "Sessions d'étude",
    "Documenti": "Documents",
    "Mappe": "Cartes",
    "Riassunti": "Résumés",
    "Flashcards": "Cartes mémoire",
    "Audiolibri": "Livres audio",
    "minuti": "minutes",

    "Schede didattiche di memorizzazione": "Fiches pédagogiques de mémorisation",

    "Vuoi davvero eliminare la sessione?": "Êtes-vous sûr de vouloir supprimer la session ?",
    "Vuoi davvero rimuovere l'elemento?": "Êtes-vous sûr de vouloir supprimer l'élément ?",
    "Se si conferma, ad eliminazione avvenuta sarai reindirizzato alla pagina principale e non potrai recuperare i dati.": "Si confirmé, après la suppression, vous serez redirigé vers la page principale et vous ne pourrez pas récupérer les données.",
    "Se si conferma, ad eliminazione avvenuta non potrai recuperare la risorsa.": "Si confirmé, après la suppression, vous ne pourrez pas récupérer la ressource."


}

export const SessionsTranslationsDE = {
    "Le tue sessioni": "Ihre Sitzungen",
    "Sto cercando le tue sessioni...": "Ihre Sitzungen werden abgerufen...",
    "Cerca per titolo": "Nach Titel suchen",
    "Oggi": "Heute",
    "Ieri": "Gestern",
    "Questa settimana": "Diese Woche",
    "Settimana scorsa": "Letzte Woche",
    "Questo mese": "Diesen Monat",
    "Sessioni più vecchie": "Ältere Sitzungen",
    "Che argomento stai studiando?": "Welches Thema studieren Sie?",
    "Che nuovo argomento vuoi studiare?": "Welches Thema möchten Sie studieren?",
    "Hai ancora": "Sie haben noch",
    "Sessioni disponibili ✨": "verfügbare Sitzungen ✨",
    "Sessioni disponibili questo mese ✨": "verfügbare Sitzungen diesen Monat ✨",
    "L'Impero romano": "Das Römische Reich",
    "La mitosi cellulare": "Die Zellteilung",
    "La geografia dell'Italia": "Die Geographie Italiens",
    "Astrofisica: Buchi neri": "Astrophysik: Schwarze Löcher",
    "Esempio \"Il Rinascimento italiano\"": "Beispiel \"Die italienische Renaissance\"",
    "Digita l'argomento per ottenere:": "Geben Sie das Thema ein, um zu erhalten:",
    "mappa concettuale": "Konzeptkarte",
    "riassunto": "Zusammenfassung",
    "parole chiave": "Schlüsselwörter",
    "audiolibro": "Hörbuch",
    "oppure carica 📤 un documento": "oder laden Sie 📤 eine Datei hoch",
    "Torna indietro": "Zurückgehen",
    "Rimuovi risorsa": "Ressource löschen",
    "Elimina sessione": "Sitzung löschen",
    "Annulla": "Abbrechen",
    "Mappa": "Karte",
    "Riassunto": "Zusammenfassung",
    "Parole chiave": "Schlüsselwörter",
    "Flashcards": "Karteikarten",
    "Audiolibro": "Hörbuch",
    "Inizia a studiare": "Beginnen Sie zu lernen",
    "Utilizzi rimanenti": "Verbleibende Nutzungen",
    "Sessioni di studio": "Lernsitzungen",
    "Documenti": "Dokumente",
    "Mappe": "Karten",
    "Riassunti": "Zusammenfassungen",
    "Flashcards": "Karteikarten",
    "Audiolibri": "Hörbücher",
    "minuti": "Minuten",

    "Schede didattiche di memorizzazione": "Lernkarten zur Gedächtnisstütze",

    "Vuoi davvero eliminare la sessione?": "Möchten Sie die Sitzung wirklich löschen?",
    "Vuoi davvero rimuovere l'elemento?": "Möchten Sie das Element wirklich entfernen?",
    "Se si conferma, ad eliminazione avvenuta sarai reindirizzato alla pagina principale e non potrai recuperare i dati.": "Wenn bestätigt, werden Sie nach der Löschung zur Hauptseite weitergeleitet und können die Daten nicht wiederherstellen.",
    "Se si conferma, ad eliminazione avvenuta non potrai recuperare la risorsa.": "Wenn bestätigt, können Sie nach der Löschung die Ressource nicht wiederherstellen."
}

export const SessionsTranslationsCA = {
    "Le tue sessioni": "Les teves sessions",
    "Sto cercando le tue sessioni...": "Buscant les teves sessions...",
    "Cerca per titolo": "Cerca per títol",
    "Oggi": "Avui",
    "Ieri": "Ahir",
    "Questa settimana": "Aquesta setmana",
    "Settimana scorsa": "La setmana passada",
    "Questo mese": "Aquest mes",
    "Sessioni più vecchie": "Sessions més antigues",
    "Che argomento stai studiando?": "Quin tema estàs estudiant?",
    "Che nuovo argomento vuoi studiare?": "Quin tema t'agradaria estudiar?",
    "Hai ancora": "Encara tens",
    "Sessioni disponibili ✨": "sessions disponibles ✨",
    "Sessioni disponibili questo mese ✨": "sessions disponibles aquest mes ✨",
    "L'Impero romano": "L'Imperi Romà",
    "La mitosi cellulare": "La mitosi cel·lular",
    "La geografia dell'Italia": "La geografia d'Itàlia",
    "Astrofisica: Buchi neri": "Astrofísica: Forats negres",
    "Esempio \"Il Rinascimento italiano\"": "Exemple \"El Renaixement italià\"",
    "Digita l'argomento per ottenere:": "Escriu el tema per obtenir:",
    "mappa concettuale": "mapa conceptual",
    "riassunto": "resum",
    "parole chiave": "paraules clau",
    "audiolibro": "audiollibre",
    "oppure carica 📤 un documento": "o carrega 📤 un document",
    "Torna indietro": "Torna enrere",
    "Rimuovi risorsa": "Elimina recurs",
    "Elimina sessione": "Suprimir sessió",
    "Annulla": "Cancel·la",
    "Mappa": "Mapa",
    "Riassunto": "Resum",
    "Parole chiave": "Paraules clau",
    "Flashcards": "Flashcards",
    "Audiolibro": "Audiollibre",
    "Inizia a studiare": "Comença a estudiar",
    "Utilizzi rimanenti": "Usos restants",
    "Sessioni di studio": "Sessions d'estudi",
    "Documenti": "Documents",
    "Mappe": "Mapes",
    "Riassunti": "Resums",
    "Flashcards": "Flashcards",
    "Audiolibri": "Audiollibres",
    "minuti": "minuts",

    "Schede didattiche di memorizzazione": "Fitxes didàctiques de memorització",

    "Vuoi davvero eliminare la sessione?": "Segur que vols eliminar la sessió?",
    "Vuoi davvero rimuovere l'elemento?": "Segur que vols eliminar l'element?",
    "Se si conferma, ad eliminazione avvenuta sarai reindirizzato alla pagina principale e non potrai recuperare i dati.": "Si es confirma, després de l'eliminació seràs redirigit a la pàgina principal i no podràs recuperar les dades.",
    "Se si conferma, ad eliminazione avvenuta non potrai recuperare la risorsa.": "Si es confirma, després de l'eliminació no podràs recuperar el recurs."


}

export const SessionsTranslationsPT = {
    "Le tue sessioni": "Suas sessões",
    "Sto cercando le tue sessioni...": "Buscando suas sessões...",
    "Cerca per titolo": "Buscar por título",
    "Oggi": "Hoje",
    "Ieri": "Ontem",
    "Questa settimana": "Esta semana",
    "Settimana scorsa": "Semana passada",
    "Questo mese": "Este mês",
    "Sessioni più vecchie": "Sessões antigas",
    "Che argomento stai studiando?": "Qual tema você está estudando?",
    "Che nuovo argomento vuoi studiare?": "Qual tema você gostaria de estudar?",
    "Hai ancora": "Você ainda tem",
    "Sessioni disponibili ✨": "sessões disponíveis ✨",
    "Sessioni disponibili questo mese ✨": "sessões disponíveis este mês ✨",
    "L'Impero romano": "O Império Romano",
    "La mitosi cellulare": "A mitose celular",
    "La geografia dell'Italia": "A geografia da Itália",
    "Astrofisica: Buchi neri": "Astrofísica: Buracos negros",
    "Esempio \"Il Rinascimento italiano\"": "Exemplo \"O Renascimento italiano\"",
    "Digita l'argomento per ottenere:": "Digite o tema para obter:",
    "mappa concettuale": "mapa conceitual",
    "riassunto": "resumo",
    "parole chiave": "palavras-chave",
    "audiolibro": "audiolivro",
    "oppure carica 📤 un documento": "ou faça upload 📤 de um documento",
    "Torna indietro": "Voltar",
    "Rimuovi risorsa": "Excluir recurso",
    "Elimina sessione": "Eliminar a sessão",
    "Annulla": "Cancelar",
    "Mappa": "Mapa",
    "Riassunto": "Resumo",
    "Parole chiave": "Palavras-chave",
    "Flashcards": "Flashcards",
    "Audiolibro": "Audiolivro",
    "Inizia a studiare": "Começar a estudar",
    "Utilizzi rimanenti": "Usos restantes",
    "Sessioni di studio": "Sessões de estudo",
    "Documenti": "Documentos",
    "Mappe": "Mapas",
    "Riassunti": "Resumos",
    "Flashcards": "Flashcards",
    "Audiolibri": "Audiolivros",
    "minuti": "minutos",

    "Schede didattiche di memorizzazione": "Fichas didáticas de memorização",

    "Vuoi davvero eliminare la sessione?": "Tem certeza de que deseja excluir a sessão?",
    "Vuoi davvero rimuovere l'elemento?": "Tem certeza de que deseja remover o item?",
    "Se si conferma, ad eliminazione avvenuta sarai reindirizzato alla pagina principale e non potrai recuperare i dati.": "Se confirmado, após a exclusão, você será redirecionado para a página principal e não poderá recuperar os dados.",
    "Se si conferma, ad eliminazione avvenuta non potrai recuperare la risorsa.": "Se confirmado, após a exclusão, você não poderá recuperar o recurso."

}

export const SessionsTranslationsCZ = {
    "Le tue sessioni": "Vaše relace",
    "Sto cercando le tue sessioni...": "Načítám vaše relace...",
    "Cerca per titolo": "Vyhledat podle názvu",
    "Oggi": "Dnes",
    "Ieri": "Včera",
    "Questa settimana": "Tento týden",
    "Settimana scorsa": "Minulý týden",
    "Questo mese": "Tento měsíc",
    "Sessioni più vecchie": "Starší relace",
    "Che argomento stai studiando?": "Jaké téma studujete?",
    "Che nuovo argomento vuoi studiare?": "Jaké téma byste chtěli studovat?",
    "Hai ancora": "Máte ještě",
    "Sessioni disponibili ✨": "dostupné relace ✨",
    "Sessioni disponibili questo mese ✨": "dostupné relace tento měsíc ✨",
    "L'Impero romano": "Římská říše",
    "La mitosi cellulare": "Buněčné dělení",
    "La geografia dell'Italia": "Geografie Itálie",
    "Astrofisica: Buchi neri": "Astrofyzika: Černé díry",
    "Esempio \"Il Rinascimento italiano\"": "Příklad \"Italská renesance\"",
    "Digita l'argomento per ottenere:": "Zadejte téma k získání:",
    "mappa concettuale": "myšlenková mapa",
    "riassunto": "shrnutí",
    "parole chiave": "klíčová slova",
    "audiolibro": "audiokniha",
    "oppure carica 📤 un documento": "nebo nahrajte 📤 dokument",
    "Torna indietro": "Vrátit se zpět",
    "Rimuovi risorsa": "Odstranit zdroj",
    "Elimina sessione": "Odstranění relace",
    "Annulla": "Zrušit",
    "Mappa": "Mapa",
    "Riassunto": "Shrnutí",
    "Parole chiave": "Klíčová slova",
    "Flashcards": "Flashcards",
    "Audiolibro": "Audiokniha",
    "Inizia a studiare": "Začněte studovat",
    "Utilizzi rimanenti": "Zbývající použití",
    "Sessioni di studio": "Studijní relace",
    "Documenti": "Dokumenty",
    "Mappe": "Mapy",
    "Riassunti": "Shrnutí",
    "Flashcards": "Flashcards",
    "Audiolibri": "Audioknihy",
    "minuti": "minuty",

    "Schede didattiche di memorizzazione": "Paměťové učební kartičky",

    "Vuoi davvero eliminare la sessione?": "Opravdu chcete smazat relaci?",
    "Vuoi davvero rimuovere l'elemento?": "Opravdu chcete odstranit položku?",
    "Se si conferma, ad eliminazione avvenuta sarai reindirizzato alla pagina principale e non potrai recuperare i dati.": "Pokud bude potvrzeno, po smazání budete přesměrováni na hlavní stránku a nebudete moci data obnovit.",
    "Se si conferma, ad eliminazione avvenuta non potrai recuperare la risorsa.": "Pokud bude potvrzeno, po smazání již nebudete moci obnovit zdroj."

}

export const SessionsTranslationsNL = {
    "Le tue sessioni": "Uw sessies",
    "Sto cercando le tue sessioni...": "Uw sessies worden opgehaald...",
    "Cerca per titolo": "Zoeken op titel",
    "Oggi": "Vandaag",
    "Ieri": "Gisteren",
    "Questa settimana": "Deze week",
    "Settimana scorsa": "Vorige week",
    "Questo mese": "Deze maand",
    "Sessioni più vecchie": "Oudere sessies",
    "Che argomento stai studiando?": "Welk onderwerp studeert u?",
    "Che nuovo argomento vuoi studiare?": "Welk onderwerp wilt u studeren?",
    "Hai ancora": "U heeft nog",
    "Sessioni disponibili ✨": "beschikbare sessies ✨",
    "Sessioni disponibili questo mese ✨": "beschikbare sessies deze maand ✨",
    "L'Impero romano": "Het Romeinse Rijk",
    "La mitosi cellulare": "De celdeling",
    "La geografia dell'Italia": "De geografie van Italië",
    "Astrofisica: Buchi neri": "Astrofysica: Zwarte gaten",
    "Esempio \"Il Rinascimento italiano\"": "Bijv. \"De Italiaanse Renaissance\"",
    "Digita l'argomento per ottenere:": "Typ het onderwerp om te ontvangen:",
    "mappa concettuale": "conceptueel schema",
    "riassunto": "samenvatting",
    "parole chiave": "sleutelwoorden",
    "audiolibro": "luisterboek",
    "oppure carica 📤 un documento": "of upload 📤 een bestand",
    "Torna indietro": "Ga terug",
    "Rimuovi risorsa": "Verwijder bron",
    "Elimina sessione": "Sessie verwijderen",
    "Annulla": "Annuleren",
    "Mappa": "Kaart",
    "Riassunto": "Samenvatting",
    "Parole chiave": "Sleutelwoorden",
    "Flashcards": "Flashcards",
    "Audiolibro": "Luisterboek",
    "Inizia a studiare": "Begin met studeren",
    "Utilizzi rimanenti": "Resterend aantal keren",
    "Sessioni di studio": "Studiesessies",
    "Documenti": "Documenten",
    "Mappe": "Kaarten",
    "Riassunti": "Samenvattingen",
    "Flashcards": "Flashcards",
    "Audiolibri": "Luisterboeken",
    "minuti": "minuten",

    "Schede didattiche di memorizzazione": "Didactische geheugenkaarten",


    "Vuoi davvero eliminare la sessione?": "Weet u zeker dat u de sessie wilt verwijderen?",
    "Vuoi davvero rimuovere l'elemento?": "Weet u zeker dat u het item wilt verwijderen?",
    "Se si conferma, ad eliminazione avvenuta sarai reindirizzato alla pagina principale e non potrai recuperare i dati.": "Als bevestigd, wordt u na het verwijderen doorgestuurd naar de startpagina en kunt u de gegevens niet herstellen.",
    "Se si conferma, ad eliminazione avvenuta non potrai recuperare la risorsa.": "Als bevestigd, kunt u na het verwijderen de bron niet herstellen."

}

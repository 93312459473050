import { IonAccordion, IonAccordionGroup, IonButton, IonContent, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonMenu, IonMenuButton, IonMenuToggle, IonPopover, IonRow, IonText, IonTitle, isPlatform } from "@ionic/react";
import { closeOutline, helpCircle, helpCircleOutline, helpOutline, homeOutline, lockClosedOutline, lockOpenOutline, personOutline, settingsOutline } from "ionicons/icons";
import React, { ReactNode } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { appVersion } from "../../appVersion";
import { authActions } from "../authentication/store/actions";
import Joyride from "react-joyride";
import { handleJoyrideCallback, Step, updateChoice } from "../../common/utilsJoyride";

import "./SideMenuStyle.css";
import { auth, firestore, functions } from "../../firebaseConfig";
import { settingsServices } from "../settings/store/services";
import { sendEvent } from "../../common/amplitudeUtils";
import redeemBookIcon from "../../assets/icons/redeem_book.svg"
import i18n from "../../i18n";
import { Organization } from "../user/store/reducers";
import { httpsCallable } from "firebase/functions";
import { Document } from "../files/store/reducers";
import { filesServices } from "../files/store/services";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { redeemBook } from "../files/utils/utils";
import { store as paywallStateStore } from "../../common/store";
import { paywallActions } from "../../common/Paywall/store/actions";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from "../../firebaseConfig";
type Props = {
	userUuid: string | null,
	history: any;
	orgData: Organization | null | 'Private',
	userDocuments: Document[] | null,
	sideMenuHome: { homeIcon: any; color: any };
	sideMenuProfile: { profileIcon: any; color: any };
	sideMenuSetting: { settingsIcon: any; color: any };
};

type State = {
	stepsSideMenu: Step[];
	tutorialSideMenu: boolean;
	stepIndexSideMenu: number;
	userUuid: string;
	showRedeemBookPopover: boolean,
	showRedeemBookError: boolean,
	popoverEvent: any,
	ISBNcode: string,
	showConfirmationMessage: boolean,
	showBookAlreadyRedeemedError: boolean,
	isLoadingRedeemBook: boolean,
	documents: Document[] | null,

	loadingLicense: boolean,
	isWarning: boolean,
	licenseCode: string | null | undefined,
	remoteConfig: boolean,
};

class SideMenu extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			stepsSideMenu: [
				{
					target: ".sideMenuContainer",
					content: (
						<div>
							<p>
								<Trans>Scopri il menu</Trans>
							</p>
							{/* <iframe
                        width="280"
                        height="157"
                        src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/18+-+Come+caricare+un+appunto+scritto+a+mano.mp4"
                        title="YouTube video player"
                        allowFullScreen
                        autoplay
                      ></iframe> */}
						</div>
					),
					disableBeacon: true,
					placement: "right",
				},
				{
					target: "#profileItem",
					content: (
						<div>
							<p>
								<Trans>Esplora il profilo</Trans>
							</p>
						</div>
					),
					disableBeacon: true,
					placement: "right",
				},
				{
					target: "#settingsItem",
					content: (
						<div>
							<p>
								<Trans>Esplora le configurazioni</Trans>
							</p>
						</div>
					),
					disableBeacon: true,
					placement: "right",
				},
			],
			tutorialSideMenu: false,
			stepIndexSideMenu: 0,
			userUuid: "",
			showRedeemBookPopover: false,
			showRedeemBookError: false,
			popoverEvent: null,
			ISBNcode: '',
			showConfirmationMessage: false,
			showBookAlreadyRedeemedError: false,
			isLoadingRedeemBook: false,
			documents: null,

			loadingLicense: false,
			isWarning: false,
			licenseCode: '',
			remoteConfig: false,
		};
	}

	updateChoice() {
		return new Promise<void>((resolve, reject) => {
			auth.onAuthStateChanged(async (userData) => {
				if (userData) {
					this.setState({ userUuid: userData.uid }, () => {
						settingsServices.getUserTutorialChoice(userData.uid, "tSM").then((response) => {
							this.setState({ tutorialSideMenu: response }, () => {
								if (response) {
									sendEvent({
										"user_id": this.state.userUuid,
										"event_type": "Tutorial showed",
										"event_properties": {
											"user_org": this.props.orgData === 'Private' ? "Private User" : this.props.orgData !== null ? this.props.orgData.name : "Private User",
											"page": "Files Manager Side menu",
										},
										"language": i18n.language,
										"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
										"app_version": appVersion,
										"time": Date.now(),
									})
								}
							});
						});
					});

					resolve();
				}
			});
		});
	}

	componentDidMount(): void {
		fetchAndActivate(remoteConfig)
			.then((activated) => {
				if (activated) console.log("Fetched and activated configs!");
				else {
					const remoteConfigValue = getValue(remoteConfig, "showLicenseCode").asBoolean();
					this.setState({ remoteConfig: remoteConfigValue })
				}

			})
			.catch((err) => console.error("Failed to fetch and activate configs", err));
		auth.onAuthStateChanged(userData => {
			if (userData) {
				if (!this.props.userDocuments) {
					const q = query(collection(firestore, "f"), where("o", "==", userData.uid));
					const unsubscribe = onSnapshot(q, async (querySnapshot) => {
						// this.setState({ isLoadingFoldersAndFiles: true });
						this.setState(
							{
								documents: filesServices.fromFilesSnapshotToArrayOfDocuments(querySnapshot),
							},
						)
					})

				}
			}
		})

	}

	render() {
		return (
			<IonMenu
				/* style={this.isPortrait() ? { maxWidth: window.innerWidth } : { maxWidth: "300px" }} */
				type="push"
				menuId="sideMenu"
				contentId="openSideMenu"
				className="sideMenu"
				onIonDidOpen={() => {
					sendEvent({
						"user_id": this.props.userUuid,
						"event_type": "Hamburger menu pressed",
						"event_properties": {
							"user_org": this.props.orgData === 'Private' ? "Private User" : this.props.orgData !== null ? this.props.orgData.name : "Private User",
							"action": "opened",
						},
						"language": i18n.language,
						"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
						"app_version": appVersion,
						"time": Date.now(),
					});
					// this.updateChoice().then(() => {

					// });
				}}
				onIonDidClose={() => {
					sendEvent({
						"user_id": this.props.userUuid,
						"event_type": "Hamburger menu pressed",
						"event_properties": {
							"user_org": this.props.orgData === 'Private' ? "Private User" : this.props.orgData !== null ? this.props.orgData.name : "Private User",
							"action": "closed",
						},
						"language": i18n.language,
						"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
						"app_version": appVersion,
						"time": Date.now(),
					});
				}}
			>
				<IonContent className="sideMenuContainer">
					<Joyride
						steps={this.state.stepsSideMenu}
						run={this.state.tutorialSideMenu} // Set to true to start the tour automatically
						continuous={true} // Allow continuous navigation through the tour
						showProgress={true} // Display progress indicator
						showSkipButton={true} // Display skip button
						spotlightClicks={true}
						callback={(data) => handleJoyrideCallback.call(this, data, "Files Manager Side menu")}
						hideBackButton={false}
						locale={{
							skip: (
								<div className="skip" onClick={() => { updateChoice.call(this, "tutorialSideMenu") }}>
									<Trans>Salta</Trans>
								</div>
							),
							back: (
								<div className="nextTutorialBtn">
									<Trans>Precedente</Trans>
								</div>
							),
							next: (
								<span>
									<Trans>Prosegui</Trans>
								</span>
							),
							last: (

								<div className="nextTutorialBtn"
									onClick={() => { updateChoice.call(this, "tutorialSideMenu") }}>
									<Trans>Ultimo</Trans>
								</div>
							),
						}}
						styles={{
							buttonNext: {
								fontSize: "12px",
								outline: "none",
							},
						}}
					/>
					<div className="sideHeader">
						<IonImg className="logo"></IonImg>
						<IonText>Reasy</IonText>
						<IonMenuToggle>
							<IonButton className="sideMenuClose">
								<IonIcon icon={closeOutline}></IonIcon>
							</IonButton>
						</IonMenuToggle>
					</div>
					<div className="navigation">
						<IonItem onClick={() => {
							var storedValue = localStorage.getItem('isPayWallClose');
							// Check if the value exists in localStorage
							var isPayWallClose = storedValue !== null ? JSON.parse(storedValue) : false;
							if (isPayWallClose) {
								localStorage.setItem('isPayWallClose', JSON.stringify(false))
								setTimeout(() => window.location.reload(), 200);
							}
						}} routerLink="/files" className="sideMenuItem" id="homeitem">
							<IonIcon
								id="homeIcon"
								size="medium"
								style={{
									margin: "10px",
									color: this.props.sideMenuHome.color === undefined ? "var(--txt-color)" : this.props.sideMenuHome.color,
								}}
								icon={this.props.sideMenuHome.homeIcon === undefined ? homeOutline : this.props.sideMenuHome.homeIcon}
							/>
							<Trans>Home</Trans>
						</IonItem>

						<IonItem routerLink="/profile" className="sideMenuItem " id="profileItem">
							<IonIcon
								id="profileIcon"
								size="medium"
								style={{
									margin: "10px",
									color: this.props.sideMenuProfile.color === undefined ? "var(--txt-color)" : this.props.sideMenuProfile.color,
								}}
								icon={this.props.sideMenuProfile.profileIcon === undefined ? personOutline : this.props.sideMenuProfile.profileIcon}
							/>
							<Trans>Profilo</Trans>
						</IonItem>

						<IonItem routerLink="/settings" className="sideMenuItem" id="settingsItem">
							<IonIcon
								id="settingsIcon"
								size="medium"
								style={{
									margin: "10px",
									color: this.props.sideMenuSetting.color === undefined ? "var(--txt-color)" : this.props.sideMenuSetting.color,
								}}
								icon={this.props.sideMenuSetting.settingsIcon === undefined ? settingsOutline : this.props.sideMenuSetting.settingsIcon}
							/>
							<Trans>Configurazioni</Trans> {/* TO BE LOCALIZED */}
						</IonItem>
						{
							this.props.orgData && this.props.orgData !== 'Private' && this.props.orgData.type === 'publisher' &&
							<IonItem
								id="redeemBookButton"
								button
								onClick={() => this.setState({ showRedeemBookPopover: true })}
								className="sideMenuItem"
							>
								<IonIcon
									className=""
									style={{
										margin: "10px",
										color: "var(--txt-color)"
									}}
									icon={redeemBookIcon}
								/>
								<Trans>Riscatta un libro</Trans>
							</IonItem>
						}
						{
							this.state.remoteConfig &&
							<IonItem
								id="redeemLicenseButton"
								button
								style={{
									width: "max-content",
								}}
								onClick={() => {
									paywallStateStore.dispatch(paywallActions.showPaywall());
								}}
								className="sideMenuItem"
							>

								<IonIcon
									style={{
										color: 'var(--txt-color)',
										margin: "10px",
									}}
									icon={lockOpenOutline}
								/>
								<Trans>Hai un codice licenza?</Trans>
								{/* <div className="filesManagerRedeemLicenseContentDiv">
									<IonInput
										className="filesManagerRedeemLicenseInput"
										placeholder={
											i18n.language === 'es' ? "Código de licencia" :
												i18n.language === 'it' ? "Codice licenza" :
													i18n.language === 'en' ? "License code" :
														i18n.language === 'fr' ? "Code de licence" :
															i18n.language === 'de' ? "Lizenzcode" :
																i18n.language === 'ca' ? "Codi de llicència" :
																	i18n.language === 'pt' ? "Código de licença" :
																		i18n.language === 'cz' ? "Licenční kód" :
																			i18n.language === 'nl' ? "Licentiecode" : ''
										}
										type="text"
										onIonInput={(e) => {
											this.setState({ licenseCode: e.detail.value?.toString() })
										}}
									/>
									<IonButton
										disabled={!this.state.licenseCode}
										onClick={() => {
											this.setState({ loadingLicense: true })
											const redeemLicense = httpsCallable(functions, "redeemLicense-redeemLicense");
											redeemLicense({
												licenseCode: this.state.licenseCode
											})
												.then(res => {
													const data: any = res.data;
													sendEvent({
														"user_id": auth.currentUser?.uid,
														"event_type": "Redeem License",
														"event_properties": {
															"license_code": this.state.licenseCode,
															"is_valid": data.ok === 'ok' ? true : false
														},
														"language": i18n.language,
														"app_version": appVersion,
														"platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
														"time": Date.now()
													})
													if (data.ok == "ok") {
														//SET LOCAL VARIABLE TO HIDE THE BANNER??
														window.location.reload()
													}
													if (data.error) {
														this.setState({ loadingLicense: false, isWarning: true, })
													};
												}
												)
												.catch(err => {
													console.error("[Profile] error calling redeemLicense function:", err);
												})
										}}
										className="filesManagerRedeemLicenseButton"
									>
										<Trans>
											Riscatta
										</Trans>
									</IonButton>
									<IonLabel className="warning" hidden={!this.state.isWarning}><Trans>Licenza non valida o incorretta</Trans> </IonLabel> */}
								{/* </div> */}
							</IonItem>
						}
					</div>
					<IonPopover
						keepContentsMounted
						isOpen={this.state.showRedeemBookPopover}
						onDidDismiss={() => this.setState({ showRedeemBookPopover: false, showRedeemBookError: false, ISBNcode: '' })}
						className="redeemBookPopover"
					>
						<div className="redeemBookPopoverDiv">
							<p className="redeemBookPopoverTitle"><Trans>Riscatta un libro</Trans></p>
							<p className="redeemBookPopoverDescription"><Trans>Inserisci il codice ISBN del libro</Trans></p>
							<IonItem className="redeemBookItem">
								<IonInput
									id="redeemBookInput"
									className="redeemBookInput"
									label={i18n.language === 'it' ? "Codice ISBN" :
										i18n.language === 'en' ? "ISBN-Code" :
											i18n.language === 'es' ? "Código ISBN" :
												i18n.language === 'fr' ? "Code ISBN" :
													i18n.language === 'de' ? "ISBN-Code" :
														i18n.language === 'ca' ? "Codi ISBN" : ''
									}
									labelPlacement="floating"
									onIonChange={(e) => {
										if (e.detail.value)
											this.setState({ ISBNcode: e.detail.value })
									}}
									value={this.state.ISBNcode} />
							</IonItem>
							{this.state.showRedeemBookError &&
								<IonLabel className="redeemBookErrorLabel"><Trans>Il codice non è valido</Trans></IonLabel>
							}
							{this.state.showBookAlreadyRedeemedError &&
								<IonLabel className="redeemBookErrorLabel"><Trans>Libro già riscattato</Trans></IonLabel> /* TO LOCALIZE THIS */
							}
							{this.state.showConfirmationMessage &&
								<IonLabel className="mobileRedeemBookConfirmation"><Trans>Libro riscattato con successo</Trans></IonLabel>
							}
							<IonButton
								disabled={this.state.isLoadingRedeemBook}
								onClick={() => {
									this.setState({ isLoadingRedeemBook: true })
									let ISBNcode = (document.getElementById('redeemBookInput') as HTMLIonInputElement).value as any;
									let regex = new RegExp(/^(?=(?:[^0-9]*[0-9]){10}(?:(?:[^0-9]*[0-9]){3})?$)[\d-]+$/);
									if (ISBNcode && regex.test(ISBNcode)) {
										let userDocuments = this.props.userDocuments ? this.props.userDocuments : this.state.documents ? this.state.documents : null
										redeemBook(this, ISBNcode, userDocuments)
									}
									else {
										this.setState({ showRedeemBookError: true, isLoadingRedeemBook: false, showConfirmationMessage: false, showBookAlreadyRedeemedError: false })
									}
								}}
								className="redeemBookPopoverButton"
							>
								<Trans>
									Riscatta
								</Trans>
							</IonButton>
						</div>
					</IonPopover>
					<div className="sideMenuFooter">
						<IonRow className="sideMenuFooterElement">
							<p>
								<Trans>Versione</Trans> {appVersion}
							</p>
						</IonRow>

						<IonRow className="sideMenuFooterElement">
							<a href="/legal/privacy-policy">Privacy Policy</a>
						</IonRow>
						<IonRow className="sideMenuFooterElement">
							<a href="/legal/terms-conditions">
								<Trans>Termini e Condizioni</Trans>
							</a>
						</IonRow>
					</div>

					{/* <IonIcon
						className="helpIcon"
						onClick={() => {
							this.setState({ tutorialSideMenu: true }, () => {
								console.log(this.props.userUuid)

								sendEvent({
									"user_id": this.props.userUuid,
									"event_type": "Tutorial opened",
									"event_properties": {
										"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
										"page": "Files Manager Side menu",
									},
									"language": i18n.language,
									"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
									"app_version": appVersion,
									"time": Date.now(),
								});
							});
						}}
						icon={helpCircleOutline}
						style={{ fontSize: "3vw" }}
					></IonIcon> */}
				</IonContent>
			</IonMenu >
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		isAuthenticated: state.auth.access ? true : false,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		logout: () => {
			dispatch(authActions.logout());
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);

export const PopoverTranslationsEN = {
    //POPOVER LICENCE
    "Partiamo!": "Let's go!",
    "Conferma": "Confirm",
    "Ritorna": "Come back",
    "La tua sottoscrizione è scaduta": "Your subscription has expired",
    "Provo gratis Reasy per 7 giorni": "I try Reasy for free for 7 days",
    "Acquista": "Purchase",
    "Aggiorna": "Update",
    "Stiamo assegnando la tua prova!": "We are assigning your trial!",
    "Appena sarà pronta la pagina si aggiornerà": "The page will update as soon as it is ready",
    "al mese": "per month",
    //POPOVER WORD SELECTOR (PAGE 1):
    "Clicca una parola per analizzarla": "Click on a word to analyze it",
    "Leggi Frase": "Read the sentence",
    "Cambia la lingua della frase": "Change sentence language",
    //POPOVER WORD ACTIONS (PAGE 2):
    "Ascolta": "Read word",
    "Dizionario": "Dictionary",
    "Non ci sono definizioni": "There are no definitions",
    "Evidenzia lettere": "Letter highlight",
    "Disegna immagine": "Draw image",
    //POPOVER LETTER SELECTOR:
    "Evidenzia sempre": "Always highlight",
    "Seleziona lettere": "Select letters",
    
    "Definizione": "Definition",
    "Seleziona una parola": "Select a word",
    "Non ci sono immagini": "There are no images",
    "Disegnane una": "Draw one!",
    "Ho un codice licenza": "I have a license code",
    "Non ho un codice licenza, ": "I do not have a license code, ",
    "Inserisci il codice": "Enter the code",
    "Licenza non valida o incorretta": "Invalid or incorrect license",
    
    
    }
    export const PopoverTranslationsIT = {
    //POPOVER LICENCE
    "Partiamo!": "Partiamo!",
    "Conferma": "Conferma",
    "Ritorna": "Ritorna",
    "La tua sottoscrizione è scaduta": "La tua sottoscrizione è scaduta",
    "Provo gratis Reasy per 7 giorni": "Provo gratis Reasy per 7 giorni",
    "Acquista": "Acquista",
    "Aggiorna": "Aggiorna",
    "Stiamo assegnando la tua prova!": "Stiamo assegnando la tua prova!",
    "Appena sarà pronta la pagina si aggiornerà": "Appena sarà pronta la pagina si aggiornerà",
    "al mese": "al mese",
    //POPOVER WORD SELECTOR (PAGE 1):
    "Clicca una parola per analizzarla": "Clicca una parola per analizzarla",
    "Leggi Frase": "Leggi Frase",
    "Cambia la lingua della frase": "Cambia la lingua della frase",
    //POPOVER WORD ACTIONS (PAGE 2):
    "Ascolta": "Ascolta",
    "Dizionario": "Dizionario",
    "Non ci sono definizioni": "Non ci sono definizioni",
    "Evidenzia lettere": "Evidenzia lettere",
    "Disegna immagine": "Disegna immagine",
    //POPOVER LETTER SELECTOR:
    "Evidenzia sempre": "Evidenzia sempre",
    "Seleziona lettere": "Seleziona lettere",
    
    "Seleziona una parola": "Seleziona una parola",
    "Definizione": "Definizione",
    
    "Non ci sono immagini": "Non ci sono immagini",
    "Disegnane una": "Disegnane una",
    "Ho un codice licenza": "Ho un codice licenza",
    "Non ho un codice licenza, ": "Non ho un codice licenza, ",
    "Inserisci il codice": "Inserisci il codice",
    "Licenza non valida o incorretta": "Licenza non valida o incorretta",
    }
    export const PopoverTranslationsES = {
    //POPOVER LICENCE
    "Conferma": "Confirma",
    "Ritorna": "Regresar",
    "La tua sottoscrizione è scaduta": "Tu suscripción ha expirado",
    "Provo gratis Reasy per 7 giorni": "Pruebo Reasy gratis durante 7 días",
    "Acquista": "Comprar",
    "Aggiorna": "Actualizar",
    "Partiamo!": "¡Vamonos!",
    "Stiamo assegnando la tua prova!": "¡Estamos asignando tu prueba!",
    "Appena sarà pronta la pagina si aggiornerà": "La página se actualizará tan pronto como esté lista",
    "al mese": "por mes",
    //POPOVER WORD SELECTOR (PAGE 1):
    "Clicca una parola per analizzarla": "Click on a word to analyze it",
    "Leggi Frase": "Read the sentence",
    "Cambia la lingua della frase": "Change sentence language",
    //POPOVER WORD ACTIONS (PAGE 2):
    "Ascolta": "Escuchar",
    "Dizionario": "Diccionario",
    "Non ci sono definizioni": "No hay definiciones",
    "Evidenzia lettere": "Letter highlight",
    "Disegna immagine": "Dibujar imagen",
    //POPOVER LETTER SELECTOR:
    "Evidenzia sempre": "Always highlight",
    "Seleziona lettere": "Select letters",
    
    "Seleziona una parola": "Seleccione una palabra",
    "Definizione": "Definición",
    
    "Non ci sono immagini": "No hay fotos",
    "Disegnane una": "Dibuja uno",
    "Ho un codice licenza": "Tengo un código de licencia",
    "Non ho un codice licenza, ": "No tengo un código de licencia, ",
    "Inserisci il codice": "Introduce el código",
    "Licenza non valida o incorretta": "Licencia no válida o incorrecta",
    }
    export const PopoverTranslationsFR = {
    //POPOVER LICENCE
    "Partiamo!": "Allons-y!",
    "Conferma": "Confirmation",
    "Ritorna": "Revient",
    "La tua sottoscrizione è scaduta": "Votre abonnement a expiré",
    "Provo gratis Reasy per 7 giorni": "J'essaye Reasy gratuitement pendant 7 jours",
    "Acquista": "Acheter",
    "Aggiorna": "Actualiser",
    "Stiamo assegnando la tua prova!": "Nous attribuons votre test!",
    "Appena sarà pronta la pagina si aggiornerà": "La page sera mise à jour aussitôt qu'elle sera prête",
    "al mese": "par mois",
    //POPOVER WORD SELECTOR (PAGE 1):
    "Clicca una parola per analizzarla": "Click on a word to analyze it",
    "Leggi Frase": "Read the sentence",
    "Cambia la lingua della frase": "Change sentence language",
    //POPOVER WORD ACTIONS (PAGE 2):
    "Ascolta": "Ecoutez",
    "Dizionario": "Dictionnaire",
    "Non ci sono definizioni": "Il n'y a pas de définition",
    "Evidenzia lettere": "Letter highlight",
    "Disegna immagine": "Dessiner une image",
    //POPOVER LETTER SELECTOR:
    "Evidenzia sempre": "Always highlight",
    "Seleziona lettere": "Select letters",
    
    "Seleziona una parola": "Sélectionnez un mot",
    "Definizione": "Définition",
    
    "Non ci sono immagini": "Il n'y a pas de photos",
    "Disegnane una": "Tirez-en un",
    "Ho un codice licenza": "J'ai un code de licence",
    "Non ho un codice licenza, ": "Je n'ai pas de code de licence, ",
    "Inserisci il codice": "Entrez le code",
    "Licenza non valida o incorretta": "Licence non valide ou incorrecte",
    
    }
    export const PopoverTranslationsDE = {
    //POPOVER LICENCE
    "Partiamo!": "Los geht's!",
    "Conferma": "Bestätigen",
    "Ritorna": "Zurück",
    "La tua sottoscrizione è scaduta": "Dein Abonnement ist abgelaufen",
    "Provo gratis Reasy per 7 giorni": "Ich probiere Reasy 7 Tage lang kostenlos aus",
    "Acquista": "Kaufen",
    "Aggiorna": "Aktualisieren",
    "Stiamo assegnando la tua prova!": "Wir weisen Ihre Testversion zu!",
    "Appena sarà pronta la pagina si aggiornerà": "Die Seite wird aktualisiert, sobald sie bereit ist",
    "al mese": "pro Monat",
    //POPOVER WORD SELECTOR (PAGE 1):
    "Clicca una parola per analizzarla": "Klicken Sie auf ein Wort, um es zu analysieren",
    "Leggi Frase": "Satz lesen",
    "Cambia la lingua della frase": "Ändere die Sprache des Satzes",
    //POPOVER WORD ACTIONS (PAGE 2):
    "Ascolta": "Hören",
    "Dizionario": "Wörterbuch",
    "Non ci sono definizioni": "Es gibt keine Definitionen",
    "Evidenzia lettere": "Buchstaben hervorheben",
    "Disegna immagine": "Bild zeichnen",
    //POPOVER LETTER SELECTOR:
    "Evidenzia sempre": "Immer hervorheben",
    "Seleziona lettere": "Buchstaben auswählen",
    
    "Seleziona una parola": "Ein Wort auswählen",
    "Definizione": "Definition",
    
    "Non ci sono immagini": "Es gibt keine Bilder",
    "Disegnane una": "Zeichnen Sie eines",
    "Ho un codice licenza": "Ich habe einen Lizenzcode",
    "Non ho un codice licenza, ": "Ich habe keinen Lizenzcode, ",
    "Inserisci il codice": "Gib den Code ein",
    "Licenza non valida o incorretta": "Ungültige oder fehlerhafte Lizenz",
    }
    export const PopoverTranslationsCA = {
    //POPOVER LICENCE
    "Partiamo!": "Comencem!",
    "Conferma": "Confirma",
    "Ritorna": "Torna",
    "La tua sottoscrizione è scaduta": "La teva subscripció ha caducat",
    "Provo gratis Reasy per 7 giorni": "Provo gratis Reasy durant 7 dies",
    "Acquista": "Compra",
    "Aggiorna": "Actualitza",
    "Stiamo assegnando la tua prova!": "Estem assignant la teva prova!",
    "Appena sarà pronta la pagina si aggiornerà": "Tan aviat com estigui llesta la pàgina, s'actualitzarà",
    "al mese": "al mes",
    
    //POPOVER WORD SELECTOR (PAGE 1):
    "Clicca una parola per analizzarla": "Fes clic en una paraula per analitzar-la",
    "Leggi Frase": "Llegeix Frase",
    "Cambia la lingua della frase": "Canvia la llengua de la frase",
    
    //POPOVER WORD ACTIONS (PAGE 2):
    "Ascolta": "Escolta",
    "Dizionario": "Diccionari",
    "Non ci sono definizioni": "No hi ha definicions",
    "Evidenzia lettere": "Destaca lletres",
    "Disegna immagine": "Dibuixa imatge",
    
    //POPOVER LETTER SELECTOR:
    "Evidenzia sempre": "Destaca sempre",
    "Seleziona lettere": "Selecciona lletres",
    "Seleziona una parola": "Selecciona una paraula",
    "Definizione": "Definició",
    
    "Non ci sono immagini": "No hi ha imatges",
    "Disegnane una": "Dibuixa'n una",
    "Ho un codice licenza": "Tinc un codi de llicència",
    "Non ho un codice licenza, ": "No tinc un codi de llicència, ",
    "Inserisci il codice": "Introdueix el codi",
    "Licenza non valida o incorretta": "Llicència no vàlida o incorrecta"
    }
export const PopoverTranslationsPT = {
    //POPOVER LICENCE
    "Partiamo!": "Vamos começar!",
    "Conferma": "Confirmar",
    "Ritorna": "Voltar",
    "La tua sottoscrizione è scaduta": "Sua assinatura expirou",
    "Provo gratis Reasy per 7 giorni": "Experimente o Reasy gratuitamente por 7 dias",
    "Acquista": "Comprar",
    "Aggiorna": "Atualizar",
    "Stiamo assegnando la tua prova!": "Estamos atribuindo sua prova!",
    "Appena sarà pronta la pagina si aggiornerà": "Assim que a página estiver pronta, ela será atualizada",
    "al mese": "por mês",

    //POPOVER WORD SELECTOR (PAGE 1):
    "Clicca una parola per analizzarla": "Fes clic en una paraula per analitzar-la",
    "Leggi Frase": "Llegeix Frase",
    "Cambia la lingua della frase": "Canvia la llengua de la frase",

    //POPOVER WORD ACTIONS (PAGE 2):
    "Ascolta": "Ouvir",
    "Dizionario": "Dicionário",
    "Non ci sono definizioni": "Não há definições",
    "Evidenzia lettere": "Destacar letras",
    "Disegna immagine": "Desenhar imagem",

    //POPOVER LETTER SELECTOR:
    "Evidenzia sempre": "Destacar sempre",
    "Seleziona lettere": "Selecionar letras",
    "Seleziona una parola": "Selecionar uma palavra",
    "Definizione": "Definição",

    "Non ci sono immagini": "Não há imagens",
    "Disegnane una": "Desenhe uma",
    "Ho un codice licenza": "Tenho um código de licença",
    "Non ho un codice licenza, ": "Não tenho um código de licença, ",
    "Inserisci il codice": "Digite o código",
    "Licenza non valida o incorretta": "Licença inválida ou incorreta",
}
export const PopoverTranslationsCZ = {
    //POPOVER LICENCE
    "Partiamo!": "Začněme!",
    "Conferma": "Potvrdit",
    "Ritorna": "Vrátit se",
    "La tua sottoscrizione è scaduta": "Váše předplatné vypršelo",
    "Provo gratis Reasy per 7 giorni": "Zkuste Reasy zdarma po dobu 7 dní",
    "Acquista": "Koupit",
    "Aggiorna": "Aktualizovat",
    "Stiamo assegnando la tua prova!": "Přidělujeme vám váš test!",
    "Appena sarà pronta la pagina si aggiornerà": "Jakmile bude stránka připravená, aktualizuje se",
    "al mese": "měsíčně",

    //POPOVER WORD SELECTOR (PAGE 1):
    "Clicca una parola per analizzarla": "Klikněte na slovo pro analýzu",
    "Leggi Frase": "Přečtěte si větu",
    "Cambia la lingua della frase": "Změnit jazyk věty",

    //POPOVER WORD ACTIONS (PAGE 2):
    "Ascolta": "Poslouchej",
    "Dizionario": "Slovník",
    "Non ci sono definizioni": "Žádné definice nejsou k dispozici",
    "Evidenzia lettere": "Zvýraznit písmena",
    "Disegna immagine": "Nakreslete obrázek",

    //POPOVER LETTER SELECTOR:
    "Evidenzia sempre": "Vždy zvýraznit",
    "Seleziona lettere": "Vyberte písmena",
    "Seleziona una parola": "Vyberte slovo",
    "Definizione": "Definice",

    "Non ci sono immagini": "Žádné obrázky nejsou k dispozici",
    "Disegnane una": "Nakreslit jeden",
    "Ho un codice licenza": "Mám licenční kód",
    "Non ho un codice licenza, ": "Nemám licenční kód, ",
    "Inserisci il codice": "Zadejte kód",
    "Licenza non valida o incorretta": "Neplatná nebo nesprávná licence",
}
export const PopoverTranslationsNL = {
    //POPOVER LICENCE
    "Partiamo!": "Laten we beginnen!",
    "Conferma": "Bevestigen",
    "Ritorna": "Terugkeren",
    "La tua sottoscrizione è scaduta": "Je abonnement is verlopen",
    "Provo gratis Reasy per 7 giorni": "Ik probeer Reasy gratis voor 7 dagen",
    "Acquista": "Kopen",
    "Aggiorna": "Bijwerken",
    "Stiamo assegnando la tua prova!": "We wijzen je proefperiode toe!",
    "Appena sarà pronta la pagina si aggiornerà": "De pagina wordt bijgewerkt zodra deze klaar is",
    "al mese": "per maand",
    //POPOVER WORD SELECTOR (PAGE 1):
    "Clicca una parola per analizzarla": "Klik op een woord om het te analyseren",
    "Leggi Frase": "Lees Zin",
    "Cambia la lingua della frase": "Verander de taal van de zin",
    //POPOVER WORD ACTIONS (PAGE 2):
    "Ascolta": "Luister",
    "Dizionario": "Woordenboek",
    "Non ci sono definizioni": "Er zijn geen definities",
    "Evidenzia lettere": "Markeer letters",
    "Disegna immagine": "Teken afbeelding",
    //POPOVER LETTER SELECTOR:
    "Evidenzia sempre": "Altijd markeren",
    "Seleziona lettere": "Selecteer letters",

    "Seleziona una parola": "Selecteer een woord",
    "Definizione": "Definitie",

    "Non ci sono immagini": "Er zijn geen afbeeldingen",
    "Disegnane una": "Tekenen",
    "Ho un codice licenza": "Ik heb een licentiecode",
    "Non ho un codice licenza, ": "Ik heb geen licentiecode, ",
    "Inserisci il codice": "Voer de code in",
    "Licenza non valida o incorretta": "Ongeldige of incorrecte licentie",
}
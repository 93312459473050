import axios from "axios";
import i18n from "../../../i18n";

export function getTextMeanings(word: string, language: string,token:string) {
    return new Promise<string[]>((resolve, reject) => {
        let lang = i18n.language === 'it' ? 'italian' : i18n.language === 'en' ? 'english' : i18n.language === 'es' ? 'spanish' : 'french';
        axios.post(`https://fujjvnytml.execute-api.eu-central-1.amazonaws.com/staging/DictionaryLock/`, 
                { 
                    "Authorization": `Bearer ${token}`,
                    word: word.trim(),
                    language: lang 
                })
            .then(response => {
                // console.log("TYPE: ", typeof response.data);

                console.log('[getWordMeanings] response:', response.data); // for debugging
                if(response.data.statusCode===500)
                    resolve(["Testo troppo lungo"]);
                else if(response.data===null)
                    resolve(["Riporva"]);
                else
                    resolve(response.data);
            })
            .catch(error => {
                //console.log('[getWordMeanings] error:', error.response.data);
                reject(error.response.data);
            });
    })
}
export const FileTranslationsEN = {
    "Gestore dei File": "File Manager",
    "Ordine alfabetico": "Alphabetical order",
    "Stai filtrando per tag:": "Filter by tags:",
    "Dal più recente": "Newest",
    "Dal meno recente": "Oldest",
    "Cerca file": "Search file",
    "Analizza": "Analyse",
    "Carica File": "Upload File",
    "Mappa da PDF": "PDF to Map",
    "Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.": "Allow this device to receive push notifications. Tap here.",
    "Carica il tuo primo documento": "Upload your first document",

    "Sezione": "Section",
    "Sezioni": "Sections",
    "Annulla": "Cancel",
    "Prosegui": "Continue",

    "Crea Mappa": "Create Map",
    "Crea riassunto!": "Create summary!",
    "Crea parole chiave!": "Create keywords!",

    "Visualizza Lista": "List View",
    "Visualizza Griglia": "Grid View",
    "Lingua": "Language",
    "Lingua del testo": "Text language",
    "Inglese": "English",
    "Italiano": "Italian",
    "Spagnolo": "Spanish",
    "Francese": "French",
    "Rinomina file": "Rename file",

    "Il tuo PDF è pronto": "Your PDF is ready",
    "Scarica il PDF": "Download the PDF",

    "I tuoi file": "Your files",
    "Rimuovi file": "Delete file",
    "Che tipo di file vuoi caricare?": "What type of file you want to upload?",
    "Foto scrittura a mano": "Handwritten text photo",
    "PDF/IMG libro/testo": "PDF/IMG book/text",


    //SUMMARY AND KEYWORDS RELATED
    "Genera riassunto": "Generate summary",
    "Genera": "Generate",
    "Genera parole chiave": "Generate keywords",
    "Stiamo preparando il tuo riassunto!": "We are preparing your summary!",
    "Stiamo preparando le tue parole chiave!": "We are preparing your keywords!",
    "Stiamo preparando la tua mappa!": "We are preparing your map!",
    "Ti avviseremo appena sarà pronta": "We will notify you as soon as it is ready",
    "Ti avviseremo appena sarà pronto": "We will notify you as soon as it is ready",
    "Cancella riassunto": "Delete summary",
    "Cancella parole chiave": "Delete keywords",
    "Scarica riassunto pdf": "Download summary pdf",
    "Scarica pdf": "Download pdf",
    "Scarica parole chiave pdf": "Download keywords pdf",
    "Il tuo riassunto è pronto!": "Your summary is ready!",
    "Le parole chiave sono pronte!": "Your keywords are ready",
    "La tua mappa è pronta!": "Your map is ready!",
    "Ho capito": "I got it",
    "Parole chiave": "Keywords",
    "Riassunto": "Summary",
    "Clicca le sezioni che vuoi trasformare in riassunto...": "Click the sections you want to turn into a summary...",
    "Clicca le sezioni da cui estrarre le parole chiave...": "Click the sections from which to extract keywords...",
    "Sintesi vocale": "Speech synthesis",
    "Mappa concettuale": "Conceptual map",
    "Modifica documento": "Edit document",
    "Modalità lettura": "Viewing mode",

    "Attendi giusto qualche secondo, stiamo processando il tuo documento...": "Please wait just a few seconds, we are processing your document...",
    "Clicca le sezioni che vuoi trasformare in mappa concettuale...": "Click the sections you want to turn into a concept map...",
    "Non sei proprietario di questo documento": "You do not own this document",


    "Mappa": "Map",
    "Modifica": "Edit",
    "Funzioni": "Functions",


    "Ascolta il testo": "Listen to the text",
    "Crea una mappa concettuale": "Create a concept map",
    "Modifica il documento caricato": "Edit the uploaded document",
    "Crea un riassunto": "Create a summary",
    "Individua le parole chiave": "Identify the keywords",
    "Aumenta o diminuisci la dimensione del documento": "Increase or decrease the document size",
    "Scorri le pagine del documento": "Scroll through the document pages",
    "Scarica il documento in pdf": "Download the document in pdf",
    "Precedente": "Back",
    "Avanti": "Next",
    "Ultimo": "Last",
    "Salta": "Skip",
    "Benvenuto su Reasy!\nSegui i tutorial per esplorare l’app": "Welcome to Reasy!\nFollow the tutorials to explore the app",
    "Il tutorial sarà sempre disponibile premendo qui": "The tutorial will always be available by pressing here",
    "Carica un documento di testo.\n- Foto di un testo scritto a mano\n- PDF/JPG/PNG di un libro o testo": "Upload a text document.\n- Photo of handwritten text\n- PDF/JPG/PNG of a book or text",
    "Cambia la modalità di visualizzazione dei file": "Change the file display mode",
    "Premi qui per cambiare il titolo del file o cancellarlo": "Press here to change the file title or delete it",
    "Premi qui per visualizzare il file che hai caricato": "Press here to view the file you have uploaded",



    "Mappa vuota": "Empty Map",
    "Le tue Mappe": "Your Maps",
    "Che titolo ha la tua Mappa?": "What is the title of your map?",
    "Crea mappa vuota": "Create empty map",
    "Ti trovi qui": "You are here",


    "Contatta l'assistenza": "Contact support",
    "Evidenzia": "Highlight",
    "Togli": "Remove",
    "Riassunti e parole chiave estensione": "Summaries and keyword extension",

    /* BANNER */
    "Dicci di più su di te": "Tell us more about you",
    "Studente": "Student",
    "Docente": "Teacher",
    "Altro": "Other",
    "(specificare)": "(specify)",
    "Invia": "Send",
    "Hai un codice licenza?": "Do you have a license code?",


}
export const FileTranslationsIT = {
    "Gestore dei File": "Gestore dei File",
    "Salva": "Salva",
    "Ordine alfabetico": "Ordine alfabetico",
    "Stai filtrando per tag:": "Stai filtrando per tag:",
    "Dal più recente": "Dal più recente",
    "Dal meno recente": "Dal meno recente",
    "Rinomina file": "Rinomina file",
    "Indietro": "Indietro",
    "Carica File": "Carica File",
    "Mappa da PDF": "Mappa da PDF",
    "Pulisci": "Pulisci",
    "Disegna": "Disegna",
    "Annulla": "Annulla",
    "Prosegui": "Prosegui",
    "Cancella": "Cancella",
    "Analizza": "Analizza",
    "Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.": "Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.",
    "Carica il tuo primo documento": "Carica il tuo primo documento",

    "Sezione": "Sezione",
    "Sezioni": "Sezioni",
    "Crea Mappa": "Crea Mappa",
    "Crea riassunto!": "Crea riassunto!",
    "Crea parole chiave!": "Crea parole chiave!",

    "Il tuo PDF è pronto": "Il tuo PDF è pronto",
    "Scarica il PDF": "Scarica il PDF",

    "Visualizza Lista": "Visualizza Lista",
    "Visualizza Griglia": "Visualizza Griglia",
    "Lingua": "Lingua",
    "Lingua del testo": "Lingua del testo",
    "Inglese": "Inglese",
    "Italiano": "Italiano",
    "Spagnolo": "Spagnolo",
    "Francese": "Francese",
    "I tuoi file": "I tuoi file",
    "Rimuovi file": "Rimuovi file",
    "Che tipo di file vuoi caricare?": "Che tipo di file vuoi caricare?",
    "Foto scrittura a mano": "Foto scrittura a mano",
    "PDF/IMG libro/testo": "PDF/IMG libro/testo",

    //SUMMARY AND KEYWORDS RELATED
    "Genera riassunto": "Genera riassunto",
    "Genera": "Genera",
    "Genera parole chiave": "Genera parole chiave",
    "Stiamo preparando il tuo riassunto!": "Stiamo preparando il tuo riassunto!",
    "Ti avviseremo appena sarà pronto": "Ti avviseremo appena sarà pronto",
    "Stiamo preparando le tue parole chiave!": "Stiamo preparando le tue parole chiave!",
    "Stiamo preparando la tua mappa!": "Stiamo preparando la tua mappa!",
    "Ti avviseremo appena sarà pronta": "Ti avviseremo appena sarà pronta",
    "Cancella riassunto": "Cancella riassunto",
    "Cancella parole chiave": "Cancella parole chiave",
    "Scarica riassunto pdf": "Scarica riassunto pdf",
    "Scarica pdf": "Scarica pdf",
    "Scarica parole chiave pdf": "Scarica parole chiave pdf",
    "Il tuo riassunto è pronto!": "Il tuo riassunto è pronto!",
    "Le parole chiave sono pronte!": "Le parole chiave sono pronte!",
    "La tua mappa è pronta!": "La tua mappa è pronta!",
    "Ho capito": "Ho capito",
    "Parole chiave": "Parole chiave",
    "Riassunto": "Riassunto",
    "Clicca le sezioni che vuoi trasformare in riassunto...": "Clicca le sezioni che vuoi trasformare in riassunto...",
    "Clicca le sezioni da cui estrarre le parole chiave...": "Clicca le sezioni da cui estrarre le parole chiave...",
    "Sintesi vocale": "Sintesi vocale",
    "Mappa concettuale": "Mappa concettuale",
    "Modifica documento": "Modifica documento",
    "Modalità lettura": "Modalità lettura",

    "Attendi giusto qualche secondo, stiamo processando il tuo documento...": "Attendi giusto qualche secondo, stiamo processando il tuo documento...",
    "Clicca le sezioni che vuoi trasformare in mappa concettuale...": "Clicca le sezioni che vuoi trasformare in mappa concettuale...",
    "Non sei proprietario di questo documento": "Non sei proprietario di questo documento",

    "Mappa": "Mappa",
    "Modifica": "Modifica",
    "Funzioni": "Funzioni",


    "Benvenuto su Reasy!\nSegui i tutorial per esplorare l’app": "Benvenuto su Reasy!\nSegui i tutorial per esplorare l’app",
    "Il tutorial sarà sempre disponibile premendo qui": "Il tutorial sarà sempre disponibile premendo qui",
    "Carica un documento di testo.\n- Foto di un testo scritto a mano\n- PDF/JPG/PNG di un libro o testo": "Carica un documento di testo.\n- Foto di un testo scritto a mano\n- PDF/JPG/PNG di un libro o testo",
    "Cambia la modalità di visualizzazione dei file": "Cambia la modalità di visualizzazione dei file",
    "Premi qui per cambiare il titolo del file o cancellarlo": "Premi qui per cambiare il titolo del file o cancellarlo",
    "Premi qui per visualizzare il file che hai caricato": "Premi qui per visualizzare il file che hai caricato",

    "Ascolta il testo": "Ascolta il testo",
    "Crea una mappa concettuale": "Crea una mappa concettuale",
    "Modifica il documento caricato": "Modifica il documento caricato",
    "Crea un riassunto": "Crea un riassunto",
    "Individua le parole chiave": "Individua le parole chiave",
    "Controlli dimensione del documento": "Controlli dimensione del documento",
    "Aumenta o diminuisci la dimensione del documento": "Aumenta o diminuisci la dimensione del documento",
    "Scorri le pagine del documento": "Scorri le pagine del documento",
    "Scarica il documento in pdf": "Scarica il documento in pdf",
    "Avanti": "Avanti",
    "Ultimo": "Ultimo",
    "Salta": "Salta",
    "Precedente": "Precedente",



    "Mappa vuota": "Mappa vuota",
    "Le tue Mappe": "Le tue Mappe",
    "Che titolo ha la tua Mappa?": "Che titolo ha la tua Mappa?",
    "Crea mappa vuota": "Crea mappa vuota",
    "Ti trovi qui": "Ti trovi qui",

    "Contatta l'assistenza": "Contatta l'assistenza",
    "Evidenzia": "Evidenzia",
    "Togli": "Togli",
    "Riassunti e parole chiave estensione": "Riassunti e parole chiave estensione",

    /* BANNER */
    "Dicci di più su di te": "Dicci di più su di te",
    "Studente": "Studente",
    "Docente": "Docente",
    "Altro": "Altro",
    "(specificare)": "(specificare)",
    "Invia": "Invia",
    "Hai un codice licenza?": "Hai un codice licenza?",


}
export const FileTranslationsES = {
    "Gestore dei File": "Gestor de archivos",
    "Ordine alfabetico": "Orden alfabético",
    "Stai filtrando per tag:": "Filtrado por etiquetas:",
    "Dal più recente": "Del más reciente",
    "Dal meno recente": "Desde el menos reciente",
    "Cerca file": "Búsqueda de archivos",
    "Carica File": "Sube tu archivo",
    "Mappa da PDF": "PDF a mapa",
    "Analizza": "Analiza",
    "Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.": "Añade este dispositivo a la lista de autorizados. Pulse aquí.",
    "Carica il tuo primo documento": "Cargue su primer documento",
    "Annulla": "Cancelar",
    "Prosegui": "Continuar",
    "Sezione": "Sección",
    "Sezioni": "Secciones",
    "Crea Mappa": "Crear mapa",
    "Crea riassunto!": "Cree resumen!",
    "Crea parole chiave!": "Cree palabras clave!",

    "Rinomina file": "Renombrar archivo",

    "Visualizza Lista": "Ver lista",
    "Visualizza Griglia": "Ver cuadrícula",
    "Lingua": "Idioma",
    "Lingua del testo": "Idioma del texto",
    "Inglese": "Inglés",
    "Italiano": "Italiano",
    "Spagnolo": "Español",
    "Francese": "Francés",

    "Il tuo PDF è pronto": "Tu PDF está listo",
    "Scarica il PDF": "Descargar el PDF",

    "I tuoi file": "Tus archivos",
    "Rimuovi file": "Eliminar archivo",
    "Che tipo di file vuoi caricare?": "¿Qué tipo de archivo desea cargar?",
    "Foto scrittura a mano": "Foto de notas escritas a mano",
    "PDF/IMG libro/testo": "PDF/IMG libro/texto",

    //SUMMARY AND KEYWORDS RELATED
    "Genera riassunto": "Genera el resumen",
    "Genera": "Genera",
    "Genera parole chiave": "Genera las palabras clave",
    "Stiamo preparando il tuo riassunto!": "Preparación del resumen!",
    "Ti avviseremo appena sarà pronto": "Te avisaremos en cuanto esté listo",
    "Stiamo preparando le tue parole chiave!": "Preparación de las palabras clave!",
    "Stiamo preparando la tua mappa!": "¡Estamos preparando su mapa!",
    "Ti avviseremo appena sarà pronta": "Le avisaremos en cuanto esté listo",
    "Cancella riassunto": "Elimina el resumen",
    "Cancella parole chiave": "Elimina las palabras clave",
    "Scarica riassunto pdf": "Descarga el resumen en PDF",
    "Scarica pdf": "Descarga en PDF",
    "Scarica parole chiave pdf": "Descarga las palabras clave en PDF",
    "Il tuo riassunto è pronto!": "Ton resumen está listo",
    "Le parole chiave sono pronte!": "Tus palabras clave están listas!",
    "La tua mappa è pronta!": "Su mapa está listo!",
    "Ho capito": "He entendido",
    "Parole chiave": "Palabras clave",
    "Riassunto": "Resumen",
    "Clicca le sezioni che vuoi trasformare in riassunto...": "Haz clic en las secciones que quieres convertir en un resumen...",
    "Clicca le sezioni da cui estrarre le parole chiave...": "Haz clic en las secciones de las que deseas extraer palabras clave...",
    "Sintesi vocale": "Síntesis de habla",
    "Mappa concettuale": "Mapa conceptual",
    "Modifica documento": "Modificar el documento",
    "Modalità lettura": "Modo lectura",

    "Attendi giusto qualche secondo, stiamo processando il tuo documento...": "Espere solo unos segundos, estamos procesando su documento...",
    "Clicca le sezioni che vuoi trasformare in mappa concettuale...": "Haz clic en las secciones que quieres convertir en un mapa conceptual...",
    "Non sei proprietario di questo documento": "No eres propietario de este documento",


    "Mappa": "Mapa",
    "Modifica": "Editar",
    "Funzioni": "Funciones",


    "Ascolta il testo": "Escucha el texto",
    "Crea una mappa concettuale": "Crea un mapa conceptual",
    "Modifica il documento caricato": "Edita el documento cargado",
    "Crea un riassunto": "Crea un resumen",
    "Individua le parole chiave": "Identifica las palabras clave",
    "Aumenta o diminuisci la dimensione del documento": "Aumenta o disminuye el tamaño del documento",
    "Scorri le pagine del documento": "Desplázate por las páginas del documento",
    "Scarica il documento in pdf": "Descarga el documento en pdf",
    "Ultimo": "Último",
    "Precedente": "Previo",
    "Salta": "Omite",
    "Salta tutti": "Omitir todo",
    "Benvenuto su Reasy!\nSegui i tutorial per esplorare l’app": "¡Bienvenido a Reasy!\nSigue los tutoriales para explorar la aplicación",
    "Il tutorial sarà sempre disponibile premendo qui": "El tutorial siempre estará disponible presionando aquí",
    "Carica un documento di testo.\n- Foto di un testo scritto a mano\n- PDF/JPG/PNG di un libro o testo": "Sube un documento de texto.\n- Foto de un texto escrito a mano\n- PDF/JPG/PNG de un libro o texto",
    "Cambia la modalità di visualizzazione dei file": "Cambia el modo de visualización de los archivos",
    "Premi qui per cambiare il titolo del file o cancellarlo": "Presiona aquí para cambiar el título del archivo o eliminarlo",
    "Premi qui per visualizzare il file che hai caricato": "Presiona aquí para visualizar el archivo que has subido",


    "Mappa vuota": "Mapa vacío",
    "Le tue Mappe": "Tus mapas",
    "Che titolo ha la tua Mappa?": "¿Cuál es el título de tu mapa?",
    "Crea mappa vuota": "Crear mapa vacío",
    "Ti trovi qui": "Te encuentras aquí",
    "Contatta l'assistenza": "Contacta el soporte",
    "Evidenzia": "Resaltar",
    "Togli": "Quitar",
    "Riassunti e parole chiave estensione": "Resúmenes y palabras clave extensión",


    /* BANNER */
    "Dicci di più su di te": "Cuéntanos más sobre ti",
    "Studente": "Estudiante",
    "Docente": "Profesor",
    "Altro": "Otro",
    "(specificare)": "(especificar)",
    "Invia": "Enviar",
    "Hai un codice licenza?": "¿Tienes un código de licencia?",

}
export const FileTranslationsFR = {
    "Gestore dei File": "File Manager",
    "Ordine alfabetico": "Alphabetical order",
    "Stai filtrando per tag:": "Filter by tags:",
    "Dal più recente": "Newest",
    "Dal meno recente": "Oldest",
    "Cerca file": "Search file",
    "Analizza": "Analyses",
    "Carica File": "Télécharger un fichier",
    "Mappa da PDF": "PDF vers carte",
    "Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.": "Ajouter ce dispositif à la liste autorisée. Tapez ici",
    "Carica il tuo primo documento": "Upload your first document",
    "Annulla": "Annuler",
    "Prosegui": "Continuer",
    "Sezione": "Section",
    "Sezioni": "Sections",
    "Crea Mappa": "Crea schéma",
    "Crea riassunto!": "créer un résumé!",
    "Crea parole chiave!": "Créer des mots-clés!",

    "Visualizza Lista": "Affichage de la liste",
    "Visualizza Griglia": "Affichage de la grille",
    "Lingua": "Langue",
    "Lingua del testo": "Langue du texte",
    "Inglese": "Anglais",
    "Italiano": "Italien",
    "Spagnolo": "Espagnol",
    "Francese": "Français",
    "Rinomina file": "Renommer le fichier",
    "I tuoi file": "Vos fichiers",
    "Rimuovi file": "Supprimer le fichier",
    "Che tipo di file vuoi caricare?": "Quel type de fichier voulez-vous télécharger?",
    "Foto scrittura a mano": "Photo d'écriture à la main",
    "PDF/IMG libro/testo": "PDF/IMG livre/texte",

    "Il tuo PDF è pronto": "Votre PDF est prêt",
    "Scarica il PDF": "Télécharger le PDF",

    //SUMMARY AND KEYWORDS RELATED
    "Genera riassunto": "Générer un résumé",
    "Genera": "Générer",
    "Genera parole chiave": "Générer les mots-clés",
    "Stiamo preparando il tuo riassunto!": "Préparation du résumé en cours!",
    "Ti avviseremo appena sarà pronto": "Vous serez averti lorsqu'il sera disponible",
    "Stiamo preparando le tue parole chiave!": "Préparation des mots-clés en cours!",
    "Stiamo preparando la tua mappa!": "Nous préparons votre carte!",
    "Ti avviseremo appena sarà pronta": "Nous vous informerons dès qu'il sera prêt.",
    "Cancella riassunto": "Supprimer le résumé",
    "Cancella parole chiave": "Supprimer les mots-clés",
    "Scarica riassunto pdf": "Télécharger le résumé en PDF",
    "Scarica pdf": "Télécharger en PDF",
    "Scarica parole chiave pdf": "Télécharger les mots-clés en PDF",
    "Il tuo riassunto è pronto!": "Votre résumé est disponible",
    "Le parole chiave sono pronte!": "Vos mots-clés sont disponibles!",
    "La tua mappa è pronta!": "Votre carte est prête!",
    "Ho capito": "J'ai compris",
    "Parole chiave": "Mots-clés",
    "Riassunto": "Résumé",
    "Clicca le sezioni che vuoi trasformare in riassunto...": "Cliquez sur les sections que vous souhaitez transformer en résumé...",
    "Clicca le sezioni da cui estrarre le parole chiave...": "Cliquez sur les sections à partir desquelles extraire les mots clés...",
    "Sintesi vocale": "Synthèse vocale",
    "Mappa concettuale": "Schéma conceptuel",
    "Modifica documento": "Modifier le fichier",
    "Modalità lettura": "Mode lecture",

    "Attendi giusto qualche secondo, stiamo processando il tuo documento...": "Veuillez patienter quelques secondes, nous traitons votre document...",
    "Clicca le sezioni che vuoi trasformare in mappa concettuale...": "Cliquez sur les sections que vous souhaitez transformer en carte conceptuelle...",
    "Non sei proprietario di questo documento": "Vous n'êtes pas propriétaire de ce document",


    "Mappa": "Carte",
    "Modifica": "Éditer",
    "Funzioni": "Fonctions",


    "Ascolta il testo": "Écoutez le texte",
    "Crea una mappa concettuale": "Créez une carte conceptuelle",
    "Modifica il documento caricato": "Modifiez le document téléchargé",
    "Crea un riassunto": "Créez un résumé avec",
    "Individua le parole chiave": "Identifiez les mots-clés",
    "Aumenta o diminuisci la dimensione del documento": "Augmente ou diminue la taille du document",
    "Scorri le pagine del documento": "Faites défiler les pages du document",
    "Scarica il documento in pdf": "Téléchargez le document en pdf",
    "Ultimo": "Dernier",
    "Precedente": "Retour",
    "Salta": "Saute",
    "Salta tutti": "Saute tout",

    "Benvenuto su Reasy!\nSegui i tutorial per esplorare l’app": "Bienvenue sur Reasy !\nSuivez les tutoriels pour explorer l'application",
    "Il tutorial sarà sempre disponibile premendo qui": "Le tutoriel sera toujours disponible en appuyant ici",
    "Carica un documento di testo.\n- Foto di un testo scritto a mano\n- PDF/JPG/PNG di un libro o testo": "Téléchargez un document texte.\n- Photo d'un texte écrit à la main\n- PDF/JPG/PNG d'un livre ou texte",
    "Cambia la modalità di visualizzazione dei file": "Changez le mode d'affichage des fichiers",
    "Premi qui per cambiare il titolo del file o cancellarlo": "Appuyez ici pour changer le titre du fichier ou le supprimer",
    "Premi qui per visualizzare il file che hai caricato": "Appuyez ici pour voir le fichier que vous avez téléchargé",


    "Mappa vuota": "Carte vide",
    "Le tue Mappe": "Tes cartes",
    "Che titolo ha la tua Mappa?": "Quel est le titre de ta carte?",
    "Crea mappa vuota": "Créer une carte vide",
    "Ti trovi qui": "Vous êtes ici",
    "Contatta l'assistenza": "Contactez le support",

    "Evidenzia": "Surligner",
    "Togli": "Supprimer",
    "Riassunti e parole chiave estensione": "Résumés et mots-clés extension",

    "Dicci di più su di te": "Parlez-nous de vous",

    /* BANNER */
    "Studente": "Étudiant",
    "Docente": "Enseignant",
    "Altro": "Autre",
    "(specificare)": " (préciser)",
    "Invia": "Envoyer",
    "Hai un codice licenza?": "Avez-vous un code de licence?",

}
export const FileTranslationsDE = {
    "Gestore dei File": "Dateimanager",
    "Salva": "Speichern",
    "Ordine alfabetico": "Alphabetische Reihenfolge",
    "Stai filtrando per tag:": "Sie filtern nach Tags:",
    "Dal più recente": "Von Neuestem",
    "Dal meno recente": "Von Ältestem",
    "Rinomina file": "Datei umbenennen",
    "Indietro": "Zurück",
    "Carica File": "Datei hochladen",
    "Mappa da PDF": "PDF zur Karte",
    "Avanti": "Weiter",
    "Pulisci": "Löschen",
    "Disegna": "Zeichnen",
    "Annulla": "Abbrechen",
    "Prosegui": "Weiter",
    "Cancella": "Löschen",
    "Analizza": "Analysieren",
    "Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.": "Fügen Sie dieses Gerät zur autorisierten Liste hinzu. Tippen Sie hier.",
    "Carica il tuo primo documento": "Laden Sie Ihr erstes Dokument hoch",

    "Sezione": "Abschnitt",
    "Sezioni": "Abschnitte",
    "Crea Mappa": "Karte erstelAbschnittelen",
    "Crea riassunto!": "Zusammenfassung erstellen!",
    "Crea parole chiave!": "Schlüsselwörter erstellen!",

    "Il tuo PDF è pronto": "Ihr PDF ist bereit",
    "Scarica il PDF": "PDF herunterladen",

    "Visualizza Lista": "Liste anzeigen",
    "Visualizza Griglia": "Raster anzeigen",
    "Lingua": "Sprache",
    "Lingua del testo": "Textsprache",
    "Inglese": "Englisch",
    "Italiano": "Italienisch",
    "Spagnolo": "Spanisch",
    "Francese": "Französisch",
    "I tuoi file": "Ihre Dateien",
    "Rimuovi file": "Datei entfernen",
    "Che tipo di file vuoi caricare?": "Welchen Dateityp möchten Sie hochladen?",
    "Foto scrittura a mano": "Handgeschriebenes Foto",
    "PDF/IMG libro/testo": "PDF/IMG Buch/Text",

    //SUMMARY AND KEYWORDS RELATED
    "Genera riassunto": "Zusammenfassung generieren",
    "Genera": "Generieren",
    "Genera parole chiave": "Schlüsselwörter generieren",
    "Stiamo preparando il tuo riassunto!": "Wir bereiten Ihre Zusammenfassung vor!",
    "Ti avviseremo appena sarà pronto": "Wir werden Sie benachrichtigen, sobald es fertig ist",
    "Stiamo preparando le tue parole chiave!": "Wir bereiten Ihre Schlüsselwörter vor!",
    "Stiamo preparando la tua mappa!": "Wir bereiten Ihre Karte vor!",
    "Ti avviseremo appena sarà pronta": "Wir werden Sie benachrichtigen, sobald er fertig ist",
    "Cancella riassunto": "Zusammenfassung löschen",
    "Cancella parole chiave": "Schlüsselwörter löschen",
    "Scarica riassunto pdf": " PDF herunterladen",
    "Scarica pdf": "PDF herunterladen",
    "Scarica parole chiave pdf": "PDF herunterladen",
    "Il tuo riassunto è pronto!": "Ihre Zusammenfassung ist fertig!",
    "Le parole chiave sono pronte!": "Die Schlüsselwörter sind bereit!",
    "La tua mappa è pronta!": "Ihre Karte ist fertig!",
    "Ho capito": "Ich verstehe",
    "Parole chiave": "Schlüsselwörter",
    "Riassunto": "Zusammenfassung",
    "Clicca le sezioni che vuoi trasformare in riassunto...": "Klicken Sie auf die Abschnitte, die Sie in eine Zusammenfassung umwandeln möchten...",
    "Clicca le sezioni da cui estrarre le parole chiave...": "Klicken Sie auf die Abschnitte, aus denen Sie Schlüsselwörter extrahieren möchten...",
    "Sintesi vocale": "Sprachsynthese",
    "Mappa concettuale": "Konzeptkarte",
    "Modifica documento": "Dokument bearbeiten",
    "Modalità lettura": "Lesemodus",

    "Attendi giusto qualche secondo, stiamo processando il tuo documento...": "Warten Sie nur einen Moment, wir verarbeiten Ihr Dokument...",
    "Clicca le sezioni che vuoi trasformare in mappa concettuale...": "Klicken Sie auf die Abschnitte, die Sie in eine Konzeptkarte umwandeln möchten...",
    "Non sei proprietario di questo documento": "Sie sind nicht der Eigentümer dieses Dokuments",

    "Mappa": "Karte",
    "Modifica": "Bearbeiten",
    "Funzioni": "Funktionen",

    "Benvenuto su Reasy!\nSegui i tutorial per esplorare l’app": "Willkommen bei Reasy!\nFolgen Sie den Tutorials, um die App zu erkunden",
    "Il tutorial sarà sempre disponibile premendo qui": "Das Tutorial ist immer verfügbar, indem Sie hier drücken",
    "Carica un documento di testo.\n- Foto di un testo scritto a mano\n- PDF/JPG/PNG di un libro o testo": "Laden Sie ein Textdokument hoch.\n- Foto von handgeschriebenem Text\n- PDF/JPG/PNG eines Buches oder Textes",
    "Cambia la modalità di visualizzazione dei file": "Ändern Sie den Anzeigemodus der Dateien",
    "Premi qui per cambiare il titolo del file o cancellarlo": "Drücken Sie hier, um den Dateititel zu ändern oder ihn zu löschen",
    "Premi qui per visualizzare il file che hai caricato": "Drücken Sie hier, um die Datei anzuzeigen, die Sie hochgeladen haben",

    "Ascolta il testo": "Hören Sie sich den Text an",
    "Crea una mappa concettuale": "Erstellen Sie eine Konzeptkarte",
    "Modifica il documento caricato": "Bearbeiten Sie das hochgeladene Dokument",
    "Crea un riassunto": "Erstellen Sie eine Zusammenfassung",
    "Individua le parole chiave": "Identifiziere die Schlüsselwörter",
    "Aumenta o diminuisci la dimensione del documento": "Erhöhe oder verringere die Größe des Dokuments",
    "Scorri le pagine del documento": "Durch Dokumentseiten scrollen",
    "Scarica il documento in pdf": "Laden Sie das Dokument als PDF herunter",


    "Precedente": "Zurück",
    "Ultimo": "Letzte",
    "Salta": "Überspringen",

    "Mappa vuota": "Leere Karte",
    "Le tue Mappe": "Deine Karten",
    "Che titolo ha la tua Mappa?": "Wie lautet der Titel deiner Karte?",
    "Crea mappa vuota": "Leere Karte erstellen",
    "Ti trovi qui": "Du bist hier",
    "Contatta l'assistenza": "Kontaktieren Sie den Support",

    "Evidenzia": "Hervorheben",
    "Togli": "Entfernen",
    "Riassunti e parole chiave estensione": "Zusammenfassungen und Schlüsselwörter Erweiterung",

    /* BANNER */
    "Dicci di più su di te": "Erzähle uns mehr über dich",
    "Studente": "Schüler",
    "Docente": "Lehrer",
    "Altro": "Andere",
    "(specificare)": "(bitte angeben)",
    "Invia": "Senden",
    "Hai un codice licenza?": " Hast du einen Lizenzcode?",
}
export const FileTranslationsCA = {
    "Gestore dei File": "Gestor de fitxers",
    "Salva": "Desa",
    "Ordine alfabetico": "Ordre alfabètic",
    "Stai filtrando per tag:": "Estàs filtrant per etiqueta:",
    "Dal più recente": "Des del més recent",
    "Dal meno recente": "Des del menys recent",
    "Rinomina file": "Reanomena fitxer",
    "Indietro": "Endarrera",
    "Carica File": "Carrega fitxer",
    "Mappa da PDF": "PDF al mapa",
    "Avanti": "Endavant",
    "Pulisci": "Neteja",
    "Disegna": "Dibuixa",
    "Annulla": "Desfés",
    "Prosegui": "Segueix",
    "Cancella": "Esborra",
    "Analizza": "Analitza",
    "Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.": "Afegeix aquest dispositiu a la llista d'autoritzats. Toca aquí.",
    "Carica il tuo primo documento": "Carrega el teu primer document",

    "Sezione": "Secció",
    "Sezioni": "Seccions",
    "Crea Mappa": "Crea Mapa",
    "Crea riassunto!": "Crea resum!",
    "Crea parole chiave!": "Crea paraules clau!",

    "Il tuo PDF è pronto": "El teu PDF està llest",
    "Scarica il PDF": "Descarrega el PDF",

    "Visualizza Lista": "Visualitza Llista",
    "Visualizza Griglia": "Visualitza Graella",
    "Lingua": "Llengua",
    "Lingua del testo": "Llengua del text",
    "Inglese": "Anglès",
    "Italiano": "Italià",
    "Spagnolo": "Espanyol",
    "Francese": "Francès",
    "I tuoi file": "El teus fitxers",
    "Rimuovi file": "Suprimeix fitxer",
    "Che tipo di file vuoi caricare?": "Quin tipus de fitxer vols carregar?",
    "Foto scrittura a mano": "Foto d'escriptura a mà",
    "PDF/IMG libro/testo": "PDF/IMG llibre/text",

    //SUMMARY AND KEYWORDS RELATED
    "Genera riassunto": "Genera resum",
    "Genera": "Genera",
    "Genera parole chiave": "Genera paraules clau",
    "Stiamo preparando il tuo riassunto!": "Estem preparant el teu resum!",
    "Ti avviseremo appena sarà pronto": "Et notificarem tan aviat estigui llest.",
    "Stiamo preparando le tue parole chiave!": "Estem preparant les teves paraules clau!",
    "Stiamo preparando la tua mappa!": "Estem preparant el teu mapa!",
    "Ti avviseremo appena sarà pronta": "T'avisarem tan aviat com estigui llest",
    "Cancella riassunto": "Esborra resum",
    "Cancella parole chiave": "Esborra paraules clau",
    "Scarica riassunto pdf": "Descarrega resum PDF",
    "Scarica pdf": "Descarrega PDF",
    "Scarica parole chiave pdf": "Descarrega paraules clau PDF",
    "Il tuo riassunto è pronto!": "El teu resum està llest!",
    "Le parole chiave sono pronte!": "Les paraules clau estan llestes!",
    "La tua mappa è pronta!": "La teva mapa està llesta!",
    "Ho capito": "Entenc",
    "Parole chiave": "Paraules clau",
    "Riassunto": "Resum",
    "Clicca le sezioni che vuoi trasformare in riassunto...": "Fes clic a les seccions que vols convertir en resum...",
    "Clicca le sezioni da cui estrarre le parole chiave...": "Fes clic a les seccions des d'on vols extreure les paraules clau...",
    "Sintesi vocale": "Síntesi de veu",
    "Mappa concettuale": "Mapa conceptual",
    "Modifica documento": "Edita document",
    "Modalità lettura": "Mode lectura",


    "Attendi giusto qualche secondo, stiamo processando il tuo documento...": "Si us plau, espera només uns segons, estem processant el teu document...",
    "Clicca le sezioni che vuoi trasformare in mappa concettuale...": "Fes clic a les seccions que vols convertir en mapa conceptual...",
    "Non sei proprietario di questo documento": "No ets propietari d'aquest document",

    "Mappa": "Mapa",
    "Modifica": "Edita",
    "Funzioni": "Funcions",

    "Benvenuto su Reasy!\nSegui i tutorial per esplorare l’app": "Benvingut a Reasy!\nSegueix els tutorials per explorar l'aplicació",
    "Il tutorial sarà sempre disponibile premendo qui": "El tutorial sempre estarà disponible prement aquí",
    "Carica un documento di testo.\n- Foto di un testo scritto a mano\n- PDF/JPG/PNG di un libro o testo": "Puja un document de text.\n- Foto d'un text escrit a mà\n- PDF/JPG/PNG d'un llibre o text",
    "Cambia la modalità di visualizzazione dei file": "Canvia la manera de visualitzar els arxius",
    "Premi qui per cambiare il titolo del file o cancellarlo": "Prem aquí per canviar el títol de l'arxiu o esborrar-lo",
    "Premi qui per visualizzare il file che hai caricato": "Prem aquí per veure l'arxiu que has pujat",
    "Ascolta il testo": "Escolta el text",
    "Crea una mappa concettuale": "Crea un mapa conceptual",
    "Modifica il documento caricato": "Edita el document carregat",
    "Crea un riassunto": "Crea un resum",
    "Individua le parole chiave": "Identifica les paraules clau",
    "Aumenta o diminuisci la dimensione del documento": "Augmenta o disminueix la mida del document",
    "Scorri le pagine del documento": "Desplaça les pàgines del document",
    "Scarica il documento in pdf": "Descarrega el document en PDF",


    "Precedente": "Anterior",
    "Ultimo": "Últim",
    "Salta": "Salta",

    "Mappa vuota": "Mapa buit",
    "Le tue Mappe": "Els teus mapes",
    "Che titolo ha la tua Mappa?": "Quin títol té el teu mapa?",
    "Crea mappa vuota": "Crea un mapa buit",
    "Ti trovi qui": "Et trobes aquí",

    "Contatta l'assistenza": "Contacta el suport",
    "Evidenzia": "Destacar",
    "Togli": "Eliminar",
    "Riassunti e parole chiave estensione": "Resums i paraules clau extensió",

    /* BANNER */
    "Dicci di più su di te": "Digues-nos més sobre tu",
    "Studente": "Estudiant",
    "Docente": "Professor",
    "Altro": "Altre",
    "(specificare)": "(especificar)",
    "Invia": "Enviar",
    "Hai un codice licenza?": "Tens un codi de llicència?",
}
export const FileTranslationsPT = {
    "Gestore dei File": "Gestor de ficheiros",
    "Salva": "Desa",
    "Ordine alfabetico": "Ordem alfabética",
    "Stai filtrando per tag:": "Está filtrando por etiqueta:",
    "Dal più recente": "Do mais recente",
    "Dal meno recente": "Do menos recente",
    "Rinomina file": "Renomear arquivo",
    "Indietro": "Voltar",
    "Carica File": "Carregar Arquivo",
    "Mappa da PDF": "PDF para Mapa",
    "Avanti": "Avançar",
    "Pulisci": "Limpar",
    "Disegna": "Desenhar",
    "Annulla": "Desfazer",
    "Prosegui": "Continuar",
    "Cancella": "Excluir",
    "Analizza": "Analisar",
    "Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.": "Adicionar este dispositivo à lista de autorizados. Toque aqui.",
    "Carica il tuo primo documento": "Carregue o seu primeiro documento",


    "Sezione": "Seção",
    "Sezioni": "Seções",
    "Crea Mappa": "Cria Mapa",
    "Crea riassunto!": "Cria resumo!",
    "Crea parole chiave!": "Cria palavras-chave!",

    "Il tuo PDF è pronto": "O seu PDF está pronto",
    "Scarica il PDF": "Descarregar o PDF",

    "Visualizza Lista": "Visualizar Lista",
    "Visualizza Griglia": "Visualizar Grade",
    "Lingua": "Idioma",
    "Lingua del testo": "Idioma do texto",
    "Inglese": "Inglês",
    "Italiano": "Italiano",
    "Spagnolo": "Espanhol",
    "Francese": "Francês",
    "I tuoi file": "Seus arquivos",
    "Rimuovi file": "Remover arquivo",
    "Che tipo di file vuoi caricare?": "Que tipo de arquivo deseja carregar?",
    "Foto scrittura a mano": "Foto de escrita à mão",
    "PDF/IMG libro/testo": "PDF/IMG livro/texto",


    //SUMMARY AND KEYWORDS RELATED
    "Genera riassunto": "Gerar resumo",
    "Genera": "Gerar",
    "Genera parole chiave": "Gerar palavras-chave",
    "Stiamo preparando il tuo riassunto!": "Estamos preparando o seu resumo!",
    "Ti avviseremo appena sarà pronto": "Vamos notificá-lo assim que estiver pronto.",
    "Stiamo preparando le tue parole chiave!": "Estamos preparando suas palavras-chave!",
    "Stiamo preparando la tua mappa!": "Estamos preparando o seu mapa!",
    "Ti avviseremo appena sarà pronta": "Vamos notificá-lo assim que estiver pronto",
    "Cancella riassunto": "Apagar resumo",
    "Cancella parole chiave": "Apagar palavras-chave",
    "Scarica riassunto pdf": "Baixar resumo PDF",
    "Scarica pdf": "Baixar PDF",
    "Scarica parole chiave pdf": "Baixar palavras-chave PDF",
    "Il tuo riassunto è pronto!": "Seu resumo está pronto!",
    "Le parole chiave sono pronte!": "As palavras-chave estão prontas!",
    "La tua mappa è pronta!": "Seu mapa está pronto!",
    "Ho capito": "Entendi",
    "Parole chiave": "Palavras-chave",
    "Riassunto": "Resumo",
    "Clicca le sezioni che vuoi trasformare in riassunto...": "Clique nas seções que deseja transformar em resumo...",
    "Clicca le sezioni da cui estrarre le parole chiave...": "Clique nas seções das quais deseja extrair as palavras-chave...",
    "Sintesi vocale": "Síntese de voz",
    "Mappa concettuale": "Mapa conceitual",
    "Modifica documento": "Editar documento",
    "Modalità lettura": "Modo leitura",


    "Attendi giusto qualche secondo, stiamo processando il tuo documento...": "Por favor, espere apenas alguns segundos, estamos processando o seu documento...",
    "Clicca le sezioni che vuoi trasformare in mappa concettuale...": "Clique nas seções que deseja transformar em mapa conceitual...",
    "Non sei proprietario di questo documento": "Você não é o proprietário deste documento",

    "Mappa": "Mapa",
    "Modifica": "Editar",
    "Funzioni": "Funções",

    "Benvenuto su Reasy!\nSegui i tutorial per esplorare l’app": "Bem-vindo ao Reasy!\nSiga os tutoriais para explorar o aplicativo",
    "Il tutorial sarà sempre disponibile premendo qui": "O tutorial estará sempre disponível clicando aqui",
    "Carica un documento di testo.\n- Foto di un testo scritto a mano\n- PDF/JPG/PNG di un libro o testo": "Carregue um documento de texto.\n- Foto de um texto escrito à mão\n- PDF/JPG/PNG de um livro ou texto",
    "Cambia la modalità di visualizzazione dei file": "Mude o modo de visualização dos arquivos",
    "Premi qui per cambiare il titolo del file o cancellarlo": "Clique aqui para alterar o título do arquivo ou excluí-lo",
    "Premi qui per visualizzare il file che hai caricato": "Clique aqui para visualizar o arquivo que você carregou",
    "Ascolta il testo": "Ouça o texto",
    "Crea una mappa concettuale": "Crie um mapa conceitual",
    "Modifica il documento caricato": "Edite o documento carregado",
    "Crea un riassunto": "Crie um resumo",
    "Individua le parole chiave": "Identifique as palavras-chave",
    "Aumenta o diminuisci la dimensione del documento": "Aumente ou diminua o tamanho do documento",
    "Scorri le pagine del documento": "Navegue pelas páginas do documento",
    "Scarica il documento in pdf": "Baixe o documento em PDF",


    "Precedente": "Anterior",
    "Ultimo": "Último",
    "Salta": "Pular",

    "Mappa vuota": "Mapa vazio",
    "Le tue Mappe": "Seus mapas",
    "Che titolo ha la tua Mappa?": "Qual é o título do seu mapa?",
    "Crea mappa vuota": "Crie um mapa vazio",
    "Ti trovi qui": "Você está aqui",

    "Contatta l'assistenza": "Contate o suporte",
    "Evidenzia": "Realçar",
    "Togli": "Remover",
    "Riassunti e parole chiave estensione": "Extensão de resumos e palavras-chave",

    /* BANNER */
    "Dicci di più su di te": "Fale-nos mais sobre você",
    "Studente": "Estudante",
    "Docente": "Professor",
    "Altro": "Outro",
    "(specificare)": "(especificar)",
    "Invia": "Enviar",
    "Hai un codice licenza?": "Tem um código de licença?",
}
export const FileTranslationsCZ = {
    "Gestore dei File": "Správce souborů",
    "Salva": "Uložit",
    "Ordine alfabetico": "Abecední pořadí",
    "Stai filtrando per tag:": "Filtrujete podle tagu:",
    "Dal più recente": "Od nejnovějšího",
    "Dal meno recente": "Od nejstaršího",
    "Rinomina file": "Přejmenovat soubor",
    "Indietro": "Zpět",
    "Carica File": "Nahrát soubor",
    "Mappa da PDF": "Mapa z PDF",
    "Pulisci": "Vyčistit",
    "Disegna": "Kreslit",
    "Annulla": "Zrušit",
    "Prosegui": "Pokračovat",
    "Cancella": "Smazat",
    "Analizza": "Analyzovat",
    "Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.": "Přidejte toto zařízení do seznamu autorizovaných. Klepněte zde.",
    "Carica il tuo primo documento": "Nahrajte svůj první dokument",
    "Sezione": "Sekce",
    "Sezioni": "Sekce",
    "Crea Mappa": "Vytvořit mapu",
    "Crea riassunto!": "Vytvořit shrnutí!",
    "Crea parole chiave!": "Vytvořit klíčová slova!",
    "Il tuo PDF è pronto": "Váš PDF je připraven",
    "Scarica il PDF": "Stáhnout PDF",
    "Visualizza Lista": "Zobrazit seznam",
    "Visualizza Griglia": "Zobrazit mřížku",
    "Lingua": "Jazyk",
    "Lingua del testo": "Jazyk textu",
    "Inglese": "Angličtina",
    "Italiano": "Italština",
    "Spagnolo": "Španělština",
    "Francese": "Francouzština",
    "I tuoi file": "Vaše soubory",
    "Rimuovi file": "Odstranit soubor",
    "Che tipo di file vuoi caricare?": "Jaký typ souboru chcete nahrát?",
    "Foto scrittura a mano": "Fotografie psaní rukou",
    "PDF/IMG libro/testo": "PDF/Obrázek knihy/text",


    //SUMMARY AND KEYWORDS RELATED
    "Genera riassunto": "Generuj shrnutí",
    "Genera": "Generuj",
    "Genera parole chiave": "Generuj klíčová slova",
    "Stiamo preparando il tuo riassunto!": "Připravujeme vaše shrnutí!",
    "Ti avviseremo appena sarà pronto": "Upozorníme vás, jakmile bude připraveno",
    "Stiamo preparando le tue parole chiave!": "Připravujeme vaše klíčová slova!",
    "Stiamo preparando la tua mappa!": "Připravujeme vaši mapu!",
    "Ti avviseremo appena sarà pronta": "Upozorníme vás, jakmile bude připraveno",
    "Cancella riassunto": "Smazat shrnutí",
    "Cancella parole chiave": "Smazat klíčová slova",
    "Scarica riassunto pdf": "Stáhnout shrnutí pdf",
    "Scarica pdf": "Stáhnout pdf",
    "Scarica parole chiave pdf": "Stáhnout pdf klíčová slova",
    "Il tuo riassunto è pronto!": "Vaše shrnutí je připraveno!",
    "Le parole chiave sono pronte!": "Klíčová slova jsou připravena!",
    "La tua mappa è pronta!": "Vaše mapa je připravena!",
    "Ho capito": "Rozumím",
    "Parole chiave": "Klíčová slova",
    "Riassunto": "Shrnutí",
    "Clicca le sezioni che vuoi trasformare in riassunto...": "Klikněte na sekce, které chcete převést na shrnutí...",
    "Clicca le sezioni da cui estrarre le parole chiave...": "Klikněte na sekce, ze kterých chcete extrahovat klíčová slova...",
    "Sintesi vocale": "Hlasová syntéza",
    "Mappa concettuale": "Koncepční mapa",
    "Modifica documento": "Upravit dokument",
    "Modalità lettura": "Režimu čtení",


    "Attendi giusto qualche secondo, stiamo processando il tuo documento...": "Počkejte jen pár sekund, zpracováváme váš dokument...",
    "Clicca le sezioni che vuoi trasformare in mappa concettuale...": "Klikněte na sekce, které chcete převést na koncepční mapu...",
    "Non sei proprietario di questo documento": "Nejste vlastníkem tohoto dokumentu",
    "Mappa": "Mapa",
    "Modifica": "Upravit",
    "Funzioni": "Funkce",
    "Benvenuto su Reasy!\nSegui i tutorial per esplorare l’app": "Vítejte v Reasy!\nSledujte průvodce prozkoumáním aplikace",
    "Il tutorial sarà sempre disponibile premendo qui": "Průvodce bude vždy k dispozici stisknutím zde",
    "Carica un documento di testo.\n- Foto di un testo scritto a mano\n- PDF/JPG/PNG di un libro o testo": "Nahrajte dokument s textem.\n- Fotografie textu psaného rukou\n- PDF/JPG/PNG knihy nebo textu",
    "Cambia la modalità di visualizzazione dei file": "Změnit způsob zobrazení souborů",
    "Premi qui per cambiare il titolo del file o cancellarlo": "Stiskněte zde pro změnu názvu souboru nebo jeho smazání",
    "Premi qui per visualizzare il file che hai caricato": "Stiskněte zde pro zobrazení nahráného souboru",
    "Ascolta il testo": "Poslechněte si text",
    "Crea una mappa concettuale": "Vytvořit koncepční mapu",
    "Modifica il documento caricato": "Upravit nahrávaný dokument",
    "Crea un riassunto": "Vytvořit shrnutí",
    "Individua le parole chiave": "Najít klíčová slova",
    "Controlli dimensione del documento": "Ovládání velikosti dokumentu",
    "Aumenta o diminuisci la dimensione del documento": "Zvětšit nebo zmenšit velikost dokumentu",
    "Scorri le pagine del documento": "Posunout stránky dokumentu",
    "Scarica il documento in pdf": "Stáhnout dokument jako pdf",
    "Avanti": "Dál",
    "Ultimo": "Poslední",
    "Salta": "Přeskočit",
    "Precedente": "Předchozí",
    "Mappa vuota": "Prázdná mapa",
    "Le tue Mappe": "Vaše mapy",
    "Che titolo ha la tua Mappa?": "Jaký název má vaše mapa?",
    "Crea mappa vuota": "Vytvořit prázdnou mapu",
    "Ti trovi qui": "Nacházíte se zde",
    "Contatta l'assistenza": "Kontaktovat podporu",
    "Evidenzia": "Zvýraznit",
    "Togli": "Odebrat",
    "Riassunti e parole chiave estensione": "Riassunti a klíčová slova rozšíření",

    /* BANNER */
    "Dicci di più su di te": "Řekněte nám více o sobě",
    "Studente": "Student",
    "Docente": "Učitel",
    "Altro": "Jiné",
    "(specificare)": "(uveďte)",
    "Invia": "Odeslat",
    "Hai un codice licenza?": " Máte licenční kód?",
}
export const FileTranslationsNL = {
    "Gestore dei File": "Bestandsbeheer",
    "Salva": "Opslaan",
    "Ordine alfabetico": "Alfabetische volgorde",
    "Stai filtrando per tag:": "Je filtert op tag:",
    "Dal più recente": "Van meest recent",
    "Dal meno recente": "Van minst recent",
    "Rinomina file": "Bestand hernoemen",
    "Indietro": "Terug",
    "Carica File": "Bestand uploaden",
    "Mappa da PDF": "Kaart van PDF",
    "Pulisci": "Wissen",
    "Disegna": "Tekenen",
    "Annulla": "Annuleren",
    "Prosegui": "Doorgaan",
    "Cancella": "Verwijderen",
    "Analizza": "Analyseren",
    "Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.": "Voeg dit apparaat toe aan de lijst met geautoriseerde apparaten. Tik hier.",
    "Carica il tuo primo documento": "Upload je eerste document",

    "Sezione": "Sectie",
    "Sezioni": "Secties",
    "Crea Mappa": "Kaart maken",
    "Crea riassunto!": "Maak samenvatting!",
    "Crea parole chiave!": "Maak sleutelwoorden!",

    "Il tuo PDF è pronto": "Je PDF is klaar",
    "Scarica il PDF": "Download de PDF",

    "Visualizza Lista": "Weergave lijst",
    "Visualizza Griglia": "Weergave raster",
    "Lingua": "Taal",
    "Lingua del testo": "Taal van de tekst",
    "Inglese": "Engels",
    "Italiano": "Italiaans",
    "Spagnolo": "Spaans",
    "Francese": "Frans",
    "I tuoi file": "Je bestanden",
    "Rimuovi file": "Bestand verwijderen",
    "Che tipo di file vuoi caricare?": "Welk type bestand wil je uploaden?",
    "Foto scrittura a mano": "Foto handschrift",
    "PDF/IMG libro/testo": "PDF/IMG boek/tekst",


    //SUMMARY AND KEYWORDS RELATED
    "Genera riassunto": "Samenvatting genereren",
    "Genera": "Genereren",
    "Genera parole chiave": "Sleutelwoorden genereren",
    "Stiamo preparando il tuo riassunto!": "We bereiden je samenvatting voor!",
    "Ti avviseremo appena sarà pronto": "We laten je weten zodra het klaar is",
    "Stiamo preparando le tue parole chiave!": "We bereiden je sleutelwoorden voor!",
    "Stiamo preparando la tua mappa!": "We bereiden je kaart voor!",
    "Ti avviseremo appena sarà pronta": "We laten je weten zodra het klaar is",
    "Cancella riassunto": "Samenvatting verwijderen",
    "Cancella parole chiave": "Sleutelwoorden verwijderen",
    "Scarica riassunto pdf": "Download samenvatting pdf",
    "Scarica pdf": "Download pdf",
    "Scarica parole chiave pdf": "Download sleutelwoorden pdf",
    "Il tuo riassunto è pronto!": "Je samenvatting is klaar!",
    "Le parole chiave sono pronte!": "De sleutelwoorden zijn klaar!",
    "La tua mappa è pronta!": "Je kaart is klaar!",
    "Ho capito": "Begrepen",
    "Parole chiave": "Sleutelwoorden",
    "Riassunto": "Samenvatting",
    "Clicca le sezioni che vuoi trasformare in riassunto...": "Klik op de secties die je in een samenvatting wilt omzetten...",
    "Clicca le sezioni da cui estrarre le parole chiave...": "Klik op de secties waarvan je sleutelwoorden wilt extraheren...",
    "Sintesi vocale": "Spraaksynthese",
    "Mappa concettuale": "Conceptmap",
    "Modifica documento": "Document bewerken",
    "Modalità lettura": "Leesmodus",


    "Attendi giusto qualche secondo, stiamo processando il tuo documento...": "Even geduld, we verwerken je document...",
    "Clicca le sezioni che vuoi trasformare in mappa concettuale...": "Klik op de secties die je in een conceptmap wilt omzetten...",
    "Non sei proprietario di questo documento": "Je bent geen eigenaar van dit document",

    "Mappa": "Kaart",
    "Modifica": "Bewerken",
    "Funzioni": "Functies",

    "Benvenuto su Reasy!\nSegui i tutorial per esplorare l’app": "Welkom bij Reasy!\nVolg de tutorials om de app te verkennen",
    "Il tutorial sarà sempre disponibile premendo qui": "De tutorial is altijd beschikbaar door hier te drukken",
    "Carica un documento di testo.\n- Foto di un testo scritto a mano\n- PDF/JPG/PNG di un libro o testo": "Upload een tekstdocument.\n- Foto van een handgeschreven tekst\n- PDF/JPG/PNG van een boek of tekst",
    "Cambia la modalità di visualizzazione dei file": "Wijzig de weergavemodus van de bestanden",
    "Premi qui per cambiare il titolo del file o cancellarlo": "Druk hier om de bestandsnaam te wijzigen of te verwijderen",
    "Premi qui per visualizzare il file che hai caricato": "Druk hier om het geüploade bestand te bekijken",

    "Ascolta il testo": "Luister naar de tekst",
    "Crea una mappa concettuale": "Maak een conceptmap",
    "Modifica il documento caricato": "Bewerk het geüploade document",
    "Crea un riassunto": "Maak een samenvatting",
    "Individua le parole chiave": "Identificeer de sleutelwoorden",
    "Controlli dimensione del documento": "Documentgrootte controles",
    "Aumenta o diminuisci la dimensione del documento": "Vergroot of verklein de documentgrootte",
    "Scorri le pagine del documento": "Scroll door de pagina's van het document",
    "Scarica il documento in pdf": "Download het document als pdf",
    "Avanti": "Verder",
    "Ultimo": "Laatste",
    "Salta": "Overslaan",
    "Precedente": "Vorige",

    "Mappa vuota": "Lege kaart",
    "Le tue Mappe": "Je kaarten",
    "Che titolo ha la tua Mappa?": "Wat is de titel van je kaart?",
    "Crea mappa vuota": "Maak een lege kaart",
    "Ti trovi qui": "Je bent hier",

    "Contatta l'assistenza": "Contacteer ondersteuning",
    "Evidenzia": "Markeren",
    "Togli": "Verwijderen",
    "Riassunti e parole chiave estensione": "Samenvattingen en sleutelwoorden extensie",

    /* BANNER */
    "Dicci di più su di te": "Vertel ons meer over jezelf",
    "Studente": "Student",
    "Docente": "Docent",
    "Altro": "Anders",
    "(specificare)": "(specificeren)",
    "Invia": "Verzenden",
    "Hai un codice licenza?": "Heeft u een licentiecode?",

}
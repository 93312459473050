export const OnboardingTranslationsEN = {
    "Ti diamo il benvenuto su Reasy": "Welcome on Reasy",
    "Reasy, l’unico strumento di cui avrai bisogno" : "Reasy, your must-have tool",
    "Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.":"Upload a pdf and Reasy will turn it into digital. You won't need to transcribe it",
    "Lo strumento per semplificare lo studio" : "The tool to simplify the study",
    "Avanti" : "Continue",
    'Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile':"In section 'Accessibility' you will find preferences to make text more understandable",
    "Carica un documento":"Upload a file",
    "Configurazioni" : "Setup",
    "Inizia subito" : "Get started",
}
export const OnboardingTranslationsIT = {
    "Ti diamo il benvenuto su Reasy": "Ti diamo il benvenuto su Reasy",
    "Reasy, l’unico strumento di cui avrai bisogno" : "Reasy, l’unico strumento di cui avrai bisogno",
    "Avanti" : "Avanti",
    "Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.":"Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.",
    "Lo strumento per semplificare lo studio" : "Lo strumento per semplificare lo studio",
    "Carica un documento":"Carica un documento",
    "Configurazioni" : "Configurazioni",
    'Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile':'Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile',
    "Inizia subito" : "Inizia subito",
}
export const OnboardingTranslationsES = {
    "Ti diamo il benvenuto su Reasy": "Ti diamo il benvenuto su Reasy",
    "Reasy, l’unico strumento di cui avrai bisogno" : "Reasy, la aplicación que necesitas tener ",
    "Avanti" : "Siguiente",
    "Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.":"Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.",
    "Lo strumento per semplificare lo studio" : "Lo strumento per semplificare lo studio",
    "Carica un documento":"Sube tu archivo",
    "Configurazioni" : "Ajustes",
    'Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile':'Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile',
    "Inizia subito" : "Inizia subito",
}
export const OnboardingTranslationsFR = {
    "Ti diamo il benvenuto su Reasy": "Ti diamo il benvenuto su Reasy",
    "Reasy, l’unico strumento di cui avrai bisogno" : "Reasy, l’unico strumento di cui avrai bisogno",
    "Avanti" : "Avanti",
    "Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.":"Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.",
    "Lo strumento per semplificare lo studio" : "Lo strumento per semplificare lo studio",
    "Carica un documento":"Carica un documento",
    "Configurazioni" : "Panneau de configuration",
    'Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile':'Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile',
    "Inizia subito" : "Inizia subito",
}
export const OnboardingTranslationsDE = {
    "Ti diamo il benvenuto su Reasy": "Wir begrüßen Sie bei Reasy",
    "Reasy, l’unico strumento di cui avrai bisogno" : "Reasy, das einzige Werkzeug, das du benötigen wirst",
    "Avanti" : "Weiter",
    "Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.":"Laden Sie eine PDF-Datei hoch, und Reasy wird sie in digitalen Text umwandeln. Sie müssen sie nicht abtippen",
    "Lo strumento per semplificare lo studio" : "Das Werkzeug zur Vereinfachung des Lernens",
    "Carica un documento":"Laden Sie ein Dokument hoch",
    "Configurazioni" : "Konfigurationen",
    'Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile':'In der "Barrierefreiheit"-Sektion finden Sie Einstellungen, um den Text besser verständlich zu machen',
    "Inizia subito" : "Jetzt beginnen",
}
export const OnboardingTranslationsPT = {
    "Ti diamo il benvenuto su Reasy": "Damos-lhe as boas-vindas ao Reasy",
    "Reasy, l’unico strumento di cui avrai bisogno": "Reasy, a única ferramenta que você vai precisar",
    "Avanti": "Avançar",
    "Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.": "Carregue um PDF e o Reasy o transformará em digital. Você não precisará transcrevê-lo.",
    "Lo strumento per semplificare lo studio": "A ferramenta para simplificar o estudo",
    "Carica un documento": "Carregue um documento",
    "Configurazioni": "Configurações",
    "Nella sezione 'Accessibilità' troverai le preferenze per rendere il testo maggiormente comprensibile": "Na seção 'Acessibilidade', você encontrará preferências para tornar o texto mais compreensível",
    "Inizia subito": "Comece agora",
}
export const OnboardingTranslationsCZ = {
    "Ti diamo il benvenuto su Reasy": "Vítejte na Reasy",
    "Reasy, l’unico strumento di cui avrai bisogno" : "Reasy, jediný nástroj, který budete potřebovat",
    "Avanti" : "Dál",
    "Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.":"Nahrajte PDF a Reasy jej převede na digitální formát. Nebudete ho muset přepisovat.",
    "Lo strumento per semplificare lo studio" : "Nástroj pro zjednodušení studia",
    "Carica un documento":"Nahrajte dokument",
    "Configurazioni" : "Nastavení",
    'Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile':'V sekci "Přístupnost" najdete preference pro zvýšení srozumitelnosti textu',
    "Inizia subito" : "Začít ihned",
}
export const OnboardingTranslationsNL = {
    "Ti diamo il benvenuto su Reasy": "Welkom bij Reasy",
    "Reasy, l’unico strumento di cui avrai bisogno" : "Reasy, de enige tool die je nodig hebt",
    "Avanti" : "Volgende",
    "Carica un pdf e Reasy lo strasformarà in digitale. Non avrai bisogno di trascriverlo.":"Upload een pdf-bestand en Reasy zal het digitaliseren. Je hoeft het niet over te typen.",
    "Lo strumento per semplificare lo studio" : "Het gereedschap om het studeren te vereenvoudigen",
    "Carica un documento":"Upload een document",
    "Configurazioni" : "Instellingen",
    'Nella sezione "Accessibilità" troverai le preferenze per rendere il testo maggiormente comprensibile':'In het "Toegankelijkheid" gedeelte vind je voorkeuren om de tekst begrijpelijker te maken',
    "Inizia subito" : "Begin nu",
}
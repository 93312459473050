import {
    IonAccordion,
    IonAccordionGroup,
    IonButton, IonIcon, IonItem, IonLabel, IonList, IonModal,
    IonRadio, IonRadioGroup, IonRange, IonToggle, IonToolbar, isPlatform
} from '@ionic/react';
import { addOutline, chevronBack, chevronForward, closeOutline, contrastOutline, downloadOutline, eyeOffOutline, eyeOutline, gitBranchOutline, helpCircleOutline, removeOutline, textOutline } from 'ionicons/icons';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Trans } from 'react-i18next';
import TypeLink from "../../../assets/icons/TypeLink.svg";
import defaultConnectionDark from '../../../assets/icons/defaultConnectionDark.svg';
import defaultConnectionLight from '../../../assets/icons/defaultConnectionLight.svg';
import floatingConnectionDark from '../../../assets/icons/floatingConnectionDark.svg';
import floatingConnectionLight from '../../../assets/icons/floatingConnectionLight.svg';
import smoothConnectionDark from '../../../assets/icons/smoothConnectionDark.svg';
import smoothConnectionLight from '../../../assets/icons/smoothConnectionLight.svg';
import mindMapIconDark from '../../../assets/icons/mind_map_icon_dark.svg';
import mindMapIconLight from '../../../assets/icons/mind_map_icon_light.svg';
import topDownTreeDark from '../../../assets/icons/top_down_tree_dark.svg';
import topDownTreeLight from '../../../assets/icons/top_down_tree_light.svg';
import leftRightTreeDark from '../../../assets/icons/left_right_tree_dark.svg';
import leftRightTreeLight from '../../../assets/icons/left_right_tree_light.svg';

import Joyride from "react-joyride";
import { handleJoyrideCallback, Step, updateChoice } from "../../../common/utilsJoyride";
import { auth } from '../../../firebaseConfig';
import { settingsServices } from '../../settings/store/services';
import { sendEvent } from '../../../common/amplitudeUtils';
import i18n from '../../../i18n';
import { appVersion } from '../../../appVersion';
import { getDarkMode } from './utils';


type Props = {
    isModalMenuOpen: boolean,
    modalMenuPage: 'main' | 'text' | 'edgeType',
    isUppercase: boolean,
    fontSettings: any,
    edgeType: string,
    fillNodeColors: boolean,
    showTextOnImages: boolean,
    organizationName: string | null,

    switchPage: (page: string) => void,
    toggleUppercase: () => void,
    fontMinusClicked: () => void,
    fontPlusClicked: () => void,
    fontRangeDidChange: (e: any) => void,
    edgeTypeDidChange: (e: any) => void,
    fillNodesClicked: () => void,
    showImagesClicked: () => void,
    downloadButtonClicked: () => void,
    connectNodesClicked: () => void,
    connectionTypeClicked: () => void,
    textClicked: () => void,
    autoLayout(algorithm: string, direction: 'DOWN' | 'RIGHT' | 'RADIAL'): void,
    closeModal: () => void,

};

type State = {
    stepsSideMenu: Step[];
    tutorialSideMenu: boolean;
    stepIndexSideMenu: number;
    userUuid: string;
    closeModal: boolean | undefined;
};



export class MapFormatModal extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            stepsSideMenu: [
                {
                    target: "#fillNode",
                    content: (
                        <div>
                            <p>
                                <Trans>Colora l’interno dei nodi</Trans>
                            </p>
                            {/* <iframe
                                width="280"
                                height="157"
                                src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/18+-+Come+caricare+un+appunto+scritto+a+mano.mp4"
                                title="YouTube video player"
                                allowFullScreen
                                autoplay
                              ></iframe> */}
                            {/* <video
                        className='tutorialVideo'
                        autoPlay
                        controls
                        controlsList="nodownload"
                      >
                        <source
                          src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/3.+Il+menu.mp4"
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video> */}

                        </div>
                    ),
                    disableBeacon: true,
                    placement: "left",
                },
                {
                    target: "#showImg",
                    content: (
                        <div>
                            <p>
                                <Trans>Mostra testo è immagini</Trans>
                            </p>

                        </div>
                    ),
                    disableBeacon: true,
                    placement: "left",
                },
                {
                    target: "#connectNodes",
                    content: (
                        <div>
                            <p>
                                <Trans>Connetti nodi</Trans>
                            </p>

                        </div>
                    ),
                    disableBeacon: true,
                    placement: "left",
                }, {
                    target: "#connectionType",
                    content: (
                        <div>
                            <p>
                                <Trans>Scegli il tipo di collegamento</Trans>
                            </p>

                        </div>
                    ),
                    disableBeacon: true,
                    placement: "left",
                },
                {
                    target: "#txtOptions",
                    content: (
                        <div>
                            <p>
                                <Trans>Scegli il testo maiuscolo o minuscolo</Trans>
                            </p>

                        </div>
                    ),
                    disableBeacon: true,
                    placement: "left",
                },
            ],
            tutorialSideMenu: false,
            stepIndexSideMenu: 0,
            userUuid: "",
            closeModal: undefined,
        }
    }

    updateChoice(): void {
        auth.onAuthStateChanged(async (userData) => {
            if (userData) {
                this.setState({ userUuid: userData.uid })
                settingsServices
                    .getUserTutorialChoice(userData.uid, "tM")
                    .then((response) => {
                        console.log(response);
                        this.setState({ tutorialSideMenu: response }, () => {
                            if (response === true) {

                            }
                        });
                    });
            }
        });
    }
    componentDidMount(): void {
        auth.onAuthStateChanged(async (userData) => {
            if (userData) {
                this.setState({ userUuid: userData.uid })
            }
        })
        this.setState({ closeModal: this.props.isModalMenuOpen }, () => { console.log(this.state.closeModal) })
    }
    render() {
        return (
            <IonModal
                id='mindMapModalMenu'
                mode='md'
                isOpen={this.props.isModalMenuOpen}
                // onDidPresent={() => this.updateChoice()}
                showBackdrop={false}
                onDidDismiss={() => this.props.closeModal()}
                initialBreakpoint={1}
                breakpoints={[0, 1]}
            >
                <Joyride
                    steps={this.state.stepsSideMenu}
                    run={this.state.tutorialSideMenu} // Set to true to start the tour automatically
                    continuous={true} // Allow continuous navigation through the tour
                    showProgress={true} // Display progress indicator
                    showSkipButton={true} // Display skip button
                    spotlightClicks={true}
                    callback={(data) => handleJoyrideCallback.call(this, data, "Map format modal")}
                    hideBackButton={false}
                    locale={{
                        skip: (
                            <div className="skip" onClick={() => { updateChoice.call(this, "tutorialMapModal") }}>
                                <Trans>Salta</Trans>
                            </div>
                        ),
                        back: (
                            <div className="nextTutorialBtn">
                                <Trans>Precedente</Trans>
                            </div>
                        ),
                        next: (
                            <span>
                                <Trans>Avanti</Trans>
                            </span>
                        ),
                        last: (
                            <div className="nextTutorialBtn"
                                onClick={() => { updateChoice.call(this, "tutorialMapModal") }}>
                                <Trans>Ultimo</Trans>
                            </div>
                        ),
                    }}
                    styles={{
                        buttonNext: {
                            fontSize: "12px",
                            outline: "none",
                        },
                    }}
                />
                <IonToolbar className='toolbarModalMap'>
                    <div className='toolbarMindMapModalMenu'>
                        <IonButton hidden={this.props.modalMenuPage === 'main'} className='Button' color='white'
                            onClick={() => {
                                // this.setState({ modalMenuPage: 'main' })
                                this.props.switchPage('main')
                            }}>
                            <IonIcon icon={chevronBack} />
                        </IonButton>
                        <p style={{ marginLeft: '15px', fontSize: '20px' }}>
                            <Trans>
                                {this.props.modalMenuPage === 'edgeType' ? 'Tipo di collegamento' : 'Formattazione Mappa' &&
                                    this.props.modalMenuPage === 'text' ? 'Testi' : 'Formattazione Mappa'}
                            </Trans>
                        </p>
                    </div>
                    <IonButton className='Button' color='white' slot='end'
                        onClick={() => {
                            this.props.closeModal()
                            // this.setState({ isModalMenuOpen: false })

                        }}>

                        <IonIcon icon={closeOutline} />
                    </IonButton>
                </IonToolbar>

                <IonList className='modalMenuList'>
                    <div className='colorFillModalMenu' style={{ /* marginBottom: '20px' */ }}>
                        <IonItem id="fillNode" className='modalMenuItem' lines='none'>
                            <IonIcon size='large' color={this.props.fillNodeColors ? 'danger' : 'success'} icon={contrastOutline} />
                            <span style={{ margin: '10px' }}><Trans>Riempi nodi</Trans></span>

                            <IonToggle mode='ios' slot='end' className='mapsModalMenuToggle' onClick={() => {
                                this.props.fillNodesClicked();
                                /* this.props.toggleColorFill();
                                for (let i = 0; i < document.getElementsByClassName('react-flow__node').length; i++) {
                                    if (!this.props.fillNodeColors) {
                                        const node = document.getElementsByClassName('react-flow__node')[i] as HTMLElement;
                                        node.style.backgroundColor = ''
                                    }
                                    else {
                                        const node = document.getElementsByClassName('react-flow__node')[i] as HTMLElement;
                                        node.style.backgroundColor = nodes[i].style!.backgroundColor!
                                    }
                                } */
                            }}>
                            </IonToggle>
                        </IonItem>

                    </div>
                    <div className='showImageModalMenu' style={{ /* marginBottom: '20px'  */ }}>
                        <IonItem id="showImg" className='modalMenuItem' lines='none'>
                            <IonIcon size='large' className='modalMenuMapIcons' icon={this.props.showTextOnImages ? eyeOutline : eyeOffOutline} />
                            <span style={{ margin: '10px' }}><Trans>Mostra immagini</Trans></span>

                            <IonToggle mode='ios' slot='end' className='mapsModalMenuToggle' onClick={() => {
                                this.props.showImagesClicked();
                                // let nodesWithImage = nodes.filter((node: any) => node.image != null)
                                // let nodesWithImageIds = nodesWithImage.map(node => node.id)
                                // this.setState({ showTextOnImages: !this.state.showTextOnImages }, () => {
                                //     for (let i = 0; i < document.getElementsByClassName('react-flow__node').length; i++) {
                                //         let nodeElement = (document.getElementsByClassName('react-flow__node')[i] as HTMLElement)
                                //         if (nodesWithImageIds.includes(nodeElement.getAttribute('data-id')!)) {
                                //             if (this.state.showTextOnImages === true) {
                                //                 nodeElement.style.fontSize = this.state.fontSettings.fontSize + "px";
                                //             }
                                //             else
                                //                 nodeElement.style.fontSize = '0px'
                                //         }
                                //     }
                                // })

                            }}>
                            </IonToggle>
                        </IonItem>
                    </div>
                    <div className='downloadMapsModalMenu ' style={{ /* marginBottom: '20px' */ }}>
                        <IonButton hidden={isMobile} className='Button' id="download" onClick={() => {
                            this.props.downloadButtonClicked()
                        }
                        } style={{ /* marginBottom: '20px' */ }}>
                            <IonIcon icon={downloadOutline} size='large' />
                            <span style={{ margin: '10px' }}> Download Mappa</span>
                        </IonButton>
                    </div>
                    <div>
                        <IonItem id="connectNodes" lines='none' className='modalMenuItem' style={{ /* marginBottom: '20px' */ }} onClick={() => {
                            this.props.connectNodesClicked()
                            // this.setState({ connectingMode: !this.state.connectingMode, selectedNodesToConnect: [] })
                            // // if (isMobile) {
                            // this.setState({ selectedNodesToConnect: [], isModalMenuOpen: false })
                        }}>
                            {/* <IonLabel className='typeMaps'> */}
                            <IonIcon icon={gitBranchOutline} className='modalMenuMapIcons' size='large' />
                            <span style={{ margin: '10px' }}><Trans>Collegamento nodi</Trans></span>
                            {/* </IonLabel> */}
                        </IonItem>
                    </div>
                    <IonAccordionGroup multiple={true}>
                        <IonAccordion value="edgeType" className='modalMenuAccordion'>
                            <IonItem slot="header" className='modalMenuItem' lines='none'>
                                <IonIcon size='large' icon={TypeLink} className='typeLink modalMenuMapIcons'></IonIcon>
                                <IonLabel style={{ margin: '10px' }}><Trans>Tipo di collegamento</Trans></IonLabel>
                            </IonItem>
                            <div className="ion-padding" slot="content" style={{ background: "var(--bg-content-color)" }}>
                                <div className='changeEdgeType'>
                                    <IonRadioGroup
                                        value={this.props.edgeType}
                                        style={{ width: '100%' }}
                                        onIonChange={(e) => {
                                            this.props.edgeTypeDidChange(e);
                                            // edges.forEach(edge => edge.type = e.detail.value);
                                            // this.setState({ edgeType: e.detail.value });
                                        }}>
                                        <div className='mapsEdgeTypeMainDiv'>
                                            <div
                                                onClick={() => {
                                                    document.getElementById('radioSmooth')?.click()
                                                }}
                                                className='mapsEdgeTypeItem'
                                            >
                                                <IonIcon style={{ fontSize: '60px' }} icon={smoothConnectionDark} />
                                                <span style={{ margin: '10px' }}>Smooth</span>
                                                <IonRadio id='radioSmooth' value={'smoothstep'} />
                                            </div>
                                            <div onClick={() => {
                                                document.getElementById('radioDefault')?.click()
                                            }}
                                                className='mapsEdgeTypeItem'
                                            >
                                                <IonIcon style={{ fontSize: '60px' }} icon={defaultConnectionDark} />
                                                <span style={{ margin: '10px' }}>Default</span>
                                                <IonRadio id='radioDefault' value={'default'} />
                                            </div>
                                            <div onClick={() => {
                                                document.getElementById('radioFloating')?.click()
                                            }}
                                                className='mapsEdgeTypeItem'
                                            >
                                                <IonIcon style={{ fontSize: '60px' }} icon={floatingConnectionDark} />
                                                <span style={{ margin: '10px' }}>Floating</span>
                                                <IonRadio id='radioFloating' value={'floating'} />
                                            </div>
                                        </div>
                                    </IonRadioGroup>
                                </div>
                            </div>
                        </IonAccordion>
                        <IonAccordion value="texts" className='modalMenuAccordion'>
                            <IonItem slot="header" className='modalMenuItem' lines='none'>
                                <IonIcon icon={textOutline} size='large' />
                                <IonLabel style={{ margin: '10px' }}><Trans>Testi</Trans></IonLabel>
                            </IonItem>
                            <div className="ion-padding" slot="content" style={{ background: "var(--bg-content-color)" }}>
                                <div className='fontModalMenu'>
                                    <IonItem lines='none' className='colorButtonMaps'>
                                        <IonToggle
                                            checked={!this.props.isUppercase}
                                            mode='ios'
                                            onIonChange={() => {
                                                this.props.toggleUppercase();
                                                for (let i = 0; i < document.getElementsByClassName('react-flow__edge').length; i++) {
                                                    const edge = document.getElementsByClassName('react-flow__edge')[i] as HTMLElement;
                                                    if (!this.props.isUppercase) {
                                                        edge.style.textTransform = 'none';
                                                    }
                                                    else {
                                                        edge.style.textTransform = 'uppercase';
                                                    }
                                                }
                                                for (let i = 0; i < document.getElementsByClassName('react-flow__node').length; i++) {
                                                    const node = document.getElementsByClassName('react-flow__node')[i] as HTMLElement;
                                                    if (!this.props.isUppercase) {
                                                        node.style.textTransform = 'none';
                                                    }
                                                    else {
                                                        node.style.textTransform = 'uppercase';
                                                    }
                                                }
                                            }}

                                        />
                                        {/* <IonButton style={{ width: '50px', height: '50px' }} color={!this.props.isUppercase ? 'warning' : 'tertiary'}
                                            onClick={() => {
                                                this.props.toggleUppercase();
                                                for (let i = 0; i < document.getElementsByClassName('react-flow__edge').length; i++) {
                                                    const edge = document.getElementsByClassName('react-flow__edge')[i] as HTMLElement;
                                                    if (!this.props.isUppercase) {
                                                        edge.style.textTransform = 'none';
                                                    }
                                                    else {
                                                        edge.style.textTransform = 'uppercase';
                                                    }
                                                }
                                                for (let i = 0; i < document.getElementsByClassName('react-flow__node').length; i++) {
                                                    const node = document.getElementsByClassName('react-flow__node')[i] as HTMLElement;
                                                    if (!this.props.isUppercase) {
                                                        node.style.textTransform = 'none';
                                                    }
                                                    else {
                                                        node.style.textTransform = 'uppercase';
                                                    }
                                                }
                                            }}>
                                            <IonIcon icon={textOutline} size='large' />
                                        </IonButton> */}
                                        <IonLabel>
                                            <span style={{ margin: '10px', fontSize: '15px', color: 'var(--txt-color)' }}><Trans>Testo MAIUSCOLO</Trans></span>
                                        </IonLabel>
                                    </IonItem>

                                    {/* <p className='settingsFontSizeParagraph'>{this.props.fontSettings?.fontSize} </p> */}
                                    <p style={{ textAlign: 'center', color: "var(--txt-color)" }}><Trans>Dimensione Font</Trans></p>
                                    <div className='fontSizeModalMenu'>
                                        <IonButton
                                            className='settingsDocumentViewingRangeButton'
                                            fill='clear'
                                            onClick={() => {
                                                this.props.fontMinusClicked();
                                            }}>
                                            <IonIcon icon={removeOutline} size='medium' />
                                        </IonButton>


                                        <div style={{ display: "contents" }}>
                                            <IonRange id='MapSettingsFontSizeModalMenu'
                                                mode='ios'
                                                max={110} min={10} ticks={false} snaps value={this.props.fontSettings?.fontSize} debounce={300}
                                                onIonChange={(e) => {
                                                    this.props.fontRangeDidChange(e)
                                                }} />

                                        </div>
                                        <IonButton
                                            className='settingsDocumentViewingRangeButton'
                                            fill='clear'
                                            onClick={() => {
                                                this.props.fontPlusClicked();
                                            }}>
                                            <IonIcon icon={addOutline} size='medium' />
                                        </IonButton>
                                    </div>
                                </div>
                            </div>
                        </IonAccordion>
                        <IonAccordion value="layout" className='modalMenuAccordion'>
                            <IonItem slot="header" className='modalMenuItem' lines='none'>
                                <IonIcon icon={getDarkMode() ? mindMapIconDark : mindMapIconLight} size='large' />
                                <IonLabel style={{ margin: '10px' }}><Trans>Cambia layout</Trans></IonLabel>
                            </IonItem>
                            <div className="ion-padding" slot="content" style={{ background: "var(--bg-content-color)" }}>
                                <div className='modalAutoLayoutDiv'>
                                    <div
                                        onClick={() => {
                                            this.props.autoLayout('radial', 'RADIAL')
                                        }}
                                        className='mapsEdgeTypeItem'
                                    >
                                        <IonIcon style={{ fontSize: '60px' }} icon={getDarkMode() ? mindMapIconDark : mindMapIconLight} />
                                        <span style={{ margin: '10px' }}><Trans>Mappa mentale</Trans></span>
                                    </div>
                                    <div onClick={() => {
                                        this.props.autoLayout('mrtree', 'DOWN')
                                    }}
                                        className='mapsEdgeTypeItem'
                                    >
                                        <IonIcon style={{ fontSize: '60px' }} icon={getDarkMode() ? topDownTreeDark : topDownTreeLight} />
                                        <span style={{ margin: '10px' }}><Trans>Verticale</Trans></span>
                                    </div>
                                    <div onClick={() => {
                                        this.props.autoLayout('mrtree', 'RIGHT')
                                    }}
                                        className='mapsEdgeTypeItem'
                                    >
                                        <IonIcon style={{ fontSize: '60px' }} icon={getDarkMode() ? leftRightTreeDark : leftRightTreeLight} />
                                        <span style={{ margin: '10px' }}><Trans>Orizzontale</Trans></span>
                                    </div>
                                </div>
                            </div>
                        </IonAccordion>
                    </IonAccordionGroup>
                </IonList >
                <IonIcon
                    id="homeHelpIcon"
                    className="helpIcon"
                    style={{ fontSize: '35px', bottom: '0px', top: 'unset' }}
                    onClick={() => {

                        this.setState({ tutorialSideMenu: true })
                        sendEvent({
                            "user_id": this.state.userUuid,
                            "event_type": "Tutorial showed",
                            "event_properties": {
                                "user_org": this.props.organizationName !== null ? this.props.organizationName : 'Private User',
                                "page": "Map format modal"
                            },
                            "language": i18n.language,
                            "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
                            "app_version": appVersion,
                            "time": Date.now()
                        })

                    }}
                    icon={helpCircleOutline}
                ></IonIcon>
            </IonModal >
        );
    }
}

import { appConstant } from './constants';

export interface LoadingElem {
  key: string;
}

export interface Message {
  show: boolean;
  title: string;
  text: string;
}

export interface ApplicationStore {
  message: Message;
  loading: LoadingElem[];
}

const initialState: ApplicationStore = {
  message: {
    show: false,
    title: '',
    text: '',
  },
  loading: [],
};

const applicationReducer = (
  // Allow state to be ApplicationStore or undefined, and default to initialState if undefined
  state: ApplicationStore | undefined = initialState,
  action: { type: string; key?: string; title?: string; text?: string }
): ApplicationStore => {
  switch (action.type) {
    case appConstant.SHOW_LOADING:
      return {
        ...state,
        loading: [...state.loading, { key: action.key as string }],
      };

    case appConstant.HIDE_LOADING:
      return {
        ...state,
        loading: state.loading.filter((loadingElem) => loadingElem.key !== action.key),
      };

    case appConstant.SHOW_MESSAGE:
      return {
        ...state,
        message: { show: true, title: action.title as string, text: action.text as string },
      };

    case appConstant.HIDE_MESSAGE:
      return {
        ...state,
        message: { show: false, title: '', text: '' },
      };

    default:
      return state;
  }
};

export default applicationReducer;

export const SettingsTranslationsEN = {
    "Evidenziazioni": "Highlightings",
    "Colora lettere/parole": "Letters and words colouring",
    "Colorazione di lettere o parole": "Letters and words colouring",
    "Lettere da colorare": "Characters to colour",
    "Salva": "Save",
    "Configurazioni": "Settings",
    "Configurazioni font": "Font setting",
    "Dimensione font": "Font size",
    "Interlinea": "Line spacing",
    "Anteprima": "Preview",
    "Sintesi vocale": "Speech synthesis",
    "Velocità di riproduzione": "Playback speed",
    "Velocita": "Speed Rate:",
    "Lento": "Slow",
    "Medio": "Medium",
    "Veloce": "Fast",
    "Genere": "Voice type",
    "Gen": "Voice",
    "Voce 1": "Voice 1",
    "Voce 2": "Voice 2",
    "Stai visualizzando le modifiche del testo che hai scelto. Con Reasy il tuo apprendimento diventa accessibile e personalizzabile.": "You are viewing the changes in the text you have chosen. With Reasy, your learning becomes accessible and customizable.",
    "L'app Reasy utilizza anche il carattere ad alta leggibilità ": "The Reasy app also uses the highly readable font ",
    "Player TTS": "TTS Player",
    "Seleziona il colore": "Choose color",
    "Blu": "Blue",
    "Arancione": "Orange",
    "Verde": "Green",
    "Rosso": "Red",
    "Rosa": "Pink",
    "Grigio": "Grey",
    "Fine": "Save",
    "Rimuovi colore": "Remove color",
    "Ascolta la voce": "Try out",
    "Mostra": "Show",
    "Nascondi": "Hide",
    "Licenze": "Licenses",
    "Testi": "Texts",
    "Lingue": "Languages",
    "Seleziona una lingua": "Select a language",
    "Cambia Palette colori": "Change Color Palette",
    "Disconnetti": "Log Out",
    "Profilo": "Account",
    "Font": "Font",

    //COLOR PICKER POPUP

    "Cambia colore": "Color change",
    "Hue": "Hue",
    "Saturation": "Saturation",
    "Brightness": "Brightness",
    "Campioni": "Samples",
    "Recenti": "Recent",

    "Scegli il tipo di font, la sua dimensione e l'interlinea del testo": "Choose the type of font, its size and the line spacing of the text",
    "Personalizza i colori di lettere o parole specifiche nel testo": "Customize the colors of specific letters or words in the text",
    "Guarda l'anteprima delle configurazioni che hai scelto": "Preview the configurations you have chosen",
    "Scegli la velocità e il tipo di voce della sintesi vocale": "Choose the speed and type of voice for speech synthesis",
    "Cambia i colori dello sfondo di Reasy": "Change the background colors of Reasy",

}
export const SettingsTranslationsIT = {
    "Evidenziazioni": "Evidenziazioni",
    "Colora lettere/parole": "Colora lettere/parole",
    "Colorazione di lettere o parole": "Colorazione di lettere o parole",
    "Lettere da colorare": "Lettere da colorare",
    "Salva": "Salva",
    "Configurazioni": "Configurazioni",
    "Configurazioni font": "Configurazioni font",
    "Dimensione font": "Dimensione font",
    "Interlinea": "Interlinea",
    "Anteprima": "Anteprima",
    "Sintesi vocale": "Sintesi vocale",
    "Velocità di riproduzione": "Velocità di riproduzione",
    "Velocita": "Velocità:",
    "Lento": "Lento",
    "Medio": "Medio",
    "Veloce": "Veloce",
    "Genere": "Genere",
    "Gen": "Voce",
    "Voce 1": "Voce 1",
    "Voce 2": "Voce 2",
    "Stai visualizzando le modifiche del testo che hai scelto. Con Reasy il tuo apprendimento diventa accessibile e personalizzabile.": "Stai visualizzando le modifiche del testo che hai scelto. Con Reasy il tuo apprendimento diventa accessibile e personalizzabile.",
    "L'app Reasy utilizza anche il carattere ad alta leggibilità ": "L'app Reasy utilizza anche il carattere ad alta leggibilità ",
    "Player TTS": "Player TTS",
    "Ascolta la voce": "Ascolta la voce",
    "Mostra": "Mostra",
    "Nascondi": "Nascondi",
    "Licenze": "Licenze",
    "Testi": "Testi",
    "Lingue": "Lingue",
    "Seleziona una lingua": "Seleziona una lingua",
    "Cambia Palette colori": "Cambia Palette colori",
    "Disconnetti": "Disconnetti",
    "Font": "Font",

    //COLOR PICKER POPUP

    "Cambia colore": "Cambia colore",
    "Hue": "Tonalità",
    "Saturation": "Saturazione",
    "Brightness": "Luminosità",
    "Campioni": "Campioni",
    "Recenti": "Recenti",

    "Scegli il tipo di font, la sua dimensione e l'interlinea del testo": "Scegli il tipo di font, la sua dimensione e l'interlinea del testo",
    "Personalizza i colori di lettere o parole specifiche nel testo": "Personalizza i colori di lettere o parole specifiche nel testo",
    "Guarda l'anteprima delle configurazioni che hai scelto": "Guarda l'anteprima delle configurazioni che hai scelto",
    "Scegli la velocità e il tipo di voce della sintesi vocale": "Scegli la velocità e il tipo di voce della sintesi vocale",
    "Cambia i colori dello sfondo di Reasy": "Cambia i colori dello sfondo di Reasy",
}

//TO TRANSLATE!
export const SettingsTranslationsES = {
    "Evidenziazioni": "Destaca",
    "Colora lettere/parole": "Resaltar letras/palabras",
    "Colorazione di lettere o parole": "Coloreado de letras o palabras",
    "Lettere da colorare": "Letras a destacar",
    "Salva": "Guardar",
    "Configurazioni": "Ajustes",
    "Configurazioni font": "Ajustes de fuente",
    "Dimensione font": "Tamaño de fuente",
    "Interlinea": "Interlineado",
    "Anteprima": "Vista previa",
    "Sintesi vocale": "Síntesis de habla",
    "Velocità di riproduzione": "Velocidad de reproducción",
    "Velocita": "Velocidad:",
    "Lento": "Lenta",
    "Medio": "Normal",
    "Veloce": "Rápida",
    "Genere": "Voces",
    "Gen": "Voces",
    "Voce 1": "Voz 1",
    "Voce 2": "Voz 2",
    "Stai visualizzando le modifiche del testo che hai scelto. Con Reasy il tuo apprendimento diventa accessibile e personalizzabile.": "Está visualizando los cambios en el texto elegido. Con Reasy su aprendizaje se vuelve accesible y personalizable.",
    "L'app Reasy utilizza anche il carattere ad alta leggibilità ": "La aplicación Reasy también utiliza la fuente de alta legibilidad ",
    "Player TTS": "TTS Player",
    "Seleziona il colore": "Seleccione el color",
    "Blu": "Azul",
    "Arancione": "Naranja",
    "Verde": "Verde",
    "Rosso": "Rojo",
    "Rosa": "Rosa",
    "Grigio": "Gris",
    "Fine": "Finalizar",
    "Rimuovi colore": "Eliminar el color",
    "Ascolta la voce": "Escucha la voz",
    "Mostra": "Muestra",
    "Nascondi": "Oculta",
    "Licenze": "Licencias",
    "Testi": "Textos",
    "Lingue": "Idiomas",
    "Seleziona una lingua": "Seleccione un idioma",
    "Cambia Palette colori": "Cambiar paleta de colores",
    "Disconnetti": "Cerrar Sesión",
    "Font": "Fuente",

    //COLOR PICKER POPUP

    "Cambia colore": "Cambiar color",
    "Hue": "Matiz",
    "Saturation": "Saturación",
    "Brightness": "Brillo",
    "Campioni": "Muestras",
    "Recenti": "Recientes",


    "Scegli il tipo di font, la sua dimensione e l'interlinea del testo": "Elige el tipo de fuente, su tamaño y el interlineado del texto",
    "Personalizza i colori di lettere o parole specifiche nel testo": "Personaliza los colores de letras o palabras específicas en el texto",
    "Guarda l'anteprima delle configurazioni che hai scelto": "Mira la vista previa de las configuraciones que has elegido",
    "Scegli la velocità e il tipo di voce della sintesi vocale": "Elige la velocidad y el tipo de voz para la síntesis de voz",
    "Cambia i colori dello sfondo di Reasy": "Cambia los colores de fondo de Reasy"
}

//TO TRANSLATE!
export const SettingsTranslationsFR = {
    "Evidenziazioni": "Surlignages",
    "Colora lettere/parole": "Souligne les lettres/mots",
    "Colorazione di lettere o parole": "Coloration des lettres ou des mots",
    "Lettere da colorare": "Surlignez des lettres",
    "Salva": "Sauvegarder",
    "Configurazioni": "Panneau de configuration",
    "Configurazioni font": "Configurations des polices de caractères",
    "Font": "Type de police",
    "Dimensione font": "Taille des caractères",
    "Interlinea": "Interligne",
    "Anteprima": "Aperçu",
    "Sintesi vocale": "Synthèse vocale",
    "Velocità di riproduzione": "Vitesse de lecture",
    "Velocita": "Vitesse:",
    "Lento": "Lente",
    "Medio": "Normale",
    "Veloce": "Rapide",
    "Genere": "Voix",
    "Gen": "Voix",
    "Voce 1": "Voix 1",
    "Voce 2": "Voix 2",
    "Stai visualizzando le modifiche del testo che hai scelto. Con Reasy il tuo apprendimento diventa accessibile e personalizzabile.": "Vous visualisez les changements dans le texte que vous avez choisi. Avec Reasy, votre apprentissage devient accessible et personnalisable.",
    "L'app Reasy utilizza anche il carattere ad alta leggibilità ": "L'application Reasy utilise également la police à haute lisibilité ",
    "Player TTS": "TTS Player",
    "Seleziona il colore": "Sélectionnez la couleur",
    "Blu": "Bleu",
    "Arancione": "Orange",
    "Verde": "Vert",
    "Rosso": "Rouge",
    "Rosa": "Rose",
    "Grigio": "Gris",
    "Fine": "Fin",
    "Rimuovi colore": "Enlever la couleur",
    "Ascolta la voce": "Écoutez la voix",
    "Mostra": "Show",
    "Nascondi": "Hide",
    "Licenze": "Licenses",
    "Testi": "Textes",
    "Lingue": "Traduction",
    "Seleziona una lingua": "Sélectionner une langue",
    "Cambia Palette colori": "Changer la palette de couleurs",
    "Disconnetti": "Déconnecter",
    "Font": "Type de police",

    //COLOR PICKER POPUP

    "Cambia colore": "Changer de couleur",
    "Hue": "Teinte",
    "Saturation": "Saturation",
    "Brightness": "Luminosité",
    "Campioni": "Échantillons",
    "Recenti": "Récents",

    "Scegli il tipo di font, la sua dimensione e l'interlinea del testo": "Choisissez le type de police, sa taille et l'interligne du texte",
    "Personalizza i colori di lettere o parole specifiche nel testo": "Personnalisez les couleurs de lettres ou de mots spécifiques dans le texte",
    "Guarda l'anteprima delle configurazioni che hai scelto": "Prévisualisez les configurations que vous avez choisies",
    "Scegli la velocità e il tipo di voce della sintesi vocale": "Choisissez la vitesse et le type de voix de la synthèse vocale",
    "Cambia i colori dello sfondo di Reasy": "Cambia los colores del fondo de Reasy",
}
export const SettingsTranslationsDE = {
    "Evidenziazioni": "Hervorhebung",
    "Colora lettere/parole": "Farbige Buchstaben/Wörter",
    "Colorazione di lettere o parole": "Ausmalen von Buchstaben oder Wörtern ",
    "Lettere da colorare": "Buchstaben färben",
    "Salva": "Sichern",
    "Configurazioni": "Konfigurationen",
    "Configurazioni font": "Schriftart-Konfigurationen",
    "Dimensione font": "Schriftgröße",
    "Interlinea": "Abstände",
    "Anteprima": "Vorschau",
    "Sintesi vocale": "Sprachsynthese",
    "Velocità di riproduzione": "Wiedergabegeschwindigkeit",
    "Velocita": "Geschwindigkeit:",
    "Lento": "Langsam",
    "Medio": "Mittel",
    "Veloce": "Schnell",
    "Genere": "Genre",
    "Gen": "Jan",
    "Voce 1": "Stimme 1",
    "Voce 2": "Stimme 2",
    "Stai visualizzando le modifiche del testo che hai scelto. Con Reasy il tuo apprendimento diventa accessibile e personalizzabile.": "Sie visualisieren die Veränderungen in dem von Ihnen gewählten Text. Mit Reasy wird Ihr Lernen zugänglich und anpassbar.",
    "L'app Reasy utilizza anche il carattere ad alta leggibilità ": "Die Reasy-App verwendet auch die gut lesbare Schriftart ",
    "Player TTS": "TTS-Spieler",
    "Seleziona il colore": "Farbe auswählen",
    "Blu": "Blu",
    "Arancione": "Orange",
    "Verde": "Grün",
    "Rosso": "Rot",
    "Rosa": "Rosa",
    "Grigio": "Grau",
    "Fine": "Ende",
    "Rimuovi colore": "Farbe entfernen",
    "Ascolta la voce": "Hören Sie auf die Stimme",
    "Mostra": "Anzeigen",
    "Nascondi": "Ausblenden",
    "Licenze": "Lizenzen",
    "Testi": "Texte",
    "Lingue": "Sprachen",
    "Seleziona una lingua": "Wählen Sie eine Sprache",
    "Cambia Palette colori": "Farbpalette ändern",
    "Disconnetti": "Haftungsausschlüsse",
    "Profilo": "Profil",
    "Font": "Schriftart",

    //COLOR PICKER POPUP

    "Cambia colore": "Farbe ändern",
    "Hue": "Farbton",
    "Saturation": "Sättigung",
    "Brightness": "Helligkeit",
    "Campioni": "Proben",
    "Recenti": "Neueste",


    "Scegli il tipo di font, la sua dimensione e l'interlinea del testo": "Wähle die Schriftart, ihre Größe und den Zeilenabstand des Textes",
    "Personalizza i colori di lettere o parole specifiche nel testo": "Passe die Farben von bestimmten Buchstaben oder Wörtern im Text an",
    "Guarda l'anteprima delle configurazioni che hai scelto": "Sieh dir eine Vorschau der von dir gewählten Einstellungen an",
    "Scegli la velocità e il tipo di voce della sintesi vocale": "Wähle die Geschwindigkeit und die Art der Sprachsynthese",
    "Cambia i colori dello sfondo di Reasy": "Ändere die Hintergrundfarben von Reasy",
}

export const SettingsTranslationsCA = {
    "Evidenziazioni": "Destacats",
    "Colora lettere/parole": "Pinta lletres/paraules",
    "Colorazione di lettere o parole": "Coloració de lletres o paraules",
    "Lettere da colorare": "Lletres per pintar",
    "Salva": "Desa",
    "Configurazioni": "Configuracions",
    "Configurazioni font": "Configuracions de tipus de lletra",
    "Dimensione font": "Mida de la lletra",
    "Interlinea": "Interlineal",
    "Anteprima": "Previsualització",
    "Sintesi vocale": "Síntesi de veu",
    "Velocità di riproduzione": "Velocitat de reproducció",
    "Velocita": "Velocitat",
    "Lento": "Lent",
    "Medio": "Mitjà",
    "Veloce": "Ràpid",
    "Genere": "Gènere",
    "Gen": "Gen",
    "Voce 1": "Veu 1",
    "Voce 2": "Veu 2",
    "Stai visualizzando le modifiche del testo che hai scelto. Con Reasy il tuo apprendimento diventa accessibile e personalizzabile.": "Estàs veient els canvis al text que has triat. Amb Reasy, el teu aprenentatge es fa accessible i personalitzable.",
    "L'app Reasy utilizza anche il carattere ad alta leggibilità ": "L'app Reasy també utilitza el tipus de lletra d'alta llegibilitat ",
    "Player TTS": "Reproductor TTS",
    "Seleziona il colore": "Selecciona el color",
    "Blu": "Blau",
    "Arancione": "Taronja",
    "Verde": "Verd",
    "Rosso": "Vermell",
    "Rosa": "Rosa",
    "Grigio": "Gris",
    "Fine": "Fi",
    "Rimuovi colore": "Elimina el color",
    "Ascolta la voce": "Escolta la veu",
    "Mostra": "Mostra",
    "Nascondi": "Amaga",
    "Licenze": "Llicències",
    "Testi": "Textos",
    "Lingue": "Idiomes",
    "Seleziona una lingua": "Selecciona un idioma",
    "Cambia Palette colori": "Canvia la paleta de colors",
    "Disconnetti": "Desconnecta",
    "Profilo": "Perfil",
    "Font": "Tipus de lletra",

    //COLOR PICKER POPUP

    "Cambia colore": "Canvia el color",
    "Hue": "To",
    "Saturation": "Saturació",
    "Brightness": "Lluminositat",
    "Campioni": "Mostres",
    "Recenti": "Recents",

    "Scegli il tipo di font, la sua dimensione e l'interlinea del testo": "Selecciona el tipus de lletra, la seva mida i l'interlineat del text",
    "Personalizza i colori di lettere o parole specifiche nel testo": "Personalitza els colors de lletres o paraules específiques en el text",
    "Guarda l'anteprima delle configurazioni che hai scelto": "Visualitza la previsualització de les configuracions que has triat",
    "Scegli la velocità e il tipo di voce della sintesi vocale": "Selecciona la velocitat i el tipus de veu de la síntesi de veu",
    "Cambia i colori dello sfondo di Reasy": "Canvia els colors del fons de Reasy",
}

export const SettingsTranslationsPT = {
    "Evidenziazioni": "Destaques",
    "Colora lettere/parole": "Colorir letras/palavras",
    "Colorazione di lettere o parole": "Coloração de letras ou palavras",
    "Lettere da colorare": "Letras para colorir",
    "Salva": "Salvar",
    "Configurazioni": "Configurações",
    "Configurazioni font": "Configurações de fonte",
    "Dimensione font": "Tamanho da fonte",
    "Interlinea": "Interlinha",
    "Anteprima": "Pré-visualização",
    "Sintesi vocale": "Síntese de voz",
    "Velocità di riproduzione": "Velocidade de reprodução",
    "Velocita": "Velocidade",
    "Lento": "Lento",
    "Medio": "Médio",
    "Veloce": "Rápido",
    "Genere": "Gênero",
    "Gen": "Gen",
    "Voce 1": "Voz 1",
    "Voce 2": "Voz 2",
    "Stai visualizzando le modifiche del testo che hai scelto. Con Reasy il tuo apprendimento diventa accessibile e personalizzabile.": "Você está visualizando as alterações no texto que escolheu. Com Reasy, sua aprendizagem se torna acessível e personalizável.",
    "L'app Reasy utilizza anche il carattere ad alta leggibilità ": "O aplicativo Reasy também utiliza a fonte de alta legibilidade ",
    "Player TTS": "Reprodutor TTS",
    "Seleziona il colore": "Selecione a cor",
    "Blu": "Azul",
    "Arancione": "Laranja",
    "Verde": "Verde",
    "Rosso": "Vermelho",
    "Rosa": "Rosa",
    "Grigio": "Cinza",
    "Fine": "Fim",
    "Rimuovi colore": "Remover cor",
    "Ascolta la voce": "Ouvir a voz",
    "Mostra": "Mostrar",
    "Nascondi": "Esconder",
    "Licenze": "Licenças",
    "Testi": "Textos",
    "Lingue": "Idiomas",
    "Seleziona una lingua": "Selecione um idioma",
    "Cambia Palette colori": "Mudar paleta de cores",
    "Disconnetti": "Desconectar",
    "Profilo": "Perfil",
    "Font": "Fonte",


    //COLOR PICKER POPUP

    "Cambia colore": "Alterar cor",
    "Hue": "Matiz",
    "Saturation": "Saturação",
    "Brightness": "Luminosidade",
    "Campioni": "Amostras",
    "Recenti": "Recentes",

    "Scegli il tipo di font, la sua dimensione e l'interlinea del testo": "Escolha o tipo de fonte, seu tamanho e o espaçamento entre linhas do texto",
    "Personalizza i colori di lettere o parole specifiche nel testo": "Personalize as cores de letras ou palavras específicas no texto",
    "Guarda l'anteprima delle configurazioni che hai scelto": "Veja a pré-visualização das configurações que você escolheu",
    "Scegli la velocità e il tipo di voce della sintesi vocale": "Escolha a velocidade e o tipo de voz da síntese de voz",
    "Cambia i colori dello sfondo di Reasy": "Mude as cores de fundo do Reasy",
}
export const SettingsTranslationsCZ = {

    "Evidenziazioni": "Zvýraznění",
    "Colora lettere/parole": "Barva písmen/slov",
    "Colorazione di lettere o parole": "Barvení písmen nebo slov",
    "Lettere da colorare": "Písmena k vybarvení",
    "Salva": "Uložit",
    "Configurazioni": "Nastavení",
    "Configurazioni font": "Nastavení písma",
    "Dimensione font": "Velikost písma",
    "Interlinea": "Meziradkový odstup",
    "Anteprima": "Náhled",
    "Sintesi vocale": "Hlasová syntéza",
    "Velocità di riproduzione": "Rychlost přehrávání",
    "Velocita": "Rychlost",
    "Lento": "Pomalé",
    "Medio": "Střední",
    "Veloce": "Rychlé",
    "Genere": "Žánr",
    "Gen": "Gen",
    "Voce 1": "Hlas 1",
    "Voce 2": "Hlas 2",
    "Stai visualizzando le modifiche del testo che hai scelto. Con Reasy il tuo apprendimento diventa accessibile e personalizzabile.": "Zobrazujete změny ve vybraném textu. S Reasy se váš vzdělávací proces stává přístupným a personalizovaným.",
    "L'app Reasy utilizza anche il carattere ad alta leggibilità ": "Aplikace Reasy také používá písmo s vysokou čitelností ",
    "Player TTS": "Přehrávač TTS",
    "Seleziona il colore": "Vyberte barvu",
    "Blu": "Modrá",
    "Arancione": "Oranžová",
    "Verde": "Zelená",
    "Rosso": "Červená",
    "Rosa": "Růžová",
    "Grigio": "Šedá",
    "Fine": "Konec",
    "Rimuovi colore": "Odstranit barvu",
    "Ascolta la voce": "Poslouchej hlas",
    "Mostra": "Zobrazit",
    "Nascondi": "Skrýt",
    "Licenze": "Licence",
    "Testi": "Texty",
    "Lingue": "Jazyky",
    "Seleziona una lingua": "Vyberte jazyk",
    "Cambia Palette colori": "Změnit paletu barev",
    "Disconnetti": "Odpojit",
    "Profilo": "Profil",
    "Font": "Font",
    



    //COLOR PICKER POPUP

    "Cambia colore": "Alterar cor",
    "Hue": "Matiz",
    "Saturation": "Saturação",
    "Brightness": "Luminosidade",
    "Campioni": "Amostras",
    "Recenti": "Recentes",

    "Scegli il tipo di font, la sua dimensione e l'interlinea del testo": "Escolha o tipo de fonte, seu tamanho e o espaçamento entre linhas do texto",
    "Personalizza i colori di lettere o parole specifiche nel testo": "Personalize as cores de letras ou palavras específicas no texto",
    "Guarda l'anteprima delle configurazioni che hai scelto": "Veja a pré-visualização das configurações que você escolheu",
    "Scegli la velocità e il tipo di voce della sintesi vocale": "Escolha a velocidade e o tipo de voz da síntese de voz",
    "Cambia i colori dello sfondo di Reasy": "Mude as cores de fundo do Reasy",
}
export const SettingsTranslationsNL = {
    "Evidenziazioni": "Markeringen",
    "Colora lettere/parole": "Kleur letters/woorden",
    "Colorazione di lettere o parole": "Letter- of woordkleuring",
    "Lettere da colorare": "Letters om te kleuren",
    "Salva": "Opslaan",
    "Configurazioni": "Instellingen",
    "Configurazioni font": "Lettertype-instellingen",
    "Dimensione font": "Lettergrootte",
    "Interlinea": "Regelafstand",
    "Anteprima": "Voorbeeld",
    "Sintesi vocale": "Spraaksynthese",
    "Velocità di riproduzione": "Afspeelsnelheid",
    "Velocita": "Snelheid:",
    "Lento": "Langzaam",
    "Medio": "Gemiddeld",
    "Veloce": "Snel",
    "Genere": "Geslacht",
    "Gen": "Stem",
    "Voce 1": "Stem 1",
    "Voce 2": "Stem 2",
    "Stai visualizzando le modifiche del testo che hai scelto. Con Reasy il tuo apprendimento diventa accessibile e personalizzabile.": "Je bekijkt de tekstwijzigingen die je hebt gekozen. Met Reasy wordt je leerproces toegankelijk en aanpasbaar.",
    "L'app Reasy utilizza anche il carattere ad alta leggibilità ": "De Reasy-app maakt ook gebruik van een hoog leesbaar lettertype",
    "Player TTS": "TTS-speler",
    "Ascolta la voce": "Luister naar de stem",
    "Mostra": "Weergeven",
    "Nascondi": "Verbergen",
    "Licenze": "Licenties",
    "Testi": "Teksten",
    "Lingue": "Talen",
    "Seleziona una lingua": "Selecteer een taal",
    "Cambia Palette colori": "Wijzig kleurpalet",
    "Disconnetti": "Afmelden",
    "Font": "Lettertype",


    //COLOR PICKER POPUP

    "Cambia colore": "Kleur wijzigen",
    "Hue": "Tint",
    "Saturation": "Verzadiging",
    "Brightness": "Helderheid",
    "Campioni": "Stalen",
    "Recenti": "Recent",

    "Scegli il tipo di font, la sua dimensione e l'interlinea del testo": "Kies het lettertype, de grootte en de regelafstand van de tekst",
    "Personalizza i colori di lettere o parole specifiche nel testo": "Pas de kleuren van specifieke letters of woorden in de tekst aan",
    "Guarda l'anteprima delle configurazioni che hai scelto": "Bekijk een voorbeeld van de instellingen die je hebt gekozen",
    "Scegli la velocità e il tipo di voce della sintesi vocale": "Kies de snelheid en het type stem voor de spraaksynthese",
    "Cambia i colori dello sfondo di Reasy": "Wijzig de achtergrondkleuren van Reasy",
}
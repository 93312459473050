import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonLoading,
    IonPage, IonTitle, IonToolbar
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { auth } from '../../../firebaseConfig';
import "./SubscriptionsDashboard.css";
import { purchaseServices } from '../store/services';
import { UserSubscription } from '../store/reducers';
import { getPlanName, isPlatformSameAsPurchase } from '../utils/purchaseUtils';
import { Trans } from 'react-i18next';
import i18n from '../../../i18n';

type Props = {
    history: any,
};

type State = {
    userSubscriptions: UserSubscription[],
    bearerToken: string | null,
    isLoadingStripePortal: boolean,
};

class SubscriptionsDashboard extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            userSubscriptions: [],
            bearerToken: null,
            isLoadingStripePortal: false,
        }
    }

    componentDidMount(): void {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                userData.getIdToken().then(token => {
                    this.setState({ bearerToken: token })
                    purchaseServices.getUserSubscriptions(userData.uid, token)
                        .then(response => {
                            this.setState({ userSubscriptions: response })
                        })
                        .catch(err => {

                        })
                })
            }
        })
    }

    handleStripeSubscription() {
        this.setState({ isLoadingStripePortal: true, })
        if (this.state.bearerToken) {
            purchaseServices.createOrRetrieveStripeCustomerId(this.state.bearerToken)
                .then(response => {
                    purchaseServices.openStripeCustomerPortal(response, this.state.bearerToken!)
                        .then(response => {
                            this.setState({ isLoadingStripePortal: false, })
                            window.open(response, "_self") //we have a redirect back to reasy from stripe, so we don't need a _blank tab
                        })
                })
                .catch(err => {
                    this.setState({ isLoadingStripePortal: false, })
                })
        }
    }

    render() {
        return (
            <IonPage className='body'>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/profile" />
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <div className='subscriptionsHeaderDiv'>
                    <p><Trans>Le tue sottoscrizioni</Trans></p> {/* TO LOCALIZE THIS */}
                </div>
                <div className='subscriptionsMainDiv'>
                    <IonList className='subscriptionsList'>
                        {this.state.userSubscriptions.map(sub => {
                            return (
                                <IonCard className='subscriptionCard'> {/* TO LOCALIZE THIS */}
                                    <IonCardSubtitle>
                                        Data di acquisto: <b>{new Date(sub.created_at).toLocaleDateString()}</b>
                                        <br />
                                        {new Date(sub.end_at) > new Date() ? i18n.t('Scade il: ') : i18n.t('Scaduta il: ')}<b>{new Date(sub.end_at).toLocaleDateString()}</b>
                                    </IonCardSubtitle>
                                    <IonCardTitle className='subscriptionName'>
                                        <p>{getPlanName(sub.plan_id)}</p> {/* TO LOCALIZE THIS */}
                                    </IonCardTitle>
                                    <IonCardSubtitle> {/* TO LOCALIZE THIS */}
                                    </IonCardSubtitle>
                                    {
                                        isPlatformSameAsPurchase(sub.payment_source) &&
                                        new Date(sub.end_at) > new Date() && /* TO LOCALIZE THIS */
                                        <IonButton
                                            className='handleSubscriptionButton'
                                            onClick={() => {
                                                sub.payment_source === 'ios' ? window.open('https://apps.apple.com/account/subscriptions', '_blank') : /* TO TEST THIS REDIRECT */
                                                    sub.payment_source === 'android' ? window.open('https://play.google.com/store/account/subscriptions?package=ai.tech4all.reasy', '_blank') : /* TO TEST THIS REDIRECT */
                                                        sub.payment_source === 'stripe' ? this.handleStripeSubscription() :
                                                            {}
                                            }}
                                        >
                                            Gestisci
                                        </IonButton>
                                    }
                                </IonCard>
                            )
                        })}
                    </IonList>
                    <IonLoading isOpen={this.state.isLoadingStripePortal} message={i18n.t('Sarai reindirizzato alla pagina di gestione...')} />{/* TO LODALIZE THIS */}
                </div>
            </IonPage >

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsDashboard);
import {
	IonAlert,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonFab,
	IonGrid,
	IonIcon,
	IonImg,
	IonItem,
	IonLabel,
	IonLoading,
	IonModal,
	IonPage,
	IonPopover,
	IonSpinner,
	IonText,
	IonToast,
	isPlatform,
} from "@ionic/react";
import { Unsubscribe, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where, writeBatch } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { deleteObject, getDownloadURL, ref as storageRef, uploadBytes } from "firebase/storage";
import {
	addOutline,
	arrowDown,
	checkmarkCircle,
	closeOutline,
	cloudDownloadOutline,
	helpCircleOutline,
	timeOutline,
	volumeMediumOutline
} from "ionicons/icons";
import React, { createRef } from "react";
import { ChromePicker } from "react-color"; // Assuming you're using a library like react-color for the color picker
import { isMobile, isTablet } from "react-device-detect";
import { Trans } from "react-i18next";
import Joyride from "react-joyride";
import Lottie from "react-lottie";
import { connect } from "react-redux";
import { appVersion } from "../../../appVersion";
import processingAnimation from "../../../assets/animations/processing.json";
import ArrowDown from "../../../assets/icons/arrowDown.svg";
import ArrowUp from "../../../assets/icons/arrowUp.svg";
import ediText from "../../../assets/icons/editText.svg"; //!
import genSumm from "../../../assets/icons/generateSummary.svg"; //!
import keyWord from "../../../assets/icons/keyword.svg"; //!
import loading from "../../../assets/icons/loading.svg";
import MapsButtonLogo from "../../../assets/icons/map.svg"; //!
import Markers from "../../../assets/icons/marker.svg";
import reasyLogo from "../../../assets/icons/reasyLogo.svg";
import reasyRoundedLogo from "../../../assets/icons/reasyRoundedLogo.svg";
import voiceOff from "../../../assets/icons/speechdictionary.svg";
import trashBin from "../../../assets/icons/trashBin.svg";
import editTextOff from "../../../assets/icons/viewingMode.svg";
import voiceOn from "../../../assets/icons/voiceOn.svg";
import ZoomOut from "../../../assets/icons/zoomminus.svg";
import ZoomIN from "../../../assets/icons/zoomplus.svg";
import { sendEvent } from "../../../common/amplitudeUtils";
import { handleJoyrideCallback, updateChoice } from "../../../common/utilsJoyride";
import { auth, firestore, functions, storage } from "../../../firebaseConfig";
import i18n from "../../../i18n";
import { loadPaywall } from "../../../loadPaywall";
import { mapsActions } from "../../maps/store/actions";
import ClickPopover from "../../popover/ClickPopover";
import SelectionPopover from "../../popover/SelectionPopover";
import { getTTS } from "../../popover/common/ttsServices";
import { Coloring, DocumentFontSettings, Highlight, settingsServices, UserSettings } from "../../settings/store/services";
import { TTSSettings } from "../../tts/store/reducers";
import { ttsServices } from "../../tts/store/services";
import { Organization } from "../../user/store/reducers";
import FileViewerHeader from "../components/FileViewerHeader";
import TtsPlayer from "../components/TtsPlayer";
import { getTutorialObject, getTutorialObjectMobile } from "../components/fileViewerTutorials";
import { Document, DocumentElement } from "../store/reducers";
import { filesServices, getLocalStorageItem, makeId } from "../store/services";
import {
	addNewParagraph,
	areSummaryOrKeywords,
	calcolaToptts,
	createConceptualMaps,
	generaRiassunto,
	generateConfirmationToast,
	generatePdf,
	handleTTSButtonPress,
	handleTTSLogic,
	handleTTSOptionChange,
	startTTS,
	viewFile,
	viewSummary
} from "../utils/utils";
import "./FileViewer.css";

export let howMuchScrolled: any;
export let array: any[] = [];

type ParagraphUpdate = {
	oldText: string;
	newText: string;
};

export type Paragraph = {
	index: number;
	text: string | undefined;
	keywords:string |undefined;
};

type Props = {
	history: any;
	match: any;
	showTutorial: boolean;
	disabledButtons: string[];
	disableMapButton: (uuid: string) => void;
	enableMapButton: (uuid: string) => void;
	summarySelected: (Sstate: boolean) => void;
	keyWordsSelected: (keyState: boolean) => void;
	orgData: Organization | null
};

type State = {
	highlightActive: boolean;
	isDarkMode: boolean;
	document: Document | null;
	elementsTTS: DocumentElement[] | null;
	documentElements: DocumentElement[] | null;
	summaryDocumentElements: DocumentElement[] | null;
	summaryIsClicked: boolean;
	makeSummary: boolean;
	summaryReadyMsg: boolean;
	originalFile: boolean;
	isKeyWordsOpen: boolean;
	typeSumOrKey: string;
	choice: boolean;
	fileUID: string;
	isMouseOverButton: boolean;
	isMouseOverMarkers: boolean;

	isLoadingFile: boolean;
	highlightings: Highlight[];
	fontSettings: DocumentFontSettings | undefined;
	userSettings: UserSettings | null;
	colorings: Coloring[];


	summaryState: boolean;
	keywordsState: boolean;
	isSummaryOpen: boolean;
	isGeneratingSummary: boolean;
	isGeneratingKeywords: boolean;
	infoMsg: boolean;
	//////
	userUuid: string;
	scrollOffsetY: number;
	unsubscribeOnFilesChanges: Unsubscribe | null;
	language: string | undefined;

	dictionaryIsOpen: boolean;

	showTtsPlayer: boolean;
	ttsParagraphPointer: number | null;
	audioToPlay: string | undefined;

	showClickPopover: boolean;
	clickedDocumentElement: DocumentElement | null;

	mapButtonDisabled: boolean;
	summButtonDisabled: boolean;
	keygenButtonDisabled: boolean;
	addUnderlineWords: boolean;
	sectionSelectMode: boolean;
	sectionSelectModeSummary: boolean;
	sectionSelectModeMap: boolean;
	selectedSections: string[];

	showMapCreationStartToast: boolean;
	showMapTypeSelector: boolean;
	selectedMapType: "standard" | "keywords" | "";
	mapExists: boolean;
	mapIcon: string;

	paragraphsUpdates: ParagraphUpdate[];
	showUpdateParagraphButton: boolean;

	initialParagraphs: Paragraph[];
	fileEditingMode: boolean;

	showPopoverButton: boolean | null;

	pageCounter: number;
	numberOfPages: number;
	startParagraphIndex: string;
	lastParagraphIndex: string;
	PageButtonDisabled: boolean;
	lastParagraphofPages: boolean;
	showMapCreationPopover: boolean;
	disablePlayButton: boolean;
	showModalSelection: boolean;

	showMapTextErrorAlert: boolean;
	disableTTSSkipButtons: boolean;
	disableTTSOptions: boolean;
	generatedAudios: {
		id: number;
		text: string;
		audio: string;
		speechMarks: any;
		settings: UserSettings;
	}[];
	keywordsReadyMsg: boolean;
	modifiedParagraphs: any;
	isLoading: boolean;
	loadingMessage: string;
	downloadUrl: string;
	showPdfReadyPopover: boolean;
	bearerToken: string;
	paywallState: boolean;

	steps: any[];
	tutorial: boolean;
	stepIndexFileViewer: number;
	tutorialMobile: boolean;
	speechMarks: any;

	selectedText: string | null;
	wordCustomImage: string | null;
	isLoadingTTS: boolean;

	markerActive: boolean;
	pressMouseLeftButton: boolean;
	underlineWord: {
		nameFile: string | undefined;
		numElemnt: number;
		underlineId: any[];
		backgroundColors: any;
	};
	disableClickPopover: boolean;
	pressTouch: boolean;
	showColorPicker: boolean;
	selectedColor: string;
	modifiedId: string;
	firstLoaderMessage: string;
	contentRef: any;
	howMuchScrolled: number;
};


class FileViewer extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			highlightActive: false,
			isDarkMode: false,
			document: null,
			elementsTTS: null,
			documentElements: null,
			isLoadingFile: false,
			highlightings: [],
			fontSettings: undefined,
			summaryState: false,
			keywordsState: false,
			isSummaryOpen: false,
			isGeneratingSummary: false,
			isGeneratingKeywords: false,
			makeSummary: false,
			infoMsg: false,
			summaryReadyMsg: false,
			summaryDocumentElements: null,
			summaryIsClicked: false,
			originalFile: false,
			isKeyWordsOpen: false,
			typeSumOrKey: "",
			choice: false,
			fileUID: "",
			isMouseOverButton: false,
			isMouseOverMarkers: false,
			addUnderlineWords: false,
			userSettings:null,
			colorings:[],
			/////
			userUuid: "",
			scrollOffsetY: 0,
			unsubscribeOnFilesChanges: null,

			dictionaryIsOpen: false,

			showTtsPlayer: false,
			ttsParagraphPointer: 0,
			audioToPlay: undefined,
			language: "",

			showClickPopover: false,
			clickedDocumentElement: null,
			mapButtonDisabled: false,
			mapExists: false,
			mapIcon: "",

			summButtonDisabled: false,
			keygenButtonDisabled: false,

			sectionSelectMode: false,
			sectionSelectModeSummary: false,
			sectionSelectModeMap: false,
			selectedSections: [],

			showMapCreationStartToast: false,
			showMapTypeSelector: false,

			selectedMapType: "",
			paragraphsUpdates: [],

			showUpdateParagraphButton: false,
			initialParagraphs: [],
			fileEditingMode: false,
			showPopoverButton: null,
			pageCounter: 0,
			numberOfPages: 0,
			startParagraphIndex: "0",
			lastParagraphIndex: "0",
			PageButtonDisabled: false,
			lastParagraphofPages: false,
			showMapCreationPopover: false,
			disablePlayButton: false,
			showMapTextErrorAlert: false,
			disableTTSSkipButtons: false,
			disableTTSOptions: false,
			generatedAudios: [],
			keywordsReadyMsg: false,
			modifiedParagraphs: [],

			showModalSelection: false,
			isLoading: false,
			loadingMessage: "",
			downloadUrl: "",
			showPdfReadyPopover: false,
			bearerToken: "",
			paywallState: false,

			selectedText: null,
			wordCustomImage: null,
			isLoadingTTS: false,

			steps: !isMobile ? getTutorialObject(this) : getTutorialObjectMobile(this),
			tutorial: false,
			stepIndexFileViewer: 0,
			tutorialMobile: false,

			speechMarks: null,

			markerActive: false,
			pressMouseLeftButton: false,
			underlineWord: {
				nameFile: "",
				numElemnt: 0,
				underlineId: [],
				backgroundColors: "#ffff00",
			},
			disableClickPopover: false,
			pressTouch: false,
			showColorPicker: false,
			selectedColor: "",
			modifiedId: "",
			firstLoaderMessage: "",
			contentRef: null,
			howMuchScrolled: 0,
		};
	}

	selectionPopoverRef = createRef();
	clickPopoverRef = createRef();
	showPopoverButton = createRef();
	numberWsUnderline = 1;
	checkBoxesObserver: any;


	componentWillUnmount() {
		document.onselectionchange = function () { };
		array = [];
		this.setState({ speechMarks: [], documentElements: [] });
		let audio = document.getElementById("audioElement");
		audio?.remove();
	}
	showMarkers = () => {
		const markers = document.getElementById("markers");
		if (markers) {
			markers.style.visibility = "visible";
			markers.style.opacity = "1";
			markers.style.position = "absolute";
			markers.style.top = "25%";
			markers.style.left = "100%";
		}
		if (this.state.showColorPicker) {
		} else {
		}
	};

	hideMarkers = () => {
		// Delay hiding the markers to give time for the user to move to the markers area
		setTimeout(() => {
			if (!this.state.isMouseOverButton && !this.state.isMouseOverMarkers) {
				const markers = document.getElementById("markers");
				if (markers) {
					markers.style.visibility = "hidden";
					markers.style.opacity = "0";
					markers.style.position = "absolute";
				}
			}
		}, 300); // Adjust the delay as needed
	};
	setLocalStorageItem(state: boolean, docID: string) {
		// Retrieve the current array of states from localStorage, or initialize it as an empty array if it doesn't exist
		let storedStates = localStorage.getItem("mapState");
		let statesArray;

		try {
			statesArray = storedStates ? JSON.parse(storedStates) : [];
			if (!Array.isArray(statesArray)) {
				statesArray = []; // Ensure statesArray is an array
			}
		} catch (e) {
			statesArray = []; // Fallback to an empty array in case of error
		}

		// Define the new state to be added or updated
		let newState = [false, docID];

		// Check if the docID is already present in the statesArray
		const index = statesArray.findIndex(state => state[1] === docID);

		if (index !== -1) {
			// If the docID exists and the state is different, update the existing state
			if (statesArray[index][0] !== newState[0]) {
				statesArray[index] = newState;
			}
		} else {
			// If the docID does not exist, add the new state to the array of states
			statesArray.push(newState);
		}

		// Store the updated array back in localStorage
		localStorage.setItem('mapState', JSON.stringify(statesArray));

	}

	componentDidMount() {
		this.setState({ contentRef: createRef<HTMLIonContentElement>(), howMuchScrolled: 0 })
		if (isMobile) {
			document.getElementById("textgrid")?.classList.add("textgridmobile");
		} else if (!isMobile) {
			document.getElementById("textgrid")?.classList.add("textgridnotmobile");
		}
		document.getElementById("fileViewerTextDivContainer")!.hidden = true;

		//console.log(('[FileViewer] File id:', this.props.match.params.fileId); // TO REMOVE
		auth.onAuthStateChanged(async (userData) => {
			// Persist language setting
			const storedLanguage = localStorage.getItem('languageview');
			this.setState({
				firstLoaderMessage: storedLanguage === "it" ? "Attendi giusto qualche secondo, stiamo processando il tuo documento..."
					: storedLanguage === "en" ? "Please wait just a few seconds, we are processing your document..."
						: storedLanguage === "fr" ? "Veuillez patienter quelques secondes, nous traitons votre document..."
							: storedLanguage === "de" ? "Warten Sie nur einen Moment, wir verarbeiten Ihr Dokument..."
								: storedLanguage === "ca" ? "Si us plau, espera només uns segons, estem processant el teu document..."
									: storedLanguage === "es" ? "Espere solo unos segundos, estamos procesando su documento..."
										: "Por favor, espere apenas alguns segundos, estamos processando o seu documento..."
			}, async () => {
				if (userData)
					//console.log(("UUID: ", userData.uid)
					auth.currentUser?.getIdToken().then((token) => {
						this.setState({ bearerToken: token },()=>{
							let colorings:Coloring[]=[]
							let userSettings: UserSettings | null = null
							settingsServices.getColourings(userData.uid,this.state.bearerToken).then((response)=>{
								colorings=response
							})
							settingsServices.getUserSettings(userData.uid,this.state.bearerToken).then((response)=>{
								userSettings=response
							})
							this.setState({colorings:colorings,userSettings:userSettings})
						});

						let mapState = localStorage.getItem("mapState");
						let localStorageItem = mapState ? JSON.parse(mapState) : [false, null];
						if (localStorageItem) {
							console.log(getLocalStorageItem())

							if (getLocalStorageItem()) {
								let _this = this
								const ws = new WebSocket("wss://wgm4e9m8g7.execute-api.eu-central-1.amazonaws.com/production/");
								ws.onopen = function (event) {
									// Send a message to the server once the connection is open
									ws.send(
										JSON.stringify({
											action: "restoreSession",
											token: token,
											userID: userData.uid,
										})
									);
								};
								ws.onmessage = (event) => {
									let data = JSON.parse(event.data);
									console.log(data);
									if (data.action && data.action === "closeConnection") {
										if (data.message == "Success") {
											this.setState({ mapIcon: MapsButtonLogo, mapButtonDisabled: false });
											generateConfirmationToast.call(this, false, "");
										} else {
											this.setState({ mapIcon: MapsButtonLogo, mapButtonDisabled: false });
										}
										this.setLocalStorageItem(false, _this.state.document!.uuid)
										console.log("Closing connection as per client request.");
										ws.close();
									}
								};
								ws.onerror = function (event) {
									_this.setLocalStorageItem(false, _this.state.document!.uuid)
									console.log("Error from server:", event);
									ws.close();
								};
							}
						}
					});
				if (userData) {
					if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
						this.setState({ isDarkMode: true });
					}
					const _this = this;
					if (!isMobile) {
						var selectionTimer: any = null;
						document.onselectionchange = function (e) {
							if (!_this.state.sectionSelectMode && !_this.state.fileEditingMode) {
								if (selectionTimer) {
									clearTimeout(selectionTimer);
								}
								selectionTimer = setTimeout(function () {
									_this.getTextSelection();
									selectionTimer = null;
								}, 500); // Wait 1s before calling getTextSelection()
							}
						};
					}
					this.setState({ userUuid: userData.uid });
					if (this.props.match.params.fileId) {
						this.setState({ isLoadingFile: true, fileUID: this.props.match.params.fileId });
						filesServices
							.getMainFileData(this.props.match.params.fileId)
							.then(async (document) => {

								if (auth.currentUser?.uid !== document.owner) {
									return;
								}
								getDoc(doc(firestore, `m/${document.uuid}/`)).then((documentSnapshot) => {
									if (documentSnapshot.exists()) {
										this.setState({ mapIcon: MapsButtonLogo });
									} else {
										let mapState = localStorage.getItem("mapState");
										let localStorageItem = mapState ? JSON.parse(mapState) : [false, null];
										if (localStorageItem) {
											console.log(localStorageItem)
											if (getLocalStorageItem()) {
												this.props.disableMapButton(_this.state.document!.uuid);
												this.setState({ mapIcon: timeOutline, mapButtonDisabled: true });
											} else this.setState({ mapIcon: MapsButtonLogo });
										} else this.setState({ mapIcon: MapsButtonLogo });
									}
								});
								this.setState({
									document: document,
									isLoadingFile: false,
								});
								try {
									//console.log((("[File Viewer] Started getting preferences");
									const highlightings = await settingsServices.getHighlightings(userData.uid);
									this.setState({
										highlightings: highlightings,
										//processedText: this.state.documentElements ? processText(this.state.documentElements, highlightings) : "",
									});

									const viewingSettings = await settingsServices.getDocumentViewingSettings(userData.uid);
									this.setState({ fontSettings: viewingSettings });
								} catch (e) {
									console.error("[FileViewer] error getting highlighting preferences or viewing settings:", e);
								}

								viewFile.call(this);
							})
							.catch((err1) => {
								console.error(`[FileViewer] get file error: ${JSON.stringify(err1)}`);
								this.setState({
									isLoadingFile: false,
								});
							});
					}
					areSummaryOrKeywords.call(this);
					const paywallShowOrHide = await loadPaywall(false);
					this.setState({ paywallState: paywallShowOrHide });
					const markersArea = document.getElementById("markers");
					if (markersArea) {
						markersArea.addEventListener("mouseenter", () => this.setState({ isMouseOverMarkers: true }));
						markersArea.addEventListener("mouseleave", () => {
							this.setState({ isMouseOverMarkers: false }, this.hideMarkers);
						});
					}
					let storedSpotlightColor = localStorage.getItem("spotlightColor");
					// console.log(storedSpotlightColor);
					this.setState({ selectedColor: storedSpotlightColor ? storedSpotlightColor : "#ffff00" });
				} else {
					this.setState({ userUuid: "" });
				}
			})
		});
		console.log(this.props.orgData != null && this.props.orgData?.uuid !== '-NxNg7LxheY6VGbbEsXZ')
	}
	/* 
		updateParagraph() {
			alert('UPDATE DB')
		}
	 */
	timer: any = null;
	debounce(func: any, delay: any) {
		let timeoutId: any;
		let _this = this;
		return function (...args: any) {
			clearTimeout(timeoutId);

			timeoutId = setTimeout(() => {
				func.apply(_this, args);
			}, delay);
		};
	}

	handleParagraphChange(event: any, index: number) {
		//console.log("FUNZIONA");
		const value = event.currentTarget.innerText;

		// Avoid triggering the save when clicking "ESC" or "ALT" or other non-text modifying keys.
		const nonTextModifyingKeys = ["Alt", "Control", "Escape"]; // Aggiungi altri tasti non testuali se necessario

		const key = event.key;
		//console.log(key);
		const isNonTextModifyingKey = nonTextModifyingKeys.includes(key);
		if (isNonTextModifyingKey) return;

		const isTextModifyingKey = key.length === 1 || key === "Enter" || key === "Backspace" || key === "Delete";
		if (!isTextModifyingKey) return;

		let modifiedParagraphs = this.state.modifiedParagraphs;
		let alreadyEdited = modifiedParagraphs.some((p: any) => p.id === index);

		if (alreadyEdited) {
			// Se il paragrafo è già stato modificato, aggiorna il testo
			let editedParagraphIndex = modifiedParagraphs.findIndex((p: any) => p.id === index);
			modifiedParagraphs[editedParagraphIndex].text = value;
		} else {
			// Altrimenti, aggiungi il nuovo paragrafo modificato
			modifiedParagraphs.push({ id: index, text: value });
		}

		this.setState({ modifiedParagraphs: modifiedParagraphs }, () => {
			//console.log(("WE ARRIVO QUI?");
			this.handleSave();
		});
	}

	handleSave = this.debounce(async () => {
		//console.log((("generated audios ", this.state.generatedAudios);
		//console.log(("NON CI ARRIVA", this.state.modifiedParagraphs)
		const batch = writeBatch(firestore);
		this.state.modifiedParagraphs.forEach((modifiedParagraph: any) => {
			//console.log(("SAVING");
			//console.log((modifiedParagraph);
			const spanEl = document.getElementById(modifiedParagraph.id)?.getElementsByTagName("span");
			if (spanEl) {
				const spanArray = Array.from(spanEl);
				for (const span of spanArray) {
					if (span.id != "")
						this.state.underlineWord.underlineId.forEach((el) => {
							let segSpan = span.id.split("-");
							let segEl = el.split("-");
							// console.log(this.state.underlineWord.underlineId)
							if (segEl[2] === segSpan[2]) {
								console.log("REEE", segEl[1], segSpan[1]);
								let cleanTxt = document
									.getElementById(span.id)
									?.textContent?.replace(/[!?*\[\]]/g, "")
									.toLowerCase()
									.trim();
								console.log(cleanTxt, segEl[1]);
								if (segEl[1] != cleanTxt) {
									let newId = "word-" + cleanTxt + "-" + segEl[2];
									this.setState({ modifiedId: newId });
									console.log("BEE", newId);
								}
							}
						});
				}
			}
			let generatedAudios = this.state.generatedAudios;
			let genAudiosFilter = generatedAudios.filter((audio) => audio.id === modifiedParagraph.id);
			if (genAudiosFilter.length > 0) {
				//console.log("E' STATO MODIFICATO UN PARAGRAFO CON AUDIO")
				generatedAudios.splice(genAudiosFilter.indexOf(genAudiosFilter[0]), 1);
				this.setState({ generatedAudios: generatedAudios }, () => {
					//console.log("REMOVED AUDIO -> ", this.state.generatedAudios);
				});
			}
			let docEls = this.state.isSummaryOpen ? this.state.summaryDocumentElements : this.state.documentElements;
			let paragraph
			let paragraphUuid: string | undefined;
			if (this.state.isSummaryOpen || this.state.isKeyWordsOpen) {
				paragraph = this.state.summaryDocumentElements?.filter((el) => el.index === modifiedParagraph.id)[0];
				if (paragraph) {
					paragraphUuid = paragraph.uuid;
					docEls?.forEach((docEl) => {
						if (docEl.index === modifiedParagraph.id) {
							docEl.text = modifiedParagraph.text;
						}
					});
				}
				else
					return
			} else {
				paragraph = this.state.documentElements?.filter((el) => el.index === modifiedParagraph.id)[0];
				if (paragraph) {
					paragraphUuid = paragraph.uuid;
					docEls?.forEach((docEl) => {
						if (docEl.index === modifiedParagraph.id) {
							docEl.text = modifiedParagraph.text;
						}
					});
				}
				else
					return
			}
			this.setState({ documentElements: docEls }, () => {
				//console.log("modified doc els: ", this.state.documentElements);
			});
			const docRef = this.state.isSummaryOpen
				? doc(firestore, `s/${this.state.document!.uuid}/s/${paragraphUuid}`)
				: this.state.isKeyWordsOpen
					? doc(firestore, `s/${this.state.document!.uuid}/s/${paragraphUuid}`)
					: doc(firestore, `f/${this.state.document!.uuid}/d/${paragraphUuid}`);
			//console.log("PAROLE CHIAVE", this.state.isKeyWordsOpen)
			if (!this.state.isKeyWordsOpen) {
				batch.update(docRef, {
					d: this.state.document!.uuid,
					p: modifiedParagraph.text,
				});
			} else {
				modifiedParagraph.text = modifiedParagraph.text.replace(/\n\n|\n/g, ",");
				batch.update(docRef, {
					d: this.state.document!.uuid,
					k: modifiedParagraph.text,
				});
			}
		});
		await batch.commit();

		//resets array to avoid sending the same data every time
		this.setState({ modifiedParagraphs: [] });
	}, 2000);

	highlight(text: string, paragraph: any, documentElements: DocumentElement[], highlightings: Highlight[]) {
		// Sort the highlightings array by the length of the target in descending order
		highlightings.sort((a, b) => b.target.length - a.target.length);

		// Create a regular expression pattern for matching the highlight targets
		const highlightPattern = new RegExp(highlightings.map((h) => h.target.toLowerCase()).join("|"), "gi");

		// Replace matching text with span elements for highlighting
		const highlightedText = text.replace(highlightPattern, (match) => {
			const highlighting = highlightings.find((h) => h.target.toLowerCase() === match.toLowerCase());
			return `<span style="color:${highlighting?.color}">${match}</span>`;
		});

		// Set the paragraph's innerHTML to the highlighted text
		paragraph.innerHTML = highlightedText;

		return paragraph;
	}

	splitTextIntoWords(text: string) {
		return text.split(/\s+/);
	}

	changeExistingSpans() {
		let currentParagraphIndex = this.state.ttsParagraphPointer;
		console.log("[TTS POINTER]", currentParagraphIndex)
		if (this.state.speechMarks) {
			let paragraphElement = document.getElementById(currentParagraphIndex!.toString());

			let existingSpans = paragraphElement?.childNodes;

			if (existingSpans && /^word-\d+-0-.+$/.test((existingSpans[0] as HTMLElement)?.id)) {
				/* PARAGRAPH HAS ALREADY BEEN FORMATTED*/
				return;
			}

			let currentIndex = 0;
			let text = this.state.isSummaryOpen
				? this.state.summaryDocumentElements![this.state.ttsParagraphPointer!].text.trim()
				: this.state.documentElements![this.state.ttsParagraphPointer!].text.trim();
			if (paragraphElement) {
				paragraphElement.innerHTML = "";
				this.state.speechMarks.forEach((speechMark: any, index: any) => {
					let smValue = /^word-\d+-\d+-.+$/.test(speechMark.value) ? speechMark.value.split("-")[3] : speechMark.value;
					// speechMark.value = speechMark.value.replace('\u00a0', ' ')
					// Find the start position of the current speechMark in the original text
					const start = text.indexOf(smValue, currentIndex);
					const end = start + smValue.length;

					// Create spans for the text before and within the current speechMark
					if (!isMobile && start > currentIndex) {
						let beforeSpan = document.createElement("span");
						beforeSpan = this.highlight(text.substring(currentIndex, start), beforeSpan, this.state.documentElements!, this.state.highlightings!);
						// beforeSpan.textContent = text.substring(currentIndex, start);
						beforeSpan.id = `wsUnderline${this.numberWsUnderline++}`;

						paragraphElement!.appendChild(beforeSpan);
						//console.log(`BeforeSpan: ${beforeSpan.textContent}`);
					}

					let speechMarkSpan = document.createElement("span");
					const spanId = `word-${this.state.ttsParagraphPointer}-${index}-${smValue.replace(/[^\w\s\u00C0-\u00FF']|'\B|\B'/g, "")}`;
					speechMarkSpan = this.highlight(smValue.replace("\u00a0", " "), speechMarkSpan, this.state.documentElements!, this.state.highlightings!);
					speechMarkSpan.id = spanId;
					// speechMarkSpan.textContent = speechMark.value.replace('\u00a0', ' ');
					paragraphElement!.appendChild(speechMarkSpan);
					isMobile && speechMarkSpan.appendChild(document.createTextNode(" "));
					//console.log(`SpeechMarkSpan: ${speechMarkSpan.textContent}, ID: ${spanId}`);
					// speechMarkSpan.appendChild(document.createTextNode(' '));

					// Update currentIndex to the end of the current speechMark
					currentIndex = end;
				});
				// Append the remaining text after the last speechMark
				if (currentIndex < text.length) {
					let afterSpan = document.createElement("span");
					afterSpan = this.highlight(text.substring(currentIndex), afterSpan, this.state.documentElements!, this.state.highlightings!);
					afterSpan.id = `wsUnderline${this.numberWsUnderline++}`;
					// afterSpan.textContent = text.substring(currentIndex);
					paragraphElement!.appendChild(afterSpan);
				}
			}
		}
	}
	timeupdateListener: any = null;

	getKaraoke(documentElements: DocumentElement[]) {
		let speechMarksWithSpaces: any[] = [];
		let globalWordCounter = 0;
		let currentWordIndex = 0; // Tieni traccia dell'indice della parola corrente
		let audio = document.getElementById("audioElement") as HTMLAudioElement;
		let timeOfWord;
		let newSM: any[] = [];
		//console.log(("INIZIO: ", JSON.stringify(this.state.speechMarks));
		if (this.state.speechMarks) {
			this.state.speechMarks.forEach((sm: any, j: any) => {
				if (/^word-\d+-\d+-.+$/.test(sm.value)) {
					newSM.push(sm);
				} else {
					let normalizedSpeechMark = sm;
					normalizedSpeechMark.value = normalizedSpeechMark.value.replace(/[^\w\s\u00C0-\u00FF']|'\B|\B'/g, "");
					normalizedSpeechMark.value = `word-${this.state.ttsParagraphPointer}-${j}-${normalizedSpeechMark.value}`;
					newSM.push(normalizedSpeechMark);
				}
			});
		}
		//console.log(("FINISCO: ", this.state.speechMarks);

		//REMOVE ALL HIGHLIGHTINGS FROM OTHER PARAGRAPHS
		for (let i = 0; i < document.getElementsByTagName("p").length; i++) {
			const element = document.getElementsByTagName("p")[i];
			if (element.id !== this.state.ttsParagraphPointer!.toString()) {
				element.childNodes.forEach((node: any) => {
					if (node.nodeType === 1 && node.classList.contains("paragraphinReading")) {
						node.classList.remove("paragraphinReading");
					}
				});
			}
		}

		const removeEventListenerCallback = () => { };

		if (audio && newSM && newSM.length > 0) {
			let callbackFunc = () => this.updateAudio(audio, newSM, currentWordIndex);

			if (this.timeupdateListener) {
				audio.removeEventListener("timeupdate", this.timeupdateListener); // Remove existing listener
				this.timeupdateListener = null; // Reset listener flag

				audio.addEventListener("timeupdate", removeEventListenerCallback); // Add new listener with a unique callback

				// Re-add the listener with the updated callback function
				audio.addEventListener("timeupdate", callbackFunc);
				this.timeupdateListener = callbackFunc; // Update listener flag with the new callback
			} else {
				audio.addEventListener("timeupdate", callbackFunc);
				this.timeupdateListener = callbackFunc;
			}
		}
	}
	updateAudio(audio: any, newSM: any[], currentWordIndex: number): any {
		//console.log((document.getElementById('audioElement'));

		const currentTime = audio.currentTime * 1000; // Converti il tempo in millisecondi
		// Trova la parola corrente in base al tempo di riproduzione
		// let currentWordIndex = 0;
		while (currentWordIndex < newSM.length) {
			let timeOfWord = this.getTimeOfWord(currentWordIndex, newSM);
			// Aggiungi un margine temporale (ad esempio, 50 ms prima dell'inizio della parola)
			const margin = 0;
			// Estrai la parola associata all'ID dello span
			let currentWord = newSM[currentWordIndex].value;
			// Aggiorna la gestione del tempo per considerare un intervallo temporale
			if (currentTime + margin >= timeOfWord) {
				// Confronta direttamente con il contenuto degli speech marks

				let matchingSpeechMark = newSM.find((mark: any) => mark.value === currentWord);

				newSM.forEach((sm: any, i: any) => {
					document.getElementById(sm.value)?.classList.remove("paragraphinReading");
				});
				//console.log((matchingSpeechMark.value);

				// Se trovi una corrispondenza, puoi fare qualcosa con l'informazione (ad esempio, evidenziare)
				if (matchingSpeechMark) {
					document.getElementById(matchingSpeechMark.value)?.classList.add("paragraphinReading");
				}
				currentWordIndex++;
			} else {
				break;
			}
		}
		// Gestione degli errori per evitare problemi quando currentWordIndex supera la lunghezza dell'array
		// currentWordIndex = Math.min(currentWordIndex, wordsArray.length - 1);
	}

	getTimeOfWord(index: number, mergedSpeechMarks: any) {
		if (index < mergedSpeechMarks.length) {
			// Aggiungi questo controllo
			return mergedSpeechMarks[index].time;
		} else {
			// Ritorna un valore predefinito o gestisci il caso in modo appropriato
			return 0;
		}
	}
	getTextSelection() {
		if (window.getSelection) {
			const gotSelection = window.getSelection();
			try {
				if (gotSelection) {
					console.log("works", gotSelection);
					(this.selectionPopoverRef.current as any).clearImageState();
					(this.selectionPopoverRef.current as any).eraseDictionaryMeanings();
					// OR
					// const lngDetector = new (require('languagedetect'));
					if (gotSelection.rangeCount > 0 && gotSelection.toString().trim() !== "") {
						console.log("working", gotSelection);

						(this.showPopoverButton.current as any).showPopoverButton(true);
						//console.log("asdas", gotSelection.toString());

						//console.log(((franc(gotSelection.toString(), { only: ['ita', 'fra', 'spa', 'deu'] })); // TO REMOVE
						(this.selectionPopoverRef.current as any).changeSelectedText(gotSelection.toString());
						(this.selectionPopoverRef.current as any).setSelection(gotSelection, gotSelection.toString(), true);
						// (this.selectionPopoverRef.current as any).showPopover(true);

						this.setState({ dictionaryIsOpen: true });
					} else {
						this.setState({ dictionaryIsOpen: false });
						//empty dictionary meanings array
						//ciaone
						(this.selectionPopoverRef.current as any).eraseDictionaryMeanings();

						// (this.showPopoverButton.current as any).showPopoverButton(false);
						(this.selectionPopoverRef.current as any).changeSelectedText(null);
						(this.selectionPopoverRef.current as any).setSelection(null, null, false);
					}
				}
			} catch (err) {
				console.error(err);
			}
		} else {
			(this.showPopoverButton.current as any).showPopoverButton(false);
			(this.selectionPopoverRef.current as any).changeSelectedText(null);
			(this.selectionPopoverRef.current as any).setSelection(null);
		}
		//console.log((((this.showPopoverButton.current as any));
	}
	async removeParagraph(paragraph: any) {
		this.setState({
			isLoading: true,
			loadingMessage:
				i18n.language === "it"
					? "Cancello il paragrafo..."
					: i18n.language === "en"
						? "Deleting the paragraph..."
						: i18n.language === "es"
							? "Borrando el párrafo"
							: i18n.language === "fr"
								? "Suppression du paragraphe"
								: i18n.language === "ca"
									? "Esborro el paràgraf..."
									: "den Absatz löschen",
		});
		let tempElements = this.state.isSummaryOpen ? this.state.summaryDocumentElements : this.state.documentElements;
		let elToRemove = tempElements!.filter((el) => el.index === Number(paragraph.id))[0];
		let indexToRemove = elToRemove.index;
		console.log("elements: ", tempElements);
		if (tempElements?.indexOf(elToRemove) === tempElements!.length - 1) {
			//LAST INDEX IS REMOVED, DONT DO ANYTHING

		} else if (tempElements?.indexOf(elToRemove) === 0) {
			//FIRST INDEX IS REMOVED, SHIFT ALL PARAGRAPH INDEXES BY -1
			tempElements?.forEach((el) => {
				if (el.index > indexToRemove) {
					el.index--;
				}
			});
		} else {
			//INDEX IN THE MIDDLE IS REMOVED, SHIFT ALL NEXT PARAGRAPH INDEXES BY -1
			tempElements?.forEach((el) => {
				if (el.index > indexToRemove) {
					el.index--;
				}
			});
		}
		if (this.state.ttsParagraphPointer === indexToRemove)
			this.setState({ ttsParagraphPointer: 0 });
		//console.log(("EL TO REMOVE: ", elToRemove.index);

		tempElements?.splice(tempElements!.indexOf(elToRemove), 1);
		//console.log((tempElements);

		tempElements!.forEach((el) => {
			let docRef = this.state.isSummaryOpen ? doc(firestore, `s/${this.state.document!.uuid}/s/${el.uuid}`) : doc(firestore, `f/${this.state.document!.uuid}/d/${el.uuid}`);
			updateDoc(docRef, {
				i: el.index,
			});
		});
		let docRef = this.state.isSummaryOpen ? doc(firestore, `s/${this.state.document!.uuid}/s/${elToRemove.uuid}`) : doc(firestore, `f/${this.state.document!.uuid}/d/${elToRemove.uuid}`);
		await deleteDoc(docRef).then(() => {
			this.setState({ documentElements: tempElements, isLoading: false, loadingMessage: "" }, () => {
				document.getElementById(paragraph.id)!.parentElement!.remove();
				let paragraphArr = document.getElementsByClassName("fileViewerParagraph");
				for (let i = 0; i < paragraphArr.length; i++) {
					const paragraph = paragraphArr[i] as HTMLElement;
					paragraph.id = tempElements![i].index.toString();
				}
				// this.processText(this.state.documentElements!, this.state.highlightings, this.state.fontSettings)
			});
		});
	}

	addEditingButtons(el: any) {
		let wrapper = document.createElement("div");
		wrapper.id = "paragraphWrapper";

		let removeIcon = document.createElement("ion-icon");
		removeIcon.src = trashBin;
		removeIcon.className = "removeImage";
		removeIcon.slot = "icon-only";

		let removeBtn = document.createElement("ion-button");
		removeBtn.id = "removeBtn";
		removeBtn.className = "btn-fileviewer btn-background";
		removeBtn.shape = "round";
		removeBtn.addEventListener("click", () => this.removeParagraph(el));
		removeBtn.appendChild(removeIcon);

		wrapper.appendChild(removeBtn);
		el.parentNode?.insertBefore(wrapper, el);
		wrapper.appendChild(el);

		let addIcon = document.createElement("ion-icon");
		addIcon.src = addOutline;
		addIcon.className = "addImage";
		addIcon.slot = "icon-only";

		let addButton = document.createElement("ion-button");
		addButton.id = "addBtn";
		addButton.className = "btn-fileviewer btn-background";
		addButton.shape = "round";
		addButton.addEventListener("click", () => addNewParagraph.call(this, el));
		addButton.appendChild(addIcon);

		let joinIcon = document.createElement("ion-icon");
		joinIcon.src = arrowDown;
		joinIcon.className = "addImage";
		joinIcon.slot = "icon-only";

		wrapper.appendChild(addButton);
	}

	editingModeClicked() {
		this.setState({ fileEditingMode: !this.state.fileEditingMode, sectionSelectMode: false, selectedSections: [], selectedMapType: "" });
		// (document.getElementById('expandableMenuCheckbox') as HTMLInputElement).checked = false
		//REMOVE SELECTION AND THE ANALYZE BUTTON
		(this.showPopoverButton.current as any).showPopoverButton(false);
		(this.selectionPopoverRef.current as any).changeSelectedText(null);
		(this.selectionPopoverRef.current as any).setSelection(null);

		if (!document.getElementById("addFirstParagraphBtn")) {
			let addInitialParagraphBtn = document.createElement("ion-button");
			addInitialParagraphBtn.id = "addFirstParagraphBtn";
			addInitialParagraphBtn.textContent =
				i18n.language === "en"
					? "Add paragraph"
					: i18n.language === "es"
						? "Añadir párrafo"
						: i18n.language === "fr"
							? "Ajouter un paragraphe"
							: i18n.language === "it"
								? "Aggiungi paragrafo"
								: i18n.language === "ca"
									? "Afegeix paràgraf"
									: "Absatz hinzufügen";
			addInitialParagraphBtn.className = "addFirstParagraph";
			addInitialParagraphBtn.onclick = () => {
				addNewParagraph.call(this, null);
			};
			document
				.getElementById("fileViewerTextDivContainer")
				?.insertBefore(addInitialParagraphBtn, document.getElementById("fileViewerTextDivContainer")!.firstChild);
		}

		let paragraphs = this.state.isKeyWordsOpen ? document.getElementsByClassName("keyWordsContainer") : document.getElementsByClassName("fileViewerParagraph");
		for (let i = 0; i < paragraphs.length; i++) {
			let el = paragraphs[i] as HTMLElement;
			el.contentEditable = el.contentEditable === "true" ? "false" : "true";
			el.style.backgroundColor = "";

			if (el.contentEditable === "true" && !this.state.isKeyWordsOpen) {
				// this.addEditingButtons(el);
			} else {
				document.getElementById("paragraphWrapper")?.replaceWith(el);
				document.getElementById("addFirstParagraphBtn")?.remove();
			}
		}
	}

	popoverButtonClicked() {
		if (this.state.sectionSelectMode) return;
		(this.showPopoverButton.current as any).showPopoverButton(false);
		(this.selectionPopoverRef.current as any).setSelection(window.getSelection()!.toString());
		(this.selectionPopoverRef.current as any).searchImages();
		(this.selectionPopoverRef.current as any).showPopover(true);
	}
	addSelectionCheckBoxes() {
		let paragraphs = document.getElementsByTagName("p");
		for (let i = 0; i < paragraphs.length; i++) {
			const p = paragraphs[i];
			let checkBox = document.createElement("div");
			checkBox.className = "squareSelector";
			checkBox.id = "check-" + p.id;
			p.style.marginLeft = "40px";
			checkBox.onclick = p.onclick;
			p.parentNode!.insertBefore(checkBox, p);
		}
	}

	async mapButtonClicked() {
		if (this.state.document && this.state.document.uuid) {
			await getDoc(doc(firestore, `m/${this.state.document?.uuid}/`)).then((documentSnapshot) => {
				if (documentSnapshot.exists()) {
					this.setLocalStorageItem(false, this.state.document!.uuid)
					sendEvent({
						"user_id": this.state.userUuid,
						"event_type": "Map visited",
						"event_properties": {
							"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
							"document_uuid": this.state.document?.uuid,
							"document_name": this.state.document?.name,
						},
						"language": i18n.language,
						"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
						"app_version": appVersion,
						"time": Date.now(),
					});
					this.props.history.push({
						pathname: `/maps/${this.state.document!.uuid}`,
					});
				} else {
					sendEvent({
						"user_id": this.state.userUuid,
						"event_type": "Map button pressed",
						"event_properties": {
							"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
							"document_uuid": this.state.document?.uuid,
							"document_name": this.state.document?.name,
						},
						"language": i18n.language,
						"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
						"app_version": appVersion,
						"time": Date.now(),
					});
					this.setState({ sectionSelectMode: true, sectionSelectModeMap: true /* showMapCreationConfirm: true */ });
					this.addSelectionCheckBoxes();
				}
			});
		}
	}

	showFileViewerPlayer(show: boolean) {
		// if (!show) {
		// 	this.numberWsUnderline = 1;
		// 	this.setState({ generatedAudios: [], speechMarks: null, originalFile: false }, () => {
		// 		viewFile.call(this);
		// 	});
		// }
		this.setState({ showTtsPlayer: show });
		if (this.state.userUuid && !this.state.showTtsPlayer) {
			let docElements: DocumentElement[] | null;
			if (this.state.isSummaryOpen) {
				//console.log("SUMMARU")
				if (!isMobile) calcolaToptts.call(this)
				docElements = this.state.summaryDocumentElements;
			} else docElements = this.state.documentElements;
		}
	}


	PageMinus() {
		let appoggio = this.state.pageCounter - 1;
		let firstparagraphid = array[appoggio].firstChild.id;
		let lastparagraphid = array[appoggio].lastChild.id;

		this.setState(
			{ ttsParagraphPointer: Number(firstparagraphid), lastParagraphIndex: lastparagraphid, startParagraphIndex: firstparagraphid, pageCounter: appoggio },
			() => {
				if (this.state.showTtsPlayer)
					startTTS.call(this);
			}
		);

		//console.log(('pagecounter' + this.state.pageCounter, 'primo' + this.state.startParagraphIndex, 'ultimo' + this.state.lastParagraphIndex, 'ttspointer' + this.state.ttsParagraphPointer);

		(document.getElementById("fileViewerTextDivContainer") as HTMLDivElement)?.replaceChildren(array[appoggio]);
		this.state.contentRef.current?.scrollToTop(500);
	}
	PagePlus() {
		let appoggio = this.state.pageCounter + 1;
		let firstparagraphid = array[appoggio].firstChild.id;
		let lastparagraphid = array[appoggio].lastChild.id;
		this.setState(
			{ ttsParagraphPointer: firstparagraphid, lastParagraphIndex: lastparagraphid, startParagraphIndex: firstparagraphid, pageCounter: appoggio },
			() => {
				if (this.state.showTtsPlayer)
					startTTS.call(this);
			}
		);

		//console.log(('pagecounter' + this.state.pageCounter, 'primo' + this.state.startParagraphIndex, 'ultimo' + this.state.lastParagraphIndex, 'ttspointer' + this.state.ttsParagraphPointer);
		//console.log(('adding', String(firstparagraphid));
		// document.getElementById(String(firstparagraphid))?.classList.add('paragraphinReading');

		(document.getElementById("fileViewerTextDivContainer") as HTMLDivElement)?.replaceChildren(array[appoggio]);
		if (this.state.sectionSelectMode) this.addSelectionCheckBoxes();
		this.state.contentRef.current?.scrollToTop(500);
	}

	handleChkBoxCreation() {
		const documentParagraphs = document.getElementsByTagName("p");

		// Remove existing checkboxes to avoid duplicates
		const checkBoxes = document.querySelectorAll('[id^="check-"]');
		checkBoxes.forEach((el) => el.remove());

		// Setup an Intersection Observer to add checkboxes only when paragraphs are visible
		this.checkBoxesObserver = new IntersectionObserver(
			(entries, obs) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						const paragraph = entry.target as HTMLElement;
						this.createCheckBox(paragraph);
						// Unobserve the paragraph once the checkbox is added
						obs.unobserve(paragraph);
					}
				});
			},
			{
				root: null, // observing for visibility in the viewport
				rootMargin: "0px",
				threshold: 0.1, // adjust threshold based on your needs
			}
		);

		// Observe each paragraph
		for (let i = 0; i < documentParagraphs.length; i++) {
			this.checkBoxesObserver.observe(documentParagraphs[i]);
		}
	}

	createCheckBox(paragraph: HTMLElement) {
		const checkBox = document.createElement("div");
		checkBox.className = "squareSelector";
		checkBox.id = "check-" + paragraph.id;
		paragraph.style.marginLeft = "40px";
		checkBox.onclick = paragraph.onclick;
		paragraph.parentNode!.insertBefore(checkBox, paragraph);
	}

	removeCheckBoxes() {
		if (this.checkBoxesObserver) this.checkBoxesObserver.disconnect();
		const checkBoxes = document.querySelectorAll('[id^="check-"]');
		checkBoxes.forEach((el) => el.remove());

		const documentParagraphs = document.getElementsByTagName("p");
		Array.from(documentParagraphs).forEach((paragraph) => {
			paragraph.style.backgroundColor = "";
			paragraph.style.marginLeft = "unset";
			paragraph.style.color = "var(--txt-color)";
			if (!this.state.isDarkMode) paragraph.style.color = "unset";
		});

		this.setState({ sectionSelectMode: false, sectionSelectModeSummary: false, sectionSelectModeMap: false, selectedSections: [] });
	}

	customImageFromGSUrltoUrl(gsUrl: string) {
		return new Promise<string>((resolve, reject) => {
			getDownloadURL(storageRef(storage, gsUrl))
				.then((url) => {
					this.setState({ wordCustomImage: url /* isLoadingImages: false, */ });
					resolve(url);
				})
				.catch((err) => {
					//console.log(('[fromGSUrltoUrl] service error:', err);
					reject(err);
				});
		});
	}
	uploadCustomImage = (media: File | null | undefined, alreadyExists: boolean, text: string) => {
		if (media && this.state.userUuid && media.type.includes("image/")) {
			let selectedText = text.toLowerCase().trim();
			uploadBytes(storageRef(storage, `i/${this.state.userUuid}/${selectedText}/`), media)
				.then((data) => {
					//console.log(("[Utils] upload success:", data.ref);
					this.customImageFromGSUrltoUrl(data.ref.fullPath).then(async (url) => {
						if (!alreadyExists) {
							//console.log("NEW URL PUBLIC: ", url);
							await setDoc(doc(firestore, `i/${this.state.userUuid}/i/${makeId(16)}`), {
								w: selectedText,
								i: data.metadata.fullPath,
							})
								.then((response) => {
									//console.log("[File Viewer] setting custom image doc response:", response);
									(this.selectionPopoverRef.current as any).addCustomImage(url);
									(this.clickPopoverRef.current as any).addCustomImage(url);
									this.setState({ wordCustomImage: url });
									// (nodes.filter(node => node.id === this.state.selectedNodeId)[0] as any).image = url
									// setNodes(nodes)
								})
								.catch((e) => {
									console.error("[File Viewer] error setting custom image doc:", e);
								});
						}
					});
				})
				.catch((err) => {
					//console.log("[MindMap] error uploading custom image", err);
				});
		}
	};

	async deleteCustomImage(url: string, text: string) {
		//console.log("Delete image: ", url);
		if (url !== "" && url !== null) {
			//REMOVE EXISTING IMAGE
			let imageRef = await storageRef(storage, url);
			//console.log("Found ref: ", imageRef);
			await deleteObject(imageRef)
				.then(async (response) => {
					//console.log("Successfully removed custom image from storage: ", response);
					//also remove from firestore db
					const imageQuery = query(collection(firestore, `i/${this.state.userUuid}/i`), where("w", "==", text.toLowerCase().trim()));
					const querySnapshot = await getDocs(imageQuery);
					if (!querySnapshot.empty) {
						querySnapshot.forEach(async (doc) => {
							await deleteDoc(doc.ref)
								.then((response) => {
									(this.selectionPopoverRef.current as any).removeDeletedImage(url);
									(this.clickPopoverRef.current as any).removeDeletedImage(url);

									//console.log("succesfully removed custom image firestore doc");
								})
								.catch((error) => {
									//console.log("Error during custom image doc deletion: ", error);
								});
						});
					}
				})
				.catch((error) => {
					//console.log("Error removing custom image from storage: ", error);
				});
		} else {
			document.getElementById("utilsFileInput")?.click();
		}
	}
	async deleteDrawing(url: string, text: string) {
		//console.log("Delete image: ", url);
		if (url !== "" && url !== null) {
			//REMOVE EXISTING DRAWING
			let imageRef = await storageRef(storage, url);
			console.log("Found ref: ", imageRef);
			await deleteObject(imageRef)
				.then(async (response) => {
					//console.log("Successfully removed custom image from storage: ", response);
					//also remove from firestore db
					const imageQuery = query(collection(firestore, `d/${auth.currentUser?.uid}/d`), where("w", "==", text.toLowerCase().trim()));
					const querySnapshot = await getDocs(imageQuery);
					if (!querySnapshot.empty) {
						querySnapshot.forEach(async (doc) => {
							await deleteDoc(doc.ref)
								.then((response) => {
									isMobile
										? (this.clickPopoverRef.current as any).removeDeletedDrawing(url)
										: (this.selectionPopoverRef.current as any).setState({ drawingUrl: null });
								})
								.catch((error) => {
									//console.log("Error during custom image doc deletion: ", error);
								});
						});
					}
				})
				.catch((error) => {
					//console.log("Error removing custom image from storage: ", error);
				});
		} else {
			document.getElementById("utilsFileInput")?.click();
		}
	}

	removeTTSHighlighting() {
		let paragraph = document.getElementById(this.state.ttsParagraphPointer!.toString());
		if (paragraph)
			for (let i = 0; i < paragraph.children.length; i++) {
				paragraph.children[i].classList.remove("paragraphinReading");
			}
	}
	tutorialOpenMethod: "auto" | "manual" = "auto";

	underline() {
		console.log("attiva");
		if (isMobile || isTablet) {
			this.setState({
				disableClickPopover: true,
			});
			console.log("click popover disablitato!!");
		}

		const container = document.getElementById("fileViewerTextDivContainer");
		if (container) {
			if (isMobile || isTablet) {
				container.addEventListener("touchstart", this.detectsPressTouch);
				container.addEventListener("touchend", this.detectsPressTouch);
				container.addEventListener("touchmove", this.addSelectionTouch);
			}
			container.addEventListener("selectstart", this.disableSelect);
			container.addEventListener("mousedown", this.detectsPressMouse);
			container.addEventListener("mouseup", this.detectsPressMouse);

			this.setState({ markerActive: true });
			const spans = container.querySelectorAll("span");
			if (spans) {
				spans.forEach((span) => {
					span.addEventListener("mouseover", this.addSelectionWhenButtonPress);
					span.addEventListener("mousedown", this.addSelection);
				});
			} else {
				console.log("span non letti");
			}
		} else {
			console.log("container non esiste id: fileViewerTextDivContainer");
		}
	}

	disableSelect = (event: any) => {
		event.preventDefault();
	};

	addSelection = (event: any) => {
		let targetElement = event.target;

		if (targetElement.parentElement.tagName === "SPAN") {
			targetElement = targetElement.parentElement;
		}
		if (targetElement.tagName === "SPAN") {
			// // Check if the span does not have an ID
			// if (!targetElement.id) {
			// 	// Generate a random three-digit number for the ID
			// 	const randomId = this.getRandomThreeDigitNumber();
			// 	targetElement.id = `wsUnderline${randomId}`;
			// }

			if (this.state.addUnderlineWords) this.addUnderline(targetElement);
			else {
				this.removeUnderline(targetElement);
			}
		}
		this.addElementToStructure(targetElement);
	};

	getRandomThreeDigitNumber = () => {
		// This will generate a number between 100 and 999
		return Math.floor(Math.random() * 900) + 100;
	};
	addUnderline = (element: any) => {
		element.style.backgroundColor = this.state.selectedColor;
		element.classList.add("underline");
	};

	removeUnderline = (element: any) => {
		if (element.id.startsWith("wsUnderline")) {
			element.removeAttribute("id");
		}
		element.style.backgroundColor = "";
		element.classList.remove("underline");
	};

	addElementToStructure(element: any) {
		if (!element.id) return; // Exit if no ID is present
		this.setState(
			(prevState) => {
				const isElementIdIncluded = prevState.underlineWord.underlineId.includes(element.id);
				const updatedBackgroundColors = { ...prevState.underlineWord.backgroundColors };
				if (this.state.addUnderlineWords) {
					// Only add if the ID is not already included
					if (!isElementIdIncluded) {
						const newUnderlineId = [...prevState.underlineWord.underlineId, element.id];
						const newNumElement = prevState.underlineWord.numElemnt + 1;
						updatedBackgroundColors[element.id] = this.state.selectedColor;
						return {
							...prevState,
							underlineWord: {
								...prevState.underlineWord,
								nameFile: prevState.document?.name,
								numElemnt: newNumElement,
								underlineId: newUnderlineId,
								backgroundColors: updatedBackgroundColors,
							},
						};
					} else {
						updatedBackgroundColors[element.id] = this.state.selectedColor;
						const newUnderlineId = [...prevState.underlineWord.underlineId, this.state.modifiedId];

						return {
							...prevState,
							underlineWord: {
								...prevState.underlineWord,
								underlineId: newUnderlineId,
								backgroundColors: updatedBackgroundColors,
							},
						};
					}
				} else {
					// Only remove if the ID is already included
					if (isElementIdIncluded) {
						const newUnderlineId = prevState.underlineWord.underlineId.filter((id) => id !== element.id);
						const newNumElement = prevState.underlineWord.numElemnt - 1;
						delete updatedBackgroundColors[element.id];
						return {
							...prevState,
							underlineWord: {
								...prevState.underlineWord,
								nameFile: prevState.document?.name,
								numElemnt: newNumElement,
								underlineId: newUnderlineId,
								backgroundColors: updatedBackgroundColors,
							},
						};
					}
				}
				return prevState; // Return the current state if the ID is already included
			},
			() => {
				localStorage.setItem("underlineWord", JSON.stringify(this.state.underlineWord));
			}
		);
	}

	addSelectionWhenButtonPress = (event: any) => {
		if (this.state.pressMouseLeftButton || this.state.pressTouch) {
			// console.log("span: " + event.target.textContent);
			this.addSelection(event);
		}
	};

	detectsPressMouse = (event: any) => {
		if (event.button === 0) {
			if (event.type === "mousedown") {
				this.setState({ pressMouseLeftButton: true });
			} else {
				this.setState({ pressMouseLeftButton: false });
			}
		}
	};

	detectsPressTouch = (event: any) => {
		if (event.type === "touchstart") {
			this.setState({ pressTouch: true });
		} else {
			this.setState({ pressTouch: false });
		}
	};
	toggleColorPicker = () => {
		const markers = document.getElementById("markers");

		this.setState(
			(prevState) => ({
				showColorPicker: !prevState.showColorPicker,
			}),
			() => { }
		);
	};
	handleColorChange = (color: any) => {
		localStorage.setItem("spotlightColor", color.hex ? color.hex : "#ffff00");
		this.setState({ selectedColor: color.hex }, () => this.setState({ addUnderlineWords: true }, () => this.underline()));
	};

	addSelectionTouch = (event: any) => {
		var touchedElement = document.elementFromPoint(event.touches[0].clientX, event.touches[0].clientY);
		if (touchedElement && touchedElement.tagName === "SPAN" && touchedElement != null) {
			if (touchedElement.parentElement?.tagName === "SPAN") {
				touchedElement = touchedElement.parentElement;
			}
			if (touchedElement.tagName === "SPAN") {
				// Check if the span does not have an ID
				if (!touchedElement.id) {
					// Generate a random three-digit number for the ID
					const randomId = this.getRandomThreeDigitNumber();
					touchedElement.id = `wsUnderline${randomId}`;
				}

				if (this.state.addUnderlineWords) this.addUnderline(touchedElement);
				else {
					this.removeUnderline(touchedElement);
				}
			}
			this.addElementToStructure(touchedElement);
		}
	};

	async disableUnderline() {
		console.log(this.state.underlineWord);
		if (this.state.underlineWord !== undefined) {
			await setDoc(doc(firestore, `h/${this.state.userUuid}/w/${this.state.document?.uuid}`), {
				words: this.state.underlineWord,
			});
		}
		console.log("dissativa");
		if (isMobile || isTablet) {
			this.setState({
				disableClickPopover: false,
				showClickPopover: false,
			});
		}

		const container = document.getElementById("fileViewerTextDivContainer");
		if (container) {
			if (isMobile || isTablet) {
				container.removeEventListener("touchstart", this.detectsPressTouch);
				container.removeEventListener("touchend", this.detectsPressTouch);
				container.removeEventListener("touchmove", this.addSelectionTouch);
			}
			container.removeEventListener("selectstart", this.disableSelect);
			container.removeEventListener("mousedown", this.detectsPressMouse);
			container.removeEventListener("mouseup", this.detectsPressMouse);

			this.setState({ markerActive: false });
			const spans = container.querySelectorAll("span");
			if (spans) {
				spans.forEach((span) => {
					span.removeEventListener("mouseover", this.addSelectionWhenButtonPress);
					span.removeEventListener("mousedown", this.addSelection);
				});
			} else {
				console.log("span non letti");
			}
		} else {
			console.log("container non esiste id: fileViewerTextDivContainer");
		}
	}

	render() {
		const downloadSection = (
			<div
				hidden={this.state.sectionSelectMode || this.state.sectionSelectModeMap || this.state.sectionSelectModeSummary}
				className="downloadButtonSection"
				id="downloadSection"
			>
				<IonButtons className="downloadButton">
					<IonButton
						onClick={() => {
							generatePdf(this, this.state.document);
						}}
						style={{ width: "100%" }}
					>
						<IonIcon icon={cloudDownloadOutline} size="large" />
					</IonButton>
				</IonButtons>
			</div>
		);
		return (
			<IonPage>
				
				<Joyride
					steps={this.state.steps}
					run={this.state.tutorial} // Set to true to start the tour automatically
					continuous={true} // Allow continuous navigation through the tour
					showProgress={true} // Display progress indicator
					showSkipButton={true} // Display skip button
					spotlightClicks={true}
					callback={(data) => handleJoyrideCallback.call(this, data, "File Viewer")}
					hideBackButton={false}
					disableOverlayClose={true}
					hideCloseButton={true}
					locale={{
						skip: (
							<div
								className="skip"
								onClick={() => {
									updateChoice.call(this, "tutorialFileViewer");
								}}
							>
								<Trans>Salta</Trans>
							</div>
						),
						back: (
							<div className="nextTutorialBtn">
								<Trans>Precedente</Trans>
							</div>
						),
						next: (
							<span>
								<Trans>Avanti</Trans>
							</span>
						),
						last: (
							<div
								className="nextTutorialBtn"
								onClick={() => {
									updateChoice.call(this, "tutorialFileViewer");
								}}
							>
								<Trans>Ultimo</Trans>
							</div>
						),
					}}
					styles={{
						buttonNext: {
							fontSize: "12px",
							outline: "none",
						},
					}}
				/>
				{this.state.document && (
					<FileViewerHeader
						history={this.props.history}
						isEditingMode={this.state.fileEditingMode}
						tutorialClicked={() => {
							this.setState({ tutorial: true }, () => {
								sendEvent({
									"user_id": this.state.userUuid,
									"event_type": "Tutorial opened",
									"event_properties": {
										"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
										"page": "File Viewer",
									},
									"language": i18n.language,
									"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
									"app_version": appVersion,
									"time": Date.now(),
								});
							});
						}}
						mapButtonDisabled={
							this.props.disabledButtons && Array.isArray(this.props.disabledButtons) ? this.props.disabledButtons.includes(this.state.document!.uuid) : false
						}
						ref={this.showPopoverButton as React.LegacyRef<FileViewerHeader>}
						document={this.state.document}
						documentElements={this.state.documentElements ? this.state.documentElements : []}
						isLoadingFile={this.state.isLoadingFile}
						orgData={this.props.orgData}
						showPlayer={this.state.showTtsPlayer}
						summarySelected={this.state.isSummaryOpen}
						keyWordsSelected={this.state.isKeyWordsOpen}
						pdfReady={(url: string) => {
							this.setState({ downloadUrl: url, showPdfReadyPopover: true });
						}}
						backButtonDisabled={this.state.isLoadingTTS && this.state.isSummaryOpen}
						summaryDeleted={(choice) => {
							if (choice === "sum") {
								this.setState({ summaryState: false, isSummaryOpen: false });
							} else {
								this.setState({ keywordsState: false, isKeyWordsOpen: false });
							}
							if (!isMobile) document.getElementById("pageScrollControlls")!.hidden = false;
							this.setState({ isLoading: true, loadingMessage: "Sto caricando il documento" });
							viewFile.call(this);
						}}
						backButtonBehaviour={() => {
							if (this.state.sectionSelectMode) {
								this.setState({ sectionSelectMode: false, sectionSelectModeSummary: false, sectionSelectModeMap: false, selectedSections: [] });
								this.removeCheckBoxes();
							} else if (this.state.isKeyWordsOpen) {
								viewFile.call(this);
								if (!isMobile) document.getElementById("pageScrollControlls")!.hidden = false;
								this.setState({ isKeyWordsOpen: false });
								(this.selectionPopoverRef.current as any).showPopover(false)
							} else if (this.state.isSummaryOpen) {
								viewFile.call(this);
								if (!isMobile) document.getElementById("pageScrollControlls")!.hidden = false;
								this.setState({ isSummaryOpen: false });
								(this.selectionPopoverRef.current as any).showPopover(false)
							} else {
								this.props.history.push("/");
							}
							let Audio = document.getElementById("audioElement") as HTMLAudioElement;
							Audio.pause();
							this.removeTTSHighlighting();
							this.setState({ showTtsPlayer: false })
							document.getElementById("textgrid")!.classList.remove("spostaTesto");
							viewFile.call(this);
							document.getElementById("pageScrollControlls")!.hidden = false;
							this.setState({ isSummaryOpen: false, speechMarks: [], generatedAudios: [] });
						}}
						fileUID={this.state.fileUID}
					/>
				)}
				<IonContent
					ref={this.state.contentRef}
					className="fileViewerContent"
					fullscreen
					scrollEvents={true}
					/* onIonScroll={e => this.handleScroll(e)}
					onIonScrollStart={e => this.scrollStart()} */
					onIonScroll={(e) => {
						(this.selectionPopoverRef.current as any).setScrollOffsetY(e.detail.scrollTop);
						this.setState({ howMuchScrolled: e.detail.scrollTop })
					}}
				>
					{!isMobile && this.state.documentElements && this.state.documentElements.length > 0 && (
						<div className="actionButtons" hidden={this.state.sectionSelectMode}>
							<>
								<div className="sideactionbtn">
									{/* <IonButton onClick={() => { //console.log(('pagecounter' + this.state.pageCounter, 'primo' + this.state.startParagraphIndex, 'ultimo' + this.state.lastParagraphIndex, 'ttspointer' + this.state.ttsParagraphPointer) }}></IonButton> */}
									<IonButton
										id="tts"
										className="btn-fileviewer"
										color="whitebuttons"
										slot="icon-only"
										hidden={this.state.fileEditingMode || this.state.isKeyWordsOpen}
										disabled={this.state.isLoadingTTS}
										onClick={() => {
											if (!isMobile) {
												calcolaToptts.call(this)
											}
											if (this.state.paywallState) loadPaywall(true);
											else {
												let elementsToSynthetize: any = null;
												/* SET THE CORRECT TEXT TO SYNTHESIZE */
												if (this.state.isSummaryOpen && this.state.summaryDocumentElements && this.state.summaryDocumentElements?.length > 0)
													elementsToSynthetize = this.state.summaryDocumentElements;
												else elementsToSynthetize = this.state.documentElements;
												/* CHECK IF THE AUDIO HAS ALREADY BEEN GENERATED */
												if (
													this.state.generatedAudios.filter(
														(el) =>
															!this.state.isSummaryOpen &&
															el.text === this.state.documentElements![this.state.ttsParagraphPointer!].text &&
															el.settings.speech_rate === this.state.generatedAudios[0].settings.speech_rate &&
															el.settings.speech_gender === this.state.generatedAudios[0].settings.speech_gender
													).length === 0 &&
													this.state.generatedAudios.filter(
														(el) => this.state.summaryDocumentElements && el.text === this.state.summaryDocumentElements![this.state.ttsParagraphPointer!].text
													).length === 0
												) {
													/* THE AUDIO DOESN'T EXIST YET */
													this.setState({
														disablePlayButton: true,
														disableTTSOptions: true,
														disableTTSSkipButtons: true,
														isLoadingTTS: true
													});

													//console.log("ENTRO NELL IF");
													settingsServices.getUserSettings(this.state.userUuid,this.state.bearerToken).then((ttsSettings) => {
														getTTS(elementsToSynthetize, elementsToSynthetize[this.state.ttsParagraphPointer!].text, ttsSettings)
															.then((response) => {
																sendEvent({
																	"user_id": this.state.userUuid,
																	"event_type": "TTS pressed",
																	"event_properties": {
																		"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
																		"document_uuid": this.state.document?.uuid,
																		"document_name": this.state.document?.name,
																	},
																	"language": i18n.language,
																	"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
																	"app_version": appVersion,
																	"time": Date.now(),
																});
																this.setState({ speechMarks: response.speech_marks });
																//console.log('CIAONE: ', response.length > 1000)
																//console.log('SPEECH MARKS AAA: ', response)
																let tempAudios = this.state.generatedAudios;
																tempAudios.push({
																	id: elementsToSynthetize![0].index,
																	text: elementsToSynthetize![this.state.ttsParagraphPointer!].text,
																	audio: response.audio,
																	speechMarks: response.speech_marks,
																	settings: ttsSettings,
																});
																this.setState(
																	{
																		audioToPlay: response.audio,
																		speechMarks: response.speech_marks,
																		generatedAudios: tempAudios,
																		disablePlayButton: false,
																		disableTTSOptions: false,
																		disableTTSSkipButtons: false,
																		isLoadingTTS: false
																	},
																	() => {
																		this.changeExistingSpans();
																		this.getKaraoke(this.state.documentElements!);
																	}
																);
															})
															.catch((err) => {
																// console.error("[FileViewer] error getting tts:", err);
															});
													});
												} else {
													/* THE AUDIO ALREADY EXISTS FOR THIS PARAGRAPH */
													if (!this.state.showTtsPlayer) {
														sendEvent({
															"user_id": this.state.userUuid,
															"event_type": "TTS pressed",
															"event_properties": {
																"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
																"document_uuid": this.state.document?.uuid,
																"document_name": this.state.document?.name,
															},
															"language": i18n.language,
															"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
															"app_version": appVersion,
															"time": Date.now(),
														});

														let Audio = document.getElementById("audioElement") as HTMLAudioElement;
														/* ADD THE AUDIO TO THE SRC */
														if (this.state.audioToPlay !== undefined) {
															Audio.src = this.state.generatedAudios.filter(
																(el) => el.text === elementsToSynthetize![this.state.ttsParagraphPointer!].text
															)[0].audio;
															Audio.play();
														}
														/* WHY DOES IT GENERATE IT AGAIN? */
														/* DOES IT GET HERE? */
														else {
															this.setState({
																disablePlayButton: true,
																disableTTSOptions: true,
																disableTTSSkipButtons: true,
																isLoadingTTS: true
															});
															settingsServices.getUserSettings(this.state.userUuid,this.state.bearerToken).then((ttsSettings) => {
																getTTS(elementsToSynthetize, elementsToSynthetize[this.state.ttsParagraphPointer!].text, ttsSettings)
																	.then((response) => {
																		this.setState({ speechMarks: response.speech_marks });
																		//console.log('CIAONE: ', response.length > 1000)
																		//console.log('SPEECH MARKS AAA: ', response)
																		let tempAudios = this.state.generatedAudios;
																		tempAudios.push({
																			id: elementsToSynthetize![0].index,
																			text: elementsToSynthetize![this.state.ttsParagraphPointer!].text,
																			audio: response.audio,
																			speechMarks: response.speech_marks,
																			settings: ttsSettings,
																		});
																		this.setState(
																			{
																				audioToPlay: response.audio,
																				speechMarks: response.speech_marks,
																				generatedAudios: tempAudios,
																				disablePlayButton: false,
																				disableTTSOptions: false,
																				disableTTSSkipButtons: false,
																				isLoadingTTS: false,
																			},
																			() => {
																				this.changeExistingSpans();
																				this.getKaraoke(this.state.documentElements!);
																			}
																		);
																	})
																	.catch((err) => {
																		console.error("[FileViewer] error getting tts:", err);
																	});
															});
														}
													} else {
														let Audio = document.getElementById("audioElement") as HTMLAudioElement;
														Audio.pause();
														this.removeTTSHighlighting();
													}
												}
												//put text in a variable for the TTS so it play correctly for summary or normal file
												if (this.state.isSummaryOpen) this.setState({ elementsTTS: this.state.summaryDocumentElements });
												else this.setState({ elementsTTS: this.state.documentElements });

												document.getElementById("textgrid")!.classList.add("spostaTesto");

												// console.log("gionitan", this.state.ttsParagraphPointer);
												if (this.state.isSummaryOpen) {
													this.setState({ elementsTTS: this.state.summaryDocumentElements });
												} else this.setState({ elementsTTS: this.state.documentElements });

												if (!this.state.showTtsPlayer) {
													//put text in a variable for the TTS so it play correctly for summary or normal file

													document.getElementById("textgrid")!.classList.add("spostaTesto");

													// document.getElementById((String(this.state.ttsParagraphPointer)))!.classList.add("paragraphinReading");
												} else {
													let Audio = document.getElementById("audioElement") as HTMLAudioElement;
													Audio.pause();
													document.getElementById("textgrid")!.classList.remove("spostaTesto");
													this.removeTTSHighlighting();
													/* for (let i = 0; i < document.getElementsByClassName('fileViewerParagraph').length; i++) {
														// (document.getElementsByClassName('fileViewerParagraph')[i] as HTMLElement).classList.remove("paragraphinReading");
													} */
												}
												this.showFileViewerPlayer(!this.state.showTtsPlayer);
											}
										}}
										onMouseEnter={() => {
											// document.getElementById("ttslabel")!.style.visibility = "visible";
											// document.getElementById("ttslabel")!.style.opacity = "1";
											document.getElementById("ttslabel")!.style.display = 'unset';

										}}
										onMouseLeave={() => {
											// document.getElementById("ttslabel")!.style.visibility = "hidden";
											// document.getElementById("ttslabel")!.style.opacity = "0";
											document.getElementById("ttslabel")!.style.display = 'none';
										}}
									>
										<IonIcon icon={!this.state.showTtsPlayer ? voiceOff : voiceOn} style={{ paddingInlineStart: "0px", paddingInlineEnd: "0px" }}></IonIcon>
									</IonButton>
									<div id="ttslabel">
										<Trans>Sintesi vocale</Trans>
									</div>
								</div>

								<div className="sideactionbtn" hidden={this.state.showTtsPlayer}>
									<IonButton
										id="conceptualMap"
										className="btn-fileviewer"
										color="whitebuttons"
										style={{ pointerEvents: this.state.mapButtonDisabled ? "none" : "auto" }}
										hidden={this.state.fileEditingMode || this.state.isSummaryOpen || this.state.isKeyWordsOpen}
										onClick={() => {
											if (this.state.paywallState) loadPaywall(true);
											else {
												if (this.state.showTtsPlayer) {
													this.removeTTSHighlighting();
													document.getElementById("textgrid")!.classList.remove("spostaTesto");
													this.showFileViewerPlayer(!this.state.showTtsPlayer);
												}
												this.mapButtonClicked()
											};
										}}
										onMouseEnter={() => {
											// document.getElementById("mapLabel")!.style.visibility = "visible";
											// document.getElementById("mapLabel")!.style.display= "none";
											document.getElementById("mapLabel")!.style.display = "unset";
										}}
										onMouseLeave={() => {
											// document.getElementById("mapLabel")!.style.visibility = "hidden";
											// document.getElementById("mapLabel")!.style.opacity = "0";
											document.getElementById("mapLabel")!.style.display = "none";
										}}
									>
										<IonIcon size="large" slot="icon-only" icon={this.state.mapIcon} />
										<IonSpinner hidden={!this.state.mapButtonDisabled} className="spinner" name="crescent" />
									</IonButton>
									<div id="mapLabel">
										<Trans>Mappa concettuale</Trans>
									</div>
								</div>

								<div className="sideactionbtn" hidden={this.state.showTtsPlayer}>
									<IonButton
										id="modifyDoc"
										className="btn-fileviewer"
										color="whitebuttons"
										onClick={() => {
											if (this.state.paywallState) loadPaywall(true);
											else {
												this.editingModeClicked();
												if (this.state.showTtsPlayer) {
													document.getElementById("textgrid")!.classList.remove("spostaTesto");
													// for (let i = 0; i < document.getElementsByClassName('fileViewerParagraph').length; i++) {
													// (document.getElementsByClassName('fileViewerParagraph')[i] as HTMLElement).classList.remove("paragraphinReading");
													// }
													this.showFileViewerPlayer(!this.state.showTtsPlayer);
												}
											}
										}}
										onMouseEnter={() => {
											// document.getElementById("ModifyLabel")!.style.visibility = "visible";
											// document.getElementById("ModifyLabel")!.style.opacity = "1";
											document.getElementById("ModifyLabel")!.style.display = "unset";
										}}
										onMouseLeave={() => {
											// document.getElementById("ModifyLabel")!.style.visibility = "hidden";
											// document.getElementById("ModifyLabel")!.style.opacity = "0";
											document.getElementById("ModifyLabel")!.style.display = "none";
										}}
									>
										<IonIcon icon={this.state.fileEditingMode ? editTextOff : ediText} slot="icon-only" />
									</IonButton>
									<div id="ModifyLabel">
										{this.state.fileEditingMode &&
											<Trans>Modalità lettura</Trans>
										}
										{!this.state.fileEditingMode &&
											<Trans>Modifica documento</Trans>
										}
									</div>
								</div>

								<div className="sideactionbtn" hidden={this.state.showTtsPlayer}>
									<IonButton
										id="summary"
										className="btn-fileviewer"
										color="whitebuttons"
										hidden={this.state.fileEditingMode}
										onMouseEnter={() => {
											// document.getElementById("SummaryLabel")!.style.visibility = "visible";
											// document.getElementById("SummaryLabel")!.style.opacity = "1";
											document.getElementById("SummaryLabel")!.style.display = "unset";
										}}
										onMouseLeave={() => {
											// document.getElementById("SummaryLabel")!.style.visibility = "hidden";
											// document.getElementById("SummaryLabel")!.style.opacity = "0";
											document.getElementById("SummaryLabel")!.style.display = "none";

										}}
										style={{ pointerEvents: this.state.summButtonDisabled ? "none" : "auto" }}
										onClick={() => {
											if (this.state.paywallState) loadPaywall(true);
											else {
												if (this.state.isGeneratingSummary || (this.state.isGeneratingKeywords && !this.state.summaryState)) {
													return;
												}
												//Deactivates tts when changing views
												if (this.state.showTtsPlayer) {
													this.removeTTSHighlighting();
													document.getElementById("textgrid")!.classList.remove("spostaTesto");
													this.showFileViewerPlayer(!this.state.showTtsPlayer);
												}
												if (!this.state.isSummaryOpen) {
													if (this.state.summaryState) {
														this.setState({ isSummaryOpen: true, isKeyWordsOpen: false, generatedAudios: [], speechMarks: "", ttsParagraphPointer: 0 }, () => {
															viewSummary.call(this);
														});
														document.getElementById("pageScrollControlls")!.hidden = true;
													} else {
														this.setState(
															{ summaryIsClicked: true, typeSumOrKey: "summary", choice: false, isKeyWordsOpen: false, generatedAudios: [] },
															() => {
																viewFile.call(this);
																sendEvent({
																	"user_id": this.state.userUuid,
																	"event_type": "Summary clicked",
																	"event_properties": {
																		"user_org": this.props.orgData !== null ? this.props.orgData.name : 'Private User',
																	},
																	"language": i18n.language,
																	"platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
																	"app_version": appVersion,
																	"time": Date.now()
																})
															}
														);
														// this.setState({ isLoading: true, loadingMessage: 'Sto caricando il documento' })
														document.getElementById("pageScrollControlls")!.hidden = false;
													}
												} else {
													// this.setState({ isLoading: true, loadingMessage: 'Sto caricando il documento' })
													viewFile.call(this);
													document.getElementById("pageScrollControlls")!.hidden = false;
													/*!!!!!!!!!!!! RESETTING SPEECHMARKS AND GENERATED AUDIOS SINCE IF YOU SWITCH FROM FILE TO SUMMARY OR VICE VERSA
													 IT WILL PROCESS THE PREVIOUS SPEECHMARKS AS TEXT TOO !!!!!!!!!!!!!!!!!!!!!!!*/
													this.setState({ isSummaryOpen: false, speechMarks: [], generatedAudios: [] });
												}
											}
										}}
									>
										<IonIcon
											icon={this.state.isGeneratingSummary || (this.state.isGeneratingKeywords && !this.state.summaryState) ? timeOutline : genSumm}
											slot="icon-only"
										/>
										<IonSpinner hidden={!this.state.summButtonDisabled} className="spinner" name="crescent" />
									</IonButton>
									<div id="SummaryLabel">
										<Trans>Genera riassunto</Trans>
									</div>
								</div>
								<div className="sideactionbtn" hidden={this.state.showTtsPlayer}>
									<IonButton
										id="keywords"
										className="btn-fileviewer"
										color="whitebuttons"
										hidden={this.state.fileEditingMode}
										onMouseEnter={() => {
											// document.getElementById("keyWordsLabel")!.style.visibility = "visible";
											// document.getElementById("keyWordsLabel")!.style.opacity = "1";
											document.getElementById("keyWordsLabel")!.style.display = "unset";
										}}
										onMouseLeave={() => {
											// document.getElementById("keyWordsLabel")!.style.visibility = "hidden";
											// document.getElementById("keyWordsLabel")!.style.opacity = "0";
											document.getElementById("keyWordsLabel")!.style.display = "none";
										}}
										style={{ pointerEvents: this.state.keygenButtonDisabled ? "none" : "auto" }}
										onClick={() => {
											if (this.state.paywallState) loadPaywall(true);
											else {
												if (this.state.isGeneratingKeywords || (this.state.isGeneratingSummary && !this.state.keywordsState)) return;
												//Deactivates tts when changing views
												if (this.state.showTtsPlayer) {
													document.getElementById("textgrid")!.classList.remove("spostaTesto");
													// for (let i = 0; i < document.getElementsByClassName('fileViewerParagraph').length; i++) {
													//     // (document.getElementsByClassName('fileViewerParagraph')[i] as HTMLElement).classList.remove("paragraphinReading");
													// }
													this.showFileViewerPlayer(!this.state.showTtsPlayer);
												}
												if (this.state.isSummaryOpen) {
													this.setState({ isSummaryOpen: false }, () => viewFile.call(this)); /* ALSO APPLY ON MOBILE */
													// this.setState({ isLoading: true, loadingMessage: 'Sto caricando il documento' })
												}
												if (!this.state.isKeyWordsOpen) {
													if (this.state.keywordsState) {
														this.setState({ isKeyWordsOpen: true, isSummaryOpen: false }, () => {
															viewSummary.call(this);
														});
														document.getElementById("pageScrollControlls")!.hidden = true;
													} else
														this.setState({ summaryIsClicked: true, typeSumOrKey: "keywords", choice: true }, () => {
															sendEvent({
																"user_id": this.state.userUuid,
																"event_type": this.state.typeSumOrKey ? this.state.typeSumOrKey.charAt(0).toUpperCase() + this.state.typeSumOrKey.slice(1) + " clicked" : '',
																"event_properties": {
																	"user_org": this.props.orgData !== null ? this.props.orgData.name : 'Private User',
																},
																"language": i18n.language,
																"platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
																"app_version": appVersion,
																"time": Date.now()
															})
															viewFile.call(this);
														});
												} else {
													// this.setState({ isLoading: true, loadingMessage: 'Sto caricando il documento' })
													console.log(array[0])
													viewFile.call(this);
													document.getElementById("pageScrollControlls")!.hidden = false;
													this.setState({ isKeyWordsOpen: false });
												}
											}
										}}
									>
										<IonIcon
											icon={(this.state.isGeneratingSummary && !this.state.keywordsState) || this.state.isGeneratingKeywords ? timeOutline : keyWord}
											slot="icon-only"
										/>
										<IonSpinner hidden={!this.state.keygenButtonDisabled} className="spinner" name="crescent" />
									</IonButton>
									<div id="keyWordsLabel">
										<Trans>Genera parole chiave</Trans>
									</div>
								</div>
								<div className="sideactionbtn" hidden>
									<IonButton
										id="keywords"
										className="btn-fileviewer"
										color="whitebuttons"
										hidden={this.state.fileEditingMode}
										onMouseEnter={() => {
											this.setState({ isMouseOverButton: true }, () => this.showMarkers());
										}}
										onMouseLeave={() => {
											this.setState({ isMouseOverButton: false }, () => this.hideMarkers());
										}}
										onClick={() => {
											if (this.state.markerActive) {
												this.disableUnderline();
											}
										}}
										style={{ pointerEvents: this.state.keygenButtonDisabled ? "none" : "auto" }}
									>
										<IonIcon icon={this.state.markerActive ? closeOutline : Markers} slot="icon-only" />
										{/* <IonSpinner hidden={!this.state.keygenButtonDisabled} className='spinner' name='crescent' /> */}
									</IonButton>
									{/* Aggiungere trad */}
									<div
										id="markers"
									// onMouseLeave={() => {
									//     document.getElementById('markers')!.style.visibility = 'hidden';
									//     document.getElementById('markers')!.style.opacity = '0';
									// }}
									>
										<IonButton
											className="spotlightWordAdd-Btn"
											onClick={() => {
												this.setState({ addUnderlineWords: true }, () => this.underline());
												// else {
												// 	this.disableUnderline();
												// }
											}}
										// onClick={()=>{
										// 	// this.toggleColorPicker()
										// 	if(!this.state.addUnderlineWords)
										// 		this.disableUnderline()
										// }}
										>
											<Trans>Evidenzia</Trans>
										</IonButton>

										<div>
											<ChromePicker className="chrome-picker" disableAlpha={true} color={this.state.selectedColor} onChangeComplete={this.handleColorChange} />
											{/* <IonButton className="spotlightWordSave-Btn" onClick={()=>this.disableUnderline()}>Salva Modifiche</IonButton> */}
										</div>
										<IonButton
											className="spotlightWordRemove-Btn"
											onClick={async () => {
												this.setState({ addUnderlineWords: false }, () => this.underline());

												// else {
												// 	this.disableUnderline();
												// }
											}}
										>
											<Trans>Togli</Trans>
										</IonButton>
									</div>
								</div>
							</>
						</div>
					)}
					{/* POPOVER SUMMARY */}
					<IonModal
						isOpen={this.state.summaryIsClicked}
						className="summaryPopover"
						onDidDismiss={() => {
							this.setState({ summaryIsClicked: false });
						}}
					>
						<div className="summaryPopoverDiv">
							<IonText className="sumPopupTxt">{!this.state.choice ? <Trans>Crea riassunto!</Trans> : <Trans>Crea parole chiave!</Trans>}</IonText>
							<div className="summaryPopoverBtns">
								<IonButton fill="outline" className="annullaSummary" onClick={() => {
									this.setState({ summaryIsClicked: false })
									this.removeCheckBoxes();
								}}>
									<Trans>Annulla</Trans>
								</IonButton>

								<IonButton
									className="doSummary"
									onClick={() => {
										this.setState({ sectionSelectMode: true, sectionSelectModeSummary: true, fileEditingMode: false, summaryIsClicked: false }, () => {
											this.handleChkBoxCreation();
										});
									}}
								>
									<Trans>Prosegui</Trans>
								</IonButton>
							</div>
						</div>
					</IonModal>
					{/* PDF DOWNLOAD POPOVER */}
					<IonPopover
						className={"pdfReadyPopover"}
						isOpen={this.state.downloadUrl !== "" && this.state.showPdfReadyPopover}
						onDidDismiss={() => {
							this.setState({ showPdfReadyPopover: false });
						}}
					>
						<div className="pdfReadyPopoverDiv">
							<p>
								<Trans>Il tuo PDF è pronto</Trans>
							</p>
							<IonButton
								onClick={() => {
									//call the cloud function to remove the generated file from storage
									const deleteGeneratedPdf = httpsCallable(functions, "deleteGeneratedPdf-deleteGeneratedPdf");
									deleteGeneratedPdf({
										documentName: this.state.document?.name,
										userUuid: this.state.userUuid,
									})
										.then((response: any) => {
											if (response.ok) {
												//console.log('Successfully deleted file with uuid: ', this.state.document?.uuid)
											} else if (response.error) {
												//show error and return
												//console.log(response.error);
												return;
											}
										})
										.catch((e) => {
											//console.log("ERROR ", e);
										});
									window.open(this.state.downloadUrl, "_system");
									this.setState({ downloadUrl: "" });
								}}
							>
								<Trans>Scarica il PDF</Trans>
							</IonButton>
						</div>
					</IonPopover>
					{/* POPOVER SUMMARY MESSAGE*/}
					<IonPopover
						isOpen={this.state.infoMsg}
						className="summaryPopoverOk"
						onDidDismiss={() => {
							this.setState({ infoMsg: false }); /* //console.log("dismiss") */
							this.removeCheckBoxes()
						}}
					>
						<div className="summaryPopoverOkDiv">
							<IonIcon className="summaryLoadingIcon" icon={loading}></IonIcon>
							<div className="summaryPopoverOkTxt">
								<IonText>
									{!this.state.choice ? <Trans>Stiamo preparando il tuo riassunto!</Trans> : <Trans>Stiamo preparando le tue parole chiave!</Trans>}
								</IonText>{" "}
								{/* TO LOCALIZE THIS */}
								<IonText>
									<Trans>Ti avviseremo appena sarà pronto</Trans>
								</IonText>
							</div>
							<IonButton
								className="doSummary"
								onClick={() => {
									this.setState({ infoMsg: false });
									this.removeCheckBoxes();
								}}
							>
								<Trans>Ho capito</Trans>
							</IonButton>
						</div>
					</IonPopover>
					{/* POPOVER SUMMARY READY MESSAGE*/}
					<IonPopover
						backdropDismiss={false}
						isOpen={this.state.summaryReadyMsg && !this.state.sectionSelectMode}
						className="summaryPopoverOk"
						onDidDismiss={() => {
							this.setState({ summaryReadyMsg: false }); /* //console.log("dismiss") */
						}}
					>
						<div className="summaryPopoverOkDiv">
							<IonIcon className="summaryReadyIcon" icon={reasyLogo}></IonIcon>
							<div className="summaryPopoverOkTxt">
								<IonText>
									<Trans>Il tuo riassunto è pronto!</Trans>
								</IonText>
							</div>
							<IonButton
								className="doSummary"
								onClick={() => {
									this.setState({ isSummaryOpen: true, isKeyWordsOpen: false });
									this.removeCheckBoxes()
									viewSummary.call(this);
									if (!isMobile) document.getElementById("pageScrollControlls")!.hidden = true;
									this.setState({ infoMsg: false });


									/* if (this.state.isSummaryOpen) {
									if (this.state.summaryState) {
										viewSummary(this, array)
										document.getElementById("pageScrollControlls")!.hidden = true
										this.setState({ isSummaryOpen: true, isKeyWordsOpen: false })
									}
									else this.setState({ summaryIsClicked: true })
								}
								else if (this.state.isKeyWordsOpen) {
									if (this.state.keywordsState) {
										viewSummary(this, array)
										document.getElementById("pageScrollControlls")!.hidden = true
										this.setState({ isKeyWordsOpen: true, isSummaryOpen: false })
									}
								}
								else {
									viewFile.call(this,array)
									this.setState({ isSummaryOpen: false })
									document.getElementById("pageScrollControlls")!.hidden = false
	 
								} */
									this.setState({ summaryReadyMsg: false });
								}}
							>
								<Trans>Prosegui</Trans>
							</IonButton>
						</div>
					</IonPopover>
					<IonPopover
						backdropDismiss
						isOpen={this.state.keywordsReadyMsg && !this.state.sectionSelectMode}
						className="summaryPopoverOk"
						onDidDismiss={() => {
							this.setState({ keywordsReadyMsg: false }); /* //console.log("dismiss")  */
							this.removeCheckBoxes;
						}}
					>
						<div className="summaryPopoverOkDiv">
							<IonIcon className="summaryReadyIcon" icon={reasyLogo}></IonIcon>
							<div className="summaryPopoverOkTxt">
								<IonText>
									<Trans>Le parole chiave sono pronte!</Trans>
								</IonText>
							</div>
							<IonButton
								className="doSummary"
								onClick={() => {
									this.setState({ isSummaryOpen: false, isKeyWordsOpen: true });
									this.removeCheckBoxes;
									viewSummary.call(this);
									if (!isMobile) document.getElementById("pageScrollControlls")!.hidden = true;
									/*  if (this.state.isSummaryOpen) {
									 if (this.state.summaryState) {
										 viewSummary(this, array)
										 if (!isMobile)
											 document.getElementById("pageScrollControlls")!.hidden = true
										 this.setState({ isSummaryOpen: true, isKeyWordsOpen: false })
									 }
									 else this.setState({ summaryIsClicked: true })
								 } else if (this.state.isKeyWordsOpen) {
									 if (this.state.keywordsState) {
										 viewSummary(this, array)
										 if (!isMobile)
											 document.getElementById("pageScrollControlls")!.hidden = true
										 this.setState({ isKeyWordsOpen: true, isSummaryOpen: false })
									 }
								 } else {
									 this.setState({ isLoading: true, loadingMessage: 'Sto caricando il documento' })
									 viewFile.call(this, array)
									 if (!isMobile)
										 document.getElementById("pageScrollControlls")!.hidden = false
									 this.setState({ isSummaryOpen: false })
								 } */
									this.setState({ keywordsReadyMsg: false });
								}}
							>
								<Trans>Prosegui</Trans>
							</IonButton>
						</div>
					</IonPopover>

					{!isMobile && this.state.documentElements && this.state.documentElements.length > 0 && (
						<>
							{/* MERITA users can only download summaries and keywords, not the original files */}
							{
								((this.props.orgData != null && this.props.orgData?.uuid === '-NxNg7LxheY6VGbbEsXZ') && (this.state.isSummaryOpen || this.state.isKeyWordsOpen)) ?
									(downloadSection) : (this.props.orgData == null || this.props.orgData?.uuid !== '-NxNg7LxheY6VGbbEsXZ') ? downloadSection : null
							}
							<div className="divNextPrevious" id="pageScrollControlls">
								<IonButtons className="zoomButtons">
									<div style={{ margin: "auto", fontSize: "14px" }}>
										{this.state.pageCounter + 1}/{this.state.numberOfPages}
									</div>
									<IonButton
										id="indietro"
										disabled={(this.state.pageCounter === 0 ? true : false) || this.state.PageButtonDisabled}
										onClick={() => {
											this.setState({ PageButtonDisabled: true });
											let wasEditing = this.state.fileEditingMode;
											if (this.state.fileEditingMode) {
												this.editingModeClicked();
											}

											setTimeout(() => {
												this.setState({ PageButtonDisabled: false });
											}, 100);
											this.PageMinus();
											this.setState({ audioToPlay: undefined });
											if (this.state.ttsParagraphPointer !== null) {
												calcolaToptts.call(this)
											}
											if (wasEditing) {
												this.editingModeClicked();
												this.setState({ fileEditingMode: true });
											}

											// if (this.state.userUuid) {
											//     ttsServices.getTTSSettings(this.state.userUuid)
											//         .then(ttsSettings => {
											//             if (this.state.showTtsPlayer) {
											//                 //console.log((document.getElementById(String(this.state.ttsParagraphPointer)));
											//                 // document.getElementById(String(this.state.ttsParagraphPointer))?.classList.add('paragraphinReading');
											//             }
											//             if (this.state.documentElements !== null &&
											//                 this.state.ttsParagraphPointer !== null &&
											//                 this.state.ttsParagraphPointer >= Number(this.state.startParagraphIndex) &&
											//                 this.state.ttsParagraphPointer <= Number(this.state.lastParagraphIndex)) {
											//                 //console.log((this.state.documentElements[this.state.ttsParagraphPointer].text);
											//                 getTTS(this.state.documentElements, this.state.documentElements[this.state.ttsParagraphPointer].text, ttsSettings)
											//                     .then(audio => {
											//                         this.setState({ audioToPlay: audio });
											//                         let Audio = (document.getElementById('audioElement') as HTMLAudioElement)
											//                         Audio.pause()
											//                     })
											//                     .catch(err => {
											//                         console.error("[FileViewer] error getting tts:", err);
											//                     });
											//             }
											//             return ttsSettings
											//         })
											// }
											// contentRef.current?.scrollToTop(500)
										}}
									>
										<IonIcon icon={ArrowUp} size="medium"></IonIcon>
									</IonButton>

									<IonButton
										id="avanti"
										disabled={(this.state.pageCounter + 1 === this.state.numberOfPages ? true : false) || this.state.PageButtonDisabled}
										onClick={() => {
											this.setState({ PageButtonDisabled: true });
											let wasEditing = this.state.fileEditingMode;
											if (this.state.fileEditingMode) {
												this.editingModeClicked();
											}
											setTimeout(() => {
												this.setState({ PageButtonDisabled: false });
											}, 100);
											this.PagePlus();
											// let appoggio = this.state.pageCounter + 1;

											// this.setState({ pageCounter: appoggio });

											// let firstparagraphid = array[appoggio].firstChild.id;
											// this.setState({ startParagraphIndex: firstparagraphid });

											// let lastparagraphid = array[appoggio].lastChild.id;
											// this.setState({ lastParagraphIndex: lastparagraphid })

											// this.setState({ ttsParagraphPointer: Number(firstparagraphid) });

											if (this.state.showTtsPlayer) {
												this.setState({ audioToPlay: undefined });
												if (this.state.ttsParagraphPointer !== null) {
													calcolaToptts.call(this)
												}
											}
											// (document.getElementById("fileViewerTextDivContainer") as HTMLDivElement)?.replaceChildren(array[appoggio]);
											if (wasEditing) {
												this.editingModeClicked();
												this.setState({ fileEditingMode: true });
											}

											// contentRef.current?.scrollToTop(500)
										}}
									>
										<IonIcon icon={ArrowDown} size="medium"></IonIcon>
									</IonButton>
								</IonButtons>
							</div>
						</>
					)}

					{!isMobile && this.state.documentElements && this.state.documentElements.length > 0 && (
						<div className="divZoom" hidden={this.state.fileEditingMode || this.state.sectionSelectMode}>
							<IonButtons className="zoomButtons">
								<IonButton
									id="zoomIn"
									onClick={() => {
										let currentZoom = parseFloat((document.getElementById("textgrid") as HTMLElement).style.scale) || 1;
										if (currentZoom < 1.6) {
											(document.getElementById("textgrid") as HTMLElement).style.scale = String(currentZoom + 0.1);
										}
									}}
								>
									<IonIcon icon={ZoomIN} />
								</IonButton>

								<IonButton
									id="zoomOut"
									onClick={() => {
										let currentZoom = parseFloat((document.getElementById("textgrid") as HTMLElement).style.scale) || 1;
										if (currentZoom > 0.6) {
											(document.getElementById("textgrid") as HTMLElement).style.scale = String(currentZoom - 0.1);
										}
									}}
								>
									<IonIcon icon={ZoomOut} />
								</IonButton>
							</IonButtons>
						</div>
					)}
					{this.state.sectionSelectModeMap && (
						<div
							hidden={!this.state.sectionSelectMode || isMobile}
							style={{ width: '100%', fontSize: "30px", marginTop: '15px', textAlign: 'center', color: "var(--txt-color)" }}
						>
							<b>
								<Trans>Clicca le sezioni che vuoi trasformare in mappa concettuale...</Trans>
							</b>
						</div>
					)}
					{this.state.sectionSelectModeSummary && (
						<div
							hidden={!this.state.sectionSelectMode || isMobile}
							style={{ width: '100%', fontSize: "30px", marginTop: '15px', textAlign: 'center', color: "var(--txt-color)" }}
						>
							{!this.state.choice && <Trans>Clicca le sezioni che vuoi trasformare in riassunto...</Trans>}
							{this.state.choice && <Trans>Clicca le sezioni da cui estrarre le parole chiave...</Trans>}
						</div>
					)}
					<div hidden={!this.state.sectionSelectModeMap || isMobile} className="fileViewerSelectedSectionsDiv" style={{ zIndex: "1000" }}>
						{this.state.selectedSections.length} {this.state.selectedSections.length === 1 ? <Trans>Sezione</Trans> : <Trans>Sezioni</Trans>}
						<IonButton
							className="annullaMappa"
							fill="outline"
							shape="round"
							onClick={() => {
								this.removeCheckBoxes();
								this.setState({ sectionSelectMode: false, sectionSelectModeMap: false, selectedSections: [] });
							}}
						>
							<Trans>Annulla</Trans>
						</IonButton>
						<IonButton
							className="createMapButton"
							disabled={this.state.selectedSections.length < 1}
							onClick={async () => {
								this.removeCheckBoxes();
								createConceptualMaps.call(this).then(() => {
									sendEvent({
										"user_id": this.state.userUuid,
										"event_type": "Map created",
										"event_properties": {
											"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
											"document_uuid": this.state.document?.uuid,
											"document_name": this.state.document?.name,
										},
										"language": i18n.language,
										"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
										"app_version": appVersion,
										"time": Date.now(),
									});
								});
							}}
						>
							{/* WEB */}
							<Trans>Crea Mappa</Trans>
						</IonButton>
					</div>
					<div hidden={!this.state.sectionSelectModeSummary || isMobile} className="fileViewerSelectedSectionsDiv" style={{ zIndex: "1000" }}>
						{this.state.selectedSections.length} {this.state.selectedSections.length === 1 ? <Trans>Sezione</Trans> : <Trans>Sezioni</Trans>}
						<IonButton
							className="annullaMappa"
							shape="round"
							fill="outline"
							onClick={() => {
								this.removeCheckBoxes();
								this.setState({ sectionSelectMode: false, sectionSelectModeSummary: false, selectedSections: [] });
							}}
						>
							<Trans>Annulla</Trans>
						</IonButton>
						<IonButton
							className="createMapButton"
							disabled={this.state.selectedSections.length < 1}
							onClick={() => {
								this.setState({ sectionSelectModeSummary: false, sectionSelectMode: false, infoMsg: true });
								let documentParagraphs = document.getElementsByClassName("fileViewerParagraph");
								// let totalSize = 0;

								for (let i = 0; i < documentParagraphs.length; i++) {
									const divSize = ((documentParagraphs[i] as HTMLParagraphElement).innerText.length * 2) / 1024; // calculate size in kb
									// totalSize += divSize;
								}
								//console.log((`Total size: ${totalSize.toFixed(2)} kb`);
								for (let i = 0; i < documentParagraphs.length; i++) {
									let el = documentParagraphs[i] as HTMLElement;
									el.style.backgroundColor = "";
									el.style.color = "";
									if (!this.state.isDarkMode) el.style.color = "unset";
								}
								let checkBoxes = document.querySelectorAll('[id^="check-"]');
								checkBoxes.forEach((el) => el.remove());
								auth.currentUser?.getIdToken().then(async (token) => {
									let bearerToken = token;
									let text = this.state.selectedSections.join(" ");
									this.removeCheckBoxes();
									//OLD FUNCTION TO GET SUMMARY USING SPACY THROUGH CLOUD FUNCTION
									// const createStandardMap = httpsCallable(functions, "Summary", { timeout: 600000 });
									// createStandardMap({
									//     text: text,
									//     ln: "it",
									//     docId: this.state.document!.uuid,
									//     auth: bearerToken
									// })
									if (this.state.choice) this.setState({ isGeneratingKeywords: true, keygenButtonDisabled: true });
									else this.setState({ isGeneratingSummary: true, summButtonDisabled: true });
									filesServices
										.getSummary(text, bearerToken, this.state.typeSumOrKey, this.state.document!.uuid, this.state.userUuid)
										.then(async (response) => {
											if (response) {
												if (this.state.choice)
													this.setState({ isGeneratingKeywords: false, keygenButtonDisabled: false });
												else
													this.setState({ isGeneratingSummary: false, summButtonDisabled: false });
												areSummaryOrKeywords.call(this);
												if (this.state.typeSumOrKey === "summary")
													this.setState({ isSummaryOpen: true, summaryReadyMsg: true });
												else
													this.setState({ isKeyWordsOpen: true, keywordsReadyMsg: true });
												sendEvent({
													"user_id": this.state.userUuid,
													"event_type": this.state.typeSumOrKey ? this.state.typeSumOrKey.charAt(0).toUpperCase() + this.state.typeSumOrKey.slice(1) + " created" : '',
													"event_properties": {
														"user_org": this.props.orgData !== null ? this.props.orgData.name : 'Private User',
													},
													"language": i18n.language,
													"platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
													"app_version": appVersion,
													"time": Date.now()
												})
												this.setState({ infoMsg: false });
												//console.log(("success")
												setTimeout(() => {
													//console.log(("GETSUMMARY SUMMARY OPEN: ", this.state.isSummaryOpen);
												}, 500);
											} else {
												//console.log(("error:il summary non è andato a buon fine");
											}
										})
										.catch((code) => {
											//ERROR STATUS CODE 500
											//HANDLE ERROR
											//console.log(("[SUMMARY] error status code ", code);
										});
									this.setState({ selectedSections: [] }); // DO NOT REMOVE!!!
								});
							}}
						>
							<IonText>
								<Trans>Genera</Trans>
							</IonText>
						</IonButton>
					</div>
					{(!this.state.documentElements || this.state.documentElements.length === 0) && (
						<div className="fileViewerProcessingAnimationDiv">
							<Lottie
								isClickToPauseDisabled={true}
								options={{
									loop: true,
									autoplay: true,
									animationData: processingAnimation,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice",
									},
								}}
								height={200}
								width={200}
							/>
							<p className="fileViewerProcessingAnimationParagraph">
								{this.state.firstLoaderMessage}
								{/* <Trans></Trans> */}
							</p>
						</div>
					)}

					{!this.state.showPopoverButton && isMobile && <IonButton hidden={!this.state.showPopoverButton}></IonButton>}

					<IonGrid
						className="fileViewerTextGrid"
						id="textgrid"
						onMouseUp={() => {
							try {
								// this.popoverButtonClicked();
							} catch (error) { }
						}}
					>
						<IonCol className="containerMobile">
							<div
								id="fileViewerTextDivContainer"
								style={{
									position: "absolute",
									width: "100%",
								}}
							></div>
						</IonCol>
					</IonGrid>

					<div id="selectionPopoverDiv" style={{ width: "400px", position: "absolute", top: "40px", left: "63%" }}>
						<SelectionPopover
							history={this.props.history}
							customImageUrl={this.state.wordCustomImage}
							uploadImageClicked={(text) => {
								document.getElementById("viewerImageUpload")?.click();
								this.setState({ selectedText: text });
							}}
							deleteCustomImage={(url, text) => {
								this.deleteCustomImage(url, text);
							}}
							removeDeletedDrawing={(url, text) => {
								this.deleteDrawing(url, text);
							}}
							ref={this.selectionPopoverRef as React.LegacyRef<SelectionPopover>}
						/>
					</div>

					<input
						style={{ position: "absolute", top: "-100px" }}
						id="viewerImageUpload"
						type={"file"}
						accept="image/png,image/gif,image/jpeg"
						onChange={(e) => {
							//console.log(e);
							if (this.state.selectedText) this.uploadCustomImage((e.nativeEvent.target as HTMLInputElement).files?.item(0), false, this.state.selectedText);
							e.target.value = "";
						}}
					/>
					<IonToast
						id="generatingMapToast"
						onDidDismiss={() => this.setState({ showMapCreationStartToast: false })}
						isOpen={this.state.showMapCreationStartToast}
						duration={3000}
						message={
							i18n.language === "it"
								? "Generazione mappa in corso..."
								: i18n.language === "es"
									? "Generación de mapa en curso..."
									: i18n.language === "fr"
										? "Génération de carte en cours..."
										: i18n.language === "en"
											? "Map generation in progress..."
											: i18n.language === "ca"
												? "Generació del mapa en curs..."
												: "Kartenerstellung läuft..."
						}
					/>

					<TtsPlayer
						dictionaryOpen={this.state.dictionaryIsOpen}
						scrolled={howMuchScrolled}
						showPlayer={this.state.showTtsPlayer}
						audioToPlay={this.state.audioToPlay}
						isFirstParagraph={this.state.ttsParagraphPointer === 0}
						isLastParagraph={Number(this.state.ttsParagraphPointer) === Number(this.state.lastParagraphIndex) && this.state.lastParagraphofPages}
						disablePlayButton={this.state.disablePlayButton}
						disableTTSSkipButtons={this.state.disableTTSSkipButtons}
						disableOptions={this.state.disableTTSOptions}
						closeDictionary={() => {
							(this.selectionPopoverRef.current as any).showPopover(false);
							this.setState({ dictionaryIsOpen: false });
						}}
						onBackwardPressed={() => {
							if (!isMobile)
								if (
									Number(this.state.ttsParagraphPointer) === Number(this.state.startParagraphIndex) &&
									array.length > 1 &&
									Number(this.state.ttsParagraphPointer) !== 0
								) {
									this.setState(
										{ speechMarks: "" }
									)
									this.PageMinus();
								}
							handleTTSButtonPress.call(this, "backward");
						}}
						onForwardPressed={() => {
							if (!isMobile) {
								console.log(array);
								if (Number(this.state.lastParagraphIndex) === Number(array[array.length - 1].lastChild.id)) this.setState({ lastParagraphofPages: true });
								if (Number(this.state.ttsParagraphPointer) === Number(this.state.lastParagraphIndex) && array.length > 1) {
									this.setState(
										{ speechMarks: "" }
									)
									this.PagePlus();
								}
							}
							handleTTSButtonPress.call(this, "forward");
						}}
						onRateChange={(Rate) => {
							handleTTSOptionChange.call(this, "Rate", Rate);
						}}
						onVoiceChange={(Voice) => {
							handleTTSOptionChange.call(this, "Voice", Voice);
						}}
						onPlaybackEnded={() => {
							if (this.state.elementsTTS && this.state.elementsTTS.length > 0) {
								this.setState({ disableTTSSkipButtons: true, disablePlayButton: true, disableTTSOptions: true });
								const currentPointer = this.state.ttsParagraphPointer || 0;

								if (this.state.elementsTTS.length > currentPointer + 1) {
									this.setState({ ttsParagraphPointer: currentPointer + 1 }, () => {
										if (this.state.userUuid) {
											settingsServices.getUserSettings(this.state.userUuid,this.state.bearerToken).then((ttsSettings) => {
												if (this.state.elementsTTS && this.state.ttsParagraphPointer !== null) {
													if (!isMobile) {
														if (Number(this.state.ttsParagraphPointer) <= Number(this.state.lastParagraphIndex)) {
															handleTTSLogic.call(this, ttsSettings);
														} else {
															this.PagePlus();
															handleTTSLogic.call(this, ttsSettings);
														}
													} else if (isMobile) {
														if (this.state.ttsParagraphPointer < this.state.elementsTTS.length) {
															// Additional logic for mobile
														}
														handleTTSLogic.call(this, ttsSettings);
													}
												}
											});
										}
									});
								} else {
									this.setState({ disableTTSSkipButtons: false, disablePlayButton: false, disableTTSOptions: false });
								}
							} else {
								this.setState({ ttsParagraphPointer: null });
							}
						}}
					/>
					{/*  <IonFooter className='fileViewerMobileFooter' hidden={!isMobile || !this.state.sectionSelectMode}>
                        <IonToolbar > */}
					{/*  <div
                                    // hidden={!isMobile && !this.state.sectionSelectMode}
                                    className='fileViewerSelectedSectionsDiv'
                                > */}
					{/*  <p style={{ marginLeft: '15px' }} slot='start'>
                                {this.state.selectedSections.length} {this.state.selectedSections.length === 1 ? <Trans>Sezione</Trans> : <Trans>Sezioni</Trans>}
                            </p> */}
					{isMobile && this.state.sectionSelectMode && (
						<div className="createMapButtonDiv">
							<IonButton
								slot="end"
								hidden={!this.state.sectionSelectModeMap}
								disabled={this.state.selectedSections.length < 1}
								className="createMapButton"
								onClick={async () => {
									this.removeCheckBoxes();
									createConceptualMaps.call(this).then(() => {
										sendEvent({
											"user_id": this.state.userUuid,
											"event_type": "Map created",
											"event_properties": {
												"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",

												"document_uuid": this.state.document?.uuid,
												"document_name": this.state.document?.name,
											},
											"language": i18n.language,
											"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
											"app_version": appVersion,
											"time": Date.now(),
										});
									});
								}}
							>
								{/* MOBILE */}
								<Trans>Crea Mappa</Trans>
							</IonButton>
							{/* <IonButton
                                slot='end'
                                fill='outline'
                                className='annullaMappa'
                                style={{ borderRadius: '15px' }}
                                onClick={() => {
                                    let documentParagraphs = document.getElementsByClassName('fileViewerParagraph')
                                    for (let i = 0; i < documentParagraphs.length; i++) {
                                        let el = documentParagraphs[i] as HTMLElement;
                                        el.style.backgroundColor = ''
                                        if (!this.state.isDarkMode)
                                            el.style.color = 'unset'
                                    }
                                    this.setState({ selectedMapType: '', sectionSelectMode: false, selectedSections: [], sectionSelectModeMap: false, sectionSelectModeSummary: false })
                                }}>
                                <Trans>Annulla</Trans>
                            </IonButton> */}
						</div>
					)}
					{/* </div> */}
					<div className="mapButtonMobileDiv">
						<IonButton
							className="createMapButton"
							disabled={this.state.selectedSections.length < 1}
							hidden={!this.state.sectionSelectModeSummary || !isMobile}
							onClick={() => {
								this.removeCheckBoxes();
								generaRiassunto.call(this);
							}}
						>
							<IonText>
								<Trans>Genera</Trans>
							</IonText>
						</IonButton>
					</div>
					{/* </IonToolbar>
                    </IonFooter> */}
					<IonAlert
						isOpen={this.state.showMapTextErrorAlert}
						onDidDismiss={() => this.setState({ showMapTextErrorAlert: false })}
						message={
							i18n.language === "en"
								? "This text is already selected in the map you want to create."
								: i18n.language === "es"
									? "Este texto ya está seleccionado en el mapa que desea crear."
									: i18n.language === "fr"
										? "Ce texte est déjà sélectionné dans la carte que vous voulez créer."
										: i18n.language === "de"
											? "Dieser Text ist in der Karte, die Sie erstellen möchten, bereits ausgewählt"
											: i18n.language === "ca"
												? "Aquest text ja està seleccionat al mapa que vols crear"
												: "Questo testo è già selezionato nella mappa che vuoi creare"
						}
						buttons={[
							{
								text:
									i18n.language === "en"
										? "Understood"
										: i18n.language === "es"
											? "Entiendo"
											: i18n.language === "fr"
												? "J'ai compris"
												: i18n.language === "de"
													? "Ich verstehe"
													: i18n.language === "ca"
														? "Entès"
														: "Ho capito",
								handler: () => {
									this.setState({ showMapTextErrorAlert: false });
								},
							},
						]}
					/>
					<IonModal
						mode="md"
						isOpen={this.state.showMapCreationPopover}
						className={"mapInstructionsPopover"}
					/* buttons={[
						{
							cssClass: 'primary',
							text: i18n.language === 'en' ? 'I understand' :
								i18n.language === 'es' ? 'Entiendo' :
									i18n.language === 'fr' ? "J'ai compris" :
										"Ho capito",
							handler: () => {
								this.setState({ showMapCreationPopover: false })
							}
						},
					]} */
					>
						<div className="mapInstructionsPopoverDiv">
							<IonImg style={{ marginBottom: "35px" }} src={reasyLogo} />
							{i18n.language === "en"
								? "Select the parts you want to turn into a concept map..."
								: i18n.language === "es"
									? "Selecciona las partes que quieres convertir en un mapa conceptual..."
									: i18n.language === "fr"
										? "Sélectionnez les parties que vous souhaitez transformer en carte conceptuelle..."
										: i18n.language === "de"
											? "Wählen Sie die Teile aus, die Sie in eine Konzeptkarte umwandeln möchten ..."
											: i18n.language === "ca"
												? "Selecciona les parts que vols convertir en un mapa conceptual..."
												: "Seleziona le parti che vuoi trasformare in mappa concettuale..."}
							<IonButton style={{ marginTop: "35px" }} onClick={() => this.setState({ showMapCreationPopover: false })}>
								{i18n.language === "en"
									? "I understand"
									: i18n.language === "es"
										? "Entiendo"
										: i18n.language === "fr"
											? "J'ai compris"
											: i18n.language === "de"
												? "Ich verstehe"
												: i18n.language === "ca"
													? "Entès"
													: "Ho capito"}
							</IonButton>
						</div>
					</IonModal>

					{!this.state.disableClickPopover && (
						<ClickPopover
							ref={this.clickPopoverRef as React.LegacyRef<ClickPopover>}
							isOpen={this.state.showClickPopover}
							keyWordStatus={this.state.isKeyWordsOpen}
							documentElement={this.state.clickedDocumentElement}
							textContent={undefined}
							userSettings={this.state.userSettings}
							history={this.props.history}
							uploadImageClicked={(text: string | null) => {
								document.getElementById("viewerImageUpload")?.click();
								this.setState({ selectedText: text });
							}}
							deleteCustomImage={(url: string, text: string) => {
								this.deleteCustomImage(url, text);
							}}
							removeDeletedDrawing={(url: string, text: string) => {
								this.deleteDrawing(url, text);
							}}
							onDidDismiss={() => {
								this.setState({
									showClickPopover: false,
									clickedDocumentElement: null,
								});
							}}
						/>
					)}
				</IonContent>

				{
					(isTablet || isMobile) && (
						<IonFab horizontal="end" vertical="bottom" className="selectionIonFabMainTM">
							<div
								onClick={() => {
									//console.log(this.state.fileEditingMode)
									if (this.state.fileEditingMode) {
										this.handleSave();
										this.editingModeClicked();

									} else
										this.setState({ showModalSelection: true }, () => {
											sendEvent({
												"user_id": this.state.userUuid,
												"event_type": "Reasy button pressed",
												"event_properties": {
													"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
													"document_uuid": this.state.document?.uuid,
												},
												"language": i18n.language,
												"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
												"app_version": appVersion,
												"time": Date.now(),
											});
										});
								}}
							>
								<IonIcon className={isMobile ? "menuIconM" : "menuIconT"} icon={this.state.fileEditingMode ? checkmarkCircle : reasyRoundedLogo} />
							</div>
						</IonFab>
					)
				}
				<IonModal
					mode="ios"
					className={"ViewerModalSelectionFunctionality"}
					isOpen={this.state.showModalSelection}
					onDidPresent={() => {
						if (this.state.tutorialMobile) {
							this.setState({ tutorial: true }, () => {
								console.log("TRIGGERED");

								sendEvent({
									"user_id": this.state.userUuid,
									"event_type": "Tutorial showed",
									"event_properties": {
										"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
										"page": "File Viewer",
									},
									"language": i18n.language,
									"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
									"app_version": appVersion,
									"time": Date.now(),
								});
							});
						}
					}}
					onDidDismiss={() => this.setState({ showModalSelection: false, highlightActive: false })}
				>
					<IonItem lines="none" slot="end" className={"ViewerModalSelectionFunctionalityTitle"}>
						<IonLabel>
							<Trans>Funzioni</Trans>
						</IonLabel>
						<IonIcon
							className="ViewerModalSelectionFunctionalityIcon"
							icon={closeOutline}
							onClick={() => {
								if (this.state.highlightActive) this.setState({ highlightActive: false });
								else this.setState({ showModalSelection: false });
							}}
						></IonIcon>
					</IonItem>
					<div className="modalHighlightBtns" hidden={!this.state.highlightActive}>
						<IonButton
							className="spotlightWordAdd-Btn"
							onClick={() => {
								if (!this.state.markerActive) {
									this.toggleColorPicker();
								} else {
									this.setState({ highlightActive: false });
									this.disableUnderline();
								}
							}}
						>
							<Trans>Evidenzia</Trans>
						</IonButton>

						<IonButton
							className="spotlightWordRemove-Btn"
							onClick={() => {
								if (!this.state.markerActive) {
									this.setState({ addUnderlineWords: false, showModalSelection: false, highlightActive: false }, () => this.underline());
								} else {
									this.setState({ highlightActive: false });
									this.disableUnderline();
								}
							}}
						>
							<Trans>Togli</Trans>
						</IonButton>
					</div>
					<IonModal
						isOpen={this.state.showColorPicker}
						onDidDismiss={() => {
							if (this.state.showColorPicker) this.toggleColorPicker();
						}}
						className="colorPicker"
					>
						<ChromePicker disableAlpha={true} color={this.state.selectedColor} onChangeComplete={this.handleColorChange} />
						<div>
							<IonButton
								className="spotlightWordAdd-Btn"
								onClick={() => {
									this.setState({ addUnderlineWords: true, showModalSelection: false }, () => this.underline());
								}}
							>
								Usa
							</IonButton>
							<IonButton
								className="spotlightWordRemove-Btn"
								onClick={() => {
									this.toggleColorPicker();
									this.disableUnderline();
								}}
							>
								Chiudi
							</IonButton>
						</div>
					</IonModal>
					<div className="functionModalGrid">
						<IonItem
							hidden={this.state.highlightActive}
							id="div1"
							lines="none"
							className={"selectionItemFunctionality"}
							onClick={async () => {
								if (this.state.paywallState) loadPaywall(true);
								else {
									if (this.state.isKeyWordsOpen) viewFile.call(this);
									this.setState({
										showModalSelection: false,
										sectionSelectModeSummary: false,
										sectionSelectMode: false,
										isKeyWordsOpen: false,
										showTtsPlayer: false,
									});
									this.handleChkBoxCreation();
									// for (let i = 0; i < document.getElementsByClassName('fileViewerParagraph').length; i++) {
									//     (document.getElementsByClassName('fileViewerParagraph')[i] as HTMLElement).classList.remove("paragraphinReading");
									// }
									if (this.state.fileEditingMode) {
										this.setState({ fileEditingMode: false });
										this.editingModeClicked();
									}
									if (this.state.document && this.state.document.uuid) {
										await getDoc(doc(firestore, `m/${this.state.document.uuid}/`)).then((documentSnapshot) => {
											if (documentSnapshot.exists()) {
												sendEvent({
													"user_id": this.state.userUuid,
													"event_type": "Map visited",
													"event_properties": {
														"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
														"document_uuid": this.state.document?.uuid,
														"document_name": this.state.document?.name,
													},
													"language": i18n.language,
													"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
													"app_version": appVersion,
													"time": Date.now(),
												});
												try {
													if (this.state.document) this.props.history.push(`/maps/${this.state.document.uuid}`);
												} catch (error) {
													console.log("ERORRRRRRRR", error);
												}
											} else {
												sendEvent({
													"user_id": this.state.userUuid,
													"event_type": "Map button pressed",
													"event_properties": {
														"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
														"document_uuid": this.state.document?.uuid,
														"document_name": this.state.document?.name,
													},
													"language": i18n.language,
													"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
													"app_version": appVersion,
													"time": Date.now(),
												});
												this.setState({
													sectionSelectMode: true,
													sectionSelectModeMap: true,
													showMapCreationPopover: true /*  showMapCreationConfirm: true */,
												});
												// let paragraphs = document.getElementsByTagName("p");
												// for (let i = 0; i < paragraphs.length; i++) {
												// 	const p = paragraphs[i];
												// 	let checkBox = document.createElement("div");
												// 	checkBox.className = "squareSelector";
												// 	checkBox.id = "check-" + p.id;
												// 	p.style.marginLeft = "40px";
												// 	checkBox.onclick = p.onclick;
												// 	p.parentNode!.insertBefore(checkBox, p);
												// }
											}
										});
									}
								}
							}}
						>
							<IonIcon icon={MapsButtonLogo} className={"selectionIconFunctionality"}></IonIcon>
							<IonLabel className="selectionLabelModalFunctionality">
								<Trans>Mappa</Trans>
							</IonLabel>
						</IonItem>

						<IonItem
							id="div2"
							lines="none"
							className={"selectionItemFunctionality"}
							onClick={() => {
								if (this.state.paywallState) loadPaywall(true);
								else {
									this.setState({
										showModalSelection: false,
										sectionSelectModeMap: false,
										sectionSelectModeSummary: false,
										sectionSelectMode: false,
										selectedSections: [],
										selectedMapType: "",
									});
									if (this.state.fileEditingMode) {
										this.setState({ fileEditingMode: false });
										this.editingModeClicked();
									}
									if (this.state.showTtsPlayer) {
										document.getElementById("textgrid")!.classList.remove("spostaTesto");
										// for (let i = 0; i < document.getElementsByClassName('fileViewerParagraph').length; i++) {
										// (document.getElementsByClassName('fileViewerParagraph')[i] as HTMLElement).classList.remove("paragraphinReading");
										// }
										this.showFileViewerPlayer(!this.state.showTtsPlayer);
									}
									if ((this.state.isGeneratingSummary && !this.state.keywordsState) || this.state.isGeneratingKeywords) {
										return;
									}
									if (!this.state.isKeyWordsOpen) {
										if (this.state.keywordsState) {
											this.setState({ isKeyWordsOpen: true, isSummaryOpen: false });
											viewSummary.call(this);
										} else {
											if (this.state.isSummaryOpen) {
												this.setState({ isSummaryOpen: false }); /* ALSO APPLY ON MOBILE */
												viewFile.call(this);
											}
											this.setState({ summaryIsClicked: true, typeSumOrKey: "kewords", choice: true }, () => {
												sendEvent({
													"user_id": this.state.userUuid,
													"event_type": this.state.typeSumOrKey ? this.state.typeSumOrKey.charAt(0).toUpperCase() + this.state.typeSumOrKey.slice(1) + " clicked" : '',
													"event_properties": {
														"user_org": this.props.orgData !== null ? this.props.orgData.name : 'Private User',
													},
													"language": i18n.language,
													"platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
													"app_version": appVersion,
													"time": Date.now()
												})
											});
										}
									} else {
										viewFile.call(this);
										this.setState({ isKeyWordsOpen: false });
									}
								}
							}}
						>
							<IonIcon icon={keyWord} className={"selectionIconFunctionality"}></IonIcon>
							<IonLabel className={"selectionLabelModalFunctionality"}>
								<Trans>Parole chiave</Trans>
							</IonLabel>
						</IonItem>

						<IonItem
							id="div3"
							lines="none"
							className="selectionItemFunctionality"
							onClick={() => {
								if (this.state.paywallState) loadPaywall(true);
								else {
									this.setState({
										showModalSelection: false,
										sectionSelectModeMap: false,
										sectionSelectMode: false,
										selectedSections: [],
										selectedMapType: "",
										generatedAudios: [],
										speechMarks: ""
									});
									if (this.state.fileEditingMode) {
										this.setState({ fileEditingMode: false });
										this.editingModeClicked();
									}
									if (this.state.isGeneratingSummary || (this.state.isGeneratingKeywords && !this.state.summaryState)) {
										return;
									}
									if (this.state.showTtsPlayer) {
										document.getElementById("textgrid")!.classList.remove("spostaTesto");
										this.removeTTSHighlighting();
										this.showFileViewerPlayer(!this.state.showTtsPlayer);
									}
									if (!this.state.isSummaryOpen) {
										if (this.state.summaryState) {
											this.setState({ isSummaryOpen: true, isKeyWordsOpen: false });
											viewSummary.call(this);
										} else {
											//console.log("SONO QUA GIUSTO?");
											this.setState({ isKeyWordsOpen: false });
											this.setState({ summaryIsClicked: true, typeSumOrKey: "summary", choice: false }, () => {
												sendEvent({
													"user_id": this.state.userUuid,
													"event_type": "Summary clicked",
													"event_properties": {
														"user_org": this.props.orgData !== null ? this.props.orgData.name : 'Private User',
													},
													"language": i18n.language,
													"platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
													"app_version": appVersion,
													"time": Date.now()
												})
											});
										}
									} else {
										this.setState({ isLoading: true, loadingMessage: "Sto caricando il documento" });
										viewFile.call(this);
										this.setState({ isSummaryOpen: false });
									}
								}
							}}
						>
							<IonIcon icon={genSumm} className="selectionIconFunctionality"></IonIcon>
							<IonLabel className="selectionLabelModalFunctionality">
								<Trans>Riassunto</Trans>
							</IonLabel>
						</IonItem>

						<IonItem
							id="div4"
							lines="none"
							className="selectionItemFunctionality"
							disabled={this.state.isLoadingTTS}
							onClick={() => {
								if (this.state.paywallState) loadPaywall(true);
								else {
									this.setState({ showModalSelection: false });
									if (this.state.fileEditingMode) {
										this.setState({ fileEditingMode: false });
										this.editingModeClicked();
									}
									let elementsToSynthetize: any = null;
									if (this.state.isSummaryOpen && this.state.summaryDocumentElements && this.state.summaryDocumentElements?.length > 0)
										elementsToSynthetize = this.state.summaryDocumentElements;
									else elementsToSynthetize = this.state.documentElements;
									console.log(this.state.generatedAudios, this.state.speechMarks)
									if (
										this.state.generatedAudios.filter(
											(el) =>
												!this.state.isSummaryOpen &&
												el.text === this.state.documentElements![this.state.ttsParagraphPointer!].text &&
												el.settings.speech_rate === this.state.generatedAudios[0].settings.speech_rate &&
												el.settings.speech_gender === this.state.generatedAudios[0].settings.speech_gender
										).length === 0 &&
										this.state.generatedAudios.filter(
											(el) => this.state.summaryDocumentElements && el.text === this.state.summaryDocumentElements![this.state.ttsParagraphPointer!].text
										).length === 0
									) {
										this.setState({
											disablePlayButton: true,
											disableTTSOptions: true,
											disableTTSSkipButtons: true,
										});
										settingsServices.getUserSettings(this.state.userUuid,this.state.bearerToken).then((ttsSettings) => {
											getTTS(elementsToSynthetize, elementsToSynthetize[this.state.ttsParagraphPointer!].text, ttsSettings)
												.then((audio) => {
													sendEvent({
														"user_id": this.state.userUuid,
														"event_type": "TTS pressed",
														"event_properties": {
															"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
															"document_uuid": this.state.document?.uuid,
															"document_name": this.state.document?.name,
														},
														"language": i18n.language,
														"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
														"app_version": appVersion,
														"time": Date.now(),
													});
													let tempAudios = this.state.generatedAudios;
													tempAudios.push({
														id: elementsToSynthetize[0].index,
														text: elementsToSynthetize[this.state.ttsParagraphPointer!].text,
														audio: audio.audio,
														speechMarks: audio.speech_marks,
														settings: ttsSettings,
													});
													this.setState(
														{
															audioToPlay: audio.audio as string,
															speechMarks: audio.speech_marks,
															generatedAudios: tempAudios,
															disablePlayButton: false,
															disableTTSOptions: false,
															disableTTSSkipButtons: false,
														},
														() => {
															this.changeExistingSpans();

															this.getKaraoke(this.state.documentElements!);
														}
													);
												})
												.catch((err) => {
													console.error("[FileViewer] error getting tts:", err);
												});
										});
									} else {
										if (!this.state.showTtsPlayer) {
											sendEvent({
												"user_id": this.state.userUuid,
												"event_type": "TTS pressed",
												"event_properties": {
													"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
													"document_uuid": this.state.document?.uuid,
													"document_name": this.state.document?.name,
												},
												"language": i18n.language,
												"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
												"app_version": appVersion,
												"time": Date.now(),
											});
											this.getKaraoke(this.state.documentElements!);
											let Audio = document.getElementById("audioElement") as HTMLAudioElement;
											Audio.src = this.state.generatedAudios.filter((el) => el.text === elementsToSynthetize![this.state.ttsParagraphPointer!].text)[0].audio;
											Audio.play();
										} else {
											let Audio = document.getElementById("audioElement") as HTMLAudioElement;
											Audio.pause();
											this.removeTTSHighlighting();
										}
									}
									//put text in a variable for the TTS so it play correctly for summary or normal file
									if (this.state.isSummaryOpen) this.setState({ elementsTTS: this.state.summaryDocumentElements });
									else this.setState({ elementsTTS: this.state.documentElements });

									//console.log(("gionitan", this.state.ttsParagraphPointer);
									if (this.state.isSummaryOpen) {
										this.setState({ elementsTTS: this.state.summaryDocumentElements });
									} else this.setState({ elementsTTS: this.state.documentElements });

									if (!this.state.showTtsPlayer) {
										//put text in a variable for the TTS so it play correctly for summary or normal file
										// document.getElementById((String(this.state.ttsParagraphPointer)))!.classList.add("paragraphinReading");
									} else {
										let Audio = document.getElementById("audioElement") as HTMLAudioElement;
										Audio.pause();
										document.getElementById("textgrid")!.classList.remove("spostaTesto");
										this.removeTTSHighlighting();
									}
									this.showFileViewerPlayer(!this.state.showTtsPlayer);
								}
							}}
						>
							<IonIcon icon={volumeMediumOutline} className="selectionIconFunctionality"></IonIcon>
							<IonLabel className="selectionLabelModalFunctionality">
								<Trans>Sintesi vocale</Trans>
							</IonLabel>
						</IonItem>

						<IonItem
							id="div5"
							lines="none"
							className={"selectionItemFunctionality"}
							onClick={() => {
								if (this.state.paywallState) loadPaywall(true);
								else {
									this.setState({
										showModalSelection: false,
										sectionSelectModeMap: false,
										sectionSelectModeSummary: false,
										sectionSelectMode: false,
										selectedSections: [],
										selectedMapType: "",
									});
									// this.handleChkBoxCreation();
									this.editingModeClicked();

									if (this.state.showTtsPlayer) {
										document.getElementById("textgrid")!.classList.remove("spostaTesto");
										// for (let i = 0; i < document.getElementsByClassName('fileViewerParagraph').length; i++) {
										//     (document.getElementsByClassName('fileViewerParagraph')[i] as HTMLElement).classList.remove("paragraphinReading");
										// }
										this.showFileViewerPlayer(!this.state.showTtsPlayer);
									}
								}
							}}
						>
							<IonIcon icon={ediText} className={"selectionIconFunctionality"}></IonIcon>
							<IonLabel className={"selectionLabelModalFunctionality"}>
								<Trans>Modifica</Trans>
							</IonLabel>
						</IonItem>

						<IonItem
							hidden
							id="div6"
							lines="none"
							className={"selectionItemFunctionality"}
							onClick={() => {
								if (!this.state.markerActive) this.setState({ highlightActive: true });
								else this.disableUnderline();
							}}
						>
							<IonIcon icon={this.state.markerActive ? closeOutline : Markers} className={"selectionIconFunctionality"}></IonIcon>
							<IonLabel className={"selectionLabelModalFunctionality"}>Evidenziatore</IonLabel>
						</IonItem>
					</div>
					<IonIcon
						className="helpIconModal"
						style={{ fontSize: '25px' }}
						onClick={() => {
							this.tutorialOpenMethod = "manual";
							this.setState({ tutorial: true }, () => {
								sendEvent({
									"user_id": this.state.userUuid,
									"event_type": "Tutorial opened",
									"event_properties": {
										"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
										"page": "File Viewer",
									},
									"language": i18n.language,
									"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
									"app_version": appVersion,
									"time": Date.now(),
								});
							});
						}}
						icon={helpCircleOutline}
					></IonIcon>
				</IonModal>

				<IonLoading isOpen={this.state.isLoading} message={this.state.loadingMessage} />
				<IonAlert
					isOpen={this.state.showMapTypeSelector}
					cssClass="custom-alert"
					onDidDismiss={() => this.setState({ showMapTypeSelector: false })}
					inputs={[
						{
							name: `mapType`,
							id: "btnstandard",
							type: "radio",
							label: `Standard ${this.state.selectedMapType === "standard" ? "- Selezionato" : ""}`,
							value: "1",
							checked: true,
						},
						{
							name: `mapType`,
							id: "btnKeywords",
							type: "radio",
							label:
								i18n.language === "it"
									? `Parole chiave ${this.state.selectedMapType === "keywords" ? "- Selezionato" : ""}`
									: i18n.language === "en"
										? `Keywords ${this.state.selectedMapType === "keywords" ? "- Selected" : ""}`
										: i18n.language === "es"
											? `Palabras clave ${this.state.selectedMapType === "keywords" ? "- Seleccionado" : ""}`
											: i18n.language === "fr"
												? `Mots-clés ${this.state.selectedMapType === "keywords" ? "- Sélectionné" : ""}`
												: i18n.language === "ca"
													? `Paraules clau ${this.state.selectedMapType === "keywords" ? "- Seleccionat" : ""}`
													: `Stichwort ${this.state.selectedMapType === "keywords" ? "- Ausgewählt" : ""}`,
							value: "2",
						},
					]}
					buttons={[
						{
							text: "Annulla",
							role: "cancel",
							cssClass: "secondary",
							handler: () => {
								//console.log(('Confirm Cancel');
							},
						},
						{
							text: "Prosegui",
							handler: () => {
								if (this.state.paywallState) loadPaywall(true);
								else {
									if ((document.getElementById("btnstandard") as any).ariaChecked) {
										this.setState({ sectionSelectMode: true, sectionSelectModeMap: true, selectedMapType: "standard", fileEditingMode: false });
										//console.log((this.state.sectionSelectMode);
										let paragraphs = document.getElementsByClassName("fileViewerParagraph");
										for (let i = 0; i < paragraphs.length; i++) {
											let el = paragraphs[i] as HTMLElement;
											el.contentEditable = "false";
											el.style.backgroundColor = "";
										}
									} else if ((document.getElementById("btnKeywords") as any).ariaChecked) {
										this.setState({ sectionSelectMode: true, sectionSelectModeMap: true, selectedMapType: "keywords", fileEditingMode: false });
										let paragraphs = document.getElementsByClassName("fileViewerParagraph");
										for (let i = 0; i < paragraphs.length; i++) {
											let el = paragraphs[i] as HTMLElement;
											el.contentEditable = "false";
											el.style.backgroundColor = "";
										}
									}
								}
							},
						},
					]}
				/>

			</IonPage >
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		showTutorial: state.tutorials.showFileViewerTutorial ? true : false,
		disabledButtons: state.maps.disabledButtons,
		orgData: state.user.organization,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		disableMapButton: (uuid: string) => {
			dispatch(mapsActions.disableMapButton(uuid));
		},
		enableMapButton: (uuid: string) => {
			dispatch(mapsActions.enableMapButton(uuid));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FileViewer);

import { Product, fromProductsDbDataToProducts, getActiveProducts } from "./common/Paywall/store/paywallUtils";
import { ref, onValue } from "firebase/database";
import { httpsCallable } from "firebase/functions";
import { paywallActions } from "./common/Paywall/store/actions";
import { store } from "./common/store";
import { getAnalytics } from "firebase/analytics";
import { auth } from "./firebaseConfig";
import { database } from "./firebaseConfig";
import { functions } from "./firebaseConfig";
import { app } from "./firebaseConfig";
getAnalytics(app);

async function retryGetActiveProducts(purchasedProducts: any, buttonORcomponent: boolean) {
	let maxRetries = 3;
	let delayTime = 150;
	let retries = 0;
	let activeProducts: any[] = [];

	while (retries < maxRetries && activeProducts.length === 0) {
		activeProducts = await getActiveProducts(purchasedProducts);
		if (buttonORcomponent) retries = 3;
		else {
			if (retries < maxRetries - 1 && activeProducts.length === 0) {
				await new Promise((resolve) => setTimeout(resolve, delayTime));
			}
		}
		retries++;
	}
	return activeProducts;
}

let hideORshow: boolean;

export async function showOrHidePaywall(purchasedProducts: Product[], orgActiveProducts: any, buttonORcomponent: boolean) {
	let showPaywall: boolean = false;
	await retryGetActiveProducts(purchasedProducts, buttonORcomponent).then((response) => {
		if (orgActiveProducts.length === 0 && response.length === 0) {
			showPaywall = true;
		} else if (response.length === 0) showPaywall = false;
		else showPaywall = false;

		if (window.location.href.includes("/purchase")) {
			if (buttonORcomponent) store.dispatch(paywallActions.hidePaywall()); // Dispatch the action using the store's dispatch function
			hideORshow = showPaywall;
		} else if (showPaywall) {
			if (buttonORcomponent) store.dispatch(paywallActions.showPaywall()); // Dispatch the action using the store's dispatch function
			hideORshow = showPaywall;
		} else {
			if (buttonORcomponent) store.dispatch(paywallActions.hidePaywall()); // Dispatch the action using the store's dispatch function
			hideORshow = showPaywall;
		}
	});
}

export async function loadPaywall(buttonORcomponent: boolean) {
	return new Promise<boolean>(async (resolve) => {
		auth.onAuthStateChanged(async (userData) => {
			/* console.log("[firebaseConfig] User data", userData); */
			if (userData !== null) {
				const purchasesDbRef = ref(database, `u/${userData.uid}/p`);
				onValue(
					purchasesDbRef,
					async (data) => {
						// console.log("[Firebase] on purchases data red:", data.val());
						if(data.val()!=null){
							const purchasedProducts = fromProductsDbDataToProducts(data.val());
							if (purchasedProducts.length != 0) {
								let allExpired: boolean = true;
								const currentDate = new Date(); // Get the current date and time
								purchasedProducts.forEach(async (element) => {
									const expiryDate = new Date(element.expiryTimestamp * 1000);
									if (expiryDate >= currentDate) {
										allExpired = false;
									}
								});
								if (!allExpired) {
									console.log(allExpired);
									console.log("Product is still valid");
									await showOrHidePaywall(purchasedProducts, [], buttonORcomponent);

									resolve(hideORshow);
								} else {
									console.log("Product has expired");
									getOrganziations(buttonORcomponent, userData.uid).then((result) => {
										resolve(result);
									});
								}
							} else {
								getOrganziations(buttonORcomponent, userData.uid).then((result) => {
									resolve(result);
								});
							}
						}else{
							getOrganziations(buttonORcomponent, userData.uid).then(async (result) => {
								resolve(result);
							});
						}
					},
					(err) => {
						console.error("[Firebase] error on purchases value:", err);
						resolve(hideORshow);
					}
				);
			}
		});
	});
}

function getOrganziations(buttonORcomponent: boolean, userUuid: string) {
	return new Promise<boolean>(async (resolve) => {
		const userOrgRef = ref(database, `u/${userUuid}/o`);

		onValue(
			userOrgRef,
			async (data) => {
				// console.log(
				//   "[Firebase] on user organization value:",
				//   data.val()
				// );

				if (data.val()) {
					const getOrganizationActiveProducts = httpsCallable(functions, "getOrganizationActiveProducts-getOrganizationActiveProducts");
					try {
						await getOrganizationActiveProducts({
							organizationUuid: data.val(),
						}).then(async (response) => {
							const getOrganizationActiveProductsRes = response.data;
							// console.log("[Firebase] org active products:", getOrganizationActiveProductsRes);
							if ((getOrganizationActiveProductsRes as any).orgActiveProducts) {
								await showOrHidePaywall([], (getOrganizationActiveProductsRes as any).orgActiveProducts as Product[], buttonORcomponent);

								resolve(hideORshow);
							} else {
								await showOrHidePaywall([], [], buttonORcomponent);
								console.error("[Firebase] error returned by getOrganizationActiveProducts cloud function:", getOrganizationActiveProductsRes);
								resolve(hideORshow);
							}
						});
					} catch (err) {
						console.error("[Firebase] error calling getOrganizationActiveProducts cloud function:", err);
						resolve(hideORshow);
					}
				} else {
					await showOrHidePaywall([], [], buttonORcomponent);
					resolve(hideORshow);
				}
			},
			(err) => {
				console.error("[Firebase] error on user organization:", err);
				resolve(hideORshow);
			}
		);
	});
}

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { en } from './assets/translations/en';
import { fr } from "./assets/translations/fr";
import { it } from './assets/translations/it';
import { es } from './assets/translations/es';
import { de } from "./assets/translations/de";
import { ca } from "./assets/translations/ca";
import { pt } from "./assets/translations/pt";
import { cz } from "./assets/translations/cz";
import { nl } from "./assets/translations/nl";


i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: en.mergedObject
    },
    en_GB: {
      translations: en.mergedObject
    },
    it: {
      translations: it.mergedObject
    },
    it_: {
      translations: it.mergedObject
    },
    it_IT: {
      translations: it.mergedObject
    },
    fr: {
      translations: fr.mergedObject
    },
    es: {
      translations: es.mergedObject
    },
    itIt: {
      translations: it.mergedObject
    },
    de: {
      translations: de.mergedObject
    },
    ca: {
      translations: ca.mergedObject
    },
    pt: {
      translations: pt.mergedObject
    },
    cz: {
      translations: cz.mergedObject
    },
    nl: {
      translations: nl.mergedObject
    },
  },

  fallbackLng: "it", //changed from 'en' to 'it' since the app default language is italian
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",
  // lng: 'it',
  detection: {
    // Options for language detection
    order: ['localStorage', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
    caches: ['localStorage', 'cookie'],
  },
  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;
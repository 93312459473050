import { Token as PushNotificationToken, ActionPerformed as PushNotificationActionPerformed, PushNotificationSchema as PushNotification, PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { userServices } from '../modules/user/store/services';
import { sendEvent } from './amplitudeUtils';
import { auth } from '../firebaseConfig';
import i18n from '../i18n';
import { isPlatform } from '@ionic/react';
import { appVersion } from '../appVersion';
const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');


function pushNotificationHandler() {
    // alert('oooh: ' + isPushNotificationsAvailable)
    if (isPushNotificationsAvailable) {
        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token: PushNotificationToken) => {
                // alert('triggerato?')
                //console.log('[pushNotificationHandler] token:', JSON.stringify(token));
                userServices.addDevice(token.value)
                    .then(data => {
                        //console.log('[pushNotificationHandler - addDevice service] data:', JSON.stringify(data));
                    })
                    .catch((err) => {
                        // alert('errore qua? ' + err)
                        //console.log('[pushNotificationHandler - addDevice service] error:', JSON.stringify(err));
                    });
            }
        );

        // Some issue with your setup and push will not work
        PushNotifications.addListener('registrationError',
            (error: any) => {
                //console.log('[pushNotificationHandler - registration error] error:', JSON.stringify(error));
                alert('Impossibile attivare le notifiche. Riprovare. ' + JSON.stringify(error)); // TO LOCALIZE THIS
            }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotification) => {
                console.log('[pushNotificationHandler - notification received] notification:', JSON.stringify(notification));
                sendEvent({
                    "user_id": auth.currentUser?.uid ? auth.currentUser.uid : '',
                    "event_type": "Notification Received",
                    "language": i18n.language,
                    "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
                    "app_version": appVersion,
                    "time": Date.now()
                })
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: PushNotificationActionPerformed) => {
                //console.log('[pushNotificationHandler - pushNotificationActionPerformed] notification:', JSON.stringify(notification));

                /* TO TRACKING NOTIFICATION OPENING */

                /* CALL TO ACTION URL */
                if (notification.notification.data.callToActionUrl) {
                    try {
                        let callToActionUrl = new URL(notification.notification.data.callToActionUrl);
                        //console.log('Url:', callToActionUrl); // TO REMOVE
                        sendEvent({
                            "user_id": auth.currentUser?.uid ? auth.currentUser.uid : '',
                            "event_type": "Notification Opened",
                            "language": i18n.language,
                            "event_properties": {
                                "notification_title": notification.notification.title,
                                "notification_body": notification.notification.body,
                                "notification_url": notification.notification.data.callToActionUrl,
                            },
                            "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
                            "app_version": appVersion,
                            "time": Date.now()
                        })
                        if (callToActionUrl && callToActionUrl.toString().startsWith('https://app.reasyedu.com')) {
                            window.location.replace(callToActionUrl.pathname + callToActionUrl.search);
                        }
                        else {
                            window.open(callToActionUrl.toString(), '_system');
                        }
                    }
                    catch (e2) {
                        //console.log('Impossible to construct call to action url from notification:', JSON.stringify(e2));
                    }
                }
            }
        );
    }
}

export default pushNotificationHandler;
import {
    IonAlert,
    IonButton,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonPage,
    IonToggle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { helpCircleOutline } from 'ionicons/icons';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { auth } from '../../../firebaseConfig';
import i18n from '../../../i18n';
import './CredentialsLogin.css';
import './CredentialsSignup.css';
import { sendEvent } from '../../../common/amplitudeUtils';
import { appVersion } from '../../../appVersion';
import { store } from '../../../common/store';
import { userServices } from '../../user/store/services';
import { userActions } from '../../user/store/actions';
import eyeOpened from "../../../assets/icons/eye-alt-svgrepo-com.svg";
import eyeClosed from "../../../assets/icons/eye-slash-alt-svgrepo-com.svg";
import { Organization, Organization_New } from '../../user/store/reducers';


type Props = {
    history: any,

    isAuthenticated?: boolean,
    setOrganizations: (org_data: Organization_New[] | null) => void

};

type State = {
    email: string | null,
    password: string | null,

    legalTermsAccepted: boolean,

    showResetPasswordButton: boolean,

    showErrorAlert: boolean,
    errorAlertHeaderMessage: string,
    errorAlertMessage: string,
    showSupportButton: boolean,
    showPassword: boolean,

};

class CredentialsSignup extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: null,
            password: null,

            legalTermsAccepted: false,

            showResetPasswordButton: false,

            showErrorAlert: false,
            errorAlertHeaderMessage: "",
            errorAlertMessage: "",
            showSupportButton: false,
            showPassword: false,
        }
    }
    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    render() {
        return (
            <IonPage className='authGetStartedPage'>
                <div className='authGetStartedContainer'>

                    {/* {isMobile &&
                    <IonHeader className='ion-no-border authSupportHeader'>
                        <IonToolbar className='authSupportHeaderToolbar'>
                            <IonIcon icon={helpCircleOutline} slot='end' className='authSupportIcon' onClick={() => this.props.history.push('/support')} />
                        </IonToolbar>
                    </IonHeader>
                } */}
                    <IonIcon icon={helpCircleOutline} slot='end' className='authSupportIcon' onClick={() => this.props.history.push('/support')} />
                    <IonGrid className="authGetStartedMainGrid">
                        {/* <IonIcon hidden={!isMobile} icon={helpCircleOutline} className='authSupportIcon' onClick={() => this.props.history.push('/support')} /> */}
                        <div className="authGetStartedTextDiv">
                            <p className="authGetStartedWelcomeText">
                                <Trans>Inserisci email e password per registrarti</Trans>
                            </p>
                        </div>
                        <div className='credentialsSignupInputDiv'>
                            <div className='passEyeContainer'>
                                <IonInput
                                    className="credentialsSignupInputField"
                                    placeholder={i18n.language === 'es' ? "Tu email" : i18n.language === 'en' ? 'Your email address' : i18n.language === 'fr' ? 'Votre e-mail' : 'La tua email'}
                                    type="email"
                                    autocomplete="email"
                                    inputMode="email"
                                    value={this.state.email}
                                    onIonChange={(e) => {
                                        this.setState({ email: e.detail.value ? e.detail.value : null });
                                    }}
                                />
                            </div>
                            <div className='passEyeContainer'>
                                <IonInput
                                    className="credentialsSignupInputField"
                                    placeholder="Password"
                                    type={this.state.showPassword ? "text" : "password"}
                                    inputMode="text"
                                    value={this.state.password}
                                    onIonChange={(e) => {
                                        this.setState({ password: e.detail.value ? e.detail.value : null });
                                    }}
                                />
                                <IonIcon className="passwordShowBtn" icon={this.state.showPassword ? eyeOpened : eyeClosed} onClick={this.togglePasswordVisibility} />
                            </div>

                            <div className="getStartedLegalTermsDiv">
                                <IonToggle
                                    className='magicLinkRequestToggle'
                                    mode="md"
                                    checked={this.state.legalTermsAccepted}
                                    onIonChange={e => {
                                        this.setState({ legalTermsAccepted: e.detail.checked });
                                    }}
                                />
                                <p className="magicLinkRequestTermsParagraph">
                                    <Trans>Ho letto e accetto la </Trans> <a
                                        href='https://app.reasyedu.com/legal/privacy-policy'
                                        target='_blank'
                                        rel="noopener noreferrer"
                                    >
                                        privacy policy
                                    </a><Trans> e i </Trans><a
                                        href='https://app.reasyedu.com/legal/terms-conditions'
                                        target='_blank'
                                        rel="noopener noreferrer"
                                    >
                                        <Trans>termini di servizio</Trans></a>.

                                </p>
                            </div>
                        </div>

                        <div className="credentialsSignupButtonsDiv">
                            <IonButton
                                disabled={!this.state.legalTermsAccepted}
                                className="magicLinkRequestSendEmailButton"
                                onClick={() => {
                                    if (this.state.password === '' || !this.state.password) {
                                        this.setState({
                                            showErrorAlert: true,
                                            errorAlertHeaderMessage: i18n.language === 'it' ? "Password non valida" : i18n.language === 'en' ? 'Invalid password' : i18n.language === 'fr' ? 'Mot de passe incorrect' : i18n.language === 'de' ? "Ungültiges Passwort" : 'Contraseña invalida',
                                            errorAlertMessage: i18n.language === 'it' ? "Assicurati di aver inserito una password" : i18n.language === 'en' ? 'Make sure you have entered a password' : i18n.language === 'fr' ? "Assurez-vous d'avoir saisi un mot de passe" : i18n.language === 'de' ? "Stellen Sie sicher, dass Sie ein Passwort eingegeben haben" : 'Asegúrate de haber ingresado una contraseña',
                                        });
                                        return
                                    }
                                    if (this.state.email === '' || !this.state.email) {
                                        this.setState({
                                            showErrorAlert: true,
                                            errorAlertHeaderMessage: i18n.language === 'it' ? "Email non valida" : i18n.language === 'en' ? 'Invalid email' : i18n.language === 'fr' ? 'Email invalide' : i18n.language === 'de' ? "Ungültige E-Mail" : 'Email inválido',
                                            errorAlertMessage: i18n.language === 'it' ? "Assicurati di aver inserito un'email" : i18n.language === 'en' ? 'Make sure you have entered an email' : i18n.language === 'fr' ? "Assurez-vous d'avoir saisi un e-mail" : i18n.language === 'de' ? "Stellen Sie sicher, dass Sie eine E-Mail-Adresse eingegeben haben" : 'Asegúrate de haber ingresado una e-mail',
                                        });
                                        return
                                    }

                                    if (this.state.legalTermsAccepted && this.state.email && this.state.password) {
                                        createUserWithEmailAndPassword(auth, this.state.email, this.state.password)
                                            .then(uc => {
                                                //console.log("[GetStarted] user created. Uid:", uc.user.uid);
                                                // if (store.getState().user.organization_name === null) {
                                                //     userServices.getUserOrganizations(auth.currentUser!.uid)
                                                //         .then(org_data => {
                                                //             this.props.setOrganizations(org_data && org_data !== 'Private' ? org_data : null)
                                                //         })
                                                // }
                                                sendEvent({
                                                    "user_id": uc.user.uid,
                                                    "event_type": "Sign-Up",
                                                    "event_properties": {
                                                        "method": "email",
                                                    },
                                                    "language": i18n.language,
                                                    "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
                                                    "app_version": appVersion,
                                                    "time": Date.now()
                                                })

                                            })
                                            .catch(err => {
                                                if (err.code === "auth/weak-password") {
                                                    this.setState({
                                                        showErrorAlert: true,
                                                        errorAlertHeaderMessage: i18n.language === 'it' ? "Password debole" : i18n.language === 'es' ? 'Contraseña débil' : i18n.language === 'fr' ? 'Mot de passe faible' : i18n.language === 'de' ? "Schwaches Passwort" : 'Weak password',
                                                        errorAlertMessage: i18n.language === 'it' ? "Prova ad inserire altri caratteri e/o ad usare simboli." : i18n.language === 'es' ? 'Prueba a insertar otros caracteres y/o a utilizar símbolos.' : i18n.language === 'fr' ? "Essayez d'insérer d'autres caractères et/ou d'utiliser des symboles." : i18n.language === 'de' ? "Versuchen Sie, zusätzliche Zeichen einzugeben und/oder Symbole zu verwenden." : 'Try inserting other characters and/or using symbols.',
                                                    });
                                                }
                                                else if (err.code === "auth/internal-error") {
                                                    this.setState({
                                                        showErrorAlert: true,
                                                        errorAlertHeaderMessage: i18n.language === 'it' ? "Errore interno" : i18n.language === 'es' ? 'Error interno' : i18n.language === 'fr' ? 'Erreur interne' : i18n.language === 'de' ? "Interner Fehler" : 'Internal error',
                                                        errorAlertMessage: i18n.language === 'it' ? "Contatta il supporto cliccando l'icona in alto a destra" : i18n.language === 'es' ? 'Póngase en contacto con el servicio de asistencia haciendo clic en el icono de la esquina superior derecha.' : i18n.language === 'fr' ? "Contactez l'assistance en cliquant sur l'icône dans le coin supérieur droit." : i18n.language === 'de' ? "Kontaktiere den Support, indem du auf das Symbol oben rechts klickst" : 'Contact support by clicking the icon in the top right-hand corner.',
                                                    });
                                                }
                                                else if (err.code === "auth/too-many-requests") {
                                                    this.setState({
                                                        showErrorAlert: true,
                                                        errorAlertHeaderMessage: i18n.language === 'it' ? "Troppe richieste" : i18n.language === 'es' ? 'Demasiadas solicitudes' : i18n.language === 'fr' ? 'Trop de demandes' : i18n.language === 'de' ? "Zu viele Anfragen" : 'Too many request',
                                                        errorAlertMessage: i18n.language === 'it' ? "Contattare il supporto o ritentare tra un po'." : i18n.language === 'es' ? "Contacta con el servicio de asistencia o inténtalo de nuevo dentro de un rato" : i18n.language === 'fr' ? "Contactez l'assistance ou réessayez dans quelque temps" : i18n.language === 'de' ? " Kontaktieren Sie den Support oder versuchen Sie es später erneut" : 'Contact support or try again in a while',
                                                    });
                                                }
                                                else if (err.code === "auth/email-already-in-use") {
                                                    this.setState({
                                                        showErrorAlert: true,
                                                        errorAlertHeaderMessage: i18n.language === 'it' ? "Email già registrata" : i18n.language === 'es' ? 'Correo electrónico ya registrado' : i18n.language === 'fr' ? 'Adresse e-mail déjà enregistrée' : i18n.language === 'de' ? "Bereits registrierte E-Mail" : 'Email already registered',
                                                        errorAlertMessage: i18n.language === 'it' ? "Puoi utilizzare un altro indirizzo email, accedere con l'indirizzo email attuale o richiedere la modifica della password per l'indirizzo corrente." : i18n.language === 'es' ? "Puede utilizar otra dirección de correo electrónico, iniciar sesión con la dirección de correo electrónico actual o solicitar el cambio de contraseña para la dirección actual." : i18n.language === 'fr' ? "Vous pouvez utiliser une autre adresse e-mail, vous connecter avec l'adresse e-mail actuelle ou demander la modification du mot de passe pour l'adresse actuelle." : i18n.language === 'de' ? "Sie können eine andere E-Mail-Adresse verwenden, sich mit der aktuellen E-Mail-Adresse anmelden oder eine Passwortänderung für die aktuelle Adresse anfordern." : 'You can use another email address, log in with the current email address, or request a password change for the current address.',
                                                    });
                                                }
                                            })
                                    }
                                }}
                            >
                                <Trans>Iniziamo</Trans>
                            </IonButton>
                            <IonButton
                                fill="clear"
                                onClick={() => {
                                    this.props.history.push(`/authentication/email-password-authentication`);
                                }}
                            >
                                <Trans>Indietro</Trans>
                            </IonButton>
                        </div>

                        {
                            this.props.isAuthenticated &&
                            <Redirect to="/" />
                        }

                        <IonAlert
                            isOpen={this.state.showErrorAlert}
                            cssClass='getStartedErrorAlert'
                            onDidDismiss={() => {
                                this.setState({
                                    showErrorAlert: false,
                                    errorAlertHeaderMessage: "",
                                    errorAlertMessage: "",
                                });
                            }}
                            keyboardClose={true}
                            header={this.state.errorAlertHeaderMessage} /* TO BE LOCALIZED */
                            message={this.state.errorAlertMessage} /* TO BE LOCALIZED */
                            buttons={["Ok"]}
                        />
                    </IonGrid>
                </div>
            </IonPage >
        );
    }

}

const mapStateToProps = (state: any) => {
    return {
        isAuthenticated: state.auth.userData ? true : false,
    }
}


const mapDispatchToProps = (dispatch: any) => {
    return {
        setOrganizations: (org_data: Organization_New[] | null) => {
            dispatch(userActions.setOrganizations(org_data))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CredentialsSignup);

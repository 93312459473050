import { User } from "firebase/auth";
import { authConstants } from "./constants";

export interface AuthenticationStore {
  userData: User | null;
}

interface Action {
  type: string;
  userData?: User;
}

const initialState: AuthenticationStore = {
  userData: null,
};

const authenticationReducer = (
  state: AuthenticationStore = initialState,
  action: Action
): AuthenticationStore => {
  switch (action.type) {
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        userData: action.userData || null,
      };

    case authConstants.LOGOUT:
    case authConstants.LOGIN_FAILED:
      return {
        ...state,
        userData: null,
      };

    default:
      return state;
  }
};

export default authenticationReducer;

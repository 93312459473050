import axios, { AxiosResponse } from "axios";
import { backendApi, backendApiDemo } from "../../../../common/apiService";
import { Audiobook } from "../../../audiobooks/store/reducers";
import { Summary } from "../../../summaries/store/reducers";

export async function createSummary(token: string,language:string, session_id?: string, content?: string,prompt?:string,title?:string) {
	try {
		const response: AxiosResponse = await backendApi.post(
			`/resources/summaries`,
			{
                content: content,
				study_session_id: session_id,
				prompt: prompt,
                title:title,
				language:language,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
                timeout: 45000  // 5 seconds timeout

			}
		);
		if (response.status === 201) {
            console.log("[createSummary]Creation Successful:", response.status);
			return response.data;
		} else {
			console.error("[createSummary]Unexpected error:", response.status);
			return false
		}
	} catch (error) {
		// Handle errors
		if (axios.isAxiosError(error)) {
            if (error.code === 'ECONNABORTED') {
                console.error("[createSummary] Request timed out:", error.message);
            } else {
                console.error("[createSummary] Error creating summary:", error.response?.data || error.message);
			}
			return false
        } else {
			console.error("[createSummary]Unexpected error:", error);
		}
	}
}

export async function updateSummary(token: string,summary_id:string,session_id?: string, content?: string,title?:string) {
	try {
		const response: AxiosResponse = await backendApi.patch(
			`/resources/summaries/${summary_id}`,
			{
                content: content,
				study_session_id: session_id,
                title:title,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
                timeout: 45000  // 5 seconds timeout

			}
		);
		if (response.status === 200) {
            console.log("[updateSummary]Update Successful:", response.status);
			return response.data;
		} else {
			console.error("[updateSummary]Unexpected error:", response.status);
			return false
		}
	} catch (error) {
		// Handle errors
		if (axios.isAxiosError(error)) {
            if (error.code === 'ECONNABORTED') {
                console.error("[updateSummary] Request timed out:", error.message);
            } else {
                console.error("[updateSummary] Error creating summary:", error.response?.data || error.message);
            }		
        } else {
			console.error("[updateSummary]Unexpected error:", error);
		}
	}
}

export function deleteSummary(summary_id: string, token: string) {
    console.log(summary_id)
	return new Promise<Summary>(async (resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let summaryObj: Summary;
		if (summary_id) {
			backendApi
				.delete(`/resources/summaries/${summary_id}`, config)
				.then((response) => {
					if (response.status === 200) {
                        console.log(response.data)
						summaryObj = response.data;
						resolve(summaryObj);
					} else {
						reject(new Error(`[deleteSummary]Request failed with status ${response.status}`));
					}
				})
				.catch((error) => {
					console.error(`[deleteSummary]Error deleting summary ${summary_id}}`, error);
					reject(error);
				});
		}
	});
}

export interface Product {
    uuid: string,

    creationTimestamp: number,
    expiryTimestamp: number,
    from: "web" | "ios" | "android" | "free" | "transfer" | "free-trial",
    productId: string,
    startTimestamp: number,

    originalTransactionId?: string,
    latestReceipt?: string,

    purchaseToken?: string,
}

export function fromProductDbDataToProduct(dbData: any, productUuid: string): Product | null {
    let productToReturn: Product | null = null;
    if(dbData) {
        productToReturn = {
            uuid: productUuid,
            creationTimestamp: dbData.creationTimestamp,
            expiryTimestamp: dbData.e,
            from: fromNumberToPlatform(dbData.f),
            productId: dbData.p,
            startTimestamp: dbData.s
        }
    }
    return productToReturn;
}

export function fromProductsDbDataToProducts(dbData: any): Product[] {
    const productsToReturn: Product[] = [];
    // console.log("[paywallUtils] products db data:", dbData)
    if(dbData) {
        const productsKeys = Object.keys(dbData);
        for (let i = 0; i < productsKeys.length; i++) {
            const product = fromProductDbDataToProduct(dbData[productsKeys[i]], productsKeys[i]);
            if(product) {
                productsToReturn.push(product);
            }            
        }
    }
    return productsToReturn;
}

export function fromNumberToPlatform(platformNumber: number): "web" | "ios" | "android" | "free" | "transfer" | "free-trial" {
    switch (platformNumber) {
        case 0:
            return "web";
        case 1:
            return "ios";
        case 2:
            return "android";
        case 3:
            return "free";
        case 4:
            return "transfer";
        case -1:
            return "free-trial";
    
        default:
            return "transfer";
    }
}

export function fromPlatformToNumber(platform: "web" | "ios" | "android" | "free" | "transfer" | "free-trial"): number  {
    switch (platform) {
        case "web":
            return 0;
        case "ios":
            return 1;
        case "android":
            return 2;
        case "free":
            return 3;
        case "transfer":
            return 4;
        case "free-trial":
            return -1;
    
        default:
            return 3;
    }
}

export async function getActiveProducts(products: Product[]): Promise<Product[]> {
    const nowTimestamp = Math.floor(new Date().getTime() / 1000);
    const filteredProducts = products.filter(
      (product) => product.expiryTimestamp > nowTimestamp && product.startTimestamp < nowTimestamp
    );
    return filteredProducts;
  }
  
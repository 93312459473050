import {
	IonBadge,
	IonButton,
	IonButtons,
	IonContent,
	IonFab,
	IonFabButton,
	IonGrid,
	IonHeader,
	IonIcon,
	IonImg,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonLoading,
	IonMenu,
	IonMenuButton,
	IonMenuToggle,
	IonPage,
	IonPopover,
	IonSearchbar,
	IonSelect,
	IonSelectOption,
	IonSpinner,
	IonSplitPane,
	IonTabBar,
	IonTabButton,
	IonTitle,
	IonToolbar,
	isPlatform,
} from "@ionic/react";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { chevronForward, cloudUploadOutline, personCircleOutline } from "ionicons/icons";
import Cookies from "js-cookie";
import React from "react";
import { connect } from "react-redux";
import createSession from "../../../assets/icons/createSessionIcon.svg";
import { auth, database, functions, remoteConfig } from "../../../firebaseConfig";
import i18n from "../../../i18n";
import { Argument, FileData, Session } from "../store/reducers";
import { argumentServices, getLocalStorageSessionState } from "../store/services";
import "./SessionsHome.css";

import { onValue, ref, update } from "firebase/database";
import { httpsCallable } from "firebase/functions";
import { isIOS, isMobile } from "react-device-detect";
import { appVersion } from "../../../appVersion";
import closeSideMenu from "../../../assets/icons/closeSideMenu.svg";
import openSideMenu from "../../../assets/icons/openSideMenu.svg";
import reasyBook from "../../../assets/icons/reasy_logo_book.svg";
import historyIcon from "../../../assets/icons/history_icon.png";
import geoIcon from "../../../assets/icons/geo_icon.png";
import scienceIcon from "../../../assets/icons/science_icon.png";
import telescope from "../../../assets/icons/telescope.png";

import { sendEvent } from "../../../common/amplitudeUtils";
import { getActiveProducts, Product } from "../../../common/Paywall/store/paywallUtils";
import { store } from "../../../common/store";
import GetPremiumPopover from "../../purchase/components/GetPremiumPopover";
import { TTSSettings } from "../../tts/store/reducers";
import { ttsServices } from "../../tts/store/services";
import { Organization, OrganizationLicenseData, ResourceUsageEvent, UserLicense, UserLicenseData } from "../../user/store/reducers";
import { fromProductsDbDataToProducts, userServices } from "../../user/store/services";
import {
	calculateAvailableResources,
	checkStatusBar,
	getAvailableResources,
	getFreeTrialAvailableResources,
	getHighestLimits,
	getStudySession,
	isImageValid,
	sumOrgLimitsAndPersonalLimits,
	uploadFile,
} from "../utils/utils";
import "./LoadingMaterial.css";
import { Trans } from "react-i18next";
import { settingsServices, UserSettings } from "../../settings/store/services";
import { purchaseServices } from "../../purchase/store/services";
import { ResourceLimit } from "../../purchase/store/reducers";
import { User } from "firebase/auth";
import { fromResourceTypeDBToCorrectName } from "../../purchase/utils/purchaseUtils";

type Props = {
	history: any;
	orgData: Organization | null | "Private";
};

type State = {
	userData: User | null;
	userUuid: string | null;
	userSingleSession: Session[];
	userSessions: Session[];
	// userArguments: any[],
	remoteConfig: boolean;
	newArgument: string;
	isProcessingResources: boolean;
	bearerToken: string;
	fileType: string | undefined;
	isUploadingFile: boolean;
	generatedArgument: Argument | null;
	lastClickTime: number,

	isLoadingArguments: boolean;
	isLoadingAudiobook: boolean;
	isLoadingKeywords: boolean;
	isLoadingMap: boolean;
	isLoadingSummary: boolean;
	isLoadingFlashcards: boolean;
	showPremiumPopover: boolean;
	userProducts: Product[];
	userLicenses: UserLicense[];
	isloadingPaymentSession: boolean;
	ttsSettings: UserSettings;
	searchArgument: string;
	filteredSingleSession: Session[];
	filteredSessions: Session[];
	filteredFiles: FileData[];
	availableSessions: number;
	showReferralRedeemPopup: boolean;
	userLanguage: string;
	files: boolean;
	studySessions: boolean;
	userFiles: FileData[];
	availableResources: any[];
	resourceUsage: ResourceUsageEvent[];
	isGeneratingSession: boolean;
	overflowingArguments: any;
};

class SessionsHome extends React.Component<Props, State> {
	argumentRefs: any;
	constructor(props: any) {
		super(props);
		this.argumentRefs = {};

		this.state = {
			userData: null,
			userUuid: null,
			userSingleSession: [],
			userSessions: [],
			remoteConfig: false,
			newArgument: "",
			bearerToken: "",
			fileType: undefined,
			isUploadingFile: false,
			isLoadingArguments: true,
			lastClickTime: 0,

			isProcessingResources: false,
			isLoadingAudiobook: false,
			isLoadingKeywords: false,
			isLoadingMap: false,
			isLoadingSummary: false,
			isLoadingFlashcards: false,
			generatedArgument: null,
			showPremiumPopover: false,
			userProducts: [],
			userLicenses: [],
			isloadingPaymentSession: false,
			ttsSettings: {
				speech_rate: 1,
				speech_gender: "",
			},
			searchArgument: "",
			filteredSingleSession: [],
			filteredSessions: [],
			filteredFiles: [],
			availableSessions: 75, //just the default number
			showReferralRedeemPopup: false,
			availableResources: [],
			resourceUsage: [],
			isGeneratingSession: false,
			userLanguage: "",
			files: false,
			studySessions: true,
			userFiles: [],
			overflowingArguments: {},
		};

	}

	componentDidMount() {
		// this.setState({ isLoadingArguments: true });
		fetchAndActivate(remoteConfig)
			.then((activated) => {
				if (activated) console.log("Fetched and activated configs!");
				else {
					const remoteConfigValue = getValue(remoteConfig, "showLicenseCode").asBoolean();
					this.setState({ remoteConfig: remoteConfigValue });
				}
			})
			.catch((err) => console.error("Failed to fetch and activate configs", err));
		auth.onAuthStateChanged(async (userData) => {
			if (userData) {
				// console.log(auth.currentUser);
				this.setState({ userData: userData });
				this.handleSessionState("");
				this.handleSessionState("creation");
				// setTimeout(() => {
				userData.getIdToken().then(async (token) => {
					const referral_code = Cookies.get("referral_code");
					if (referral_code) {
						userServices
							.redeemReferralCode(userData.uid, referral_code, token)
							.then((response) => {
								Cookies.remove("referral_code");
								this.setState({ showReferralRedeemPopup: true });
							})
							.catch((err) => { });
					}
					const textValue = Cookies.get("topicCookie");
					if (textValue) this.setState({ newArgument: textValue });
					this.setState({ userUuid: userData.uid, bearerToken: token });

					/* GET ALL USAGE EVENTS */
					let resourceUsage: ResourceUsageEvent[] = [];
					userServices.getResourceUsageEvents(userData.uid, token).then(async (response) => {
						resourceUsage = response;
						this.setState({ resourceUsage: response });
						/* IF THERE'S NO DATA ABOUT THE AVAILABLE RESOURCES */
						if (!localStorage.getItem("availableResources")) {
							/* GET USER LICENSES */
							await getAvailableResources.call(this, userData, token, resourceUsage);
						} else {
							let availableResources = JSON.parse(localStorage.getItem("availableResources") || "[]");
							// console.log("AVAILABLE RESOURCES FROM LOCAL STORAGE", availableResources);
							this.setState({ availableResources: availableResources });
						}
					});

					argumentServices
						.getUserSessions(userData.uid, token)
						.then((response: Session[]) => {
							this.setState({ userSessions: response, filteredSessions: response, isLoadingArguments: false }, () => {
								argumentServices
									.getUserArguments(this.state.userSessions[0].id, token)
									.then((response: Session[]) => {
										response
											.sort((a: any, b: any) => b.created_at - a.created_at) // Sort by created_at
											.filter(
												(item: any, index: number, self: any[]) => index === self.findIndex((t) => t.id === item.id) // Exclude duplicates based on 'id'
											);
										this.setState({ userSingleSession: response, filteredSingleSession: response, isLoadingArguments: false });
										document.getElementById("newArgumentInput")?.focus();
										this.initializeTaebSwitch();
									})
									.catch((err) => {
										this.setState({ isLoadingArguments: false });
										console.log(err);
									});
							});
							document.getElementById("newArgumentInput")?.focus();
						})
						.catch((err: Error) => {
							this.setState({ isLoadingArguments: false });
							console.log("There are no sessions");
						});
					argumentServices.getUserFiles(token, userData.uid).then((response) => {
						this.setState({ userFiles: response, filteredFiles: response });
					});
					settingsServices.getPrimaryLanguage(userData.uid, token).then((response) => {
						this.setState({ userLanguage: response });
					});
				});
				// }, 100);
			} else {
				this.setState({
					userUuid: null,
				});
			}
		});
		// setTimeout(() => {
		// 	this.checkOverflow();
		// }, 500);
		// window.addEventListener("resize", this.handleResize);
		if (document.getElementById("sessionsSideMenu")?.style.display === "none")
			setTimeout(() => {
				this.checkAllOverflows();
			}, 2000);

		window.addEventListener('resize', this.handleResize);
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}
	handleResize = () => {
		this.checkAllOverflows();
	}
	handleSessionState(sessionType: string) {
		let sessionKey = sessionType === "creation" ? "creationSessionState" : "sessionState";
		let storedState = localStorage.getItem(sessionKey);
		let localStorageState = storedState ? JSON.parse(storedState) : [false, null];

		if (localStorageState) {
			let sessionTypes = getLocalStorageSessionState(sessionType);
			// console.log(`${sessionType === "creation" ? "CREATION " : ""}SESSION TYPES:`, sessionTypes);

			if (sessionTypes.length > 0) {
				sessionTypes.forEach((session) => {
					let sessionResourceType = sessionType === "creation" ? session[2] : session;
					let additionalData = sessionType === "creation" ? session.slice(3) : [];

					switch (sessionResourceType) {
						case "map":
							this.setState({ isLoadingMap: true });
							break;
						case "summary":
							this.setState({ isLoadingSummary: true });
							break;
						case "keywords":
							this.setState({ isLoadingKeywords: true });
							break;
						case "flashcards":
							this.setState({ isLoadingFlashcards: true });
							break;
						default:
							this.setState({ isLoadingAudiobook: true });
					}
				});
			}
		}
	}
	handleFileUpload(file: File) {
		if (file.type.startsWith("image/")) {
			isImageValid.call(this, file).then((isValid) => {
				if (isValid)
					this.setState(
						{
							newArgument: file?.name,
							isUploadingFile: true,
							isProcessingResources: true,
						},
						() => uploadFile.call(this, file, this.state.userUuid)
					);
				else {
					let errorMessage =
						i18n.language === "it"
							? "Immagine troppo grande. Le immagini non possono superare gli 8000 pixel di larghezza o altezza. Per favore ridimensiona la tua immagine e riprova"
							: i18n.language === "en"
								? "Image too large. Images cannot exceed 8000 pixels in width or height. Please resize your image and try again."
								: i18n.language === "fr"
									? "Image trop grande. Les images ne peuvent pas dépasser 8000 pixels de largeur ou de hauteur. Veuillez redimensionner votre image et réessayer."
									: i18n.language === "de"
										? "Bild zu groß. Bilder dürfen nicht breiter oder höher als 8000 Pixel sein. Bitte ändern Sie die Größe Ihres Bildes und versuchen Sie es erneut."
										: i18n.language === "ca"
											? "Imatge massa gran. Les imatges no poden superar els 8000 píxels d'amplada o alçada. Si us plau, redimensiona la teva imatge i torna-ho a provar."
											: i18n.language === "es"
												? "Imagen demasiado grande. Las imágenes no pueden exceder los 8000 píxeles de ancho o alto. Por favor, redimensiona tu imagen y vuelve a intentarlo."
												: i18n.language === "pt"
													? "Imagem muito grande. As imagens não podem exceder 8.000 pixels de largura ou altura. Por favor, redimensione sua imagem e tente novamente."
													: i18n.language === "cz"
														? "Obrázek je příliš velký. Obrázky nesmí překročit 8000 pixelů na šířku nebo výšku. Změňte prosím velikost obrázku a zkuste to znovu."
														: i18n.language === "nl"
															? "Afbeelding te groot. Afbeeldingen mogen niet groter zijn dan 8.000 pixels breed of hoog. Herdimensioneer uw afbeelding en probeer het opnieuw."
															: "";

					alert(errorMessage);
					return;
				}
			});
		} else
			this.setState(
				{
					newArgument: file?.name,
					isUploadingFile: true,
					isProcessingResources: true,
				},
				() => uploadFile.call(this, file, this.state.userUuid)
			);
	}

	async handleSessionGeneration(method: "string" | "file", fileObj?: File) {
		if (this.state.resourceUsage && this.state.userData && this.state.bearerToken) {
			// CHECK EVERY RESOURCE AVAILABILITY AND CREATE THE SESSION WITH THE CORRECT STARTING RESOURCE
			getAvailableResources.call(this, this.state.userData, this.state.bearerToken, this.state.resourceUsage).then((response) => {
				console.log("AVAILABLE BEFORE CREATION ", this.state.availableResources);
				if (this.state.availableResources.find((res) => (res.available > 0 || res.available === null))) {
					//* THERE IS AT LEAST ONE RESOURCE AVAILABLE
					this.setState({
						isGeneratingSession: true,
					});
					if (method === "string") {
						sendEvent({
							"user_id": this.state.userUuid,
							"event_type": "Argument string sent",
							"event_properties": {
								"user_org": store.getState().user.organization_name ? store.getState().user.organization_name : "Private User",
								// "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
								"argument_title": this.state.newArgument,
							},
							"language": i18n.language,
							"app_version": appVersion,
							"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
							"time": Date.now(),
						});
						getStudySession.call(this);
					} else {
						if (fileObj) {
							this.handleFileUpload(fileObj);
						}
					}
				} else {
					/*
					 TODO: SHOW PAYWALL
					 */
					this.setState({ showPremiumPopover: true });
				}
			});
		}
	}

	sendStringFromExample(argument: string) {
		this.setState({ newArgument: argument });
		// if (this.state.userSessions.length < 3 || this.state.userProducts.length > 0 || this.state.availableSessions > 0) {
		//     this.setState({
		//         isLoadingAudiobook: true,
		//         isLoadingKeywords: true,
		//         isLoadingMap: true,
		//         isLoadingSummary: true,
		//         isLoadingFlashcards: true,
		//         isProcessingResources: true,
		//         newArgument: argument
		//     }, () => {
		//         sendEvent({
		//             "user_id": this.state.userUuid,
		//             "event_type": "Argument example clicked",
		//             "event_properties": {
		//                 "user_org": store.getState().user.organization_name ? store.getState().user.organization_name : 'Private User',
		//                 // "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
		//                 "argument_title": this.state.newArgument,
		//             },
		//             "language": i18n.language,
		//             "app_version": appVersion,
		//             "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
		//             "time": Date.now()
		//         })
		this.handleSessionGeneration("string");
		//     })
		// }
		// else {
		//     /* SHOW PAYWALL */
		//     this.setState({ showPremiumPopover: true })
		// }
	}

	initializeTaebSwitch() {
		// Get the element with class 'taeb-switch'
		const taeb = document.querySelector(".taeb-switch");

		if (!taeb) return;

		// Get all elements with class 'taeb' inside the taeb switch
		const taebItems = (taeb as HTMLElement).querySelectorAll(".taeb");

		taebItems.forEach((item) => {
			item.addEventListener("click", (event: Event) => {
				const target = event.currentTarget as HTMLElement;

				if (target.classList.contains("active")) return;

				// Get the direction from the 'taeb-direction' attribute
				const direction = target.getAttribute("taeb-direction") || "";

				// Remove the 'left' and 'right' classes and add the direction class
				taeb.classList.remove("left", "right");
				taeb.classList.add(direction);

				// Remove the 'active' class from the currently active element
				(taeb as HTMLElement).querySelector(".taeb.active")?.classList.remove("active");

				// Add the 'active' class to the clicked element
				target.classList.add("active");

				// Get the tab name from the clicked element's data attribute
				const tabName = target.getAttribute("data-tab-name") || "";

				// Update the state based on the tab name
				this.setState(
					(prevState) => ({
						...prevState,
						files: tabName === "documents",
						studySessions: tabName === "session",
					}),
					() => {
						this.checkAllOverflows()
					}
				);
			});
		});
	}
	// checkOverflow = () => {
	// 	const element = document.querySelector(".sessionsHomeArgumentList") as HTMLElement;
	// 	const icon = document.getElementById("wobbleIcon");
	// 	if (element && icon) {
	// 		if (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth) {
	// 			icon.classList.remove("hidden");
	// 		} else {
	// 			icon.classList.add("hidden");
	// 		}
	// 	}
	// };

	getDaysPassed = (date: number) => {
		// Create a Date object from the given timestamp
		const createdAt = new Date(date);
		// Get the current time
		const now = new Date().getTime();
		// Calculate the difference in milliseconds
		const timeDiff = now - createdAt.getTime();

		// Convert the difference to days and return
		return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
	};

	getDayGroupLabel = (daysPassed: number) => {
		if (daysPassed === 0) {
			return i18n.t("Oggi");
		} else if (daysPassed === 1) {
			return i18n.t("Ieri");
		} else if (daysPassed <= 7) {
			return i18n.t("Questa settimana");
		} else if (daysPassed <= 14) {
			return i18n.t("Settimana scorsa");
		} else if (daysPassed <= 30) {
			return i18n.t("Questo mese");
		} else {
			return i18n.t("Sessioni più vecchie");
		}
	};

	checkAllOverflows = () => {

		Object.keys(this.argumentRefs).forEach(id => {
			const titleElement = this.argumentRefs[id]?.querySelector('.sessionsHomeArgumentTitle');
			if (titleElement) {
				const isOverflowing = titleElement.scrollWidth > titleElement.clientWidth;
				this.setState(prevState => ({
					overflowingArguments: {
						...prevState.overflowingArguments,
						[id]: isOverflowing
					}
				}));
			}
		});
	}
	render() {
		let lastGroupLabel = "";

		return (
			<>
				{/* <IonLoading isOpen={this.state.isLoadingArguments} onDidDismiss={()=>this.setState({isLoadingArguments:false})} message={"Sto cercando le tue sessioni..."} /> */}

				<IonSplitPane contentId="sessionsMenu" className="sessionsSplitPane" when="(min-width: 1000px)">
					<IonMenu
						hidden={/* this.state.isProcessingResources && */ this.state.isGeneratingSession}
						onIonWillClose={() => {
							if (document.getElementById("sessionsMenubutton")) document.getElementById("sessionsMenubutton")!.hidden = false;
						}}
						type="reveal"
						contentId="sessionsMenu"
						id="sessionsSideMenu"
						swipeGesture={false}
					>
						{isMobile && isPlatform("capacitor") && isIOS && <IonToolbar className="toolbarNotchSideMenu" style={{ height: checkStatusBar() }}></IonToolbar>}
						{/* <IonHeader className="sessionsMenuHeader">
						</IonHeader> */}
						<div className="sessionsSideMenuTitleContainer">
							<IonMenuToggle id="menuBtnNoSplit" className="sessionSideMenuBtn">
								<IonIcon className="sideMenuButtonImg" src={closeSideMenu} />
							</IonMenuToggle>
							<IonMenuButton
								id="menuBtnSplit"
								className="sessionSideMenuBtn"
								onClick={(e) => {
									let sideMenuElement = (document.getElementById("sessionsSideMenu") as HTMLIonMenuElement)
										? (document.getElementById("sessionsSideMenu") as HTMLIonMenuElement)
										: null;
									let menuButton = (document.getElementById("openSideMenuSessions") as HTMLIonButtonElement)
										? (document.getElementById("openSideMenuSessions") as HTMLIonMenuElement)
										: null;
									if (sideMenuElement) {
										if (sideMenuElement.style.display === "none") {
											document.getElementById("sessionsMenubutton")!.hidden = false;
											sideMenuElement.style.display = "flex";
										} else sideMenuElement.style.display = "none";
										if (menuButton) menuButton.hidden = !menuButton.hidden;
									}
									if (document.getElementById("sessionsMenubutton")) document.getElementById("sessionsMenubutton")!.hidden = false;
									e.currentTarget.hidden = true;
									document.getElementById("openSideMenuSessions")!.hidden = false;
								}}
							>
								<IonIcon className="sideMenuButtonImg" src={closeSideMenu} />
							</IonMenuButton>
							<div className="sessionsMenuHeaderTitle">
								<Trans>Le tue sessioni</Trans>
								<IonSelect
									className="appLanguageSelect"
									value={i18n.language}
									interface="alert"
									interfaceOptions={
										{
											header: i18n.language === 'it' ? 'Seleziona una lingua' :
												i18n.language === 'en' ? 'Select a language' :
													i18n.language === 'es' ? 'Seleccione un idioma' :
														i18n.language === 'de' ? 'Wählen Sie eine Sprache' :
															i18n.language === 'fr' ? 'Sélectionner une langue' :
																'Seleccioneu un idioma'
										}
									}
									onIonChange={(e) => {
										console.log(e.detail.value)
										if (e.detail.value != i18n.language) {
											i18n.changeLanguage(e.detail.value);
											if (this.state.userUuid) {
												settingsServices.setPrimaryLanguage(this.state.userUuid, this.state.bearerToken, e.detail.value)
													.then(() => {
														console.log(i18n.language);
														window.location.reload();
													})
													.catch((err) => {
														console.error(
															"[Profile] error updating user language:",
															err
														);
													});
											}
										}
									}}
								>
									<IonSelectOption value="it">IT 🇮🇹</IonSelectOption>
									<IonSelectOption value="en">EN 🇬🇧</IonSelectOption>
									<IonSelectOption value="fr">FR 🇫🇷</IonSelectOption>
									<IonSelectOption value="es">ES 🇪🇸</IonSelectOption>
									<IonSelectOption value="de">DE 🇩🇪</IonSelectOption>
									<IonSelectOption value="ca">CA 🇪🇸</IonSelectOption>
									<IonSelectOption value="pt">PT 🇵🇹</IonSelectOption>
									<IonSelectOption value="nl">NL 🇳🇱</IonSelectOption>
									<IonSelectOption value="cz">CZ 🇨🇿</IonSelectOption>
								</IonSelect>
							</div>
						</div>
						<IonContent className="ion-padding sideMenuContent">
							<IonSearchbar
								mode="ios"
								placeholder={i18n.t("Cerca per titolo")}
								className="sessionsHomeSearchbar"
								onInput={(e) => {
									if (this.state.studySessions)
										this.setState({ searchArgument: e.currentTarget.value!.toString() }, () => {
											let filteredArguments = this.state.userSessions.filter((arg) =>
												arg.title?.toLowerCase().includes(this.state.searchArgument.toLowerCase())
											);
											this.setState({ filteredSessions: filteredArguments });
										});
									else
										this.setState({ searchArgument: e.currentTarget.value!.toString() }, () => {
											let filteredArguments = this.state.userFiles.filter((arg) =>
												arg.file_name?.toLowerCase().includes(this.state.searchArgument.toLowerCase())
											);
											this.setState({ filteredFiles: filteredArguments });
										});
								}}
							/>
							<div className="wrapper">
								<div className="taeb-switch left text-center">
									<div className="taeb active" taeb-direction="left" data-tab-name="session">
										<Trans>Sessioni di studio</Trans>
									</div>
									<div className="taeb" taeb-direction="right" data-tab-name="documents">
										<Trans>Documenti</Trans>
									</div>
								</div>
							</div>
							{this.state.studySessions
								? this.state.filteredSessions.map((argument) => {
									const daysPassed = this.getDaysPassed(argument.created_at);
									const currentGroupLabel = this.getDayGroupLabel(daysPassed);
									const showDivider = currentGroupLabel !== lastGroupLabel;
									// console.log("SADSADSADSAD: ", showDivider);

									lastGroupLabel = currentGroupLabel;
									// console.log("SADSADSADSAD: ", currentGroupLabel);
									return (
										<React.Fragment key={argument.id}>
											{showDivider && (
												<div key={`${argument.id}-divider`} className="sessionsHomeDayDivider">
													<p>{currentGroupLabel}</p>
												</div>
											)}
											<div
												key={argument.id}
												ref={el => this.argumentRefs[argument.id] = el}
												id={argument.id}
												className={`sessionsHomeArgumentItem hoverEffect ${this.state.overflowingArguments[argument.id] ? 'can-slide' : ''
													}`}
												onClick={() => {
													sendEvent({
														"user_id": this.state.userUuid,
														"event_type": "Argument opened from list",
														"event_properties": {
															"user_org": store.getState().user.organization_name ? store.getState().user.organization_name : "Private User",
															// "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
															"argument_title": argument.title,
														},
														"language": i18n.language,
														"app_version": appVersion,
														"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
														"time": Date.now(),
													});
													this.props.history.push({
														pathname: "/session-details",
														state: { argument: argument },
													});
												}}

											>
												<p className="sessionsHomeArgumentTitle">{argument.title}</p>
												<IonIcon icon={chevronForward} className="sessionsHomeArgumentArrow" />
												<div className="gradientDiv" />
											</div>
										</React.Fragment>
									);
								})
								: this.state.files &&
								this.state.filteredFiles.map((file) => {
									const daysPassed = this.getDaysPassed(file.uploaded_at);
									const currentGroupLabel = this.getDayGroupLabel(daysPassed);
									const showDivider = currentGroupLabel !== lastGroupLabel;
									// console.log("SADSADSADSAD: ", showDivider);
									lastGroupLabel = currentGroupLabel;
									// console.log("SADSADSADSAD: ", currentGroupLabel);
									return (
										<React.Fragment key={file.id}>
											{showDivider && (
												<div key={`${file.id}-divider`} className="sessionsHomeDayDivider">
													<p>{currentGroupLabel}</p>
												</div>
											)}
											<div
												key={file.id}
												ref={el => this.argumentRefs[file.id] = el}
												className={`sessionsHomeArgumentItem hoverEffect ${this.state.overflowingArguments[file.id] ? 'can-slide' : ''
													}`} onClick={() => {
														sendEvent({
															"user_id": this.state.userUuid,
															"event_type": "Argument opened from list",
															"event_properties": {
																"user_org": store.getState().user.organization_name ? store.getState().user.organization_name : "Private User",
																// "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
																"argument_title": file.file_name,
															},
															"language": i18n.language,
															"app_version": appVersion,
															"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
															"time": Date.now(),
														});
														this.props.history.push({
															pathname: `/summaryView`,
															state: { file_id: file.id, file_text: true },
														});
													}}
											>
												<p className="sessionsHomeArgumentTitle">{file.file_name}</p>
												<IonIcon icon={chevronForward} className="sessionsHomeArgumentArrow" />
												<div className="gradientDiv" />
											</div>
										</React.Fragment>
									);
								})}
						</IonContent>
						<IonTabBar className="sessionsSideMenuBottomTabContainer">
							<IonTabButton className="sessionsSideMenuBottomTab" tab="shared" href="/profile">
								{" "}
								{auth.currentUser && (
									<div className="sessionsSideMenuBottomDiv">
										<p className="sessionsSideMenuUserEmail">
											{auth.currentUser?.displayName && auth.currentUser.displayName.trim() !== ""
												? auth.currentUser.displayName
												: auth.currentUser?.email || "User"}
										</p>
										<IonIcon className="sessionsSideMenuProfileIcon" icon={personCircleOutline} />
									</div>
								)}
							</IonTabButton>
						</IonTabBar>
					</IonMenu>

					<IonPage id="sessionsMenu" className="sessionsHomePage body">
						{isMobile && isPlatform("capacitor") && isIOS && <IonToolbar className="toolbarNotch" style={{ height: checkStatusBar() }}></IonToolbar>}
						<div className="sessionsHomeHeaderDiv" hidden={/* this.state.isProcessingResources && */ this.state.isGeneratingSession}>
							<IonMenuToggle
								id="sessionsMenubutton"
								className="sideMenuButtonImg"
								onClick={(e) => {
									setTimeout(() => {
										this.checkAllOverflows();
									}, 1000);
									e.currentTarget.hidden = true;
									let sideMenuElement = document.getElementById("sessionsSideMenu") as HTMLIonMenuElement;
									sideMenuElement.style.display = "flex";
								}}
							>
								<IonIcon className="iconOpenSideMenu" src={openSideMenu} />
							</IonMenuToggle>
							<IonButton
								id="openSideMenuSessions"
								fill="clear"
								onClick={(e) => {
									let sideMenuElement = (document.getElementById("sessionsSideMenu") as HTMLIonMenuElement)
										? (document.getElementById("sessionsSideMenu") as HTMLIonMenuElement)
										: null;
									if (sideMenuElement) {
										if (sideMenuElement.style.display === "none") sideMenuElement.style.display = "flex";
										else sideMenuElement.style.display = "none";
									}
									e.currentTarget.hidden = true;
									document.getElementById("menuBtnSplit")!.hidden = false;
								}}
								hidden={isMobile || document.getElementById("sessionsSideMenu")?.style.display !== "none"}
							>
								<IonIcon className="sideMenuButtonImg" src={openSideMenu} />
							</IonButton>
							<div className="sessionsHomeCounterDiv">
								{this.state.availableResources && (
									<>
										<p id="availableResourcesParagraph" className="sessionsHomeCounterPar" slot="end"
											onClick={() => {
												const currentTime = Date.now();
												const timeSinceLastClick = currentTime - this.state.lastClickTime;
												const cooldownPeriod = 10 * 60 * 1000; // 10 minutes in milliseconds

												if (timeSinceLastClick >= cooldownPeriod) {
													if (this.state.userData) {
														getAvailableResources.call(this, this.state.userData, this.state.bearerToken, this.state.resourceUsage);
													}
													this.setState({ lastClickTime: currentTime });
												} else {
													console.log(`Please wait ${Math.ceil((cooldownPeriod - timeSinceLastClick) / 1000)} seconds before clicking again.`);
												}
											}}>
											<Trans>
												Utilizzi rimanenti
											</Trans>
										</p>
										<IonPopover trigger="availableResourcesParagraph">
											<IonList className="availableResourcesList">
												{this.state.availableResources.map((res, index) => (
													<IonItem key={index} className="availableResourcesItem">
														<IonLabel>
															<Trans>
																{fromResourceTypeDBToCorrectName(res.resource_type)}
															</Trans>
														</IonLabel>
														{
															res.resource_type === "tts" ? (
																<IonBadge slot="end">{res.available != null ? (res.available / 1000 / 60).toFixed() : "ထ"} <Trans>minuti</Trans></IonBadge>
															) : (
																<IonBadge slot="end">{res.available != null ? res.available : "ထ"}</IonBadge>
															) /* TO LOCALIZE THIS */
														}
													</IonItem>

												))}
											</IonList>
										</IonPopover>
									</>
								)}
								{/* {3 - this.state.userArguments.length === 0 && this.state.userProducts.length === 0 && (
                                    <IonButton
                                        className="sessionsHomeUnlockNowBtn"
                                        slot="end"
                                        onClick={() => {
                                            this.setState({ showPremiumPopover: true });
                                        }}
                                    >
                                        Sblocca ora
                                    </IonButton>
                                )}
                                {this.state.userArguments && this.state.userProducts.length ===0 && (
                                    <p className="sessionsHomeCounterPar" slot="end">
                                        <Trans>Hai ancora</Trans> <b>{this.state.availableSessions} / 75 </b> <Trans>Sessioni disponibili questo mese ✨</Trans>
                                    </p>
                                )} */}
							</div>
						</div>

						{/* </IonToolbar>
                        </IonHeader> */}
						{
							/* !this.state.isProcessingResources &&  */ !this.state.isGeneratingSession && !this.state.isLoadingArguments && (
								<div className="sessionsHomeContent">
									{!this.state.isLoadingArguments && (
										<div className="sessionsHomeMainDiv">
											{/* <div className="sessionsHomeOnboardingTitle">
                                            <p style={{ marginBottom: "25px", marginTop: '25px' }}>Sessioni di studio</p>
                                        </div> */}
											<div className="sessionsHomeLogoAndCtaDiv">
												<p className="sessionsHomeTitle">
													<Trans>Che nuovo argomento vuoi studiare?</Trans>
												</p>
												<IonImg className="sessionsHomeLogo" src={reasyBook} />
											</div>
											<div className="sessionsHomeExamplesDiv">
												<div
													className="sessionsHomeExampleDiv"
													onClick={() => {
														this.sendStringFromExample(i18n.t("L'Impero romano"));
													}}
												>
													<IonImg className="sessionsHomeExampleIcon" src={historyIcon} />
													<p>
														<Trans>L'Impero romano</Trans>
													</p>
												</div>
												<div
													className="sessionsHomeExampleDiv"
													onClick={() => {
														this.sendStringFromExample(i18n.t("La mitosi cellulare"));
													}}
												>
													<IonImg className="sessionsHomeExampleIcon" src={scienceIcon} />
													<p>
														<Trans>La mitosi cellulare</Trans>
													</p>
												</div>
												<div
													className="sessionsHomeExampleDiv"
													onClick={() => {
														this.sendStringFromExample(i18n.t("La geografia dell'Italia"));
													}}
												>
													<IonImg className="sessionsHomeExampleIcon" src={geoIcon} />
													<p>
														<Trans>La geografia dell'Italia</Trans>
													</p>
												</div>
												<div
													className="sessionsHomeExampleDiv"
													onClick={() => {
														this.sendStringFromExample(i18n.t("Astrofisica: Buchi neri"));
													}}
												>
													<IonImg className="sessionsHomeExampleIcon" src={telescope} />
													<p>
														<Trans>Astrofisica: Buchi neri</Trans>
													</p>
												</div>
											</div>
											<div className="sessionsHomeInputDiv" style={{ marginBottom: "0px" }}>
												<IonInput
													className="sessionsHomeNewArgumentInput"
													placeholder={i18n.t('Esempio "Il Rinascimento italiano"')}
													onKeyDown={(e) => {
														if (e.key === "Enter") {
															if (this.state.newArgument.trim().length > 3)
																// if (this.state.userSessions.length < 3 || this.state.userProducts.length > 0 || this.state.availableSessions > 0) {
																// this.setState({
																//     isLoadingAudiobook: true,
																//     isLoadingKeywords: true,
																//     isLoadingMap: true,
																//     isLoadingSummary: true,
																//     isLoadingFlashcards: true,
																//     isProcessingResources: true,
																// })
																// sendEvent({
																//     "user_id": this.state.userUuid,
																//     "event_type": "Argument string sent",
																//     "event_properties": {
																//         "user_org": store.getState().user.organization_name ? store.getState().user.organization_name : 'Private User',
																//         // "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
																//         "argument_title": this.state.newArgument,
																//     },
																//     "language": i18n.language,
																//     "app_version": appVersion,
																//     "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
																//     "time": Date.now()
																// })
																this.handleSessionGeneration("string");
															// }
															// else {
															/* SHOW PAYWALL */
															// this.setState({ showPremiumPopover: true })
															// }
														}
													}}
													onInput={(e) => {
														if (e.currentTarget.value) this.setState({ newArgument: e.currentTarget.value.toString() });
														else this.setState({ newArgument: "" });
													}}
													debounce={200}
													value={this.state.newArgument}
												>
													<IonIcon
														className="newArgumentUploadIcon"
														slot="start"
														icon={cloudUploadOutline}
														onClick={() => {
															document.getElementById("argumentFile")?.click();
															// } else {
															// 	/* SHOW PAYWALL */
															// 	this.setState({ showPremiumPopover: true });
															// }
														}}
													/>

													<IonIcon
														slot="end"
														icon={createSession}
														className="newArgumentArrow"
														hidden={this.state.newArgument.trim().length < 3}
														onClick={() => {
															if (this.state.newArgument.trim().length < 3) return;

															this.handleSessionGeneration("string");
														}}
													/>
												</IonInput>
											</div>
										</div>
									)}
									{/*   <IonFab hidden={this.state.userArguments.length === 0} style={{ position: "fixed" }} horizontal="start" vertical="bottom">
                                    <div
                                        className="profileButtonReasy"
                                        onClick={() => {
                                            this.props.history.push('/profile')
                                        }}
                                    >
                                        <IonIcon className="sessionsReasyLogoButton" icon={reasyRoundedLogo} />
                                        <p className="profileButtonReasyText">Vai al profilo</p>
                                    </div>
                                </IonFab> */}
								</div>
							)
						}
						{this.state.isGeneratingSession && (
							/*  <div className="sessionsHomeProcessingScreen">
								 <IonProgressBar buffer={.25} value={.1} />
								 <p className="sessionsHomeArgumentDescription">
									 Attendi qualche secondo, stiamo generando la tua mappa concettuale, il riassunto, le parole chiave e l'audiolibro relativo all'argomento
								 </p>
								 <p className="sessionsHomeProcessingScreenArgument">"{this.state.newArgument}"</p>
							 </div> */
							<IonGrid className="loadingMaterialMainDiv">
								<div className="loadingMaterialHeaderDiv">
									<p className="loadingMaterialHeaderPar">
										<Trans>Stiamo preparando il tuo materiale a tema:</Trans>
									</p>
									<h2 className="loadingMaterialHeaderTitle">
										<b>{this.state.newArgument.trim()}</b>
									</h2>
								</div>

								<div className="loadingMaterialMaterialsContainer">
									{this.state.isLoadingMap && (
										<div className="loadingMaterialMaterialDiv">
											<div className="loadingMaterialStatusDiv">
												{this.state.isLoadingMap && <IonSpinner className="loadingMaterialSpinner" name="crescent" />}
												{!this.state.isLoadingMap && <p className="loadingMaterialCompletedPar">✔️</p>}
											</div>
											<p className="loadingMaterialLabelPar">
												<Trans>Mappa concettuale</Trans>
											</p>
										</div>
									)}

									{/* <div className="loadingMaterialLine" /> */}
									{this.state.isLoadingAudiobook && (
										<div className="loadingMaterialMaterialDiv">
											<div className="loadingMaterialStatusDiv">
												{this.state.isLoadingAudiobook && <IonSpinner className="loadingMaterialSpinner" name="crescent" />}
												{!this.state.isLoadingAudiobook && <p className="loadingMaterialCompletedPar">✔️</p>}
											</div>
											<p className="loadingMaterialLabelPar">
												<Trans>Audiolibro</Trans>
											</p>
										</div>
									)}

									{/* <div className="loadingMaterialLine" /> */}
									{this.state.isLoadingKeywords && (
										<div className="loadingMaterialMaterialDiv">
											<div className="loadingMaterialStatusDiv">
												{this.state.isLoadingKeywords && <IonSpinner className="loadingMaterialSpinner" name="crescent" />}
												{!this.state.isLoadingKeywords && <p className="loadingMaterialCompletedPar">✔️</p>}
											</div>
											<p className="loadingMaterialLabelPar">
												<Trans>Parole chiave</Trans>
											</p>
										</div>
									)}
									{/* <div className="loadingMaterialLine" /> */}
									{this.state.isLoadingSummary && (
										<div className="loadingMaterialMaterialDiv">
											<div className="loadingMaterialStatusDiv">
												{this.state.isLoadingSummary && <IonSpinner className="loadingMaterialSpinner" name="crescent" />}
												{!this.state.isLoadingSummary && <p className="loadingMaterialCompletedPar">✔️</p>}
											</div>
											<p className="loadingMaterialLabelPar">
												<Trans>Riassunto</Trans>
											</p>
										</div>
									)}
									{/* <div className="loadingMaterialLine" /> */}
									{this.state.isLoadingFlashcards && (
										<div className="loadingMaterialMaterialDiv">
											<div className="loadingMaterialStatusDiv">
												{this.state.isLoadingFlashcards && <IonSpinner className="loadingMaterialSpinner" name="crescent" />}
												{!this.state.isLoadingFlashcards && <p className="loadingMaterialCompletedPar">✔️</p>}
											</div>
											<p className="loadingMaterialLabelPar">
												<Trans>Flashcards</Trans>
											</p>
										</div>
									)}
									{!this.state.isLoadingAudiobook &&
										!this.state.isLoadingKeywords &&
										!this.state.isLoadingMap &&
										!this.state.isLoadingSummary &&
										!this.state.isLoadingFlashcards && (
											<div className="loadingMaterialStartButtonDiv">
												<IonButton
													className="loadingMaterialStartButton"
													mode="ios"
													onClick={() => {
														if (this.state.generatedArgument) {
															this.props.history.push({
																pathname: "/session-details",
																state: { argument: this.state.generatedArgument },
															});
														}
													}}
												>
													<Trans>Inizia a studiare</Trans>
												</IonButton>
											</div>
										)}
								</div>
							</IonGrid>
						)}
						<input
							type="file"
							id="argumentFile"
							style={{ opacity: 0, position: "absolute", pointerEvents: "none" }}
							onClick={(e) => {
								if (!this.state.availableResources.find((res) => res.available > 0 || res.available === null)) {
									e.preventDefault();
									e.stopPropagation();
									this.setState({ showPremiumPopover: true });
								}
							}}
							onChange={(e) => {
								// this.setState({
								//     isLoadingAudiobook: true,
								//     isLoadingKeywords: true,
								//     isLoadingMap: true,
								//     isLoadingSummary: true,
								//     isLoadingFlashcards: true,
								// })
								// if (this.state.userSessions.length < 3 || this.state.userProducts.length > 0 || this.state.availableSessions > 0) {
								const file = (e.nativeEvent.target as HTMLInputElement).files?.item(0);
								if (file) {
									if (file.size > 25 * 1024 * 1024) {
										/* FILE SIZE > 25MB */
										let errorMessage =
											i18n.language === "it"
												? "Errore durante il caricamento del file. Foto/file non chiaro o dimensione superiore a 25MB, riprovare.(Se il file supera i 25MB, comprimi o separalo in più parti)"
												: i18n.language === "en"
													? "Error during file upload. The photo/file is unclear or exceeds 25MB in size, please try again. (If the file exceeds 25MB, compress it or split it into multiple parts)"
													: i18n.language === "fr"
														? "Erreur lors du chargement du fichier. La photo/le fichier est flou ou dépasse 25 Mo, veuillez réessayer. (Si le fichier dépasse 25 Mo, compressez-le ou divisez-le en plusieurs parties.)"
														: i18n.language === "de"
															? "Fehler beim Hochladen der Datei. Das Foto/die Datei ist unklar oder überschreitet 25 MB, bitte versuchen Sie es erneut. (Wenn die Datei 25 MB überschreitet, komprimieren Sie sie oder teilen Sie sie in mehrere Teile auf.)"
															: i18n.language === "ca"
																? "Error durant la càrrega del fitxer. La foto/el fitxer no és clar o supera els 25 MB, si us plau, torni a intentar-ho. (Si el fitxer supera els 25 MB, comprimeixi'l o separi'l en diverses parts.)"
																: i18n.language === "es"
																	? "Error durante la carga del archivo. La foto/el archivo no está claro o supera los 25 MB, por favor, intente nuevamente. (Si el archivo supera los 25 MB, comprímalo o sepárelo en varias partes.)"
																	: "Erro durante o carregamento do arquivo. A foto/arquivo está pouco claro ou excede 25 MB, por favor, tente novamente. (Se o arquivo exceder 25 MB, compacte-o ou divida-o em várias partes.)";
										alert(errorMessage);
										return;
									}
									this.handleSessionGeneration("file", file);
								}
								// }
								// else {
								// this.setState({ showPremiumPopover: true })
								// }
							}}
							accept="image/png, image/jpg, application/pdf"
						></input>
						<IonLoading isOpen={this.state.isLoadingArguments} message={i18n.t("Sto cercando le tue sessioni...")} />
						<GetPremiumPopover
							userUuid={this.state.userUuid ? this.state.userUuid : ""}
							isOpen={this.state.showPremiumPopover}
							history={this.props.history}
							onGetPremiumButtonPressed={() => {
								this.props.history.push("/purchase");
								return;
								// this.setState({ showPremiumPopover: false, isloadingPaymentSession: true });
								// const createCheckoutSession = httpsCallable(functions, "createCheckoutSession-createCheckoutSession")
								// createCheckoutSession({})
								//     .then(res => {
								//         this.setState({ isloadingPaymentSession: false })
								//         console.log("[Purchase] checkout session res:", res);
								//         if (res.data && (res.data as any).sessionUrl) {
								//             window.location.replace((res.data as any).sessionUrl);
								//         }
								//     })
								//     .catch(err => {
								//         console.log("[Purchase] error creating checkout session:", err);
								//     })
							}}
							onDidDismiss={() => {
								this.setState({ showPremiumPopover: false });
							}}
						/>
						<IonLoading isOpen={this.state.isloadingPaymentSession} message={"Caricamento..."} />
						{/* TO LOCALIZE THIS */}
					</IonPage>
				</IonSplitPane>
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		orgData: state.user.organization,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionsHome);

export const AudiobookTranslationsEN = {
    "Ascolta l'audiolibro!": "Listen to the audiobook!",
    "Sto caricando l'audiolibro": "I am loading the audiobook",
}
export const AudiobookTranslationsIT = {
    "Ascolta l'audiolibro!": "Ascolta l'audiolibro!",
    "Sto caricando l'audiolibro": "Sto caricando l'audiolibro"
}

export const AudiobookTranslationsES = {
    "Ascolta l'audiolibro!": "¡Escucha el audiolibro!",
    "Sto caricando l'audiolibro": "Estoy cargando el audiolibro"
}
export const AudiobookTranslationsFR = {
    "Ascolta l'audiolibro!": "Écoutez l'audiobook !",
    "Sto caricando l'audiolibro": "Je charge l'audiobook",
}
export const AudiobookTranslationsDE = {
    "Ascolta l'audiolibro!": "Hören Sie das Hörbuch!",
    "Sto caricando l'audiolibro": "Ich lade das Hörbuch"
}
export const AudiobookTranslationsCA = {
    "Ascolta l'audiolibro!": "Escolta l'audiollibre!",
    "Sto caricando l'audiolibro": "Estic carregant l'audiollibre"
}
export const AudiobookTranslationsPT = {
    "Ascolta l'audiolibro!": "Ouça o audiolivro!",
    "Sto caricando l'audiolibro": "Estou carregando o audiolivro"
}
export const AudiobookTranslationsCZ = {
    "Ascolta l'audiolibro!": "Poslouchejte audioknihu!",
    "Sto caricando l'audiolibro": "Načítám audioknihu"
}
export const AudiobookTranslationsNL = {
    "Ascolta l'audiolibro!": "Luister naar het audioboek!",
    "Sto caricando l'audiolibro": "Ik laad het audioboek"
}

import { mapsConstant } from "./constants";

export const mapsActions = {
    disableMapButton,
    enableMapButton,
    toggleColorFill,
    toggleUppercase,
    toggleImagesCaptions,
};

const actionCreator = {
    disableMapButton: (uuid: string) => { return { type: mapsConstant.DISABLE_MAP_BUTTON, uuid } },
    enableMapButton: (uuid: string) => { return { type: mapsConstant.ENABLE_MAP_BUTTON, uuid } },
    toggleColorFill: () => { return { type: mapsConstant.TOGGLE_COLOR_FILL } },
    toggleUppercase: () => { return { type: mapsConstant.TOGGLE_UPPERCASE } },
    toggleImagesCaptions: () => { return { type: mapsConstant.TOGGLE_IMAGES_CAPTIONS } },
}

function disableMapButton(uuid: string) {
    return (dispatch: any) => {
        dispatch(actionCreator.disableMapButton(uuid));
    };
}
function enableMapButton(uuid: string) {
    return (dispatch: any) => {
        dispatch(actionCreator.enableMapButton(uuid));
    };
}

function toggleColorFill() {
    return (dispatch: any) => {
        dispatch(actionCreator.toggleColorFill());
    };
}
function toggleImagesCaptions() {
    return (dispatch: any) => {
        dispatch(actionCreator.toggleImagesCaptions());
    };
}

function toggleUppercase() {
    return (dispatch: any) => {
        dispatch(actionCreator.toggleUppercase());
    };
}
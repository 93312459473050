import {
    IonButton, IonButtons, IonContent, IonHeader, IonIcon,
    IonItem, IonLabel, IonList, IonPage, IonRadio, IonRadioGroup,
    IonRange, IonTitle, IonToolbar, isPlatform,
} from '@ionic/react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ref as storageRef, uploadBytes } from 'firebase/storage';
import {
    arrowBackOutline, arrowRedoOutline, arrowUndoOutline, brushOutline
} from 'ionicons/icons';
import clearCanvasIcon from '../../../assets/icons/clear_canvas_icon.svg'
import React from "react";
import { isMobile } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { Prompt } from 'react-router';
import { ReactSketchCanvas } from "react-sketch-canvas";
import { canvasSvgTools } from '../../../common/canvasSvgTools';
import { auth, firestore, storage } from '../../../firebaseConfig';
import i18n from '../../../i18n';
import { makeId } from '../../files/store/services';
import { sketchServices } from "../store/services";
import './Sketch.css';

type Props = {
    history: any,
}

type State = {
    word: string | undefined,
    drawingUrl: string | undefined,
    colors: Color[],
    selectedColor: string,
    // eraseMode: boolean,
    strokeWidth: number,
    savedChanges: boolean,
    initialPath: any[],
    onceExecute: boolean,
    userUuid: string | null,
    selectedNodeId: string,
    documentId: string,
    docTitle: string,
}

interface Color {
    color: string,
    name: string,
}

export default class Sketch extends React.Component<Props, State> {
    canvas: any;
    update: any;

    constructor(props: any,) {
        super(props);
        this.state = {
            word: undefined,
            userUuid: null,
            drawingUrl: undefined,
            selectedColor: '#000000',
            // eraseMode: false,
            strokeWidth: isPlatform('mobile') ? 3 : 5,
            colors: [
                { color: '#000000', name: 'Nero' }, // TO LOCALIZE THIS
                { color: '#0a78ff', name: 'Blu' },
                { color: '#ffad14', name: 'Arancione' },
                { color: '#0cab0f', name: 'Verde' },
                { color: '#ff0800', name: 'Rosso' },
                { color: '#ff94e4', name: 'Rosa' },
            ],
            savedChanges: true,
            initialPath: [],
            onceExecute: true,
            selectedNodeId: '',
            documentId: '',
            docTitle: '',
        }
        this.canvas = React.createRef();
    }

    componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                this.setState({ userUuid: userData.uid })
            }
        })
        console.log(this.props.history.location.state.drawing)

        this.setState({
            word: this.props.history.location.state.word.trim().toLowerCase(),
            documentId: this.props.history.location.state.documentId,
            docTitle: this.props.history.location.state.docTitle,
            drawingUrl: this.props.history.location.state.drawing,
        }, () => {
            console.log(this.state.drawingUrl);
            if (this.props.history.location.state.drawing) {
                canvasSvgTools.getDocumentSVGStringFromUrl(this.props.history.location.state.drawing)
                    .then(data => {
                        console.log('[Sketch] Svg string:', data); // FOR DEBUGGING
                        data = data.replace('canvasPenStrokes', 'canvas-pen-paths')
                        let path = canvasSvgTools.getCanvasPathsFromSvg(data);
                        console.log(path)
                        this.canvas.current.loadPaths(path)
                        console.log(path)
                    })
                    .catch(err => {
                        //console.log('[Sketch] Error getting SVG from URL:', err);
                    });
            }
            if (this.props.history.location.state.selectedNodeId) {
                this.setState({ selectedNodeId: this.props.history.location.state.selectedNodeId })
            }
            this.update = window.setInterval(() => {
                if (!this.state.savedChanges)  //Avoids to push to DB if already saved
                    this.canvas.current.exportPaths()
                        .then((canvasPath: any) => {
                            if (canvasPath.length >= 1) {
                                this.canvas.current.exportSvg()
                                    .then((data: any) => {
                                        var svgBlob = new Blob([data], { type: "image/svg+xml" });
                                        this.uploadImage(svgBlob)
                                        this.setState({ savedChanges: true }, () => {
                                            //console.log(this.state.savedChanges)
                                        })
                                    })
                            }
                            else {
                                this.setState({ savedChanges: false }, () => {
                                    //console.log(this.state.savedChanges)
                                })
                            }
                            //Non modificato
                            return
                        })
            }, 2500);
        });

    }

    componentWillUnmount() {
        //console.log('SALVATO?: ' + this.state.savedChanges)
        window.clearInterval(this.update);
    }

    uploadImage(data: any) {
        if (this.state.drawingUrl) {
            console.log("DENTRO FUNZIONE:", this.state.drawingUrl)
            this.canvas.current.exportPaths()
                .then((canvasPath: any) => {
                    if (canvasPath.length >= 1 && this.state.drawingUrl) {
                        var svgBlob = new Blob([data], { type: "image/svg+xml" });
                        sketchServices.updatePicture(this.state.drawingUrl, svgBlob)
                            .then(() => {
                                this.setState({ savedChanges: true }, () => {
                                    //console.log(this.state.savedChanges)
                                })
                                //console.log('[Sketch] picture updated by using PATCH');
                            })
                            .catch(err2 => {
                                //console.log('[Sketch] error updating picture. Error:', err2);
                            })
                    }
                    else {
                        alert(i18n.language === 'it' ? 'Non è possibile salvare un disegno vuoto' :
                            i18n.language === 'fr' ? "Il n'est pas possible de sauver un tableau vide" :
                                i18n.language === 'es' ? 'No es posible guardar un dibujo vacío' :
                                    'You cannot save an empty drawing')
                    }
                })
        }
        else {
            //NON HO UN'IMMAGINE ASSEGNATA
            this.canvas.current.exportPaths()
                .then((canvasPath: any) => {
                    if (canvasPath.length >= 1) {
                        //HO MODIFICATO
                        var svgBlob = new Blob([data], { type: "image/svg+xml" });
                        // var file = new File([svgBlob], `${this.state.word}.svg`);
                        uploadBytes(storageRef(storage, `d/${this.state.userUuid}/${this.state.word}`), svgBlob)
                            .then(async data => {
                                console.log(data);

                                await setDoc(doc(firestore, `d/${this.state.userUuid}/d/${makeId(16)}`), {
                                    w: this.state.word,
                                    p: data.metadata.fullPath,
                                })
                                    .then(response => {
                                        console.log("[MindMap] setting doc response:", response);
                                    })
                                    .catch(e => {
                                        console.error("[MindMap] error setting doc:", e);
                                    })
                                this.setState({
                                    drawingUrl: data.metadata.fullPath,
                                });
                            })

                        /* sketchServices.uploadDrawing(svgBlob)
                            .then(drawing => {
                                sketchServices.matchPictureWithWord(drawing.uuid, this.state.word!.word.uuid)
                                    .then((matching) => {
                                        this.setState({
                                            drawing: matching,
                                        });
                                        //console.log('[Sketch] success creating matching between word', this.state.word!.word.uuid, 'and picture:', drawing.uuid);
                                    })
                                    .catch(err5 => {
                                        //console.log('[Sketch] error creating matching between word:', this.state.word!.word.uuid, 'and picture:', drawing.uuid, 'Error:', err5);
                                    });
                                //console.log('Immagine aggiunta')
                                this.setState({ savedChanges: true }, () => {
                                    //console.log(this.state.savedChanges)
                                })
                            })
                            .catch(err4 => {
                                //console.log('[Sketch] error uploading drawing. Error:', err4);
                            }) */
                    }
                    else {
                        //console.log('Il disegno è vuoto')
                    }
                })
        }
    }

    render() {
        return (
            <IonPage>
                <Prompt
                    when={this.state.savedChanges === false}
                    message={i18n.language === 'it' ? 'Ci sono delle modifiche non salvate, vuoi davvero uscire?' :
                        i18n.language === 'fr' ? 'Il y a des changements non sauvés, voulez-vous vraiment en sortir?' :
                            i18n.language === 'es' ? 'Hay cambios sin salvación, ¿realmente quieres salir?' :
                                'There are some unsaved changes, do you really want to exit?'}
                />
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={async () => {
                                await getDoc(doc(firestore, `m/${this.state.documentId}/`))
                                    .then(documentSnapshot => {
                                        console.log("GETTING DOC MAP", this.state.documentId);
                                        if (documentSnapshot.exists()) {
                                            this.props.history.push(`/maps/${this.state.documentId}`)

                                            // this.props.history.push({
                                            //     pathname: '/maps',
                                            //     state: { documentData: documentSnapshot.data(), docId: this.state.documentId, docTitle: this.state.docTitle }
                                            // })
                                        }
                                        else {
                                            this.props.history.goBack()
                                        }
                                    })
                            }}>
                                <IonIcon icon={arrowBackOutline} />
                            </IonButton>
                            {/* <IonBackButton defaultHref='/' /> */}
                        </IonButtons>
                        <IonTitle>Sketch</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <div className='sketchWordTitleDiv' >
                        <IonLabel className='sketchWordTitle' >
                            <Trans>
                                {this.state.word ? "Disegna un'immagine per la parola " : "Disegna un' immagine"}
                            </Trans>
                            "
                            {this.state.word ? this.state.word.toUpperCase() + "\"" : ""}

                        </IonLabel>
                    </div>

                    <div className='canvasContainer'>
                        <ReactSketchCanvas
                            onChange={(path) => { //fired when component mounts
                                //code to set initial state saved
                                if (this.state.onceExecute === true) {
                                    this.setState({ onceExecute: false, initialPath: path, savedChanges: true })
                                }
                                else {
                                    this.setState({ savedChanges: false }, () => {
                                        //console.log(this.state.savedChanges)
                                    })
                                }
                            }}
                            className='sketchCanvas'
                            height='400px'
                            width='400px'
                            ref={this.canvas}
                            strokeWidth={this.state.strokeWidth}
                            strokeColor={this.state.selectedColor}
                            eraserWidth={this.state.strokeWidth}
                        />

                        <IonRadioGroup value={this.state.selectedColor} onIonChange={e => this.setState({ selectedColor: e.detail.value })}>
                            <IonList
                                className='sketchColorPaletteList'
                            >
                                {this.state.colors.map((color: Color, i) => {
                                    return (
                                        <IonItem
                                            key={i}
                                            lines='none'
                                            className='sketchRadioLabel'
                                            color={color.color}
                                            style={
                                                this.state.selectedColor === color.color ?
                                                    { backgroundColor: color.color } :
                                                    { opacity: .4, backgroundColor: color.color }
                                            }
                                        >
                                            <IonLabel>
                                                <IonRadio aria-label='colours' className='sketchRadioButton' value={color.color} />
                                            </IonLabel>
                                        </IonItem>
                                    )
                                })}
                            </IonList>
                        </IonRadioGroup>

                    </div>

                    {this.state.savedChanges &&
                        <p style={{ textAlign: 'center' }}>Disegno salvato</p>
                    }
                    {!this.state.savedChanges &&
                        <p style={{ textAlign: 'center' }}>Salvataggio...</p>
                    }
                    <IonButtons className='sketchButtons'>
                        {/* <IonButton
                            disabled={this.state.savedChanges}
                            onClick={() => {
                                this.canvas.current.exportSvg()
                                    .then((data: any) => {
                                        this.uploadImage(data);
                                        this.setState({ savedChanges: true }, () => {
                                            //console.log(this.state.savedChanges)
                                        })
                                    })
                                    .catch((e: any) => {
                                        //console.log(e);
                                    });
                            }}
                        >
                            <IonIcon icon={cloudUploadOutline} slot='start' size='large' />

                            <Trans>Salva</Trans>
                        </IonButton> */}

                        <IonButton
                            onClick={() => {
                                this.canvas.current.undo()
                            }}
                        >
                            <Trans>Indietro</Trans>
                            <IonIcon icon={arrowUndoOutline} slot='start' size='large' />
                        </IonButton>
                        <IonButton
                            onClick={() => {
                                this.canvas.current.redo()
                            }}
                        >
                            <Trans>Avanti</Trans>
                            <IonIcon icon={arrowRedoOutline} slot='start' size='large' />
                        </IonButton>
                        <IonButton
                            onClick={() => {
                                this.canvas.current.clearCanvas()
                            }}
                        >
                            <Trans>Pulisci</Trans>
                            <IonIcon icon={clearCanvasIcon} slot='start' size='large' />
                        </IonButton>
                        {/* <IonButton
                            onClick={() => {
                                this.setState({ eraseMode: !this.state.eraseMode }, () => {
                                    this.canvas.current.eraseMode(this.state.eraseMode);
                                })
                            }}
                        >
                            <IonIcon icon={this.state.eraseMode ? radioButtonOffOutline : radioButtonOnOutline} slot='start' size='large' />
                            <Trans>{this.state.eraseMode ? 'Cancella' : 'Disegna'}</Trans>
                        </IonButton> */}
                    </IonButtons>
                    <IonButtons className='sketchButtons'>
                        <IonRange
                            className='sketchRange'
                            style={isMobile ? { maxWidth: '90%' } : { maxWidth: '650px' }}
                            onIonChange={(e) => this.setState({ strokeWidth: (e.detail.value as number) })}
                            min={2}
                            max={isMobile ? 40 : 70}
                            value={this.state.strokeWidth}>
                            <IonIcon icon={brushOutline} style={{ fontSize: '23px' }} slot='start' />
                            <IonIcon icon={brushOutline} slot='end' size='large' />
                        </IonRange>
                    </IonButtons>
                </IonContent>
            </IonPage>
        );
    }
};
import { backendApi } from "../../../common/apiService"
import { StripeCheckoutSession, SubscriptionPlan, UserSubscription } from "./reducers"
export const purchaseServices = {
    createOrRetrieveStripeCustomerId,
    createStripeCheckoutSession,
    openStripeCustomerPortal,

    getPurhcasablePlans,

    getUserSubscriptions,
}

function createOrRetrieveStripeCustomerId(token: string) {
    return new Promise<string>((resolve, reject) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        /* NO DATA NEEDED SINCE THE BACKEND GATHERS THE DATA FROM BEARER TOKEN */
        backendApi.post(`/stripe/customer`, {}, config)
            .then(response => {
                if (response.status === 200) {
                    console.log('[create stripeCustomerId] response:', response)
                    const stripeCustomerId = response.data.stripe_customer_id
                    resolve(stripeCustomerId);
                }
            })
            .catch(err => {
                console.error('[create stripeCustomerId] error:', err)
                reject(err)
            })
    })
}

function createStripeCheckoutSession(stripeCustomerId: string, priceId: string, token: string) {
    return new Promise<StripeCheckoutSession>((resolve, reject) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        const data = {
            "stripe_customer_id": stripeCustomerId,
            "price_id": priceId,
        }

        backendApi.post(`/stripe/checkout-session`, data, config)
            .then(response => {
                if (response.status === 200) {
                    console.log('[create stripe checkout session] response:', response)
                    const stripeCheckoutSession = response.data
                    resolve(stripeCheckoutSession)
                }
            })
            .catch(err => {
                console.error('[create stripe checkout session] error:', err)
                reject(err)
            })
    })
}
function openStripeCustomerPortal(stripeCustomerId: string, token: string) {
    return new Promise<string>((resolve, reject) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        const data = {
            "stripe_customer_id": stripeCustomerId,
        }

        backendApi.post(`/stripe/customer-portal`, data, config)
            .then(response => {
                if (response.status === 200) {
                    console.log('[open stripe customer portal] response:', response)
                    const portalUrl = response.data.url
                    resolve(portalUrl)
                }
            })
            .catch(err => {
                console.error('[open stripe customer portal] error:', err)
                reject(err)
            })
    })
}
function getPurhcasablePlans(token: string) {
    return new Promise<SubscriptionPlan[]>((resolve, reject) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        backendApi.get(`/public/plans`, config)
            .then(response => {
                if (response.status === 200) {
                    console.log('[get purhcasable plans] response:', response)
                    const plans = response.data
                    resolve(plans)
                }
            })
            .catch(err => {
                console.error('[get purhcasable plans] error:', err)
                reject(err)
            })
    })
}

function getUserSubscriptions(userId: string, token: string) {
    return new Promise<UserSubscription[]>((resolve, reject) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        backendApi.get(`/users/${userId}/subscriptions`, config)
            .then(response => {
                if (response.status === 200) {
                    console.log('[get user subscriptions] response:', response)
                    const subscriptions = response.data
                    resolve(subscriptions)
                }
            })
            .catch(err => {
                console.error('[get user subscriptions] error:', err)
                reject(err)
            })
    })
}
import { userConstants } from './constants';
import { Dispatch } from 'redux';
import { Organization, Organization_New, UserLicense } from './reducers';

export const userActions = {
    setOrganizations,
    setBannerLicenseRedeemed
};

export const actionCreator = {
    setOrganizations: (org_data: Organization_New[] | null) => { return { type: userConstants.SET_ORGANIZATION, org_data } },
    setBannerLicenseRedeemed: (isRedeemed: boolean) => { return { type: userConstants.SET_BANNER_LICENSE_REDEEMED, isRedeemed } },
};


function setOrganizations(org_data: Organization_New[] | null) {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.setOrganizations(org_data));
    };
}
function setBannerLicenseRedeemed(isRedeemed: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.setBannerLicenseRedeemed(isRedeemed));
    };
}
export const ViewerTranslationsCA = {
    "PUNTO DI PARTENZA": "PUNT DE PARTIDA",
    "Clicca sulla parola da dove vuoi partire": "Fes clic a la paraula des d'on vols començar",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'B'": "Selecciona el text o una paraula i torna a fer clic a la 'B'",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'I'": "Selecciona el text o una paraula i torna a fer clic a la 'I'",
    "Seleziona il testo o una parola e poi clicca 'Conferma colore'": "Selecciona el text o una paraula i fes clic a 'Confirma color'",
    "Seleziona il testo o una parola e attendi il caricameto del dizionario e immagini": "Selecciona el text o una paraula i espera que es carregui el diccionari i les imatges",
    "Conferma colore": "Confirma color"
}

export const ViewerTranslationsCZ = {
    "PUNTO DI PARTENZA": "VÝCHOZÍ BOD",
    "Clicca sulla parola da dove vuoi partire": "Klikněte na slovo, odkud chcete začít",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'B'": "Vyberte text nebo slovo a pak znovu klikněte na 'B'",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'I'": "Vyberte text nebo slovo a pak znovu klikněte na 'I'",
    "Seleziona il testo o una parola e poi clicca 'Conferma colore'": "Vyberte text nebo slovo a pak klikněte na 'Potvrdit barvu'",
    "Seleziona il testo o una parola e attendi il caricameto del dizionario e immagini": "Vyberte text nebo slovo a počkejte na načtení slovníku a obrázků",
    "Conferma colore": "Potvrdit barvu"
}

export const ViewerTranslationsDE = {
    "PUNTO DI PARTENZA": "STARTPUNKT",
    "Clicca sulla parola da dove vuoi partire": "Klicken Sie auf das Wort, von dem Sie starten möchten",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'B'": "Wählen Sie Text oder ein Wort aus und klicken Sie erneut auf 'B'",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'I'": "Wählen Sie Text oder ein Wort aus und klicken Sie erneut auf 'I'",
    "Seleziona il testo o una parola e poi clicca 'Conferma colore'": "Wählen Sie Text oder ein Wort aus und klicken Sie auf 'Farbe bestätigen'",
    "Seleziona il testo o una parola e attendi il caricameto del dizionario e immagini": "Wählen Sie Text oder ein Wort aus und warten Sie, bis das Wörterbuch und die Bilder geladen sind",
    "Conferma colore": "Farbe bestätigen"
}

export const ViewerTranslationsEN = {
    "PUNTO DI PARTENZA": "STARTING POINT",
    "Clicca sulla parola da dove vuoi partire": "Click on the word from which you want to start",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'B'": "Select text or a word and then click the 'B' again",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'I'": "Select text or a word and then click the 'I' again",
    "Seleziona il testo o una parola e poi clicca 'Conferma colore'": "Select text or a word and then click the 'Confirm color'",
    "Seleziona il testo o una parola e attendi il caricameto del dizionario e immagini": "Select text or a word and await the loading of the dictionary and images",
    "Conferma colore": "Confirm color"
}

export const ViewerTranslationsES = {
    "PUNTO DI PARTENZA": "PUNTO DE PARTIDA",
    "Clicca sulla parola da dove vuoi partire": "Haz clic en la palabra desde donde quieres empezar",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'B'": "Selecciona el texto o una palabra y luego haz clic nuevamente en 'B'",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'I'": "Selecciona el texto o una palabra y luego haz clic nuevamente en 'I'",
    "Seleziona il testo o una parola e poi clicca 'Conferma colore'": "Selecciona el texto o una palabra y luego haz clic en 'Confirmar color'",
    "Seleziona il testo o una parola e attendi il caricameto del dizionario e immagini": "Selecciona el texto o una palabra y espera a que se cargue el diccionario y las imágenes",
    "Conferma colore": "Confirmar color"
}

export const ViewerTranslationsFR = {
    "PUNTO DI PARTENZA": "POINT DE DÉPART",
    "Clicca sulla parola da dove vuoi partire": "Cliquez sur le mot à partir duquel vous souhaitez commencer",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'B'": "Sélectionnez le texte ou un mot puis cliquez à nouveau sur 'B'",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'I'": "Sélectionnez le texte ou un mot puis cliquez à nouveau sur 'I'",
    "Seleziona il testo o una parola e poi clicca 'Conferma colore'": "Sélectionnez le texte ou un mot puis cliquez sur 'Confirmer la couleur'",
    "Seleziona il testo o una parola e attendi il caricameto del dizionario e immagini": "Sélectionnez le texte ou un mot et attendez le chargement du dictionnaire et des images",
    "Conferma colore": "Confirmer la couleur"
}

export const ViewerTranslationsIT = {
    "PUNTO DI PARTENZA": "PUNTO DI PARTENZA",
    "Clicca sulla parola da dove vuoi partire": "Clicca sulla parola da dove vuoi partire",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'B'": "Seleziona il testo o una parola e poi clicca di nuovo la 'B'",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'I'": "Seleziona il testo o una parola e poi clicca di nuovo la 'I'",
    "Seleziona il testo o una parola e poi clicca 'Conferma colore'": "Seleziona il testo o una parola e poi clicca 'Conferma colore'",
    "Seleziona il testo o una parola e attendi il caricameto del dizionario e immagini": "Seleziona il testo o una parola e attendi il caricameto del dizionario e immagini",
    "Conferma colore": "Conferma colore"
}

export const ViewerTranslationsNL = {
    "PUNTO DI PARTENZA": "STARTPUNT",
    "Clicca sulla parola da dove vuoi partire": "Klik op het woord waar je wilt beginnen",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'B'": "Selecteer tekst of een woord en klik dan opnieuw op 'B'",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'I'": "Selecteer tekst of een woord en klik dan opnieuw op 'I'",
    "Seleziona il testo o una parola e poi clicca 'Conferma colore'": "Selecteer tekst of een woord en klik dan op 'Bevestig kleur'",
    "Seleziona il testo o una parola e attendi il caricameto del dizionario e immagini": "Selecteer tekst of een woord en wacht tot het woordenboek en de afbeeldingen zijn geladen",
    "Conferma colore": "Bevestig kleur"
}

export const ViewerTranslationsPT = {
    "PUNTO DI PARTENZA": "PONTO DE PARTIDA",
    "Clicca sulla parola da dove vuoi partire": "Clique na palavra de onde você quer começar",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'B'": "Selecione o texto ou uma palavra e depois clique novamente no 'B'",
    "Seleziona il testo o una parola e poi clicca di nuovo la 'I'": "Selecione o texto ou uma palavra e depois clique novamente no 'I'",
    "Seleziona il testo o una parola e poi clicca 'Conferma colore'": "Selecione o texto ou uma palavra e depois clique em 'Confirmar cor'",
    "Seleziona il testo o una parola e attendi il caricameto del dizionario e immagini": "Selecione o texto ou uma palavra e aguarde o carregamento do dicionário e imagens",
    "Conferma colore": "Confirmar cor"
}
export const SketchTranslationsEN = {
    "Disegna un'immagine per la parola ": "Draw an image for word '",
    "Disegna un' immagine": "Draw an image",
    "Salva": "Save",
    "Indietro": "Undo",
    "Avanti": "Redo",
    "Pulisci": "Clear",
    "Disegna": "Draw",
    "Cancella": "Erase",
    "Disegno salvato": "Drawing saved",
    "Salvataggio...": "Saving...",
}
export const SketchTranslationsIT = {
    "Disegna un'immagine per la parola ": "Disegna un'immagine per la parola ",
    "Disegna un' immagine": "Disegna un' immagine",
    "Salva": "Salva",
    "Indietro": "Indietro",
    "Avanti": "Avanti",
    "Pulisci": "Pulisci",
    "Disegna": "Disegna",
    "Cancella": "Cancella",
    "Disegno salvato": "Disegno salvato",
    "Salvataggio...": "Salvataggio...",
}
export const SketchTranslationsES = {
    "Disegna un'immagine per la parola ": "Haz un dibujo para la palabra ",
    "Salva": "Guardar",
    "Indietro": "Deshacer",
    "Avanti": "Rehacer",
    "Pulisci": "Limpia",
    "Disegna": "Dibujar",
    "Cancella": "Borrar",
    "Disegno salvato": "Dibujo guardado",
    "Salvataggio...": "Guardando...",
}
export const SketchTranslationsFR = {
    "Disegna un'immagine per la parola ": "Dessine une image pour le mot ",
    "Salva": "Sauvegarder",
    "Indietro": "Annuler",
    "Avanti": "Refaire",
    "Pulisci": "Effacer",
    "Disegna": "Dessiner",
    "Cancella": "Effacer",
    "Disegno salvato": "Dessin enregistré",
    "Salvataggio...": "Sauvetage...",
}
export const SketchTranslationsDE = {
    "Disegna un'immagine per la parola ": "Zeichnen Sie ein Bild für das Wort ",
    "Disegna un' immagine": "Zeichnen sie ein bild",
    "Salva": "Speichern Sie",
    "Indietro": "Zurück",
    "Avanti": "Weiter",
    "Pulisci": "Sauber",
    "Disegna": "Zeichnen sie",
    "Cancella": "Löschen",
    "Disegno salvato": "Zeichnung gespeichert",
    "Salvataggio...": "Speichern...",
}
export const SketchTranslationsCA = {
    "Disegna un'immagine per la parola ": "Dibuixa una imatge per a la paraula ",
    "Disegna un' immagine": "Dibuixa una imatge",
    "Salva": "Desa",
    "Indietro": "Enrere",
    "Avanti": "Endavant",
    "Pulisci": "Neteja",
    "Disegna": "Dibuixa",
    "Cancella": "Esborra",
    "Disegno salvato": "Dibuix desat",
    "Salvataggio...": "Desant...",
}
export const SketchTranslationsPT = {
    "Disegna un'immagine per la parola ": "Desenhe uma imagem para a palavra ",
    "Disegna un' immagine": "Desenhe uma imagem",
    "Salva": "Salvar",
    "Indietro": "Voltar",
    "Avanti": "Avançar",
    "Pulisci": "Limpar",
    "Disegna": "Desenhar",
    "Cancella": "Apagar",
    "Disegno salvato": "Desenho salvo",
    "Salvataggio...": "Salvando...",
}
export const SketchTranslationsCZ = {
    "Disegna un'immagine per la parola ": "Nakreslete obrázek k slovu ",
    "Disegna un' immagine": "Nakreslete obrázek",
    "Salva": "Uložit",
    "Indietro": "Zpět",
    "Avanti": "Vpřed",
    "Pulisci": "Vyčistit",
    "Disegna": "Kreslit",
    "Cancella": "Smazat",
    "Disegno salvato": "Kresba uložena",
    "Salvataggio...": "Ukládání...",
}
export const SketchTranslationsNL = {
    "Disegna un'immagine per la parola ": "Teken een afbeelding voor het woord ",
    "Disegna un' immagine": "Teken een afbeelding",
    "Salva": "Opslaan",
    "Indietro": "Terug",
    "Avanti": "Verder",
    "Pulisci": "Wissen",
    "Disegna": "Tekenen",
    "Cancella": "Verwijderen",
    "Disegno salvato": "Tekening opgeslagen",
    "Salvataggio...": "Opslaan..."
}
import {
    IonButton,
    IonGrid,
    IonIcon,
    IonImg,
    IonPage,
    IonSelect,
    IonSelectOption
} from '@ionic/react';
import React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import loginAnimation from '../../../assets/animations/login.json';
import reasyLogoBook from '../../../assets/icons/reasy_logo_book.svg';
import reasyScritta from '../../../assets/icons/reasy_scritta.svg';
import './AuthenticationSignupOrSigninChoice.css';
import './CredentialsLogin.css';
import { chevronDownOutline, globeOutline } from 'ionicons/icons';
import { isMobile } from 'react-device-detect';
import i18n from '../../../i18n';

type Props = {
    history: any,

    isAuthenticated?: boolean,
};

type State = {
    email: string | null,
    password: string | null,

    showLegalTerms: boolean,
    legalTermsAccepted: boolean,

    showResetPasswordButton: boolean,

    showErrorAlert: boolean,
    errorAlertHeaderMessage: string,
    errorAlertMessage: string,
    showSupportButton: boolean,

    language: string,
    languageChanged: boolean

};

class AuthenticationSignupOrSigninChoice extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: null,
            password: null,

            showLegalTerms: false,
            legalTermsAccepted: false,

            showResetPasswordButton: false,

            showErrorAlert: false,
            errorAlertHeaderMessage: "",
            errorAlertMessage: "",
            showSupportButton: false,

            language: "",
            languageChanged: false

        }
    }
    componentDidMount(): void {
        if (!this.state.languageChanged) {
            switch (i18n.language) {
                case "en-GB":
                    this.setState({ language: "en" })
                    break;
                case "en-US":
                    this.setState({ language: "en" })
                    break;
                case "it-IT":
                    this.setState({ language: "it" })
                    break;
                case "es-ES":
                    this.setState({ language: "es" })
                    break;
                default:
                    this.setState({ language: i18n.language })
            }
        }
    }
    render() {
        const animationOptions = {
            loop: true,
            autoplay: true,
            animationData: loginAnimation,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };
        return (
            <IonPage className='authGetStartedPage'>
                <div className='authGetStartedContainer'>

                    {/* <IonHeader>
                        <IonToolbar>
                            <IonTitle><Trans>Iniziamo</Trans></IonTitle>
                            <IonButtons slot='start'>
                                <IonBackButton defaultHref='/' />
                            </IonButtons>
                            {
                                this.state.showSupportButton &&
                                <IonButtons slot='end'>
                                    <IonButton
                                        fill='clear'
                                        shape='round'
                                        onClick={() => {
                                            window.open('https://wa.me/393661126799');
                                        }}
                                    >
                                        {/* <IonIcon
                                            size='large'
                                            icon={supportPersonIcon}
                                        /> 
                                    </IonButton>
                                </IonButtons>
                            }
                        </IonToolbar>
                    </IonHeader> */}
                    <IonGrid className="authGetStartedMainGrid">
                        <div /* hidden={isMobile}  */ className='languageContainer-Auth cardBg'>
                            <div className='settingsDocumentViewingItemDiv' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                                <IonIcon className="lnIcon" slot='start' icon={globeOutline} />
                                <IonSelect
                                    style={{ width: 'fit-content', paddingLeft: '25px', }}
                                    className='appLanguageSelect'
                                    id="setLanguage"
                                    value={this.state.language}
                                    toggleIcon={chevronDownOutline}
                                    //value={this.state.language == "" ? i18n.language : this.state.language}
                                    interface={isMobile ? 'action-sheet' : 'popover'}
                                    onIonChange={e => {
                                        if (e.detail.value != i18n.language) {
                                            i18n.changeLanguage(e.detail.value);
                                            this.setState({ languageChanged: true, language: e.detail.value }, () => {
                                            })
                                        }
                                    }}
                                >
                                    <IonSelectOption value="it">Italiano</IonSelectOption>
                                    <IonSelectOption value="en">English</IonSelectOption>
                                    <IonSelectOption value="fr">Français</IonSelectOption>
                                    <IonSelectOption value="es">Español</IonSelectOption>
                                    <IonSelectOption value="de">Deutsch</IonSelectOption>
                                    <IonSelectOption value="ca">Català</IonSelectOption>
                                </IonSelect>
                            </div>
                        </div>
                        <div className="authGetStartedTextDiv">
                            <p className="authGetStartedWelcomeText">
                                <Trans>Ti diamo il benvenuto su</Trans> {/* TO BE LOCALIZED */} {/* TO EDIT */}
                            </p>
                        </div>

                        <div className="authChoiceIllustrationDiv">
                            <IonImg src={reasyLogoBook} />
                            <IonIcon className='authLoginLogoTxt' src={reasyScritta} />
                        </div>
                        <div className="authChoiceButtonsDiv">
                            <IonButton
                                className='authChoiceSignUpButton'
                                expand='block'
                                onClick={() => {
                                    this.props.history.push(`/authentication/signup-old`);
                                }}
                            >
                                <Trans>Registrati</Trans> {/* TO BE LOCALIZED */}
                            </IonButton>
                            <IonButton
                                className="authChoiceSignInButton"
                                color='medium'
                                shape='round'
                                fill='outline'
                                onClick={() => {
                                    this.props.history.push(`/authentication/login-old`);
                                }}
                            >
                                <Trans>Accedi</Trans> {/* TO BE LOCALIZED */}
                            </IonButton>
                            {/*   <IonButton
                            fill="clear"
                            onClick={() => {
                                this.props.history.push(`/authentication/get-started`);
                            }}
                        >
                            <Trans>Indietro</Trans>
                        </IonButton> */}
                        </div>
                    </IonGrid>

                    {
                        this.props.isAuthenticated &&
                        <Redirect to="/" />
                    }
                </div>
            </IonPage>


        );
    }

}

const mapStateToProps = (state: any) => {
    return {
        isAuthenticated: state.auth.userData ? true : false,
    }
}


const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationSignupOrSigninChoice);

import { getDownloadURL, ref as storageRef } from "firebase/storage";
import { storage } from "../firebaseConfig";

export function fromGSUrltoUrl(gsUrl: string) {
    return new Promise<string>((resolve, reject) => {
        getDownloadURL(storageRef(storage, gsUrl))
            .then(url => {
                resolve(url);
            })
            .catch(err => {
                console.log("[fromGSUrltoUrl] service error:", err);
                reject(err);
            })
    });
}
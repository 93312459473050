import { useCallback } from 'react';
import React from 'react';
import { useStore, getBezierPath } from 'reactflow';

import { getEdgeParams } from './utils.js';

function FloatingEdge({ id, source, target, markerEnd, style, label }) {
    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

    if (!sourceNode || !targetNode) {
        return null;
    }

    const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);
    const [edgePath] = getBezierPath({
        sourceX: sx - (sourceNode.width / 2),
        sourceY: sy - (sourceNode.height / 2),
        sourcePosition: sourcePos,
        targetPosition: targetPos,
        targetX: tx - (targetNode.width / 2),
        targetY: ty - (targetNode.height / 2),

    });

    // const centerPosition = getBezierPath({ sourceX: sx, sourceY: sy, targetX: tx, targetY: ty, sourcePosition: sourcePos, targetPosition: targetPos });
    const midX = (sx + tx) / 2;
    const midY = (sy + ty) / 2;
    return (
        <g className="react-flow__connection">
            <path id={id} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} style={style} />
            {/* <text className='react-flow__edge-text' x={midX} y={midY} >
                {label}
            </text> */}
        </g>
    );
}

export default FloatingEdge;

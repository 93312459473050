import { SettingsTranslationsCA } from '../../modules/settings/SettingsTranslations'
import { ProfileTranslationsCA } from '../../modules/user/ProfileTranslations';
import { MapsTranslationsCA } from '../../modules/maps/MapsTranslations';
import { MenuTranslationsCA } from '../../modules/menu/MenuTranslations'
import { SketchTranslationsCA } from '../../modules/sketch/SketchTranslations';
import { FileTranslationsCA } from '../../modules/files/FileTranslations';
import { PopoverTranslationsCA } from '../../modules/popover/PopoverTranslations';
import { AuthTranslationsCA } from '../../modules/authentication/AuthTranslations';
import { SessionsTranslationsCA } from '../../modules/sessions/SessionsTranslations';
import { FlashcardsTranslationsCA } from '../../modules/flashcards/FlashcardsTranslations';
import { ViewerTranslationsCA } from '../../modules/summaries/ViewerTranslations';

// import { OnboardingTranslationsCA } from '../../modules/onboarding/OnboardingTranslations';

const mergedObject = {
    ...SettingsTranslationsCA,
    ...ProfileTranslationsCA,
    ...MapsTranslationsCA,
    ...FileTranslationsCA,
    ...MenuTranslationsCA,
    ...PopoverTranslationsCA,
    ...SketchTranslationsCA,
    ...AuthTranslationsCA,
    ...SessionsTranslationsCA,
    ...FlashcardsTranslationsCA,
    ...ViewerTranslationsCA,

};

export const ca = {
    mergedObject,
}
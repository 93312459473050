import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { createRef } from 'react';
import { fromGSUrltoUrl } from '../../common/firebaseUtils';
import { auth, firestore } from '../../firebaseConfig';
import "./SelectionPopover.css";
import { imagesSearch } from './common/imagesSearch';
import WordActions from './components/WordActions';

type Props = {
    history: any,
    customImageUrl: string | null,
    onDidDismiss?: () => void,
    uploadImageClicked: (text: string | null) => void
    deleteCustomImage: (url: string, text: string) => void
    removeDeletedDrawing: (url: string, text: string) => void
}

type State = {
    navigationStack: string[],
    imageSearchResponse: any[],

    selectedText: string | null,
    selection: Selection | null,

    isLoadingImages: boolean,
    scrollOffsetY: number,

    drawingUrl: string | null,
    customImageUrl: string | null,
};


class SelectionPopover extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            navigationStack: [],
            imageSearchResponse: [],

            selectedText: null,
            selection: null,
            isLoadingImages: false,

            scrollOffsetY: 0,

            drawingUrl: null,
            customImageUrl: null,
        }
    }
    wordActionsRef = createRef();

    clearImageState() {
        this.setState({ imageSearchResponse: [], drawingUrl: null, customImageUrl: null })
    }
    removeDeletedImage(url: string) {
        this.setState({ customImageUrl: null })
    }
    clearDrawingState(url: string) {
        let oldImages = this.state.imageSearchResponse
        oldImages = oldImages.splice(oldImages.indexOf(url), 1)
        this.setState({ imageSearchResponse: oldImages, drawingUrl: null })
    }

    changeSelectedText(text: string | null) {
        this.setState({ selectedText: text });
        if (!text) {
            this.setState({ navigationStack: [] });
        }
    }

    showPopover(show: boolean) {
        console.log(show, this.state.selectedText, this.state.selection)

        if (this.state.selectedText && this.state.selection && show) {
            this.calculateAndSetPopoverPosition();
        }
        else if (!show) {
            this.dismissPopover()
        }
    }
    eraseDictionaryMeanings() {
        (this.wordActionsRef.current as any).eraseDictionaryMeanings();

    }

    setSelection(selection: Selection | null, text: string | null, show: boolean) {
        this.setState({ selection: selection });
        this.setState({ selectedText: text }, () => {
            this.searchImages()
            if (this.state.selectedText && this.state.selection && show) {
                this.calculateAndSetPopoverPosition();
            }
            else if (!show) {
                this.dismissPopover()
            }
        });
    }

    setScrollOffsetY(offsetY: number) {
        this.setState({ scrollOffsetY: offsetY });
    }

    navigateTo(path: string) {
        const navStackCopy = this.state.navigationStack.slice();
        navStackCopy.push(path);
        this.setState({
            navigationStack: navStackCopy
        });
    }

    navigateBack() {
        const navStackCopy = this.state.navigationStack.slice();
        navStackCopy.pop();
        this.setState({
            navigationStack: navStackCopy,
        });
    }

    clearNavigationStack() {
        this.setState({
            navigationStack: [],
        });
    }

    componentDidUpdate() {
        // console.log("[SelectionPopover] component did update"); // TO REMOVE
        /* let _this = this; */
        /* window.addEventListener("orientationchange", function () {
            _this.dismissPopover()
        }, false); */


        if (this.state.selectedText && this.state.selection) {
            (this.wordActionsRef.current as any)?.setState({ wordPicture: null, customImageUrl: null });
        } else {
            try {
                if (this.state.imageSearchResponse && this.state.imageSearchResponse.length > 0) {
                    this.setState({ imageSearchResponse: [], customImageUrl: '', drawingUrl: '' })
                }
                this.dismissPopover();
            } catch (err) { 
                console.error('Error in componentDidUpdate:', err) 
            }
        }

    }

    addCustomImage(image: string) {
        this.setState({ /* imageSearchResponse: images, */ customImageUrl: image })
    }

    async searchImages() {
        console.log("SELECTION", this.state.selectedText)

        if (this.state.selectedText) {
            this.setState({
                isLoadingImages: true,
            });
            let imageSearchResponse = this.state.imageSearchResponse
            // if (this.props.customImageUrl) {
            //     imageSearchResponse.push(this.props.customImageUrl)
            //     this.setState({ imageSearchResponse: imageSearchResponse })
            // }
            const q = query(collection(firestore, `d/${auth.currentUser?.uid}/d`), where("w", "==", this.state.selectedText.trim().toLowerCase()));
            const customImageQuery = query(collection(firestore, `i/${auth.currentUser?.uid}/i`), where("w", "==", this.state.selectedText.trim().toLowerCase()));
            await imagesSearch(this.state.selectedText)
                .then(images => {
                    this.setState({ imageSearchResponse: [...images] }, async () => {
                        const querySnapshot = await getDocs(q);
                        const customImageQuerySnapshot = await getDocs(customImageQuery);
                        console.log("[WordActions] query snapshot: ", querySnapshot);
                        if (!querySnapshot.empty) {
                            querySnapshot.forEach(async (doc) => {
                                let drawing = await fromGSUrltoUrl(doc.data().p)
                                let oldImages = this.state.imageSearchResponse;
                                oldImages.unshift(drawing); //place drawing in first position
                                this.setState({ imageSearchResponse: oldImages, drawingUrl: drawing })
                            })

                            this.setState({ isLoadingImages: false });
                        }
                        else {
                            this.setState({ isLoadingImages: false });
                        }
                        if (!customImageQuerySnapshot.empty) {
                            customImageQuerySnapshot.forEach(async (doc: any) => {
                                await fromGSUrltoUrl(doc.data().i)
                                    .then((url: string) => {
                                        console.log("URL: ", url);
                                        // let oldImages = this.state.imageSearchResponse;
                                        // oldImages.unshift(url); //place custom image in first position
                                        this.setState({ /* imageSearchResponse: oldImages, */ customImageUrl: url })
                                    });
                            });
                        }
                    });
                })
                .catch(err => {
                    console.error("[WordActions] error calling imageSearch:", err);
                });
        }
    }

    calculateAndSetPopoverPosition() {
        const selectionPopoverOuterDiv = document.getElementById('selectionPopoverOuterDiv') as HTMLDivElement;
        const selectionPopoverInnerDiv = document.getElementById('selectionPopoverInnerDiv') as HTMLDivElement;
        const textGrid = document.getElementById('textgrid');
        
        if (!this.state.selection || !selectionPopoverInnerDiv || !selectionPopoverOuterDiv) {
            return;
        }

        const selection = this.state.selection;
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        
        // Get viewport dimensions
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const MARGIN = 20; // Margin from viewport edges

        // Reset any previous styles
        selectionPopoverOuterDiv.style.removeProperty('left');
        selectionPopoverOuterDiv.style.removeProperty('right');
        selectionPopoverInnerDiv.style.removeProperty('transform');
        selectionPopoverInnerDiv.style.removeProperty('left');
        selectionPopoverInnerDiv.style.removeProperty('right');
        
        // Set max height based on viewport
        const maxHeight = viewportHeight - (MARGIN * 2);
        selectionPopoverOuterDiv.style.maxHeight = `${maxHeight}px`;
        selectionPopoverOuterDiv.style.overflow = 'auto';
        
        if (viewportWidth > 1000) {
            // Desktop layout - position on the right side
            selectionPopoverOuterDiv.style.position = 'fixed';
            selectionPopoverOuterDiv.style.right = '0';
            selectionPopoverOuterDiv.style.width = '450px';
            selectionPopoverOuterDiv.style.height = 'auto';
            
            selectionPopoverInnerDiv.style.position = 'relative';
            selectionPopoverInnerDiv.style.right = '0';
            selectionPopoverInnerDiv.style.width = '100%';
            selectionPopoverInnerDiv.style.margin = '0';
            selectionPopoverInnerDiv.style.transform = 'scale(0.9)';
            selectionPopoverInnerDiv.style.transformOrigin = 'right top';
            
            if (textGrid) {
                textGrid.classList.add("shiftText");
            }
        } else if (viewportWidth > 400) {
            // Tablet layout
            selectionPopoverOuterDiv.style.position = 'fixed';
            selectionPopoverOuterDiv.style.left = '50%';
            selectionPopoverOuterDiv.style.width = '400px';
            selectionPopoverOuterDiv.style.transform = 'translateX(-50%)';
            selectionPopoverOuterDiv.style.height = 'auto';
            
            selectionPopoverInnerDiv.style.position = 'relative';
            selectionPopoverInnerDiv.style.width = '100%';
            selectionPopoverInnerDiv.style.margin = '0';
            selectionPopoverInnerDiv.style.transform = 'scale(0.8)';
            selectionPopoverInnerDiv.style.transformOrigin = 'center top';
        } else {
            // Mobile layout
            selectionPopoverOuterDiv.style.position = 'fixed';
            selectionPopoverOuterDiv.style.left = '0';
            selectionPopoverOuterDiv.style.right = '0';
            selectionPopoverOuterDiv.style.width = '100%';
            selectionPopoverOuterDiv.style.height = 'auto';
            
            selectionPopoverInnerDiv.style.position = 'relative';
            selectionPopoverInnerDiv.style.width = '100%';
            selectionPopoverInnerDiv.style.margin = '0';
            selectionPopoverInnerDiv.style.transform = 'none';
        }

        // Calculate optimal vertical position
        let topPosition = Math.max(MARGIN, rect.top);
        
        // Check if popover would overflow bottom of viewport
        const contentHeight = selectionPopoverInnerDiv.getBoundingClientRect().height;
        if (topPosition + contentHeight > viewportHeight - MARGIN) {
            // If content is taller than available space, position at top of viewport
            topPosition = MARGIN;
        }
        
        selectionPopoverOuterDiv.style.top = `${topPosition}px`;
        
        // Ensure visibility
        selectionPopoverOuterDiv.style.opacity = '1';
        selectionPopoverOuterDiv.style.visibility = 'visible';
    }

    dismissPopover() {
        // console.log("[SelectionPopover] dismiss");
        if (!window.location.pathname.includes('/summary') && !window.location.pathname.includes('/file')) {
            return;
        }
        else {
            const selectionPopoverOuterDiv: HTMLDivElement | null = document.getElementById('selectionPopoverOuterDiv') as HTMLDivElement;
            const textGrid: HTMLElement | null = document.getElementById('textgrid');
            
            if (selectionPopoverOuterDiv) {
                selectionPopoverOuterDiv.style.opacity = '0';
                if (textGrid) {
                    textGrid.classList.remove("shiftText");
                }
                selectionPopoverOuterDiv.style.visibility = 'hidden';
            }
        }

    }
    render() {
        return (

            <div
                id='selectionPopoverOuterDiv'
                className='selectionPopoverOuterDiv'
            >
                <div
                    id='selectionPopoverInnerDiv'
                    className='selectionPopoverInnerDiv'
                >
                    <div style={{ display: "flex" }}>
                        <div style={{ backgroundColor: "var(--accents-color)", width: "50px", borderRadius: "10px 0px 0px 10px" }}>
                        </div>


                        {
                            this.state.navigationStack.length === 0 &&
                            <div style={{ width: '400px', background: 'var(--bg-content-color' }} className='dictonaryPopover'>
                                <WordActions
                                    ref={this.wordActionsRef as React.LegacyRef<WordActions>}
                                    history={this.props.history}
                                    selectedText={this.state.selectedText}
                                    textForLn={this.state.selectedText}
                                    isLoadingImages={this.state.isLoadingImages}
                                    images={this.state.imageSearchResponse}
                                    drawingUrl={this.state.drawingUrl}
                                    customImageUrl={this.state.customImageUrl}
                                    removeDeletedDrawing={(url, text) => {
                                        this.props.removeDeletedDrawing(url, text)
                                    }}
                                    showDrawImage={true}
                                    uploadImageClicked={(text) => {
                                        this.props.uploadImageClicked(text)
                                    }}
                                    deleteCustomImage={(url, text) => {
                                        this.props.deleteCustomImage(url, text)
                                    }}
                                    onHighlightClicked={() => {
                                        this.navigateTo("lettersSelector");
                                    }}
                                    onDictionaryClicked={() => {
                                        this.navigateTo("wordDictionary");
                                    }}
                                />
                            </div>
                        }

                    </div>
                </div>
            </div>

        );
    }
}

export default SelectionPopover;                   

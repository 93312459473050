import { SettingsTranslationsIT } from '../../modules/settings/SettingsTranslations'
import { ProfileTranslationsIT } from '../../modules/user/ProfileTranslations'
import { MenuTranslationsIT } from '../../modules/menu/MenuTranslations'
import { SketchTranslationsIT } from '../../modules/sketch/SketchTranslations';
import { FileTranslationsIT } from '../../modules/files/FileTranslations';
import { PopoverTranslationsIT } from '../../modules/popover/PopoverTranslations';
import { AuthTranslationsIT } from '../../modules/authentication/AuthTranslations';
import { OnboardingTranslationsIT } from '../../modules/onboarding/OnboardingTranslations';
import { MapsTranslationsIT } from '../../modules/maps/MapsTranslations';
import { SessionsTranslationsIT } from '../../modules/sessions/SessionsTranslations';
import { FlashcardsTranslationsIT } from '../../modules/flashcards/FlashcardsTranslations';
import { AudiobookTranslationsIT } from '../../modules/audiobooks/AudiobookTranslations';
import { ViewerTranslationsIT} from '../../modules/summaries/ViewerTranslations';

const mergedObject = {
  ...SettingsTranslationsIT,
  ...ProfileTranslationsIT,
  ...MenuTranslationsIT,
  ...SketchTranslationsIT,
  ...FileTranslationsIT,
  ...PopoverTranslationsIT,
  ...AuthTranslationsIT,
  ...OnboardingTranslationsIT,
  ...MapsTranslationsIT,
  ...SessionsTranslationsIT,
  ...FlashcardsTranslationsIT,
  ...AudiobookTranslationsIT,
  ...ViewerTranslationsIT,
};

export const it = {
  mergedObject,
}
import React from 'react';
import { IonApp, isPlatform, setupIonicReact } from '@ionic/react';
import { Provider as StoreProvider, connect } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Router from './Router';
import { persistor, store } from './common/store';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import CookieAlert from './modules/legal/cookieManager/components/CookieAlert';
import pushNotificationHandler from './common/pushNotificationHandler';
import magicLinkHandler from './common/magicLinkHandler';
import MessageOverlay from './modules/core/components/MessageOverlay';
import Authentication, { auth } from './firebaseConfig';
import Paywall from './common/Paywall/pages/Paywall';
// import storeHandler from './common/storeHandler';
import storeHandler from './common/purchasehandler';

setupIonicReact();
class App extends React.Component {

  componentDidMount() {
    pushNotificationHandler();
    magicLinkHandler();
    // storeHandler();
    storeHandler();
  }

  render() {
    return (
      <StoreProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <IonApp>
            <Authentication />
            {/* {
              !(isPlatform('capacitor') || isPlatform('cordova')) &&
              <CookieAlert />
            } */}
            <Router />
            <MessageOverlay />
            <Paywall history={undefined} />
          </IonApp>
        </PersistGate>
      </StoreProvider>
    );
  };
}
export default App;
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createMigrate from 'redux-persist/es/createMigrate';
import authenticationReducer from '../modules/authentication/store/reducers';
import tutorials from '../modules/tutorials/store/reducers';
import applicationReducer from '../modules/core/store/reducers';
import Paywall from './Paywall/store/reducers';
import Maps from '../modules/maps/store/reducers';
import Purchases from '../modules/purchase/store/reducers';
import User from '../modules/user/store/reducers';
import thumbnailsReducer from '../modules/files/store/reducers';


const rootReducer = combineReducers({
  app: applicationReducer,
  auth: authenticationReducer,
  paywall: Paywall,
  tutorials,
  maps: Maps,
  user: User,
  purchases: Purchases,
  thumbnails:thumbnailsReducer
});

const migrations = {
  0: (state: any) => ({
    ...state,
    paywall: {
      showPaywall: false,
    },
  }),
  1: (state:any) => ({
    ...state,
    purchases: {
      isLoadingPayment: false,
      isProcessingPayment: false,
      paymentSuccess: false,
      paymentCanceled: false,
      paymentError: false,
    },
  }),
  2: (state:any) => {
    const { register, ...restState } = state;
    return restState;
  },
};

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['paywall', 'purchases'],
  version: 2,
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };

import { paywallConstants } from "./constants";

export interface PaywallStore  {
    showPaywall: boolean,
}

const initialState: PaywallStore = {
    showPaywall: false,
}

const Paywall = (state = initialState, action: any) => {

    switch (action.type) {
        case paywallConstants.SHOW_PAYWALL:
            return {
                ...state,
                showPaywall: true,
            }

        case paywallConstants.HIDE_PAYWALL:
            return {
                ...state,
                showPaywall: false,
            }

        default:
            return state
    }
}

export default Paywall;
import React, { CSSProperties, useEffect, useState } from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import './colorPicker.css'
import moveArrows from '../../../assets/icons/arrowsMove.svg'
import { Trans } from 'react-i18next';

interface ColorSliderProps {
  color: string;
  value: number;
  onChange: (value: number) => void;
}

const ColorSlider: React.FC<ColorSliderProps> = ({ color, value, onChange }) => {
  const sliderClass = `w-full ${color.toLowerCase()}-slider`;

  return (
    <div className="flex items-center mb-2">
      <label className="w-10 mr-2">{color}</label>
      <input
        type="range"
        min="0"
        max="255"
        value={value}
        onChange={(e) => onChange(parseInt(e.target.value))}
        className={sliderClass}
      />
      <span className="w-10 ml-2 text-right">{value}</span>
    </div>
  );
};

interface ColorPickerProps {
  onColorConfirm: (color: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ onColorConfirm }) => {
  const [red, setRed] = useState(0);
  const [green, setGreen] = useState(0);
  const [blue, setBlue] = useState(0);
  const [position, setPosition] = useState({ top: 50, left: 50 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  const hexColor = `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;

  type PointerEvent = MouseEvent | TouchEvent;

  const handleConfirm = () => {
    onColorConfirm(hexColor);
  };
  
  const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    setIsDragging(true);
    
    // Handle both mouse and touch events
    const point = 'touches' in e 
      ? e.touches[0]
      : e;
  
    setDragStart({
      x: point.clientX - position.left,
      y: point.clientY - position.top,
    });
  };
  
  const handleDragMove = (e: PointerEvent) => {
    if (isDragging) {
      // Handle both mouse and touch events
      const point = 'touches' in e 
        ? e.touches[0]
        : e;
  
      setPosition({
        top: point.clientY - dragStart.y,
        left: point.clientX - dragStart.x,
      });
    }
  };
  
  const handleDragEnd = () => {
    setIsDragging(false);
  };
  
  useEffect(() => {
    if (isDragging) {
      // Add both mouse and touch event listeners
      window.addEventListener('mousemove', handleDragMove);
      window.addEventListener('mouseup', handleDragEnd);
      window.addEventListener('touchmove', handleDragMove);
      window.addEventListener('touchend', handleDragEnd);
    } else {
      // Remove both mouse and touch event listeners
      window.removeEventListener('mousemove', handleDragMove);
      window.removeEventListener('mouseup', handleDragEnd);
      window.removeEventListener('touchmove', handleDragMove);
      window.removeEventListener('touchend', handleDragEnd);
    }
  
    return () => {
      // Cleanup both mouse and touch event listeners
      window.removeEventListener('mousemove', handleDragMove);
      window.removeEventListener('mouseup', handleDragEnd);
      window.removeEventListener('touchmove', handleDragMove);
      window.removeEventListener('touchend', handleDragEnd);
    };
  }, [isDragging]);


  
  return (
    <div 
      className="popup"
      style={{ top: position.top, left: position.left }}
    >
      <IonIcon
        icon={moveArrows}
        className="move-btn"
        onMouseDown={handleDragStart}
        onTouchStart={handleDragStart}
      />
      <ColorSlider color="red" value={red} onChange={setRed} />
      <ColorSlider color="green" value={green} onChange={setGreen} />
      <ColorSlider color="blue" value={blue} onChange={setBlue} />
      <div className="mt-4 flex items-center">
        <div
          className="w-16 h-16 rounded-md"
          style={{ backgroundColor: hexColor }}
        >
                  <span className="font-mono">{hexColor}</span>

        </div>
      </div>
      <IonButton 
        onClick={handleConfirm}
        className="mt-4 w-full summariesColorPicker"
      >
        <Trans>Conferma colore</Trans>
      </IonButton>
    </div>
  );
};

export default ColorPicker;
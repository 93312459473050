import { SET_THUMBNAILS, CLEAR_THUMBNAILS } from './constants';


export interface Sentence {
    sentence_words: SentenceWord[],
    uuid: string,
    index: number,
    text: string,
    language: string,
}

export interface Page {
    uuid: string,
    number: number,
    sentences: Sentence[],
}

export interface Document {
    uuid: string,
    created_at: number,
    last_access: number,
    name: string,
    /* file: string, */
    sentences?: Sentence[],
    preview: string | null,
    owner: string,
    tipo?: string,
    isbn_code?: string

    /* processing_status: string,
    processing_percentage: number, */
}

export interface DocumentElement {
    uuid: string,
    documentId: string,
    index: number,
    text: string,
    keyWord?: string,
}
export interface SummaryDocumentElement extends DocumentElement {
    keyWord: string,
}

export interface SentenceWord {
    uuid: string,
    word: Word,
    pos: string,
    can_have_an_image: boolean,
    can_have_a_synonym: boolean,
}

export interface Word {
    uuid: string,
    text: string,
    lemma: string,
}

export interface FileViewerSentence {
    sentence_words: SentenceWord[],
    uuid: string,
    index: number,
    text: FileViewerChar[],
    language: string,
}

export interface FileViewerChar {
    char: string,
    color?: string,
}

export interface SearchWordImagesResult {
    uuid: string,
    word: {
        uuid: string,
    },
    image_search: {
        uuid: string,
        text: string,
        results: SearchImage[],
    }
}

export interface SearchImage {
    uuid: string,
    image: string,
}

// src/redux/reducers/thumbnailReducer.js
export interface Thumbnail {
    id: string | null;
    url: string | null;
}
export interface ThumbnailStore {
    thumbnails: Thumbnail[]
}


const initialState: ThumbnailStore = {
    thumbnails: []

};

const thumbnailReducer = (state = initialState, action: any): ThumbnailStore => {
    switch (action.type) {
        case SET_THUMBNAILS:
            return {
                ...state,
                thumbnails: action.payload
            };
        case CLEAR_THUMBNAILS:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

export default thumbnailReducer;


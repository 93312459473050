import { getPlatformId } from "@capacitor/core/types/util";
import { getPlatforms, isPlatform } from "@ionic/react";
import { purchaseServices } from "../store/services";

export function fromResourceTypeDBToCorrectName(resource_type: string) {
    /* RETURNS THE RESOURCE TYPE AS A "USER-FRIENDLY" STRING*/
    /* map', 'summary', 'flashcards', 'keywords', 'tts */
    switch (resource_type) {
        case "map":
            return "Mappe"
            break;
        case "summary":
            return "Riassunti"
            break;
        case "flashcards":
            return "Flashcard"
            break;
        case "keywords":
            return "Parole chiave"
            break;
        case "tts":
            return "Audiolibri"
            break;
        default:
            break;
    }
}
/* possible incoming types 'trial' | 'stripe' | 'ios' | 'android' | 'free' | 'transfer' | 'pos' | 'carta_docente' | 'cash' */
export function isPlatformSameAsPurchase(sub_platform: string) {
    let purchasePlatform: any = sub_platform === 'stripe' ? 'desktop' : sub_platform;
    return isPlatform(purchasePlatform)
}
const reasyBasePlanIdDemo = "00909a10-a68a-4863-bcd3-42d270a97690"
const reasyProPlanIdDemo = "2a74097d-e8ba-4229-8149-a6d2861c77fc"

const reasyBasePlanIdProd = "7eb8f728-eb0a-4bd2-b6ed-da1b5397396c"
const reasyProPlanIdProd = "2792fcc2-960f-497a-910c-d12981e21ad4"

const priceBaseMonthly = '6,99';
const priceBaseYearly = '69,99';
const priceProMonthly = '9,99';
const priceProYearly = '99,99';

export const Demo_PlanPriceIds = [
    /* Base monthly */
    {
        plan_id: reasyBasePlanIdDemo,
        stripe_price_id: "price_1PdEAUKn10j5f5DBS8kTSmMY",
        apple_price_id: "ai.tech4all.reasy.base.monthly",
        android_price_id: "ai.tech4all.reasy.base",
        plan_name: "Reasy Base Mensile",
        price: priceBaseMonthly,
        period: 'monthly'
    },
    /* Base yearly */
    {
        plan_id: reasyBasePlanIdDemo,
        stripe_price_id: "price_1PuYDXKn10j5f5DBii49KNc5",
        apple_price_id: "ai.tech4all.reasy.base.yearly",
        android_price_id: "ai.tech4all.reasy.base",
        plan_name: "Reasy Base Annuale",
        price: priceBaseYearly,
        period: 'yearly'
    },
    /* Pro monthly */
    {
        plan_id: reasyProPlanIdDemo,
        stripe_price_id: "price_1LkZEDKn10j5f5DBW8SMbuy7",
        apple_price_id: "ai.tech4all.reasy.pro.monthly",
        android_price_id: "ai.tech4all.reasy.pro",
        plan_name: "Reasy Pro Mensile",
        price: priceProMonthly,
        period: 'monthly'
    },
    /* Pro yearly */
    {
        plan_id: reasyProPlanIdDemo,
        stripe_price_id: "price_1Ps94mKn10j5f5DBdtcznosO",
        apple_price_id: "ai.tech4all.reasy.pro.yearly",
        android_price_id: "ai.tech4all.reasy.pro",
        plan_name: "Reasy Pro Annuale",
        price: priceProYearly,
        period: 'yearly'
    },
]
export const Prod_PlanPriceIds = [
    /* Base monthly */
    {
        plan_id: reasyBasePlanIdProd,
        stripe_price_id: "price_1PdDdDKn10j5f5DBspcWIsTK",
        apple_price_id: "ai.tech4all.reasy.base.monthly",
        android_price_id: "ai.tech4all.reasy.base",
        plan_name: "Reasy Base Mensile",
        price: priceBaseMonthly,
        period: 'monthly'
    },
    /* Base yearly */
    {
        plan_id: reasyBasePlanIdProd,
        stripe_price_id: "price_1Ps5OzKn10j5f5DB41HmNbsX",
        apple_price_id: "ai.tech4all.reasy.base.yearly",
        android_price_id: "ai.tech4all.reasy.base",
        plan_name: "Reasy Base Annuale",
        price: priceBaseYearly,
        period: 'yearly'
    },
    /* Pro monthly */
    {
        plan_id: reasyProPlanIdProd,
        stripe_price_id: "price_1ODoNQKn10j5f5DBJ6GIyKb7",
        apple_price_id: "ai.tech4all.reasy.pro.monthly",
        android_price_id: "ai.tech4all.reasy.pro",
        plan_name: "Reasy Pro Mensile",
        price: priceProMonthly,
        period: 'monthly'
    },
    /* Pro yearly */
    {
        plan_id: reasyProPlanIdProd,
        stripe_price_id: "price_1Ps998Kn10j5f5DBK5xhGyLA",
        apple_price_id: "ai.tech4all.reasy.pro.yearly",
        android_price_id: "ai.tech4all.reasy.pro",
        plan_name: "Reasy Pro Annuale",
        price: priceProYearly,
        period: 'yearly'
    },
]

export function getStripePriceId(plan_id: string, period: 'monthly' | 'yearly') {
    return Prod_PlanPriceIds.filter(plan => plan.plan_id == plan_id && plan.period === period)[0].stripe_price_id
    // return Demo_PlanPriceIds.filter(plan => plan.plan_id == plan_id && plan.period === period)[0].stripe_price_id
}
export function getApplePriceId(plan_id: string, period: 'monthly' | 'yearly') {
    return Prod_PlanPriceIds.filter(plan => plan.plan_id == plan_id && plan.period === period)[0].apple_price_id
    // return Demo_PlanPriceIds.filter(plan => plan.plan_id == plan_id && plan.period === period)[0].apple_price_id
}
export function getAndroidPriceId(plan_id: string, period: 'monthly' | 'yearly') {
    return Prod_PlanPriceIds.filter(plan => plan.plan_id == plan_id && plan.period === period)[0].android_price_id
    // return Demo_PlanPriceIds.filter(plan => plan.plan_id == plan_id && plan.period === period)[0].android_price_id
}



export function getPlanName(plan_id: string) {
    return Prod_PlanPriceIds.filter(plan => plan.plan_id == plan_id)[0].plan_name
    // return Demo_PlanPriceIds.filter(plan => plan.plan_id == plan_id)[0].plan_name
}

export function getPlanPrice(plan_id: string, period: 'monthly' | 'yearly') {
    return Prod_PlanPriceIds.filter(plan => plan.plan_id == plan_id && plan.period === period)[0].price
    // return Demo_PlanPriceIds.filter(plan => plan.plan_id == plan_id && plan.period === period)[0].price
}


export async function getPlanLimits(plan_id: string, token: string) {
    let plans = await purchaseServices.getPurhcasablePlans(token);
    let currentPlan = plans.filter(plan => plan.id === plan_id)[0]
    return currentPlan.limits
}
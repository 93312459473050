import { IonAlert, IonButton, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonPage, IonToolbar, isPlatform } from "@ionic/react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { helpCircleOutline } from "ionicons/icons";
import React from "react";
import { isMobile } from "react-device-detect";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { auth } from "../../../firebaseConfig";
import i18n from "../../../i18n";
import "./AuthenticationGetStarted.css";
import "./CredentialsLogin.css";
import eyeOpened from "../../../assets/icons/eye-alt-svgrepo-com.svg";
import eyeClosed from "../../../assets/icons/eye-slash-alt-svgrepo-com.svg";
import { sendEvent } from "../../../common/amplitudeUtils";
import { appVersion } from "../../../appVersion";
import reasyLogoBook from '../../../assets/icons/reasy_logo_book.svg';
import reasyScritta from '../../../assets/icons/reasy_scritta.svg';

type Props = {
	history: any;

	isAuthenticated?: boolean;
};

type State = {
	email: string | null;
	password: string | null;

	showLegalTerms: boolean;

	showResetPasswordButton: boolean;

	showErrorAlert: boolean;
	errorAlertHeaderMessage: string;
	errorAlertMessage: string;
	showSupportButton: boolean;
	showPassword: boolean;
};

class CredentialsLogin extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			email: null,
			password: null,

			showLegalTerms: false,

			showResetPasswordButton: false,

			showErrorAlert: false,
			errorAlertHeaderMessage: "",
			errorAlertMessage: "",
			showSupportButton: false,
			showPassword: false,
		};
	}
	togglePasswordVisibility = () => {
		this.setState((prevState) => ({
			showPassword: !prevState.showPassword,
		}));
	};
	loginLogic = () => {
		let email = (document.getElementById("email") as HTMLInputElement)?.value;
		let pass = (document.getElementById("password") as HTMLInputElement)?.value;
		if (pass === "" || !pass) {
			this.setState({
				showErrorAlert: true,
				errorAlertHeaderMessage:
					i18n.language === "it"
						? "Password non valida"
						: i18n.language === "en"
							? "Invalid Password"
							: i18n.language === "fr"
								? "Mot de passe invalide"
								: i18n.language === "de"
									? "Ungültiges Passwort"
									: i18n.language === "es"
										? "Contraseña no válida"
										: "Contrasenya no vàlida", // Catalan
				errorAlertMessage:
					i18n.language === "it"
						? "Assicurati di aver inserito una password"
						: i18n.language === "en"
							? "Make sure you have entered a password"
							: i18n.language === "fr"
								? "Assurez-vous d'avoir saisi un mot de passe"
								: i18n.language === "de"
									? "Stellen Sie sicher, dass Sie ein Passwort eingegeben haben"
									: i18n.language === "es"
										? "Asegúrate de haber ingresado una contraseña"
										: "Assegura't d'haver introduït una contrasenya", // Catalan
			});
			return;
		}
		if (email === "" || !email) {
			this.setState({
				showErrorAlert: true,
				errorAlertHeaderMessage:
					i18n.language === "it"
						? "Email non valida"
						: i18n.language === "en"
							? "Invalid Email"
							: i18n.language === "fr"
								? "E-mail invalide"
								: i18n.language === "de"
									? "Ungültige E-Mail"
									: i18n.language === "es"
										? "Correo electrónico no válido"
										: "Correu electrònic no vàlid", // Catalan
				errorAlertMessage:
					i18n.language === "it"
						? "Assicurati di aver inserito una email"
						: i18n.language === "en"
							? "Make sure you have entered an email"
							: i18n.language === "fr"
								? "Assurez-vous d'avoir saisi un e-mail"
								: i18n.language === "de"
									? "Stellen Sie sicher, dass Sie eine E-Mail eingegeben haben"
									: i18n.language === "es"
										? "Asegúrate de haber ingresado un correo electrónico"
										: "Assegura't d'haver introduït un correu electrònic", // Catalan
			});
			return;
		}

		signInWithEmailAndPassword(auth, email, pass)
			.then((uc) => {
				sendEvent({
					"user_id": uc.user.uid,
					"event_type": "Log-in",
					"event_properties": {
						"method": "email",
					},
					"language": i18n.language,
					"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
					"app_version": appVersion,
					"time": Date.now(),
				});
				// console.log("[GetStarted] user logged. Uid:", uc.user.uid); // TO REMOVE
			})
			.catch((err) => {
				//console.log("[GetStarted] error signing-in:", err.code); // TO REMOVE
				if (err.code === "auth/user-not-found") {
					console.warn("Registrazione dell'utente");
					this.setState({
						showErrorAlert: true,
						errorAlertHeaderMessage:
							i18n.language === "it"
								? "Email non registrata"
								: i18n.language === "en"
									? "Email not registered"
									: i18n.language === "fr"
										? "Email non enregistré"
										: i18n.language === "de"
											? "E-Mail nicht registriert"
											: i18n.language === "es"
												? "Correo electrónico no registrado"
												: "Correu electrònic no registrat",
						errorAlertMessage:
							i18n.language === "it"
								? "Contatta il supporto cliccando l'icona in alto a destra."
								: i18n.language === "en"
									? "Contact support by clicking the icon at the top right."
									: i18n.language === "fr"
										? "Contactez le support en cliquant sur l'icône en haut à droite."
										: i18n.language === "de"
											? "Kontaktieren Sie den Support, indem Sie auf das Symbol oben rechts klicken."
											: "Contacta con el soporte haciendo clic en el icono de arriba a la derecha." /* TO BE LOCALIZED */,
						showSupportButton: true,
					});
					this.setState({ showLegalTerms: true });
				} else if (err.code === "auth/user-disabled") {
					this.setState({
						showErrorAlert: true,
						errorAlertHeaderMessage:
							i18n.language === "it"
								? "Utente disabilitato"
								: i18n.language === "en"
									? "User disabled"
									: i18n.language === "fr"
										? "Utilisateur désactivé"
										: i18n.language === "de"
											? "Benutzer deaktiviert"
											: "Usuario deshabilitado",
						errorAlertMessage:
							i18n.language === "it"
								? "Contatta il supporto cliccando l'icona in alto a destra."
								: i18n.language === "en"
									? "Contact support by clicking the icon at the top right."
									: i18n.language === "fr"
										? "Contactez le support en cliquant sur l'icône en haut à droite."
										: i18n.language === "de"
											? "Kontaktieren Sie den Support, indem Sie auf das Symbol oben rechts klicken."
											: "Contacta con el soporte haciendo clic en el icono de arriba a la derecha.",
						showSupportButton: true,
					});
				} else if (err.code === "auth/wrong-password") {
					this.setState({
						showErrorAlert: true,
						errorAlertHeaderMessage:
							i18n.language === "it"
								? "Password non valida"
								: i18n.language === "en"
									? "Invalid Password"
									: i18n.language === "fr"
										? "Mot de passe invalide"
										: i18n.language === "de"
											? "Ungültiges Passwort"
											: i18n.language === "es"
												? "Contraseña no válida"
												: "Contrasenya no vàlida", // Catalan
						errorAlertMessage:
							i18n.language === "it"
								? "La password è sbagliata. Riprova o resetta la password."
								: i18n.language === "en"
									? "The password is incorrect. Please try again or reset your password."
									: i18n.language === "fr"
										? "Le mot de passe est incorrect. Veuillez réessayer ou réinitialiser votre mot de passe."
										: i18n.language === "de"
											? "Das Passwort ist falsch. Bitte versuchen Sie es erneut oder setzen Sie Ihr Passwort zurück."
											: i18n.language === "es"
												? "La contraseña es incorrecta. Inténtalo de nuevo o restablece tu contraseña."
												: "La contrasenya és incorrecta. Torna-ho a intentar o restableix la teva contrasenya.", // Catalan
						showResetPasswordButton: true,
					});
				} else if (err.code === "auth/invalid-email") {
					this.setState({
						showErrorAlert: true,
						errorAlertHeaderMessage:
							i18n.language === "it"
								? "Email non valida"
								: i18n.language === "en"
									? "Invalid Email"
									: i18n.language === "fr"
										? "E-mail invalide"
										: i18n.language === "de"
											? "Ungültige E-Mail"
											: i18n.language === "es"
												? "Correo electrónico no válido"
												: "Correu electrònic no vàlid", // Catalan
						errorAlertMessage:
							i18n.language === "it"
								? "Prova a controllare il formato"
								: i18n.language === "en"
									? "Please check the format."
									: i18n.language === "fr"
										? "Veuillez vérifier le format."
										: i18n.language === "de"
											? "Bitte überprüfen Sie das Format."
											: i18n.language === "es"
												? "Comprueba el formato."
												: "Comprova el format.", // Catalan
					});
				} else if (err.code === "auth/internal-error") {
					this.setState({
						showErrorAlert: true,
						errorAlertHeaderMessage:
							i18n.language === "it"
								? "Errore interno"
								: i18n.language === "en"
									? "Internal Error"
									: i18n.language === "fr"
										? "Erreur interne"
										: i18n.language === "de"
											? "Interner Fehler"
											: i18n.language === "es"
												? "Error interno"
												: "Error intern", // Catalan
						errorAlertMessage:
							i18n.language === "it"
								? "Contatta il supporto cliccando l'icona in alto a destra."
								: i18n.language === "en"
									? "Contact support by clicking the icon at the top right."
									: i18n.language === "fr"
										? "Contactez le support en cliquant sur l'icône en haut à droite."
										: i18n.language === "de"
											? "Kontaktieren Sie den Support, indem Sie auf das Symbol oben rechts klicken."
											: i18n.language === "es"
												? "Contacta con el soporte haciendo clic en el icono de arriba a la derecha."
												: "Contacta amb el suport fent clic a la icona de dalt a la dreta.", // Catalan
					});
				} else if (err.code === "auth/too-many-requests") {
					this.setState({
						showErrorAlert: true,
						errorAlertHeaderMessage:
							i18n.language === "it"
								? "Troppe richieste"
								: i18n.language === "en"
									? "Too Many Requests"
									: i18n.language === "fr"
										? "Trop de demandes"
										: i18n.language === "de"
											? "Zu viele Anfragen"
											: i18n.language === "es"
												? "Demasiadas solicitudes"
												: "Massa sol·licituds", // Catalan
						errorAlertMessage:
							i18n.language === "it"
								? "Contattare il supporto o ritentare tra un po'."
								: i18n.language === "en"
									? "Please contact support or try again later."
									: i18n.language === "fr"
										? "Veuillez contacter le support ou réessayer plus tard."
										: i18n.language === "de"
											? "Bitte kontaktieren Sie den Support oder versuchen Sie es später erneut."
											: i18n.language === "es"
												? "Contacta al soporte o inténtalo de nuevo más tarde."
												: "Contacta amb el suport o torna-ho a intentar més tard.", // Catalan
						showResetPasswordButton: true,
					});
				}
			});
	};

	render() {
		return (
			<IonPage className="authGetStartedPage">
				<div className='authGetStartedContainer'>

					{/* {isMobile &&
                    <IonHeader className='ion-no-border authSupportHeader'>
                        <IonToolbar className='authSupportHeaderToolbar'>
                            <IonIcon icon={helpCircleOutline} slot='end' className='authSupportIcon' onClick={() => this.props.history.push('/support')} />
                        </IonToolbar>
                    </IonHeader>
                } */}
					<IonIcon icon={helpCircleOutline} slot="end" className="authSupportIcon" onClick={() => this.props.history.push("/support")} />

					<IonGrid className="authGetStartedMainGrid">
						{/*                 <IonHeader>
                                            <IonToolbar>
                                                <IonTitle>
                                                    <Trans>Accedi</Trans>
                                                </IonTitle>
                                                <IonButtons slot='start'>
                                                    <IonBackButton defaultHref='/authentication/email-password-authentication' />
                                                </IonButtons>
                                                {
                                                    this.state.showSupportButton &&
                                                    <IonButtons slot='end'>
                                                        <IonButton
                                                            fill='clear'
                                                            shape='round'
                                                            onClick={() => {
                                                                window.open('https://wa.me/393661126799');
                                                            }}
                                                        >
                                                        </IonButton>
                                                    </IonButtons>
                                                }
                                            </IonToolbar>
                                        </IonHeader> */}
						<div className="authGetStartedTextDiv">
							<p className="authGetStartedWelcomeText">
								<Trans>Ti diamo il benvenuto su</Trans>
							</p>
							<div className="authGetStartedIllustrationDiv">
								<IonImg className="loginLogo" src={reasyLogoBook} />
								<IonIcon className='authLoginLogoTxt' src={reasyScritta} />
							</div>
						</div>



						<form className="credentialsSignupInputDiv">
							<div className="passEyeContainer">
								<IonInput
									id="email"
									className="credentialsSignupInputField"
									placeholder={
										i18n.language === "es"
											? "Tu email"
											: i18n.language === "en"
												? "Your email address"
												: i18n.language === "fr"
													? "Votre e-mail"
													: i18n.language === "de"
														? "Deine E-Mail"
														: i18n.language === "ca" ?
															"El teu correu electrònic"
															: "La tua email"
									}
									type="email"
									autocomplete="email"
									inputMode="email"
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											this.loginLogic();
										}
									}}
								/>
							</div>
							<div className="passEyeContainer">
								<IonInput
									id="password"
									className="credentialsSignupInputField"
									placeholder={
										i18n.language === "es"
											? "Contraseña"
											: i18n.language === "en"
												? "Password"
												: i18n.language === "fr"
													? "Mot de passe"
													: i18n.language === "de"
														? "Passwort"
														: i18n.language === "ca" ?
															"Contrasenya"
															: "Password"
									}
									type={this.state.showPassword ? "text" : "password"}
									inputMode="text"
									autocomplete="current-password"
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											this.loginLogic();
										}
									}}
								/>
								<IonIcon className="passwordShowBtn" icon={this.state.showPassword ? eyeOpened : eyeClosed} onClick={this.togglePasswordVisibility} />
							</div>
						</form>

						<div className="credentialsSignupButtonsDiv">
							<IonButton
								className="magicLinkRequestSendEmailButton"
								onClick={() => {
									this.loginLogic();
								}}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										this.loginLogic();
									}
								}}
							>
								<Trans>Accedi</Trans>
							</IonButton>

							<IonButton
								className="getStartedResetPasswordButton"
								fill="clear"
								onClick={() => {
									this.props.history.push(`/authentication/reset-password`);
								}}
							>
								<Trans>Ho dimenticato la password</Trans>
							</IonButton>
							<IonButton
								fill="clear"
								onClick={() => {
									this.props.history.push(`/authentication/email-password-authentication`);
								}}
							>
								<Trans>Indietro</Trans>
							</IonButton>
						</div>

						{this.props.isAuthenticated && <Redirect to="/" />}

						<IonAlert
							isOpen={this.state.showErrorAlert}
							cssClass="getStartedErrorAlert"
							onDidDismiss={() => {
								this.setState({
									showErrorAlert: false,
									errorAlertHeaderMessage: "",
									errorAlertMessage: "",
								});
							}}
							keyboardClose={true}
							header={this.state.errorAlertHeaderMessage} /* TO BE LOCALIZED */
							message={this.state.errorAlertMessage} /* TO BE LOCALIZED */
							buttons={["Ok"]}
						/>
					</IonGrid>
				</div>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		isAuthenticated: state.auth.userData ? true : false,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CredentialsLogin);

import axios, { AxiosResponse } from "axios";
import { backendApi, backendApiDemo } from "../../../../common/apiService";
import { Audiobook } from "../../../audiobooks/store/reducers";
import { Summary } from "../../../summaries/store/reducers";
import { FlashcardsCollection } from "../../../flashcards/store/reducers";
import { KeywordsCollections } from "../reducers";

export async function createKeywordsCollection(token: string,language:string, session_id?: string, prompt?:string|null, title?:string) {
	try {
		const response: AxiosResponse = await backendApi.post(
			`/resources/keywords-collections`,
			{
				study_session_id: session_id,
				prompt: prompt,
                title: title,
				language:language
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		if (response.status === 201) {
            console.log("[createKeywordsCollection]Creation Successful:", response.status,response.data);
            getKeywordsFromCollection(token,response.data.id)
			return response.data;
		} else {
			console.error("[createKeywordsCollection]Unexpected error:", response.status);
			return false
		}
	} catch (error) {
		// Handle errors
		if (axios.isAxiosError(error)) {
			console.error("[createKeywordsCollection]Error creating flashcards collection:", error.response?.data || error.message);
			return false
		} else {
			console.error("[createKeywordsCollection]Unexpected error:", error);
		}
	}
}
export async function getKeywordsFromCollection(token:string,keywords_collection_id:string) {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
		const response: AxiosResponse = await backendApi.get(
			`/resources/keywords-collections/${keywords_collection_id}/keywords`,config
		);
		if (response.status === 200) {
            console.log("[getKeywordsFromCollection]Creation Successful:", response.status);
			return response.data;
		} else {
			console.error("[getKeywordsFromCollection]Unexpected error:", response.status);
		}
	} catch (error) {
		// Handle errors
		if (axios.isAxiosError(error)) {
			console.error("[getKeywordsFromCollection]Error creating flashcards collection:", error.response?.data || error.message);
		} else {
			console.error("[getKeywordsFromCollection]Unexpected error:", error);
		}
	}
}

export function getSingleKeywordsCollection(flashcards_collection_id: string, token: string) {
	return new Promise<KeywordsCollections>(async (resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let flashcards_collections_obj: KeywordsCollections;
		if (flashcards_collection_id) {
			backendApi
				.get(`/resources/keywords-collections/${flashcards_collection_id}`, config)
				.then((response) => {
					if (response.status === 200) {
						flashcards_collections_obj = response.data.summary;
						resolve(flashcards_collections_obj);
					} else {
						reject(new Error(`[getSingleKeywordsCollection]Request failed with status ${response.status}`));
					}
				})
				.catch((error) => {
					console.error(`[getSingleKeywordsCollection]Error fetching keywords collection ${flashcards_collection_id}}`, error);
					reject(error);
				});
		}
	});
}

export function getKeywordsCollections(token: string) {
	return new Promise<KeywordsCollections[]>(async (resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let keywords_collections_obj: KeywordsCollections[];
		backendApi
			.get(`/resources/keywords-collections`, config)
			.then((response) => {
				if (response.status === 200) {
                    console.log(response.data)
					keywords_collections_obj = response.data.keywords_collections;
					resolve(keywords_collections_obj);
				} else {
					reject(new Error(`[getKeywordsCollections]Request failed with status ${response.status}`));
				}
			})
			.catch((error) => {
				console.error(`[getKeywordsCollections]Error fetching summaries`, error);
				reject(error);
			});
	});
}
export function deleteKeywordsCollections(keywords_collection_id:string,token:string){
    return new Promise<KeywordsCollections[]>(async (resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let keywords_collections_obj: KeywordsCollections[];
		backendApi
			.delete(`/resources/keywords-collections/${keywords_collection_id}`, config)
			.then((response) => {
				if (response.status === 200) {
                    console.log("[deleteSingleFlashcardsCollection]Deletion successful")
					keywords_collections_obj = response.data.audiobook;
					resolve(keywords_collections_obj);
				} else {
					reject(new Error(`[deleteSingleFlashcardsCollection]Request failed with status ${response.status}`));
				}
			})
			.catch((error) => {
				console.error(`[deleteSingleFlashcardsCollection]Error fetching summaries`, error);
				reject(error);
			});
	});
}

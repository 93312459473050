import { SettingsTranslationsEN } from '../../modules/settings/SettingsTranslations'
import { ProfileTranslationsEN } from '../../modules/user/ProfileTranslations'
import { MenuTranslationsEN } from '../../modules/menu/MenuTranslations'
import { SketchTranslationsEN } from '../../modules/sketch/SketchTranslations';
import { FileTranslationsEN } from '../../modules/files/FileTranslations';
import { PopoverTranslationsEN } from '../../modules/popover/PopoverTranslations';
import { AuthTranslationsEN } from '../../modules/authentication/AuthTranslations';
import { OnboardingTranslationsEN } from '../../modules/onboarding/OnboardingTranslations';
import { MapsTranslationsEN } from '../../modules/maps/MapsTranslations';
import { SessionsTranslationsEN } from '../../modules/sessions/SessionsTranslations';
import { FlashcardsTranslationsEN } from '../../modules/flashcards/FlashcardsTranslations';
import { AudiobookTranslationsEN } from '../../modules/audiobooks/AudiobookTranslations';
import { ViewerTranslationsEN } from '../../modules/summaries/ViewerTranslations';

const mergedObject = {
  ...SettingsTranslationsEN,
  ...ProfileTranslationsEN,
  ...MenuTranslationsEN,
  ...SketchTranslationsEN,
  ...FileTranslationsEN,
  ...PopoverTranslationsEN,
  ...AuthTranslationsEN,
  ...OnboardingTranslationsEN,
  ...MapsTranslationsEN,
  ...SessionsTranslationsEN,
  ...FlashcardsTranslationsEN,
  ...AudiobookTranslationsEN,
  ...ViewerTranslationsEN,

};

export const en = {
  mergedObject,
}
//HOME 
export let homeTutoriaTxtSlide1 = "Benvenuto su Reasy!\nSegui i tutorial per esplorare l’app"
export let homeTutoriaTxtSlide2 = "Il tutorial sarà sempre disponibile premendo qui"
export let homeTutoriaTxtSlide3 = "Carica un documento di testo.\n- Foto di un testo scritto a mano\n- PDF/JPG/PNG di un libro o testo"
export let homeTutoriaTxtSlide4 = "Cambia la modalità di visualizzazione dei file"
export let homeTutoriaTxtSlide5 = "Premi qui per cambiare il titolo del file o cancellarlo"
export let homeTutoriaTxtSlide6 = "Premi qui per visualizzare il file che hai caricato"

//FILE VIEWER

export let fileViewerTutorialTxtSlide1 = "Ascolta il testo"
export let fileViewerTutorialTxtSlide2 = "Crea una mappa concettuale"
export let fileViewerTutorialTxtSlide3 = "Modifica il documento caricato"
export let fileViewerTutorialTxtSlide4 = "Crea un riassunto"
export let fileViewerTutorialTxtSlide5 = "Individua le parole chiave"
export let fileViewerTutorialTxtSlide6 = "Aumenta o diminuisci la dimensione del documento"
export let fileViewerTutorialTxtSlide7 = "Scarica il documento in pdf"
export let fileViewerTutorialTxtSlide8 = "Scorri le pagine del documento"

import {
    IonContent, IonPage, IonToolbar, IonHeader, IonGrid,
    IonButton,
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Player } from '@lottiefiles/react-lottie-player';
import loadingAnimation from "../../../assets/animations/waitingDots.json";
import loadingShapes from "../../../assets/animations/loadingShapes.json";
import { purchasesActions } from '../store/actions';
import "./PaymentProcessing.css";
import successPayment from "../../../assets/animations/successGreenTick.json";

type Props = {
    history: any,

    isPaymentLoading: boolean,
    isPaymentProcessing: boolean,
    isPaymentCanceled: boolean,
    isPaymentSuccessful: boolean,
    paymentError:boolean,

    clear: () => void,
};

type State = {
};

class PaymentProcessing extends React.Component<Props, State> {
    componentDidMount(): void {
        console.log("[PROCCESSING PAGE]",this.props.paymentError)
    }
    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        {/* <IonButtons slot="start">
                            <IonBackButton defaultHref="/" />
                        </IonButtons> */}
                        
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonGrid className="paymentProcessingMainGrid">

                        {
                            this.props.isPaymentLoading &&
                            <div className="paymentProcessingLoadingDiv">
                                <div>
                                    <Player
                                        autoplay
                                        loop
                                        src={loadingAnimation}
                                    />
                                </div>

                                <p className="paymentProcessingLoadingParagraph">
                                    Pagamento in corso {/* TO BE LOCALIZED */}
                                </p>

                            </div>
                        }

                        {
                            this.props.isPaymentProcessing &&
                            <div className="paymentProcessingProcessingDiv">
                                <div>
                                    <Player
                                        autoplay
                                        loop
                                        src={loadingShapes}
                                    />
                                </div>
                                <p className="paymentProcessingProcessingParagraph">
                                    Stiamo processando il pagamento
                                </p>
                            </div>
                        }
{
                            this.props.isPaymentSuccessful &&
                            <div className='paymentCompletedMainDiv'>
                                <div className="paymentCompletedSuccessDiv">
                                    <Player
                                        autoplay
                                        keepLastFrame
                                        src={successPayment}
                                    />
                                </div>
                                <div className='paymentCompletedHeaderDiv'>
                                    <p>Pagamento completato</p>
                                    <p>Grazie per aver scelto Reasy!</p>


                                </div>
                                <div className='paymentCompletedButtonsDiv'>
                                    <a
                                        className='paymentCompletedInvoiceText'
                                        onClick={() => {
                                            window.open('https://forms.gle/ysbDDin5DXPPYRwD9', '_blank')
                                        }}
                                    >
                                        Hai bisogno della fattura? 📄
                                    </a>
                                    <IonButton
                                        className='paymentCompletedCTAButton'
                                        onClick={() => {
                                            this.props.history.push('/sessions')
                                        }}
                                    >
                                        Inizia a studiare con Reasy!
                                    </IonButton>

                                </div>
                            </div>


                        }
                        {
                            this.props.isPaymentCanceled &&
                            <div className="paymentProcessingCanceledDiv">
                                <p className="paymentProcessingCanceledEmoji">
                                    😕
                                </p>

                                <p className="paymentProcessingCanceledParagraph">
                                    Hai annullato il pagamento
                                </p>
                                
                                <p
                                    className="paymentProcessingCanceledBackToPaymentButton"
                                    onClick={() => {
                                        this.props.clear();
                                        this.props.history.replace(`/purchase`);
                                    }}
                                >
                                    Se vuoi tornare indietro, clicca qui
                                </p>

                                
                            </div>
                        }
                        {this.props.paymentError && 
                            <div className="paymentProcessingCanceledDiv">
                            <p className="paymentProcessingCanceledEmoji">
                                😕
                            </p>

                            <p className="paymentProcessingCanceledParagraph">
                                Errore durante il processamento
                            </p>
                            
                            <p
                                className="paymentProcessingCanceledBackToPaymentButton"
                                onClick={() => {
                                    this.props.clear();
                                    this.props.history.replace(`/purchase`);
                                }}
                            >
                                Torna indietro
                            </p>
                        </div>
                        }


                    </IonGrid>                    

                    {/* {
                        this.props.isPaymentSuccessful &&
                        <Redirect to="/payment-completed" />
                    } */}
                    
                </IonContent>

            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        isPaymentLoading: state.purchases.isLoadingPayment,
        isPaymentProcessing: state.purchases.isProcessingPayment,
        isPaymentCanceled: state.purchases.paymentCanceled,
        isPaymentSuccessful: state.purchases.paymentSuccess,
        paymentError: state.purchases.paymentError
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        clear: () => {
            dispatch(purchasesActions.clear());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentProcessing);

import { SET_THUMBNAILS, CLEAR_THUMBNAILS } from './constants';


interface Thumbnail {
    id: string;
    url: string;
}
interface SetThumbnailsAction {
    type: typeof SET_THUMBNAILS;
    payload: Thumbnail[];
}
interface ClearThumbnailsAction {
    type: typeof CLEAR_THUMBNAILS;
}
export const thumbnailActions = {
    setThumbnails,
    clearThumbnails,
};

const actionCreator = {
    setThumbnails: (thumbnails: Thumbnail[]): SetThumbnailsAction => ({
        type: SET_THUMBNAILS,
        payload: thumbnails
    }),
    clearThumbnails: (): ClearThumbnailsAction => ({
        type: CLEAR_THUMBNAILS
    })
};

function setThumbnails(thumbnails: Thumbnail[]) {
    return (dispatch: any) => {
        dispatch(actionCreator.setThumbnails(thumbnails));
    };
}

function clearThumbnails() {
    return (dispatch: any) => {
        dispatch(actionCreator.clearThumbnails());
    };
}

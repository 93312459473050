export const MenuTranslationsEN = {
    "Home": "Home",
    "Configurazioni": "Setup",
    "Profilo": "Profile",
    "Esci": "Log out",
    "Termini e Condizioni": "Terms and Conditions",
    "Versione": "Version",

    "Scopri il menu": "Discover the menu",
    "Esplora il profilo": "Explore the profile",
    "Esplora le configurazioni": "Explore the settings",
}
export const MenuTranslationsIT = {
    "Home": "Home",
    "Configurazioni": "Configurazioni",
    "Profilo": "Profilo",
    "Esci": "Esci",
    "Termini e Condizioni": "Termini e Condizioni",
    "Versione": "Versione",

    "Scopri il menu": "Scopri il menu",
    "Esplora il profilo": "Esplora il profilo",
    "Esplora le configurazioni": "Esplora le Configurazioni",

}
export const MenuTranslationsES = {
    "Home": "Página de inicio",
    "Configurazioni": "Ajustes",
    "Profilo": "Perfil",
    "Esci": "Salida",
    "Termini e Condizioni": "Términos y condiciones",
    "Versione": "Versión",

    "Scopri il menu": "Descubre el menú",
    "Esplora il profilo": "Explora el perfil",
    "Esplora le configurazioni": "Explora les configuraciones",

}
export const MenuTranslationsFR = {
    "Home": "Page d'accueil",
    "Configurazioni": "Panneau de configuration",
    "Profilo": "Profil",
    "Esci": "Sortie",
    "Termini e Condizioni": "Modalités et conditions",
    "Versione": "Version",

    "Scopri il menu": "Découvrir le menu",
    "Esplora il profilo": "Explorer le profil",
    "Esplora le configurazioni": "Explorer les paramètres",

}
export const MenuTranslationsDE = {
    "Home": "Startseite",
    "Configurazioni": "Einstellungen",
    "Profilo": "Profil",
    "Esci": "Ausloggen",
    "Termini e Condizioni": "Nutzungsbedingungen",
    "Versione": "Version",

    "Scopri il menu": "Entdecke das Menü",
    "Esplora il profilo": "Profil erkunden",
    "Esplora le configurazioni": "Erkunde die Einstellungen",
}
export const MenuTranslationsCA = {
    "Home": "Inici",
    "Configurazioni": "Configuracions",
    "Profilo": "Perfil",
    "Esci": "Sortir",
    "Termini e Condizioni": "Termes i Condicions",
    "Versione": "Versió",
    "Scopri il menu": "Descobreix el menú",
    "Esplora il profilo": "Explora el perfil",
    "Esplora le configurazioni": "Explora les configuracions",
}
export const MenuTranslationsPT = {
    "Home": "Início",
    "Configurazioni": "Configurações",
    "Profilo": "Perfil",
    "Esci": "Sair",
    "Termini e Condizioni": "Termos e Condições",
    "Versione": "Versão",
    "Scopri il menu": "Descubra o menu",
    "Esplora il profilo": "Explore o perfil",
    "Esplora le configurazioni": "Explore as configurações",
}
export const MenuTranslationsCZ = {
    "Home": "Domů",
    "Configurazioni": "Nastavení",
    "Profilo": "Profil",
    "Esci": "Odejít",
    "Termini e Condizioni": "Podmínky a ujednání",
    "Versione": "Verze",
    "Scopri il menu": "Objevte menu",
    "Esplora il profilo": "Prozkoumejte profil",
    "Esplora le configurazioni": "Prozkoumejte nastavení",
}
export const MenuTranslationsNL = {
    "Home": "Home",
    "Configurazioni": "Instellingen",
    "Profilo": "Profiel",
    "Esci": "Afmelden",
    "Termini e Condizioni": "Voorwaarden",
    "Versione": "Versie",

    "Scopri il menu": "Ontdek het menu",
    "Esplora il profilo": "Verken het profiel",
    "Esplora le configurazioni": "Verken de instellingen"
}
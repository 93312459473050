import React, { useEffect } from 'react';
import { signInWithPopup, OAuthProvider, fetchSignInMethodsForEmail, linkWithCredential, EmailAuthProvider, signInWithEmailAndPassword, deleteUser, GoogleAuthProvider, reauthenticateWithPopup } from 'firebase/auth';
import { auth } from './firebaseConfig';

const AuthComponent = () => {
  useEffect(() => {
    const PARENT_FRAME = document.location.ancestorOrigins[0];

    function sendResponse(result:any) {
      globalThis.parent.self.postMessage(JSON.stringify(result), PARENT_FRAME);
    }

    async function handleAuth(provider: any , providerType:string) {
      try {
        const result = await signInWithPopup(auth, provider);
        sendResponse(result);
      } catch (error: any) {
        if (error.code === 'auth/account-exists-with-different-credential') {
          const email = error.customData.email;
          let pendingCred
          if(providerType==="google.com")
            pendingCred = GoogleAuthProvider.credentialFromError(error);
          else 
            pendingCred = OAuthProvider.credentialFromError(error);
          try {
            const methods = await fetchSignInMethodsForEmail(auth, email);
            if (methods.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)) {
              const password = prompt('Please enter your password:');
              if (password && pendingCred) {
                const credential = EmailAuthProvider.credential(email, password);
                const userCredential = await signInWithEmailAndPassword(auth, email, password);
                await linkWithCredential(userCredential.user, pendingCred);
                sendResponse({ user: userCredential.user, linked: true });
              }
            } else {
              sendResponse({ error: error });
            }
          } catch (linkError) {
            sendResponse({ error: linkError });
          }
        } else {
          sendResponse({ error: error.message });
        }
      }
    }

    async function handleDeleteAccount(providerId:string,language:string) {
      try {
              const confirmed = await confirmAccountDeletion(language);
              if (confirmed) {
                  // Re-authenticate the user before deleting the account
                  const user = auth.currentUser;
                  let provider
                  if(providerId==="google.com") 
                      provider = new GoogleAuthProvider()
                  else
                      provider = new OAuthProvider(providerId)
                  if(user){
                    await reauthenticateWithPopup(user,provider)
                    await deleteUser(user);
                    
                    sendResponse({ message: 'Account deleted successfully.'})
                  }
              } else {
                  sendResponse({ message: 'Account deletion cancelled by user.' });
              }
      } catch (error) {
          sendResponse({ error: error });
      } 
  }
  
  async function confirmAccountDeletion(language:string) {
      // Implement your confirmation logic here.
      // For example, using a modal dialog in a web application:
      return new Promise((resolve) => {
          const confirmation = window.confirm(language==="en"?"Are you sure you want to delete your account? This action cannot be undone."
          :language==="it"?"Sei sicuro di voler eliminare il tuo account? Questa azione non può essere annullata."
          :language==="es"?"¿Estás seguro de que deseas eliminar tu cuenta? Esta acción no se puede deshacer."
          :language==="de"?"Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden."
          :"Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible."

        );
          resolve(confirmation);
      });
  }
  
  async function reauthenticateUser(providerId:string) {
      if (providerId === 'google.com') {
          const provider = new GoogleAuthProvider();
          await signInWithPopup(auth, provider);
      } else if (providerId === 'microsoft.com') {
          const provider = new OAuthProvider('microsoft.com');
          await signInWithPopup(auth, provider);
      } else if (providerId === 'apple.com') {
          const provider = new OAuthProvider('apple.com');
          await signInWithPopup(auth, provider);
      } else {
          throw new Error('Unsupported authentication provider');
      }
  }
  

    globalThis.addEventListener('message', function({ data }) {
      if (data.initAuthMicrosoft) {
        const provider = new OAuthProvider('microsoft.com');
        handleAuth(provider,'microsoft.com');
      } else if (data.initAuthApple) {
        const provider = new OAuthProvider('apple.com');
        handleAuth(provider,'apple.com');
      } else if (data.initAuthGoogle) {
        const provider = new GoogleAuthProvider();
        handleAuth(provider,'google.com');
      } 
      else if (data.deleteAccount) {
        handleDeleteAccount(data.provider,data.language);
      }
    });
  }, []);

  return null;
};

export default AuthComponent;

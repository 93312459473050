import { IonButton, IonIcon, IonImg, IonLoading } from '@ionic/react';
import { deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { addOutline, cloudDownloadOutline, eyeOutline, printOutline } from 'ionicons/icons';
import React from 'react';
import ZoomInMaps from "../../../assets/icons/ZoomInMaps.svg";
import centerElement from "../../../assets/icons/centerElement.svg";
import pencilEdit from '../../../assets/icons/pencilEdit.svg';
import trashBin from '../../../assets/icons/trashBin.svg';
import ZoomOut from '../../../assets/icons/zoomminus.svg';
import ZoomIN from '../../../assets/icons/zoomplus.svg';
import { firestore } from '../../../firebaseConfig';
import i18n from '../../../i18n';
import { filesServices } from '../../files/store/services';
type Props = {
    selectedNodeId: string,
    modeEditing: 'main' | 'editing',
    documentId: string,
    history: any,
    onlyMap:any,

    zoomInMapsClicked: () => void,
    zoomMaps: () => void,
    zoomOut: () => void,
    zoomIn: () => void,
    editingModeClicked: () => void,
    downloadButtonClicked: () => void,
    printClicked: () => void,
    addnode:()=>void,
    openTutorial:()=>void,

};

type State = {
    mapDelete:boolean
};



export class MapSideButtons extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            mapDelete:false
        }
    }
    render() {
        return (
            <div className='actionButtons'>
                <div className='mapsSideactionbtn'>
                    {/* <IonButton onClick={() => { console.log('pagecounter' + this.state.pageCounter, 'primo' + this.state.startParagraphIndex, 'ultimo' + this.state.lastParagraphIndex, 'ttspointer' + this.state.ttsParagraphPointer) }}></IonButton> */}
                    <IonButton
                        id="zoom-all"
                        onClick={() => {
                            this.props.zoomInMapsClicked();
                            /* reactFlowInstance.fitView({ duration: 1000, minZoom: 0.1 }) */
                        }}
                        className='btn-fileviewer'
                        color='whitebuttons'
                        slot='icon-only'

                    >
                        <IonIcon style={{ paddingInlineStart: '0px', paddingInlineEnd: '0px' }} size='large' icon={ZoomInMaps}></IonIcon>
                    </IonButton>

                </div>

                <div hidden={this.props.selectedNodeId === ''} className='mapsSideactionbtn'>
                    <IonButton
                    id="zoom-node"
                        onClick={() => { this.props.zoomMaps() }}
                        className='btn-fileviewer'
                        color='whitebuttons'
                    >
                        <IonIcon size='large' icon={centerElement} />
                        {/* <IonSpinner hidden={!this.state.mapButtonDisabled} name='crescent' /> */}
                    </IonButton>
                </div>

                <div className='mapsSideactionbtn'>
                    <IonButton
                    id="zoom-out"
                        onClick={() => {
                            this.props.zoomOut()
                            // reactFlowInstance.zoomOut({ duration: 500 })
                        }}
                        className='btn-fileviewer'
                        color='whitebuttons'
                    >
                        <IonIcon icon={ZoomOut} slot='icon-only' />
                    </IonButton>
                </div>
                <div className='mapsSideactionbtn'>
                    <IonButton
                    id="zoom-in"
                        onClick={() => {
                            this.props.zoomIn()
                            // reactFlowInstance.zoomIn({ duration: 500 })
                        }}
                        className='btn-fileviewer'
                        color='whitebuttons'
                    >
                        <IonIcon icon={ZoomIN} slot='icon-only' />
                    </IonButton>
                </div>
                <div className='mapsSideactionbtn' style={{ marginTop: '25px' }}>
                    <IonButton
                    id="edit-map"
                        onClick={() => {
                            // this.props.openTutorial()
                            this.props.editingModeClicked()
                            // this.setState({ modeEditing: this.state.modeEditing === 'main' ? 'editing' : 'main' })
                        }}
                        className='btn-fileviewer'
                        color='whitebuttons'
                    >
                        <IonImg hidden={this.props.modeEditing === 'editing'} src={pencilEdit} />
                        <IonIcon hidden={this.props.modeEditing === 'main'} icon={eyeOutline} slot='icon-only' />
                    </IonButton>
                </div>
                <div className='mapsSideactionbtn' style={{ marginTop: '50px' }}>
                    <IonButton
                    id="download-map"
                        onClick={() => {
                            this.props.downloadButtonClicked()
                            // this.generatePDF()
                        }}
                        className='btn-fileviewer'
                        color='whitebuttons'
                    >
                        <IonIcon icon={cloudDownloadOutline} slot='icon-only' />
                    </IonButton>
                </div>
                <div className='mapsSideactionbtn' style={{ marginTop: '' }}>
                    <IonButton
                    id="print-map"
                        onClick={() => {
                            this.props.printClicked()
                            // this.state.rfInstance.fitView({ duration: 500, minZoom: 0.1 })
                            // setTimeout(() => {
                            //     window.print()
                            // }, 500);
                        }}
                        className='btn-fileviewer'
                        color='whitebuttons'
                    >
                        <IonIcon icon={printOutline} slot='icon-only' />
                    </IonButton>
                </div>
                <div className='mapsSideactionbtn' style={{ marginTop: '' }}>
                    <IonButton
                        id="delete-map"
                        onClick={async () => {
                            if (this.props.documentId) {
                                if (window.confirm(
                                    i18n.language === 'it' ? "Vuoi eliminare la mappa?" :
                                        i18n.language === 'es' ? "¿Quieres borrar el mapa?" :
                                            i18n.language === 'fr' ? "Voulez-vous supprimer la carte?" :
                                                'Do you want to delete the map?') === true
                                ) {
                                    console.log("ONLYMAP",this.props.onlyMap)
                                    let argPresent= this.props.history.location.state ? this.props.history.location.state.argumentID.uuid:false

                                    if(this.props.onlyMap && argPresent){
                                        this.setState({mapDelete:true})
                                        filesServices.deleteMap(this.props.documentId,this.props.history.location.state.argumentID.uuid)
                                            .then(() => {
                                                this.props.history.push(`/sessions`)
                                            })
                                        //     await deleteDoc(doc(firestore, `m/${this.props.documentId}`))
                                        //     .then(async (response)=>{
                                        //         const documentRef = doc(firestore, `a/${this.props.history.location.state.argumentID.uuid}`);
                                        //         const documentSnapshot = await getDoc(documentRef);
                                            
                                        //         if (documentSnapshot.exists()) {
                                        //           const data = documentSnapshot.data();
                                                  
                                        //           // Assuming r is an array of objects, find and remove the specific field
                                        //           const updatedArray = data.r.filter((item:any) => item.d !== this.props.documentId); // Adjust fieldName and valueToRemove accordingly
                                                  
                                        //           // Update the document with the new array
                                        //           await updateDoc(documentRef, { r: updatedArray }).then(()=>{
                                        //     })}
                                           
                                        // })
                                    }else if(!this.props.onlyMap){
                                        this.setState({mapDelete:true})
                                        await deleteDoc(doc(firestore, `m/${this.props.documentId}`)).then(() => {
                                            this.props.history.push(`/file/${this.props.documentId}`);
                                            window.location.reload();
                                        });
                                        // filesServices.deleteMap(this.props.documentId)
                                        // .then(() => {
                                        //     this.props.history.push(`/file/${this.props.documentId}`)
                                        //     window.location.reload()
                                        // })
                                        // await deleteDoc(doc(firestore, `m/${this.props.documentId}`))
                                        // .then(()=>{
                                        //     this.props.history.push(`/file/${this.props.documentId}`)
                                        //     window.location.reload()
                                        // })
                                    }else{
                                        this.setState({mapDelete:true})
                                        await deleteDoc(doc(firestore, `m/${this.props.documentId}`)).then(() => {
                                            this.props.history.push(`/file/`);
                                            window.location.reload();
                                        });
                                        // filesServices.deleteMap(this.props.documentId)
                                        // .then(() => {
                                        //     this.props.history.push(`/files/`)
                                        //     window.location.reload()
                                        // })
                                        // await deleteDoc(doc(firestore, `m/${this.props.documentId}`)).then((response)=>{console.log("sei tu",response)
                                        //     this.props.history.push(`/files/`)
                                        //     window.location.reload()
                                        // })
                                    }
                                }
                                // updateDoc(doc(firestore, `m/${this.state.documentId}`), { d: deleteField(), f: deleteField() })
                            }
                        }}
                        className='btn-fileviewer'
                        color='whitebuttons'
                    >
                        <IonImg src={trashBin} slot='icon-only' />
                    </IonButton>
                </div>
                <div className='mapsSideactionbtn' style={{ marginTop: '' }}>
                    <IonButton
                        id="add-node"
                        onClick={() => {
                            this.props.addnode()
                        }}
                        className='btn-fileviewer'
                        color='whitebuttons'
                    >
                        <IonIcon src={addOutline} slot='icon-only' />
                    </IonButton>
                </div>
                <IonLoading message={
                        i18n.language === 'it' ?
                            'Sto cancellando la mappa' :
                            i18n.language === 'en' ?
                                'Deleting map' :
                                i18n.language === 'es' ?
                                    'Borrando el resumen' :
                                    i18n.language === 'fr' ? 'Suppression du résumé' :
                                        i18n.language === 'ca' ? 'Esborraré el resum' :
                                            'Ich lösche die Zusammenfassung'
                    }
                        isOpen={this.state.mapDelete}
                    />
            </div>
        );
    }
}

import { Device } from "@capacitor/device";
import i18n from "../../../i18n";
import { Argument, Session } from "../store/reducers";
import { argumentServices } from "../store/services";
import { StatusBar } from '@capacitor/status-bar';
import { OrganizationLicense, OrganizationLicenseData, ResourceUsageEvent, UserLicense, UserLicenseData } from "../../user/store/reducers";
import { getPlanLimits } from "../../purchase/utils/purchaseUtils";
import { ResourceLimit } from "../../purchase/store/reducers";
import { userServices } from "../../user/store/services";
import { User } from "firebase/auth";

export function isImageValid(this: any, file: any) {
    return new Promise<boolean>((resolve, reject) => {
        let _URL = window.URL || window.webkitURL
        let _this = this
        let img = new Image();
        img.onload = function () {
            if (img.width > 8000 || img.height > 8000) {
                _this.setState({ isUploadingFile: false, isProcessingResources: false })
                _URL.revokeObjectURL(img.src);
                resolve(false)
            }
            else {
                _URL.revokeObjectURL(img.src);
                resolve(true)
            }
        };
        img.src = _URL.createObjectURL(file);
    })
}

export function uploadFile(this: any, file: any, userID: any, specificResource?: string, existingArgument?: Argument) {

    argumentServices.uploadFile
        .call(this, file, userID, this.state.bearerToken, undefined, specificResource, existingArgument)
        .then((response: any) => {
            console.log(response);
            this.setState({ generatedArgument: response, isUploadingFile: false, isProcessingResources: false }, () => {
                this.props.history.push({
                    pathname: "/session-details",
                    state: { argument: response },
                });
            });
        })
        .catch((err) => {
            console.error("[FilesManager] Error uploading file", err);
            let errorMessage =
                i18n.language === "it"
                    ? "Errore durante il caricamento del file. Foto/file non chiaro o dimensione superiore a 25MB, riprovare.(Se il file supera i 25MB, comprimi o separalo in più parti)"
                    : i18n.language === "en"
                        ? "Error during file upload. The photo/file is unclear or exceeds 25MB in size, please try again. (If the file exceeds 25MB, compress it or split it into multiple parts)"
                        : i18n.language === "fr"
                            ? "Erreur lors du chargement du fichier. La photo/le fichier est flou ou dépasse 25 Mo, veuillez réessayer. (Si le fichier dépasse 25 Mo, compressez-le ou divisez-le en plusieurs parties.)"
                            : i18n.language === "de"
                                ? "Fehler beim Hochladen der Datei. Das Foto/die Datei ist unklar oder überschreitet 25 MB, bitte versuchen Sie es erneut. (Wenn die Datei 25 MB überschreitet, komprimieren Sie sie oder teilen Sie sie in mehrere Teile auf.)"
                                : i18n.language === "ca"
                                    ? "Error durant la càrrega del fitxer. La foto/el fitxer no és clar o supera els 25 MB, si us plau, torni a intentar-ho. (Si el fitxer supera els 25 MB, comprimeixi'l o separi'l en diverses parts.)"
                                    : i18n.language === "es"
                                        ? "Error durante la carga del archivo. La foto/el archivo no está claro o supera los 25 MB, por favor, intente nuevamente. (Si el archivo supera los 25 MB, comprímalo o sepárelo en varias partes.)"
                                        : "Erro durante o carregamento do arquivo. A foto/arquivo está pouco claro ou excede 25 MB, por favor, tente novamente. (Se o arquivo exceder 25 MB, compacte-o ou divida-o em várias partes.)";
            alert(errorMessage);
            this.setState({ isUploadingFile: false, isProcessingResources: false })

            // this.setState({ isUploadingFile: false, showOcrPopover: false, disablePdfToMap: false });
        });
}

export function getStudySession(this: any, specificResource?: string, existingArgument?: Argument) {
    argumentServices.createArgumentFromString.call(this, this.state.newArgument, this.state.userUuid!, this.state.bearerToken, specificResource, existingArgument)
        .then((response: Argument) => {

            this.setState({ isProcessingResources: false, generatedArgument: response }, () => {
                this.props.history.push({
                    pathname: '/session-details',
                    state: { argument: response }
                })
            })

        })
}
export function clearSessionState() {
    localStorage.removeItem("sessionState");
    localStorage.removeItem("creationSessionState");
}

export const checkStatusBar = async () => {
    const info = await Device.getInfo();

    if (info.platform === 'ios') {
        const statusBarInfo = await StatusBar.getInfo();
        console.log('Status bar visible:', statusBarInfo.visible);

        // Status bar height (you can infer it, though it's often 20pt or 44pt in height)
        const statusBarHeight = statusBarInfo.visible ? (info.model.includes('iPhoneX') ? 44 : 20) : 0;
        console.log('Status bar height:', statusBarHeight);

        return statusBarHeight;
    } else {
        console.log('Not an iOS device');
        return 0;
    }
};

export function calculateAvailableResources(
    license: UserLicense | OrganizationLicense,
    totalLimits: ResourceLimit[],
    usageData: ResourceUsageEvent[]
): any[] {
    const currentDate = new Date();
    const startDate = new Date(license.start_at);
    const endDate = new Date(license.end_at);
    console.log("Start date: ", startDate.toLocaleDateString());
    console.log("Current date: ", currentDate.toLocaleDateString());
    console.log("End date: ", endDate.toLocaleDateString());

    // Function to add months to a date
    function addMonths(date: Date, months: number): Date {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + months);
        // Handle cases where adding months might skip to the next month
        while (newDate.getMonth() > (date.getMonth() + months) % 12) {
            newDate.setDate(newDate.getDate() - 1);
        }
        return newDate;
    }

    // Calculate the start of the current period
    let currentPeriodStart = new Date(startDate);
    let nextPeriodStart = addMonths(currentPeriodStart, 1);

    // Find the current period
    while (nextPeriodStart <= currentDate) {
        currentPeriodStart = nextPeriodStart;
        nextPeriodStart = addMonths(currentPeriodStart, 1);
    }

    console.log("Current period start: ", currentPeriodStart.toLocaleDateString());
    console.log("Next period start: ", nextPeriodStart.toLocaleDateString());

    // Filter usage data for the current period
    const currentPeriodUsage = usageData.filter(usage => {
        const usageDate = new Date(usage.usage_timestamp);
        return usageDate >= currentPeriodStart && usageDate < nextPeriodStart;
    });
    console.log("Current period usage count: ", currentPeriodUsage.length);

    // Calculate available resources
    return totalLimits.map(limit => {
        const used = currentPeriodUsage
            .filter(usage => usage.resource_type === limit.resource_type)
            .reduce((sum, usage) => sum + usage.usage_amount, 0);

        return {
            resource_type: limit.resource_type,
            total: limit.quantity,
            used,
            available: limit.quantity !== null ? Math.max(0, limit.quantity - used) : null
        };
    });
}

export function sumOrgLimitsAndPersonalLimits(personalLimits: ResourceLimit[], orgLimits: ResourceLimit[]) {
    const mergedMap = new Map<string, ResourceLimit>();

    // Helper function to update the merged map
    const updateMergedMap = (limit: ResourceLimit) => {
        const existing = mergedMap.get(limit.resource_type);
        if (!existing) {
            mergedMap.set(limit.resource_type, { ...limit });
        } else {
            if (existing.quantity === null || limit.quantity === null) {
                existing.quantity = null; // If either is unlimited, the result is unlimited
            } else {
                existing.quantity += limit.quantity;
            }
            // Keep the personal license's id and plan_id (arbitrary choice, could be changed)
            existing.id = limit.id;
            existing.plan_id = limit.plan_id;
        }
    };

    // Process personal limits
    personalLimits.forEach(updateMergedMap);

    // Process organization limits
    orgLimits.forEach(updateMergedMap);

    return Array.from(mergedMap.values());
}

function calculateHighestLimits(limits: ResourceLimit[][]): ResourceLimit[] {
    const resourceMap = new Map<string, ResourceLimit>();
    limits.flat().forEach(limit => {
        const existingLimit = resourceMap.get(limit.resource_type);

        if (!existingLimit ||
            limit.quantity === null ||
            (existingLimit.quantity !== null && limit.quantity > existingLimit.quantity)) {
            resourceMap.set(limit.resource_type, { ...limit });
        }
    });

    return Array.from(resourceMap.values());
}
/* RETURNS BOTH THE ORG AND THE PERSONAL HIGHEST-LIMIT LICENSES */
export async function getHighestLimits(userId: string, userLicenses: UserLicense[], token: string) {
    /* __________________ GET ORG LICENSES __________________ */
    let orgActiveLicenses = userLicenses.filter(license => license.org_id !== null && new Date(license.end_at) > new Date())
    let highestQuantityOrgLimits: ResourceLimit[] = [];
    if (orgActiveLicenses.length > 0) {
        let highestQuantity = -Infinity;
        /* GET USER CORRESPONDANT USER ORGS */
        let userOrgWithActiveLicenses = orgActiveLicenses.map(license => { return license.org_id })[0] //TAKE JUST THE FIRST ORG -> IGNORE THE CASE USER HAS MORE ORGANIZATIONS ASSIGNED
        if (userOrgWithActiveLicenses) {
            /* GET ORG'S LICENSES */
            let orgLicenses = await userServices.getOrganizationLicenses(userOrgWithActiveLicenses, token)
            /* GET ORG'S LICENSES' LIMITS */
            let tempOrgLimits = []
            for (let i = 0; i < orgLicenses.length; i++) {
                let orgLicenseDetails = await userServices.getOrganizationLicenseDetails(userOrgWithActiveLicenses!, orgLicenses[i].id, token)
                tempOrgLimits.push(orgLicenseDetails.plan_limits)
            }
            /* GET THE HIGHEST POSSIBLE LIMITS FOR EACH RESOURCE */
            highestQuantityOrgLimits = calculateHighestLimits(tempOrgLimits)
        }
    }
    /* __________________ END - GET ORG LICENSES __________________ */

    /* __________________ GET PERSONAL LICENSES __________________ */
    let personalActiveLicenses = userLicenses.filter(license => new Date(license.end_at) > new Date() && license.org_id === null)
    /* FOREACH LICENSE GET DETAILS ABOUT PLAN AND LIMITS */
    let highestQuantityPersonalLimits: ResourceLimit[] = [];
    let highestQuantity = -Infinity;
    let tempPersonalLimits = []
    console.log("PERSONAL ACTIVE: ", personalActiveLicenses);

    for (let k = 0; k < personalActiveLicenses.length; k++) {
        let userLicenseDetails = await userServices.getUserLicenseDetails(userId, personalActiveLicenses[k].id, token)
        tempPersonalLimits.push(userLicenseDetails.plan_limits)
    }
    highestQuantityPersonalLimits = calculateHighestLimits(tempPersonalLimits)

    /* __________________ END - GET PERSONAL LICENSES __________________ */
    if (highestQuantityPersonalLimits && highestQuantityOrgLimits)
        return { highestQuantityPersonalLimits, highestQuantityOrgLimits }
    else
        return null

}

export function getFreeTrialAvailableResources(resourceUsage: ResourceUsageEvent[]) {
    const resourceList = ['map', 'summary', 'flashcards', 'tts', 'keywords']
    let availableResources = []
    console.log("RESOURCE USAGE", resourceUsage);

    for (let index = 0; index < resourceList.length; index++) {
        const res = resourceList[index];
        const count = resourceUsage.reduce((acc, cur) => cur.resource_type === res ? acc + cur.usage_amount : acc, 0);

        availableResources.push({
            resource_type: res,
            total: 3,
            count,
            available: res === 'tts' ? 600000 - count : 3 - count /* 600000 is 10minutes of the free trial tts */
        })
    }
    return availableResources
}

export function getAvailableResources(this: any, userData: User, token: string, resourceUsage: ResourceUsageEvent[]) {
    return new Promise<any>((resolve, reject) => {
        userServices.getUserLicenses(userData.uid, token).then((licenses) => {
            if (licenses.length > 0) {
                this.setState({ userLicenses: licenses });
                let activeLicenses = licenses.filter(license => new Date(license.end_at) > new Date());
                if (activeLicenses.length > 0)
                    getHighestLimits(userData.uid, activeLicenses, token).then(async response => {
                        if (response) {
                            /* CALCULATE OLDEST START DATE FROM ALL*/ /* -> CHANGE LINE BELOW TO GET OLDEST BETWEEN ALL LICENSES */
                            let oldestStartLicense = activeLicenses.sort((a, b) => new Date(a.start_at).getTime() - new Date(b.start_at).getTime())[0];
                            console.log("OLDEST START LICENSE: ", oldestStartLicense);

                            /* SUM ORG LIMITS WITH PERSONAL LIMITS */
                            let totalLimits: ResourceLimit[] = sumOrgLimitsAndPersonalLimits(response.highestQuantityPersonalLimits!, response.highestQuantityOrgLimits!);

                            /* ONCE YOU HAVE ALL THE LIMITS, CALCULATE THE USAGE OF EACH RESOURCE */
                            let availableResources = calculateAvailableResources(oldestStartLicense, totalLimits, resourceUsage);
                            console.log("AVAILABLE RESOURCES: ", availableResources);
                            localStorage.setItem('availableResources', JSON.stringify(availableResources));
                            this.setState({ availableResources: availableResources }, () => {
                                resolve(availableResources)
                            });
                        }
                    });
                else {
                    this.setState({ availableResources: createEmptyAvailableResources() }, () => {
                        localStorage.setItem('availableResources', JSON.stringify(this.state.availableResources));
                        resolve(this.state.availableResources)
                    });
                }
            }
            else {
                this.setState({ userLicenses: [] });
                /* USER IS IN A FREE TRIAL PERIOD */
                let availableResources = getFreeTrialAvailableResources(resourceUsage);
                console.log("AVAILABLE FROM FREE TRIAL", availableResources);
                this.setState({ availableResources: availableResources }, () => {
                    localStorage.setItem('availableResources', JSON.stringify(availableResources));
                    resolve(this.state.availableResources)
                });
            }
        });
    })

}
export function createEmptyAvailableResources() {
    const resourceList = ['map', 'summary', 'flashcards', 'tts', 'keywords']
    let availableResources = []
    for (let i = 0; i < resourceList.length; i++) {
        availableResources.push(
            {
                resource_type: resourceList[i],
                total: 0,
                count: 0,
                available: 0
            }
        )
    }
    return availableResources
}

export function removeResourceFromLocalStorage(resource: string) {
    let availableResourcesStorage: any[] = JSON.parse(localStorage.getItem("availableResources") || "[]");

    // * UPDATE LOCAL STORAGE WITH THE USED RESOURCE 
    let generatedResourceElement = availableResourcesStorage.find(res => res.resource_type === resource)

    availableResourcesStorage[availableResourcesStorage.indexOf(generatedResourceElement)].available-- //* remove 1 resource since it has been generated
    console.log("NEW AVAILABLE RESOURCES STORAGE: ", availableResourcesStorage);
    localStorage.setItem("availableResources", JSON.stringify(availableResourcesStorage));
}


// Utility function to create delay
export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

// Wrapper function that implements the retry logic
export async function retryReloadArguments(instance: any) {
    let lastError: Error | null = null;
    
    // Wait initial 30 seconds
    await delay(500);
    
    // Initial attempt
    try {
        await instance.reloadArguments();
        return;
    } catch (error) {
        lastError = error as Error;
        console.log('Initial attempt failed:', error);
    }
    
    // Three retry attempts
    for (let attempt = 1; attempt <= 3; attempt++) {
        await delay(5000); // Wait 5 seconds between attempts
        
        try {
            await instance.reloadArguments();
            return;
        } catch (error) {
            lastError = error as Error;
            console.log(`Retry attempt ${attempt} failed:`, error);
        }
    }
    
    // If all retries failed, throw the last error
    throw lastError;
}
import React from 'react';
import {
    IonPage, IonContent, IonGrid, IonHeader, IonButtons, IonBackButton, IonToolbar, IonTitle,
} from '@ionic/react';
import { connect } from 'react-redux';
import './CookiePolicy.css';

type Props = {
    history: any,
};

type State = {
};

class CookiePolicy extends React.Component<Props, State> {

    /* constructor(props: any) {
        super(props);
        this.state = {
        }
    }
    */

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref='/' />
                        </IonButtons>
                        <IonTitle>Cookie policy</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <IonGrid className='cookiePolicyGrid'>
                        <h1>Cookie policy</h1>
                        <p>
                            Il Sito www.app.reasyedu.com utilizza Cookie, ossia delle righe di testo utili
                            per eseguire autenticazioni automatiche, tracciature di sessioni e
                            memorizzazione di informazioni specifiche riguardanti gli Utenti.
                            Alcune delle finalità d'installazione di Cookie potrebbero necessitare
                            il consenso dell'Utente.
                        </p>
                        <p>
                            Quando l’installazione di Cookie avviene sulla base del consenso, tale
                            consenso può essere revocato liberamente in ogni momento seguendo le
                            istruzioni contenute in questo documento.
                        </p>
                        <br />

                        <h3>
                            1.Attività strettamente necessarie al funzionamento (Cookie tecnici e
                            di statistica aggregata)
                        </h3>
                        <p>
                            www.app.reasyedu.com utilizza Cookie per salvare la sessione dell'Utente e per svolgere
                            altre attività strettamente necessarie al funzionamento di www.app.reasyedu.com, ad
                            esempio in relazione alla distribuzione del traffico.
                        </p>
                        <br />

                        <h3>
                            2.Attività di salvataggio delle preferenze, ottimizzazione e statistica
                        </h3>
                        <p>
                            www.app.reasyedu.com utilizza Cookie per salvare le preferenze di navigazione
                            ed ottimizzare l'esperienza di navigazione dell'Utente. Fra questi Cookie
                            rientrano, ad esempio, quelli per impostare la lingua e la valuta o per la gestione
                            di statistiche da parte del Titolare del Sito.
                        </p>
                        <br />

                        <h3>
                            3.Altre tipologie di Cookie o strumenti terzi che potrebbero installarne
                        </h3>
                        <p>
                            Alcuni dei servizi elencati di seguito raccolgono statistiche in forma aggregata ed
                            anonima e potrebbero non richiedere il consenso dell'Utente o potrebbero essere gestiti
                            direttamente dal Titolare - a seconda di quanto descritto – senza l’ausilio di terzi.
                        </p>
                        <p>
                            L’Utente ha la facoltà in qualsiasi momento di cancellare, bloccare e disabilitare i
                            Cookie tramite le impostazioni del browser web o del proprio dispositivo mobile (ove
                            consentito dal dispositivo). Nel caso in cui i Cookie siano stati cancellati, bloccati o
                            disabilitati, alcune funzionalità dei Servizi di Reasy potrebbero non funzionare
                            correttamente.
                        </p>
                        <p>
                            L'Utente può trovare informazioni su come gestire i Cookie con alcuni dei browser più diffusi
                            ad esempio ai seguenti indirizzi: Google Chrome, Mozilla Firefox, Apple Safari e Microsoft
                            Internet Explorer.
                        </p>
                        <p>
                            Con riferimento a Cookie installati da terze parti, l'Utente può inoltre gestire le proprie
                            impostazioni e revocare il consenso visitando il relativo Link di Opt Out, utilizzando gli
                            strumenti descritti nella privacy policy della terza parte o contattando direttamente la stessa.
                        </p>
                        <p>
                            Fermo restando quanto precede, si informano gli Utenti della possibilità di avvalersi delle
                            informazioni fornite da YourOnlineChoices (EU), Network Advertising Initiative (USA) e Digital
                            Advertising Alliance (USA), DAAC (Canada), DDAI (Giappone) o altri servizi analoghi. Con questi
                            servizi è possibile gestire le preferenze di tracciamento della maggior parte degli strumenti
                            pubblicitari. Il Titolare, pertanto, consiglia agli Utenti di utilizzare tali risorse in aggiunta
                            alle informazioni fornite dal presente documento.
                        </p>
                        <p>
                            Dal momento che l'installazione di Cookie e di altri sistemi di tracciamento operata da
                            terze parti tramite i servizi utilizzati all'interno di www.app.reasyedu.com non può essere
                            tecnicamente controllata dal Titolare, ogni riferimento specifico a Cookie e sistemi di
                            tracciamento installati da terze parti è da considerarsi indicativo. Per ottenere informazioni
                            complete, l'Utente è invitato a consultare la privacy policy degli eventuali servizi terzi
                            elencati in questo documento.
                        </p>
                        <br />

                        <h3>
                            4.Google Analytics (Google LLC)
                        </h3>
                        <p>
                            Google Analytics è un servizio di analisi web fornito da Google LLC (“Google”).
                            Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare
                            l’utilizzo di www.app.reasyedu.com, compilare report e condividerli con gli altri
                            servizi sviluppati da Google.
                        </p>
                        <p>
                            Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare
                            gli annunci del proprio network pubblicitario.
                        </p>
                        <p>
                            Dati Personali raccolti: Cookie e Dati di Utilizzo.
                        </p>
                        <p>
                            Luogo del trattamento: Stati Uniti – Privacy Policy – Opt Out. Soggetto aderente al
                            Privacy Shield.
                        </p>
                        <br />

                        <h3>
                            5.Statistica
                        </h3>
                        <p>
                            I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di
                            monitorare e analizzare i Dati di Traffico e servono a tener traccia del comportamento
                            dell’Utente.
                        </p>
                        <br />

                        <h3>
                            6.Titolare del Trattamento Dati
                        </h3>
                        <p>
                            Titolare: Tech4All s.r.l.<br />
                            Codice fiscale del Titolare: 02392620569<br />
                            Indirizzo del Titolare: Largo dell'Università SNC, 01100 Viterbo (VT), Italy
                        </p>
                        <p>
                            Per eventuali richieste di informazione/aggiornamento/cancellazione dei Dati
                            da parte degli Utenti si prega di consultare la pagina <a href='https://tech4all.ai/contatti'>contatti</a>.
                        </p>
                        <br />

                        <h3>
                            7.Riferimenti legali
                        </h3>
                        <p>
                            In conformità alle previsioni del Regolamento Europeo (UE) 2016/679 e del D.Lgs.
                            196/2003 e s.m.i., i Dati forniti dagli Utenti verranno trattati nel rispetto dei
                            principi di correttezza, liceità, trasparenza, limitazione delle finalità e della conservazione,
                            minimizzazione, esattezza, integrità e di tutela della riservatezza dei Dati stessi.
                        </p>
                        <p>
                            Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente
                            app.reasyedu.com.
                        </p>
                        <br />
                        <br />
                        <br />
                    </IonGrid>
                </IonContent>

            </IonPage>
        );
    }

}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CookiePolicy);

import {
    IonBackButton, IonButton, IonButtons, IonContent, IonGrid,
    IonHeader, IonInput, IonPage, IonSpinner, IonTitle,
    IonToolbar
} from '@ionic/react';
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { auth } from '../../../firebaseConfig';
import "./AuthenticationCompletion.css";
import { setUserId } from 'firebase/analytics';
import { userInfo } from 'os';
import { Trans } from 'react-i18next';

type Props = {
    history: any,
    isAuthenticated?: boolean,
    logout: () => void,
};

type State = {
    email: string | null,

    isEmailInputVisibile: boolean,
    isLoading: boolean,

    linkErrorVisible: boolean,
    expiredLinkErrorVisible: boolean,
    emailNotValidErrorVisibile: boolean,
    
};

class AuthenticationCompletion extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            email: null,

            isEmailInputVisibile: false,
            isLoading: false,

            linkErrorVisible: false,
            expiredLinkErrorVisible: false,
            emailNotValidErrorVisibile: false,
        }
    }

    componentDidMount(): void {

        if (isSignInWithEmailLink(auth, window.location.href)) {
            const email = window.localStorage.getItem('emailForSignIn');
            if (email) {
                this.setState({ isLoading: true });
                signInWithEmailLink(auth, email, window.location.href)
                    .then((result) => {
                        //console.log("[MagicLink] signin result:", result);
                        window.localStorage.removeItem("emailForSignIn");
                        this.setState({ isLoading: false });
                    })
                    .catch((error) => {
                        this.setState({ isLoading: false });
                        console.error("[AuthenticationCompletion] error signin in wih email link:", error.code, error.message);
                        if (error.code === "auth/invalid-action-code") {
                            this.setState({
                                emailNotValidErrorVisibile: false,
                                expiredLinkErrorVisible: true,
                                isEmailInputVisibile: false,
                            });
                        }
                        else if (error.code === "auth/invalid-email") {
                            this.setState({
                                emailNotValidErrorVisibile: true,
                                expiredLinkErrorVisible: false,
                                isEmailInputVisibile: false,
                            });
                        }
                    });

            }
            else {
                this.setState({ isEmailInputVisibile: true });
            }
        }

    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/authentication/magic-link-request" />
                        </IonButtons>
                        <IonTitle>
                            <Trans>Conferma la email</Trans> {/* TO BE LOCALIZED */}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid className="authenticationCompletionMainGrid">
                        {
                            this.state.isLoading &&
                            <div className="authenticationCompletionLoadingOuterDiv">
                                <div className="authenticationCompletionLoadingInnerDiv">
                                    <IonSpinner
                                        className="authenticationCompletionLoadingSpinner"
                                    />
                                    <p>
                                        <Trans>Stiamo preparando il tuo ambiente di studio</Trans> {/* TO BE LOCALIZED */}
                                    </p>
                                </div>
                            </div>
                        }

                        {
                            this.state.isEmailInputVisibile &&
                            <>
                                <div className="authenticationCompletionInsertEmailTextDiv">
                                    <div>
                                        <p>
                                            <Trans>Hai aperto la email di conferma da un dispositivo diverso da quello da cui hai richiesto l'accesso.</Trans>  {/* TO BE LOCALIZED */}
                                        </p>
                                        <p>
                                        <Trans> Inserisci la email qui sotto se vuoi accedere da questo dispositivo</Trans>
                                        </p>
                                    </div>
                                </div>


                                <div className="authenticationCompletionInsertEmailInputDiv">
                                    <div>
                                        <IonInput
                                            className="authenticationCompletionInsertEmailInput"
                                            autocomplete="email"
                                            placeholder="Email"
                                            value={this.state.email}
                                            onIonChange={e => {
                                                this.setState({ email: e.detail.value ? e.detail.value : "" });
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="authenticationCompletionConfirmEmailButtonDiv">
                                    <IonButton
                                        className="authenticationCompletionConfirmEmailButton"
                                        disabled={!this.state.email}
                                        onClick={() => {
                                            if (isSignInWithEmailLink(auth, window.location.href) && this.state.email) {
                                                this.setState({ isLoading: true });
                                                signInWithEmailLink(auth, this.state.email, window.location.href)
                                                    .then((result) => {
                                                        window.localStorage.removeItem('emailForSignIn');
                                                        //console.log("[AuthenticationCompletion] Success signin in with email link:", result);
                                                        this.setState({ isLoading: false });
                                                    })
                                                    .catch((error) => {
                                                        this.setState({ isLoading: false });
                                                        //console.log("[AuthenticationCompletion] error signin in with email link:", error, "error code:", error.code);
                                                        if (error.code === "auth/invalid-action-code") {
                                                            this.setState({
                                                                emailNotValidErrorVisibile: false,
                                                                expiredLinkErrorVisible: true,
                                                                isEmailInputVisibile: false,
                                                            });
                                                        }
                                                        else if (error.code === "auth/invalid-email") {
                                                            this.setState({
                                                                emailNotValidErrorVisibile: true,
                                                                expiredLinkErrorVisible: false,
                                                                isEmailInputVisibile: false,
                                                            });
                                                        }
                                                    });
                                            }
                                        }}
                                    >
                                        <Trans>Confermo la mia email</Trans> {/* TO BE LOCALIZED */}
                                    </IonButton>
                                </div>
                            </>
                        }

                        {
                            this.state.linkErrorVisible &&
                            <div className="authenticationCompletionErrorDiv">
                                <p>
                                    <Trans>Link non valido, richiedine uno nuovo nella</Trans> <a href="/"><Trans>pagina iniziale</Trans></a>.
                                </p>
                            </div>
                        }

                        {
                            this.state.expiredLinkErrorVisible &&
                            <div className="authenticationCompletionErrorDiv">
                                <p className='authenticationCompletionErrorText'>
                                   <Trans>Link scaduto, richiedine uno nuovo nella</Trans><a href="/"><Trans>pagina iniziale</Trans></a>.
                                </p>
                            </div>
                        }

                        {
                            this.state.emailNotValidErrorVisibile &&
                            <div className="authenticationCompletionErrorDiv">
                                <p className='authenticationCompletionErrorText'><Trans>Email diversa da quella inserita in fase di registrazione, richiedi una nuova email di accesso dalla</Trans> <a href="/"><Trans>pagina iniziale</Trans></a>.</p>
                            </div>
                        }
                    </IonGrid>
                </IonContent>

                {
                    this.props.isAuthenticated && <Redirect to="/" />
                }
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        isAuthenticated: state.auth.userData ? true : false,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationCompletion);
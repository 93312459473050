import { appConstant } from "./constants";

export const applicationActions = {
    showLoading,
    hideLoading,
    showMessage,
    hideMessage,
};

const actionCreator = {
    showLoading: (key: any) => { return { type: appConstant.SHOW_LOADING, key } },
    hideLoading: (key: any) => { return { type: appConstant.HIDE_LOADING, key } },
    showMessage: (title: string, text: string) => { return { type: appConstant.SHOW_MESSAGE, title, text } },
    hideMessage: () => { return { type: appConstant.HIDE_MESSAGE } },
}


function showLoading(key: string) {
    return (dispatch:any) => {
        dispatch(actionCreator.showLoading(key));
    };
}

function hideLoading(key: string) {
    return (dispatch:any) => {
        dispatch(actionCreator.hideLoading(key));
    };
}

function showMessage(title: string, text: string) {
    return (dispatch:any) => {
        dispatch(actionCreator.showMessage(title, text));
    };
}

function hideMessage() {
    return (dispatch:any) => {
        dispatch(actionCreator.hideMessage());
    };
}

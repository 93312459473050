import axios from "axios";
let url = "https://api.amplitude.com/2/httpapi"
let api_key = "c212f94f9c1f65a8fca4e904bd5c00c7"

export function sendEvent(event: any) {
    axios.post(url, {
        "api_key": api_key,
        "events": [event]
    })
        .then(response => {
            console.log("POSTED event", event, response);
        })
        .catch(error => {
            // console.log("ERROR posting event", error);
        })
}
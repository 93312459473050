import { SettingsTranslationsDE } from '../../modules/settings/SettingsTranslations';
import { ProfileTranslationsDE } from '../../modules/user/ProfileTranslations';
import { MenuTranslationsDE } from '../../modules/menu/MenuTranslations';
import { SketchTranslationsDE } from '../../modules/sketch/SketchTranslations';
import { FileTranslationsDE } from '../../modules/files/FileTranslations';
import { PopoverTranslationsDE } from '../../modules/popover/PopoverTranslations';
import { AuthTranslationsDE } from '../../modules/authentication/AuthTranslations';
import { OnboardingTranslationsDE } from '../../modules/onboarding/OnboardingTranslations';
import { MapsTranslationsDE } from '../../modules/maps/MapsTranslations';
import { SessionsTranslationsDE } from '../../modules/sessions/SessionsTranslations';
import { FlashcardsTranslationsDE } from '../../modules/flashcards/FlashcardsTranslations';
import { ViewerTranslationsDE } from '../../modules/summaries/ViewerTranslations';

const mergedObject = {
  ...SettingsTranslationsDE,
  ...ProfileTranslationsDE,
  ...MenuTranslationsDE,
  ...SketchTranslationsDE,
  ...FileTranslationsDE,
  ...PopoverTranslationsDE,
  ...AuthTranslationsDE,
  ...OnboardingTranslationsDE,
  ...MapsTranslationsDE,
  ...SessionsTranslationsDE,
  ...FlashcardsTranslationsDE,
  ...ViewerTranslationsDE,

};

export const de = {
  mergedObject,
}
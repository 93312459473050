export function isBackgroundColorDark(backgroundColor: string): boolean {
    // Remove any whitespace and convert to lowercase

    const rgbValues = backgroundColor.match(/\d+/g);
    let hexColor = ""
    if (rgbValues) {
        // Convert the RGB values to hexadecimal format
        hexColor = `#${(+rgbValues[0]).toString(16).padStart(2, '0')}${(+rgbValues[1]).toString(16).padStart(2, '0')}${(+rgbValues[2]).toString(16).padStart(2, '0')}`;
    }


    // Parse the hex color value into its RGB components
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);

    // Calculate the color's luminance using the relative luminance formula
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Determine whether the color is bright or dark based on the luminance value
    // You can adjust the threshold value (0.5) to suit your preference
    return luminance <= 0.5;
}

export function hasBorderColor(computedStyle: CSSStyleDeclaration): boolean {
    // Check if the borderColor property is set
    const borderColor = computedStyle.borderColor;

    // If borderColor is not "transparent" or "rgba(0, 0, 0, 0)" (fully transparent), it has a border color
    return borderColor !== 'transparent' && borderColor !== 'rgba(0, 0, 0, 0)';
}


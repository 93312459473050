import { IonButton, IonIcon, IonModal, IonPopover, IonText } from "@ionic/react";
import React from "react";
import { Trans } from "react-i18next";
import reasyLogo from "../../../assets/icons/reasyLogo.svg";
import loading from "../../../assets/icons/loading.svg";


type Props = {
    isOpen: boolean,
    isDarkMode: boolean,
    isReady: boolean,
    onDidDismiss: () => void,
    goButtonClicked: () => void,
}
type State = {

}


class MapPopover extends React.Component<Props, State> {
    constructor(props: any) {
        super(props)
    }

    render() {
        return (
            <IonModal
                backdropDismiss={false}
                isOpen={this.props.isOpen}
                className="summaryPopoverOk"
                onDidDismiss={() => {
                    this.props.onDidDismiss()
                }}
            >
                <div className="summaryPopoverOkDiv">
                    <IonIcon className="summaryReadyIcon" icon={this.props.isReady ? reasyLogo : loading}></IonIcon>
                    <div className="summaryPopoverOkTxt">
                        <IonText>
                            <Trans>
                                {this.props.isReady ?
                                    "La tua mappa è pronta!"
                                    :
                                    "Stiamo preparando la tua mappa!"
                                }
                            </Trans>
                        </IonText>
                        {!this.props.isReady &&
                            <IonText>
                                <Trans>Ti avviseremo appena sarà pronta</Trans>
                            </IonText>
                        }
                    </div>
                    <IonButton
                        className="doSummary"
                        onClick={() => {
                            this.props.goButtonClicked()


                            /* if (this.state.isSummaryOpen) {
                            if (this.state.summaryState) {
                                viewSummary(this, array)
                                document.getElementById("pageScrollControlls")!.hidden = true
                                this.setState({ isSummaryOpen: true, isKeyWordsOpen: false })
                            }
                            else this.setState({ summaryIsClicked: true })
                        }
                        else if (this.state.isKeyWordsOpen) {
                            if (this.state.keywordsState) {
                                viewSummary(this, array)
                                document.getElementById("pageScrollControlls")!.hidden = true
                                this.setState({ isKeyWordsOpen: true, isSummaryOpen: false })
                            }
                        }
                        else {
                            viewFile.call(this,array)
                            this.setState({ isSummaryOpen: false })
                            document.getElementById("pageScrollControlls")!.hidden = false
 
                        } */
                            this.setState({ summaryReadyMsg: false });
                        }}
                    >
                        <Trans>Prosegui</Trans>
                    </IonButton>
                </div>
            </IonModal>
        )
    }
}
export default MapPopover
import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { helpCircleOutline } from 'ionicons/icons';
import React from 'react';
import { Trans } from 'react-i18next';
import Lottie from 'react-lottie';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import emailSentAnimation from "../../../assets/animations/emailSent.json";
import { auth } from '../../../firebaseConfig';
import i18n from '../../../i18n';
import './ForgotPassword.css';

type Props = {
    history: any,

    isAuthenticated?: boolean,
};

type State = {
    email: string | null,
    passwordResetEmailSent: boolean,

    showErrorAlert: boolean,
    errorAlertHeaderMessage: string | undefined,
    errorAlertMessage: string | undefined,
};

class ForgotPassword extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: null,
            passwordResetEmailSent: false,

            showErrorAlert: false,
            errorAlertHeaderMessage: undefined,
            errorAlertMessage: undefined,

        }
    }

    render() {
        const animationOptions = {
            loop: false,
            autoplay: true,
            animationData: emailSentAnimation,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };
        return (
            <IonPage className='authGetStartedPage'>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>
                            <Trans>Reset password</Trans> {/* TO BE LOCALIZED */}
                        </IonTitle>
                        <IonButtons slot='start'>
                            <IonBackButton defaultHref='/authentication/email-password-authentication' />
                        </IonButtons>

                        <IonButtons slot='end'>
                            <IonButton
                                fill='clear'
                                shape='round'
                                onClick={() => {
                                    window.open('https://tech4all.ai/contatti');
                                }}
                            >
                                <IonIcon
                                    size='large'
                                    icon={helpCircleOutline}
                                />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <div className='authGetStartedContainer'>
                    <IonGrid className="forgotPasswordMainGrid">

                        {
                            !this.state.passwordResetEmailSent &&
                            <>
                                <div className="forgotPasswordTextDiv">
                                    <p className="forgotPasswordForgotText">
                                        <Trans>Recupero della password</Trans> {/* TO BE LOCALIZED */}
                                    </p>
                                </div>

                                <div className="forgotPasswordInputsDiv">
                                    <div>
                                        <IonInput
                                            id="user-email"
                                            className="getStartedInputField credentialsSignupInputField"
                                            placeholder={i18n.language === 'it' ? "La tua email" : i18n.language === 'en' ? 'Your email address' : i18n.language === 'fr' ? 'Votre e-mail' : i18n.language === 'de'? 'Deine E-Mail': 'Tu email'}
                                            type="email"
                                            autocomplete="email"
                                            inputMode="email"
                                            value={this.state.email}
                                        />

                                    </div>
                                </div>

                                <div className="forgotPasswordButtonsDiv">
                                    <IonButton
                                        className="magicLinkRequestSendEmailButton"
                                        onClick={() => {
                                           let email = document.getElementById("user-email")?.textContent
                                            if (email === '' || !email) {
                                                this.setState({
                                                    showErrorAlert: true,
                                                    errorAlertHeaderMessage: i18n.language === 'it' ? "Email non valida" : i18n.language === 'en' ? 'Invalid email' : i18n.language === 'fr' ? 'Email non valide' :i18n.language === 'es' ? 'Correo no válido':i18n.language === 'de'? "Ungültige E-Mail":"Correu electrònic no vàlid",
                                                    errorAlertMessage: i18n.language === 'it' ? "Email non registrata o sbagliata" : i18n.language === 'en' ? 'Make sure you have entered a correct email' : i18n.language === 'fr' ? "Assurez-vous d'avoir saisi un e-mail correct" :i18n.language === 'es' ? 'Asegúrate de haber introducido un correo electrónico correcto':i18n.language === 'de' ? "Stellen Sie sicher, dass Sie eine korrekte E-Mail-Adresse eingegeben haben":"Assegureu-vos que heu introduït un correu electrònic correcte",
                                                });
                                                return
                                            }

                                            sendPasswordResetEmail(auth, email)
                                                .then(() => {
                                                    console.log("[ForgotPassword] password reset email sent");
                                                    this.setState({ passwordResetEmailSent: true });
                                                })
                                                .catch(err => {
                                                    console.error("[ForgotPassword] error sending password reset email:", err);
                                                    if (err.code === "auth/invalid-email") {
                                                        this.setState({
                                                            showErrorAlert: true,
                                                            errorAlertHeaderMessage: "Email non valida", /* TO BE LOCALIZED */
                                                            errorAlertMessage: "Assicurati di aver inserito una email.", /* TO BE LOCALIZED */
                                                        });
                                                    }
                                                    else if (err.code === "auth/user-not-found") {
                                                        this.setState({
                                                            showErrorAlert: true,
                                                            errorAlertHeaderMessage: "Utente non trovato", /* TO BE LOCALIZED */
                                                            errorAlertMessage: "Verifica se sia questa la email con cui è stato creato l'account Reasy.", /* TO BE LOCALIZED */
                                                        });
                                                    }
                                                })
                                        }}
                                    >
                                        <Trans>Inviami la email di ripristino</Trans>
                                    </IonButton>
                                </div>
                            </>
                        }

                        {
                            this.state.passwordResetEmailSent &&
                            <div className="forgotPasswordResetEmailSentMainDiv">

                                <Lottie
                                    isClickToPauseDisabled={true}
                                    options={animationOptions}
                                />

                                <p className="forgotPasswordResetEmailSentText">
                                    <Trans>Email di reset password inviata.</Trans> {/* TO BE LOCALIZED */}
                                </p>
                            </div>
                        }

                    </IonGrid>

                    {
                        this.props.isAuthenticated &&
                        <Redirect to="/" />
                    }

                </div>

                <IonAlert
                    isOpen={this.state.showErrorAlert}
                    cssClass='getStartedErrorAlert'
                    onDidDismiss={() => {
                        this.setState({
                            showErrorAlert: false,
                            errorAlertHeaderMessage: undefined,
                            errorAlertMessage: undefined,
                        });
                    }}
                    keyboardClose={true}
                    header={this.state.errorAlertHeaderMessage} /* TO BE LOCALIZED */
                    message={this.state.errorAlertMessage} /* TO BE LOCALIZED */
                    buttons={["Ok"]}
                />

            </IonPage>

        );
    }

}

const mapStateToProps = (state: any) => {
    return {
        isAuthenticated: state.auth.userData ? true : false,
    }
}


const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

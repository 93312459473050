import React from "react";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, OAuthProvider, indexedDBLocalPersistence, initializeAuth, User } from "firebase/auth";
import { getDatabase, ref, get, update, push, onValue } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { connect } from "react-redux";
import { getStorage } from "@firebase/storage";
import { authActions } from "./modules/authentication/store/actions";
import { appVersion } from "./appVersion";
import { browserName, deviceType, fullBrowserVersion, isAndroid, isIOS, isMobile, isSafari, mobileModel, mobileVendor, osName, osVersion } from "react-device-detect";
import i18n from "./i18n";
import { IonLoading, isPlatform } from "@ionic/react";
import { getFirestore } from "firebase/firestore";
import { paywallActions } from "./common/Paywall/store/actions";
import { setTheme } from "./theme/theme";
import { Product } from "./common/Paywall/store/paywallUtils";
import { userServices } from "./modules/user/store/services";
import { userActions } from "./modules/user/store/actions";
import { store } from "./common/store";
import { getRemoteConfig, fetchAndActivate, getValue } from "firebase/remote-config";
import * as amplitude from "@amplitude/analytics-browser";
import { Organization, Organization_New, UserLicense } from "./modules/user/store/reducers";
import axios from "axios";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { settingsServices } from "./modules/settings/store/services";
import { backendApi } from "./common/apiService";

export const firebaseConfig = {
	apiKey: "AIzaSyD8w8BJy6jiSvqbmous-kn7XRZeKwPDRyY",
	authDomain: "t4a-reasy.firebaseapp.com",
	databaseURL: "https://t4a-reasy-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "t4a-reasy",
	storageBucket: "t4a-reasy.appspot.com",
	messagingSenderId: "888298030729",
	appId: "1:888298030729:web:2e7d5027c3c9d64bfa2858",
	measurementId: "G-HLH4N28997",
};

export const app = initializeApp(firebaseConfig);
export const remoteConfig = getRemoteConfig();
remoteConfig.settings = {
	minimumFetchIntervalMillis: 3600000, // Cache expiration time, e.g., 1 hour
	fetchTimeoutMillis: 60000, // Fetch timeout, e.g., 1 minute
};
getAnalytics(app);

export const auth = isPlatform("ios") && isPlatform('capacitor')
	? initializeAuth(app, {
		persistence: indexedDBLocalPersistence,
	})
	: getAuth();

// export const auth = getAuth();

auth.useDeviceLanguage();
export const database = getDatabase();
export const firestore = getFirestore();
export const functions = getFunctions(undefined, "europe-west1");
export const storage = getStorage();

GoogleAuth.initialize({
	clientId: (isPlatform("android") || isPlatform("desktop") || isSafari) ? "888298030729-4drthn6uc61e2b7p2coqskl19j8d58r7.apps.googleusercontent.com" : "888298030729-hoeev4m5inracd507utkvp3o5q42ccmg.apps.googleusercontent.com",
	scopes: ["email", "profile"],
	grantOfflineAccess: true
})

export const microsoftAuthProvider = new OAuthProvider('microsoft.com');

export const appleAuthProvider = new OAuthProvider('apple.com');
appleAuthProvider.addScope("email");
type Props = {
	authenticationSuccess: (userData: User) => void;
	setOrganizations: (org_data: Organization_New[] | null | 'Private') => void;

	logout: () => void;

	showPaywall: () => void;
	hidePaywall: () => void;
};

type State = {
	orgActiveProducts: Product[];
	userProducts: Product[];
	isLoading: boolean;
};

class Authentication extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			orgActiveProducts: [],
			userProducts: [],
			isLoading: false,
		};
	}
	// async  retryGetActiveProducts(maxRetries = 3, delayTime = 1000) {
	// 	let retries = 0;
	// 	let activeProducts : any[]=[];

	// 	while (retries < maxRetries && activeProducts.length === 0) {
	// 	  activeProducts = await getActiveProducts(this.state.userProducts);

	// 	  // Introduce a delay before the next retry
	// 	  if (retries < maxRetries - 1 && activeProducts.length === 0) {
	// 		await new Promise(resolve => setTimeout(resolve, delayTime));
	// 	  }
	// 	  console.log(retries)
	// 	  retries++;
	// 	}

	// 	return activeProducts;
	//   }

	// async showOrHidePaywall() {
	// 	let showPaywall: boolean = false;
	// 	await this.retryGetActiveProducts().then((response)=>{console.log(response)

	// 		showPaywall = (this.state.orgActiveProducts.length === 0) && (response.length === 0);
	// 		console.log("[Firebase] SHOW PAYWALL:", showPaywall); // TO REMOVE
	// 		console.log("HREFFF",window.location.href)
	// 		if(window.location.href.includes("/purchase"))  {
	// 			console.log("HREFFF",window.location.href)
	// 			this.props.hidePaywall();
	// 		}else if(showPaywall) {
	// 			this.props.showPaywall();
	// 		}else{
	// 			this.props.hidePaywall();
	// 		}
	// 		this.setState({isLoading:false})
	// })

	// }

	// //  loadPaywall = () => { {
	// //     auth.onAuthStateChanged(async (userData) => {
	// //       /* console.log("[firebaseConfig] User data", userData); */
	// //       if (userData !== null) {
	// //         const purchasesDbRef = ref(database, `u/${userData.uid}/p`);
	// //         onValue(
	// //           purchasesDbRef,
	// //           (data) => {
	// //             // console.log("[Firebase] on purchases data red:", data.val())
	// //             const purchasedProducts = fromProductsDbDataToProducts(data.val());
	// //             console.log("profed", purchasedProducts);
	// //             if (purchasedProducts.length != 0) {
	// //               this.setState({ userProducts: purchasedProducts }, () => {
	// //                 console.log("purchase");
	// //                 // this.showOrHidePaywall();
	// //               });
	// //             } else {
	// //               const userOrgRef = ref(database, `u/${userData.uid}/o`);
	// //               onValue(
	// //                 userOrgRef,
	// //                 async (data) => {
	// //                   console.log(
	// //                     "[Firebase] on user organization value:",
	// //                     data.val()
	// //                   );

	// //                   if (data.val()) {
	// //                     const getOrganizationActiveProducts = httpsCallable(
	// //                       functions,
	// //                       "getOrganizationActiveProducts-getOrganizationActiveProducts"
	// //                     );
	// //                     try {
	// //                       getOrganizationActiveProducts({
	// //                         organizationUuid: data.val(),
	// //                       }).then((response) => {
	// //                         const getOrganizationActiveProductsRes = response.data;
	// //                         console.log(
	// //                           "[Firebase] org active products:",
	// //                           getOrganizationActiveProductsRes
	// //                         );
	// //                         if (
	// //                           (getOrganizationActiveProductsRes as any)
	// //                             .orgActiveProducts
	// //                         ) {
	// //                           this.setState(
	// //                             {
	// //                               orgActiveProducts: (
	// //                                 getOrganizationActiveProductsRes as any
	// //                               ).orgActiveProducts as Product[],
	// //                             },
	// //                             () => {
	// //                               console.log("org");

	// //                               // this.showOrHidePaywall();
	// //                             }
	// //                           );
	// //                         } else {
	// //                           this.setState({ orgActiveProducts: [] }, () => {
	// //                             console.log("org");

	// //                             // this.showOrHidePaywall();
	// //                           });
	// //                           console.error(
	// //                             "[Firebase] error returned by getOrganizationActiveProducts cloud function:",
	// //                             getOrganizationActiveProductsRes
	// //                           );
	// //                         }
	// //                       });
	// //                     } catch (err) {
	// //                       console.error(
	// //                         "[Firebase] error calling getOrganizationActiveProducts cloud function:",
	// //                         err
	// //                       );
	// //                     }
	// //                   } else {
	// //                     this.setState({ orgActiveProducts: [] }, () => {
	// //                       console.log("org");

	// //                       // this.showOrHidePaywall()
	// //                     });
	// //                   }
	// //                 },
	// //                 (err) => {
	// //                   console.error("[Firebase] error on user organization:", err);
	// //                 }
	// //               );
	// //             }
	// //           },
	// //           (err) => {
	// //             console.error("[Firebase] error on purchases value:", err);
	// //           }
	// //         );
	// //       }
	// //     });
	// //   }}


	componentDidMount() {
		auth.onAuthStateChanged(async (userData) => {
			/* console.log("[firebaseConfig] User data", userData); */
			if (userData !== null) {

				// console.warn("REFRESH")
				// console.warn(await userData.getIdToken())

				// if (store.getState().user.organization === null) {
				/* OLD LOGIC */
				// userServices.getUserOrganization(userData.uid).then((org_data) => {
				// 	console.log("found org data: ", org_data);
				// 	this.props.setOrganization(org_data ? org_data : null);
				// });

				// }

				try {
					let bearerToken = await userData.getIdToken()

					userServices.getUserOrganizations(userData.uid, bearerToken).then((orgData) => {
						console.log("found org data: ", orgData);
						this.props.setOrganizations(orgData ? orgData : null);

					})

					settingsServices.getUserSettings(userData.uid, bearerToken).then(userSettings => {
						setTheme(userSettings.theme ? userSettings.theme : "default")
					})
					const userLanguage = navigator.language || navigator.languages[0];
					settingsServices.getPrimaryLanguage(userData.uid, bearerToken).then(response => {
						if (response === null) {
							settingsServices.setPrimaryLanguage(userData.uid, bearerToken, userLanguage)
							response = userLanguage
						}
						i18n.changeLanguage(response)

					})
				} catch (err) {
					console.error("[REQUEST ERROR]", err)
				}

				amplitude.init('c212f94f9c1f65a8fca4e904bd5c00c7', {
					userId: userData.uid,
					defaultTracking: true
				});
				this.props.authenticationSuccess(userData);
				// get user data
				// loadPaywall()

				get(ref(database, `u/${userData.uid}`))
					.then((data) => {
						if (!data.val() || !data.val()["e"]) {
							update(ref(database, `u/${userData.uid}`), {
								e: userData.email,
							});
						}

						if (!data.val() || !data.val()["l"]) {
							update(ref(database, `u/${userData.uid}`), {
								l: i18n.language,
							});
						}

						// if (data.val() && data.val()["l"]) {
						// 	localStorage.setItem('languageview', data.val()["l"])
						// 	i18n.changeLanguage(data.val()["l"]);
						// }

						// if (data.val() && data.val()["t"]) {
						// 	setTheme(data.val()["t"]);
						// }
					})
					.catch((err) => {
						//console.log("[firebase.tsx] error getting user data:", err);
					});

				const nowTimestamp = Math.round(new Date().getTime() / 1000);
				let deviceInfo = {
					t: deviceType,
					o: osName + " - " + osVersion,
					b: browserName + " - " + fullBrowserVersion,
					m: isMobile ? mobileVendor + " - " + mobileModel : null,
				};
				push(ref(database, `l/u/${userData.uid}/a`), {
					t: nowTimestamp,
					v: appVersion,
					d: deviceInfo,
				})
					.then(() => {
						//console.log("[firebase] a. push success");
					})
					.catch((err) => {
						//console.log("[firebase] error logging:", err);
					});
				
				const updateProductsState = httpsCallable(functions, "updateProductsState-updateProductsState");
				updateProductsState()
					.then((res) => {
						// console.log("[Firebase] update product state res:", res.data); // TO REMOVE
						if ((res.data as any).error) {
							console.error("[Firebase] error returned by the updateProductsState cloud function:", res.data);
						}
					})
					.catch((err) => {
						console.error("[Firebase] error calling updateProductsState cloud function:", err);
					});
				// this.setState({isLoading:true})
				// GET USER PRODUCTS
			} else {
				console.log("[Firebase] We did not authenticate.");
				this.props.logout();
				this.props.setOrganizations(null);
			}
		});
	}

	render() {
		return (
			<>
				<IonLoading isOpen={this.state.isLoading}></IonLoading>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		authenticationSuccess: (userData: User) => {
			dispatch(authActions.authenticationSuccess(userData));
		},
		setOrganizations: (org_data: Organization_New[] | null) => {
			dispatch(userActions.setOrganizations(org_data));
		},
		logout: () => {
			dispatch(authActions.logout());
		},

		showPaywall: () => {
			dispatch(paywallActions.showPaywall());
		},

		hidePaywall: () => {
			dispatch(paywallActions.hidePaywall());
		},
	};
};

export default connect(null, mapDispatchToProps)(Authentication);

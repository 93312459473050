export const MapsTranslationsEN = {
    "Salva ed esci": "Save and exit",
    "Cambia il tipo di collegamento tra i nodi": "Change node connection type",
    "Clicca sul primo nodo da collegare": "Click on the first node to be connected",
    "Clicca un altro nodo": "Click on another node",
    "Cambia Colore del Nodo": "Change node color",
    "Carica immagine": "Upload image",
    "Rimuovi immagine": "Remove image",
    "Sostituisci con un'immagine": "Replace with image",
    "Scegli un immagine da associare al nodo": "Choose an image to associate with the node",
    "Crea disegno": "Create drawing",
    "Modifica disegno": "Edit drawing",
    "Non ci sono immagini da assegnare al nodo": "No images found",
    "Aggiungi": "Add",
    "Vuoi aggiungere un etichetta al collegamento?": "Do you want to label the connection?",
    "Modifica l'etichetta": "Edit label",
    "Inserire il nome del nodo": "Insert node name",
    "Aggiorna": "Update",

    "Formattazione Mappa": "Map Formatting",
    "Riempi nodi": "Fill nodes",
    "Mostra immagini": "Show images",
    "Collegamento nodi": "Connecting nodes",
    "Tipo di collegamento": "Connection type",
    "Testi": "Texts",
    "Testo MAIUSCOLO": "Text UPPERCASE",
    "Modifica": "Editing",
    "Fatto": "Done",
    "Formatta mappa": "Format map",
    "Elimina mappa": "Delete map",
    "Cambia layout": "Change layout",
    "Mappa mentale": "Mind map",
    "Verticale": "Vertical",
    "Orizzontale": "Horizontal",

    "Riposiziona la mappa al centro dello schermo": "Reposition the map in the center of the screen",
    "Metti al centro il nodo selezionato": "Put in the center the selected node",
    "Aumenta la dimensione della mappa": "Increase the size of the map",
    "Diminuisci la dimensione della mappa": "Decrease the size of the map",
    "Modifica la mappa": "Modify the map",
    "Scarica la mappa": "Download the map",
    "Stampa la mappa": "Print the map",
    "Elimina la mappa": "Delete the map",
    "Colora l’interno dei nodi": "Color the inside of the nodes",
    "Mostra testo è immagini": "Show text and images",
    "Connetti nodi": "Connect nodes",
    "Scegli il tipo di collegamento": "Choose the type of connection",
    "Scegli il testo maiuscolo o minuscolo": "Choose uppercase or lowercase text",

    "Aggiungi un nodo": "Add a node",
    "Elimina nodo": "Delete node",
    "Fai un disegno di quello che il nodo deve rappresentare": "Draw what the node should represent",
    "Cambia colore del nodo": "Change the node's color",
    "Modifica testo all'interno del nodo": "Edit the text inside the node",
    "Diminuisci o aumenta la dimensione della mappa": "Decrease or increase the map size",
    "Dimensione Font":"Font Size"

}
export const MapsTranslationsIT = {
    "Salva ed esci": "Salva ed esci",
    "Cambia il tipo di collegamento tra i nodi": "Cambia il tipo di collegamento tra i nodi",
    "Clicca sul primo nodo da collegare": "Clicca su un nodo per collegarlo",
    "Clicca un altro nodo": "Clicca un altro nodo",
    "Cambia Colore del Nodo": "Cambia Colore del Nodo",
    "Carica immagine": "Carica immagine",
    "Rimuovi immagine": "Rimuovi immagine",
    "Sostituisci con un'immagine": "Sostituisci con un'immagine",
    "Scegli un immagine da associare al nodo": "Scegli un immagine da associare al nodo",
    "Crea disegno": "Crea disegno",
    "Modifica disegno": "Modifica disegno",
    "Non ci sono immagini da assegnare al nodo": "Non ci sono immagini da assegnare al nodo",
    "Aggiungi": "Aggiungi",
    "Vuoi aggiungere un etichetta al collegamento?": "Vuoi aggiungere un etichetta al collegamento?",
    "Modifica l'etichetta": "Modifica l'etichetta",
    "Inserire il nome del nodo": "Inserire il nome del nodo",
    "Aggiorna": "Aggiorna",

    "Formattazione Mappa": "Formattazione Mappa",
    "Riempi nodi": "Riempi nodi",
    "Mostra immagini": "Mostra immagini",
    "Collegamento nodi": "Collegamento nodi",
    "Tipo di collegamento": "Tipo di collegamento",
    "Testi": "Testi",
    "Testo MAIUSCOLO": "Testo MAIUSCOLO",
    "Modifica": "Modifica",
    "Fatto": "Fatto",
    "Formatta mappa": "Formatta mappa",
    "Elimina mappa": "Elimina mappa",
    "Cambia layout": "Cambia layout",
    "Mappa mentale": "Mappa mentale",
    "Verticale": "Verticale",
    "Orizzontale": "Orizzontale",

    "Riposiziona la mappa al centro dello schermo": "Riposiziona la mappa al centro dello schermo",
    "Metti al centro il nodo selezionato": "Metti al centro il nodo selezionato",
    "Aumenta la dimensione della mappa": "Aumenta la dimensione della mappa",
    "Diminuisci la dimensione della mappa": "Diminuisci la dimensione della mappa",
    "Modifica la mappa": "Modifica la mappa",
    "Scarica la mappa": "Scarica la mappa",
    "Stampa la mappa": "Stampa la mappa",
    "Elimina la mappa": "Elimina la mappa",
    "Colora l’interno dei nodi": "Colora l’interno dei nodi",
    "Mostra testo è immagini": "Mostra testo è immagini",
    "Connetti nodi": "Connetti nodi",
    "Scegli il tipo di collegamento": "Scegli il tipo di collegamento",
    "Scegli il testo maiuscolo o minuscolo": "Scegli il testo maiuscolo o minuscolo",

    "Aggiungi un nodo": "Aggiungi un nodo",
    "Elimina nodo": "Elimina nodo",
    "Fai un disegno di quello che il nodo deve rappresentare": "Fai un disegno di quello che il nodo deve rappresentare",
    "Cambia colore del nodo": "Cambia colore del nodo",
    "Modifica testo all'interno del nodo": "Modifica testo all'interno del nodo",
    "Dimensione Font":"Dimensione Font"

}
export const MapsTranslationsES = {
    "Salva ed esci": "Guardar y salir",
    "Cambia il tipo di collegamento tra i nodi": "Cambiar el tipo de conexión entre nodos",
    "Clicca sul primo nodo da collegare": "Haz clic en un nodo para conectarlo",
    "Clicca un altro nodo": "Haz clic en otro nodo",
    "Cambia Colore del Nodo": "Cambiar el color del nodo",
    "Carica immagine": "Subir imagen",
    "Rimuovi immagine": "Quitar la imagen",
    "Sostituisci con un'immagine": "Sustituir por una imagen",
    "Scegli un immagine da associare al nodo": "Elija una imagen para asociar al nodo",
    "Crea disegno": "Crear dibujo",
    "Modifica disegno": "Modificar el diseño",
    "Non ci sono immagini da assegnare al nodo": "No hay imágenes para asignar al nodo",
    "Aggiungi": "Añadir",
    "Vuoi aggiungere un etichetta al collegamento?": "¿Quiere añadir una etiqueta al enlace?",
    "Modifica l'etichetta": "Cambiar la etiqueta",
    "Inserire il nome del nodo": "Introduzca el nombre del nodo",
    "Aggiorna": "Actualizar",

    "Formattazione Mappa": "Formato de mapa",
    "Riempi nodi": "Rellenar nodos",
    "Mostra immagini": "Mostrar imágenes",
    "Collegamento nodi": "Enlazar nodos",
    "Tipo di collegamento": "Tipo de enlace",
    "Testi": "Textos",
    "Testo MAIUSCOLO": "Texto en MAYÚSCULAS",
    "Modifica": "Modificación",
    "Fatto": "Hecho",
    "Formatta mappa": "Formatear mapa",
    "Elimina mappa": "Eliminar mapa",
    "Cambia layout": "Cambiar disposición",
    "Mappa mentale": "Mapa mental",
    "Verticale": "Vertical",
    "Orizzontale": "Horizontal",


    "Riposiziona la mappa al centro dello schermo": "Reposiciona el mapa en el centro de la pantalla",
    "Metti al centro il nodo selezionato": "Pon en el centro el nodo seleccionado",
    "Aumenta la dimensione della mappa": "Aumenta el tamaño del mapa",
    "Diminuisci la dimensione della mappa": "Disminuye el tamaño del mapa",
    "Modifica la mappa": "Modifica el mapa",
    "Scarica la mappa": "Descarga el mapa",
    "Stampa la mappa": "Imprime el mapa",
    "Elimina la mappa": "Elimina el mapa",
    "Colora l’interno dei nod": "Colorea el interior de los nodos",
    "Mostra testo è immagini": "Muestra texto e imágenes",
    "Connetti nodi": "Conecta los nodos",
    "Scegli il tipo di collegamento": "Elige el tipo de conexión",
    "Scegli il testo maiuscolo o minuscolo": "Elige texto en mayúsculas o minúsculas",

    "Aggiungi un nodo": "Añade un nodo",
    "Elimina nodo": "Elimina el nodo",
    "Fai un disegno di quello che il nodo deve rappresentare": "Haz un dibujo de lo que debe representar el nodo",
    "Cambia colore del nodo": "Cambia el color del nodo",
    "Modifica testo all'interno del nodo": "Edita el texto dentro del nodo",
    "Diminuisci o aumenta la dimensione della mappa": "Disminuye o aumenta el tamaño del mapa",
    "Dimensione Font":"Tamaño de Fuente"


}
export const MapsTranslationsFR = {
    "Salva ed esci": "Sauvegarder et quitter",
    "Cambia il tipo di collegamento tra i nodi": "Modifier le type de connexion entre les nœuds",
    "Clicca sul primo nodo da collegare": "Cliquez sur le premier nœud à relier",
    "Clicca un altro nodo": "Cliquez sur un autre nœud",
    "Cambia Colore del Nodo": "Changer la couleur du nœud",
    "Carica immagine": "Télécharger l'image",
    "Rimuovi immagine": "Supprimer l'image",
    "Sostituisci con un'immagine": "Remplacer par une image",
    "Scegli un immagine da associare al nodo": "Choisissez une image à associer au nœud",
    "Crea disegno": "Créer le dessin",
    "Modifica disegno": "Modifier le dessin",
    "Non ci sono immagini da assegnare al nodo": "Il n'y a pas d'images à attribuer au nœud",
    "Aggiungi": "Ajouter",
    "Vuoi aggiungere un etichetta al collegamento?": "Voulez-vous ajouter une étiquette au lien?",
    "Modifica l'etichetta": "Modifier l'étiquette",
    "Inserire il nome del nodo": "Entrez le nom du nœud",
    "Aggiorna": "Mettre à jour",

    "Formattazione Mappa": "Mise en page du schéma",
    "Riempi nodi": "Remplir les nœuds ",
    "Mostra immagini": "Afficher les images",
    "Collegamento nodi": "Relier les nœuds ",
    "Tipo di collegamento": "Type de lien",
    "Testi": "Textes",
    "Testo MAIUSCOLO": "Texte en MAJUSCULES",
    "Modifica": "Modifier",
    "Fatto": "Terminé",
    "Formatta mappa": "Mettre en forme le schéma",
    "Elimina mappa": "Supprimer le schéma",
    "Cambia layout": "Changer de disposition",
    "Mappa mentale": "Carte mentale",
    "Verticale": "Vertical",
    "Orizzontale": "Horizontal",

    "Riposiziona la mappa al centro dello schermo": "Repositionne la carte au centre de l’écran",
    "Metti al centro il nodo selezionato": "Mets au centre le nœud sélectionné",
    "Aumenta la dimensione della mappa": "Augmente la taille de la carte",
    "Diminuisci la dimensione della mappa": "Diminue la taille de la carte",
    "Modifica la mappa": "Modifie la carte",
    "Scarica la mappa": "Télécharge la carte",
    "Stampa la mappa": "Imprime la carte",
    "Elimina la mappa": "Supprime la carte",
    "Colora l’interno dei nod": "Colore l’intérieur des nœuds",
    "Mostra testo è immagini": "Affiche le texte et les images",
    "Connetti nodi": "Connecte les nœuds",
    "Scegli il tipo di collegamento": "Choisis le type de lien",
    "Scegli il testo maiuscolo o minuscolo": "Choisis le texte en majuscules ou en minuscules",

    "Aggiungi un nodo": "Ajouter un nœud",
    "Elimina nodo": "Supprime le nœud",
    "Fai un disegno di quello che il nodo deve rappresentare": "Dessine ce que le nœud doit représenter",
    "Cambia colore del nodo": "Change la couleur du nœud",
    "Modifica testo all'interno del nodo": "Modifie le texte à l'intérieur du nœud",
    "Diminuisci o aumenta la dimensione della mappa": "Diminue ou augmente la taille de la carte",
    "Dimensione Font":"Taille de Police"


}
export const MapsTranslationsDE = {
    "Salva ed esci": "Speichern und verlassen",
    "Cambia il tipo di collegamento tra i nodi": "Ändere den Verbindungstyp zwischen den Knoten",
    "Clicca sul primo nodo da collegare": "Klicken Sie auf den ersten zu verbindenden Knoten",
    "Clicca un altro nodo": "Klicken Sie auf einen anderen Knoten",
    "Cambia Colore del Nodo": "Ändere die Farbe des Knotens",
    "Carica immagine": "Bild hochladen",
    "Rimuovi immagine": "Bild entfernen",
    "Sostituisci con un'immagine": "Mit einem Bild ersetzen",
    "Scegli un immagine da associare al nodo": "Wählen Sie ein Bild, um es dem Knoten zuzuordnen",
    "Crea disegno": "Zeichnung erstellen",
    "Modifica disegno": "Zeichnungsänderung",
    "Non ci sono immagini da assegnare al nodo": "Es gibt keine Bilder, die dem Knoten zugeordnet werden können",
    "Aggiungi": "Hinzufügen",
    "Vuoi aggiungere un etichetta al collegamento?": "Möchten Sie dem Link ein Label hinzufügen?",
    "Modifica l'etichetta": "Label bearbeiten",
    "Inserire il nome del nodo": "Geben Sie den Knotennamen ein",
    "Aggiorna": "Aktualisieren",

    "Formattazione Mappa": "Kartenformatierung",
    "Riempi nodi": "Fülle die Knoten",
    "Mostra immagini": "Bilder anzeigen",
    "Collegamento nodi": "Verknüpfung der Knoten",
    "Tipo di collegamento": "Verbindungstyp",
    "Testi": "Texte",
    "Testo MAIUSCOLO": "Text in GROSSBUCHSTABEN",
    "Modifica": "Bearbeiten",
    "Fatto": "Fertig",
    "Formatta mappa": "Karte formatieren",
    "Elimina mappa": "Karte löschen",
    "Cambia layout": "Layout ändern",
    "Mappa mentale": "Mindmap",
    "Verticale": "Vertikal",
    "Orizzontale": "Horizontal",

    "Riposiziona la mappa al centro dello schermo": "Zentriere die Karte auf dem Bildschirm",
    "Metti al centro il nodo selezionato": "l",
    "Aumenta la dimensione della mappa": "Vergrößere die Karte",
    "Diminuisci la dimensione della mappa": "Verkleinere die Karte",
    "Modifica la mappa": "Bearbeite die Karte",
    "Scarica la mappa": "Lade die Karte herunter",
    "Stampa la mappa": "Drucke die Karte aus",
    "Elimina la mappa": "Lösche die Karte",
    "Colora l’interno dei nodi": "Färbe das Innere der Knoten",
    "Mostra testo è immagini": "Zeige Text und Bilder",
    "Connetti nodi": "Verbinde Knoten",
    "Scegli il tipo di collegamento": "Wähle die Art der Verbindung",
    "Scegli il testo maiuscolo o minuscolo": "Wähle Groß- oder Kleinschreibung",

    "Aggiungi un nodo": "Füge einen Knoten hinzu",
    "Elimina nodo": "Lösche den Knoten",
    "Fai un disegno di quello che il nodo deve rappresentare": "Zeichne, was der Knoten darstellen soll",
    "Cambia colore del nodo": "Ändere die Farbe des Knotens",
    "Modifica testo all'interno del nodo": "Bearbeite den Text im Knoten",
    "Diminuisci o aumenta la dimensione della mappa": "Verringere oder erhöhe die Größe der Karte",
    "Dimensione Font":"Schriftgröße"

}

export const MapsTranslationsCA = {
    "Salva ed esci": "Desa i surt",
    "Cambia il tipo di collegamento tra i nodi": "Canvia el tipus de connexió entre els nodes",
    "Clicca sul primo nodo da collegare": "Feu clic al primer node a connectar",
    "Clicca un altro nodo": "Feu clic a un altre node",
    "Cambia Colore del Nodo": "Canvia el color del node",
    "Carica immagine": "Carrega imatge",
    "Rimuovi immagine": "Elimina imatge",
    "Sostituisci con un'immagine": "Substitueix per una imatge",
    "Scegli un immagine da associare al nodo": "Tria una imatge per associar al node",
    "Crea disegno": "Crea dibuix",
    "Modifica disegno": "Modifica dibuix",
    "Non ci sono immagini da assegnare al nodo": "No hi ha imatges per assignar al node",
    "Aggiungi": "Afegeix",
    "Vuoi aggiungere un etichetta al collegamento?": "Voleu afegir una etiqueta a la connexió?",
    "Modifica l'etichetta": "Modifica l'etiqueta",
    "Inserire il nome del nodo": "Introduïu el nom del node",
    "Aggiorna": "Actualitza",

    "Formattazione Mappa": "Format de mapa",
    "Riempi nodi": "Omple els nodes",
    "Mostra immagini": "Mostra imatges",
    "Collegamento nodi": "Connexió de nodes",
    "Tipo di collegamento": "Tipus de connexió",
    "Testi": "Textos",
    "Testo MAIUSCOLO": "Text MAJÚSCULES",
    "Modifica": "Modifica",
    "Fatto": "Fet",
    "Formatta mappa": "Formata el mapa",
    "Elimina mappa": "Elimina el mapa",
    "Cambia layout": "Canviar disposició",
    "Mappa mentale": "Mapa mental",
    "Verticale": "Vertical",
    "Orizzontale": "Horitzontal",

    "Riposiziona la mappa al centro dello schermo": "Reposiciona el mapa al centre de la pantalla",
    "Metti al centro il nodo selezionato": "Situa al centre el node seleccionat",
    "Aumenta la dimensione della mappa": "Augmenta la mida del mapa",
    "Diminuisci la dimensione della mappa": "Redueix la mida del mapa",
    "Modifica la mappa": "Modifica el mapa",
    "Scarica la mappa": "Descarrega el mapa",
    "Stampa la mappa": "Imprimeix el mapa",
    "Elimina la mappa": "Elimina el mapa",
    "Colora l’interno dei nodi": "Col·loca color a l'interior dels nodes",
    "Mostra testo è immagini": "Mostra text i imatges",
    "Connetti nodi": "Connecta els nodes",
    "Scegli il tipo di collegamento": "Trieu el tipus de connexió",
    "Scegli il testo maiuscolo o minuscolo": "Trieu el text en majúscules o minúscules",

    "Aggiungi un nodo": "Afegeix un node",
    "Elimina nodo": "Elimina el node",
    "Fai un disegno di quello che il nodo deve rappresentare": "Fes un dibuix del que ha de representar el node",
    "Cambia colore del nodo": "Canvia el color del node",
    "Modifica testo all'interno del nodo": "Edita el text dins del node",
    "Diminuisci o aumenta la dimensione della mappa": "Disminueix o augmenta la mida del mapa",
    "Dimensione Font":"Mida de Font"
}
export const MapsTranslationsPT = {
    "Salva ed esci": "Desa i surt",
    "Cambia il tipo di collegamento tra i nodi": "Canvia el tipus de connexió entre els nodes",
    "Clicca sul primo nodo da collegare": "Feu clic al primer node a connectar",
    "Clicca un altro nodo": "Feu clic a un altre node",
    "Cambia Colore del Nodo": "Canvia el color del node",
    "Carica immagine": "Carrega imatge",
    "Rimuovi immagine": "Elimina imatge",
    "Sostituisci con un'immagine": "Substitueix per una imatge",
    "Scegli un immagine da associare al nodo": "Tria una imatge per associar al node",
    "Crea disegno": "Crea dibuix",
    "Modifica disegno": "Modifica dibuix",
    "Non ci sono immagini da assegnare al nodo": "No hi ha imatges per assignar al node",
    "Aggiungi": "Afegeix",
    "Vuoi aggiungere un etichetta al collegamento?": "Voleu afegir una etiqueta a la connexió?",
    "Modifica l'etichetta": "Modifica l'etiqueta",
    "Inserire il nome del nodo": "Introduïu el nom del node",
    "Aggiorna": "Actualitza",

    "Formattazione Mappa": "Format de mapa",
    "Riempi nodi": "Omple els nodes",
    "Mostra immagini": "Mostra imatges",
    "Collegamento nodi": "Connexió de nodes",
    "Tipo di collegamento": "Tipus de connexió",
    "Testi": "Textos",
    "Testo MAIUSCOLO": "Text MAJÚSCULES",
    "Modifica": "Modifica",
    "Fatto": "Fet",
    "Formatta mappa": "Formata el mapa",
    "Elimina mappa": "Elimina el mapa",
    "Cambia layout": "Canviar disposició",
    "Mappa mentale": "Mapa mental",
    "Verticale": "Vertical",
    "Orizzontale": "Horitzontal",

    "Riposiziona la mappa al centro dello schermo": "Reposiciona el mapa al centre de la pantalla",
    "Metti al centro il nodo selezionato": "Situa al centre el node seleccionat",
    "Aumenta la dimensione della mappa": "Augmenta la mida del mapa",
    "Diminuisci la dimensione della mappa": "Redueix la mida del mapa",
    "Modifica la mappa": "Modifica el mapa",
    "Scarica la mappa": "Descarrega el mapa",
    "Stampa la mappa": "Imprimeix el mapa",
    "Elimina la mappa": "Elimina el mapa",
    "Colora l’interno dei nodi": "Col·loca color a l'interior dels nodes",
    "Mostra testo è immagini": "Mostra text i imatges",
    "Connetti nodi": "Connecta els nodes",
    "Scegli il tipo di collegamento": "Trieu el tipus de connexió",
    "Scegli il testo maiuscolo o minuscolo": "Trieu el text en majúscules o minúscules",

    "Aggiungi un nodo": "Afegeix un node",
    "Elimina nodo": "Elimina el node",
    "Fai un disegno di quello che il nodo deve rappresentare": "Fes un dibuix del que ha de representar el node",
    "Cambia colore del nodo": "Canvia el color del node",
    "Modifica testo all'interno del nodo": "Edita el text dins del node",
    "Diminuisci o aumenta la dimensione della mappa": "Disminueix o augmenta la mida del mapa",
    "Dimensione Font":"Tamanho da Fonte"
}
export const MapsTranslationsCZ = {
    "Salva ed esci": "Uložit a odejít",
    "Cambia il tipo di collegamento tra i nodi": "Změnit typ spojení mezi uzly",
    "Clicca sul primo nodo da collegare": "Klikněte na první uzel k propojení",
    "Clicca un altro nodo": "Klikněte na další uzel",
    "Cambia Colore del Nodo": "Změnit barvu uzlu",
    "Carica immagine": "Nahrát obrázek",
    "Rimuovi immagine": "Odebrat obrázek",
    "Sostituisci con un'immagine": "Nahradit obrázkem",
    "Scegli un immagine da associare al nodo": "Vyberte obrázek k přiřazení k uzlu",
    "Crea disegno": "Vytvořit kresbu",
    "Modifica disegno": "Upravit kresbu",
    "Non ci sono immagini da assegnare al nodo": "Neexistují žádné obrázky k přiřazení k uzlu",
    "Aggiungi": "Přidat",
    "Vuoi aggiungere un etichetta al collegamento?": "Chcete přidat popisek ke spojení?",
    "Modifica l'etichetta": "Upravit popisek",
    "Inserire il nome del nodo": "Zadat název uzlu",
    "Aggiorna": "Aktualizovat",
    "Formattazione Mappa": "Formátování mapy",
    "Riempi nodi": "Vyplnit uzly",
    "Mostra immagini": "Zobrazit obrázky",
    "Collegamento nodi": "Spojení uzlů",
    "Tipo di collegamento": "Typ spojení",
    "Testi": "Texty",
    "Testo MAIUSCOLO": "VELKÁ PÍSMENA",
    "Modifica": "Upravit",
    "Fatto": "Hotovo",
    "Formatta mappa": "Formátovat mapu",
    "Elimina mappa": "Smazat mapu",
    "Cambia layout": "Změnit rozvržení",
    "Mappa mentale": "Mentální mapa",
    "Verticale": "Vertikální",
    "Orizzontale": "Horizontální",
    "Riposiziona la mappa al centro dello schermo": "Znovu umístit mapu do středu obrazovky",
    "Metti al centro il nodo selezionato": "Umístit vybraný uzel do středu",
    "Aumenta la dimensione della mappa": "Zvětšit velikost mapy",
    "Diminuisci la dimensione della mappa": "Zmenšit velikost mapy",
    "Modifica la mappa": "Upravit mapu",
    "Scarica la mappa": "Stáhnout mapu",
    "Stampa la mappa": "Vytisknout mapu",
    "Elimina la mappa": "Smazat mapu",
    "Colora l’interno dei nodi": "Zabarvit vnitřek uzlů",
    "Mostra testo è immagini": "Zobrazit text a obrázky",
    "Connetti nodi": "Spojit uzly",
    "Scegli il tipo di collegamento": "Vyberte typ spojení",
    "Scegli il testo maiuscolo o minuscolo": "Vyberte velká nebo malá písmena",
    "Aggiungi un nodo": "Přidat uzel",
    "Elimina nodo": "Odstranit uzel",
    "Fai un disegno di quello che il nodo deve rappresentare": "Nakreslete, co má uzel znamenat",
    "Cambia colore del nodo": "Změnit barvu uzlu",
    "Modifica testo all'interno del nodo": "Upravit text uvnitř uzlu",
    "Diminuisci o aumenta la dimensione della mappa": "Zmenšit nebo zvětšit velikost mapy",
    "Dimensione Font":"Velikost Písma",
}
export const MapsTranslationsNL = {
    "Salva ed esci": "Opslaan en afsluiten",
    "Cambia il tipo di collegamento tra i nodi": "Wijzig het type verbinding tussen knooppunten",
    "Clicca sul primo nodo da collegare": "Klik op het eerste knooppunt om te verbinden",
    "Clicca un altro nodo": "Klik op een ander knooppunt",
    "Cambia Colore del Nodo": "Verander de kleur van het knooppunt",
    "Carica immagine": "Afbeelding uploaden",
    "Rimuovi immagine": "Afbeelding verwijderen",
    "Sostituisci con un'immagine": "Vervang door een afbeelding",
    "Scegli un immagine da associare al nodo": "Kies een afbeelding om aan het knooppunt te koppelen",
    "Crea disegno": "Maak een tekening",
    "Modifica disegno": "Bewerk de tekening",
    "Non ci sono immagini da assegnare al nodo": "Er zijn geen afbeeldingen om aan het knooppunt toe te wijzen",
    "Aggiungi": "Toevoegen",
    "Vuoi aggiungere un etichetta al collegamento?": "Wil je een label toevoegen aan de verbinding?",
    "Modifica l'etichetta": "Wijzig het label",
    "Inserire il nome del nodo": "Voer de naam van het knooppunt in",
    "Aggiorna": "Bijwerken",

    "Formattazione Mappa": "Mapopmaak",
    "Riempi nodi": "Vul knooppunten",
    "Mostra immagini": "Afbeeldingen tonen",
    "Collegamento nodi": "Knooppuntverbinding",
    "Tipo di collegamento": "Type verbinding",
    "Testi": "Teksten",
    "Testo MAIUSCOLO": "HOOFDLETTERS",
    "Modifica": "Bewerk",
    "Fatto": "Gedaan",
    "Formatta mappa": "Map opmaken",
    "Elimina mappa": "Map verwijderen",
    "Cambia layout": "Verander layout",
    "Mappa mentale": "Mindmap",
    "Verticale": "Verticaal",
    "Orizzontale": "Horizontaal",

    "Riposiziona la mappa al centro dello schermo": "Plaats de map terug in het midden van het scherm",
    "Metti al centro il nodo selezionato": "Zet het geselecteerde knooppunt in het midden",
    "Aumenta la dimensione della mappa": "Vergroot de grootte van de map",
    "Diminuisci la dimensione della mappa": "Verklein de grootte van de map",
    "Modifica la mappa": "Bewerk de map",
    "Scarica la mappa": "Download de map",
    "Stampa la mappa": "Print de map",
    "Elimina la mappa": "Verwijder de map",
    "Colora l’interno dei nodi": "Kleur de binnenkant van de knooppunten",
    "Mostra testo è immagini": "Toon tekst en afbeeldingen",
    "Connetti nodi": "Knooppunten verbinden",
    "Scegli il tipo di collegamento": "Kies het type verbinding",
    "Scegli il testo maiuscolo o minuscolo": "Kies hoofd- of kleine letters",

    "Aggiungi un nodo": "Voeg een knooppunt toe",
    "Elimina nodo": "Knooppunt verwijderen",
    "Fai un disegno di quello che il nodo deve rappresentare": "Maak een tekening van wat het knooppunt moet voorstellen",
    "Cambia colore del nodo": "Verander de kleur van het knooppunt",
    "Modifica testo all'interno del nodo": "Bewerk de tekst binnen het knooppunt",
    "Dimensione Font":"Lettergrootte"
}
import { isPlatform } from "@ionic/core";
import { IonButton, IonPopover } from "@ionic/react";
import React from "react";
import { connect } from 'react-redux';
import "./GetPremiumPopover.css";
import { sendEvent } from "../../../common/amplitudeUtils";
import { store } from "../../../common/store";
import i18n from "../../../i18n";
import { appVersion } from "../../../appVersion";
import { Trans } from "react-i18next";

type Props = {
    history: any,
    userUuid: string,
    isOpen: boolean,

    onGetPremiumButtonPressed?: () => void,
    onDidDismiss?: () => void,
}

type State = {
}

class GetPremiumPopover extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <IonPopover
                isOpen={this.props.isOpen}
                onDidPresent={() => {
                    sendEvent({
                        "user_id": this.props.userUuid,
                        "event_type": "License popup opened",
                        "event_properties": {
                            "user_org": store.getState().user.organization_name ? store.getState().user.organization_name : 'Private User',
                            // "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
                        },
                        "language": i18n.language,
                        "app_version": appVersion,
                        "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
                        "time": Date.now()
                    })
                }}
                onDidDismiss={() => {
                    if (this.props.onDidDismiss) {
                        this.props.onDidDismiss();
                    }
                }}
                mode="ios"
                className="getPremiumPopover"
            >
                <div className="getPremiumPopoverMainDiv">
                    <p className="getPremiumPopoverTextParagraphTitle">
                        Hey, hai terminato le risorse da generare. {/* TO BE LOCALIZED */}
                    </p>
                    <p className="getPremiumPopoverTextParagraphDesc">
                        Acquista un abbonamento per continuare a generare sessioni di studio
                    </p>
                    {/* UNIFY WITH BELOW BUTTON */}
                    {
                        <div className="getPremiumPopoverButtonDiv">
                            <IonButton
                                className="getPremiumPopoverButton"
                                onClick={() => {
                                    if (this.props.onGetPremiumButtonPressed) {
                                        this.props.onGetPremiumButtonPressed();
                                        sendEvent({
                                            "user_id": this.props.userUuid,
                                            "event_type": "License buy clicked",
                                            "event_properties": {
                                                "user_org": store.getState().user.organization_name ? store.getState().user.organization_name : 'Private User',
                                                // "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
                                            },
                                            "language": i18n.language,
                                            "app_version": appVersion,
                                            "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
                                            "time": Date.now()
                                        })
                                    }
                                }}
                            >
                                ✨ Sblocca ✨ {/* TO BE LOCALIZED */}
                            </IonButton>
                        </div>
                    }
                </div>
            </IonPopover>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetPremiumPopover);
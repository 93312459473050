
import { ref, update } from 'firebase/database';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { database } from '../firebaseConfig';
import { ReactNode } from 'react';
import { sendEvent } from './amplitudeUtils';
import i18n from '../i18n';
import { isPlatform } from '@ionic/react';
import { appVersion } from '../appVersion';


export function handleJoyrideCallback (this:any,data:any, page: string) {
    const { action, index, lifecycle, status, type, step, size } = data;
    if (action === "close") {
        this.setState({ tutorial: false, tutorialSideMenu: false });
        sendEvent({
            "user_id": this.state.userUuid,
            "event_type": "Tutorial closed",
            "event_properties": {
                "user_org": this.props.organizationName !== null ? this.props.organizationName : 'Private User',
                "page": page,
            },
            "language": i18n.language,
            "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
            "app_version": appVersion,
            "time": Date.now()
        })
    }
    if (action === 'next' && (lifecycle === 'ready' || lifecycle === 'init'))
        sendEvent({
            "user_id": this.state.userUuid,
            "event_type": "Tutorial next",
            "event_properties": {
                "user_org": this.props.organizationName !== null ? this.props.organizationName : 'Private User',
                "page": page
            },
            "language": i18n.language,
            "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
            "app_version": appVersion,
            "time": Date.now()
        })
    if (action === 'prev' && lifecycle === 'complete')
        sendEvent({
            "user_id": this.state.userUuid,
            "event_type": "Tutorial prev",
            "event_properties": {
                "user_org": this.props.organizationName !== null ? this.props.organizationName : 'Private User',
                "page": page
            },
            "language": i18n.language,
            "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
            "app_version": appVersion,
            "time": Date.now()
        })
    if (action === 'skip' && lifecycle === 'complete')
        sendEvent({
            "user_id": this.state.userUuid,
            "event_type": "Tutorial skipped",
            "event_properties": {
                "user_org": this.props.organizationName !== null ? this.props.organizationName : 'Private User',
                "steps_watched": index + 1 + "/" + size,
                "page": page
            },
            "language": i18n.language,
            "platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
            "app_version": appVersion,
            "time": Date.now()
        })

  if(action === "close"){
    this.setState({ tutorial: false,tutorialSideMenu:false });
    
  }
  
  if([EVENTS.TOUR_START,EVENTS.TOOLTIP].includes(type)){
    if(document.getElementsByClassName("settingsHeaderTablet")[0])
      (document.getElementsByClassName("settingsHeaderTablet")[0] as HTMLElement).style.display="none"
  }
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour 
      this.setState({ stepIndexSideMenu: index + (action === ACTIONS.PREV ? -1 : 1) });
      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    }else if([STATUS.SKIPPED].includes(status)){
      if(document.getElementsByClassName("settingsHeaderTablet")[0])
        (document.getElementsByClassName("settingsHeaderTablet")[0] as HTMLElement).style.display="flex"
      
      this.setState({ tutorial: false,tutorialSideMenu:false });
      
    //   if (this.state.userUuid) {
    //     update(ref(database, `u/${this.state.userUuid}/uc`), {
    //         st: false,
    //     })
    //         .catch(err => {
    //             console.error("[Profile] error updating user choice on tutorial:", err);
    //         });
    // }
    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ tutorial: false,tutorialSideMenu:false });
      if(document.getElementsByClassName("settingsHeaderTablet")[0])
        (document.getElementsByClassName("settingsHeaderTablet")[0] as HTMLElement).style.display="flex"

    }

    // console.groupCollapsed(type);
    // console.log("ssssss",data); //eslint-disable-line no-console
    // console.groupEnd();
  };

  export interface Step {
    target: string;
    content:ReactNode;
    disableBeacon: boolean,
    placement: "auto" | "center" | "top" | "top-start" | "top-end" | "bottom" | "bottom-start" | "bottom-end" | "left" | "left-start" | "left-end" | "right" | "right-start" | "right-end",
    disableScrolling?:boolean,
    disableScrollParentFix?:boolean,
    name?:string
  }

  export function updateChoice(this:any,page:string) {
    const options = [
      'tutorialFileManager',
      'tutorialSettings',
      'tutorialMapModal',
      'tutorialMindMap',
      'tutorialSideMap',
      'tutorialSideMenu',
      'tutorialFileViewer',
      'tutorialModifyNodes',
      'ALL'
    ];
    const fieldMappings: { [key: string]: string } = {
      tutorialFileManager: 'tFM',
      tutorialSettings: 'tS',
      tutorialMapModal: 'tM',
      tutorialMindMap: 'tMM',
      tutorialSideMap: 'tSMM',
      tutorialSideMenu: 'tSM',
      tutorialFileViewer:'tFV',
      tutorialModifyNodes:'tMN',
      ALL:"st",
    };
    console.log(page)
    if (this.state.userUuid) {
      if (options.includes(page)) {
        const fieldToUpdate = fieldMappings[page];
        console.log(fieldToUpdate)
        console.log(this.state.userUuid)

        update(ref(database, `u/${this.state.userUuid}/uc/t`), {
            [fieldToUpdate]: false,
        })
            .catch(err => {
                console.error("[Profile] error updating user choice on tutorial:", err);
            });
    }}
  }
import { User } from "../../user/store/reducers";
import { purchasesConstants } from "./constants";

export interface PurchasesStore {
	isLoadingPayment: boolean,
	isProcessingPayment: boolean,
	paymentSuccess: boolean,
	paymentCanceled: boolean,
	paymentError: boolean,
}
export interface ResourceLimit {
	id: string,
	plan_id: string,
	resource_type: string,
	quantity: number | null,
}
export interface UserSubscription {
	id: string,
	user_id: string,
	plan_id: string,
	created_at: string,
	start_at: string,
	end_at: string,
	payment_source: string,
	auto_renew: number,
	external_subscription_id: string
}
export interface SubscriptionPlan {
	id: string,
	name: string,
	description: string | null,
	limits: ResourceLimit[],
	billing_period: "monthly" | "yearly"

}
export interface StripeCheckoutSession {
	session_id: string,
	checkout_url: string,
}
const initialState: PurchasesStore = {
	isLoadingPayment: false,
	isProcessingPayment: false,
	paymentSuccess: false,
	paymentCanceled: false,
	paymentError: false,
}


const Purchases = (state = initialState, action: any): PurchasesStore => {

	switch (action.type) {

		case purchasesConstants.PURCHASE_LOADING:
			return {
				...state,
				isLoadingPayment: action.loading,
				isProcessingPayment: false,
				paymentCanceled: false,
			}

		case purchasesConstants.PURCHASE_PROCESSING:
			return {
				...state,
				isLoadingPayment: false,
				isProcessingPayment: action.processing,
			}

		case purchasesConstants.PURCHASE_SUCCESS:
			return {
				...state,
				isLoadingPayment: false,
				isProcessingPayment: false,
				paymentCanceled: false,
				paymentError: false,
				paymentSuccess: true,
			}

		case purchasesConstants.PURCHASE_CANCELED:
			return {
				...state,
				isLoadingPayment: false,
				isProcessingPayment: false,
				paymentCanceled: true,
			}

		case purchasesConstants.PURCHASE_ERROR:
			return {
				...state,
				isLoadingPayment: false,
				isProcessingPayment: false,
				paymentError: true,
			}

		case purchasesConstants.CLEAR:
			return initialState;

		default:
			return state
	}
}

export default Purchases;

import { SettingsTranslationsNL } from '../../modules/settings/SettingsTranslations'
import { ProfileTranslationsNL } from '../../modules/user/ProfileTranslations'
import { MenuTranslationsNL } from '../../modules/menu/MenuTranslations'
import { SketchTranslationsNL } from '../../modules/sketch/SketchTranslations';
import { FileTranslationsNL } from '../../modules/files/FileTranslations';
import { PopoverTranslationsNL } from '../../modules/popover/PopoverTranslations';
import { AuthTranslationsNL } from '../../modules/authentication/AuthTranslations';
import { OnboardingTranslationsNL } from '../../modules/onboarding/OnboardingTranslations';
import { MapsTranslationsNL } from '../../modules/maps/MapsTranslations';
import { SessionsTranslationsNL } from '../../modules/sessions/SessionsTranslations';
import { FlashcardsTranslationsNL } from '../../modules/flashcards/FlashcardsTranslations';
import { ViewerTranslationsNL } from '../../modules/summaries/ViewerTranslations';

const mergedObject = {
  ...SettingsTranslationsNL,
  ...ProfileTranslationsNL,
  ...MenuTranslationsNL,
  ...SketchTranslationsNL,
  ...FileTranslationsNL,
  ...PopoverTranslationsNL,
  ...AuthTranslationsNL,
  ...OnboardingTranslationsNL,
  ...MapsTranslationsNL,
  ...SessionsTranslationsNL,
  ...FlashcardsTranslationsNL,
  ...ViewerTranslationsNL,
};

export const nl = {
  mergedObject,
}
export const FlashcardsTranslationsEN = {
    "Clicca sulla carta per vedere la risposta": "Click on the card to read the answer",
    "di": "of",
    "Torna indietro": "Go back",
    "Stiamo preparando il tuo materiale a tema:": "We are preparing your material on the topic:",
    "Modifica": "Edit",
    "Visualizza": "View"

}
export const FlashcardsTranslationsIT = {
    "Clicca sulla carta per vedere la risposta": "Clicca sulla carta per vedere la risposta",
    "di": "di",
    "Torna indietro": "Torna indietro",
    "Stiamo preparando il tuo materiale a tema:": "Stiamo preparando il tuo materiale a tema:",
    "Modifica": "Modifica",
    "Visualizza": "Visualizza"

}
export const FlashcardsTranslationsES = {
    "Clicca sulla carta per vedere la risposta": "Haz clic en la tarjeta para ver la respuesta",
    "di": "de",
    "Torna indietro": "Volver",
    "Stiamo preparando il tuo materiale a tema:": "Estamos preparando su material temático:",
    "Modifica": "Editar",
    "Visualizza": "Ver"
}
export const FlashcardsTranslationsFR = {
    "Clicca sulla carta per vedere la risposta": "Cliquez sur la carte pour voir la réponse",
    "di": "sur",
    "Torna indietro": "Retour",
    "Stiamo preparando il tuo materiale a tema:": "Nous préparons votre matériel thématique:",
    "Modifica": "Editer",
    "Visualizza": "Voir"
}
export const FlashcardsTranslationsDE = {
    "Clicca sulla carta per vedere la risposta": "Klicken Sie auf die Karte, um die Antwort zu sehen",
    "di": "von",
    "Torna indietro": "Zurückgehen",
    "Stiamo preparando il tuo materiale a tema:": "Wir bereiten Ihr Themenmaterial vor:",
    "Modifica": "Bearbeiten",
    "Visualizza": "Siehe"

}
export const FlashcardsTranslationsCA = {
    "Clicca sulla carta per vedere la risposta": "Feu clic a la targeta per veure la resposta",
    "di": "de",
    "Torna indietro": "Torna enrere",
    "Stiamo preparando il tuo materiale a tema:": "Estem preparant el teu material temàtic:",
    "Modifica": "Edita",
    "Visualizza": "Veure"

}
export const FlashcardsTranslationsPT = {
    "Clicca sulla carta per vedere la risposta": "Clique no cartão para ver a resposta",
    "di": "de",
    "Torna indietro": "Voltar atrás",
    "Stiamo preparando il tuo materiale a tema:": "Estamos a preparar o seu material temático:",
    "Modifica": "Editar",
    "Visualizza": "Ver"
}
export const FlashcardsTranslationsCZ = {
    "Clicca sulla carta per vedere la risposta": "Kliknutím na kartu zobrazíte odpověď",
    "di": "z",
    "Torna indietro": "Vraťte se zpět",
    "Stiamo preparando il tuo materiale a tema:": "Připravujeme váš tematický materiál:",
    "Modifica": "Upravit",
    "Visualizza": "Zobrazit"
}
export const FlashcardsTranslationsNL = {
    "Clicca sulla carta per vedere la risposta": "Klik op de kaart om het antwoord te zien",
    "di": "van",
    "Torna indietro": "Terug",
    "Stiamo preparando il tuo materiale a tema:": "We bereiden je themamateriaal voor:",
    "Modifica": "Bewerk",
    "Visualizza": "Bekijk"

}

import { Dispatch } from 'redux';
import { paywallConstants } from './constants';

export const paywallActions = {
    showPaywall,
    hidePaywall,
};

export const actionCreator = {
    showPaywall: () => { return { type: paywallConstants.SHOW_PAYWALL }},
    hidePaywall: () => { return { type: paywallConstants.HIDE_PAYWALL }},
    
};

function showPaywall() {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.showPaywall());
    };
}

function hidePaywall() {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.hidePaywall());
    };
}
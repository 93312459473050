import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { deleteDirectoryContents, makeId } from "../../files/store/services";
import { generateErrorToast } from "../../files/utils/utils";
import { auth, firestore, storage } from "../../../firebaseConfig";
import { store } from "../../../common/store";
import { sendEvent } from "../../../common/amplitudeUtils";
import i18n from "../../../i18n";
import { appVersion } from "../../../appVersion";
import { isPlatform } from "@ionic/react";
import { Argument, FileData, Old_Resource, Session } from "./reducers";
import { arrayUnion, collection, doc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, where } from "firebase/firestore";
import { filesServices } from "../../files/store/services";
import { getTTS } from "../../popover/common/ttsServices";
import { ttsServices } from "../../tts/store/services";
import imageCompression from "browser-image-compression";
import { backendApi } from "../../../common/apiService";
import { Audiobook } from "../../audiobooks/store/reducers";
import { s3FileUpload, s3UploadLinkRequest } from "./utils/fileUpload";
import { createStudySession, deleteSingleStudySession, getSingleStudySession, getStudySessions } from "./utils/studySessions";
import { createAudiobook, deleteAudiobook } from "./utils/audiobook";
import { createConceptualMap, deleteConceptualMap } from "./utils/conceptualMaps";
import { createSummary, deleteSummary, updateSummary } from "./utils/summaries";
import { createFlashcardsCollection, deleteSingleFlashcardsCollection } from "./utils/flashcards";
import { createKeywordsCollection, deleteKeywordsCollections, getKeywordsFromCollection } from "./utils/keywords";
import { removeResourceFromLocalStorage } from "../utils/utils";

export const argumentServices = {
	uploadFile,
	createArgumentFromString,
	getUserArguments,
	getUserSessions,
	//RESOURCES
	//NEW BACKEND STUDY SESSIONS
	deleteSingleStudySession,
	//NEW BACKEND MAPS
	createConceptualMap,
	deleteConceptualMap,
	//NEW BACKEND SUMMARIES
	createSummary,
	updateSummary,
	deleteSummary,
	//NEW BACKEND AUDIOBOOK
	createAudiobook,
	deleteAudiobook,
	//NEW BACKEND FLASHCARDS
	createFlashcardsCollection,
	deleteSingleFlashcardsCollection,
	//NEW BACKEND KEYWORDS
	getKeywordsFromCollection,
	deleteKeywordsCollections,
	createKeywordsCollection,
	//NEW BACKEND FILES
	getUserFiles,
	getUserFile
};

async function createArgumentFromString(
	this: any,
	userArgument: string,
	userUuid: string,
	token: string,
	specificResource?: string,
	existingArgument?: Argument
) {
	return new Promise<any>(async (resolve, reject) => {
		try {
			// Create study session
			const response = await createStudySession(token, this.state.userLanguage, null, null, userArgument);
			if (response) {
				const resources = await createResources.call(this, response)
				if (resources) {
					sendEvent({
						user_id: this.state.userUuid,
						event_type: "Argument generated from string",
						event_properties: {
							user_org: store.getState().user.organization_name || "Private User",
							resources: response.resources,
							argument_title: response.title,
						},
						language: i18n.language,
						app_version: appVersion,
						platform: isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
						time: Date.now(),
					});
					resolve(response)
				}
				else
					resolve(false)
			} else {
				resolve(false);
			}
		} catch (error) {
			console.error("Error creating argument from string:", error);
			reject(error);
		}
	});
}

const compressFile = async (file: File) => {
	try {
		// Check the file type
		const fileType = file instanceof File ? file.type : "";

		// Only compress if the file is an image
		if (fileType.startsWith("image/")) {
			const options = {
				maxSizeMB: 1, // Maximum size in MB
				maxWidthOrHeight: 1920, // Maximum width or height
			};

			const compressedFile = await imageCompression(file, options);
			return compressedFile;
		} else {
			return file; // Return the original file if it's not an image
		}
	} catch (error) {
		console.error("Compression error:", error);
		return file; // If compression fails, return the original file
	}
};

function uploadFile(
	this: any,
	file: File | Blob,
	userUuid: string,
	token: string,
	chooserFileName?: string,
	specificResource?: string,
	existingArgument?: Argument
) {
	return new Promise(async (resolve, reject) => {
		let fileName: string | "" = "";
		let fileToUpload: File | Blob = file;
		if (file instanceof File) {
			// console.log("FILE NAME", file.name); // TO REMOVE
			// console.log("extension:", (file as File).name.split(".").pop()); // TO REMOVE
			fileToUpload = await compressFile(file);
			fileName = file.name.trim();
		} else if (file instanceof Blob && chooserFileName) {
			fileName = chooserFileName;
		}

		s3UploadLinkRequest(file.size, fileName, file.type, token, userUuid)
			.then((fileData) => {
				console.log(fileData)
				if (fileData?.presigned_url) {
					const s3Link = fileData.presigned_url;
					const file_id = fileData.file_id;
					s3FileUpload(file, s3Link, file_id).then((response) => {
						console.log(file_id)
						let availableResources = this.state.availableResources;
						console.log("[GENERATING RESOURCES] received available res: ", availableResources);

						// Define resource types in priority order
						const resourceTypes = ['map', 'summary', 'keywords', 'tts', 'flashcards'];

						// Find first available resource
						const firstAvailableResource = resourceTypes.find(type => 
						availableResources.some((res: { resource_type: string; available: number | null; }) => 
							res.resource_type === type && (res.available &&  res.available > 0 || res.available === null)
						)
						);
						switch (firstAvailableResource) {
							case 'map':
							  this.setState({isLoadingMap:true})
							  break;
							case 'summary':
								this.setState({isLoadingSummary:true})
								break;
							case 'keywords':
								this.setState({isLoadingSummary:true})
								break;
							case 'tts':
								this.setState({isLoadingAudiobool:true})
								break;
							case 'flashcards':
								this.setState({isLoadingFlashcards:true})
								break;
						  }
						  
						if (response)
							createStudySession(token, this.state.userLanguage, file_id).then(async (response) => {
								if (response) {
									const studySession: Session = response;
									console.log(studySession);
									if (studySession) {
										const resources = await createResources.call(this, response)
										if (resources) {
											resolve(response)
										}
										else
											resolve(false)
										sendEvent({
											"user_id": this.state.userUuid,
											"event_type": "Argument generated from File",
											"event_properties": {
												"user_org": store.getState().user.organization_name ? store.getState().user.organization_name : "Private User",
												// "document_uuid": file.uuid, // WE CANNOT GET THE FILE UUID SINCE IT IS GIVEN BY THE onFileUpload CLOUD FUNCTION
												"resources": studySession.resources,
												"argument_title": studySession.title,
											},
											"language": i18n.language,
											"app_version": appVersion,
											"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
											"time": Date.now(),
										});
										resolve(studySession);
									} else resolve(false);
								}
							});
					});
				} else {
					console.error("Failed to get file link.");
				}
			})
			.catch((error) => {
				console.error("Error during S3 upload link request:", error);
			});
	});
}

function createResources(this: any, studySession: Session): Promise<boolean> {
	return new Promise(async (resolve, reject) => {
		
		let prompt = "";
		let title = "";
		let content = "";

		// Set prompt, title, and content based on session details
		if (!studySession.prompt && studySession.title) {
			prompt = studySession.title;
			title = studySession.title;
			content = studySession.title;
		} else if (studySession.prompt && studySession.title) {
			prompt = studySession.prompt;
			title = studySession.title;
			content = studySession.prompt;
		}
		let availableResources = this.state.availableResources
		console.log("[GENERATING RESOURCES] received available res: ", availableResources);
		let isMapAvailable: boolean = availableResources.filter((res: any) => res.resource_type === 'map' && (res.available > 0 || res.available === null)).length > 0
		let isSummaryAvailable: boolean = availableResources.filter((res: any) => res.resource_type === 'summary' && (res.available > 0 || res.available === null)).length > 0
		let isKeywordsAvailable: boolean = availableResources.filter((res: any) => res.resource_type === 'keywords' && (res.available > 0 || res.available === null)).length > 0
		let isTtsAvailable: boolean = availableResources.filter((res: any) => res.resource_type === 'tts' && (res.available > 0 || res.available === null)).length > 0
		let isFlashcardsAvailable: boolean = availableResources.filter((res: any) => res.resource_type === 'flashcards' && (res.available > 0 || res.available === null)).length > 0
		console.log("[GENERATING RESOURCES] isMapAvailable: ", isMapAvailable);

		// Create a local array to track loading state of each task (5 tasks)
		let isLoadingTasks: boolean[] = [];

		// Functions to update loading states
		const updateTaskStatus = (index: number) => {
			isLoadingTasks[index] = false;
			if (isLoadingTasks.every((status) => !status)) {
				// All tasks have been completed
				console.log("All tasks are finished and states are false.");
				this.setState({ isProcessingResources: false });
				resolve(true); // Resolve promise with true when all tasks are finished
			}
		};
		// * Start creating various resources based on the available ones
		// * Always give priority to map generation
		let generatedResource: string | null = null

		if (isMapAvailable) {
			console.log("[GENERATING RESOURCES] map is available");
			this.setState({ isLoadingMap: true })
			isLoadingTasks = [true]
			await createConceptualMap(this.state.bearerToken, this.state.userLanguage, studySession.id, prompt).then((response) => {
				console.log(response)
				if(response){
					updateTaskStatus(0);
					generatedResource = 'map'
					this.setState({
						isLoadingMap: false,
					});
				}
			}).catch(reject); // Update task status
		}
		else if (isSummaryAvailable) {
			this.setState({ isLoadingSummary: true })
			isLoadingTasks = [true]
			await createSummary(this.state.bearerToken, this.state.userLanguage, studySession.id, content, prompt, title).then((response) => {
				if(response){
					updateTaskStatus(0);
					generatedResource = 'summary'
					this.setState({
						isLoadingSummary: false,
					});
				}
			}).catch(reject); // Update task status
		}
		else if (isKeywordsAvailable) {
			this.setState({ isLoadingKeywords: true })
			isLoadingTasks = [true]
			await createKeywordsCollection(this.state.bearerToken, this.state.userLanguage, studySession.id, prompt, title).then((response) => {
				if(response){
					updateTaskStatus(0);
					generatedResource = 'keywords'
					this.setState({
						isLoadingKeywords: false,
					});
				}
			}).catch(reject); // Update task status
		}
		else if (isTtsAvailable) {
			this.setState({ isLoadingAudiobook: true })
			isLoadingTasks = [true]
			await createAudiobook(this.state.bearerToken, studySession.id, prompt, this.state.userLanguage).then((response) => {
				if(response){
					updateTaskStatus(0);
					generatedResource = 'tts'
					this.setState({
						isLoadingAudiobook: false,
					});
				}
			}).catch(reject); // Update task status
		}
		else if (isFlashcardsAvailable) {
			this.setState({ isLoadingFlashcards: true })
			isLoadingTasks = [true]
			await createFlashcardsCollection(this.state.bearerToken, this.state.userLanguage, studySession.id, prompt, title).then((response) => {
				if(response){
					updateTaskStatus(0);
					generatedResource = 'flashcards'
					this.setState({
						isLoadingFlashcards: false,
					});
				}
			}).catch(reject); // Update task status
		}
		else {
			console.error("No available resources to create.");
			//* SHOW PAYWALL
			this.setState({ showPremiumPopover: true })
			reject
		}

		if (generatedResource)
			removeResourceFromLocalStorage(generatedResource)
	});
}


export function deleteSessionElement(resourceId: string, token: string, resourceToRemove: string): Promise<boolean> {
    console.log(resourceToRemove);
    
    return new Promise(async (resolve) => {
        try {
            let success = false;
            
            switch (resourceToRemove) {
                case "maps":
                    success = await deleteConceptualMap(resourceId, token)
                        .then(() => true)
                        .catch((error) => {
                            console.log(`Error deleting ${resourceToRemove}:`, error);
                            return false;
                        });
                    break;
                case "summaries":
                    success = await deleteSummary(resourceId, token)
                        .then(() => true)
                        .catch((error) => {
                            console.log(`Error deleting ${resourceToRemove}:`, error);
                            return false;
                        });
                    break;
                case "keywords_collections":
                    success = await deleteKeywordsCollections(resourceId, token)
                        .then(() => true)
                        .catch((error) => {
                            console.log(`Error deleting ${resourceToRemove}:`, error);
                            return false;
                        });
                    break;
                case "flashcards_collections":
                    success = await deleteSingleFlashcardsCollection(resourceId, token)
                        .then(() => true)
                        .catch((error) => {
                            console.log(`Error deleting ${resourceToRemove}:`, error);
                            return false;
                        });
                    break;
                case "audiobooks":
                    success = await deleteAudiobook(resourceId, token)
                        .then(() => true)
                        .catch((error) => {
                            console.log(`Error deleting ${resourceToRemove}:`, error);
                            return false;
                        });
                    break;
                default:
                    console.log("There is no resource id");
                    success = false;
            }

            document.getElementById(`delete-spinner-${resourceToRemove}`)!.style.display = "none";
            document.getElementById(`card-${resourceToRemove}`)?.classList.remove("wobbleAnimation");
            
            resolve(success);
            
        } catch (error) {
            console.log(`Error deleting ${resourceToRemove}:`, error);
            document.getElementById(`delete-spinner-${resourceToRemove}`)!.style.display = "none";
            document.getElementById(`card-${resourceToRemove}`)?.classList.remove("wobbleAnimation");
            resolve(false);
        }
    });
}

function getUserArguments(session_id: string, token: string) {
	return new Promise<Session[]>(async (resolve, reject) => {
		let userArguments: Session[] = [];
		let config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		if (session_id) {
			getSingleStudySession(session_id, config)
				.then((response) => {
					if (response) {
						userArguments = [response];
						resolve(userArguments);
					} else {
						reject(new Error(`Request failed with status ${response}`));
					}
				})
				.catch((error) => {
					console.error("Error fetching specific session:", error);
					reject(error);
				});
		}
	});
}

/* NEW BACKEND ENDPOINT */
function getUserSessions(userUuid: string, token: string, specificSessionId?: string) {
	return new Promise<Session[]>(async (resolve, reject) => {
		let config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let userSessions: Session[] = [];
		if (specificSessionId) {
			getSingleStudySession(specificSessionId, config)
				.then((response) => {
					if (response) {
						userSessions = [response];
						resolve(userSessions);
					} else {
						reject(new Error(`Request failed with status ${response}`));
					}
				})
				.catch((error) => {
					console.error("Error fetching specific session:", error);
					reject(error);
				});
		} else {
			getStudySessions(config)
				.then((response) => {
					console.log("[getStudySessions] sessions fetch correctly");
					userSessions = response;
					if (response.length > 0) resolve(userSessions);
					else reject(console.log("Non ci sono sessioni "));
				})
				.catch((error) => {
					console.error("Error fetching user sessions:", error);
					reject(error);
				});
		}
	});
}

function getUserFiles(token: string, user_id: string) {
	return new Promise<FileData[]>(async (resolve, reject) => {
		let config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let userFiles: FileData[] = [];
		backendApi
			.get(`/users/${user_id}/files`, config)
			.then((response) => {
				if (response.status === 200) {
					userFiles = response.data;
					resolve(userFiles);
				} else {
					reject(new Error(`[getUserFiles]Request failed with status ${response.status}`));
				}
			})
			.catch((error) => {
				console.error(`[getUserFiles]Error fetching files `, error);
				reject(error);
			});
	});
}
function getUserFile(token: string, user_id: string, file_id: string) {
	return new Promise<FileData>(async (resolve, reject) => {
		let config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let userFiles: FileData;
		backendApi
			.get(`/users/${user_id}/files/${file_id}`, config)
			.then((response) => {
				if (response.status === 200) {
					console.log(response.data)
					userFiles = response.data;
					resolve(userFiles);
				} else {
					reject(new Error(`[getUserFiles]Request failed with status ${response.status}`));
				}
			})
			.catch((error) => {
				console.error(`[getUserFiles]Error fetching files `, error);
				reject(error);
			});
	});
}

export function setLocalStorageSessionState(state: boolean, argID: string, docType: any, sessionType: string, filename?: string) {
	// Retrieve the current array of states from localStorage, or initialize it as an empty array if it doesn't exist
	let session = sessionType === "creation" ? "creationSessionState" : "sessionState";
	let storedStates = localStorage.getItem(session);
	let statesArray: any = [];
	try {
		statesArray = storedStates ? JSON.parse(storedStates) : [];
		if (!Array.isArray(statesArray)) {
			statesArray = []; // Ensure statesArray is an array
		}
	} catch (e) {
		statesArray = []; // Fallback to an empty array in case of error
	}

	// Function to check if an entry matches the docType and argID
	const isMatch = (item: any, docType: string, argID: string) => {
		return item[2] === docType.toLowerCase() && item[1] === argID;
	};

	// Function to add or update the state for a given docType
	const updateStateForDocType = (docType: any) => {
		let newState = [];
		if (sessionType === "creation") {
			newState = [state, argID, docType.toLowerCase(), filename];
		} else {
			newState = [state, argID, docType.toLowerCase()];
		}

		const index = statesArray.findIndex((item: any) => isMatch(item, docType, argID));

		if (index !== -1) {
			// If the entry exists, update only the state value
			statesArray[index][0] = newState[0];
		} else {
			// If the entry does not exist, add the new state to the array of states
			statesArray.push(newState);
		}
	};
	if (docType instanceof Set) {
		docType = Array.from(docType);
	}
	// Handle single string or array of strings for docType
	if (Array.isArray(docType)) {
		docType.forEach((dt) => updateStateForDocType(dt));
	} else {
		updateStateForDocType(docType);
	}

	// Store the updated array back in localStorage
	localStorage.setItem(session, JSON.stringify(statesArray));
}

export function getLocalStorageSessionState(sessionType: string, argID?: string) {
	// Retrieve the array of states from localStorage
	let docTypes = ["maps", "summaries", "keywords_collections", "audiobooks", "flashcards_collections"];
	let session = sessionType === "creation" ? "creationSessionState" : "sessionState";
	let storedStates = localStorage.getItem(session);
	let statesArray;

	try {
		statesArray = storedStates ? JSON.parse(storedStates) : [];
		if (!Array.isArray(statesArray)) {
			statesArray = []; // Ensure statesArray is an array
		}
	} catch (e) {
		statesArray = []; // Fallback to an empty array in case of error
	}

	// Array to hold matching states
	let matchingStates = [];

	// Check for true state with the specified argID
	for (let state of statesArray) {
		if (state[0] === true && state[1] === argID && docTypes.includes(state[2])) {
			if (sessionType === "creation") {
				matchingStates.push([state[0], state[1], state[2], state[3]]);
			} else {
				matchingStates.push(state[2]);
			}
		}
	}

	return matchingStates;
}

import { PushNotifications } from "@capacitor/push-notifications";

import { Chooser } from "@ionic-native/chooser";
// import { Device, DeviceOriginal } from "@ionic-native/device";
import { Device } from "@capacitor/device";
import {
	IonAccordion,
	IonAccordionGroup,
	IonAlert,
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonContent,
	IonFab,
	IonFabButton,
	IonGrid,
	IonHeader,
	IonIcon,
	IonImg,
	IonInput,
	IonItem,
	IonItemDivider,
	IonLabel,
	IonList,
	IonLoading,
	IonMenuButton,
	IonPage,
	IonPopover,
	IonProgressBar,
	IonRadio,
	IonRadioGroup,
	IonRow,
	IonSelect,
	IonSpinner,
	IonText,
	IonTitle,
	IonToast,
	IonToolbar,
	isPlatform,
} from "@ionic/react";
import { onValue, ref } from "firebase/database";
import {
	DocumentData,
	QuerySnapshot,
	Unsubscribe,
	addDoc,
	collection,
	doc,
	onSnapshot,
	orderBy,
	query,
	updateDoc,
	where
} from "firebase/firestore";
import { add, checkmarkOutline, chevronForward, chevronForwardOutline, ellipsisHorizontal, gridOutline, helpCircleOutline, helpOutline, home, listOutline, notificationsCircle, notificationsOutline, notificationsSharp, personOutline, trash } from "ionicons/icons";
import React from "react";
import { isMobile } from "react-device-detect";
import Dropzone from "react-dropzone";
import { Trans } from "react-i18next";
import Joyride from "react-joyride";
import { connect } from "react-redux";
import { appVersion } from "../../../appVersion";
import addFileButton from "../../../assets/icons/add_file_button.svg";
import addFileButtonIcon from "../../../assets/icons/add_file_button_icon.svg";
import createBlankMap from "../../../assets/icons/create_blank_map.svg";
import createBlankMapIcon from "../../../assets/icons/create_blank_map_icon.svg";
import notificationsImage from "../../../assets/icons/notify.svg";
import pdfToMap from "../../../assets/icons/pdf_to_map.svg";
import pdfToMapIcon from "../../../assets/icons/pdf_to_map_icon.svg";
import pencilEdit from "../../../assets/icons/pencilEdit.svg";
import reasyLogoBook from "../../../assets/icons/reasy_logo_book.svg";
import addPhotoButtonIcon from "../../../assets/icons/upload_image_icon.svg";
import tapAnimation from "../../../assets/illustrations/tap.json";
import previewSampleImage from "../../../assets/images/previewSample.svg";
import { sendEvent } from "../../../common/amplitudeUtils";
import { Step, handleJoyrideCallback, updateChoice } from "../../../common/utilsJoyride";
import { auth, database, firestore, functions } from "../../../firebaseConfig";
import i18n from "../../../i18n";
import { loadPaywall } from "../../../loadPaywall";
import MenuTabBar from "../../menu/MenuTabBar";
import SideMenu from "../../menu/SideMenu";
import { settingsServices } from "../../settings/store/services";
import { userServices } from "../../user/store/services";
import { thumbnailActions } from "../store/actions";
import { Document } from "../store/reducers";
import { filesServices } from "../store/services";
import {
	homeTutoriaTxtSlide1,
	homeTutoriaTxtSlide2,
	homeTutoriaTxtSlide3,
	homeTutoriaTxtSlide4,
	homeTutoriaTxtSlide5,
	homeTutoriaTxtSlide6,
} from "../utils/tutorialTxt";
import { generateConfirmationToast, generateErrorToast } from "../utils/utils";
import "./FilesManager.css";
import MapReady from "../components/MapReady";
import { getDarkMode } from "../../maps/components/utils";
import { Notification, Organization } from "../../user/store/reducers";
import { userActions } from "../../user/store/actions";
import { httpsCallable } from "firebase/functions";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from "../../../firebaseConfig";


const commonStepName = "tutorialFileManager"; // Define a common step name

var miniature: { thumbnails: any; fileName: string }[] = [];

type Props = {
	history: any;
	home: any;
	orgData: Organization | null | 'Private';
	licenseBannerRedeemed: boolean;
	setThumbnails: ([]) => void;
	clearThumbnails: () => void;
	setBannerLicenseRedeemed: (isRedeemed: boolean) => void;
	thumbnails: string;
};

type State = {
	isLoadingFoldersAndFiles: boolean;
	documents: Document[];
	maps: any[];
	extensionSummaries: any[];
	testDocument: boolean;
	querySnapshot: QuerySnapshot<DocumentData> | undefined;

	showFilePopover: boolean;
	filePopoverEvent: Event | undefined;
	showDeleteFileAlert: boolean;

	dragOverDropzone: boolean;

	fileType: string | undefined;
	docType: string | undefined;
	/////////
	userUuid: string | null;

	addDeviceBannerVisible: boolean;
	showSegmentationBanner: boolean,
	selectedRole: "student" | "teacher" | "other" | null,

	unsubscribeOnFilesChanges: Unsubscribe | null;
	unsubscribeMaps: Unsubscribe | null;
	unsubscribeFiles: Unsubscribe | null;
	unsubscribeExtensionSummaries: Unsubscribe | null;
	unsubscribeData: Unsubscribe | null;
	unsubscribeOnValue: Unsubscribe | null;
	thumbnailsGenerated: boolean;
	selectedFileWithContextMenu: string | null;

	tempFile: any;
	showOcrPopover: boolean;
	isUploadingFile: boolean;
	isDeletingFile: boolean;
	fileDropped: boolean;
	droppedFile: any;
	showMobileMenuPopover: boolean;
	viewingMode: "grid" | "list";
	renameFile: boolean;
	showRenameToast: boolean;
	paywall: boolean;

	stepsFileManager: Step[];
	tutorial: boolean;
	stepIndex: number;
	tutorialDocumentLn: string;

	showMapTitle: boolean;
	pageReloaded: boolean;
	loadingPage: boolean;
	lastUploadedFile: any;
	latestUploadedFile: Document[];
	bigLoaderDisplay: boolean;
	lastScrollTop: number;
	bearerToken: string;
	mapFromPDF: boolean;
	showMapPopover: boolean;
	mapUuid: string;
	isUploadingPdfToMap: boolean,
	disablePdfToMap: boolean,
	licenseCode: string | null | undefined,
	remoteConfig: boolean,

	customRole: string,
	loadingLicense: boolean,
	isWarning: boolean,
	userCreationDate: Date | null,

	/* Announcements section */
	notificationsData: Notification[]; /* TODO: DEFINE NOTIFICATION TYPE */
	unsubscribeNotifications: Unsubscribe | null,
	showNotificationsBanner: boolean,
	popoverEvent: any,
};

class FilesManager extends React.Component<Props, State> {
	fileRef: any;
	timer: any;
	slideOpts = {
		direction: "horizontal",
		slidesPerView: 1,
		freeMode: true,
		effect: "coverflow",
		coverflowEffect: {
			width: 50,
			rotate: 50,
			stretch: 0,
			depth: 100,
			modifier: 1,
		},
	};

	constructor(props: any) {
		super(props);
		this.state = {
			isLoadingFoldersAndFiles: true,
			documents: [],
			maps: [],
			extensionSummaries: [],
			testDocument: false,
			querySnapshot: undefined,

			showFilePopover: false,
			filePopoverEvent: undefined,
			showDeleteFileAlert: false,
			dragOverDropzone: false,

			fileType: undefined,
			docType: undefined,
			///////////
			userUuid: null,
			addDeviceBannerVisible: false,
			showSegmentationBanner: false,
			selectedRole: null,

			unsubscribeOnFilesChanges: null,
			unsubscribeMaps: null,
			unsubscribeExtensionSummaries: null,
			unsubscribeFiles: null,
			unsubscribeData: null,
			unsubscribeOnValue: null,
			unsubscribeNotifications: null,

			thumbnailsGenerated: false,

			selectedFileWithContextMenu: null,
			tempFile: null,
			showOcrPopover: false,
			isUploadingFile: false,
			isDeletingFile: false,
			fileDropped: false,
			droppedFile: null,
			showMobileMenuPopover: false,
			viewingMode: "grid",
			renameFile: false,
			showRenameToast: false,
			paywall: false,
			disablePdfToMap: false,
			customRole: '',
			licenseCode: '',
			remoteConfig: false,
			loadingLicense: false,
			isWarning: false,
			stepsFileManager: isMobile
				? [
					{
						target: ".homeMainGrid",
						content: (
							<div>
								<p style={{ whiteSpace: "pre-line" }}>
									<Trans>{homeTutoriaTxtSlide1}</Trans>
								</p>
							</div>
						),
						disableBeacon: true,
						placement: "auto",
						name: "commonStepName",
					},
					{
						target: "#helpIcon",
						content: (
							<div>
								<p>
									<Trans>{homeTutoriaTxtSlide2}</Trans>
								</p>
							</div>
						),
						disableBeacon: true,
						placement: "auto",
					},
					{
						target: ".filesManagerAddFabButton",
						content: (
							<div>
								<p className="centerTxtTutorialCard">
									<Trans>{homeTutoriaTxtSlide3}</Trans>
								</p>
								{/* <iframe
                            width="280"
                            height="157"
                            src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/18+-+Come+caricare+un+appunto+scritto+a+mano.mp4"
                            title="YouTube video player"
                            allowFullScreen
                            autoplay
                          ></iframe> */}
							</div>
						),
						disableBeacon: true,
						placement: "auto",
					},
					// {
					// 	target: ".layoutChange",
					// 	content: (
					// 		<div>
					// 			<p>
					// 				<Trans>{homeTutoriaTxtSlide4}</Trans>
					// 			</p>
					// 		</div>
					// 	),
					// 	disableBeacon: true,
					// 	placement: "auto",
					// },
					{
						target: ".fileOptions",
						content: (
							<div>
								<p>
									<Trans>{homeTutoriaTxtSlide5}</Trans>
								</p>
							</div>
						),
						disableBeacon: true,
						placement: "auto",
					},
					{
						target: ".fileManagerItem",
						content: (
							<div>
								<p>
									<Trans> {homeTutoriaTxtSlide6}</Trans>
								</p>
							</div>
						),
						disableBeacon: true,
						placement: "auto",
					},
					{
						target: ".settingsTabBar",
						content: (
							<div>
								<p>
									<Trans>Esplora le configurazioni</Trans>
								</p>
							</div>
						),
						disableBeacon: true,
						placement: "auto",
					},
				]
				: [
					{
						target: ".homePageMainPage",
						content: (
							<div>
								<p style={{ whiteSpace: "pre-line" }}>
									<Trans>{homeTutoriaTxtSlide1}</Trans>
								</p>
							</div>
						),
						disableBeacon: true,
						placement: "center",
					},
					{
						target: "#homeHelpIcon",
						content: (
							<div>
								<p>
									<Trans>{homeTutoriaTxtSlide2}</Trans>
								</p>
							</div>
						),
						disableBeacon: true,
						placement: "left",
					},
					{
						target: ".homeUploadFileCard",
						content: (
							<div>
								<p className="centerTxtTutorialCard">
									<Trans>{homeTutoriaTxtSlide3}</Trans>
								</p>
								{/* <iframe
                            width="280"
                            height="157"
                            src="https://reasy-public-tutorials.s3.eu-south-1.amazonaws.com/v1.7.5/18+-+Come+caricare+un+appunto+scritto+a+mano.mp4"
                            title="YouTube video player"
                            allowFullScreen
                            autoplay
                          ></iframe> */}
							</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
					// {
					// 	target: ".layoutChange",
					// 	content: (
					// 		<div>
					// 			<p>
					// 				<Trans>{homeTutoriaTxtSlide4}</Trans>
					// 			</p>
					// 		</div>
					// 	),
					// 	disableBeacon: true,
					// 	placement: "bottom",
					// },
					{
						target: ".fileOptions",
						content: (
							<div>
								<p>
									<Trans>{homeTutoriaTxtSlide5}</Trans>
								</p>
							</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
					{
						target: ".fileManagerItem",
						content: (
							<div>
								<p>
									<Trans>{homeTutoriaTxtSlide6}</Trans>
								</p>
							</div>
						),
						disableBeacon: true,
						placement: "bottom",
					},
				],
			tutorial: false,
			stepIndex: 0,
			tutorialDocumentLn: "",
			showMapTitle: false,
			pageReloaded: false,
			loadingPage: false,
			lastUploadedFile: null,
			latestUploadedFile: [],
			bigLoaderDisplay: false,
			lastScrollTop: 0,
			bearerToken: "",
			mapFromPDF: false,
			showMapPopover: false,
			mapUuid: "",
			isUploadingPdfToMap: false,
			userCreationDate: null,

			notificationsData: [],
			showNotificationsBanner: false,
			popoverEvent: null,

		};
	}

	async componentDidMount() {
		fetchAndActivate(remoteConfig)
			.then((activated) => {
				if (activated) console.log("Fetched and activated configs!");
				else {
					const remoteConfigValue = getValue(remoteConfig, "showLicenseCode").asBoolean();
					this.setState({ remoteConfig: remoteConfigValue })
				}
			})
			.catch((err) => console.error("Failed to fetch and activate configs", err));
		auth.onAuthStateChanged(async (userData) => {
			if (userData) {
				console.log(userData.metadata.creationTime);
				console.log(this.props.orgData);

				// if (this.props.orgData === null || this.props.orgData === 'Private') {
					if (userData.metadata.creationTime) {
						if (new Date(userData.metadata.creationTime) > new Date("07/04/2024")) {
							await new Promise(r => setTimeout(r, 5000));
							// if (this.props.orgData === 'Private') {
								this.props.history.push('/sessions')
							// }
							// else {
							// 	console.log("real org data:", this.props.orgData);

							// }
						}
					}
				// }
				auth.currentUser?.getIdToken().then((token) => {
					this.setState({ bearerToken: token });
					let localStorageItem = localStorage.getItem("mapStateFromFile");
					if (localStorageItem) {
						const savedState = JSON.parse(localStorageItem);
						if (savedState) {
							if (localStorageItem === "false")
								this.setState({ disablePdfToMap: false })
							else if (localStorageItem === "true")
								this.setState({ disablePdfToMap: true })
							let _this = this;
							const wsMapFromPdf = new WebSocket("wss://9yp8atf781.execute-api.eu-central-1.amazonaws.com/production/");
							wsMapFromPdf.onopen = function (event) {
								console.log(event);
								// Send a message to the server oncex the connection is open
								wsMapFromPdf.send(
									JSON.stringify({
										action: "restoreSession",
										token: token,
										userID: userData.uid,
									})
								);
							};
							wsMapFromPdf.onmessage = (event) => {
								let data = JSON.parse(event.data);
								let state = false;
								console.log(data);
								_this.setState({ disablePdfToMap: false })
								if (data.action && data.action === "closeConnection") {
									if (data.message == "Success") {
										console.log(data);
										localStorage.setItem("mapStateFromFile", JSON.stringify(state));
										// this.setState({ showMapPopover: true ,mapUuid: data.docID, isUploadingPdfToMap: false })
										generateConfirmationToast.call(this, true, data.docID);
									} else {
										generateErrorToast.call(this);
										localStorage.setItem("mapStateFromFile", JSON.stringify(state));
									}
									console.log("Closing connection as per client request.");
									wsMapFromPdf.close();
								}
							};
							wsMapFromPdf.onerror = function (event) {
								let state = false;
								localStorage.setItem("mapStateFromFile", JSON.stringify(state));
								console.log("Error from server:", event);
								_this.setState({ disablePdfToMap: false })
								wsMapFromPdf.close();
							};
						}
					}
				});
				if (userData.metadata.creationTime)
					this.setState({ userCreationDate: new Date(userData.metadata.creationTime) }, () => {

						console.log((new Date().getTime() - this.state.userCreationDate!.getTime()) > 5 * 60 * 1000);
					})

				// console.log("org_data: ", this.props.orgData);
				const userOrgRef = ref(database, `u/${userData.uid}/o`);
				// userServices.getUserOrganization(userData.uid).then(organization => {
				// 	this.props.setUserOrganization(organization)
				// })
				let paywallShowOrHide: boolean = false;
				const qm = query(collection(firestore, "m"), where("o", "==", userData.uid), where("f", "==", null));
				const unsubscribeQm = onSnapshot(qm, (querySnapshot) => {
					// this.setState({ isLoadingFoldersAndFiles: true });
					this.setState({
						maps: filesServices.fromFilesSnapshotToArrayOfDocuments(querySnapshot),
						querySnapshot: querySnapshot,
					});
				});
				const qs = query(collection(firestore, "s"), where("o", "==", userData.uid));
				const unsubscribeQs = onSnapshot(qs, (querySnapshot) => {
					let docs: Document[] = [];

					// this.setState({ isLoadingFoldersAndFiles: true });
					let documents = filesServices.fromFilesSnapshotToArrayOfDocuments(querySnapshot);
					documents.forEach((element) => {
						if (element.uuid.includes("WEB")) {
							docs.push(element);
						}
					});
					this.setState({
						extensionSummaries: docs,
						querySnapshot: querySnapshot,
					});
				});

				const onValueListener = onValue(userOrgRef, async (data) => {
					this.setState({ bigLoaderDisplay: false });
					if (data.val()) {
						this.setState({ /*loadingPage: false,*/ bigLoaderDisplay: true }, () =>
							setTimeout(() => {
								this.thumbnailsGen(userData.uid);
							}, 500)
						);
					} else {
						this.setState({ /* loadingPage: true,*/ bigLoaderDisplay: true }, () =>
							setTimeout(() => {
								this.thumbnailsGen(userData.uid);
							}, 500)
						);
					}
					this.setState({
						userUuid: userData.uid,
						isLoadingFoldersAndFiles: true,
					});
					const q = query(collection(firestore, "f"), where("o", "==", userData.uid));
					const unsubscribe = onSnapshot(q, async (querySnapshot) => {
						// this.setState({ isLoadingFoldersAndFiles: true });
						this.setState(
							{
								documents: filesServices.fromFilesSnapshotToArrayOfDocuments(querySnapshot),
								querySnapshot: querySnapshot,
							},
							async () => {
								settingsServices.getUserTutorialDocumentChoice(userData.uid).then(async (response) => {
									console.log(response);
									if (!response) {
										await this.getTutorial(querySnapshot);
									}
								});
								const currentDate = new Date();

								// Assuming your document objects have a 'created_at' property with timestamp in seconds
								const closestDocuments = this.state.documents.reduce(
									(closestDocs, current) => {
										const currentTimestamp = new Date(current.created_at * 1000).getTime();

										// Check if the current document's created_at is within the last minute
										if (currentDate.getTime() - currentTimestamp < 20000) {
											// 60,000 milliseconds = 1 minute
											return [...closestDocs, current];
										}

										return closestDocs;
									},
									[] as Document[] // Initialize closestDocs as an empty array
								);

								// Iterate over each document in the array and perform logic
								closestDocuments.forEach((doc) => {
									// Perform logic based on the individual document
									const documentId = doc.uuid;
									const elementId1 = documentId ? documentId + 2 : "";
									const elementId2 = documentId ? documentId + 1 : "";

									if (documentId) {
										document.getElementById(elementId1)!.style.display = "inline-block";
										document.getElementById(elementId2)?.classList.add("disable-card");
										this.thumbnailsGen(this.state.userUuid!);
									}
								});
								this.setState({ latestUploadedFile: closestDocuments });

								// Optionally, set the latestUploadedFile to the uuid of the last document in the array
								const latestUploadedFile = closestDocuments.length > 0 ? closestDocuments[closestDocuments.length - 1].uuid : null;
							}
						);

						//console.log("Array di oggetti con nome e miniatura", miniature);

						// this.setState({ paywall: paywallShowOrHide }, () => {
						// 	console.log(paywallShowOrHide)
						// 	if (!paywallShowOrHide) {
						// 		this.setState({ paywall: false }, () => {
						// 			if (userData.uid)
						// 				settingsServices.getUserTutorialChoice(userData.uid, "tFM").then((response) => {
						// 					// this.setState({ tutorial: response, loadingPage: false }, () => {
						// 					// 	if (response === true)
						// 					// 		sendEvent({
						// 					// 			"user_id": this.state.userUuid,
						// 					// 			"event_type": "Tutorial showed",
						// 					// 			"event_properties": {
						// 					// 				"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
						// 					// 				"page": "Files Manager",
						// 					// 			},
						// 					// 			"language": i18n.language,
						// 					// 			"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
						// 					// 			"app_version": appVersion,
						// 					// 			"time": Date.now(),
						// 					// 		});
						// 					// });
						// 				});
						// 		});
						// 	} else {
						// 		this.setState({ userUuid: null, paywall: true });
						// 	}
						// });
					});
					this.setState({
						unsubscribeOnFilesChanges: unsubscribe,
						unsubscribeMaps: unsubscribeQm,
						unsubscribeExtensionSummaries: unsubscribeQs,
						isLoadingFoldersAndFiles: false,
					});
				});
				setTimeout(async () => {

				}, 10000);
				paywallShowOrHide = await loadPaywall(false);
				this.setState({ paywall: paywallShowOrHide })

				// console.log("AAA", paywallShowOrHide)
				this.setState({
					unsubscribeOnValue: onValueListener,
				}); userServices
					.getUserRole(userData.uid)
					.then((role) => {
						if (!role) {
							this.setState({ showSegmentationBanner: true })
						}
					})
					.catch((err: Error) => {
						console.error("[getUserRole] getUserRole error:", err); // TO REMOVE
					});
				if (isPlatform("cordova") || isPlatform("capacitor")) {
					userServices
						.fetchDevices(userData.uid)
						.then((devices) => {
							Device.getId().then((id) => {
								const filteredDevices = devices.filter((device) => {
									return device.deviceId === id.identifier;
								});
								if (filteredDevices.length < 1) {
									this.setState({ addDeviceBannerVisible: true });
								}
							});
						})
						.catch((err: Error) => {
							console.error("[TasksList] fetch devices error:", err); // TO REMOVE
						});
				}

				const filesCollectionQuery = query(collection(firestore, "f"), where("o", "==", userData.uid));

				const unsubscribeFiles = onSnapshot(filesCollectionQuery, (querySnapshot) => {
					let noMoreChanges = true;

					querySnapshot.docChanges().forEach((change) => {
						if (change.type === "added") {
							// A new document has been added to the collection
							// console.log('New document added:', change.doc.data());
							// console.log(change.doc.id);

							this.setState({ lastUploadedFile: change.doc.id }, () => {
								let timer: NodeJS.Timeout;
								const collectionPath = `f/${this.state.lastUploadedFile}/d`;
								const subcol = collection(firestore, collectionPath);
								const unsubscribeData = onSnapshot(subcol, (docSnapshot) => {
									clearTimeout(timer); // Reset the timer on any changes
									timer = setTimeout(() => {
										if (!noMoreChanges) {
											// Iterate through each element in the latestUploadedFile array
											this.state.latestUploadedFile.forEach((fileId) => {
												console.log("FILE ID", fileId);

												const element1 = document.getElementById(fileId ? fileId.uuid + 1 : "");
												const element2 = document.getElementById(fileId ? fileId.uuid + 2 : "");

												if (element1) {
													element1.classList.remove("disable-card");
												}
												if (element2) {
													element2.style.display = "none";
												}
											});
											clearTimeout(timer); // Reset the timer on any changes
										}
									}, 3000); // 3000 milliseconds (3 seconds)}
									docSnapshot.forEach((doc) => {
										// console.log(doc)
										//  const fileData = doc.data();
										//  console.log('File Data:', fileData);
										if (doc) noMoreChanges = false;
									});
								});
								this.setState({ unsubscribeData: unsubscribeData });
							});
						}
					});
				});
				this.setState({ unsubscribeFiles: unsubscribeFiles });
				const notificationQuery = query(
					collection(firestore, 'n'),
					orderBy('t', 'desc')
				);

				const unsubscribeNotifications = onSnapshot(notificationQuery, (snapshot) => {
					const notificationsData: Notification[] = [];
					console.log(snapshot);

					snapshot.forEach(doc => notificationsData.push({
						id: doc.id,
						title: doc.data().m,
						body: doc.data().b,
						date: doc.data().t,
						url: doc.data().u ? doc.data().u : null,
						expiryDate: doc.data().e ? doc.data().e : null,
						type: doc.data().c,
					}));
					console.log(notificationsData);

					this.setState({ notificationsData: notificationsData })
				});
				this.setState({ unsubscribeNotifications: unsubscribeNotifications });
			}
			else
				this.props.history.push("/authentication/get-started")
		});
	}
	// componentDidUpdate({ }, prevState:any) {
	// 	if (prevState.thumbnailsGenerated !== this.state.thumbnailsGenerated) {
	// 			setTimeout(() => {
	// 				console.log(this.state.thumbnailsGenerated)
	// 				if(this.state.userUuid)
	// 					this.thumbnailsGen(this.state.userUuid)
	// 			}, 2000)

	// 	}
	// }
	getTutorial(querySnapshot: any) {
		return new Promise((resolve, reject) => {
			const documentArray = filesServices.fromFilesSnapshotToArrayOfDocuments(querySnapshot);
			this.setState(
				{
					testDocument: documentArray.some((element) => element.tipo?.toLowerCase() === "tutorial"),
				},
				async () => {
					console.log(this.state.testDocument);
					if (!this.state.testDocument) {
						await filesServices.getTutorialDoc(this.state.userUuid ? this.state.userUuid : "", i18n.language);
						resolve(true);
					} else resolve(false);
				}
			);
		});
	}

	processBatch(startIndex: number, batchSize: number) {
		let endIndex = startIndex + batchSize;
		for (let i = startIndex; i < endIndex && i < miniature.length; i++) {
			let id =
				"#" +
				"_" +
				miniature[i].fileName
					.split(".")[0]
					.replace(/\s+/g, "_")
					.replace(/[()]/g, "_")
					.replace(/[^a-zA-Z0-9_]/g, "");
			let arrays = document.querySelectorAll(id);
			if (arrays.length != 0) {
				arrays.forEach((element) => {
					let imageObject = element as HTMLImageElement;
					imageObject.style.background = "none";
					if (miniature[i].thumbnails) imageObject.src = miniature[i].thumbnails;
				});
			} else {
				let array_cards = document.getElementsByClassName("previewbox");
				for (let j = 0; j < array_cards.length; j++) {
					if ((array_cards[j] as HTMLIonImgElement).src == undefined) (array_cards[j] as HTMLIonImgElement).src = previewSampleImage;
				}
			}
		}

		if (endIndex < miniature.length) {
			// Schedule next batch
			this.processBatch(endIndex, batchSize);
		}
	}
	thumbnailsGen(uid: string) {
		const { thumbnails } = this.props;
		console.log(thumbnails);
		let array_cards = document.getElementsByClassName("previewbox");
		for (let j = 0; j < array_cards.length; j++) {
			if ((array_cards[j] as HTMLIonImgElement).src == undefined) (array_cards[j] as HTMLIonImgElement).src = previewSampleImage;
		}
		// console.log("DED")
		// let test=[{fileID:"uuuid1",thumbnails:"https://firebasestorage.googleapis.com/v0/b/t4a-reasy.appspot.com/o/f%2F030G6fGraMWRE3pp8YoQYNhrXIh1%2F36MqW5hMPxRRgAQq%2Fimage.jpg?alt=media&token=3239d07e-e4fd-4658-ad6f-8d25bf65a63b"},
		// {fileID:"uuuid2",thumbnails:"https://firebasestorage.googleapis.com/v0/b/t4a-reasy.appspot.com/o/f%2F030G6fGraMWRE3pp8YoQYNhrXIh1%2F36MqW5hMPxRRgAQq%2Fimage.jpg?alt=media&token=3239d07e-e4fd-4658-ad6f-8d25bf65a63b"},
		// {fileID:"uuuid3",thumbnails:"https://firebasestorage.googleapis.com/v0/b/t4a-reasy.appspot.com/o/f%2F030G6fGraMWRE3pp8YoQYNhrXIh1%2F36MqW5hMPxRRgAQq%2Fimage.jpg?alt=media&token=3239d07e-e4fd-4658-ad6f-8d25bf65a63b"},
		// {fileID:"uuuid4",thumbnails:"https://firebasestorage.googleapis.com/v0/b/t4a-reasy.appspot.com/o/f%2F030G6fGraMWRE3pp8YoQYNhrXIh1%2F36MqW5hMPxRRgAQq%2Fimage.jpg?alt=media&token=3239d07e-e4fd-4658-ad6f-8d25bf65a63b"},

		// ]
		// this.props.setThumbnails(test)

		// this.props.clearThumbnails()

		// thumbnailActions.setThumbnails("uuuid1","https://firebasestorage.googleapis.com/v0/b/t4a-reasy.appspot.com/o/f%2F030G6fGraMWRE3pp8YoQYNhrXIh1%2F36MqW5hMPxRRgAQq%2Fimage.jpg?alt=media&token=3239d07e-e4fd-4658-ad6f-8d25bf65a63b")
		// setTimeout(() => {
		// 	console.log("ACTION")
		// 			this.props.setThumbnails("uuuid1","https://firebasestorage.googleapis.com/v0/b/t4a-reasy.appspot.com/o/f%2F030G6fGraMWRE3pp8YoQYNhrXIh1%2F36MqW5hMPxRRgAQq%2Fimage.jpg?alt=media&token=3239d07e-e4fd-4658-ad6f-8d25bf65a63b")

		// 	// thumbnailActions.clearThumbnails()
		// }, 500);
	}
	// async thumbnailsGen(uid: string) {
	// 	let imgArray: any[] = []
	// 	try {
	// 		const pdfTumbnailGenerator = require("pdf-thumbnails-generator");
	// 		await listAll(storageRef(storage, `f/${uid}/`)).then((res) => {
	// 			if (res.prefixes.length > 1) {
	// 				let arratTest:any[]=[]

	// 				res.prefixes.forEach(async (folderRef) => {
	// 					await listAll(storageRef(storage, folderRef.fullPath)).then((res) => {

	// 						res.items.forEach(async (itemRef) => {
	// 							// console.log(itemRef.name);
	// 							if (itemRef.name.substring(itemRef.name.length - 3) === "pdf") {
	// 								let percorso = itemRef.fullPath.split("/")[0] + "/" + itemRef.fullPath.split("/")[1] + "/" + itemRef.fullPath.split("/")[2];
	// 								try {
	// 									await listAll(storageRef(storage, percorso + "/thumbnail")).then((res) => {
	// 										//creating, uploading to storage,setting card img of first page of pdf
	// 										// console.log(res.items.length);
	// 										if (res.items.length == 0) {
	// 											let path_thumbnails = percorso + "/thumbnail/";

	// 											getDownloadURL(storageRef(storageRef(storage, itemRef.fullPath))).then(async (url) => {
	// 												var thbfile = await pdfTumbnailGenerator.generatePdfThumbnails(url, 150);
	// 												delete thbfile[0].page;
	// 												let idfile = itemRef.name.slice(0, itemRef.name.length - 4);
	// 												let finalpath = path_thumbnails + idfile + ".png";
	// 												let img = thbfile[0].thumbnail;
	// 												// console.log(img);
	// 												fetch(img)
	// 													.then((res) => res.blob())
	// 													.then((res) => {
	// 														uploadBytes(storageRef(storage, finalpath), res).then((snapshot) => {
	// 															console.log("Uploaded a blob or file!");
	// 														});
	// 													});
	// 												let setter = idfile;
	// 												miniature.push({
	// 													thumbnails: thbfile[0].thumbnail,
	// 													fileName: idfile,
	// 												});
	// 											});
	// 											let array_cards = document.getElementsByClassName("previewbox");
	// 											for (let j = 0; j < array_cards.length; j++) {
	// 												if ((array_cards[j] as HTMLIonImgElement).src == undefined) (array_cards[j] as HTMLIonImgElement).src = previewSampleImage;
	// 											}
	// 										} else {
	// 											//set card img of first page of pdf
	// 											res.items.forEach((itemRef) => {
	// 												getDownloadURL(storageRef(storageRef(storage, itemRef.fullPath))).then(async (url) => {
	// 													let idfile;
	// 													idfile = itemRef.fullPath.split("/")[4].replace(".png","");
	// 													let setter = idfile.slice(0, idfile.length - 4);
	// 													console.log(idfile)
	// 													let id =
	// 														"#" +
	// 														"_" +
	// 														setter
	// 															.split(".")[0]
	// 															.replace(/\s+/g, "_")
	// 															.replace(/[()]/g, "_")
	// 															.replace(/[^a-zA-Z0-9_]/g, "");
	// 													let arrays = document.querySelectorAll(id);
	// 													arratTest.push({idfile, url})
	// 													// this.props.setThumbnails([{idfile,url}])

	// 													if (arrays.length != 0) {
	// 														arrays.forEach((element) => {
	// 															let imageObject = element as HTMLImageElement;
	// 															imageObject.style.background = "none";

	// 															if (url) imageObject.src = url;
	// 															else {
	// 																// console.log("NON CI ARRIVAAAA");

	// 																let array_cards = document.getElementsByClassName("previewbox");
	// 																for (let j = 0; j < array_cards.length; j++) {
	// 																	if ((array_cards[j] as HTMLIonImgElement).src == undefined) (array_cards[j] as HTMLIonImgElement).src = previewSampleImage;
	// 																}
	// 															}
	// 														});
	// 													} else {
	// 														// console.log("NON CI ARRIVAAAA");
	// 														let array_cards = document.getElementsByClassName("previewbox");
	// 														for (let j = 0; j < array_cards.length; j++) {
	// 															if ((array_cards[j] as HTMLIonImgElement).src == undefined) (array_cards[j] as HTMLIonImgElement).src = previewSampleImage;
	// 														}
	// 													}
	// 													miniature.push({
	// 														thumbnails: url,
	// 														fileName: setter,
	// 													});
	// 												});
	// 											});

	// 										}

	// 									});
	// 								} catch (error) {
	// 									console.error(error);
	// 								}
	// 							} else {
	// 								//if png/jpg set it as card img
	// 								getDownloadURL(storageRef(storageRef(storage, itemRef.fullPath))).then(async (url) => {
	// 									let idfile;
	// 									idfile = itemRef.fullPath.split("/")[3];
	// 									let setter = idfile.slice(0, idfile.length - 4);
	// 									let id =
	// 										"#" +
	// 										"_" +
	// 										setter
	// 											.split(".")[0]
	// 											.replace(/\s+/g, "_")
	// 											.replace(/[()]/g, "_")
	// 											.replace(/[^a-zA-Z0-9_]/g, "");

	// 									let arrays = document.querySelectorAll(id);
	// 									if (arrays.length != 0) {
	// 										arrays.forEach((element) => {
	// 											let imageObject = element as HTMLImageElement;
	// 											imageObject.style.background = "none";
	// 											if (url) imageObject.src = url;
	// 										});
	// 									} else {
	// 										let array_cards = document.getElementsByClassName("previewbox");
	// 										for (let j = 0; j < array_cards.length; j++) {
	// 											if ((array_cards[j] as HTMLIonImgElement).src == undefined) (array_cards[j] as HTMLIonImgElement).src = previewSampleImage;
	// 										}
	// 									}
	// 									miniature.push({
	// 										thumbnails: url,
	// 										fileName: setter,
	// 									});
	// 								});
	// 							}
	// 						});

	// 					});
	// 					console.log("THUMB ARRAY",arratTest)

	// 				});
	// 			} else {
	// 				let array_cards = document.getElementsByClassName("previewbox");
	// 				for (let j = 0; j < array_cards.length; j++) {
	// 					if ((array_cards[j] as HTMLIonImgElement).src == undefined) (array_cards[j] as HTMLIonImgElement).src = previewSampleImage;
	// 				}
	// 			}
	// 		});

	// 		this.setState({thumbnailsGenerated:true})
	// 	} catch (err) {
	// 		console.error(err);
	// 	}

	// }

	componentWillUnmount() {
		if (this.state.unsubscribeOnFilesChanges) {
			this.state.unsubscribeOnFilesChanges();
		}
		if (this.state.unsubscribeMaps) {
			this.state.unsubscribeMaps();
		}
		if (this.state.unsubscribeFiles) {
			this.state.unsubscribeFiles();
		}
		if (this.state.unsubscribeNotifications) {
			this.state.unsubscribeNotifications();
		}
		if (this.state.unsubscribeExtensionSummaries) {
			this.state.unsubscribeExtensionSummaries();
		}
		if (this.state.unsubscribeData) {
			this.state.unsubscribeData();
		}
		if (this.state.unsubscribeOnValue) {
			this.state.unsubscribeOnValue();
		}
	}
	renameFile(newName: string) {
		const doc_uuid = this.state.selectedFileWithContextMenu;
		if (doc_uuid?.includes("WEB")) {
			console.log("LO RINOMINIAMO O NO", doc_uuid);
			let fileRef = doc(firestore, `s/${this.state.selectedFileWithContextMenu}/`);
			updateDoc(fileRef, {
				t: newName,
			}).then((response) => {
				sendEvent({
					"user_id": this.state.userUuid,
					"event_type": "Document renamed",
					"language": i18n.language,
					"event_properties": {
						"user_org": this.props.orgData === 'Private' ? "Private User" : this.props.orgData !== null ? this.props.orgData.name : "Private User",
						"document_uuid": doc_uuid,
						"document_name": newName,
					},
					"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
					"app_version": appVersion,
					"time": Date.now(),
				});
			});
		} else {
			let fileRef = doc(firestore, `f/${this.state.selectedFileWithContextMenu}/`);
			updateDoc(fileRef, {
				t: newName,
			}).then((response) => {
				sendEvent({
					"user_id": this.state.userUuid,
					"event_type": "Document renamed",
					"language": i18n.language,
					"event_properties": {
						"user_org": this.props.orgData === 'Private' ? "Private User" : this.props.orgData !== null ? this.props.orgData.name : "Private User",
						"document_uuid": doc_uuid,
						"document_name": newName,
					},
					"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
					"app_version": appVersion,
					"time": Date.now(),
				});
			});
		}
	}

	uploadFileFromMobile() {
		document.getElementById(this.state.latestUploadedFile.length > 0 ? this.state.latestUploadedFile[0].uuid + 1 : "")?.classList.add("disable-card");
		const chooser = Chooser;
		chooser
			.getFile(this.state.fileType) // TO DO: SUPPORT ALSO IMAGES
			.then((file) => {
				if (file?.mediaType === this.state.fileType) console.log("AAAA");
				if (file && file.data && this.state.userUuid) {
					//console.log(`[uploadFileFromMobile] result 3: ${file.mediaType}`);
					var blob = new Blob([file.data], { type: file.mediaType });
					filesServices
						.uploadFile(blob, this.state.userUuid, this.state.mapFromPDF, this.state.bearerToken, file.name)
						.then(() => {
							this.setState({ isUploadingFile: false, showOcrPopover: false });
							//console.log('[FileManager uploadFileFromMobile] Upload done');
							/* window.location.reload(); */
						})
						.catch((e) => {
							console.error("[FileManager uploadFileFromMobile] Upload failed:", e);
						});
				} else {
					this.setState({ isUploadingFile: false, showOcrPopover: false });
				}
			})
			.catch((err) => {
				console.error("[uploadFileFromMobile]", JSON.stringify(err));
			});
	}

	uploadFile(file: any, userID: any, actionType: string) {
		// console.log("FOR UPLOAD", file, userID);
		if (this.state.mapFromPDF) {
			this.setState({ isUploadingFile: false, showOcrPopover: false, showMapPopover: true, isUploadingPdfToMap: true });
		}

		filesServices
			.uploadFile(file, userID, this.state.mapFromPDF, this.state.bearerToken, actionType)
			.then((response) => {
				this.setState({
					droppedFile: "", fileDropped: false
				})
				console.log(response, document.getElementById(this.state.latestUploadedFile.length > 0 ? this.state.latestUploadedFile[0].uuid + 1 : ""));
				if (response !== "error" && typeof response === "string") {
					this.setState({ showMapPopover: true, mapUuid: response, isUploadingPdfToMap: false });
					// generateConfirmationToast.call(this, true, response);
				}
				//console.log("[FilesManager] upload success");
				/* this.state.documents.push(document);
				this.setState({ documents: this.state.documents });
				this.watchForFileProcessingChanges(document.uuid); */
				this.setState({ isUploadingFile: false, showOcrPopover: false, disablePdfToMap: false });
			})
			.catch((err) => {
				console.error("[FilesManager] Error uploading file", err);
				let errorMessage =
					i18n.language === "it"
						? "Errore durante il caricamento del file. Foto/file non chiaro o dimensione superiore a 25MB, riprovare.(Se il file supera i 25MB, comprimi o separalo in più parti)"
						: i18n.language === "en"
							? "Error during file upload. The photo/file is unclear or exceeds 25MB in size, please try again. (If the file exceeds 25MB, compress it or split it into multiple parts)"
							: i18n.language === "fr"
								? "Erreur lors du chargement du fichier. La photo/le fichier est flou ou dépasse 25 Mo, veuillez réessayer. (Si le fichier dépasse 25 Mo, compressez-le ou divisez-le en plusieurs parties.)"
								: i18n.language === "de"
									? "Fehler beim Hochladen der Datei. Das Foto/die Datei ist unklar oder überschreitet 25 MB, bitte versuchen Sie es erneut. (Wenn die Datei 25 MB überschreitet, komprimieren Sie sie oder teilen Sie sie in mehrere Teile auf.)"
									: i18n.language === "ca"
										? "Error durant la càrrega del fitxer. La foto/el fitxer no és clar o supera els 25 MB, si us plau, torni a intentar-ho. (Si el fitxer supera els 25 MB, comprimeixi'l o separi'l en diverses parts.)"
										: i18n.language === "es"
											? "Error durante la carga del archivo. La foto/el archivo no está claro o supera los 25 MB, por favor, intente nuevamente. (Si el archivo supera los 25 MB, comprímalo o sepárelo en varias partes.)"
											: "Erro durante o carregamento do arquivo. A foto/arquivo está pouco claro ou excede 25 MB, por favor, tente novamente. (Se o arquivo exceder 25 MB, compacte-o ou divida-o em várias partes.)";
				alert(errorMessage);
				this.setState({ isUploadingFile: false, showOcrPopover: false, disablePdfToMap: false });
			});
	}


	render() {
		const animationOptions = {
			loop: true,
			autoplay: true,
			animationData: tapAnimation,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		return (
			<IonPage className="homePageMainPage">
				<IonIcon hidden={this.state.bigLoaderDisplay} className="bigBookLoader fill-icon" src={reasyLogoBook}></IonIcon>
				<Joyride
					steps={this.state.stepsFileManager}
					run={this.state.tutorial} // Set to true to start the tour automatically
					continuous={true} // Allow continuous navigation through the tour
					showProgress={true} // Display progress indicator
					showSkipButton={true} // Display skip button
					spotlightClicks={true}
					callback={(data) => handleJoyrideCallback.call(this, data, "Files Manager")}
					hideBackButton={false}
					locale={{
						skip: (
							<div
								className="skip"
								onClick={() => {
									updateChoice.call(this, "tutorialFileManager");
								}}
							>
								<Trans>Salta</Trans>
							</div>
						),
						back: (
							<div className="nextTutorialBtn">
								<Trans>Precedente</Trans>
							</div>
						),
						next: (
							<span>
								<Trans>Prosegui</Trans>
							</span>
						),
						last: (
							<div
								className="nextTutorialBtn"
								onClick={() => {
									updateChoice.call(this, "tutorialFileManager");
								}}
							>
								<Trans>Ultimo</Trans>
							</div>
						),
					}}
					styles={{
						buttonNext: {
							fontSize: "12px",
							outline: "none",
						},
					}}
				/>
				{/* <IonLoading isOpen={this.state.loadingPage} message="Loading..." duration={3000} spinner="circles" /> */}
				{!isMobile && (
					<SideMenu
						userUuid={this.state.userUuid}
						orgData={this.props.orgData}
						history={this.props.history}
						userDocuments={this.state.documents ? this.state.documents : null}
						sideMenuHome={{
							homeIcon: home,
							color: "var(--callToAction-color)",
						}}
						sideMenuProfile={{ profileIcon: undefined, color: undefined }}
						sideMenuSetting={{ settingsIcon: undefined, color: undefined }}
					/>
				)}

				<IonButtons
					hidden={isMobile || this.state.isLoadingFoldersAndFiles}
					className="sideMenuBtn"
					slot="start"
					onClick={() => {
						if (window.location.pathname == "/files/my-files") {
							document.getElementById("homeitem")!.style.color = "var(--callToAction-color)";
							document.getElementById("homeitem")!.style.fontWeight = "600";
						}
					}}
				>
					{!isMobile && (
						<>
							<IonMenuButton className="sideMenuBtnBurger" menu="sideMenu" />
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<IonIcon
									className="notificationsIcon"
									onMouseUp={(e) => {
										/* SHOW THE NEWS BANNER */

										this.setState({ showNotificationsBanner: true, popoverEvent: e })
									}}
									icon={notificationsOutline}
									style={{ fontSize: "3vw" }}
								/>
								<IonIcon
									id="homeHelpIcon"
									// className="helpIcon"
									onClick={() => {
										this.setState({ tutorial: true });
										sendEvent({
											"user_id": this.state.userUuid,
											"event_type": "Tutorial opened",
											"event_properties": {
												"user_org": this.props.orgData === 'Private' ? "Private User" : this.props.orgData !== null ? this.props.orgData.name : "Private User",
												"page": "Files Manager",
											},
											"language": i18n.language,
											"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
											"app_version": appVersion,
											"time": Date.now(),
										});
										if (!this.state.testDocument) {
											this.getTutorial(this.state.querySnapshot);
										}
									}}
									icon={helpCircleOutline}
									style={{ fontSize: "3vw", cursor: 'pointer' }}
								/>
							</div>

						</>
					)}
				</IonButtons>
				{/* <IonPage> */}
				<IonHeader mode="ios" className=" mobileHeader ion-no-border" hidden={!isMobile}>
					<IonToolbar className="homeHeaderToolbar">
						{!isMobile && (
							<IonTitle>
								<Trans>Gestore dei File</Trans>
							</IonTitle>
						)}
						{isMobile && (
							<>
								<div className="mobileHeaderContainer">
									<div className="mobileHeaderLogoContainer">

										<IonImg className="logo"></IonImg>
										<IonText>Reasy</IonText>
									</div>
									<IonIcon
										hidden={this.state.isLoadingFoldersAndFiles}
										className="notificationsIcon"
										onMouseUp={(e) => {
											/* SHOW THE NEWS BANNER */

											this.setState({ showNotificationsBanner: true, popoverEvent: e })
										}}
										icon={notificationsOutline}
									// style={{ fontSize: "60px" }}
									/>
								</div>
							</>
						)}

						<IonButtons slot="start">{!isMobile && <IonMenuButton autoHide={false} menu="sideMenu"></IonMenuButton>}</IonButtons>

						{this.state.isLoadingFoldersAndFiles && <IonProgressBar type="indeterminate" />}
					</IonToolbar>
				</IonHeader>

				<IonContent id="openSideMenu" className={!isMobile ? "fileManagerContainer" : ''}>
					<Dropzone
						onDragLeave={() => this.setState({ dragOverDropzone: false })}
						onDragOver={() => this.setState({ dragOverDropzone: true })}
						noClick={true}
						onDrop={(acceptedFiles) => {
							this.setState({
								dragOverDropzone: false,
								fileDropped: true,
								showOcrPopover: true,
								droppedFile: acceptedFiles[0],
							});
							// if (this.state.userUuid) {
							//     filesServices.uploadFile(acceptedFiles[0], this.state.userUuid)
							//         .then(document => {
							//             /* this.state.documents.push(document);
							//             this.setState({
							//                 documents: this.state.documents
							//             }); */
							//         })
							//         .catch(err => {
							//             console.error('[FilesManager] error uploading file from dropzone:', err);
							//         })
							// }
						}}
					>
						{({ getRootProps, getInputProps }) => (
							<section
								className={this.state.dragOverDropzone ? "filesManagerDropzoneActive" : "filesManagerDropzone"} // last class not defined
							>
								<div style={{ height: "100%" }} {...getRootProps()}>
									<input {...getInputProps()} />

									{!this.state.isLoadingFoldersAndFiles && (
										<IonGrid className="homeMainGrid">
											{this.state.documents.length < 1 && (
												<IonRow>
													<p className="filesManagerUploadCTAParagraph">
														<Trans>Carica il tuo primo documento</Trans>
													</p>

													{/* <div className='filesManagerTapToUploadAnimation'>
                                <Lottie
                                    isClickToPauseDisabled={true}
                                    options={animationOptions}
                                    height={200}
                                    width={200}
                                />
                            </div> */}
												</IonRow>
											)}

											<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
												{this.state.showSegmentationBanner && (
													<div className="filesManagerSegmentationBanner">
														<p className="filesManagerSegmentationTitle"><Trans>Dicci di più su di te</Trans></p>
														<div className="filesManagerSegmentationContent">
															<IonRadioGroup className="filesManagerSegmentationRadioGroup" value={this.state.selectedRole} onIonChange={(e: any) => this.setState({ selectedRole: e.detail.value })}>
																<IonRadio value={'student'} className="filesManagerSegmentationRadio">
																	<IonCard className={this.state.selectedRole === 'student' ? "filesManagerSegmentationCard selectedRole" : "filesManagerSegmentationCard"}>
																		<p className="filesManagerSegmentationRole"><Trans>Studente</Trans></p>
																		<IonCardContent className="filesManagerSegmentationCardContent">
																			<p className="filesManagerSegmentationIcon">🧑‍🎓</p>
																		</IonCardContent>
																	</IonCard>
																</IonRadio>
																<IonRadio value={'teacher'} className="filesManagerSegmentationRadio">
																	<IonCard className={this.state.selectedRole === 'teacher' ? "filesManagerSegmentationCard selectedRole" : "filesManagerSegmentationCard"}>
																		<p className="filesManagerSegmentationRole"><Trans>Docente</Trans></p>
																		<IonCardContent className="filesManagerSegmentationCardContent">
																			<p className="filesManagerSegmentationIcon">🧑‍🏫</p>
																		</IonCardContent>
																	</IonCard>
																</IonRadio>
																<IonRadio value={'other'} onMouseDown={() => {
																}} className="filesManagerSegmentationRadio">
																	<IonCard className={this.state.selectedRole === 'other' ? "filesManagerSegmentationCard selectedRole" : "filesManagerSegmentationCard"}>
																		<p className="filesManagerSegmentationRole"><Trans>Altro</Trans><br /><Trans>(specificare)</Trans></p>
																		<IonCardContent className="filesManagerSegmentationCardContent">
																			<p className="filesManagerSegmentationIcon">💼</p>
																		</IonCardContent>
																	</IonCard>
																</IonRadio>
															</IonRadioGroup>
															{this.state.selectedRole &&
																<div className="filesManagerSegmentationButtonDiv">
																	<IonInput
																		className="filesManagerSegmentationButtonInput"
																		hidden={this.state.selectedRole !== 'other'}
																		type="text"
																		onIonInput={(e) => {
																			if (e.detail.value) {
																				let value = e.detail.value?.toString().trim()
																				this.setState({ customRole: value })
																			}
																		}}
																		id="segmentationInput"
																		placeholder={
																			i18n.language === 'it' ? "Scrivi la tua professione..." :
																				i18n.language === 'en' ? "Write your profession..." :
																					i18n.language === 'es' ? "Escribe tu profesión..." :
																						i18n.language === 'fr' ? "Écrivez votre profession..." :
																							i18n.language === 'de' ? "Schreiben Sie Ihren Beruf..." :
																								i18n.language === 'ca' ? "Escriu la teva professió..." :
																									i18n.language === 'pt' ? "Escreva sua profissão..." :
																										i18n.language === 'cz' ? "Napište své povolání..." :
																											i18n.language === 'nl' ? "Schrijf je beroep..." : ''
																		}
																	>
																	</IonInput>
																	<IonButton
																		className="filesManagerSegmentationButton"
																		disabled={this.state.selectedRole === 'other' && this.state.customRole === ''}
																		onClick={() => {
																			if (this.state.userCreationDate) {
																				/* UPDATE ROLE ON DB */
																				if (this.state.selectedRole !== null) {
																					userServices.updateUserRole(auth.currentUser!.uid, this.state.selectedRole !== 'other' ? this.state.selectedRole : this.state.customRole)
																						.then(() => {
																							this.setState({ showSegmentationBanner: false })
																						})

																					if (this.state.userCreationDate > new Date('2024-05-19')) { //to include users that have registered and went in to the "Logic handler" group, so they can be moved to the specific ones
																						/* CALL CLOUD FUNCTION TO ADD TO MAILERLITE GROUP */
																						const segmentUserMailerlite = httpsCallable(functions, 'segmentUserMailerlite-segmentUserMailerlite');
																						segmentUserMailerlite({
																							role: this.state.selectedRole,
																							customRole: this.state.selectedRole === 'other' ? this.state.customRole : null
																						})
																					}
																					else {
																						/* DO NOTHING -> DB HAS BEEN UPDATED  */
																					}
																				}
																				else {
																					console.error("Error updating user role: Role not defined");
																				}
																			}
																		}}
																	>
																		<Trans>Invia</Trans>
																	</IonButton>
																</div>
															}
														</div>
													</div>
												)}
												<div>
													{!this.state.showSegmentationBanner && this.state.addDeviceBannerVisible && (
														<div className="filesManagerNotificationsDivWiggle">
															<IonGrid
																class="filesManagerNotificationsAlertGrid"
																onClick={() => {
																	PushNotifications.requestPermissions()
																		.then((result) => {
																			// alert('AA' + result.receive)
																			if (result.receive === "granted") {
																				// Register with Apple or Google to receive push via APNS/FCM
																				PushNotifications.register()
																					.then(() => {
																						// alert('registered')
																						//console.log("[FilesManager] register push notification devices pressed");
																						sendEvent({
																							"user_id": this.state.userUuid,
																							"event_type": "Device added",
																							"event_properties": {
																								"user_org": this.props.orgData === 'Private' ? "Private User" : this.props.orgData !== null ? this.props.orgData.name : "Private User",
																							},
																							"language": i18n.language,
																							"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
																							"app_version": appVersion,
																							"time": Date.now(),
																						});
																						this.setState({
																							addDeviceBannerVisible: false,
																						});
																					})
																					.catch((err) => {
																						alert("errore: " + err);
																						console.error("[FilesManager] error registering device:", err);
																					});
																			} else {
																				console.error(`[pushNotificationHandler - requestPermission] not granted: ${JSON.stringify(result)}`);
																			}
																		})
																		.catch((error) => {
																			console.error("[push notification requestPermission] error:", error);
																		});
																}}
															>
																<IonRow>
																	<IonImg class="filesManagerNotificationsIllustration" src={notificationsImage} />
																</IonRow>
																<IonRow>
																	<IonText className="filesManagerNotificationsCTAText">
																		<Trans>Aggiungi questo dispositivo alla lista degli autorizzati. Fai tap qui.</Trans>
																	</IonText>
																</IonRow>
															</IonGrid>
														</div>
													)}
												</div>
												{/* 
													let userCreationDate = new Date(userData.metadata.creationTime!).getTime()
													let dateNow = new Date().getTime()
													console.log((dateNow - userCreationDate) / (1000 * 60))
												*/}
												{this.state.remoteConfig && (i18n.language === 'es' || i18n.language === 'ca') && !this.props.licenseBannerRedeemed && this.state.userCreationDate &&
													((new Date().getTime() - this.state.userCreationDate!.getTime()) < 5 * 60 * 1000) &&
													<div className="filesManagerRedeemCodeDiv">
														<IonAccordionGroup className="filesManagerRedeemLicenseAccordionGroup">
															<IonAccordion value="redeemLicense" className="filesManagerRedeemLicenseAccordion">
																<IonItem lines='none' slot="header" className="filesManagerRedeemLicenseItem">
																	<IonLabel><Trans>Hai un codice licenza?</Trans></IonLabel>
																</IonItem>
																<div slot="content" className="filesManagerRedeemLicenseContentDiv">
																	<IonInput
																		className="filesManagerRedeemLicenseInput"
																		placeholder={
																			i18n.language === 'es' ? "Código de licencia" :
																				i18n.language === 'ca' ? "Codi de llicència" :
																					i18n.language === 'it' ? "Codice licenza" :
																						i18n.language === 'en' ? "License code" :
																							i18n.language === 'fr' ? "Code de licence" :
																								i18n.language === 'de' ? "Lizenzcode" :
																									i18n.language === 'pt' ? "Código de licença" :
																										i18n.language === 'cz' ? "Licenční kód" :
																											i18n.language === 'nl' ? "Licentiecode" : ''
																		}
																		type="text"
																		onIonInput={(e) => {
																			this.setState({ licenseCode: e.detail.value?.toString() })
																		}}
																	/>
																	<IonButton
																		disabled={!this.state.licenseCode}
																		onClick={() => {
																			this.setState({ loadingLicense: true })
																			const redeemLicense = httpsCallable(functions, "redeemLicense-redeemLicense");
																			redeemLicense({
																				licenseCode: this.state.licenseCode
																			})
																				.then(res => {
																					const data: any = res.data;
																					sendEvent({
																						"user_id": auth.currentUser?.uid,
																						"event_type": "Redeem License",
																						"event_properties": {
																							"license_code": this.state.licenseCode,
																							"is_valid": data.ok === 'ok' ? true : false
																						},
																						"language": i18n.language,
																						"app_version": appVersion,
																						"platform": isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : 'desktop',
																						"time": Date.now()
																					})
																					if (data.ok == "ok") {
																						//SET LOCAL VARIABLE TO HIDE THE BANNER
																						this.props.setBannerLicenseRedeemed(true);
																						window.location.reload()
																					}
																					if (data.error) {
																						this.setState({ loadingLicense: false, isWarning: true, })
																					};
																				}
																				)
																				.catch(err => {
																					console.error("[Profile] error calling redeemLicense function:", err);
																				})
																		}}
																		className="filesManagerRedeemLicenseButton"
																	>
																		<Trans>
																			Riscatta
																		</Trans>
																	</IonButton>
																	<IonLabel className="warning" hidden={!this.state.isWarning}><Trans>Licenza non valida o incorretta</Trans>{/* TO BE LOCALIZED */} </IonLabel>
																</div>
															</IonAccordion>
														</IonAccordionGroup>
													</div>
												}
											</div>
											{!isMobile && (
												<div
													style={{
														display: "flex",
														justifyContent: "center",
														marginBottom: "50px",
													}}
												>
													<IonCard className="homeUploadFileCard">
														<div
															className="homeUploadFileDiv"
															onClick={() => {
																let fromButton = true;
																loadPaywall(fromButton).then((loadPay) => {
																	if (!loadPay && loadPay != undefined) {
																		this.setState({ showOcrPopover: true, mapFromPDF: false }, () => console.log(this.state.mapFromPDF));
																		sendEvent({
																			"user_id": this.state.userUuid,
																			"event_type": "Upload document pressed",
																			"event_properties": {
																				"user_org": this.props.orgData === 'Private' ? "Private User" : this.props.orgData !== null ? this.props.orgData.name : "Private User",
																			},
																			"language": i18n.language,
																			"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
																			"app_version": appVersion,
																			"time": Date.now(),
																		});
																	}
																});
															}}
														>
															<div className="homeUploadFileImage uploadFileIconDiv">
																<IonImg src={addFileButtonIcon} />
															</div>
															<p className="homeUploadFileParagraph">
																<Trans>Carica File</Trans>
															</p>
														</div>
													</IonCard>
													<IonCard className="homeUploadFileCard">
														<div
															className="homeUploadFileDiv"
															onClick={() => {
																let fromButton = true;
																loadPaywall(fromButton).then((loadPay) => {
																	if (!loadPay && loadPay != undefined) {
																		this.setState({ showMapTitle: true });
																	}
																});
															}}
														>
															<div className="homeUploadFileImage emptyMapIconDiv">
																<IonImg src={createBlankMapIcon} />
															</div>
															<p className="homeUploadFileParagraph">
																<Trans>Mappa vuota</Trans>
															</p>
														</div>
													</IonCard>
													<IonCard className="homeUploadFileCard" disabled={this.state.disablePdfToMap}>
														<div
															className="homeUploadFileDiv"
															onClick={(e) => {
																let fromButton = true;

																loadPaywall(fromButton).then((loadPay) => {
																	if (!loadPay && loadPay != undefined) {
																		console.log("erere")
																		sendEvent({
																			"user_id": this.state.userUuid,
																			"event_type": "PDF to Map Pressed",
																			"event_properties": {
																				"user_org": this.props.orgData === 'Private' ? "Private User" : this.props.orgData !== null ? this.props.orgData.name : "Private User",
																			},
																			"language": i18n.language,
																			"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
																			"app_version": appVersion,
																			"time": Date.now(),
																		});
																	}
																	else {
																		console.log("erere")

																		e.preventDefault()
																	}
																});
															}}
														>
															<IonSpinner hidden={!this.state.disablePdfToMap} className="pdfToMapSpinner" name="crescent" />
															<input
																className="inputFieldFile"
																type="file"
																id="inputFieldFile"
																onChange={(e) => {
																	// console.log("aaa")
																	const file = (e.nativeEvent.target as HTMLInputElement).files?.item(0);
																	console.log(this.state.fileDropped);
																	console.log(file);
																	this.setState({ isUploadingFile: true, mapFromPDF: true, disablePdfToMap: true }, () => {
																		this.uploadFile(file, this.state.userUuid, "");
																	});

																}}
																accept=".pdf"
															></input>
															<div className="homeUploadFileImage pdfToMapIconDiv">
																<IonImg src={pdfToMapIcon} />
															</div>
															<p className="homeUploadFileParagraph">
																<Trans>Mappa da PDF</Trans>
															</p>
														</div>
													</IonCard>
												</div>
											)}
											<div className="homeYourFilesDiv">
												<p className="homeYourFilesParagraph">
													<Trans>I tuoi file</Trans>
												</p>
												<div className="helpAndLayoutChange">
													<IonButton
														className="layoutChange"
														fill="clear"
														onClick={() => {
															this.setState(
																{
																	viewingMode: this.state.viewingMode === "grid" ? "list" : "grid",
																},
																() => {
																	sendEvent({
																		"user_id": this.state.userUuid,
																		"event_type": "Files layout changed",
																		"event_properties": {
																			"user_org": this.props.orgData === 'Private' ? "Private User" : this.props.orgData !== null ? this.props.orgData.name : "Private User",
																			"new_layout": this.state.viewingMode,
																		},
																		"language": i18n.language,
																		"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
																		"app_version": appVersion,
																		"time": Date.now(),
																	});
																	if (this.state.viewingMode === "grid") {
																		setTimeout(() => {
																			if (miniature.length > 0)
																				for (let i = 0; i < miniature.length; i++) {
																					console.log(i);
																					let id =
																						"#" +
																						"_" +
																						miniature[i].fileName
																							.split(".")[0]
																							.replace(/\s+/g, "_")
																							.replace(/[()]/g, "_")
																							.replace(/[^a-zA-Z0-9_]/g, "");
																					let arrays = document.querySelectorAll(id);
																					if (arrays.length != 0) {
																						arrays.forEach((element) => {
																							let imageObject = element as HTMLImageElement;
																							imageObject.style.background = "none";
																							if (miniature[i].thumbnails) imageObject.src = miniature[i].thumbnails;
																						});
																					} else {
																						let array_cards = document.getElementsByClassName("previewbox");
																						//console.log(array_cards)
																						for (let i = 0; i < array_cards.length; i++) {
																							if ((array_cards[i] as HTMLIonImgElement).src == undefined)
																								(array_cards[i] as HTMLIonImgElement).src = previewSampleImage;
																						}
																					}
																				}
																			else {
																				let array_cards = document.getElementsByClassName("previewbox");
																				//console.log(array_cards)
																				for (let i = 0; i < array_cards.length; i++) {
																					if ((array_cards[i] as HTMLIonImgElement).src == undefined)
																						(array_cards[i] as HTMLIonImgElement).src = previewSampleImage;
																				}
																			}
																		}, 500);
																	}
																}
															);
														}}
													>
														{!isMobile && <Trans>Visualizza {this.state.viewingMode === "grid" ? "Lista" : "Griglia"}</Trans>}
														<IonIcon slot="start" icon={this.state.viewingMode === "grid" ? listOutline : gridOutline} />
													</IonButton>
												</div>
											</div>
											{this.state.viewingMode === "grid" && (
												<>
													<IonRow className="cardContainer">
														{this.state.documents
															.slice() // Create a shallow copy to avoid modifying the original array
															.sort((a, b) => {
																const nameA = a.name || ""; // Use an empty string if 'name' is not available
																const nameB = b.name || ""; // Use an empty string if 'name' is not available
																return nameA.localeCompare(nameB);
															}) // Sort by name
															.map((file) => {
																return (
																	<div key={file.uuid} id={`d${file.uuid}`} className="fileManagerItem">
																		<IonCard
																			id={file.uuid + 1}
																			onTouchStartCapture={(e) => {
																				// e.persist();
																				// let fromButton = true;
																				// loadPaywall(fromButton).then((loadPay) => {
																				// 	console.log("file PAY", loadPay);
																				// 	if (!loadPay && loadPay != undefined) {
																				// 		console.log("REERasasaER")

																				// 		this.timer = setTimeout(
																				// 			() =>
																				// 				this.setState({
																				// 					showFilePopover: true,
																				// 					filePopoverEvent: e.nativeEvent,
																				// 					selectedFileWithContextMenu: file.uuid,
																				// 				}),
																				// 			600
																				// 		);
																				// 	}

																				// });
																			}}
																			onClick={async (e) => {
																				let fromButton = true;
																				console.log(this.state.paywall)
																				if (this.state.paywall) {
																					e.preventDefault();
																					loadPaywall(fromButton);
																				} else {
																					await loadPaywall(fromButton).then((loadPay) => {
																						if (this.state.userUuid) {
																							sendEvent({
																								"user_id": this.state.userUuid,
																								"event_type": "Document opened",
																								"event_properties": {
																									"document_uuid": file.uuid,
																									"document_name": file.name,
																									"is_tutorial": file.tipo === "tutorial",
																									"user_org": this.props.orgData === 'Private' ? "Private User" : this.props.orgData !== null ? this.props.orgData.name : "Private User",
																								},
																								"language": i18n.language,
																								"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
																								"app_version": appVersion,
																								"time": Date.now(),
																							});
																						}
																						if (!loadPay && loadPay != undefined) {
																							e.persist();
																							this.setState({
																								showFilePopover: true,
																								filePopoverEvent: e.nativeEvent,
																								selectedFileWithContextMenu: file.uuid,
																							});
																						} else {
																							loadPaywall(fromButton)
																							console.log("CASX")
																							e.preventDefault()
																						}
																					});
																				}
																			}}
																			onTouchEnd={(e) => {
																				clearInterval(this.timer);
																			}}
																			onContextMenu={(e) => {



																				// e.persist();

																				// this.setState({
																				// 	showFilePopover: true,
																				// 	filePopoverEvent: e.nativeEvent,
																				// 	selectedFileWithContextMenu: file.uuid,
																				// });


																			}}
																			key={`d${file.uuid}`}
																			routerLink={
																				(!this.state.paywall ? (this.state.showFilePopover ? undefined : this.state.renameFile && this.state.selectedFileWithContextMenu === file.uuid ? undefined : `/file/${file.uuid}`) : undefined)
																			}
																			style={{
																				width: "125px",
																				boxShadow: "none",
																				background: "transparent",
																			}}
																		>
																			<IonCardContent>
																				<div id={file.uuid + 2} className="lds-dual-ring"></div>

																				<div
																					style={{
																						display: "flex",
																						height: "140px",
																						overflow: "hidden",
																						alignContent: "center",
																						justifyContent: "center",
																					}}
																				>
																					<IonImg
																						className="previewbox"
																						id={
																							"#" +
																							"_" +
																							file.name
																								.split(".")[0]
																								.replace(/\s+/g, "_")
																								.replace(/[()]/g, "_")
																								.replace(/[^a-zA-Z0-9_]/g, "")
																						}
																					/>
																				</div>
																			</IonCardContent>
																		</IonCard>
																		<p
																			id={file.uuid}
																			contentEditable={this.state.renameFile && this.state.selectedFileWithContextMenu === file.uuid}
																			onChange={() => { }}
																			className="fileManagerFileName"
																		>
																			{file.name}
																		</p>
																		<div
																			onClick={(e) => e.preventDefault()}
																			hidden={!this.state.renameFile || this.state.selectedFileWithContextMenu !== file.uuid}
																			style={{
																				display: "flex",
																				justifyContent: "center",
																				cursor: "pointer",
																			}}
																		>
																			<IonIcon
																				onClick={() => {
																					let p = document.getElementById(file.uuid);
																					this.renameFile(p!.innerText);
																					this.setState({
																						selectedFileWithContextMenu: "",
																						// showFilePopover: false,
																						showRenameToast: true,
																						renameFile: false,
																					});
																				}}
																				icon={checkmarkOutline}
																				size="large"
																			/>
																		</div>
																		<div
																			className="fileOptions"
																			style={{
																				display: "flex",
																				justifyContent: "center",
																			}}
																		>
																			<IonIcon
																				size="large"
																				onClick={(e) => {
																					// e.persist();
																					// e.preventDefault();
																					let fromButton = true;
																					loadPaywall(fromButton).then((loadPay) => {
																						if (!loadPay && loadPay != undefined) {
																							e.persist()
																							this.setState({
																								showFilePopover: true,
																								filePopoverEvent: e.nativeEvent,
																								selectedFileWithContextMenu: file.uuid,
																							});
																						} else {
																							e.preventDefault()
																						}
																					});
																				}}
																				className="fileManagerGridFileIcon"
																				icon={ellipsisHorizontal}
																			/>
																		</div>
																	</div>
																);
															})}
													</IonRow>
													<p className="homeYourFilesParagraph" hidden={this.state.maps.length == 0 ? true : false}>
														<Trans>Le tue Mappe</Trans>
													</p>
													<IonRow className="cardContainer">
														{this.state.maps
															.slice() // Create a shallow copy to avoid modifying the original array
															.sort((a, b) => {
																const nameA = a.name || ""; // Use an empty string if 'name' is not available
																const nameB = b.name || ""; // Use an empty string if 'name' is not available
																return nameA.localeCompare(nameB);
															}) // Sort by name
															.map((file) => {
																return (
																	<div key={file.uuid} id={`d${file.uuid}`}>
																		<IonCard
																			className="fileManagerItem"
																			style={{ width: "140px" }}
																			onTouchStartCapture={(e) => {
																				let fromButton = true;
																				loadPaywall(fromButton).then((loadPay) => {
																					// console.log("file PAY", loadPay);
																					if (!loadPay && loadPay != undefined) {
																						e.persist();

																						this.timer = setTimeout(
																							() =>
																								this.setState({
																									showFilePopover: true,
																									filePopoverEvent: e.nativeEvent,
																									selectedFileWithContextMenu: file.uuid,
																								}),
																							600
																						);
																					}
																					else {
																						loadPaywall(fromButton)
																						console.log("CASX")
																						e.preventDefault()
																					}

																				});
																			}}
																			onClick={(e) => {
																				let fromButton = true;
																				if (this.state.paywall) {
																					e.preventDefault();
																					loadPaywall(fromButton);
																				} else {
																					loadPaywall(fromButton).then((loadPay) => {
																						// console.log("file PAY", loadPay);

																						if (!loadPay && loadPay != undefined) {
																							e.persist();

																							this.setState({
																								showFilePopover: true,
																								filePopoverEvent: e.nativeEvent,
																								selectedFileWithContextMenu: file.uuid,
																							});
																						}
																						else {
																							loadPaywall(fromButton)
																							console.log("CASX")
																							e.preventDefault()
																						}
																					});
																				}
																			}}
																			onTouchEnd={(e) => {
																				clearInterval(this.timer);
																			}}
																			// onContextMenu={(e) => {
																			//   e.persist();

																			//   let fromButton = true;
																			//   loadPaywall(fromButton).then(
																			//     (loadPay) => {
																			//       e.preventDefault();
																			//       if (
																			//         !loadPay &&
																			//         loadPay != undefined
																			//       ) {
																			//         this.setState({
																			//           showFilePopover: true,
																			//           filePopoverEvent: e.nativeEvent,
																			//           selectedFileWithContextMenu:
																			//             file.uuid,
																			//         });
																			//       }
																			//     }
																			//   );
																			// }}
																			key={`d${file.uuid}`}
																			routerLink={
																				(!this.state.paywall ? (this.state.renameFile && this.state.selectedFileWithContextMenu === file.uuid ? undefined : `/maps/${file.uuid}`) : undefined)
																			}
																		>
																			<IonCardContent>
																				<div
																					style={{
																						display: "flex",
																						height: "140px",
																						overflow: "hidden",
																						alignContent: "center",
																						justifyContent: "center",
																					}}
																				>
																					<IonImg
																						// onTouchStart={(e) => {
																						//   e.persist();
																						//   let fromButton = true;
																						//   loadPaywall(fromButton).then(
																						//     (loadPay) => {
																						//       e.persist();
																						//       if (
																						//         !loadPay &&
																						//         loadPay != undefined
																						//       ) {
																						//         this.timer = setTimeout(
																						//           () =>
																						//             this.setState({
																						//               showFilePopover: true,
																						//               filePopoverEvent:
																						//                 e.nativeEvent,
																						//               selectedFileWithContextMenu:
																						//                 file.uuid,
																						//             }),
																						//           600
																						//         );
																						//       }
																						//     }
																						//   );
																						// }}
																						// onClick={(e) => {
																						//   let fromButton = true;
																						//   console.log(this.state.paywall);
																						//   if (this.state.paywall) {
																						//     e.preventDefault();
																						//     loadPaywall(fromButton);
																						//   } else {
																						//     e.persist();
																						//     loadPaywall(fromButton).then(
																						//       (loadPay) => {
																						//         console.log(
																						//           "sasclick",
																						//           loadPay
																						//         );
																						//         if (
																						//           !loadPay &&
																						//           loadPay != undefined
																						//         ) {
																						//           // this.props.history.push({
																						//           //   pathname: `/maps/${file.uuid}`,
																						//           //   state: {
																						//           //     docTitle: file.name,
																						//           //     documentData: "",
																						//           //     docId: file.uuid,
																						//           //     onlyMap: true,
																						//           //   },
																						//           // });
																						//         }
																						//       }
																						//     );
																						//   }
																						// }}
																						// onTouchEnd={(e) => {
																						//   clearInterval(this.timer);
																						// }}
																						// onContextMenu={(e) => {
																						//   e.persist();

																						//   let fromButton = true;
																						//   loadPaywall(fromButton).then(
																						//     (loadPay) => {
																						//       e.preventDefault();
																						//       console.log("sasa", loadPay);
																						//       if (
																						//         !loadPay &&
																						//         loadPay != undefined
																						//       ) {
																						//         this.setState({
																						//           showFilePopover: true,
																						//           filePopoverEvent:
																						//             e.nativeEvent,
																						//           selectedFileWithContextMenu:
																						//             file.uuid,
																						//         });
																						//       }
																						//     }
																						//   );
																						// }}
																						key={`d${file.uuid}`}
																						className="previewbox"
																						id={file.name}
																					/>
																				</div>
																			</IonCardContent>
																			<p
																				id={file.uuid}
																				contentEditable={this.state.renameFile && this.state.selectedFileWithContextMenu === file.uuid}
																				onChange={() => { }}
																				className="fileManagerFileName"
																			>
																				{file.name}
																			</p>
																			<div
																				hidden={!this.state.renameFile || this.state.selectedFileWithContextMenu !== file.uuid}
																				style={{
																					display: "flex",
																					justifyContent: "center",
																				}}
																			>
																				<IonIcon
																					onClick={() => {
																						let p = document.getElementById(file.uuid);
																						this.renameFile(p!.innerText);
																						this.setState({
																							selectedFileWithContextMenu: "",
																							showFilePopover: false,
																							showRenameToast: true,
																							renameFile: false,
																						});
																					}}
																					icon={checkmarkOutline}
																					size="large"
																				/>
																			</div>
																			<div
																				className="fileOptions"
																				style={{
																					display: "flex",
																					justifyContent: "center",
																				}}
																			>
																				{/* <IonIcon
                                        size="large"
                                        onClick={(e) => {
                                          e.persist();
                                          e.preventDefault();
                                          let fromButton = true;
                                          loadPaywall(fromButton).then(
                                            (loadPay) => {
                                              console.log("sasa", loadPay);
                                              if (
                                                !loadPay &&
                                                loadPay != undefined
                                              ) {
                                                this.setState({
                                                  showFilePopover: true,
                                                  filePopoverEvent:
                                                    e.nativeEvent,
                                                  selectedFileWithContextMenu:
                                                    file.uuid,
                                                });
                                              }
                                            }
                                          );
                                        }}
                                        className="fileManagerGridFileIcon"
                                        icon={ellipsisHorizontal}
                                      /> */}
																			</div>
																		</IonCard>
																	</div>
																);
															})}
													</IonRow>
													<p className="homeYourFilesParagraph" hidden={this.state.extensionSummaries.length == 0 ? true : false}>
														<Trans>Riassunti e parole chiave estensione</Trans>
													</p>
													<IonRow className="cardContainer">
														{this.state.extensionSummaries
															.slice() // Create a shallow copy to avoid modifying the original array
															.sort((a, b) => {
																const nameA = a.name || ""; // Use an empty string if 'name' is not available
																const nameB = b.name || ""; // Use an empty string if 'name' is not available
																return nameA.localeCompare(nameB);
															}) // Sort by name
															.map((file) => {
																return (
																	<div key={file.uuid} id={`d${file.uuid}`} className="fileManagerItem">
																		<IonCard
																			onTouchStartCapture={(e) => {
																				e.persist();
																				let fromButton = true;
																				loadPaywall(fromButton).then((loadPay) => {
																					// console.log("file PAY", loadPay);
																					e.persist();
																					if (!loadPay && loadPay != undefined) {
																						this.timer = setTimeout(
																							() =>
																								this.setState({
																									showFilePopover: true,
																									filePopoverEvent: e.nativeEvent,
																									selectedFileWithContextMenu: file.uuid,
																								}),
																							600
																						);
																					}
																				});
																			}}
																			onClick={(e) => {
																				let fromButton = true;
																				if (this.state.paywall) {
																					e.preventDefault();
																					loadPaywall(fromButton);
																				} else {
																					loadPaywall(fromButton).then((loadPay) => {
																						// console.log("file PAY", loadPay);
																						// if (this.state.userUuid) {
																						// 	sendEvent({
																						// 		"user_id": this.state.userUuid,
																						// 		"event_type": "Document opened",
																						// 		"event_properties": {
																						// 			"document_uuid": file.uuid,
																						// 			"document_name": file.name,
																						// 			"is_summary": file.tipo === "summary"? "summary" : file.tipo==="keywords"?"keywords":"false",
																						// 			"user_org": this.props.organizationName !== null ? this.props.organizationName : "Private User",
																						// 		},
																						// 		"language": i18n.language,
																						// 		"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
																						// 		"app_version": appVersion,
																						// 		"time": Date.now(),
																						// 	});
																						// }
																						if (!loadPay && loadPay != undefined) {
																							e.persist();
																							this.setState({
																								showFilePopover: true,
																								filePopoverEvent: e.nativeEvent,
																								selectedFileWithContextMenu: file.uuid,
																							});
																						}
																						else {
																							loadPaywall(fromButton)
																							console.log("CASX")
																							e.preventDefault()
																						}
																					});
																				}
																			}}
																			onTouchEnd={(e) => {
																				clearInterval(this.timer);
																			}}
																			// onContextMenu={(e) => {
																			//   e.persist();

																			//   let fromButton = true;
																			//   loadPaywall(fromButton).then(
																			//     (loadPay) => {
																			//       e.preventDefault();
																			//       if (
																			//         !loadPay &&
																			//         loadPay != undefined
																			//       ) {
																			//         this.setState({
																			//           showFilePopover: true,
																			//           filePopoverEvent: e.nativeEvent,
																			//           selectedFileWithContextMenu:
																			//             file.uuid,
																			//         });
																			//       }
																			//     }
																			//   );
																			// }}
																			key={`d${file.uuid}`}
																			routerLink={
																				(!this.state.paywall ? (this.state.renameFile && this.state.selectedFileWithContextMenu === file.uuid ? undefined : `/summary/${file.uuid}`) : undefined)


																			}
																			style={{
																				width: "125px",
																				boxShadow: "none",
																				background: "transparent",
																			}}
																		>
																			<IonCardContent>
																				<div
																					style={{
																						display: "flex",
																						height: "140px",
																						overflow: "hidden",
																						alignContent: "center",
																						justifyContent: "center",
																					}}
																				>
																					<IonImg
																						// onTouchStart={(e) => {
																						//   e.persist();
																						//   let fromButton = true;
																						//   loadPaywall(fromButton).then(
																						//     (loadPay) => {
																						//       e.persist();
																						//       if (
																						//         !loadPay &&
																						//         loadPay != undefined
																						//       ) {
																						//         this.timer = setTimeout(
																						//           () =>
																						//             this.setState({
																						//               showFilePopover: true,
																						//               filePopoverEvent:
																						//                 e.nativeEvent,
																						//               selectedFileWithContextMenu:
																						//                 file.uuid,
																						//             }),
																						//           600
																						//         );
																						//       }
																						//     }
																						//   );
																						// }}
																						// onClick={(e) => {
																						//   let fromButton = true;
																						//   console.log(this.state.paywall);
																						//   if (this.state.paywall) {
																						//     e.preventDefault();
																						//     loadPaywall(fromButton);
																						//   } else {
																						//     e.persist();
																						//     loadPaywall(fromButton).then(
																						//       (loadPay) => {
																						//         console.log(
																						//           "sasclick",
																						//           loadPay
																						//         );
																						//         if (
																						//           !loadPay &&
																						//           loadPay != undefined
																						//         ) {
																						//           // this.props.history.push({
																						//           //   pathname: `/maps/${file.uuid}`,
																						//           //   state: {
																						//           //     docTitle: file.name,
																						//           //     documentData: "",
																						//           //     docId: file.uuid,
																						//           //     onlyMap: true,
																						//           //   },
																						//           // });
																						//         }
																						//       }
																						//     );
																						//   }
																						// }}
																						// onTouchEnd={(e) => {
																						//   clearInterval(this.timer);
																						// }}
																						// onContextMenu={(e) => {
																						//   e.persist();

																						//   let fromButton = true;
																						//   loadPaywall(fromButton).then(
																						//     (loadPay) => {
																						//       e.preventDefault();
																						//       console.log("sasa", loadPay);
																						//       if (
																						//         !loadPay &&
																						//         loadPay != undefined
																						//       ) {
																						//         this.setState({
																						//           showFilePopover: true,
																						//           filePopoverEvent:
																						//             e.nativeEvent,
																						//           selectedFileWithContextMenu:
																						//             file.uuid,
																						//         });
																						//       }
																						//     }
																						//   );
																						// }}
																						key={`d${file.uuid}`}
																						className="previewbox"
																						id={file.name}
																					/>
																				</div>
																			</IonCardContent>
																		</IonCard>

																		<p
																			id={file.uuid}
																			contentEditable={this.state.renameFile && this.state.selectedFileWithContextMenu === file.uuid}
																			onChange={() => { }}
																			className="fileManagerFileName"
																		>
																			{file.name}
																		</p>
																		<div
																			onClick={(e) => e.preventDefault()}
																			hidden={!this.state.renameFile || this.state.selectedFileWithContextMenu !== file.uuid}
																			style={{
																				display: "flex",
																				justifyContent: "center",
																				cursor: "pointer",
																			}}
																		>
																			<IonIcon
																				onClick={() => {
																					let p = document.getElementById(file.uuid);
																					this.renameFile(p!.innerText);
																					this.setState({
																						selectedFileWithContextMenu: "",
																						// showFilePopover: false,
																						showRenameToast: true,
																						renameFile: false,
																					});
																				}}
																				icon={checkmarkOutline}
																				size="large"
																			/>
																		</div>
																		<div
																			className="fileOptions"
																			style={{
																				display: "flex",
																				justifyContent: "center",
																			}}
																		>
																			<IonIcon
																				size="large"
																				onClick={(e) => {
																					e.persist();
																					e.preventDefault();
																					let fromButton = true;
																					loadPaywall(fromButton).then((loadPay) => {
																						console.log("sasa", loadPay);
																						if (!loadPay && loadPay != undefined) {
																							this.setState({
																								showFilePopover: true,
																								filePopoverEvent: e.nativeEvent,
																								selectedFileWithContextMenu: file.uuid,
																								docType: file.tipo,
																							});
																						}
																					});
																				}}
																				className="fileManagerGridFileIcon"
																				icon={ellipsisHorizontal}
																			/>
																		</div>
																	</div>
																);
															})}
													</IonRow>
												</>
											)}

											{this.state.viewingMode === "list" && (
												<>
													{" "}
													<IonList className="fileManagerList">
														{this.state.documents
															.slice() // Create a shallow copy to avoid modifying the original array
															.sort((a, b) => a.name.localeCompare(b.name)) // Sort by name
															.map((file) => {
																return (
																	<div className="fileManagerListDiv" key={file.uuid} id={`d${file.uuid}`}>
																		<IonItem
																			detail={false}
																			onTouchStart={(e) => {
																				e.preventDefault();
																				this.timer = setTimeout(
																					() =>
																						this.setState({
																							showFilePopover: true,
																							filePopoverEvent: e.nativeEvent,
																							selectedFileWithContextMenu: file.uuid,
																						}),
																					600
																				);
																			}}
																			onTouchEnd={(e) => clearInterval(this.timer)}
																			onContextMenu={(e) => {
																				e.preventDefault();
																				this.setState({
																					showFilePopover: true,
																					filePopoverEvent: e.nativeEvent,
																					selectedFileWithContextMenu: file.uuid,
																				});
																			}}
																			key={`d${file.uuid}`}
																			className="fileManagerListItem"
																			lines="none"
																			onClick={() => {
																				// if (this.state.userUuid) {
																				// 	userServices.getUserOrganizations(this.state.userUuid,this.state.bearerToken).then((organization: Organization | null | 'Private') => {
																				// 		if (organization)
																				// 			sendEvent({
																				// 				"user_id": this.state.userUuid,
																				// 				"event_type": "Document opened",
																				// 				"event_properties": {
																				// 					"document_uuid": file.uuid,
																				// 					"document_name": file.name,
																				// 					"is_tutorial": file.tipo === "tutorial",
																				// 					"user_org": organization === 'Private' ? 'Private User' : organization.name !== null ? organization.name : "Private User",
																				// 				},
																				// 				"language": i18n.language,
																				// 				"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
																				// 				"app_version": appVersion,
																				// 				"time": Date.now(),
																				// 			});
																				// 	});
																				// }
																			}}
																			routerLink={`/file/${file.uuid}`}
																		>
																			{/* <IonCardContent>
                                            {
                                                file.preview &&
                                                <IonImg
                                                    src={file.preview}
                                                />
                                            }
                                            {
                                                !file.preview &&
                                                <p>
                                                    <IonImg src={previewSampleImage}></IonImg>
                                                </p>
                                            }

                                        </IonCardContent> */}
																			<p id={file.uuid} className="fileManagerFileName">
																				{file.name}
																			</p>
																			<IonIcon
																				size="large"
																				onClick={(e) => {
																					e.preventDefault();
																					e.stopPropagation();
																					this.setState({
																						selectedFileWithContextMenu: file.uuid,
																						showFilePopover: true,
																						filePopoverEvent: e.nativeEvent,
																					});
																				}}
																				className="fileManagerListIcon"
																				slot="end"
																				icon={ellipsisHorizontal}
																			/>
																		</IonItem>
																	</div>
																);
															})}
													</IonList>
													<p className="homeYourFilesParagraph" hidden={this.state.maps.length == 0 ? true : false}>
														<Trans>Le tue Mappe</Trans>
													</p>
													<IonList className="fileManagerList">
														{this.state.maps
															.slice() // Create a shallow copy to avoid modifying the original array
															.sort((a, b) => a.name.localeCompare(b.name)) // Sort by name
															.map((file) => {
																return (
																	<div className="fileManagerListDiv" key={file.uuid} id={`d${file.uuid}`}>
																		<IonItem
																			detail={false}
																			onTouchStart={(e) => {
																				e.persist();
																				let fromButton = true;
																				loadPaywall(fromButton).then((loadPay) => {
																					e.persist();
																					console.log("sastouch", loadPay);
																					if (!loadPay && loadPay != undefined) {
																						this.timer = setTimeout(
																							() =>
																								this.setState({
																									showFilePopover: true,
																									filePopoverEvent: e.nativeEvent,
																									selectedFileWithContextMenu: file.uuid,
																								}),
																							600
																						);
																					}
																				});
																			}}
																			onClick={(e) => {
																				let fromButton = true;
																				console.log(this.state.paywall);
																				if (this.state.paywall) {
																					e.preventDefault();
																					loadPaywall(fromButton);
																				} else {
																					e.persist();
																					loadPaywall(fromButton).then((loadPay) => {
																						console.log("sasclick", loadPay);
																						if (!loadPay && loadPay != undefined) {
																							// this.props.history.push({
																							//   pathname: `/maps/${file.uuid}`,
																							//   state: {
																							//     docTitle: file.name,
																							//     documentData: "",
																							//     docId: file.uuid,
																							//     onlyMap: true,
																							//   },
																							// });
																						}
																					});
																				}
																			}}
																			onTouchEnd={(e) => {
																				clearInterval(this.timer);
																			}}
																			onContextMenu={(e) => {
																				e.persist();

																				let fromButton = true;
																				loadPaywall(fromButton).then((loadPay) => {
																					e.preventDefault();
																					console.log("sasa", loadPay);
																					if (!loadPay && loadPay != undefined) {
																						this.setState({
																							showFilePopover: true,
																							filePopoverEvent: e.nativeEvent,
																							selectedFileWithContextMenu: file.uuid,
																						});
																					}
																				});
																			}}
																			key={`d${file.uuid}`}
																			className="fileManagerListItem"
																			lines="none"
																			routerLink={`/maps/${file.uuid}`}
																		>
																			{/* <IonCardContent>
                                            {
                                                file.preview &&
                                                <IonImg
                                                    src={file.preview}
                                                />
                                            }
                                            {
                                                !file.preview &&
                                                <p>
                                                    <IonImg src={previewSampleImage}></IonImg>
                                                </p>
                                            }

                                        </IonCardContent> */}
																			<p id={file.uuid} className="fileManagerFileName">
																				{file.name}
																			</p>
																			{/* <IonIcon
                                    size="large"
                                    color="dark"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      this.setState({
                                        selectedFileWithContextMenu: file.uuid,
                                        showFilePopover: true,
                                        filePopoverEvent: e.nativeEvent,
                                      });
                                    }}
                                    className="fileManagerListIcon"
                                    slot="end"
                                    icon={ellipsisHorizontal}
                                  /> */}
																		</IonItem>
																	</div>
																);
															})}
													</IonList>
													<p className="homeYourFilesParagraph" hidden={this.state.extensionSummaries.length == 0 ? true : false}>
														<Trans>Riassunti e parole chiave estensione</Trans>
													</p>
													<IonList className="fileManagerList">
														{this.state.extensionSummaries
															.slice() // Create a shallow copy to avoid modifying the original array
															.sort((a, b) => {
																const nameA = a.name || ""; // Use an empty string if 'name' is not available
																const nameB = b.name || ""; // Use an empty string if 'name' is not available
																return nameA.localeCompare(nameB);
															}) // Sort by name
															.map((file) => {
																return (
																	<div className="fileManagerListDiv" key={file.uuid} id={`d${file.uuid}`}>
																		<IonItem
																			detail={false}
																			onTouchStart={(e) => {
																				e.preventDefault();
																				this.timer = setTimeout(
																					() =>
																						this.setState({
																							showFilePopover: true,
																							filePopoverEvent: e.nativeEvent,
																							selectedFileWithContextMenu: file.uuid,
																						}),
																					600
																				);
																			}}
																			onTouchEnd={(e) => clearInterval(this.timer)}
																			onContextMenu={(e) => {
																				e.preventDefault();
																				this.setState({
																					showFilePopover: true,
																					filePopoverEvent: e.nativeEvent,
																					selectedFileWithContextMenu: file.uuid,
																				});
																			}}
																			key={`d${file.uuid}`}
																			className="fileManagerListItem"
																			lines="none"
																			onClick={() => {
																				if (this.state.userUuid) {
																					// userServices.getUserOrganization(this.state.userUuid).then((organization: Organization | null | 'Private') => {
																					// 	if (organization)
																					// 		sendEvent({
																					// 			"user_id": this.state.userUuid,
																					// 			"event_type": "Document opened",
																					// 			"event_properties": {
																					// 				"document_uuid": file.uuid,
																					// 				"document_name": file.name,
																					// 				"is_tutorial": file.tipo === "tutorial",
																					// 				"user_org": organization === 'Private' ? 'Private User' : organization.name !== null ? organization.name : "Private User",
																					// 			},
																					// 			"language": i18n.language,
																					// 			"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
																					// 			"app_version": appVersion,
																					// 			"time": Date.now(),
																					// 		});
																					// });
																				}
																			}}
																			routerLink={`/summary/${file.uuid}`}
																		>
																			<p id={file.uuid} className="fileManagerFileName">
																				{file.name}
																			</p>
																			<IonIcon
																				size="large"
																				onClick={(e) => {
																					e.preventDefault();
																					e.stopPropagation();
																					this.setState({
																						selectedFileWithContextMenu: file.uuid,
																						showFilePopover: true,
																						filePopoverEvent: e.nativeEvent,
																					});
																				}}
																				className="fileManagerListIcon"
																				slot="end"
																				icon={ellipsisHorizontal}
																			/>
																		</IonItem>
																	</div>
																);
															})}
													</IonList>
												</>
											)}

											<IonPopover
												className="resolution"
												animated={false}
												showBackdrop={false}
												event={this.state.filePopoverEvent}
												isOpen={this.state.showFilePopover}
												onDidDismiss={() => {
													this.setState(
														{
															showFilePopover: false,
															filePopoverEvent: undefined,
														},
														() => window.scrollTo(0, this.state.lastScrollTop)
													);
												}}
												onDidPresent={() => {
													this.setState({ lastScrollTop: window.scrollX }, () => console.log(this.state.lastScrollTop));

													sendEvent({
														"user_id": this.state.userUuid,
														"event_type": "Document option pressed",
														"event_properties": {
															"user_org": this.props.orgData === 'Private' ? "Private User" : this.props.orgData !== null ? this.props.orgData.name : "Private User",
														},
														"language": i18n.language,
														"app_version": appVersion,
														"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
														"time": Date.now(),
													});
												}}
											>
												<IonItem
													button
													lines="none"
													detail={false}
													onClick={() => {
														this.setState({ showDeleteFileAlert: true });
													}}
												>
													<IonIcon slot="start" icon={trash} />
													<Trans>Rimuovi file</Trans>
												</IonItem>
												<IonItem
													button
													lines="none"
													detail={false}
													onClick={() => {
														//Rename file
														this.setState({
															renameFile: true,
															showFilePopover: false,
														});
														if (this.state.selectedFileWithContextMenu) {
															let p = document.getElementById(this.state.selectedFileWithContextMenu);
															if (p) {
																p.focus();
																console.log(p);
															}
														}
													}}
												>
													<IonIcon slot="start" icon={pencilEdit} />
													<Trans>Rinomina file</Trans>
												</IonItem>
											</IonPopover>
											<IonAlert
												isOpen={this.state.showDeleteFileAlert}
												onDidDismiss={() =>
													this.setState({
														showDeleteFileAlert: false,
														showFilePopover: false,
													})
												}
												header={
													i18n.language === "en"
														? "Delete document"
														: i18n.language === "es"
															? "Eliminar documento"
															: i18n.language === "fr"
																? "Supprimer le document"
																: i18n.language === "de"
																	? "Dokument entfernen"
																	: "Rimuovi documento"
												} /* TO BE LOCALIZED */
												message={
													i18n.language === "en"
														? "Are you sure you want to remove the file?"
														: i18n.language === "es"
															? "¿Está seguro de que desea eliminar el archivo?"
															: i18n.language === "fr"
																? "Êtes-vous sûr de vouloir supprimer le fichier?"
																: i18n.language === "de"
																	? "Sind Sie sicher, dass Sie die Datei entfernen möchten?"
																	: "Si è sicuri di voler rimuovere il file?"
												}
												buttons={[
													{
														text:
															i18n.language === "en"
																? "Delete"
																: i18n.language === "es"
																	? "Eliminar"
																	: i18n.language === "fr"
																		? "Supprimer"
																		: i18n.language === "de"
																			? "Entfernen"
																			: "Rimuovi",
														handler: () => {
															if (this.state.selectedFileWithContextMenu) {
																this.setState({ isDeletingFile: true });
																let element = Array.from(document.getElementsByClassName("fileManagerFileName"));
																element.forEach((element) => {
																	if (
																		element.textContent?.toLowerCase() !== "benvenuto su reasy" ||
																		element.textContent?.toLowerCase() !== "willkommen bei reasy" ||
																		element.textContent?.toLowerCase() !== "bienvenue sur reasy" ||
																		element.textContent?.toLowerCase() !== "bienvenido a reasy" ||
																		element.textContent?.toLowerCase() !== "welcome to reasy"
																	) {
																		settingsServices.setUserTutorialDocumentChoice(this.state.userUuid ? this.state.userUuid : "", true);
																		this.setState({ testDocument: false });
																	}
																});
																let summaryToDelete: boolean = false;
																let keywordsToDelete: boolean = false;

																if (this.state.docType == "summary") {
																	summaryToDelete = true;
																	keywordsToDelete = true;
																} else if (this.state.docType === "keywords") {
																	summaryToDelete = true;
																	keywordsToDelete = true;
																} else {
																	summaryToDelete = false;
																	keywordsToDelete = false;
																}
																console.log(summaryToDelete, keywordsToDelete);
																filesServices.deleteFile(this.state.selectedFileWithContextMenu, summaryToDelete, keywordsToDelete).then(() => {
																	this.setState({ isDeletingFile: false }, () => {
																		sendEvent({
																			"user_id": this.state.userUuid,
																			"event_type": "Document deleted",
																			"event_properties": {
																				"user_org": this.props.orgData === 'Private' ? "Private User" : this.props.orgData !== null ? this.props.orgData.name : "Private User",
																			},
																			"language": i18n.language,
																			"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
																			"app_version": appVersion,
																			"time": Date.now(),
																		});
																	});
																});
															}
														},
													},
												]}
											/>
										</IonGrid>
									)}
								</div>
							</section>
						)}
					</Dropzone>
					<IonToast
						onDidDismiss={() => this.setState({ showRenameToast: false })}
						isOpen={this.state.showRenameToast}
						message={
							i18n.language === "en"
								? "File renamed successfully!"
								: i18n.language === "es"
									? "¡Archivo renombrado con éxito!"
									: i18n.language === "fr"
										? "Fichier renommé avec succès!"
										: i18n.language === "de"
											? "Datei erfolgreich umbenannt!"
											: "File rinominato con successo!"
						}
						color="warning"
						duration={2000}
					/>
				</IonContent>
				{/* <IonFab horizontal={isMobile ? "start" : "end"} vertical="bottom" className={isMobile ? "filesManagerAddFab" : "filesManagerHelpFab"} slot="fixed">
					<IonFabButton
						className={isMobile ? "filesManagerTutorialFabButton" : "filesManagerHelpFabButton"}
						onClick={() => {
							this.setState({ tutorial: true });
							sendEvent({
								"user_id": this.state.userUuid,
								"event_type": "Tutorial opened",
								"event_properties": {
									"user_org": this.props.orgData !== null ? this.props.orgData.name : "Private User",
									"page": "Files Manager",
								},
								"language": i18n.language,
								"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
								"app_version": appVersion,
								"time": Date.now(),
							});
							if (!this.state.testDocument) {
								this.getTutorial(this.state.querySnapshot);
							}
						}}
					>
						<IonIcon
							id="homeHelpIcon"
							className="helpIconColor"
							icon={helpCircleOutline}
							style={{ fontSize: "35px" }}
						></IonIcon>
					</IonFabButton>
				</IonFab> */}
				<IonFab
					hidden={!isMobile || this.state.isLoadingFoldersAndFiles}
					horizontal="end"
					vertical="bottom"
					slot="fixed"
					className="filesManagerAddFab"
					onClick={() => {
						if (isPlatform("capacitor") || isPlatform("cordova")) {
							// // this.uploadFileFromMobile()
							let fromButton = true;
							if (this.state.paywall) loadPaywall(fromButton);
							else this.setState({ showOcrPopover: true });
						}
					}}
				>
					<IonFabButton
						className={"filesManagerAddFabButton"}
						onClick={() => {
							let fromButton = true;
							loadPaywall(fromButton).then((loadPay) => {
								console.log("sasa", loadPay);
								if (!loadPay && loadPay != undefined) {
									this.setState({ showOcrPopover: true });
									sendEvent({
										"user_id": this.state.userUuid,
										"event_type": "Upload document pressed",
										"language": i18n.language,
										"event_properties": {
											"user_org": this.props.orgData === 'Private' ? "Private User" : this.props.orgData !== null ? this.props.orgData.name : "Private User",
										},
										"platform": isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "desktop",
										"app_version": appVersion,
										"time": Date.now(),
									});
								}
							});
						}}
					>
						<IonIcon icon={add} size="large" style={{ height: "44%", width: "44%" }} />
					</IonFabButton>
				</IonFab>

				{/* <MenuTabBar />*/}
				<IonPopover
					mode="md"
					className={"fileManagerOcrPopover"}
					isOpen={this.state.showOcrPopover}
					onDidDismiss={() => {
						this.setState({ showOcrPopover: false, mapFromPDF: false });
					}}
				>
					<p className="fileManagerOcrPopoverTitle">
						<Trans>Che tipo di file vuoi caricare?</Trans>
					</p>

					<div className={"fileManagerOcrPopoverDiv"}>
						<>
							<IonItem
								id="aaaa"
								hidden={this.state.mapFromPDF}
								className={isMobile ? "fileManagerOcrPopoverItemMobile uploadImgIconDiv" : "fileManagerOcrPopoverItem uploadImgIconDiv"}
								onClick={() => {
									console.log("sadasd");
									console.log(this.state.fileDropped);
									if (this.state.fileDropped) {
										(document.getElementById("inputFieldFile") as HTMLInputElement).disabled = true;
										//ask but dont open file upload
										this.setState({ isUploadingFile: true });
										this.uploadFile(this.state.droppedFile, this.state.userUuid, "handWriting");
									}
								}}
							// onClick={() => {
							//   console.log(this.state.fileDropped);
							//   if (isMobile) {
							//     this.setState({ fileType: "image/png, image/jpeg" }, () => {
							//       this.setState({ isUploadingFile: true });
							//       this.uploadFileFromMobile();
							//     });
							//   } else {
							//     if (this.state.fileDropped) {
							//       //ask but dont open file upload
							//       this.setState({ fileType: "image/png, image/jpeg" });
							//       this.setState({ isUploadingFile: true });
							//       this.uploadFile(
							//         this.state.droppedFile,
							//         this.state.userUuid
							//       );
							//     } else {
							//       this.setState(
							//         { fileType: "image/png, image/jpeg" },
							//         () => {
							//           document.getElementById("fileUpload")?.click();
							//         }
							//       );
							//     }
							//   }
							// }}
							>
								<input
									hidden={this.state.fileDropped}
									className="inputFieldFile"
									type="file"
									id="inputFieldFile"
									onChange={(e) => {
										const file = (e.nativeEvent.target as HTMLInputElement).files?.item(0);
										console.log(this.state.fileDropped);
										console.log(file);
										// if (isMobile) {
										//     this.setState({ isUploadingFile: true });
										//     this.uploadFileFromMobile();

										// } else {

										//Set type of file then open upload window
										// this.setState({ fileType: "application/pdf" }, () => {
										//   document.getElementById("fileUpload")?.click();
										// });

										this.setState({ isUploadingFile: true });
										this.uploadFile(file, this.state.userUuid, "handWriting");
									}}
									accept=".png, .jpg, .jpeg, .pdf"

								></input>
								<IonIcon className="fileManagerUploadIconMobile" icon={addPhotoButtonIcon}></IonIcon>
								<IonLabel className="fileManagerOcrPopoverIonLabelTablet">
									<Trans>Foto scrittura a mano</Trans>
								</IonLabel>
							</IonItem>
							<IonItem
								className={isMobile ? "fileManagerOcrPopoverItemMobile uploadPdfIconDiv" : "fileManagerOcrPopoverItem uploadPdfIconDiv"}
								onClick={() => {
									console.log("sadasd");
									console.log(this.state.fileDropped);
									if (this.state.fileDropped) {
										(document.getElementById("inputFieldPDF") as HTMLInputElement).disabled = true;
										//ask but dont open file upload
										this.setState({ isUploadingFile: true });
										this.uploadFile(this.state.droppedFile, this.state.userUuid, "digitalFile");
									}
								}}

							// onClick={() => {
							//   // console.log(this.state.fileDropped);
							//   if (isMobile) {
							//     this.setState({ fileType: "application/pdf" }, () => {
							//       this.setState({ isUploadingFile: true });
							//       this.uploadFileFromMobile();
							//     });
							//   } else {
							//     if (this.state.fileDropped) {
							//       //ask but dont open file upload
							//       this.setState({ fileType: "application/pdf" });
							//       this.setState({ isUploadingFile: true });
							//       this.uploadFile(
							//         this.state.droppedFile,
							//         this.state.userUuid
							//       );
							//     } else {
							//       //Set type of file then open upload window
							//       this.setState({ fileType: "application/pdf" }, () => {
							//         document.getElementById("fileUpload")?.click();
							//       });
							//     }
							//   }
							// }}
							>
								<input
									hidden={this.state.fileDropped}
									className="inputFieldFile"
									type="file"
									id="inputFieldPDF"
									onChange={(e) => {
										const file = (e.nativeEvent.target as HTMLInputElement).files?.item(0);
										console.log(this.state.fileDropped);
										console.log(file);
										// if (isMobile) {
										//     this.setState({ isUploadingFile: true });
										//     this.uploadFileFromMobile();

										// } else {

										//Set type of file then open upload window
										// this.setState({ fileType: "application/pdf" }, () => {
										//   document.getElementById("fileUpload")?.click();
										// });

										this.setState({ isUploadingFile: true }, () => this.uploadFile(file, this.state.userUuid, "digitalFile"));
									}}
									accept=".png, .jpg, .jpeg, .pdf"
								></input>

								<IonIcon className="fileManagerUploadIconMobile" icon={addFileButtonIcon}></IonIcon>
								<IonLabel className="fileManagerOcrPopoverIonLabelTablet">
									<Trans>PDF/IMG libro/testo</Trans>
								</IonLabel>
							</IonItem>
							<IonItem
								hidden={!isMobile}
								className={isMobile ? "fileManagerOcrPopoverItemMobile emptyMapIconDiv" : "fileManagerOcrPopoverItem emptyMapIconDiv"}
								onClick={() => {
									this.setState({ showMapTitle: true });
								}}
							>
								<IonIcon className="fileManagerUploadIconMobile" icon={createBlankMapIcon}></IonIcon>
								<IonLabel className="fileManagerOcrPopoverIonLabelTablet">
									<Trans>Mappa vuota</Trans>
								</IonLabel>
							</IonItem>
							<IonItem
								hidden={!isMobile}
								className={isMobile ? "fileManagerOcrPopoverItemMobile pdfToMapIconDiv" : "fileManagerOcrPopoverItem pdfToMapIconDiv"}
								onClick={() => {
									if (this.state.fileDropped) {
										(document.getElementById("inputFieldPDF") as HTMLInputElement).disabled = true;
										//ask but dont open file upload
										this.setState({ isUploadingFile: true });
										this.uploadFile(this.state.droppedFile, this.state.userUuid, "");
									}
								}}

							// onClick={() => {
							//   // console.log(this.state.fileDropped);
							//   if (isMobile) {
							//     this.setState({ fileType: "application/pdf" }, () => {
							//       this.setState({ isUploadingFile: true });
							//       this.uploadFileFromMobile();
							//     });
							//   } else {
							//     if (this.state.fileDropped) {
							//       //ask but dont open file upload
							//       this.setState({ fileType: "application/pdf" });
							//       this.setState({ isUploadingFile: true });
							//       this.uploadFile(
							//         this.state.droppedFile,
							//         this.state.userUuid
							//       );
							//     } else {
							//       //Set type of file then open upload window
							//       this.setState({ fileType: "application/pdf" }, () => {
							//         document.getElementById("fileUpload")?.click();
							//       });
							//     }
							//   }
							// }}
							>
								<input
									className="inputFieldFile"
									type="file"
									id="inputFieldPDF"
									onChange={(e) => {
										const file = (e.nativeEvent.target as HTMLInputElement).files?.item(0);
										// if (isMobile) {
										//     this.setState({ isUploadingFile: true });
										//     this.uploadFileFromMobile();

										// } else {

										//Set type of file then open upload window
										// this.setState({ fileType: "application/pdf" }, () => {
										//   document.getElementById("fileUpload")?.click();
										// });

										this.setState({ isUploadingFile: true, mapFromPDF: true }, () => this.uploadFile(file, this.state.userUuid, ""));
									}}
									accept=".pdf"
								></input>

								<IonIcon className="fileManagerUploadIconMobile" icon={pdfToMapIcon}></IonIcon>
								<IonLabel className="fileManagerOcrPopoverIonLabelTablet">
									<Trans>Mappa da PDF</Trans>
								</IonLabel>
							</IonItem>
						</>
					</div>
					{isPlatform("capacitor") || isPlatform("cordova") ? (
						<></>
					) : (
						<input
							id="fileUpload"
							type="file"
							hidden
							onClick={(e) => {
								console.log(e);
								const file = (e.nativeEvent.target as HTMLInputElement).files?.item(0);
								// console.log(file)
								//console.log('FILE SIZE: ', file?.size)
								/* if (file!.size > 5242880) {
										alert(i18n.language === 'en' ? 'The file size limit is 5MB' : 'Ehi ricordati che puoi caricare file di massimo 5MB') // NEED TRANSLATION IN FR AND ES
										return
									} */
								// TO DO: ERROR ON OVERSIZED DOCUMENTS
								//save file in state
								//show popover
								// this.setState({ tempFile: file})
								// Can be removed
								if (this.state.fileType == "application/pdf" && this.state.userUuid && file) {
									this.setState({ isUploadingFile: true });
									this.uploadFile(file, this.state.userUuid, "");
								}
								if (this.state.fileType == "image/png, image/jpeg" && this.state.userUuid && file) {
									this.setState({ isUploadingFile: true });
									this.uploadFile(file, this.state.userUuid, "");
								}
								// if (file && this.state.userUuid) {
								//     filesServices.uploadFile(file, this.state.userUuid)
								//         .then(() => {
								//             //console.log("[FilesManager] upload success");
								//             /* this.state.documents.push(document);
								//             this.setState({ documents: this.state.documents });
								//             this.watchForFileProcessingChanges(document.uuid); */
								//         })
								//         .catch(err => {
								//             console.error("[FilesManager] Error uploading file", err);
								//             alert("Errore durante il caricamento del file, riprovare");
								//         });
								// }
							}}
							accept={this.state.fileType}
						/>
					)}
				</IonPopover>
				<IonPopover
					className={"newMapPopover"}
					isOpen={this.state.showMapTitle}
					onDidDismiss={() => {
						this.setState({ showMapTitle: false });
					}}
				>
					<p className="fileManagerOcrPopoverTitle">
						<Trans>Che titolo ha la tua Mappa?</Trans>
					</p>
					<IonInput
						// label="Titolo"
						// labelPlacement="floating"
						className="newMapInput"
						id="emptyMapTtl"
						placeholder={
							i18n.language === "it"
								? "Argomento"
								: i18n.language === "en"
									? "Topic"
									: i18n.language === "fr"
										? "Sujet"
										: i18n.language === "de"
											? "Thema"
											: i18n.language === "es"
												? "Tema"
												: "Tema" //catalan
						}
					></IonInput>
					<IonButton
						onClick={() => {
							let title = (document.getElementById("emptyMapTtl") as HTMLInputElement).value;
							addDoc(collection(firestore, "m"), {
								d: {
									e: [],
									n: [],
								},
								f: null,
								o: this.state.userUuid,
								t: title,
							}).then((docRef) => {
								if (docRef)
									this.props.history.push({
										pathname: `/maps/${docRef.id}`,
										// state: {
										//   docTitle: title,
										//   documentData: documentSnapshot.data(),
										//   docId: docRef.id,
										//   onlyMap: true,
										// },
									});
								// getDoc(doc(firestore, `m/${docRef.id}/`)).then(
								//   (documentSnapshot) => {
								//     if (window.location.pathname === "/maps") {
								//       this.props.history.push("/");
								//     } else {
								//       this.props.history.push({
								//         pathname: `/maps/{docRef.id}`,
								//         // state: {
								//         //   docTitle: title,
								//         //   documentData: documentSnapshot.data(),
								//         //   docId: docRef.id,
								//         //   onlyMap: true,
								//         // },
								//       });
								//     }
								//   }
								// );
							});
						}}
						className={isMobile ? "fileManagerOcrPopoverItemMobile" : "fileManagerOcrPopoverItem"}
					>
						<Trans>Crea mappa vuota</Trans>
					</IonButton>
				</IonPopover>
				<IonLoading
					isOpen={this.state.isUploadingFile}
					message={
						i18n.language === "it"
							? "Caricamento del file in corso"
							: i18n.language === "en"
								? "File loading in progress"
								: i18n.language === "fr"
									? "Chargement du fichier en cours"
									: i18n.language === "de"
										? "Datei-Upload läuft"
										: "Carga de archivos en curso"
					}
				/>
				<IonLoading
					isOpen={this.state.isDeletingFile}
					message={
						i18n.language === "it"
							? "Eliminazione del file in corso"
							: i18n.language === "en"
								? "File deletion in progress"
								: i18n.language === "fr"
									? "Suppression du fichier en cours"
									: i18n.language === "de"
										? "Datei wird gelöscht"
										: "Borrar el archivo en curso"
					}
				/>
				{isMobile && !this.state.isLoadingFoldersAndFiles && <MenuTabBar profile={undefined} settings={undefined} home={home} />}
				<MapReady
					isOpen={this.state.showMapPopover}
					isDarkMode={getDarkMode()}
					isReady={this.state.isUploadingPdfToMap ? false : true}
					onDidDismiss={() => {
						this.setState({ showMapPopover: false });
					}}
					goButtonClicked={() => {
						if (this.state.isUploadingPdfToMap) {
							//just close the popover if the map is not ready
							this.setState({ showMapPopover: false });
						} else {
							//if map is ready and there's a map uuid go to the map
							if (this.state.mapUuid) this.props.history.push(`/maps/${this.state.mapUuid}`);
							else console.error("[error] no map id found");
						}
					}}
				/>
				<IonPopover
					isOpen={this.state.showNotificationsBanner}
					onDidDismiss={() => this.setState({ showNotificationsBanner: false })}
					keepContentsMounted
					side={!isMobile ? "right" : "left"}
					alignment={!isMobile ? "end" : "start"}
					event={this.state.popoverEvent}
					className="notificationsPopover"
				>
					<div className="notificationsPopoverMainDiv">
						<p className="notificationsPopoverTitle">
							<Trans>Annunci</Trans>{/* TO LOCALIZE THIS */}
						</p>
						<div className="notificationsPopoverContentDiv">
							{this.state.notificationsData.map((notification: Notification, index: number) => {
								return (
									(notification.expiryDate === null || (notification.expiryDate && notification.expiryDate > Date.now())) && /* DO NOT SHOW EXPIRED NOTIFICATIONS */
									<div
										key={index}
										className="notificationsPopoverItem"
										style={notification.url === null ? {} : { cursor: 'pointer' }}
										onClick={() => {
											if (notification.url) {
												let callToActionUrl = new URL(notification.url!)
												if (callToActionUrl && callToActionUrl.toString().startsWith('https://app.reasyedu.com')) {
													window.location.replace(callToActionUrl.pathname + callToActionUrl.search);
												}
												else {
													window.open(callToActionUrl.toString(), '_system');
												}
											}
										}}>
										<div className="notificationItemHeaderDiv">
											<p className="notificationsPopoverItemTitle">{notification.title}</p>
											<p className="notificationsPopoverItemDate">{new Date(notification.date * 1000).toLocaleDateString()}</p>
										</div>
										<div className="notificationItemDescriptionDiv">
											<p className="notificationsPopoverItemText">{notification.body}</p>
										</div>
										<IonIcon hidden={notification.url === null} className="notificationItemCTAArrow" icon={chevronForwardOutline} />
										{/* {
											notification.url &&
											<div className="notificationItemBottomDiv">
												<IonButton onClick={() => {
													let callToActionUrl = new URL(notification.url!)
													if (callToActionUrl && callToActionUrl.toString().startsWith('https://app.reasyedu.com')) {
														window.location.replace(callToActionUrl.pathname + callToActionUrl.search);
													}
													else {
														window.open(callToActionUrl.toString(), '_system');
													}
												}}
													className="notificationItemButton"
												>
													<Trans>
														Scopri di più
													</Trans>
												</IonButton>
											</div>
										} */}
									</div>
								);
							})}
						</div>
					</div>
				</IonPopover>
			</IonPage >
		);
	}
}
const mapStateToProps = (state: any) => {
	return {
		thumbnails: state.thumbnails,
		orgData: state.user.organization,
		licenseBannerRedeemed: state.user.licenseBannerRedeemed,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		setThumbnails: (thumbnails: any) => {
			dispatch(thumbnailActions.setThumbnails(thumbnails));
		},
		clearThumbnails: () => {
			dispatch(thumbnailActions.clearThumbnails());
		},
		setBannerLicenseRedeemed: (isRedeemed: boolean) => {
			dispatch(userActions.setBannerLicenseRedeemed(isRedeemed));
		},

	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FilesManager);

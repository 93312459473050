import { tutorialsConstants } from './constants';
import { combineReducers } from 'redux';

export interface TutorialsStore {
  showOnboarding: boolean;
  showFileViewerTutorial: boolean;
}

const initialTutorialsState: TutorialsStore = {
  showOnboarding: true,
  showFileViewerTutorial: true,
};

// Reducer for handling onboarding tutorial
const showOnboardingReducer = (state: boolean = initialTutorialsState.showOnboarding, action: any) => {
  switch (action.type) {
    case tutorialsConstants.SHOW_ONBOARDING:
      return action.show;
    default:
      return state;
  }
};

// Reducer for handling file viewer tutorial
const showFileViewerTutorialReducer = (state: boolean = initialTutorialsState.showFileViewerTutorial, action: any) => {
  switch (action.type) {
    case tutorialsConstants.SHOW_FILE_VIEWER_TUTORIAL:
      return action.show;
    default:
      return state;
  }
};

// Combine the individual reducers
const tutorials = combineReducers({
  showOnboarding: showOnboardingReducer,
  showFileViewerTutorial: showFileViewerTutorialReducer,
  // Add more individual reducers if needed
});

export default tutorials;


import {
    IonButton,
    IonContent,
    IonImg,
    IonPage
} from "@ionic/react";
import Cookies from "js-cookie";
import React from "react";
import { connect } from "react-redux";
import reasyFullLogo from '../../../assets/icons/reasy_full_logo.svg'
import "./Invitation.css";
import { Trans } from "react-i18next";
import { auth } from "../../../firebaseConfig";
import { UserData } from "../../user/store/reducers";
import { userServices } from "../../user/store/services";
import { invitationServices } from "../store/services";


type Props = {
    history: any;

};

type State = {
    userData: UserData | null,
    bearerToken: string | null,
    orgName: string | null,
    orgInvitationToken: string | null,
    invitationResponded: boolean,
};

class Invitation extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            userData: null,
            bearerToken: null,
            orgName: null,
            orgInvitationToken: null,
            invitationResponded: false,
        };
    }

    getInvitationResponseBoolean(invitationResponse: string | undefined) {
        if (invitationResponse)
            return invitationResponse === "true";
        else
            return undefined
    }

    componentDidMount() {
        auth.onAuthStateChanged(async (userData) => {
            let orgInvitationToken: string | null
            let invitationResponse: string | null
            let orgName: string | null
            /* CHECK IF WE CAME HERE AFTER ACCEPTATION OR DECLINATION */
            if (this.props.history.location.state && this.props.history.location.state.respondedToInvitation) {
                orgInvitationToken = Cookies.get('org_invitation_token') || null;
                invitationResponse = Cookies.get('invitation_response') || null;
                orgName = Cookies.get('org_name') || null;
                if (userData) {
                    userData.getIdToken().then(token => {
                        this.setState({ bearerToken: token }, () => {
                            if (orgInvitationToken && invitationResponse && orgName) {
                                this.setState({ orgInvitationToken: orgInvitationToken }, () => {
                                    /* USER HAS RESPONDED TO THE INVITATION*/
                                    this.respondToInvitation(invitationResponse!)
                                })
                            }
                            userServices.getUserData(userData.uid, token)
                                .then(response => {
                                    this.setState({ userData: response })
                                })
                                .catch(err => {
                                    console.error(err);
                                })
                        })
                    })
                }
            }
            else {
                const urlParams = new URLSearchParams(window.location.search);
                orgInvitationToken = urlParams.get('org_invitation_token');
                orgName = urlParams.get('org_name');
                if (orgInvitationToken) {
                    this.setState({ orgInvitationToken: orgInvitationToken })
                    const expirationTime = new Date(new Date().getTime() + 10 * 60 * 1000); // 10 minutes from now
                    Cookies.set('org_invitation_token', orgInvitationToken, { path: '/', expires: expirationTime });
                }
                if (orgName) {
                    const expirationTime = new Date(new Date().getTime() + 10 * 60 * 1000); // 10 minutes from now
                    Cookies.set('org_name', orgName, { path: '/', expires: expirationTime });
                    this.setState({ orgName: orgName })
                }
            }
        })

    }
    respondToInvitation(response: string) {
        if (auth.currentUser && this.state.orgInvitationToken && response && this.state.bearerToken) {
            invitationServices.respondToInvitation(auth.currentUser!.uid, this.state.bearerToken, this.state.orgInvitationToken!, this.getInvitationResponseBoolean(response)!)
                .then(response => {
                    Cookies.remove('org_invitation_token');
                    Cookies.remove('invitation_response');
                    Cookies.remove('org_name');
                    this.setState({ invitationResponded: true }, () => {
                        /* redirect after 2 seconds */
                        setTimeout(() => {
                            this.props.history.push('/sessions')
                        }, 2000);
                    })
                })
                .catch(error => {

                })
        }
    }

    respondWithLogin(response: 'true' | 'false') {
        const expirationTime = new Date(new Date().getTime() + 10 * 60 * 1000); // 10 minutes from now
        Cookies.set('invitation_response', response, { path: '/', expires: expirationTime })
        this.props.history.push('/authentication/get-started');
    }
    render() {
        return (
            <IonPage className="body">
                <div className="invitationContainer">
                    {!this.state.invitationResponded &&
                        <>
                            <IonImg
                                className="invitationReasyLogo"
                            /* SRC SET INSIDE CSS TO HANDLE DARK MODE LOGO */
                            />
                            <div className="invitationDiv">
                                <p className="invitationGreetingPar">
                                    Ciao {this.state.userData?.first_name}
                                </p>
                                <div className="invitationContentDiv">
                                    {/* LOTTIE OR IMAGE */}
                                    {this.state.orgName ?
                                        /* TO LOCALIZE THIS */
                                        <p>
                                            {decodeURI(this.state.orgName)} <Trans>ti ha invitato alla propria organizzazione</Trans>
                                        </p>
                                        :
                                        <p>Sei stato invitato ad unirti ad un'organizzazione</p>
                                    }
                                    <p className="invitationEmoji">
                                        📩
                                    </p>

                                </div>
                                <div className="invitationBottomDiv">
                                    <p className="invitationCtaPar">Vuoi accettare l'invito?</p>
                                    <div className="invitationButtons">
                                        <IonButton
                                            onClick={() => {
                                                if (this.state.userData) {
                                                    this.respondToInvitation('false');
                                                }
                                                else {
                                                    this.respondWithLogin("false");
                                                }
                                            }}
                                            mode='ios'
                                            className="invitationBtn declineBtn"
                                        >
                                            Rifiuta
                                        </IonButton>
                                        <IonButton
                                            onClick={() => {
                                                if (this.state.userData) {
                                                    this.respondToInvitation('true');
                                                }
                                                else {
                                                    this.respondWithLogin("true");
                                                }
                                            }}
                                            mode='ios'
                                            className="invitationBtn acceptBtn"
                                        >
                                            Accetta
                                        </IonButton>
                                    </div>
                                    {!this.state.userData &&
                                        <p className="invitationLoginParagraph">
                                            Per accettare o rifiutare l'invito sarai reindirizzato alla pagina di login
                                        </p>
                                    }
                                </div>
                            </div>
                        </>
                    }
                    {
                        this.state.invitationResponded &&
                        < div >
                            <p>Hai risposto con successo all'invito</p>
                            <p>Stai per essere reindirizzato a Reasy...</p>
                            <p>
                                In alternativa, clicca
                                <a
                                    onClick={() => {
                                        this.props.history.push('/sessions')
                                    }}
                                >
                                    questo link
                                </a>
                                per tornare alla home page di Reasy.
                            </p>
                        </div>
                    }

                </div>
            </IonPage>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invitation);

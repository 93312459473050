import React from "react"
import { Trans } from "react-i18next"
import { fileViewerTutorialTxtSlide1, fileViewerTutorialTxtSlide2, fileViewerTutorialTxtSlide3, fileViewerTutorialTxtSlide4, fileViewerTutorialTxtSlide5, fileViewerTutorialTxtSlide6, fileViewerTutorialTxtSlide7, fileViewerTutorialTxtSlide8 } from "../utils/tutorialTxt"


export function getTutorialObject(_this) {
    return [{
        target: '#tts',
        content: (
            <div>
                <p><Trans>{fileViewerTutorialTxtSlide1}</Trans></p>
            </div>
        ),
        disableBeacon: true,
        placement: "bottom",
    },
    {
        target: '#conceptualMap',
        content: <div>
            <p><Trans>{fileViewerTutorialTxtSlide2}</Trans></p>
        </div>,
        disableBeacon: true,
        placement: "bottom",

    },
    {
        target: '#modifyDoc',
        content: <div>
            <p><Trans>{fileViewerTutorialTxtSlide3}</Trans></p>
        </div>,
        disableBeacon: true,
        placement: "bottom",
    },
    {
        target: '#summary',
        content: <div>
            <p><Trans>{fileViewerTutorialTxtSlide4}</Trans></p>
        </div>,
        disableBeacon: true,
        placement: "bottom",
    },
    {
        target: '#keywords',
        content: <div>
            <p><Trans>{fileViewerTutorialTxtSlide5}</Trans></p>
        </div>,
        disableBeacon: true,
        placement: "bottom",
    },
    {
        target: '.divZoom',
        content: <div>
            <p><Trans>{fileViewerTutorialTxtSlide6}</Trans></p>
        </div>,
        disableBeacon: true,
        placement: "bottom",
    },
    {
        target: '.downloadButtonSection',
        content: <div>
            <p><Trans>{fileViewerTutorialTxtSlide7}</Trans></p>
        </div>,
        disableBeacon: true,
        placement: "bottom",
    },
    {
        target: '.divNextPrevious',
        content: <div>
            <p><Trans>{fileViewerTutorialTxtSlide8}</Trans></p>
        </div>,
        disableBeacon: true,
        placement: "bottom",
    },]
}

export function getTutorialObjectMobile(_this) {
    return [
        {
            target: '#div1',
            content: <div>
                <p><Trans>{fileViewerTutorialTxtSlide2}</Trans></p>
            </div>,
            disableBeacon: true,
            placement: "bottom",

        },


        {
            target: '#div2',
            content: <div>
                <p><Trans>{fileViewerTutorialTxtSlide5}</Trans></p>
            </div>,
            disableBeacon: true,
            placement: "bottom",
        },
        {
            target: '#div3',
            content: <div>
                <p><Trans>{fileViewerTutorialTxtSlide4}</Trans></p>
            </div>,
            disableBeacon: true,
            placement: "bottom",
        },
        {
            target: '#div4',
            content: (
                <div>
                    <p><Trans>{fileViewerTutorialTxtSlide1}</Trans></p>
                </div>
            ),
            disableBeacon: true,
            placement: "bottom",
        },
        {
            target: '#div5',
            content: <div>
                <p><Trans>{fileViewerTutorialTxtSlide3}</Trans></p>
            </div>,
            disableBeacon: true,
            placement: "bottom",
        },
        {
            target: '.kebabMenu',
            content: <div>
                <p><Trans>{fileViewerTutorialTxtSlide7}</Trans></p>
            </div>,
            disableBeacon: true,
            placement: "bottom",
        },
    ]
}

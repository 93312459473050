import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { FlashcardContent, Flashcards } from "../../sessions/store/reducers";
import { firestore } from "../../../firebaseConfig";
import { Flashcard, FlashcardsCollection } from "./reducers";
import { backendApi, backendApiDemo } from "../../../common/apiService";

export const flashcardsServices = {
	getFlashcardsCollections,
	getSingleFlashcardsCollection,

	updateFlashcardFace,

	removeFlashcard,
};

export function getSingleFlashcardsCollection(flashcards_collection_id: string, token: string) {
	return new Promise<FlashcardsCollection>(async (resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let flashcards_collections_obj: FlashcardsCollection;
		if (flashcards_collection_id) {
			backendApi
				.get(`/resources/flashcards-collections/${flashcards_collection_id}`, config)
				.then((response) => {
					if (response.status === 200) {
						console.log("[getSingleFlashcardsCollection]Fetch Successful:", response.status);
						flashcards_collections_obj = response.data.flashcards_collection;
						resolve(flashcards_collections_obj);
					} else {
						reject(new Error(`[getSingleFlashcardsCollection]Request failed with status ${response.status}`));
					}
				})
				.catch((error) => {
					console.error(`[getSingleFlashcardsCollection]Error fetching single flashcards collection ${flashcards_collection_id}}`, error);
					reject(error);
				});
		}
	});
}

export function getFlashcardsCollections(token: string) {
	return new Promise<FlashcardsCollection[]>(async (resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		let flashcards_collections_obj: FlashcardsCollection[];
		backendApi
			.get(`/resources/flashcards-collections`, config)
			.then((response) => {
				if (response.status === 200) {
					console.log("[getFlashcardsCollections]Fetch Successful:", response.status);

					flashcards_collections_obj = response.data;
					resolve(flashcards_collections_obj);
				} else {
					reject(new Error(`[getFlashcardsCollections]Request failed with status ${response.status}`));
				}
			})
			.catch((error) => {
				console.error(`[getFlashcardsCollections]Error fetching flashcards collections`, error);
				reject(error);
			});
	});
}
export function updateFlashcardFace(fc_collection_id: string, flashcard_id: string, face: 'front' | 'back', content: string, token: string,) {
	return new Promise<Flashcard>(async (resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		const data = {
			"front_content": face === 'front' ? content : undefined,
			"back_content": face === 'back' ? content : undefined,
		}
		backendApi
			.patch(`/resources/flashcards-collections/${fc_collection_id}/flashcards/${flashcard_id}`, data, config)
			.then((response) => {
				if (response.status === 200) {
					console.log("[updateFlashcardFace] Update Successful:", response.status);
					resolve(response.data)
				} else {
					reject(new Error(`[updateFlashcardFace]Request failed with status ${response.status}`));
				}
			})
			.catch((error) => {
				console.error(`[updateFlashcardFace]Error updating flashcard`, error);
				reject(error);
			});
	});
}
export function removeFlashcard(fc_collection_id: string, flashcard_id: string, token: string,) {
	return new Promise<any>(async (resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		backendApi
			.delete(`/resources/flashcards-collections/${fc_collection_id}/flashcards/${flashcard_id}`, config)
			.then((response) => {
				if (response.status === 200) {
					console.log("[removeFlashcard] Delete Successful:", response.status);
					resolve(response.data)
				} else {
					reject(new Error(`[removeFlashcard]Request failed with status ${response.status}`));
				}
			})
			.catch((error) => {
				console.error(`[removeFlashcard]Error deleting flashcard`, error);
				reject(error);
			});
	});
}
import {
	IonButton,
	IonGrid, IonHeader, IonIcon, IonInput, IonPage, IonPopover,
	IonToggle, IonToolbar
} from '@ionic/react';
import { sendSignInLinkToEmail } from "firebase/auth";
import { helpCircleOutline, informationCircle } from 'ionicons/icons';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { validateEmail } from '../../../common/validations';
import { auth } from '../../../firebaseConfig';
import i18n from '../../../i18n';
import "./MagicLinkRequest.css";

type Props = {
	history: any,
	isAuthenticated: boolean,
}

type State = {
	policyChecked: boolean,
	email: string | null | undefined,
	isValidEmail: boolean,
	showConfirmationDiv: boolean,
	showInfoTooltip: boolean,
	popoverEvent: any,
}

class MagicLinkRequest extends React.Component<Props, State> {

	constructor(props: any) {
		super(props);
		this.state = {
			policyChecked: false,
			email: '',
			isValidEmail: false,
			showConfirmationDiv: false,
			showInfoTooltip: false,
			popoverEvent: null
		}
	}

	render() {
		return (
			<IonPage className='authGetStartedPage'>
				{/* {isMobile &&
					<IonHeader className='ion-no-border authSupportHeader'>
						<IonToolbar className='authSupportHeaderToolbar'>
							<IonIcon icon={helpCircleOutline} slot='end' className='authSupportIcon' onClick={() => this.props.history.push('/support')} />
						</IonToolbar>
					</IonHeader>
				} */}
				<IonIcon icon={helpCircleOutline} slot='end' className='authSupportIcon' onClick={() => this.props.history.push('/support')} />
				<IonGrid className="authGetStartedMainGrid">
					<IonIcon hidden={isMobile} icon={helpCircleOutline} className='authSupportIcon' onClick={() => this.props.history.push('/support')} />

					{/* <IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/authentication/get-started" />
						</IonButtons>
						<IonTitle><Trans>Link magico</Trans></IonTitle>
					</IonToolbar>
				</IonHeader> */}


					{
						!this.state.showConfirmationDiv &&
						<div className="magicLinkRequestMainTextDiv">
							<IonPopover showBackdrop={false} onDidDismiss={() => this.setState({ showInfoTooltip: false })} isOpen={this.state.showInfoTooltip} event={this.state.popoverEvent}>
								<div className='magicLinkPopoverDiv'>
									<Trans>Inserisci qui la tua email, una volta premuto il pulsante riceverai un link alla tua casella email che ti permetterà di accedere a Reasy in un attimo.</Trans>
								</div>
							</IonPopover>
							<p className="magicLinkRequestHintParagraph">
								<Trans>Richiedi un link magico</Trans>
								<IonIcon
									style={{ cursor: 'pointer', marginLeft: '10px' }}
									onClick={(e) => this.setState({ showInfoTooltip: true, popoverEvent: e.nativeEvent })}
									icon={informationCircle}
								/>
							</p>
						</div>
					}

					{
						!this.state.showConfirmationDiv &&
						<div className="magicLinkRequestEmailAndTermsDiv">
							<div>
								<IonInput
									className="magicLinkRequestEmailInput"
									type='email'
									value={this.state.email}
									autocomplete='email'
									inputMode='email'
									placeholder={i18n.language === 'es' ? "Tu email" : i18n.language === 'en' ? 'Your email address' : i18n.language === 'fr' ? 'Votre e-mail' : i18n.language === 'de' ? 'Deine E-Mail' : 'La tua email'}
									onIonChange={(e) => {
										if (e.detail.value) {
											this.setState({
												email: e.detail.value,
												isValidEmail: validateEmail(e.detail.value),
											})
										}
										else {
											this.setState({
												email: e.detail.value,
												isValidEmail: false
											});
										}
									}}
								/>

								<div className="magicLinkRequestToggleAndTermsDiv">
									<IonToggle
										mode="md"
										className="magicLinkRequestToggle"
										onIonChange={e => {
											this.setState({ policyChecked: e.detail.checked })
										}}
									/>

									<p className="magicLinkRequestTermsParagraph">
										<Trans>Ho letto e accetto la </Trans>
										<a href='/legal/privacy-policy'>privacy policy</a><Trans> e i </Trans>
										<a href='/legal/terms-conditions'><Trans>termini di servizio</Trans></a>.
									</p>
								</div>
							</div>
						</div>
					}

					{
						!this.state.showConfirmationDiv &&
						<div className="magicLinkRequestSendEmailDiv">
							<IonButton
								className="magicLinkRequestSendEmailButton"
								expand="block"
								onClick={() => {
									if (this.state.email) {
										const actionCodeSettings = {
											/* url: 'http://localhost:8100/authentication/completion',  // FOR TESTING */
											url: 'https://app.reasyedu.com/authentication/completion', // FOR PRODUCTION
											handleCodeInApp: true,
											iOS: {
												bundleId: "ai.tech4all.reasy",
											},
											android: {
												packageName: "ai.tech4all.reasy",
												installApp: true,
												minimumVersion: '12'
											},
											dynamicLinkDomain: 't4areasy.page.link'
										};

										sendSignInLinkToEmail(auth, this.state.email, actionCodeSettings)
											.then(() => {
												window.localStorage.setItem('emailForSignIn', String(this.state.email));
												//console.log("[MagicLinkRequest] link sent.");
												this.setState({ showConfirmationDiv: true });
											})
											.catch(e => {
												console.error("[MagicLinkRequest] send signin link error:", e);
											});
										//let the user know what to do:
										//e.g. "we've sent a verification link to '[email]', check your email inbox"
									}
								}}

								disabled={
									!this.state.email ||
									!this.state.isValidEmail ||
									!this.state.policyChecked ||
									this.state.showConfirmationDiv
								}
							>
								<Trans>Iniziamo</Trans>
							</IonButton>
							<IonButton
								fill="clear"
								onClick={() => {
									this.props.history.push(`/authentication/get-started`);
								}}
							>
								<Trans>Indietro</Trans>
							</IonButton>
						</div>
					}

					{
						this.state.showConfirmationDiv &&
						<div className="magicLinkRequestConfirmationDiv">
							<p className="magicLinkRequestConfirmationParagraph">
								<Trans>Abbiamo inviato il link alla email da te inserita:</Trans> <i>{this.state.email ? this.state.email.trim() : ""}</i> ✅ {/* TO BE LOCALIZED */}
							</p>
							<p className="magicLinkRequestConfirmationCTAParagraph">
								<Trans>Vai ad aprire la tua casella email!</Trans>
							</p>
						</div>
					}

					{this.props.isAuthenticated && <Redirect to="/" />}
				</IonGrid>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		isAuthenticated: state.auth.access ? true : false,
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MagicLinkRequest);
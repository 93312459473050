// import { Device } from '@ionic-native/device';
import { isPlatform } from '@ionic/react';
import { get, push, ref as dbRef, remove, update } from 'firebase/database';
import { store } from '../../../common/store';
import { auth, database } from '../../../firebaseConfig';
import { Device as DeviceModel, Organization, Organization_New, OrganizationLicense, OrganizationLicenseData, PlanInfo, ReferredUser, ReferrerInfo, ResourceUsageEvent, UserData, UserLicense, UserLicenseData } from './reducers';
import { Device, DeviceInfo } from '@capacitor/device';
import { Product, fromNumberToPlatform } from '../../../common/Paywall/store/paywallUtils';
import { backendApi } from '../../../common/apiService';

export const userServices = {
    addDevice,
    fetchDevices,
    editDeviceStatus,
    editDeviceName,
    deleteDevice,

    getUserData,
    getUserRole,
    updateUserRole,
    // getUserOrganizationName,
    getUserOrganizationOld,

    deleteAccount,

    getReferredUsers,
    redeemReferralCode,
    getReferrerInfo,

    getUserLicenses,
    getUserLicenseDetails,
    getUserOrganizations,

    getOrganizationLicenses,
    getOrganizationLicenseDetails,

    getResourceUsageEvents,
};

function addDevice(fcmToken: string) {
    return new Promise<DeviceModel>((resolve, reject) => {
        let deviceInfo: DeviceInfo | undefined
        let deviceId: any = null
        Device.getId()
            .then(resp => {
                deviceId = resp.identifier
                // alert('got ID: ' + deviceId)
                Device.getInfo()
                    .then(info => {
                        deviceInfo = info
                        // alert('got info: ' + deviceInfo)
                        const deviceModelToReturn: DeviceModel = {
                            uuid: "temp",
                            creationDate: Math.floor(new Date().getTime() / 1000),
                            isActive: true,
                            name: deviceInfo ? deviceInfo.name : undefined,
                            registrationToken: fcmToken,
                            type: 0,
                        };

                        if (isPlatform('ios')) {
                            deviceModelToReturn.type = 1;
                            deviceModelToReturn.deviceId = deviceId;
                            // alert('SIAMO SU IOS, deviceId: ' + deviceId)
                        }
                        else if (isPlatform('android')) {
                            deviceModelToReturn.type = 2;
                            deviceModelToReturn.deviceId = deviceId;
                        }

                        const deviceDbObj = {
                            a: true,
                            c: deviceModelToReturn.creationDate,
                            n: deviceModelToReturn.name,
                            r: fcmToken,
                            t: deviceModelToReturn.type,
                        };

                        if (deviceModelToReturn.deviceId) {
                            // alert('ESISTE UN DEVICE ID: ' + deviceModelToReturn.deviceId)
                            Object.assign(deviceDbObj, { d: deviceModelToReturn.deviceId });
                        }
                        console.log("device id", deviceId);
                        console.log("device info", deviceInfo);

                        push(dbRef(database, `d/${store.getState().auth.userData?.uid}`), deviceDbObj)
                            .then(response => {
                                //console.log('[addDevice] response', JSON.stringify(response.data));
                                if (response.key) {
                                    deviceModelToReturn.uuid = response.key;
                                    resolve(deviceModelToReturn);
                                }
                                else {
                                    reject("[user services] Response key is null");
                                }
                            })
                            .catch(err => {
                                console.error('[addDevice] error:', JSON.stringify(err));
                                reject(err);
                            })
                    });
            })
    })
}

function fetchDevices(userUuid: string) {
    return new Promise<DeviceModel[]>((resolve, reject) => {
        const devicesArrayToReturn: DeviceModel[] = [];
        get(dbRef(database, `d/${userUuid}`))
            .then(snapshot => {
                if (snapshot.exists()) {
                    const devicesKeys = Object.keys(snapshot.val());
                    for (let i = 0; i < devicesKeys.length; i++) {
                        devicesArrayToReturn.push({
                            uuid: devicesKeys[i],
                            creationDate: snapshot.val()[devicesKeys[i]].c,
                            deviceId: snapshot.val()[devicesKeys[i]].d,
                            isActive: snapshot.val()[devicesKeys[i]].a,
                            name: snapshot.val()[devicesKeys[i]].n,
                            registrationToken: snapshot.val()[devicesKeys[i]].r,
                            type: snapshot.val()[devicesKeys[i]].t,
                        });
                    }
                }
                resolve(devicesArrayToReturn);
            })
            .catch(err => {
                console.error('[fetchDevices] error:', err);
                resolve([]);
            });
    })
}

function editDeviceStatus(deviceUuid: string, active: boolean) {
    return new Promise<DeviceModel | null>((resolve, reject) => {
        const deviceDbRef = dbRef(database, `d/${store.getState().auth!.userData?.uid}/${deviceUuid}`);
        get(deviceDbRef)
            .then(snapshot => {
                if (snapshot.exists()) {
                    const deviceToReturn: DeviceModel = {
                        uuid: deviceUuid,
                        creationDate: snapshot.val().c,
                        deviceId: snapshot.val().d,
                        isActive: snapshot.val().a,
                        name: snapshot.val().n,
                        registrationToken: snapshot.val().r,
                        type: snapshot.val().t,
                    };

                    update(deviceDbRef, {
                        a: active,
                    })
                        .then(() => {
                            deviceToReturn.isActive = active;
                            resolve(deviceToReturn);
                        })
                        .catch(err => {
                            //console.log('[editDeviceStatus] error updating device status:', err);
                            resolve(deviceToReturn);
                        })
                }
                else {
                    resolve(null);
                }
            })
            .catch(err => {
                console.error('[editDeviceStatus] error getting device from db:', err);
                resolve(null);
            });
    })
}

function editDeviceName(deviceUuid: string, name: string) {
    return new Promise<DeviceModel | null>((resolve, reject) => {
        const deviceDbRef = dbRef(database, `d/${store.getState().auth!.userData?.uid}/${deviceUuid}`);
        get(deviceDbRef)
            .then(snapshot => {
                if (snapshot.exists()) {
                    const deviceToReturn: DeviceModel = {
                        uuid: deviceUuid,
                        creationDate: snapshot.val().c,
                        deviceId: snapshot.val().d,
                        isActive: snapshot.val().a,
                        name: snapshot.val().n,
                        registrationToken: snapshot.val().r,
                        type: snapshot.val().t,
                    };

                    update(deviceDbRef, {
                        n: name,
                    })
                        .then(() => {
                            deviceToReturn.name = name;
                            resolve(deviceToReturn);
                        })
                        .catch(err => {
                            console.error("[editDeviceName] error updating device on db")
                            resolve(deviceToReturn);
                        })
                }
                else {
                    resolve(null);
                }
            })
            .catch(err => {
                console.error('[editDeviceName] error getting device from db:', err);
                resolve(null);
            });
    })
}

function deleteDevice(deviceUuid: string) {
    return new Promise((resolve, reject) => {
        const deviceDbRef = dbRef(database, `d/${store.getState().auth!.userData?.uid}/${deviceUuid}`);
        remove(deviceDbRef)
            .then(() => {
                resolve(true);
            })
            .catch(err => {
                console.error('[deleteDevice] error deleting device from db:', err);
                reject(err);
            });
    })
}
export function fromProductsDbDataToProducts(dbData: any): Product[] {
    const productsToReturn: Product[] = [];
    if (dbData) {
        const productsKeys = Object.keys(dbData);
        for (let i = 0; i < productsKeys.length; i++) {
            const product = fromProductDbDataToProduct(dbData[productsKeys[i]], productsKeys[i]);
            if (product) {
                productsToReturn.push(product);
            }
        }
    }
    return productsToReturn;
}

export function fromProductDbDataToProduct(dbData: any, productUuid: string): Product | null {
    let productToReturn: Product | null = null;
    if (dbData) {
        productToReturn = {
            uuid: productUuid,
            creationTimestamp: dbData.c,
            expiryTimestamp: dbData.e,
            from: fromNumberToPlatform(dbData.f),
            productId: dbData.p,
            startTimestamp: dbData.s
        }
    }
    return productToReturn;
}
function getUserOrganizationOld(userUuid: string) {
    return new Promise<Organization | null | 'Private'>(async (resolve, reject) => {
        await new Promise(r => setTimeout(r, 5000));

        get(dbRef(database, `u/${userUuid}/o`))
            .then((org): void => {
                console.log("FOUND ORG SERV: ", org.val());
                if (org.val()) {
                    get(dbRef(database, `o/${org.val()}`))
                        .then(org_data => {
                            if (org_data.val()) {
                                resolve({
                                    uuid: org.val(),
                                    createdAt: org_data.val().t,
                                    createdBy: org_data.val().c,
                                    name: org_data.val().n,
                                    type: org_data.val().o,
                                    products: fromProductsDbDataToProducts(org_data.val().p),
                                })
                            }
                            else {
                                resolve(null);
                            }
                        })
                        .catch(err => {
                            console.error('[getUserOrganization] error getting organization data:', err);
                        });
                }
                else {
                    resolve('Private');
                }
            })
            .catch(err => {
                console.error('[getUserOrganization] error getting user organization uuid:', err);
                reject(err);
            });
    })
}
function getUserRole(userUuid: string) {
    return new Promise<Organization | null>((resolve, reject) => {
        get(dbRef(database, `u/${userUuid}/r`))
            .then((role): void => {
                if (role) {
                    resolve(role.val())
                }
                else {
                    resolve(null);
                }
            })
            .catch(err => {
                console.error('[getUserRole] error getting user role:', err);
                reject(err);
            });
    })
}
function updateUserRole(userUuid: string, role: string) {
    return new Promise<any>((resolve, reject) => {
        update(dbRef(database, `u/${userUuid}`), {
            r: role,
        })
            .then((response) => {
                console.log("[updateUserRole] Succesfully updated user role");
                resolve(response)

            })
            .catch(err => {
                console.log("[updateUserRole] Error updating user role");
                reject(err);
            });
    })
}

function deleteAccount() {
    return new Promise((resolve, reject) => {
        if (auth.currentUser) {
            auth.currentUser?.delete()
                .then(() => {
                    resolve({});
                })
                .catch(err => {
                    reject(err);
                });
        }
        else {
            reject("Please sign-in");
        }
    })
}

/* NEW BACKEND */

export function getUserData(userId: string, token: string) {
    return new Promise<UserData>((resolve, reject) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        backendApi.get(`users/${userId}`, config)
            .then(response => {
                console.log("[Get user data] response: ", response)
                if (response.status === 200)
                    resolve(response.data)
            })
            .catch(err => {
                console.error("[Get user data] error: ", err)
                reject(err)
            })
    })
}

function getUserLicenses(userId: string, token: string) {
    return new Promise<UserLicense[]>((resolve, reject) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        backendApi.get(`users/${userId}/licenses`, config)
            .then(response => {
                console.log("[Get user licenses] response: ", response.data)
                if (response.status === 200)
                    resolve(response.data)
            })
            .catch(err => {
                console.error("[Get user licenses] error: ", err)
                reject(err)
            })

    })
}
function getUserLicenseDetails(userId: string, licenseId: string, token: string) {
    return new Promise<UserLicenseData>((resolve, reject) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        backendApi.get(`users/${userId}/licenses/${licenseId}`, config)
            .then(response => {
                console.log("[Get user license details] response: ", response.data)
                if (response.status === 200)
                    resolve(response.data)
            })
            .catch(err => {
                console.error("[Get user license details] error: ", err)
                reject(err)
            })

    })
}
function getUserOrganizations(userId: string, token: string) {
    return new Promise<Organization_New[]>((resolve, reject) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        backendApi.get(`users/${userId}/organizations`, config)
            .then(response => {
                console.log("[Get user orgs] response: ", response.status)
                resolve(response.data)
            })
            .catch(err => {
                console.error("[Get user orgs] error: ", err)
                reject(err)

            })
    })
}
export function getReferredUsers(userId: string, token: string) {
    return new Promise<ReferredUser[]>((resolve, reject) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        backendApi.get(`users/${userId}/referrals`, config)
            .then(response => {
                console.log("[Get user referrals] response: ", response.data.referred)
                if (response.status === 200)
                    resolve(response.data.referred)
            })
            .catch(err => {
                console.error("[Get user referrals] error: ", err)
                reject(err)
            })

    })
}

export function getReferrerInfo(referral_code: string) {
    return new Promise<ReferrerInfo>((resolve, reject) => {
        backendApi.get(`public/referrer-info/${referral_code}`)
            .then(response => {
                console.log("[Get referrer info] response: ", response.data)
                if (response.status === 200)
                    resolve(response.data)
            })
            .catch(err => {
                console.error("[Get referrer info] error: ", err)
                reject(err)
            })

    })
}
export function redeemReferralCode(userID: string, code: string, token: string) {
    return new Promise<boolean>((resolve, reject) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        backendApi.post(
            `users/${userID}/referrals/associate`,
            {
                referral_code: code
            },
            config
        )
            .then(response => {
                console.log("[Redeem code] response: ", response.data.referred)
                if (response.status === 200)
                    resolve(true)
            })
            .catch(err => {
                console.error("[Redeem Code] error: ", err)
                reject(err)
            })

    })
}

function getResourceUsageEvents(userID: string, token: string) {
    return new Promise<ResourceUsageEvent[]>((resolve, reject) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        backendApi.get(
            `users/${userID}/resource-usage-events`,
            config
        )
            .then(response => {
                console.log("[getResourceUsageEvents] response: ", response.status)
                if (response.status === 200)
                    resolve(response.data)
            })
            .catch(err => {
                console.error("[getResourceUsageEvents] error: ", err)
                reject(err)
            })

    })
}

function getOrganizationLicenses(orgId: string, token: string) {
    return new Promise<OrganizationLicense[]>((resolve, reject) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        backendApi.get(
            `organizations/${orgId}/licenses`,
            config
        )
            .then(response => {
                console.log("[getOrganizationLicenses] response: ", response.data)
                if (response.status === 200)
                    resolve(response.data)
            })
            .catch(err => {
                console.error("[getOrganizationLicenses] error: ", err)
                reject(err)
            })

    })
}

function getOrganizationLicenseDetails(orgId: string, licenseId: string, token: string) {
    return new Promise<OrganizationLicenseData>((resolve, reject) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        backendApi.get(
            `organizations/${orgId}/licenses/${licenseId}`,
            config
        )
            .then(response => {
                console.log("[getOrganizationLicenseDetails] response: ", response.data)
                if (response.status === 200)
                    resolve(response.data)
            })
            .catch(err => {
                console.error("[getOrganizationLicenseDetails] error: ", err)
                reject(err)
            })

    })
}
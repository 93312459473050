import {
    IonButton,
    IonIcon,
    IonPage,
    IonRange,
    IonSelect,
    IonSelectOption,
    IonToolbar,
    isPlatform
} from "@ionic/react";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { arrowRedo, arrowUndo, chevronBackCircle, chevronBackCircleOutline, chevronForwardCircleOutline, closeOutline, download, downloadOutline, eyeOutline, flash, pause, pencilOutline, play, playBack, playForward, trashBin, trashOutline, volumeMedium } from "ionicons/icons";
import React from "react";
import { connect } from "react-redux";
import { auth, remoteConfig } from "../../../firebaseConfig";
import { Argument } from "../../sessions/store/reducers";
import { Organization } from "../../user/store/reducers";
import "./Flashcards.css";
import { flashcardsServices } from "../store/services";
import { Flashcards } from "../../sessions/store/reducers";
import { isIOS, isMobile } from "react-device-detect";
import { checkStatusBar } from "../../sessions/utils/utils";
import { Trans } from "react-i18next";
import { Flashcard, FlashcardsCollection } from "../store/reducers";
import * as XLSX from 'xlsx';
import i18n from "../../../i18n";


type Props = {
    history: any;
    orgData: Organization | null

};

type State = {
    userUuid: string | null,
    userArguments: Argument[],
    // userArguments: any[],
    remoteConfig: boolean,
    newArgument: string,
    isProcessingResources: boolean,
    argument: Argument | null,
    flashcards: Flashcards | null
    isLoadingFlashcards: boolean,
    flashcardPointer: number,
    flashcardsCollection: FlashcardsCollection | null,
    editingMode: boolean,
    bearerToken: string,
};

class FlashcardsPage extends React.Component<Props, State> {
    debounceTimer: ReturnType<typeof setTimeout> | null = null;
    constructor(props: any) {
        super(props);
        this.state = {
            userUuid: null,
            userArguments: [],
            remoteConfig: false,
            newArgument: '',
            isProcessingResources: false,
            argument: null,
            flashcards: null,
            isLoadingFlashcards: true,
            flashcardPointer: 0,
            flashcardsCollection: null,
            editingMode: false,
            bearerToken: '',
        };
    }
    componentDidMount() {
        if (this.props.history.location.state.argument) {
            let argument = this.props.history.location.state.argument;
            this.setState({
                argument: argument,
            })
        }

        console.log(this.props.history.location.state.flashcardUuid);
        /*  flashcardsServices.getFlashcards(this.props.history.location.state.flashcardUuid)
             .then(flashcards => {
                 if (flashcards) {
                     this.setState({
                         flashcards: flashcards,
                     })
                 }
             }) */
        // (document.getElementById('audioRange') as HTMLIonRangeElement).max = this.audioElement.duration

        fetchAndActivate(remoteConfig)
            .then((activated) => {
                if (activated) console.log("Fetched and activated configs!");
                else {
                    const remoteConfigValue = getValue(remoteConfig, "showLicenseCode").asBoolean();
                    this.setState({ remoteConfig: remoteConfigValue })
                }

            })
            .catch((err) => console.error("Failed to fetch and activate configs", err));
        auth.onAuthStateChanged((userData) => {
            if (userData) {
                this.setState({ userUuid: userData.uid });
                userData.getIdToken().then(token => {
                    this.setState({ bearerToken: token })
                    if (this.props.history.location.state.flashcardUuid) {
                        flashcardsServices.getSingleFlashcardsCollection(this.props.history.location.state.flashcardUuid, token)
                            .then(response => {
                                console.log(response)
                                this.setState({
                                    flashcardsCollection: response
                                })
                            })
                            .catch(err => {
                                console.error(err);
                            })

                    }
                })

            } else {
                this.setState({
                    userUuid: null,
                });
            }
        });
    }
    removeFlashcard(flashcard: Flashcard) {
        if (this.state.flashcardsCollection && flashcard)
            flashcardsServices.removeFlashcard(this.state.flashcardsCollection.id, flashcard.id, this.state.bearerToken)
                .then(response => {
                    let newFlashcards = this.state.flashcardsCollection?.flashcards.filter(fc => fc.id !== flashcard.id)
                    this.setState((prevState: any) => ({
                        flashcardsCollection: {
                            ...prevState.flashcardsCollection,
                            flashcards: newFlashcards
                        },
                    }))

                })

    }
    updateFlashcardFace(flashcard: Flashcard, face: 'front' | 'back', content: string) {
        if (this.state.flashcardsCollection && flashcard && face && content)
            flashcardsServices.updateFlashcardFace(this.state.flashcardsCollection.id, flashcard.id, face, content.trim(), this.state.bearerToken)
    }

    debounce = (callback: () => void, delay: number = 2000) => {
        // Clear the previous timer
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }

        // Set a new timer for the specified delay (2 seconds by default)
        this.debounceTimer = setTimeout(() => {
            callback(); // Execute the callback once no more changes are detected
        }, delay);
    };


    paletteColors = ["#C7DEFF", "#FFCCDC", "#BDBDFF", "#FCD9CA"]

    render() {
        return (
            <IonPage className="flashcardsPage body">
                {this.state.argument && this.state.flashcardsCollection && this.state.flashcardsCollection.flashcards && this.state.flashcardsCollection.flashcards.length > 0 &&
                    <div className="flashcardsMainDiv">
                        {(isMobile && (isPlatform('capacitor') && isIOS)) && <IonToolbar className="toolbarNotch" style={{ height: checkStatusBar() }}  ></IonToolbar>}

                        <IonButton
                            onClick={() => {
                                this.props.history.push({
                                    pathname: `/session-details`,
                                    state: { argument: this.state.argument }
                                });
                            }}
                            className="sessionsDetailsGoBackBtn"
                        >
                            <Trans>
                                Torna indietro
                            </Trans>
                        </IonButton>
                        <div className="sessionsHomeOnboardingTitle">
                            <p className="flashcardsTitle">"{this.state.argument?.title}"</p>
                        </div>
                        <div>
                            <p className="flashcardsCTAPar" style={{ margin: 0 }}>
                                <Trans>
                                    Clicca sulla carta per vedere la risposta
                                </Trans>
                            </p>
                        </div>
                        <div className="flaschardsModeDiv">
                            <IonButton
                                className="flashcardsEditButton"
                                fill="outline"
                                onClick={() => {
                                    this.setState({ editingMode: !this.state.editingMode })
                                }}
                            >
                                <IonIcon icon={this.state.editingMode ? eyeOutline : pencilOutline} />
                                {this.state.editingMode ? i18n.t("Visualizza") : i18n.t("Modifica")}
                            </IonButton>
                            <IonButton
                                className="flashcardsEditButton"
                                fill="outline"
                                onClick={() => {
                                    if (this.state.flashcardsCollection?.flashcards) {
                                        const exportData = this.state.flashcardsCollection.flashcards.map(item => ({
                                            Domanda: item.front_content,
                                            Risposta: item.back_content
                                        }));

                                        // Create worksheet
                                        const ws = XLSX.utils.json_to_sheet(exportData);

                                        // Create workbook and add the worksheet
                                        const wb = XLSX.utils.book_new();
                                        XLSX.utils.book_append_sheet(wb, ws, 'Quiz Data');

                                        // Generate Excel file and trigger download
                                        XLSX.writeFile(wb, `${this.state.flashcardsCollection.title}_flashcards.xlsx`);
                                    }
                                }}
                            >
                                <IonIcon icon={downloadOutline} />
                            </IonButton>
                        </div>
                        <div className="flashcardsContainer">
                            {this.state.flashcardsCollection.flashcards.map((fc, i) => {
                                return (
                                    <div
                                        className={i === 0 ? "fcCard card--current" : i === 1 ? "fcCard card--next" : "fcCard"}
                                        style={{ backgroundColor: this.paletteColors[i % this.paletteColors.length] }}
                                        onClick={(e) => {
                                            console.log(e.nativeEvent.target as HTMLElement)

                                            if (this.state.editingMode && (e.nativeEvent.target as HTMLElement).className !== 'fcCardContentPar' || !this.state.editingMode) {
                                                if (e.currentTarget.classList.contains('fcCardClicked'))
                                                    e.currentTarget.classList.remove('fcCardClicked')
                                                else
                                                    e.currentTarget.classList.add('fcCardClicked')
                                            }

                                        }}
                                        key={i}
                                        id={`fc-${i}`}
                                    >
                                        <IonIcon hidden={this.state.flashcardPointer !== i || !this.state.editingMode} className="fcRemoveIcon" icon={trashOutline} onClick={() => {
                                            this.removeFlashcard(fc)
                                        }} />
                                        <p className="fcCounter">{i + 1} <Trans>di</Trans> {this.state.flashcardsCollection!.flashcards.length}</p>
                                        <div className="fcCardInner">
                                            <div className="fcCardFront">
                                                <p
                                                    contentEditable={this.state.editingMode}
                                                    className="fcCardContentPar"
                                                    onInput={(e) => {
                                                        let value = (e.target as HTMLParagraphElement).innerText
                                                        this.debounce(() => {
                                                            this.updateFlashcardFace(fc, 'front', value)
                                                        })
                                                    }}
                                                >
                                                    {fc.front_content}
                                                </p>
                                            </div>
                                            <div
                                                className="fcCardBack"
                                            >
                                                <p
                                                    className="fcCardContentPar"
                                                    contentEditable={this.state.editingMode}
                                                    onInput={(e) => {
                                                        let value = (e.target as HTMLParagraphElement).innerText
                                                        this.debounce(() => {
                                                            this.updateFlashcardFace(fc, 'back', value)
                                                        })
                                                    }}
                                                >
                                                    {fc.back_content}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            <IonIcon
                                className="fcButton fcPrevious"
                                icon={arrowUndo}
                                onClick={() => {
                                    let cards = document.querySelectorAll('.fcCard')
                                    let currentCard = document.getElementById(`fc-${this.state.flashcardPointer}`)
                                    if (this.state.flashcardsCollection && currentCard) {

                                        cards.forEach((card) => card.classList.remove('card--out', 'card--next', 'card--in', 'fcCardClicked'));
                                        // currentCard.classList.add('card--out');


                                        let nextIndex
                                        if (this.state.flashcardPointer === 0) {
                                            this.setState({ flashcardPointer: this.state.flashcardsCollection.flashcards.length - 1 })
                                            nextIndex = this.state.flashcardsCollection.flashcards.length - 1
                                        }
                                        else {
                                            this.setState({ flashcardPointer: this.state.flashcardPointer - 1 })
                                            nextIndex = this.state.flashcardPointer - 1
                                        }

                                        const nextCard = cards[nextIndex];

                                        nextCard.classList.add('card--in')
                                        setTimeout(() => {
                                            currentCard!.classList.remove('card--current',)
                                            nextCard.classList.remove('card--in');
                                            nextCard.classList.add('card--current');
                                        }, 600);

                                        let followingCard
                                        if (nextIndex - 1 > 0)
                                            followingCard = cards[nextIndex - 1];
                                        else
                                            followingCard = cards[cards.length - 1];
                                        followingCard?.classList.add('card--next');
                                    }

                                }}
                            >
                            </IonIcon>
                            <IonIcon
                                className="fcButton fcNext"
                                icon={arrowRedo}
                                onClick={() => {
                                    let cards = document.querySelectorAll('.fcCard')
                                    console.log("CARDS ARRAY: ", cards);
                                    let currentCard = document.getElementById(`fc-${this.state.flashcardPointer}`)
                                    if (this.state.flashcardsCollection && currentCard) {

                                        cards.forEach((card) => card.classList.remove('card--current', 'card--out', 'card--next', 'card--in', 'fcCardClicked'));
                                        currentCard.classList.add('card--out');

                                        let nextIndex
                                        if (this.state.flashcardPointer === this.state.flashcardsCollection.flashcards.length - 1) {
                                            this.setState({ flashcardPointer: 0 })
                                            nextIndex = 0
                                        }
                                        else {
                                            this.setState({ flashcardPointer: this.state.flashcardPointer + 1 })
                                            nextIndex = this.state.flashcardPointer + 1
                                        }
                                        const nextCard = cards[nextIndex];
                                        setTimeout(() => {
                                            currentCard!.classList.remove('card--out');

                                        }, 600);
                                        nextCard.classList.add('card--current');

                                        let followingCard
                                        if (nextIndex + 1 < cards.length)
                                            followingCard = cards[nextIndex + 1];
                                        else
                                            followingCard = cards[0];
                                        followingCard?.classList.add('card--next');
                                    }
                                }}
                            >
                                Prossima
                            </IonIcon>
                        </div>
                        <div className="bottomButtons">

                        </div>
                    </div>
                }
            </IonPage>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        orgData: state.user.organization,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlashcardsPage);

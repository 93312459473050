import { backendApi } from "../../../common/apiService";

export const invitationServices = {
    respondToInvitation,
};

function respondToInvitation(userId: string, token: string, invitationToken: string, invitationResponse: boolean) {
    return new Promise<string>((resolve, reject) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        backendApi.post(`users/${userId}/invitations/respond`,
            {
                invitation_token: invitationToken,
                response: invitationResponse,
            },
            config
        )
            .then(response => {
                if (response.status === 200) {
                    console.log("[Respond to invitation] response: ", response.data)
                    resolve(response.data.message)
                }
                else
                    reject(response.status)
            })
            .catch(err => {
                console.error("[Respond to invitation] error: ", err)
                reject(err)
            })
    })
}
import axios, { AxiosResponse } from "axios";
import { backendApi, backendApiDemo } from "../../../../common/apiService";
import { Session } from "../reducers";


export async function createStudySession(token:string,language:string,file_id?:string|null ,description?:string|null,title?:string):Promise<Session | null>{
    try{
        const response: AxiosResponse = await  backendApi.post(
                `/study-sessions`,
            {
                title: title,
                description: description,
                file_id: file_id,
                language:language
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if(response.status ===201){
            console.log("[createStudySession]Creation Successful:", response.status);
            return response.data;
        }else{
            console.error('[createStudySession]Unexpected error:', response.status);
            return null
        }
    } catch (error) {
        // Handle errors
        if (axios.isAxiosError(error)) {
            console.error('[createStudySession]Error creating session:', error.response?.data || error.message);
        } else {
            console.error('[createStudySession]Unexpected error:', error);
        }
        return null
    }
}


export async function getStudySessions(config: {}): Promise<Session[]> {
    try {
        const response: AxiosResponse = await backendApi.get(
            `/study-sessions`,config
        );

        if (response.status === 200 && response.data) {
            let session:Session[]=response.data
            return session
        } else {
            console.error('[getStudySessions] Error retrieving sessions:', response.status);
            return [];
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('[getStudySessions] Axios error retrieving sessions:', error.response?.data || error.message);
        } else {
            console.error('[getStudySessions] Unexpected error:', error);
        }
        return [];
    }
}

export async function getSingleStudySession(session_id: string, config:{}): Promise<Session | null> {
    try {
        const response: AxiosResponse= await backendApi.get(
            `/study-sessions/${session_id}`,config);

        if (response.status === 200 && response.data) {
            let session:Session=response.data
            return session
        } else {
            console.error(`[getSingleStudySession] Error retrieving session: ${response.status}`);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error(`Error fetching single session ${session_id}`, error.response?.data || error.message);
        } else {
            console.error('[getSingleStudySession] Unexpected error:', error);
        }
        return null;
    }
}

export async function deleteSingleStudySession(session_id: string, config:{}): Promise<Session | null> {
    try {
        const response: AxiosResponse= await backendApi.delete(
            `/study-sessions/${session_id}`,config);

        if (response.status === 200 && response.data) {
            let session:Session=response.data
            console.log("[deleteSingleStudySession] Success")
            return session
        } else {
            console.error(`[deleteSingleStudySession] Error retrieving session: ${response.status}`);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error(`Error fetching single session ${session_id}`, error.response?.data || error.message);
        } else {
            console.error('[deleteSingleStudySession] Unexpected error:', error);
        }
        return null;
    }
}


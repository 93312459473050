import React from "react";
import { IonPage, IonContent, IonGrid, IonHeader, IonButtons, IonBackButton, IonToolbar, IonTitle, IonButton } from "@ionic/react";
import { connect } from "react-redux";
import "./PrivacyPolicy.css";

type Props = {
	history: any;
};

type State = {};

class PrivacyPolicy extends React.Component<Props, State> {
	/* constructor(props: any) {
		super(props);
		this.state = {
		}
	}
	*/

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/" />
						</IonButtons>
						<IonTitle>Informativa sulla privacy</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent fullscreen>
					<IonGrid className="privacyPolicyGrid">
						<h1>Privacy policy</h1>
						<p>
							{/* Da tradurre nelle varie lingue appena saranno  modificati */}
							La presente informativa tiene conto di quanto indicato dal GDPR e dal Codice della Privacy (D. Lgs 30 giugno 2003 n. 196). Il documento è stato
							redatto anche in base alle Linee Guida del Garante Privacy (soprattutto le Linee Guida di contrasto allo spam emesse dl Garante Privacy il 4
							luglio 2013).
						</p>
						<h3>Titolare del Trattamento </h3>
						<p>
							Tech4All s.r.l.
							<br />
							<br />
							Largo dell’Università SNC
							<br />
							<br />
							01100 Viterbo VT
							<br />
							<br />
							dpo@tech4all.ai
							<br />
							<br />
							tech4all@pec.it
							<br />
							<br />{" "}
						</p>
						<h3>Resposabile della Protezione dei Dati</h3>
						<p>
							Lorenzo Daidone
							<br />
							<br />
							Largo dell’Università SNC
							<br />
							<br />
							01100 Viterbo VT
							<br />
							<br />
							dpo@tech4all.ai
							<br />
							<br />
							tech4all@pec.it
							<br />
							<br />{" "}
						</p>

						<p>Piattaforme (Piattaforma) al quale si riferisce la presente privacy policy:</p>
						<ul>
							<li>
								Reasy app iOS <a href="https://apps.apple.com/app/id1615405021">(https://apps.apple.com/app/id1615405021)</a>
							</li>
							<li>
								Reasy app Android{" "}
								<a href="https://play.google.com/store/apps/details?id=ai.tech4all.reasy">(https://play.google.com/store/apps/details?id=ai.tech4all.reasy)</a>
							</li>
							<li>
								Reasy web App <a href="https://app.reasyedu.com">(https://app.reasyedu.com)</a>
							</li>
						</ul>
						<br />
						<h1>Informazioni generali</h1>
						<p>Il presente documento descrive come il Titolare del Trattamento tratta i Suoi dati personali conferiti sul Sito.</p>
						<p>
							Di seguito vengono descritti i principali trattamenti dei Suoi dati personali. Viene in particolare spiegata la base giuridica del trattamento, se
							il conferimento è obbligatorio e le conseguenze del mancato conferimento di dati personali. Per descrivere al meglio i Suoi diritti, qualora
							necessario, abbiamo specificato se e quando un determinato trattamento di dati personali non viene effettuato.
						</p>

						<h3>Registrazione sulla Piattaforma</h3>
						<p>
							Le informazioni e i dati richiesti in caso di registrazione verranno utilizzati per consentirLe sia di accedere all’area riservata della
							Piattaforma, sia di usufruire dei servizi on line offerti dal Titolare del Trattamento agli utenti registrati.
						</p>
						<p>
							La base giuridica del trattamento è la necessità del Titolare del Trattamento di eseguire misure precontrattuali adottate su richiesta
							dell'interessato. Il conferimento dei dati è facoltativo. Tuttavia, il Suo eventuale rifiuto di conferire i dati comporterà l’impossibilità di
							registrarsi sulla Piattaforma.
						</p>
						<br />
						<br />
						<p>
							Sulla Piattaforma è possibile registrarsi utilizzando anche i servizi esterni. In questo caso i Suoi dati di registrazione saranno condivisi con
							le aziende di questi servizi esterni al solo fine di permettere la registrazione sul Sito.
						</p>
						<p>
							La base giuridica di questo trattamento è il legittimo interesse del Titolare del Trattamento a permettere la registrazione alla Piattaforma
							tramite servizi esterni. Il conferimento dei dati personali per questa finalità è meramente facoltativo. Il mancato assenso al trattamento dei
							dati comporterà però l'impossibilità di registrarsi tramite servizi esterni.
						</p>
						<h3>Rispondere alle Sue richieste </h3>
						<p>
							I Suoi dati verranno trattati per rispondere alle Sue richieste di informazioni. Il conferimento è facoltativo, ma il Suo rifiuto comporterà
							l’impossibilità per il Titolare del Trattamento di rispondere alle Sue domande.
						</p>
						<p>
							La base giuridica del trattamento è il legittimo interesse del Titolare del Trattamento a dare seguito alle richieste dell’utente. Questo
							legittimo interesse è equivalente all'interesse dell'utente a ricevere risposta alle comunicazioni inviate al Titolare del Trattamento.
						</p>
						<h3>Marketing generico </h3>
						<p>
							Previo Suo consenso, il Titolare del Trattamento potrà trattare i dati personali da Lei conferiti al fine di inviarLe materiale pubblicitario e/o
							newsletter relativo a prodotti propri o di terzi.
						</p>
						<p>
							La base giuridica di questo trattamento è il Suo consenso. Il conferimento dei dati personali per questa finalità è meramente facoltativo. Il
							mancato assenso al trattamento dei dati per finalità di marketing comporterà l’impossibilità per Lei di ricevere materiale pubblicitario relativo
							a prodotti/servizi del Titolare del Trattamento e/o di terzi nonché l'impossibilità per il Titolare del Trattamento di svolgere indagini di
							mercato, anche dirette a valutare il grado di soddisfazione degli utenti, nonché di inviarLe newsletter. L'invio di queste comunicazioni avverrà
							alla e-mail da Lei conferita sulla Piattaforma.
						</p>

						<h3>Profilazione </h3>
						<p>
							Previo Suo consenso, il Titolare del Trattamento potrà trattare i Suoi dati personali per finalità di profilazione, ossia per l'analisi delle Sue
							scelte di consumo attraverso la rivelazione della tipologia e della frequenza degli acquisti da Lei effettuati, al fine di inviarLe materiale
							pubblicitario e/o newsletter relativi a prodotti propri o di terzi, di Suo specifico interesse.{" "}
						</p>
						<p>
							La base giuridica di questo trattamento è il Suo consenso. Il conferimento dei dati per questa finalità è meramente facoltativo. Il mancato
							assenso al trattamento dei Suoi dati personali per finalità di profilazione comporterà l’impossibilità per il Titolare del Trattamento di
							elaborare il Suo profilo commerciale, mediante la rilevazione delle Sue scelte e abitudini di acquisto nonché di inviarLe materiale pubblicitario,
							relativo a prodotti del Titolare del Trattamento e/o di terzi, di Suo specifico interesse. Queste comunicazioni verranno inviate alla e-mail da
							Lei conferita sulla Piattaforma.
						</p>
						<h4>Cessione dei dati </h4>
						<p>Il Titolare del Trattamento non cede a terzi i Suoi dati personali. </p>
						<h3>Comunicazione dei dati personali</h3>
						<p>
							Nell'ambito della propria ordinaria attività, il Titolare del Trattamento può comunicare i Suoi dati personali a determinate categorie di
							soggetti. All'articolo 2 Lei può trovare l'elenco dei soggetti ai quali il Titolare del Trattamento comunica i Suoi dati personali.
						</p>
						<p>Per agevolare la tutela dei Suoi diritti, l'articolo 2 può specificare in alcuni casi quando i Suoi dati non vengono comunicati a terzi.</p>
						<br />
						<br />
						<p>
							La "comunicazione" a terzi del dato personale è diversa dalla "cessione" (disciplinata al punto che precede). Infatti, nella comunicazione il
							terzo al quale è trasmesso il dato può usarlo solo per le specifiche finalità descritte nel rapporto con il Titolare del Trattamento. Nella
							cessione, invece, il terzo diventa Titolare del Trattamento autonomo del dato personale. Inoltre, per cedere i Suoi dati personali a terzi è
							sempre richiesto il Suo consenso.
						</p>
						<br />
						<br />
						<p>
							Fermo quanto precede, resta inteso che il Titolare del Trattamento potrà comunque utilizzare i Suoi dati personali per dare corretto adempimento
							agli obblighi previsti dalle leggi in vigore.
						</p>
						<h1>Informativa privacy specifica</h1>
						<h4 style={{ color: "#5e87c9" }}>Art. 1 Modalità di trattamento </h4>
						<p>
							1.1 Il trattamento dei Suoi dati personali sarà principalmente effettuato con l’ausilio di mezzi elettronici o comunque automatizzati, secondo le
							modalità e con gli strumenti idonei a garantirne la sicurezza e la riservatezza in conformità al GDPR. Qualora sia operativo il servizio di
							chatbot automatico, i Suoi dati personali saranno trattati anche per permettere l’attivazione di questo servizio, attraverso il quale l'utente può
							contattare ed essere contattato del Titolare del Trattamento, previo consenso. La base giuridica è il legittimo interesse del Titolare del
							Trattamento a rispondere alle richieste dell'utente tramite il servizio di chatbot. Questo legittimo interesse può ritenersi equivalente
							all'interesse dell'interessato ad utilizzare il servizio di chatbot automatico.
						</p>
						<br />
						<p>
							1.2 Le informazioni acquisite e le modalità del trattamento saranno pertinenti e non eccedenti rispetto alla tipologia dei servizi resi. I Suoi
							dati saranno altresì gestiti e protetti in ambienti informatici sicuri e adeguati alle circostanze.
						</p>
						<p>
							1.3 Tramite il Sito non vengono trattati "dati particolari". I dati particolari sono quelli che possono rivelare l'origine razziale ed etnica, le
							convinzioni religiose, filosofiche o di altro genere, le opinioni politiche, l'adesione a partiti, sindacati, associazioni od organizzazioni a
							carattere religioso, filosofico, politico o sindacale, lo stato di salute e la vita sessuale.
						</p>
						<br />

						<p>1.4 Tramite il Sito non vengono trattati dati giudiziari.</p>
						<h4 style={{ color: "#5e87c9" }}>Art. 2 Comunicazione dei dati personali </h4>
						<p>
							Il Titolare del Trattamento può comunicare i Suoi dati personali a categorie determinate di soggetti. Di seguito vengono indicati i soggetti ai
							quali il Titolare del Trattamento si riserva di comunicare i Suoi dati:
						</p>
						<ul>
							<li>
								Il Titolare del Trattamento può comunicare i Suoi dati personali a tutti quei soggetti (ivi incluse le Pubbliche Autorità) che hanno accesso ai
								dati personali in forza di provvedimenti normativi o amministrativi.
							</li>
							<li>
								I Suoi dati personali possono essere comunicati anche a tutti quei soggetti pubblici e/o privati, persone fisiche e/o giuridiche (studi di
								consulenza legale, amministrativa e fiscale, Uffici Giudiziari, Camere di Commercio, Camere ed Uffici del Lavoro, ecc.), qualora la
								comunicazione risulti necessaria o funzionale al corretto adempimento degli obblighi derivanti dalla legge.
							</li>
							<li>
								Il Titolare del Trattamento non si avvale di dipendenti e/o collaboratori a qualsiasi titolo. Pertanto, i Suoi dati personali non verranno
								comunicati a questa categoria di soggetti.
							</li>
							<li>
								Il Titolare del Trattamento non si avvale di società, consulenti o professionisti incaricati dell’installazione, della manutenzione,
								dell’aggiornamento e, in generale, della gestione degli hardware e software del Titolare del Trattamento. Pertanto, i Suoi dati non verranno
								comunicati a queste categorie di soggetti.
							</li>
							<li>
								Per l'invio delle proprie comunicazioni il Titolare del Trattamento si avvale di società esterne incaricate dell’invio di questo tipo di
								comunicazioni (piattaforme CRM). I Suoi dati personali (in particolare l'email) potranno quindi essere comunicati a queste società.
							</li>
							<li>Il Titolare del Trattamento non si avvale di società esterne per prestare il servizio di customer care.</li>
							<li>I dati personali degli acquirenti non sono comunicati a corrieri o spedizionieri.</li>
						</ul>
						<p>
							Il Titolare si riserva la facoltà di modificare il sopra indicato elenco in base alla propria ordinaria operatività. Pertanto, Lei è invitato ad
							accedere con regolarità alla presente informativa per controllare a quali soggetti il Titolare del Trattamento comunica i Suoi dati personali.
						</p>
						<h4 style={{ color: "#5e87c9" }}>Art. 3 Conservazione dei dati personali </h4>
						<p>3.1 Il presente articolo descrive per quanto tempo il Titolare del Trattamento si riserva il diritto di conservare i Suoi dati personali.</p>
						<ul>
							<li>
								I Suoi dati personali saranno conservati per il solo tempo necessario a garantire la corretta prestazione dei servizi offerti tramite il Sito.
							</li>
							<li>
								Per finalità di marketing, i dati personali saranno conservati fino alla eventuale revoca del consenso. Per gli utenti inattivi, i dati
								personali verranno cancellati dopo un anno dall'invio dell'ultima email eventualmente visionata.
							</li>
						</ul>
						<p>
							3.2 Fermo quanto previsto all'articolo 3.1, il Titolare del Trattamento può conservare i Suoi dati personali per il tempo richiesto da normative
							specifiche, come di volta in volta modificate.
						</p>
						<h4 style={{ color: "#5e87c9" }}>Art. 4 Trasferimento dei dati personali </h4>
						<p>
							4.1 Il Titolare del Trattamento ha sede all'interno dell'Unione europea. Pertanto, il trattamento dei Suoi dati è sicuro dal punto di vista
							normativo in quanto disciplinato dal GDPR. Qualora il trasferimento dei Suoi dati personali avvenga in un Paese extra-UE e per il quale la
							Commissione europea ha espresso un giudizio di adeguatezza, il trasferimento si ritiene in ogni caso sicuro dal punto di vista normativo. Il
							presente articolo 4.1 indica di volta in volta i Paesi nei quali i Suoi dati personali possono essere eventualmente trasferiti e dove la
							Commissione europea ha espresso un giudizio di adeguatezza. Si invita pertanto l'utente ad accedere con regolarità al presente articolo per
							verificare se il trasferimento dei Suoi dati personali avviene in un Paese con queste caratteristiche.
						</p>
						<p>
							4.2 Fermo quanto indicato all'articolo 4.1, i Suoi dati possono essere trasferiti anche in Paesi extra-UE e per i quali la Commissione europea non
							ha espresso un giudizio di adeguatezza. Lei è pertanto invitato a visionare con regolarità il presente articolo 4.2 per appurare in quali di
							questi Paesi i Suoi dati sono eventualmente trasferiti.{" "}
						</p>
						<p>
							4.3 In questo articolo il Titolare del Trattamento indica i Paesi presso i quali eventualmente dirige in modo specifico la propria attività.
							Questa circostanza può implicare l'applicazione della normativa del Paese di riferimento, unitamente a quella del GDPR. Su richiesta dell'utente,
							il Titolare del Trattamento applicherà al trattamento dei dati personali la normativa eventualmente più favorevole prevista dalla legislazione
							nazionale dell'utente stesso.
						</p>

						<h4 style={{ color: "#5e87c9" }}>Art. 5. Diritti dell'interessato </h4>
						<p>Ai sensi dell’art. 13 del Regolamento Privacy, il Titolare del Trattamento La informa che Lei ha diritto di:</p>
						<p>
							chiedere al Titolare del Trattamento l’accesso ai Suoi dati personali e la rettifica o la cancellazione degli stessi o la limitazione del
							trattamento che La riguardano o di opporsi al loro trattamento, oltre al diritto alla portabilità dei dati revocare il consenso in qualsiasi
							momento senza pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca proporre reclamo a un’autorità di controllo
							(es.: il Garante per la protezione dei dati personali). I diritti di cui sopra potranno essere esercitati con richiesta rivolta senza formalità ai
							contatti indicati in Premessa.
						</p>
						<h4 style={{ color: "#5e87c9" }}>Art. 6. Modifiche e Miscellanea </h4>
						<p>
							Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente informativa in qualsiasi momento, dandone idonea pubblicità
							agli utenti del Sito e garantendo in ogni caso una adeguata ed analoga protezione dei dati personali. Al fine di visionare eventuali modifiche,
							Lei è invitato a consultare con regolarità la presente informativa. In caso di modifiche sostanziali alla presente informativa privacy, il
							Titolare del Trattamento ne potrà dare comunicazione anche tramite email.
						</p>
						<hr style={{ width: "100%", background: "#4a4a4a" }}></hr>
						<h4>Contattaci</h4>
						<p>
							In caso di domande, richieste o reclami riguardanti la presente Privacy Policy o su come trattiamo i dati personali, l’utente è invitato a
							contattarci utilizzando i dettagli di contatto di seguito.
						</p>
						<p>
							Se l’utente sporge reclamo, lo esamineremo e forniremo una risposta entro un termine ragionevole, a condizione di disporre di tutte le
							informazioni necessarie e di aver completato qualsiasi indagine richiesta. In alcuni casi, potremmo aver bisogno di chiedere all’utente di mettere
							il reclamo per iscritto per essere sicuri di comprenderlo. Potremmo inoltre aver bisogno di chiedere all’utente ulteriori informazioni o di
							verificare la sua identità. Cercheremo di confermare se appropriato e necessario con voi la vostra comprensione della condotta rilevante per il
							reclamo e di ciò che vi aspettate come risultato. Tratteremo il reclamo in modo confidenziale e risponderemo entro un termine ragionevole,
							solitamente per iscritto.
						</p>
						<p>
							Se non sei soddisfatto del risultato, ti preghiamo di contattarci. In alternativa, hai il diritto di presentare reclamo al regolatore della
							privacy nel tuo paese.
						</p>
						<br />
						<br />
						<br />
						<IonButton routerLink="/support">Assistenza</IonButton>
					</IonGrid>
				</IonContent>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);

import { genericApiService } from "./apiService";

export const canvasSvgTools = {
    getDocumentSVGStringFromUrl,
    getCanvasPathsFromSvg,
};

interface CanvasPoint {
    x: number,
    y: number,
}

interface CustomCanvasPath {
    drawMode: boolean,
    strokeColor: string,
    strokeWidth: number,
    paths: CanvasPoint[]
}

function getCanvasPathsFromSvg(svg: string): CustomCanvasPath[] {
    const canvasPathsToReturn: CustomCanvasPath[] = [];
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(svg, "image/svg+xml");
    console.log("XML DOC ", xmlDoc, xmlDoc.getElementsByTagName("g"));

    const gElements = xmlDoc.getElementsByTagName("g");

    for (let i = 0; i < gElements.length; i++) {
        const gElement = gElements[i];
        for (let j = 0; j < gElement.children.length; j++) {
            const child = gElement.children[j];
            console.log(child);
            const strokeColor = child.getAttribute('stroke') || '#000000'; // Default value if null
            const drawMode = strokeColor !== 'white';
            const strokeWidth = child.getAttribute('stroke-width') ? Number(child.getAttribute('stroke-width')) : 5;
            const segments = child.getAttribute('d');

            const canvasPathToPush: CustomCanvasPath = {
                drawMode: drawMode,
                strokeColor: strokeColor,
                strokeWidth: strokeWidth,
                paths: []
            };

            if (segments) {
                const arrayOfSvgPaths = segments.split('C');
                canvasPathToPush.paths.push(getPathStartingPoint(arrayOfSvgPaths[0]));
                for (let k = 1; k < arrayOfSvgPaths.length; k++) {
                    let segment = arrayOfSvgPaths[k].split(/\s+/);
                    segment = sanitizePointsArray(segment); // Ensure sanitizePointsArray function is defined elsewhere
                    const path = canvasPoint(segment); // Ensure canvasPoint function is defined elsewhere
                    canvasPathToPush.paths.push(path);
                }
                canvasPathsToReturn.push(canvasPathToPush);
            }
        }
    }

    return canvasPathsToReturn;
}


function getPathStartingPoint(pointString: string) {
    let tempString = pointString.replace('M', '');
    tempString = tempString.replace(/\s/g, '');
    return canvasPoint(tempString.split(','))
}

function sanitizePointsArray(array: string[]) {
    let arrayToReturn: string[] = [];
    const tempArray = array.filter(point => point !== ''); // there can be ',' and jointed coordinates
    for (let i = 0; i < tempArray.length; i++) {
        let tempArray2 = tempArray[i].split(',');
        tempArray2 = tempArray2.filter(point => point !== '');
        for (let j = 0; j < tempArray2.length; j++) {
            arrayToReturn.push(tempArray2[j]);
        }
    }
    return arrayToReturn;
}

function canvasPoint(segment: string[]) {
    const y = Number(segment.pop());
    const x = Number(segment.pop());
    const pointToReturn: CanvasPoint = {
        x: x,
        y: y,
    }
    return pointToReturn;
}

function getDocumentSVGStringFromUrl(fileUrl: string) {
    return new Promise<string>((resolve, reject) => {
        genericApiService.get(fileUrl)
            .then(response => {
                //console.log('[getDocumentSVGStringFromUrl] response:', response.data); // for debugging
                resolve(response.data);
            })
            .catch(error => {
                //console.log('[getDocumentSVGStringFromUrl] error:', error.response.data);
                reject(error.response.data);
            });
    })
}
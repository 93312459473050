import { SettingsTranslationsCZ } from '../../modules/settings/SettingsTranslations'
import { ProfileTranslationsCZ } from '../../modules/user/ProfileTranslations'
import { MenuTranslationsCZ } from '../../modules/menu/MenuTranslations'
import { SketchTranslationsCZ } from '../../modules/sketch/SketchTranslations';
import { FileTranslationsCZ } from '../../modules/files/FileTranslations';
import { PopoverTranslationsCZ } from '../../modules/popover/PopoverTranslations';
import { AuthTranslationsCZ } from '../../modules/authentication/AuthTranslations';
import { OnboardingTranslationsCZ } from '../../modules/onboarding/OnboardingTranslations';
import { MapsTranslationsCZ } from '../../modules/maps/MapsTranslations';
import { SessionsTranslationsCZ } from '../../modules/sessions/SessionsTranslations';
import { FlashcardsTranslationsCZ } from '../../modules/flashcards/FlashcardsTranslations';
import { ViewerTranslationsCZ } from '../../modules/summaries/ViewerTranslations';

const mergedObject = {
  ...SettingsTranslationsCZ,
  ...ProfileTranslationsCZ,
  ...MenuTranslationsCZ,
  ...SketchTranslationsCZ,
  ...FileTranslationsCZ,
  ...PopoverTranslationsCZ,
  ...AuthTranslationsCZ,
  ...OnboardingTranslationsCZ,
  ...MapsTranslationsCZ,
  ...SessionsTranslationsCZ,
  ...FlashcardsTranslationsCZ,
  ...ViewerTranslationsCZ,


};

export const cz = {
  mergedObject,
}